import { html } from 'lit';
import { directive } from 'lit/directive.js';

import { getPracticeInformation } from '../neb-api-client/src/practice-information';
import { store } from '../neb-redux/neb-redux-store';

export const FEATURE_FLAGS = {
  EGG_AI_INTAKE_NARRATIVE: 'egg-ai-intake-narrative',
  CLAIMS_PLAYGROUND: 'claims-playground',
  CT_CHECK_IN_FORMS: 'ct-checkin-forms',
  CT_INFORMS: 'ct-informs',
  CT_INSIGHTS: 'ct-insights',
  ENABLE_PROVIDER_ACTIONS: 'enable-provider-actions',
  RECENT_RECORDS: 'recent-records',
  INSURANCE_CARE_PACKAGE: 'insurance-care-package',
  EXCEL_REPORT_LINK: 'excel-report-link',
  LOG_DUPLICATE_API_CALLS: 'log-duplicate-api-calls',
  LS_TAX_DISCOUNT: 'ls-tax-discount',
  LS_PREALLOCATION_SCREEN: 'ls-preallocation-screen',
  LS_DISCOUNT_FEEDBACK_BUTTON: 'ls-discount-feedback-button',
  LS_PREALLOCATION_FEEDBACK_BUTTON: 'ls-preallocation-feedback-button',
  LS_NOTIFICATIONS: 'ls-notifications',
  LS_CREDIT_PREALLOCATION: 'ls-credit-preallocation',
  LS_BULK_DISCOUNT_SELECTION: 'ls-bulk-discount-selection',
  TESTING_REPORT: 'testing-report',
  ENTERPRISE_REPORTS: 'enterprise-reports',
  CHECK_IN_OUT_V2: 'check-in-out-v2',
  DISABLE_INITIAL_LOAD: 'disable-initial-load',
  ZERO_CHARGE_CARE_PACKAGES: 'zero-charge-care-packages', // Used in neb-ms-billing
  RCM_SECONDARY_FIELD: 'rcm-secondary-field',
  RCM_CHANGE_SECONDARY: 'rcm-change-secondary',
  EASY_BILLING_NOTES: 'rcm-easy-billing-notes',
  RCM_QUICK_DELETE_SEARCH: 'rcm-quick-delete-search',
  RCM_CLAIM_FLAG: 'rcm-claim-flag',
  RCM_CLAIM_ACTION_HISTORY: 'rcm-claim-action-history',
  REMOVE_ALLOCATION_NAV: 'remove-allocation-nav',
  ADVANCED_PRACTICE_MENU: 'advanced-practice-menu',
  ALLOCATIONS_PATIENT_SEARCH: 'allocations-patient-search',
  APPLY_RESET_ALLOCATE_FIT: 'apply-reset-allocate-fit',
  PDF_CLEAN_UP_HTML_CONTENT: 'pdf-clean-up-html-content', // Used in neb-ms-pdf
  OWL_FILTERED_APPOINTMENTS_PRINT: 'owl-filtered-appointments-print',
  RTS_SCRUB: 'rts-scrub',
  RCM_RELEASE_2: 'rcm-release-2',
  SECONDARY_CLAIMS: 'rcm-secondary-claims',
  RTS_LATENCY_IMPROVEMENT: 'rts-latency-improvement',
  ITD_DOO_CLAIM_ERROR_OVERLAY: 'rcm-itd-doo-claim-error-overlay',
  MANAGE_ENCOUNTER_CLAIM_ERROR_OVERLAY:
    'rcm-manage-encounter-claim-error-overlay',
  RCM_INSURANCE_CLAIM_ERROR_OVERLAY: 'rcm-insurance-claim-error-overlay',
  PATIENT_INFO_CLAIM_ERROR_OVERLAY: 'rcm-patient-info-claim-error-overlay',
  RCM_PAYER_CLAIM_ERROR_OVERLAY: 'rcm-payer-claim-error-overlay',
  FIT_INVOICE_OVERLAY_PERFORMANCE: 'fit-invoice-overlay-performance',
  ENABLE_RUM_SESSION_REPLAY: 'enable-rum-session-replay',
  ERA_OVERVIEW_NAVIGATION_A: 'era-overview-navigation-a',
  ERA_OVERVIEW_NAVIGATION_B: 'era-overview-navigation-b',
  ERA_PERF_S3_KAFKA: 'era-perf-s3-kafka', // used in neb-ms-electronic-claims and neb-ms-billing
  EGG_CLAIMS_RESOLUTION_DOCUMENTS_TAB: 'egg-claims-resolution-documents-tab',
  DH_CHECK_IN: 'dh-check-in',
  DH_CHECK_OUT: 'dh-check-out',
  DH_SINGLE_CHARGE_VIEW_AUDIT_LOG: 'dh-single-charge-view-audit-log',
  EGG_FUTURE_COPY_ANY_ENCOUNTER: 'egg-future-copy-any-encounter',
  CLAIM_TOTALS_V2: 'claim-totals-v2',
  OWL_ERA_EOB_REMOVE_EDIT_CHARGES: 'owl-era-eob-remove-edit-charges',
  PAYMENT_DETAILS_BETA: 'payment-details-beta', // Used in neb-ms-reports,
  EGG_SHOW_NEXT_INSURANCE: 'egg-show-next-insurance',
  END_OF_DAY_SUMMARY_BETA: 'end-of-day-summary-beta',
  END_OF_DAY_TRANSACTIONS_BETA: 'end-of-day-transactions-beta',
  OWL_ALLOCATION_PAGE_PERFORMANCE: 'owl-allocation-page-performance',
  EGG_AR_HUB_TAB: 'egg-ar-hub-tab',
  EGG_OPTIMIZE_AR_HUB_INVOICES: 'egg-optimize-ar-hub-invoices',
  OWL_ERA_ACTIONS_ENHANCEMENTS: 'owl-era-actions-enhancements',
  OWL_ITEMIZED_RECEIPT: 'owl-itemized-receipt',
  LS_NEW_SCAN_VERSION: 'ls-new-scan-version',
  LS_SCAN_API_CACHE: 'ls-scan-api-cache',
  OWL_PAYER_PLAN_CACHE: 'owl-payer-plan-cache',
  OWL_PAYER_PLAN_JOIN: 'owl-payer-plan-join',
  EGG_FIT_REPORT: 'egg-fit-report',
  EGG_DATE_OF_ONSET: 'egg-date-of-on-set',
  OWL_REMIT_OFFSET_AUTOMATIC_CREATION: 'owl-remit-offset-automatic-creation',
  OWL_PREVIEW_ALLOCATIONS_PERFORMANCE: 'owl-preview-allocations-performance',
  OWL_LEDGER_BALANCE_IMPROVEMENTS: 'owl-ledger-balance-improvements',
  HELIX_CHC_OVERRIDE: 'helix-chc-override',
  EGG_AVOID_DATA_CORRUPTION_FOR_CHARGES_FROM_ARCHIVED_ENCOUNTERS:
    'egg-avoid-data-corruption-for-charges-from-archived-encounters',
  OWL_ASYNC_STATEMENT_BATCHES: 'owl-async-statement-batches',
  DH_LOCAL_STORAGE_FILTERS: 'dh-local-storage-filters',
  OWL_ITEMIZED_RECEIPT_BULK_GENERATION: 'owl-itemized-receipt-bulk-generation',
  LS_POST_PAYMENT_REFACTOR: 'ls-post-payment-refactor',
  DH_CHARGE_UNITS: 'dh-charge-units',
  LS_OPEN_PAYMENTS_V2: 'ls-open-payments-v2',
  OMEGA_INITIAL_ALLOCATION_PERFORMANCE: 'omega-initial-allocation-performance',
  OMEGA_CHRONOLOGICAL_EFFECTIVE_DATE: 'omega-chronological-effective-date',
  LS_CARD_PAYMENT_BANNER_ERROR: 'ls-card-payment-banner-error',
  LS_PREALLOCATION_FAMILY: 'ls-preallocation-family',
  EGG_SCHEDULING_BUTTON_MENU: 'egg-scheduling-button-menu',
  EGG_USER_AGREEMENT_POPUP: 'egg-user-agreement-popup',
};

export const FEATURE_FLAGS_ON_PRODUCTION = [
  FEATURE_FLAGS.ALLOCATIONS_PATIENT_SEARCH,
  FEATURE_FLAGS.CODE_SEARCH,
  FEATURE_FLAGS.EASY_BILLING_NOTES,
  FEATURE_FLAGS.EGG_CLAIMS_RESOLUTION_DOCUMENTS_TAB,
  FEATURE_FLAGS.ITD_DOO_CLAIM_ERROR_OVERLAY,
  FEATURE_FLAGS.LS_RENAME_CARD_DESCRIPTION,
  FEATURE_FLAGS.PAYMENT_FREQUENCY,
  FEATURE_FLAGS.MANAGE_ENCOUNTER_CLAIM_ERROR_OVERLAY,
  FEATURE_FLAGS.PATIENT_INFO_CLAIM_ERROR_OVERLAY,
  FEATURE_FLAGS.SECONDARY_CLAIMS,
  FEATURE_FLAGS.ZERO_CHARGE_CARE_PACKAGES,
];

export const RCM_FEATURE_FLAGS = [
  FEATURE_FLAGS.RCM_CHANGE_SECONDARY,
  FEATURE_FLAGS.RCM_CLAIM_ACTION_HISTORY,
  FEATURE_FLAGS.RCM_CLAIM_FLAG,
  FEATURE_FLAGS.RCM_INSURANCE_CLAIM_ERROR_OVERLAY,
  FEATURE_FLAGS.RCM_PAYER_CLAIM_ERROR_OVERLAY,
  FEATURE_FLAGS.RCM_QUICK_DELETE_SEARCH,
  FEATURE_FLAGS.RCM_RELEASE_2,
  FEATURE_FLAGS.RCM_SECONDARY_FIELD,
  FEATURE_FLAGS.EGG_CLAIMS_RESOLUTION_DOCUMENTS_TAB,
];

export const INTEGRATION_FEATURE_FLAGS = [];

const lookupPracticeInfo = () =>
  store.getState().practiceInformation.item
    ? store.getState().practiceInformation.item
    : store.getState().practiceInfo.practiceInfoItems[0];

export const hasFeatureOrBeta = async feature => {
  let practiceInfo;

  while (!practiceInfo) {
    practiceInfo = lookupPracticeInfo();

    if (!practiceInfo) await new Promise(resolve => setTimeout(resolve, 200));
  }

  return !!practiceInfo.features && practiceInfo.features.includes(feature);
};

export const supporthasFeatureOrBeta = async (tenantId, feature) => {
  let practiceInfo;

  while (!practiceInfo || practiceInfo === {}) {
    practiceInfo = await getPracticeInformation(tenantId);

    if (practiceInfo === {}) {
      await new Promise(resolve => setTimeout(resolve, 200));
    }
  }

  return !!practiceInfo.features && practiceInfo.features.includes(feature);
};

export const featureGate = directive(
  (featureName, withFeature, withoutFeature = html``) =>
    part => {
      hasFeatureOrBeta(featureName).then(enabled => {
        if (enabled) {
          part.setValue(withFeature);
        } else {
          part.setValue(withoutFeature);
        }

        part.commit();
      });
    },
);

export const getFeatures = async () => {
  let practiceInfo;

  while (!practiceInfo) {
    practiceInfo = lookupPracticeInfo();

    if (!practiceInfo) await new Promise(resolve => setTimeout(resolve, 200));
  }

  return practiceInfo.features || [];
};

export const hasFeatureOrBetaSync = feature => {
  const practiceInfo = lookupPracticeInfo();
  return (
    !!practiceInfo &&
    !!practiceInfo.features &&
    practiceInfo.features.includes(feature)
  );
};

export const hasTierSync = tier => {
  const practiceInfo = lookupPracticeInfo();

  return !!practiceInfo && practiceInfo.tier === tier;
};
