import '../../../../packages/neb-lit-components/src/components/controls/neb-button-action';
import '../../../../packages/neb-lit-components/src/components/neb-unsupported-page';

import '../../filters/neb-filters-ar-hub';
import '../../tabs/neb-tabs-ar-hub';
import { openPopup } from '@neb/popup';
import { css, html, LitElement } from 'lit';

import { getBillingCodesWriteOffs } from '../../../../packages/neb-api-client/src/billing-codes';
import { getLedgerInvoiceItems } from '../../../../packages/neb-api-client/src/invoice-api-client';
import { fetchOne } from '../../../../packages/neb-api-client/src/patient-api-client';
import { getPayerPlans } from '../../../../packages/neb-api-client/src/payer-plan-api-client';
import { getProviderUsers } from '../../../../packages/neb-api-client/src/practice-users-api-client';
import {
  openError,
  openInfo,
  openSuccess,
  openWarning,
} from '../../../../packages/neb-dialog/neb-banner-state';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../../packages/neb-lit-components/src/utils/overlay-constants';
import { POPUP_RENDER_KEYS } from '../../../../packages/neb-popup/src/renderer-keys';
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import { CLAIM_STATUS } from '../../../../packages/neb-utils/claims';
import Debouncer from '../../../../packages/neb-utils/debouncer';
import {
  FEATURE_FLAGS,
  hasFeatureOrBeta,
} from '../../../../packages/neb-utils/feature-util';
import { objToName } from '../../../../packages/neb-utils/formatters';
import { BILL_TYPE_ITEMS } from '../../../../packages/neb-utils/neb-ledger-util';
import {
  getArHubCustomViews,
  getArHubCustomViewsV2,
  getArHubFilters,
  getArHubInvoices,
  getArHubInvoicesV2,
  saveArHubCustomView,
} from '../../../api-clients/ar-hub';
import { getMany as getCharges } from '../../../api-clients/charges';
import { updateArHubFollowUp } from '../../../api-clients/follow-up';
import { getLocations } from '../../../api-clients/locations';
import {
  formatArHubInvoices,
  formatArHubParams,
  formatViewFilters,
  formatViewToSave,
} from '../../../formatters/ar-hub';
import { LINE_ITEM_BILLED_STATUSES } from '../../../formatters/encounter-charge-details';
import {
  CSS_COLOR_WHITE,
  CSS_SMALL_SPACING,
  CSS_SPACING,
} from '../../../styles';
import { getFilteredColumns } from '../../../utils/ar-hub/column-definitions';
import { SORT_DIR } from '../../../utils/sort/sort';
import { MODE } from '../../controls/buttons/neb-button-menu';
import '../../popups/ar-hub/neb-popup-ar-hub-add-follow-up';
import './neb-page-ar-hub-new-custom-view';
import '../../../../packages/neb-material-design/src/components/neb-loading-spinner';

export const ELEMENTS = {
  manageViewsButton: { id: 'manage-views-button' },
  buildNewViewButton: { id: 'build-new-view-button' },
  saveButton: { id: 'save-button' },
  unsupportedPage: { id: 'unsupported-page' },
  customViewPage: { id: 'custom-view-page' },
  filters: { id: 'filters' },
  loadingSpinner: { id: 'loading-spinner' },
  tabs: { id: 'tabs' },
};

export const AR_HUB_RESOLUTION_MESSAGE =
  'You can access AR HUB by resizing your browser to fit the resolution of a desktop/laptop.';

export const AR_HUB_ERROR_MESSAGE =
  'An error occurred while fetching invoices.';

export const AR_HUB_UPDATE_SUCCESS_MESSAGE = 'View saved successfully.';

export const AR_HUB_UPDATE_ERROR_MESSAGE =
  'An error occurred while updating the view.';

export const AR_HUB_CUSTOM_VIEW_ERROR_MESSAGE =
  'An error occurred while fetching views.';

export const AR_HUB_UPDATE_FOLLOW_UP_ERROR_MESSAGE =
  'An error occurred while updating the follow-up.';

export const AR_HUB_FOLLOW_UP_COMPLETED_MESSAGE =
  'Follow-up marked as completed.';

export const AR_HUB_FOLLOW_UP_UNCOMPLETED_MESSAGE =
  'Follow-up marked as incomplete.';

class NebPageArHub extends LitElement {
  static get properties() {
    return {
      __customViews: Array,
      __hasEggOptimizeArHubInvoicesFF: Boolean,
      __isLoading: {
        type: Boolean,
        reflect: true,
      },
      layout: {
        reflect: true,
        type: String,
      },
      showTable: Boolean,
      showNewView: Boolean,
      showBuildNewViewButton: Boolean,
      selectedViews: Array,
      selectedTab: Object,
      tableModel: Array,
      viewOrderBy: String,
      viewSortAsc: Boolean,
      sortParams: Array,
      search: Object,
      providers: Array,
      locations: Array,
      items: Object,
      filteredItems: Object,
      appliedFilters: Object,
      procedureCodes: Array,
      adjustments: Array,
      claimStatuses: Array,
      billTypes: Array,
      billedStatuses: Array,
      isInvoiceOverlayClosed: Boolean,
      selectedColumns: Array,
    };
  }

  static get styles() {
    return css`
      :host {
        height: 100%;
        background-color: ${CSS_COLOR_WHITE};
        display: flex;
        flex-direction: column;
      }

      .container {
        background-color: ${CSS_COLOR_WHITE};
        flex: 1;
        display: flex;
        flex-direction: column;
        min-height: 0;
      }

      .links {
        display: flex;
        padding: ${CSS_SMALL_SPACING};
        gap: ${CSS_SMALL_SPACING};
        align-items: center;
        width: 100%;
        box-sizing: border-box;
        justify-content: space-between;
      }

      .button-container {
        display: flex;
        gap: ${CSS_SMALL_SPACING};
        align-items: center;
        flex-wrap: wrap;
      }

      .custom-view-container {
        position: relative;
        flex: 1;
        display: flex;
        background-color: ${CSS_COLOR_WHITE};
      }

      .loading-container {
        position: absolute;
        inset: 0;
        display: none;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: ${CSS_SPACING};
      }

      .custom-view-page {
        flex: 1;
      }

      :host([__isLoading]) .loading-container {
        display: flex;
      }
    `;
  }

  constructor() {
    super();
    this.__initState();
    this.__initServices();
    this.__initHandlers();
  }

  __initState() {
    this.__customViews = [];
    this.__isLoading = false;
    this.__hasEggOptimizeArHubInvoicesFF = false;
    this.__hasFitInvoiceOverlayPerformanceFF = false;

    this.layout = '';
    this.showTable = false;
    this.showNewView = false;
    this.showBuildNewViewButton = true;
    this.selectedViews = [];
    this.selectedTab = {};
    this.tableModel = [];
    this.viewOrderBy = '';
    this.viewSortAsc = false;
    this.sortParams = [];
    this.providers = [];
    this.locations = [];
    this.payers = [];
    this.procedureCodes = [];
    this.adjustments = [];
    this.claimStatuses = [];
    this.billTypes = [];
    this.billedStatuses = [];
    this.isInvoiceOverlayClosed = false;

    this.search = {
      providers: '',
      locations: '',
      claimStatuses: '',
      payers: '',
      procedureCodes: '',
      adjustments: '',
      billedStatuses: '',
    };

    this.items = {
      providers: [],
      locations: [],
      claimStatuses: [],
      billTypes: [],
      payers: [],
      procedureCodes: [],
      adjustments: [],
      billedStatuses: [],
    };

    this.filteredItems = {
      providers: [],
      locations: [],
      claimStatuses: [],
      billTypes: [],
      payers: [],
      procedureCodes: [],
      adjustments: [],
      billedStatuses: [],
    };

    this.appliedFilters = {
      providers: [],
      locations: [],
      claimStatuses: [],
      billTypes: [],
      billedStatuses: [],
      payers: [],
      procedureCodes: [],
      adjustments: [],
      payerBalance: null,
      patientBalance: null,
      primaryBalance: null,
      secondaryBalance: null,
      adjustmentBalance: null,
      totalBalance: null,
      followUpExists: null,
      followUpDaysUntilDue: null,
      followUpCompleted: null,
    };

    this.selectedColumns = [];
  }

  __initServices() {
    this.__saveDebouncer = new Debouncer(() => this.__updateExistingView());
    this.__saveAsDebouncer = new Debouncer(() => this.__addNewView());
  }

  __initHandlers() {
    this.__handlers = {
      openManageViews: async () => {
        const result = await openOverlay(OVERLAY_KEYS.MANAGE_VIEWS, {
          savedViews: this.__customViews,
        });

        if (result) {
          const { selectedViews, defaultViews, savedViews } = result;

          if (!selectedViews.length) {
            this.selectedViews = [];
            this.__handlers.buildNewView();
            this.__updateViews({ savedViews, defaultViews });
            return;
          }

          try {
            this.showTable = true;

            await this.__setSelectViews(selectedViews);

            this.__updateViews({
              savedViews,
              defaultViews,
            });
          } catch (error) {
            store.dispatch(openError(AR_HUB_ERROR_MESSAGE));
            console.error(error);
          }
        }
      },
      save: async () => {
        if (this.selectedTab.id) {
          await this.__saveDebouncer.debounce();
        } else {
          await this.__saveAsDebouncer.debounce();
        }
      },
      saveAs: async () => {
        await this.__saveAsDebouncer.debounce();
      },
      selectTab: async tab => {
        this.appliedFilters = tab.filters;
        this.__applyCheckedItems();
        this.__setSelectedTab(tab);
        await this.__getInvoices();
      },
      onChangeSort: async (key, dir) => {
        this.viewOrderBy = key;
        this.viewSortAsc = dir === SORT_DIR.ASC;

        this.sortParams = [
          {
            key: this.viewOrderBy,
            dir: this.viewSortAsc ? SORT_DIR.ASC : SORT_DIR.DESC,
          },
        ];

        await this.__getInvoices();
      },
      onClickInvoice: async row => {
        try {
          this.isInvoiceOverlayClosed = false;

          const [result, patient] = await Promise.all([
            getLedgerInvoiceItems(row.invoiceId),
            fetchOne(row.patientId),
          ]);
          const lineItemIds = result.data.map(li => li.id);
          const overlayKey = this.__hasFitInvoiceOverlayPerformanceFF
            ? OVERLAY_KEYS.LEDGER_VIEW_SELECTED_CHARGES_V2
            : OVERLAY_KEYS.LEDGER_VIEW_SELECTED_CHARGES;

          await openOverlay(overlayKey, {
            patient,
            lineItemIds,
            selectedIds: [],
            closeOnSave: true,
          });

          await this.__getInvoices();
          this.isInvoiceOverlayClosed = true;
        } catch (error) {
          console.error('Error opening invoice:', error);
          store.dispatch(openError('Error opening invoice'));
        }
      },
      onClickFollowUp: async () => {
        await this.__getInvoices();
      },
      onCompleteFollowUp: async ({ followUpId, isCompleted, rowIndex }) => {
        try {
          const result = await updateArHubFollowUp(followUpId, {
            isCompleted: !isCompleted,
          });

          if (result.isCompleted) {
            store.dispatch(openSuccess(AR_HUB_FOLLOW_UP_COMPLETED_MESSAGE));
          } else {
            store.dispatch(openInfo(AR_HUB_FOLLOW_UP_UNCOMPLETED_MESSAGE));
          }

          this.tableModel[rowIndex].isFollowUpCompleted = result.isCompleted;
          this.tableModel = [...this.tableModel];
        } catch (error) {
          console.error(error);
          store.dispatch(openError(AR_HUB_UPDATE_FOLLOW_UP_ERROR_MESSAGE));
        }
      },
      applyFilters: filters => {
        this.appliedFilters = filters;

        this.__getInvoices();
      },
      reset: () => {
        this.__resetFilters();
      },
      onFollowUpAdded: async result => {
        if (result) {
          await this.__getInvoices();
        }
      },
      buildNewView: async () => {
        this.showTable = true;
        this.showNewView = true;
        this.showBuildNewViewButton = false;

        this.__resetFilters();
        this.__setSelectedTab({});
        this.__setSortParams({});

        await this.__getInvoices();
      },
      onSetColumns: columns => {
        this.selectedColumns = this.__formatColumns(columns);
      },
    };
  }

  __formatColumns(columns) {
    return columns.map((column, index) => ({
      columnKey: column.key,
      displayOrder: index,
      visible: true,
    }));
  }

  async connectedCallback() {
    super.connectedCallback();

    this.__hasEggOptimizeArHubInvoicesFF = await hasFeatureOrBeta(
      FEATURE_FLAGS.EGG_OPTIMIZE_AR_HUB_INVOICES,
    );

    this.__hasFitInvoiceOverlayPerformanceFF = await hasFeatureOrBeta(
      FEATURE_FLAGS.FIT_INVOICE_OVERLAY_PERFORMANCE,
    );

    await this.__getFiltersData();

    try {
      const customViews = this.__hasEggOptimizeArHubInvoicesFF
        ? await getArHubCustomViewsV2()
        : await getArHubCustomViews();

      if (customViews.length) {
        const defaultViews = customViews.filter(view => view.isDefault);

        this.__customViews = customViews.map(view => ({
          ...view,
          selected: defaultViews.length ? view.isDefault : view.isSystemView,
        }));

        const viewsToSelect = this.__customViews.filter(view => view.selected);

        if (viewsToSelect.length) {
          this.showTable = true;
          await this.__setSelectViews(viewsToSelect);
        }
      }
    } catch (error) {
      store.dispatch(openError(AR_HUB_CUSTOM_VIEW_ERROR_MESSAGE));
      console.error(error);
    }
  }

  async __getFiltersData() {
    const [providers, locations, payers, procedureCodes, adjustments] =
      await Promise.all([
        getProviderUsers(),
        getLocations(),
        getPayerPlans({ hideInactive: true }),
        getCharges(),
        getBillingCodesWriteOffs(),
      ]);

    this.providers = providers;
    this.locations = locations;
    this.payers = payers.payerPlan;
    this.procedureCodes = [
      ...new Map(procedureCodes.map(code => [code.procedure, code])).values(),
    ];

    this.adjustments = adjustments;

    this.claimStatuses = Object.values(CLAIM_STATUS).sort();
    this.billTypes = BILL_TYPE_ITEMS;
    this.billedStatuses = LINE_ITEM_BILLED_STATUSES;

    this.__resetFilters();
  }

  async __setSelectViews(views) {
    this.selectedViews = this.__hasEggOptimizeArHubInvoicesFF
      ? views
      : await this.__setViewFilters(views);

    this.__setSelectedTab(this.selectedViews[0]);

    this.appliedFilters = this.selectedViews[0].filters;

    this.__applyCheckedItems();

    await this.__getInvoices();
  }

  async __setViewFilters(views) {
    const viewIds = views.map(view => view.id);

    const filters = await getArHubFilters({
      viewIds,
    });

    return views.map(view => ({
      ...view,
      filters: formatViewFilters(filters, view.id),
    }));
  }

  async __getInvoices() {
    this.__isLoading = true;

    let params;

    if (this.showTable) {
      try {
        if (this.selectedTab.id) {
          params = formatArHubParams({
            selectedTab: { ...this.selectedTab, filters: this.appliedFilters },
            sortParams: this.sortParams[0],
          });
        } else {
          params = formatArHubParams({
            sortParams: this.sortParams[0],
            filters: this.appliedFilters,
          });
        }

        const invoices = this.__hasEggOptimizeArHubInvoicesFF
          ? await getArHubInvoicesV2(params)
          : await getArHubInvoices(params);

        this.tableModel = formatArHubInvoices(invoices);
      } catch (error) {
        this.tableModel = [];

        if (error.statusCode === 413) {
          store.dispatch(
            openWarning('Too many invoices, please refine your filters'),
          );
        } else {
          store.dispatch(openError(AR_HUB_ERROR_MESSAGE));
          console.error(error);
        }
      } finally {
        this.__isLoading = false;
      }
    }
  }

  async __addNewView() {
    const savedView = await openPopup(POPUP_RENDER_KEYS.AR_HUB_SAVE_NEW_VIEW, {
      viewOrderBy: this.viewOrderBy,
      viewSortAsc: this.viewSortAsc,
      filters: this.appliedFilters,
      columns: this.selectedColumns,
    });

    if (savedView) {
      try {
        this.showNewView = false;

        const [newView] = await this.__setViewFilters([savedView]);

        this.selectedViews.push({ ...newView, columns: this.selectedColumns });
        this.__setSelectedTab(newView);

        this.__customViews = [
          ...this.__customViews,
          { ...newView, selected: true, columns: this.selectedColumns },
        ];

        this.showBuildNewViewButton = true;
        this.__getInvoices();
      } catch (error) {
        store.dispatch(openError(AR_HUB_ERROR_MESSAGE));
        console.error(error);
      }
    }
  }

  async __updateExistingView() {
    try {
      const view = formatViewToSave({
        id: this.selectedTab.id,
        name: this.selectedTab.name,
        description: this.selectedTab.description,
        orderBy: this.viewOrderBy,
        sortAsc: this.viewSortAsc,
        filters: this.appliedFilters,
        columns: this.selectedColumns,
      });

      await saveArHubCustomView(view);

      const selectedViewIndex = this.selectedViews.findIndex(
        v => v.id === this.selectedTab.id,
      );

      if (selectedViewIndex !== -1) {
        this.selectedViews[selectedViewIndex] = {
          ...this.selectedViews[selectedViewIndex],
          orderBy: this.viewOrderBy,
          sortAsc: this.viewSortAsc,
          columns: this.selectedColumns,
        };

        this.selectedViews = await this.__setViewFilters(this.selectedViews);
      }

      store.dispatch(openSuccess(AR_HUB_UPDATE_SUCCESS_MESSAGE));
    } catch (error) {
      store.dispatch(openError(AR_HUB_UPDATE_ERROR_MESSAGE));
      console.error(error);
    }
  }

  __updateViews({ savedViews, defaultViews }) {
    const views = savedViews.map(view => ({
      ...view,
      selected: this.selectedViews.some(
        selectedView => selectedView.id === view.id,
      ),
      isDefault: defaultViews
        ? defaultViews.some(defaultView => defaultView.viewId === view.id)
        : view.isDefault,
    }));

    this.__customViews = views;
  }

  __applyCheckedItems() {
    const {
      providers,
      locations,
      claimStatuses,
      payers,
      billTypes,
      procedureCodes,
      adjustments,
      billedStatuses,
    } = this.items;

    this.items = {
      providers: providers.map(provider => ({
        ...provider,
        checked: this.appliedFilters.providers.includes(provider.value.id),
      })),
      locations: locations.map(location => ({
        ...location,
        checked: this.appliedFilters.locations.includes(location.value.id),
      })),
      claimStatuses: claimStatuses.map(status => ({
        ...status,
        checked: this.appliedFilters.claimStatuses.includes(status.value.id),
      })),
      payers: payers.map(payer => ({
        ...payer,
        checked: this.appliedFilters.payers.includes(payer.value.id),
      })),
      procedureCodes: procedureCodes.map(code => ({
        ...code,
        checked: this.appliedFilters.procedureCodes.includes(code.value.id),
      })),
      adjustments: adjustments.map(adjustment => ({
        ...adjustment,
        checked: this.appliedFilters.adjustments.includes(adjustment.value.id),
      })),
      billedStatuses: billedStatuses.map(status => ({
        ...status,
        checked: this.appliedFilters.billedStatuses.includes(status.value.id),
      })),
      billTypes,
    };
  }

  __resetFilters() {
    const allProviders = this.providers.map(provider => ({
      label: objToName(provider.name),
      value: provider,
      checked: false,
    }));

    const allLocations = this.locations.map(location => ({
      label: location.name,
      value: location,
      checked: false,
    }));

    const allClaimStatuses = this.claimStatuses.map(status => ({
      label: status,
      value: { id: status },
      checked: false,
    }));

    const allPayers = this.payers.map(payer => ({
      label: payer.alias,
      value: payer,
      checked: false,
    }));

    const allProcedureCodes = this.procedureCodes.map(code => ({
      label: code.procedure,
      value: { id: code.procedure },
      checked: false,
    }));

    const allAdjustments = this.adjustments.map(adjustment => ({
      label: `${adjustment.codeGroup} - ${adjustment.code}`,
      value: adjustment,
      checked: false,
    }));

    const allBillTypes = this.billTypes.map(billType => ({
      label: billType.label,
      value: billType.data,
    }));

    const allBilledStatuses = this.billedStatuses.map(billedStatus => ({
      label: billedStatus.label,
      value: {
        id: billedStatus.value,
      },
      checked: false,
    }));

    this.items = {
      providers: this.__addNoItemOption('Provider', allProviders),
      locations: this.__addNoItemOption('Location', allLocations),
      billedStatuses: this.__addNoItemOption(
        'Billed Status',
        allBilledStatuses,
      ),
      claimStatuses: allClaimStatuses,
      payers: allPayers,
      procedureCodes: allProcedureCodes,
      adjustments: allAdjustments,
      billTypes: allBillTypes,
    };

    this.appliedFilters = {
      providers: [],
      locations: [],
      claimStatuses: [],
      billTypes: [],
      payers: [],
      procedureCodes: [],
      adjustments: [],
      billedStatuses: [],
      dosFrom: null,
      dosTo: null,
      daysAged: null,
      payerBalance: null,
      patientBalance: null,
      primaryBalance: null,
      secondaryBalance: null,
      adjustmentBalance: null,
      totalBalance: null,
      followUpExists: null,
      followUpDaysUntilDue: null,
      followUpCompleted: null,
    };
  }

  __addNoItemOption(label, items) {
    return [
      {
        label: `No ${label}`,
        value: {
          id: null,
        },
        checked: false,
      },
      ...items,
    ];
  }

  __setSelectedTab(selectedTab) {
    this.selectedTab = selectedTab;
    this.__setSortParams(selectedTab);

    this.selectedColumns = selectedTab.columns?.length
      ? selectedTab.columns
      : this.__formatColumns(getFilteredColumns());
  }

  __setSortParams(selectedTab) {
    let dir = '';

    if (selectedTab.id) {
      dir = selectedTab.sortAsc ? SORT_DIR.ASC : SORT_DIR.DESC;
    }

    this.sortParams = [
      {
        key: selectedTab?.orderBy || '',
        dir,
      },
    ];
  }

  __getSaveItems() {
    const { isSystemView } = this.selectedTab;

    return [
      {
        label: 'SAVE',
        icon: 'neb:save',
        truncate: false,
        disabled: isSystemView,
        onClick: this.__handlers.save,
      },
      {
        label: 'SAVE AS',
        icon: 'neb:save',
        truncate: false,
        disabled: false,
        onClick: this.__handlers.saveAs,
      },
    ];
  }

  __renderUnsupportedPage() {
    return html`
      <neb-unsupported-page
        id="${ELEMENTS.unsupportedPage.id}"
        .layout="${this.layout}"
        icon="wallet"
        location="AR Hub"
        specificMessage="${AR_HUB_RESOLUTION_MESSAGE}"
      ></neb-unsupported-page>
    `;
  }

  __renderLoadingSpinner() {
    return this.__isLoading
      ? html`
          <div id="${ELEMENTS.loadingSpinner.id}" class="loading-container">
            <neb-loading-spinner></neb-loading-spinner>
            <div>Loading</div>
          </div>
        `
      : '';
  }

  __renderCustomViewPage() {
    return this.showTable
      ? html`
          <div class="custom-view-container">
            ${this.__renderLoadingSpinner()}
            <neb-page-ar-hub-new-custom-view
              id="${ELEMENTS.customViewPage.id}"
              class="custom-view-page"
              style="${this.__isLoading ? 'visibility: hidden' : ''}"
              .model="${this.tableModel}"
              .columns="${this.selectedColumns}"
              .selectedTab="${this.selectedTab}"
              .onChangeSort="${this.__handlers.onChangeSort}"
              .onClickInvoice="${this.__handlers.onClickInvoice}"
              .onFollowUpAdded="${this.__handlers.onFollowUpAdded}"
              .onClickFollowUp="${this.__handlers.onClickFollowUp}"
              .onCompleteFollowUp="${this.__handlers.onCompleteFollowUp}"
              .sortParams="${this.sortParams}"
              .isInvoiceOverlayClosed="${this.isInvoiceOverlayClosed}"
              .onSetColumns="${this.__handlers.onSetColumns}"
            ></neb-page-ar-hub-new-custom-view>
          </div>
        `
      : '';
  }

  __renderButtonLinks() {
    return html`
      <div class="button-container">
        ${this.__renderSaveButton()} ${this.__renderBuildNewViewButton()}
        ${this.__renderManageViewsButton()}
      </div>
    `;
  }

  __renderSaveButton() {
    return this.showNewView || this.selectedViews.length
      ? html`
          <neb-button-menu
            id="${ELEMENTS.saveButton.id}"
            .items="${this.__getSaveItems()}"
            .mode="${MODE.DROPDOWN_MENU}"
          ></neb-button-menu>
        `
      : '';
  }

  __renderBuildNewViewButton() {
    return this.showBuildNewViewButton
      ? html`
          <neb-button-action
            id="${ELEMENTS.buildNewViewButton.id}"
            class="button"
            label="Build New View"
            .onClick="${this.__handlers.buildNewView}"
          ></neb-button-action>
        `
      : '';
  }

  __renderManageViewsButton() {
    return html`
      <neb-button-action
        id="${ELEMENTS.manageViewsButton.id}"
        label="Manage Views"
        leadingIcon="batch"
        .onClick="${this.__handlers.openManageViews}"
      ></neb-button-action>
    `;
  }

  __renderTabs() {
    return html`
      <neb-tabs-ar-hub
        id="${ELEMENTS.tabs.id}"
        .selectedViews="${this.selectedViews}"
        .selectedTab="${this.selectedTab}"
        .showNewView="${this.showNewView}"
        .onSelectTab="${this.__handlers.selectTab}"
        .onBuildNewView="${this.__handlers.buildNewView}"
      ></neb-tabs-ar-hub>
    `;
  }

  __renderLinks() {
    return html`
      <div class="links">
        <div class="tabs-group">${this.__renderTabs()}</div>
        ${this.__renderButtonLinks()}
      </div>
    `;
  }

  __renderFilters() {
    return html`
      <neb-filters-ar-hub
        id="${ELEMENTS.filters.id}"
        .search="${this.search}"
        .items="${this.items}"
        .appliedFilters="${this.appliedFilters}"
        .onApply="${this.__handlers.applyFilters}"
        .onReset="${this.__handlers.reset}"
      ></neb-filters-ar-hub>
    `;
  }

  render() {
    return this.layout !== 'large'
      ? html` ${this.__renderUnsupportedPage()} `
      : html` <div class="container">
          ${this.__renderLinks()} ${this.__renderFilters()}
          ${this.__renderCustomViewPage()}
        </div>`;
  }
}

customElements.define('neb-page-ar-hub', NebPageArHub);
