import { openPopup } from '@neb/popup';
import { css, html } from 'lit';

import {
  openError,
  openSuccess,
} from '../../../../../packages/neb-dialog/neb-banner-state';
import Overlay from '../../../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import { POPUP_RENDER_KEYS } from '../../../../../packages/neb-popup/src/renderer-keys';
import { store } from '../../../../../packages/neb-redux/neb-redux-store';
import { moveItem } from '../../../../../packages/neb-utils/utils';
import { saveArHubDefaultView } from '../../../../api-clients/ar-hub';
import { CSS_SPACING, CSS_COLOR_GREY_1 } from '../../../../styles';

import '../../../../../packages/neb-lit-components/src/components/neb-action-bar';
import '../../../../../packages/neb-lit-components/src/components/neb-popup-header';
import '../../../../../packages/neb-lit-components/src/components/controls/neb-button-action';
import '../../../../../packages/neb-lit-components/src/components/tables/neb-table';

export const ELEMENTS = {
  header: { id: 'header' },
  table: { id: 'table' },
  actionBar: { id: 'action-bar' },
};

export const AR_HUB_CUSTOM_VIEWS_TABLE_CONFIG = [
  {
    key: 'name',
    label: '',
    flex: css`1 0 0`,
    formatter: (value, row) => html`
      <div>
        <neb-text bold>${row.name}</neb-text>
        <div>${row.description}</div>
      </div>
    `,
  },
  {
    key: 'isDefault',
    label: '',
    flex: css`0 0 100px`,
    formatter: value =>
      value
        ? html`
            <neb-text part="default-text" style="font-style: italic;"
              >default</neb-text
            >
          `
        : html``,
  },
];

class NebOverlayManageViews extends Overlay {
  static get properties() {
    return {
      __disableSetAsDefaultButton: { type: Boolean },
      savedViews: { type: Array },
      selectedViews: { type: Array },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          width: 500px;
        }

        .header {
          padding: ${CSS_SPACING};
        }

        .button {
          padding: 0 0 ${CSS_SPACING} ${CSS_SPACING};
        }

        ::part(default-text) {
          color: ${CSS_COLOR_GREY_1};
        }

        neb-table::part(cell-data) {
          padding: ${CSS_SPACING};
        }

        neb-table::part(row-header) {
          display: none;
        }

        neb-table {
          overflow-y: auto;
        }
      `,
    ];
  }

  constructor() {
    super();
    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    super.initState();

    this.__disableSetAsDefaultButton = true;
    this.savedViews = [];
    this.model = { savedViews: [] };
  }

  __initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      reorder: (fromIndex, toIndex) => {
        this.savedViews = moveItem(this.savedViews, fromIndex, toIndex);
        this.__updateSetAsDefaultButtonState();
      },
      selectCheckbox: (_, index) => {
        const newSavedViews = [...this.savedViews];
        const currentView = newSavedViews[index];

        newSavedViews[index] = {
          ...currentView,
          selected: !currentView.selected,
        };

        this.savedViews = newSavedViews;
        this.__updateSetAsDefaultButtonState();
      },
      apply: () =>
        this.dismiss({
          selectedViews: this.__getSelectedViewsItems(),
          savedViews: this.savedViews,
        }),
      setDefaultView: async () => {
        const confirmOverride = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
          title: 'Overriding current default view',
          message:
            'Are you sure you want to override the current default view?',
          confirmText: 'Yes',
          cancelText: 'No',
        });

        if (!confirmOverride) return;

        const viewIds = this.__getSelectedViewsItems().map(item => item.id);

        try {
          const { message, defaultViews } = await saveArHubDefaultView({
            viewIds,
          });

          store.dispatch(openSuccess(message));

          this.dismiss({
            defaultViews,
            selectedViews: this.__getSelectedViewsItems(),
            savedViews: this.savedViews,
          });
        } catch (error) {
          console.error(error);
          store.dispatch(openError('Error saving default view'));
        }
      },
    };
  }

  update(changedProps) {
    if (changedProps.has('model')) {
      this.savedViews = this.model.savedViews;
      this.__updateSetAsDefaultButtonState();
    }
    super.update(changedProps);
  }

  __updateSetAsDefaultButtonState() {
    const hasSelectedNonDefault = this.savedViews.some(
      view => view.selected && !view.isDefault,
    );

    const hasUnselectedDefault = this.savedViews.some(
      view => !view.selected && view.isDefault,
    );

    const hasReorderedDefaults = this.savedViews.some(
      (view, index) =>
        view.isDefault && this.model.savedViews[index]?.id !== view.id,
    );

    this.__disableSetAsDefaultButton = !(
      hasSelectedNonDefault ||
      hasUnselectedDefault ||
      hasReorderedDefaults
    );
  }

  __getSelectedViews() {
    return this.savedViews.map(item => item.selected);
  }

  __getSelectedViewsItems() {
    return this.savedViews.filter(item => item.selected);
  }

  __renderHeader() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        title="Manage Views"
        subTitle="Choose which views to display in the AR HUB."
        .onCancel="${this.handlers.dismiss}"
        showCancelButton
      ></neb-popup-header>
    `;
  }

  renderContent() {
    return html`
      ${this.__renderHeader()}
      <neb-table
        id="${ELEMENTS.table.id}"
        .config="${AR_HUB_CUSTOM_VIEWS_TABLE_CONFIG}"
        .model="${this.savedViews}"
        .reorder="${true}"
        .onReorder="${this.handlers.reorder}"
        .showSelectAll="${true}"
        .onSelectCheckbox="${this.handlers.selectCheckbox}"
        .selectedItems="${this.__getSelectedViews()}"
      ></neb-table>
      <neb-action-bar
        id="${ELEMENTS.actionBar.id}"
        confirmLabel="Apply"
        removeLabel="Save As Default"
        .removeDisabled="${this.__disableSetAsDefaultButton}"
        .onConfirm="${this.handlers.apply}"
        .onCancel="${this.handlers.dismiss}"
        .onRemove="${this.handlers.setDefaultView}"
      ></neb-action-bar>
    `;
  }
}

customElements.define('neb-overlay-manage-views', NebOverlayManageViews);
