/* eslint-disable complexity */
import { css, html, LitElement } from 'lit';

import { layoutStyles } from '../../../../../src/styles';
import '../controls/neb-switch';
import '../inputs/neb-textfield';
import '../neb-header';
import '../neb-radio-button';
import '../patients/neb-patient-card';
import '../inputs/neb-select-search';
import '../neb-tooltip';
import '../neb-date-picker';
import '../inputs/neb-select';
import { formatPersonForPatientCard } from '../../../../neb-api-client/src/formatters/person';
import { parseDate } from '../../../../neb-utils/date-util';
import {
  INSURED_RELATIONSHIP,
  POLICY_HOLDER_ITEMS,
} from '../../../../neb-utils/patientInsurance';
import { EMPTY_POLICY_HOLDER } from '../../../../neb-utils/policyHolder';
import { addLabelToPerson } from '../guarantor/guarantor-util';
import * as FORM_DATA from '../patients/neb-patient-profile-data';

export const ELEMENTS = {
  dobPicker: { id: 'date-picker-dob' },
  textfieldFirstName: { id: 'textfield-first-name' },
  textfieldLastName: { id: 'textfield-last-name' },
  textfieldMiddleName: { id: 'textfield-middle-name' },
  textfieldMemberId: { id: 'textfield-member-id' },
  tooltipMemberId: { id: 'tooltip-member-id' },
  textMemberId: { id: 'text-member-id' },
  dropdownRelation: { id: 'dropdown-insured-relationship' },
  selectPerson: { id: 'select-person' },
  selectSex: { id: 'select-sex' },
  textfieldSuffix: { id: 'textfield-suffix' },
  textfieldInsuredName: { id: 'textfield-insured-name' },
};

const SELECT_SEARCH_ITEM_HEIGHT = 84;
const NO_RESULTS_FOUND = 'No results found';

class ViewPolicyHolderInfo extends LitElement {
  static get properties() {
    return {
      errors: Object,
      model: Object,
      selectedPerson: Object,
      searchResults: Array,
      isWorkersComp: Boolean,
      showResults: Boolean,
    };
  }

  static get styles() {
    return [
      layoutStyles,
      css`
        .tooltip {
          margin-bottom: 4px;
        }

        .relation {
          grid-column-start: 1;
          grid-column-end: 2;
        }

        .person {
          grid-column-start: 2;
          grid-column-end: 4;
        }

        .date-picker {
          width: auto;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.errors = {
      insuredRelationship: '',
      planInfo: {
        memberIdentifier: '',
      },
      policyHolder: {
        id: '',
        firstName: '',
        lastName: '',
        middleName: '',
        sex: '',
        suffix: '',
        insuredName: '',
        dateOfBirth: '',
      },
    };

    this.model = {
      insuredRelationship: '',
      planInfo: {
        memberIdentifier: '',
      },
      policyHolder: EMPTY_POLICY_HOLDER,
    };

    this.searchResults = [];
    this.selectedPerson = null;
    this.showResults = true;
    this.isWorkersComp = false;

    this.onChange = () => {};

    this.onChangeDate = () => {};

    this.onSearchPerson = () => {};

    this.onSelectPerson = () => {};

    this.onSelfRelation = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      change: e => this.onChange(e),

      changeDate: e => this.onChangeDate(e),

      selectPerson: ({ value }) => {
        if (value !== NO_RESULTS_FOUND) this.onSelectPerson(value || null);
      },

      selectRelation: e => {
        if (this.model.insuredRelationship === 'Self' && e.value !== 'Self') {
          this.onSelectPerson(null);
        } else if (
          this.model.insuredRelationship !== 'Self' &&
          e.value === 'Self'
        ) {
          this.onSelfRelation();
        }

        this.onChange(e);
      },

      searchPerson: e => this.onSearchPerson(e),

      dateSelectable: date => parseDate(date).isBefore(parseDate()),
    };
  }

  __isPersonSelected() {
    return (
      this.model.insuredRelationship !== INSURED_RELATIONSHIP.Self &&
      this.selectedPerson !== null
    );
  }

  __isSelfSelected() {
    return this.model.insuredRelationship === INSURED_RELATIONSHIP.Self;
  }

  __renderInsuredName() {
    return this.isWorkersComp
      ? html`
          <neb-textfield
            id="${ELEMENTS.textfieldInsuredName.id}"
            class="span"
            name="policyHolder.insuredName"
            label="Insured Name"
            helper="Required"
            .error="${this.errors.policyHolder.insuredName}"
            .value="${this.model.policyHolder.insuredName || ''}"
            .onChange="${this.__handlers.change}"
          ></neb-textfield>
        `
      : '';
  }

  renderItem(model) {
    const patientModel = formatPersonForPatientCard(model);

    return html`
      <neb-patient-card
        class="list-item"
        .model="${patientModel}"
        hidePhoto
      ></neb-patient-card>
    `;
  }

  render() {
    const selfSelected = this.__isSelfSelected();
    const disablePatient = selfSelected || this.__isPersonSelected();

    return html`
      <div class="grid spacer-bottom">
        <div class="grid grid-3 grid-lean">
          <div class="grid grid-auto-right">
            <neb-textfield
              id="${ELEMENTS.textfieldMemberId.id}"
              name="planInfo.memberIdentifier"
              label="Insured ID or Policy #"
              helper="Required"
              .error="${this.errors.planInfo.memberIdentifier}"
              .value="${this.model.planInfo.memberIdentifier}"
              .onChange="${this.__handlers.change}"
            ></neb-textfield>

            <neb-tooltip
              id="${ELEMENTS.tooltipMemberId.id}"
              class="tooltip"
              defaultAnchor="right"
            >
              <p id="${ELEMENTS.textMemberId.id}" slot="tooltip">
                Enter the insured's policy # or member ID that identifies the
                policyholder.
              </p>
            </neb-tooltip>
          </div>
        </div>

        ${this.__renderInsuredName()}

        <div class="grid grid-3 grid-lean">
          <neb-select
            id="${ELEMENTS.dropdownRelation.id}"
            class="relation"
            name="insuredRelationship"
            label="Policyholder Relation to Patient"
            helper=" "
            .items="${POLICY_HOLDER_ITEMS}"
            .value="${this.model.insuredRelationship}"
            .onChange="${this.__handlers.selectRelation}"
          ></neb-select>

          <neb-select-search
            id="${ELEMENTS.selectPerson.id}"
            label="Search for an existing patient or guarantor"
            class="person"
            emptyMessage="${NO_RESULTS_FOUND}"
            helper=" "
            .showMenu="${this.showResults}"
            .value="${this.selectedPerson
              ? addLabelToPerson(this.selectedPerson)
              : ''}"
            .items="${this.searchResults}"
            .onChange="${this.__handlers.selectPerson}"
            .onSearch="${this.__handlers.searchPerson}"
            .onRenderItem="${this.renderItem}"
            .itemHeight="${SELECT_SEARCH_ITEM_HEIGHT}"
            ?disabled="${selfSelected}"
            showSearch
          ></neb-select-search>
        </div>

        <div class="grid grid-3 grid-lean">
          <neb-textfield
            id="${ELEMENTS.textfieldFirstName.id}"
            name="policyHolder.firstName"
            label="First Name"
            helper="Required"
            .error="${this.errors.policyHolder.firstName}"
            .value="${this.model.policyHolder.firstName}"
            .onChange="${this.__handlers.change}"
            ?disabled="${disablePatient}"
          ></neb-textfield>

          <neb-textfield
            id="${ELEMENTS.textfieldMiddleName.id}"
            name="policyHolder.middleName"
            helper=" "
            label="Middle Name"
            .value="${this.model.policyHolder.middleName || ''}"
            .onChange="${this.__handlers.change}"
            ?disabled="${disablePatient}"
          ></neb-textfield>

          <neb-textfield
            id="${ELEMENTS.textfieldLastName.id}"
            name="policyHolder.lastName"
            label="Last Name"
            helper="Required"
            .error="${this.errors.policyHolder.lastName}"
            .value="${this.model.policyHolder.lastName}"
            .onChange="${this.__handlers.change}"
            ?disabled="${disablePatient}"
          ></neb-textfield>
        </div>

        <div class="grid grid-3 grid-lean">
          <neb-textfield
            id="${ELEMENTS.textfieldSuffix.id}"
            name="policyHolder.suffix"
            label="Suffix"
            helper=" "
            maxLength="10"
            .value="${this.model.policyHolder.suffix}"
            .onChange="${this.__handlers.change}"
            ?disabled="${disablePatient}"
          ></neb-textfield>

          <neb-date-picker
            id="${ELEMENTS.dobPicker.id}"
            class="date-picker"
            label="Date of Birth"
            name="policyHolder.dateOfBirth"
            placeholder="Select Date"
            manualPopoverPosition="center"
            momentFlag
            .isDateSelectable="${this.__handlers.dateSelectable}"
            .selectedDate="${this.model.policyHolder.dateOfBirth
              ? parseDate(this.model.policyHolder.dateOfBirth)
              : null}"
            .onClick="${this.__handlers.changeDate}"
            .onClear="${this.__handlers.changeDate}"
            .disabled="${disablePatient}"
          ></neb-date-picker>

          <neb-select
            id="${ELEMENTS.selectSex.id}"
            name="policyHolder.sex"
            class="field"
            label="Sex"
            helper=" "
            .items="${FORM_DATA.SEX_ALLOW_EMPTY}"
            .value="${this.model.policyHolder.sex}"
            .onChange="${this.__handlers.change}"
            ?disabled="${disablePatient}"
          ></neb-select>
        </div>
      </div>
    `;
  }
}

window.customElements.define(
  'neb-view-policy-holder-info',
  ViewPolicyHolderInfo,
);
