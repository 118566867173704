import { Method } from '../../packages/neb-api-client/src/utils/api-client-utils';
import ApiClientV2 from '../../packages/neb-api-client/src/utils/api-client-v2';

export const billingApiClient = new ApiClientV2({ microservice: 'billing' });

export const saveArHubFollowUps = async ({
  followUpDate,
  description,
  parentIds,
  parentType,
  optOutLoadingIndicator = true,
}) => {
  const { data } = await billingApiClient.makeRequest({
    optOutLoadingIndicator,
    method: Method.POST,
    body: { followUpDate, description, parentIds, parentType },
    path: '/api/v1/tenants/:tenantId/follow-up',
    version: 1,
  });

  return data[0];
};

export const updateArHubFollowUp = async (
  id,
  body,
  optOutLoadingIndicator = true,
) => {
  const result = await billingApiClient.makeRequest({
    optOutLoadingIndicator,
    method: Method.PUT,
    body,
    path: `/api/v1/tenants/:tenantId/follow-up/${id}`,
    version: 1,
  });

  return result;
};
