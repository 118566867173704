import DeprecatedApiClient, {
  Method,
} from '../../packages/neb-api-client/src/utils/api-client-utils';
import { centsToCurrency } from '../../packages/neb-utils/formatters';

import { formatVendorToModel } from './vendor';

export const apiClient = new DeprecatedApiClient({ microservice: 'billing' });

const INVENTORY_BASE_URL = 'inventory';
const LOW_INVENTORY_MAX_CHARACTERS = 112;

export const API_VERSION = 1;

export const HEADERS = { 'Content-Type': 'application/json' };

export const createModel = () => ({
  id: '',
  vendorId: '',
  name: '',
  active: true,
  quantity: '',
  reorderAt: '',
  upc: '',
  website: '',
  notes: '',
});

const formatInventoryChargesToModel = inventoryCharges => {
  if (inventoryCharges) {
    return inventoryCharges
      .map(ic => ({
        id: ic.id,
        chargeId: ic.chargeId,
        charge: {
          amount: centsToCurrency(ic.charge.amount),
          description: ic.charge.description,
          procedure: ic.charge.procedure,
          modifiers: ic.charge.modifiers,
          availableForPurchase: ic.charge.availableForPurchase,
          units: ic.charge.units,
        },
      }))
      .sort((a, b) => a.charge.procedure.localeCompare(b.charge.procedure));
  }

  return [];
};

const formatInventoryChargeCodesToModel = inventoryChargeCodes => {
  if (inventoryChargeCodes) {
    return inventoryChargeCodes
      .map(ic => ({
        id: ic.id,
        code: ic.code,
        codeCharge: {
          description: ic.codeCharge ? ic.codeCharge.description : '',
          code: ic.code,
        },
      }))
      .sort((a, b) => a.code.localeCompare(b.code));
  }

  return [];
};

export const formatInventoryItemToModel = item => ({
  id: item.id,
  name: item.name,
  active: item.active,
  quantity: item.quantity,
  reorderAt: item.reorderAt === null ? '' : `${item.reorderAt}`,
  upc: item.upc || '',
  notes: item.notes || '',
  website: item.website || '',
  vendorId: item.vendorId,
  vendor: formatVendorToModel(item.vendor),
  inventoryCharges: formatInventoryChargesToModel(item.inventoryCharges),
  inventoryChargeCodes: formatInventoryChargeCodesToModel(
    item.inventoryChargeCodes,
  ),
});

export const formatInventoryToModel = items =>
  items ? items.map(item => formatInventoryItemToModel(item)) : [];

export const getInventory = async (
  queryParams = {},
  optOutLoadingIndicator = false,
) => {
  const inventory = await apiClient.makeRequest({
    method: Method.GET,
    path: INVENTORY_BASE_URL,
    queryParams,
    headers: HEADERS,
    version: API_VERSION,
    cacheKey: `inventory-${JSON.stringify(queryParams)}`,
    optOutLoadingIndicator,
  });

  return formatInventoryToModel(inventory);
};

export const getInventoryItem = async (id, optOutLoadingIndicator = false) => {
  const inventory = await apiClient.makeRequest({
    method: Method.GET,
    path: `${INVENTORY_BASE_URL}/${id}`,
    headers: HEADERS,
    version: API_VERSION,
    optOutLoadingIndicator,
  });

  return formatInventoryItemToModel(inventory);
};

export const getInventoryExists = (name, optOutLoadingIndicator = false) =>
  apiClient.makeRequest({
    method: Method.POST,
    path: `${INVENTORY_BASE_URL}/exists`,
    body: JSON.stringify({ name }),
    headers: HEADERS,
    version: API_VERSION,
    optOutLoadingIndicator,
  });

export const getInventoryCount = (
  queryParams = {},
  optOutLoadingIndicator = false,
) =>
  apiClient.makeRequest({
    method: Method.GET,
    path: `${INVENTORY_BASE_URL}/count`,
    queryParams,
    headers: HEADERS,
    version: API_VERSION,
    cacheKey: `inventory-count-${JSON.stringify(queryParams)}`,
    optOutLoadingIndicator,
  });

const buildChargeIdsQueryString = chargeIds =>
  chargeIds && chargeIds.length
    ? `?${chargeIds.map(id => `chargeIds[]=${id}`).join('&')}`
    : '';

const buildCodesQueryString = (codes, previous) =>
  codes && codes.length
    ? `${previous}${previous.length ? '&' : '?'}${codes
        .map(id => `codes[]=${id}`)
        .join('&')}`
    : previous;

const buildQueryString = args => {
  const { chargeIds, codes } = args;

  const chargeIdsString = buildChargeIdsQueryString(chargeIds);

  return buildCodesQueryString(codes, chargeIdsString);
};

export const getLowInventory = (args, optOutLoadingIndicator = false) =>
  apiClient.makeRequest({
    method: Method.GET,
    path: `${INVENTORY_BASE_URL}/low${buildQueryString(args)}`,
    headers: HEADERS,
    version: API_VERSION,
    optOutLoadingIndicator,
  });

const isChargeIdsEmpty = chargeIds =>
  !chargeIds || (chargeIds && chargeIds.length === 0);

const isCodesEmpty = codes => !codes || (codes && codes.length === 0);

export const getLowInventoryMessage = async ({ chargeIds, codes }) => {
  if (isChargeIdsEmpty(chargeIds) && isCodesEmpty(codes)) return '';

  const lowInventory = await getLowInventory({ chargeIds, codes });

  const lowInventoryNames = lowInventory.reduce((memo, item, idx) => {
    if (idx === 0) {
      memo = item.name;
    } else if (idx === lowInventory.length - 1) {
      memo += `, and ${item.name}`;
    } else {
      memo += `, ${item.name}`;
    }

    return memo;
  }, '');

  if (lowInventoryNames.length) {
    let message = `Inventory is low for ${lowInventoryNames}`;

    if (message.length > LOW_INVENTORY_MAX_CHARACTERS) {
      message = `${message.substring(0, LOW_INVENTORY_MAX_CHARACTERS)}...`;
    }

    return message;
  }

  return '';
};

export const createInventory = async body => {
  const result = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.POST,
    path: INVENTORY_BASE_URL,
    headers: HEADERS,
    body: JSON.stringify(body),
    version: API_VERSION,
  });

  return formatInventoryItemToModel(result);
};

export const updateInventory = async body => {
  const result = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.PUT,
    path: `${INVENTORY_BASE_URL}/${body.id}`,
    headers: HEADERS,
    body: JSON.stringify(body),
    version: API_VERSION,
  });

  return formatInventoryItemToModel(result);
};
