import { LitElement, html, css } from 'lit';

import { layoutStyles } from '../../styles';
import { parse } from '../../utils/document-parser';

import '../../../packages/neb-lit-components/src/components/controls/neb-button-action';

import './neb-structured-document-header';

export const ELEMENTS = {
  header: { id: 'header' },
  questions: { selector: '[id^=question-]', class: 'question' },
  answers: { selector: '[id^=answer-]', class: 'answer' },
  copyButtons: { selector: '[id^=button-]', class: 'button' },
};

class NebStructuredDocumentViewer extends LitElement {
  static get properties() {
    return {
      __questionAnswerPairs: Array,

      document: Object,
      hideUnanswered: Boolean,
      isSOAPTabSelected: Boolean,
    };
  }

  static get styles() {
    return [
      layoutStyles,
      css`
        .container {
          padding: 20px;
        }

        .text {
          font-size: 16px;
          font-family: sans-serif, Arial, Verdana, 'Trebuchet MS',
            'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
        }

        .question {
          font-weight: bold;
        }

        .answer {
          padding: 5px 0 10px 10px;
        }

        .pair {
          padding: 0 0 30px 0;
        }

        .answer-table,
        th,
        td {
          border: 1px solid black;
          text-align: center;
          border-collapse: collapse;
          margin: 5px 0 10px 10px;
        }

        th,
        td,
        tr {
          padding: 6px;
        }

        tr:first-child,
        td:first-child {
          font-weight: bold;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.initState();
    this.initHandlers();
  }

  initState() {
    this.__questionAnswerPairs = [];

    this.document = {};
    this.hideUnanswered = false;
    this.isSOAPTabSelected = false;

    this.onCopy = () => {};
  }

  initHandlers() {
    this.handlers = {
      copyOne: ({ name }) => {
        this.onCopy([this.__questionAnswerPairs[name]]);
      },

      copyAll: () => {
        this.onCopy(this.__questionAnswerPairs);
      },
    };
  }

  updated(changedProps) {
    if (changedProps.has('document') || changedProps.has('hideUnanswered')) {
      try {
        this.__questionAnswerPairs = parse(
          this.document.documentData,
          this.hideUnanswered,
        );
      } catch (e) {
        this.__questionAnswerPairs = [];
      }
    }
  }

  renderHeader() {
    return html`
      <neb-structured-document-header
        id="${ELEMENTS.header.id}"
        copyButtonLabel="Copy All to Chart Notes"
        .document="${this.document}"
        .isSOAPTabSelected="${this.isSOAPTabSelected}"
        .onCopy="${this.handlers.copyAll}"
      ></neb-structured-document-header>
    `;
  }

  renderAnswer(answer, idx) {
    return answer && typeof answer === 'object'
      ? html`
          <div id="answer-${idx}" class="answer text">
            ${answer.map(subAns => html` ${subAns}<br /> `)}
          </div>
        `
      : html` <div id="answer-${idx}" class="answer text">${answer}</div> `;
  }

  renderTable(answer, idx) {
    const nonTableAnswers = answer.filter(ans => !Array.isArray(ans));

    return html`
      <table class="answer-table text">
        ${answer
          .filter(a => Array.isArray(a))
          .map(
            ans => html`
              ${html`
                <tr id="answer-${idx}" class="answer text">
                  ${ans.map(
                    subAns => html`
                      <td>
                        ${Array.isArray(subAns)
                          ? subAns.map(an => html` ${an} <br /> `)
                          : html` ${subAns} `}
                      </td>
                    `,
                  )}
                </tr>
              `}
            `,
          )}
      </table>
      ${nonTableAnswers ? this.renderAnswer(nonTableAnswers, idx) : null}
    `;
  }

  renderItems() {
    return this.__questionAnswerPairs.map(
      ({ question, answer }, idx) => html`
        <div class="pair">
          <div id="question-${idx}" class="question text">${question}</div>
          ${Array.isArray(answer[0])
            ? this.renderTable(answer, idx)
            : this.renderAnswer(answer, idx)}

          <neb-button-action
            id="button-${idx}"
            class="button"
            name="${idx}"
            label="Copy to Chart Notes"
            .onClick="${this.handlers.copyOne}"
            .disabled="${this.isSOAPTabSelected}"
          ></neb-button-action>
        </div>
      `,
    );
  }

  render() {
    return html`
      <div class="container">${this.renderHeader()} ${this.renderItems()}</div>
    `;
  }
}

customElements.define(
  'neb-structured-document-viewer',
  NebStructuredDocumentViewer,
);
