import { html } from 'lit';

import { PAGE_SIZE } from '../../../../../src/components/forms/settings/neb-form-update-fee-schedule-billing-code';

import Table from './neb-table';

export const ELEMENTS = {
  patientAdjustmentCodeSelects: {
    selector: '[id^=patient-adjustment-code-select-]',
  },
  payerAdjustmentCodeSelects: {
    selector: '[id^=payer-adjustment-code-select-]',
  },
};

class NebTableUpdateFeeScheduleBillingCode extends Table {
  static get properties() {
    return {
      patientAdjustmentCodes: Array,
      payerAdjustmentCodes: Array,
      errors: Object,
      currentPageIndex: Number,
    };
  }

  initState() {
    super.initState();

    this.patientAdjustmentCodes = [];
    this.payerAdjustmentCodes = [];
    this.errors = {};
    this.currentPageIndex = 0;
    this.name = 'feeSchedules';
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      changeCode: e => {
        this.onChange({
          name: `${this.name}.${e.name}`,
          value: e.value,
        });
      },
    };
  }

  __renderPatientCodeSelect(value, rowIndex) {
    const globalIndex = rowIndex + this.currentPageIndex * PAGE_SIZE;

    return html`
      <neb-select
        id="patient-adjustment-code-select-${globalIndex}"
        class="select"
        label="Patient Adjustment Code"
        name="${rowIndex}.patientAdjustmentCodeId"
        helper="Required"
        .items="${this.patientAdjustmentCodes}"
        .value="${value}"
        .error="${this.errors.feeSchedules[globalIndex]
          .patientAdjustmentCodeId}"
        .onChange="${this.handlers.changeCode}"
      ></neb-select>
    `;
  }

  __renderPayerCodeSelect(value, rowIndex) {
    const globalIndex = rowIndex + this.currentPageIndex * PAGE_SIZE;

    return html`
      <neb-select
        id="payer-adjustment-code-select-${globalIndex}"
        class="select"
        label="Payer Adjustment Code"
        name="${rowIndex}.payerAdjustmentCodeId"
        helper="Required"
        .items="${this.payerAdjustmentCodes}"
        .value="${value}"
        .error="${this.errors.feeSchedules[globalIndex].payerAdjustmentCodeId}"
        .onChange="${this.handlers.changeCode}"
      ></neb-select>
    `;
  }

  renderDataCell(value, columnConfig, rowIndex) {
    switch (columnConfig.key) {
      case 'patientAdjustmentCodeId':
        return this.__renderPatientCodeSelect(value, rowIndex);
      case 'payerAdjustmentCodeId':
        return this.__renderPayerCodeSelect(value, rowIndex);
      default:
        return super.renderDataCell(value, columnConfig, rowIndex);
    }
  }
}

window.customElements.define(
  'neb-table-update-fee-schedule-billing-code',
  NebTableUpdateFeeScheduleBillingCode,
);
