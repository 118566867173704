import '../../../forms/ledger/payments/neb-form-split-payment';
import '../../../../../packages/neb-lit-components/src/components/controls/neb-button-bar';
import '../../../../../packages/neb-lit-components/src/components/neb-popup-header';

import { html, css } from 'lit';

import {
  fetchSplits,
  splitPayment,
} from '../../../../../packages/neb-api-client/src/payments-api-client';
import Overlay from '../../../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import { LocationsService } from '../../../../../packages/neb-redux/services/locations';
import {
  NEBULA_REFRESH_EVENT,
  REFRESH_CHANGE_TYPE,
} from '../../../../../packages/neb-utils/neb-refresh';
import { CSS_SPACING } from '../../../../styles';

export const ELEMENTS = {
  header: { id: 'header' },
  form: { id: 'form' },
};

class NebOverlaySplitPayment extends Overlay {
  static get properties() {
    return {
      __hasSplits: Boolean,
      __history: Array,
      __formModel: Object,
      __parentPayment: Object,
      __locationsService: Object,
      __locations: Array,
      model: Object,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          width: 100%;
        }

        .header {
          padding: ${CSS_SPACING};
        }

        .form {
          overflow-y: scroll;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.initServices();
  }

  initState() {
    super.initState();

    this.__hasSplits = false;

    this.__formModel = {
      splits: [
        {
          patientId: '',
          amount: 0,
        },
      ],
    };

    this.__parentPayment = {
      id: '',
      patientId: '',
      allocations: [],
      paymentId: '',
      amount: 0,
      credits: [{ amount: 0 }],
    };

    this.__history = [];
    this.__locations = [];

    this.model = {
      payment: {
        id: '',
        patientId: '',
        allocations: [],
        paymentId: '',
        amount: 0,
        credits: [{ amount: 0 }],
      },
    };
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      split: async (model, claims) => {
        try {
          const res = await splitPayment({
            splits: model.splits,
            paymentId: this.__hasSplits
              ? model.splits[0].id
              : this.__parentPayment.id,
            claims,
          });

          const splits = res.children.filter(c => !c.voidedAt);

          this.__formModel = {
            splits,
          };

          this.__parentPayment = {
            ...res.parent,
            paymentNumber: this.__parentPayment.paymentNumber,
          };

          this.__history = res.history;

          this.__hasSplits = true;

          const refreshEvent = new CustomEvent(NEBULA_REFRESH_EVENT, {
            detail: {
              changed: [REFRESH_CHANGE_TYPE.LEDGER],
            },
          });
          window.dispatchEvent(refreshEvent);

          return true;
        } catch (e) {
          console.error(e);

          return false;
        }
      },
      refetchSplits: () => this.__fetchSplits(),
    };
  }

  initServices() {
    this.__locationsService = new LocationsService(({ locations }) => {
      this.__locations = locations;
    });
  }

  connectedCallback() {
    super.connectedCallback();
    this.__locationsService.connect();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.__locationsService.disconnect();
  }

  __getNonSplitPaymentFormModel() {
    const { payment } = this.model;
    const preserve = payment.amount - payment.available;
    return {
      splits: [
        {
          patientId: payment.patientId || '',
          payerPlanId: payment.payerPlanId || '',
          amount: preserve,
          status: payment.status || '',
          preserve,
          progenitor: true,
          paymentNumber: payment.paymentNumber,
        },
      ],
    };
  }

  async __fetchSplits() {
    const splitData = await fetchSplits(this.model.payment.id, {
      includeHistory: true,
      includeRefundPayment: true,
    });

    this.__history = splitData.history ? splitData.history : [];

    if (splitData.children.length) {
      this.__hasSplits = true;
      const splits = splitData.children.filter(c => !c.voidedAt);

      this.__formModel = {
        splits,
      };

      this.__parentPayment = {
        ...splitData.parent,
        locationId: splits[0].locationId,
      };
    } else {
      this.__hasSplits = false;
      this.__formModel = this.__getNonSplitPaymentFormModel();

      this.__parentPayment = { ...this.model.payment };
    }
  }

  async updated(changed) {
    if (
      changed.has('model') &&
      this.model &&
      this.model.payment &&
      this.model.payment.id
    ) {
      await this.__fetchSplits();
    }
  }

  __renderHeader() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        showCancelButton
        title="Split Payment"
        .onCancel="${this.handlers.dismiss}"
      ></neb-popup-header>
    `;
  }

  renderContent() {
    return html`
      ${this.__renderHeader()}
      <neb-form-split-payment
        id="${ELEMENTS.form.id}"
        class="form"
        .parentPayment="${this.__parentPayment}"
        .model="${this.__formModel}"
        .fromEraEobPage="${this.model.fromEraEobPage}"
        .locations="${this.__locations}"
        .hasSplits="${this.__hasSplits}"
        .onSave="${this.handlers.split}"
        .onDismiss="${this.handlers.dismiss}"
        .onChangeDirty="${this.handlers.dirty}"
        .history="${this.__history}"
        .onRefetchSplits="${this.handlers.refetchSplits}"
      ></neb-form-split-payment>
    `;
  }
}

customElements.define('neb-overlay-split-payment', NebOverlaySplitPayment);
