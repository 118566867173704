import { Method } from '../../packages/neb-api-client/src/utils/api-client-utils';
import ApiClientV2 from '../../packages/neb-api-client/src/utils/api-client-v2';

export const apiClient = new ApiClientV2({ microservice: 'billing' });

export const getNotificationsCount = async () => {
  const { count } = await apiClient.makeRequest({
    method: Method.GET,
    path: '/api/v1/tenants/:tenantId/notifications/count',
  });

  return count;
};
