import { LitElement, html, css } from 'lit';

import { CSS_COLOR_BLUE_1 } from '../../../../packages/neb-lit-components/src/components/neb-button';
import {
  baseStyles,
  CSS_COLOR_WHITE,
  CSS_FONT_FAMILY,
  CSS_FONT_SIZE_BODY,
  CSS_FONT_WEIGHT_BOLD,
  CSS_SMALL_SPACING,
} from '../../../styles';
import '../../misc/neb-icon';

export const MODE = {
  ACTION_MENU: 'action-menu',
  DROPDOWN_MENU: 'dropdown-menu',
};

export const ELEMENTS = {
  leadingIcon: { id: 'leading-icon' },
  expandIcon: { id: 'expand-icon' },
  primaryButton: { id: 'primary-button' },
  primaryButtonLabel: { id: 'primary-button-label' },
  buttonItem: { selector: '[id^=button-item-]' },
  buttonItemLabel: { selector: '[id^=button-item-label]' },
  buttonContainer: { id: 'button-container' },
  buttonExpand: { id: 'button-expand' },
  container: { id: 'container' },
  menuItems: { id: 'menu-items' },
};

class NebButtonMenu extends LitElement {
  static get properties() {
    return {
      __open: {
        reflect: true,
        type: Boolean,
      },
      items: {
        type: Array,
      },
      mode: {
        type: String,
        reflect: true,
      },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: inline-block;
          min-width: 106.5px;
          position: relative;
        }

        .container {
          position: relative;
          width: 100%;
        }

        .button-container {
          display: flex;
          align-items: stretch;
          height: 36px;
          width: 100%;
        }

        .button {
          display: flex;
          align-items: center;
          height: 36px;
          padding: 0;
          outline: none;
          border: none;
          background-color: ${CSS_COLOR_BLUE_1};
          overflow: hidden;
          position: relative;
          white-space: nowrap;
        }

        .button-primary {
          flex: 1;
          min-width: 0;
          width: 100%;
          border-radius: 18px 0 0 18px;
        }

        .button-expand {
          flex: 0 0 36px;
          border-radius: 0 18px 18px 0;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: ${CSS_COLOR_BLUE_1};
        }

        .button-item,
        .button-last {
          border-top: 1px solid ${CSS_COLOR_WHITE};
          width: 100%;
        }

        .button-last {
          border-radius: 0 0 18px 18px;
        }

        .button-content {
          display: flex;
          align-items: center;
          padding-left: 10px;
          width: 100%;
        }

        .icon-container {
          display: none;
          width: 20px;
          height: 20px;
          align-items: center;
          justify-content: center;
        }

        .icon-container:has(neb-icon) {
          display: flex;
        }

        .icon {
          width: 20px;
          height: 20px;
          fill: ${CSS_COLOR_WHITE};
        }

        .leading-icon {
          flex-shrink: 0;
        }

        .trailing-icon {
          height: 13px;
          width: 13px;
          margin: 5px 10px;
          transform: rotate(0);
          transition: transform 100ms;
        }

        .label {
          position: relative;
          padding: 0 ${CSS_SMALL_SPACING};
          color: ${CSS_COLOR_WHITE};
          font-size: ${CSS_FONT_SIZE_BODY};
          font-family: ${CSS_FONT_FAMILY};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          text-align: center;
        }

        .truncate {
          max-width: 100px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }

        :host([mode='action-menu'])
          .button-container:hover:not(:has(.disabled)) {
          cursor: pointer;
          opacity: 0.8;
        }

        :host([mode='dropdown-menu']) .button:hover:not(.disabled),
        :host([mode='dropdown-menu']) .button-expand:hover,
        .button-item:hover:not(.disabled),
        .button-last:hover:not(.disabled) {
          cursor: pointer;
          opacity: 0.8;
        }

        :host([mode='dropdown-menu']) .button-expand {
          border-left: 1px solid ${CSS_COLOR_WHITE};
        }

        :host([__open]) {
          .button-primary {
            border-radius: 18px 0 0 0;
          }
          .button-expand {
            border-radius: 0 18px 0 0;
          }
          .trailing-icon {
            transform: rotate(180deg);
          }
        }

        .menu-items {
          position: absolute;
          top: 100%;
          left: 0;
          width: 100%;
          z-index: 1000;
          display: flex;
          flex-direction: column;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.items = [];
    this.mode = MODE.DROPDOWN_MENU;

    this.__open = false;
  }

  __initHandlers() {
    this.__handlers = {
      expand: () => {
        this.__open = !this.__open;
      },
      mouseEnter: () => {
        this.__open = true;
      },
      mouseLeave: () => {
        this.__open = false;
      },
      onClickMenuItem: async item => {
        if (item.disabled) return;
        await item.onClick();
      },
    };
  }

  __renderLabel(item, isPrimary, index) {
    const { label, truncate } = item;

    return html`
      <span
        id="${isPrimary
          ? ELEMENTS.primaryButtonLabel.id
          : `button-item-label-${index}`}"
        class="label ${truncate ? 'truncate' : ''}"
      >
        ${label}
      </span>
    `;
  }

  __renderLeadingIcon(icon) {
    return html`
      <div id="${ELEMENTS.leadingIcon.id}" class="icon-container">
        ${icon
          ? html` <neb-icon class="icon leading-icon" icon=${icon}></neb-icon> `
          : ''}
      </div>
    `;
  }

  __renderExpandIcon() {
    return html`
      <neb-icon
        id="${ELEMENTS.expandIcon.id}"
        class="icon trailing-icon"
        icon="neb:chevron"
      ></neb-icon>
    `;
  }

  __renderPrimaryButton() {
    return html`
      <div
        id="${ELEMENTS.buttonContainer.id}"
        class="button-container"
        @mouseenter="${this.mode === MODE.ACTION_MENU
          ? this.__handlers.mouseEnter
          : null}"
      >
        <button
          id="${ELEMENTS.primaryButton.id}"
          class="button button-primary ${this.items[0].disabled
            ? 'disabled'
            : ''}"
          @click="${() =>
            this.mode === MODE.DROPDOWN_MENU
              ? this.__handlers.onClickMenuItem(this.items[0])
              : null}"
        >
          <div class="button-content">
            ${this.__renderLeadingIcon(this.items[0].icon)}
            ${this.__renderLabel(this.items[0], true)}
          </div>
        </button>
        <button
          id="${ELEMENTS.buttonExpand.id}"
          class="button-expand"
          @click="${this.__handlers.expand}"
          @mouseenter="${this.__handlers.mouseEnter}"
        >
          ${this.__renderExpandIcon()}
        </button>
      </div>
    `;
  }

  __renderMenu() {
    if (this.__open) {
      const items = this.items.slice(1);
      const len = items.length;
      const menu = [];

      items.forEach((item, index) => {
        const isLast = index === len - 1;

        menu.push(html`
          <button
            id="button-item-${index}"
            class="button ${isLast
              ? 'button-last'
              : 'button-item'} ${item.disabled ? 'disabled' : ''}"
            @click="${() => this.__handlers.onClickMenuItem(item)}"
            ?disabled="${item.disabled}"
          >
            <div class="button-content">
              ${this.__renderLeadingIcon(item.icon)}
              ${this.__renderLabel(item, false, index)}
            </div>
          </button>
        `);
      });

      return html`<div id="${ELEMENTS.menuItems.id}" class="menu-items">
        ${menu}
      </div>`;
    }

    return '';
  }

  render() {
    return html`
      <div
        id="${ELEMENTS.container.id}"
        class="container"
        @mouseleave="${this.__handlers.mouseLeave}"
      >
        ${this.__renderPrimaryButton()} ${this.__renderMenu()}
      </div>
    `;
  }
}

customElements.define('neb-button-menu', NebButtonMenu);
