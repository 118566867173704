import '../../../../packages/neb-lit-components/src/components/inputs/neb-menu';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-textbox';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-text-helper';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-floating-label';
import '../../misc/neb-icon';
import '../field-groups/neb-pill';
import './neb-checkbox';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../packages/neb-styles/neb-styles';
import {
  CSS_FIELD_MARGIN,
  CSS_FONT_SIZE_BODY,
  CSS_SPACING,
  CSS_COLOR_HIGHLIGHT,
} from '../../../../packages/neb-styles/neb-variables';
import { CSS_COLOR_BLUE_BORDER, CSS_COLOR_GREY_1 } from '../../../styles';

export const DISPLAY_MODES = {
  PILLS: 'pills',
  MENU: 'menu',
};

export const ELEMENTS = {
  label: { id: 'label' },
  textbox: { id: 'textbox' },
  input: { id: 'input' },
  menu: { id: 'menu' },
  helper: { id: 'helper' },
  pills: { selector: '.pill' },
  leadingIcon: { id: 'leading-icon' },
  trailingIcon: { id: 'trailing-icon' },
};

class SelectSearchMulti extends LitElement {
  static get properties() {
    return {
      __open: {
        reflect: true,
        type: Boolean,
        attribute: 'open',
      },
      __focused: {
        reflect: true,
        type: Boolean,
        attribute: 'focused',
      },
      disabled: {
        reflect: true,
        type: Boolean,
      },
      pinLabel: {
        reflect: true,
        type: Boolean,
      },
      label: {
        reflect: true,
        type: String,
      },
      maxLength: Number,
      maxVisibleItems: Number,
      itemHeight: Number,
      thresholdCount: Number,
      name: String,
      search: String,
      helper: String,
      error: Boolean || String,
      placeholder: String,
      items: Array,
      value: Array,
      onRenderItem: Function,
      onRenderPill: Function,
      scrollOnKeydown: Boolean,
      displayMode: String,
      totalItems: Number,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: inline-block;
          height: 100px;
          --max-pill-width: 120px;
        }

        .container {
          position: relative;
          width: 100%;
          height: 100%;
          padding-top: ${CSS_FIELD_MARGIN};
        }

        :host([label='']) .container {
          padding-top: 0;
        }

        .textbox {
          position: relative;
          height: 100%;
          min-height: 0;
          max-height: 80px;
          cursor: pointer;
          padding: 0;
        }

        :host([disabled]) .textbox {
          pointer-events: none;
        }

        .pills {
          display: flex;
          overflow-y: auto;
          padding: 8px 12px;
          width: 100%;
          flex-wrap: wrap;
          align-items: baseline;
        }

        :host([focused]) .pills {
          padding: 7px 11px;
        }

        :host([focused]) .icon {
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .pill {
          margin-right: 4px;
          margin-bottom: 4px;
          --max-width: var(--max-pill-width);
        }

        .input {
          border: none;
          padding: 0 ${CSS_SPACING} 0 0;
          width: fit-content;
          min-width: 48px;
          font-size: ${CSS_FONT_SIZE_BODY};
          flex: 1 0 0;
        }

        .menu {
          left: 0;
        }

        .icon {
          fill: ${CSS_COLOR_GREY_1};
        }

        .icon-leading {
          height: 20px;
          width: 20px;
          margin-right: 12px;
        }

        .icon-trailing {
          cursor: pointer;
          height: 10px;
          width: 10px;
          transition:
            transform 0.2s ease,
            fill 0.2s ease;
        }

        :host([open]) .icon-trailing {
          transform: rotate(180deg);
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .search {
          display: flex;
          align-items: center;
          padding: 0 10px;
          width: 100%;
        }

        .input-search {
          border: none;
          caret-color: ${CSS_COLOR_BLUE_BORDER};
          flex: 1;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
    this.__preventFocus = false;
  }

  __initState() {
    this.__open = false;
    this.__focused = false;

    this.disabled = false;
    this.pinLabel = false;
    this.maxVisibleItems = 4;
    this.maxLength = 255;
    this.itemHeight = 48;
    this.thresholdCount = 4;
    this.name = '';
    this.search = '';
    this.label = '';
    this.helper = '';
    this.error = '';
    this.placeholder = '';
    this.items = [];
    this.value = [];
    this.scrollOnKeydown = true;
    this.displayMode = '';
    this.focusFlag = false;
    this.totalItems = 0;

    this.__iconClick = false;

    this.onChange = () => {};

    this.onSearch = () => {};

    this.onRequest = () => {};

    this.onRenderItem = null;

    this.onRenderPill = null;
  }

  __initHandlers() {
    this.__handlers = {
      request: () => this.onRequest(),
      focus: () => {
        if (!this.disabled) {
          this.__focused = true;
          this.__open = true;
          this.focusFlag = false;
        }
      },
      closeMenu: () => {
        if (!this.disabled && !this.__iconClick) {
          this.__focused = false;
          this.__open = false;
        }

        if (this.__iconClick) {
          this.__iconClick = false;
        }
      },
      click: e => {
        this.__toggleMenu(e);
      },
      keydown: e => {
        if (!this.disabled) {
          if (!this.__focused && (e.key === 'Enter' || e.key === ' ')) {
            setTimeout(() => {
              this.__focused = true;
            });
          }

          if (e.key === 'Escape') {
            this.__focused = false;
          }

          if (e.key === 'Backspace' && this.value.length && !this.search) {
            const index = this.value.length - 1;
            const value = [...this.value];
            value.splice(index, 1);

            this.onChange({
              name: this.name,
              value,
            });
          }
        }
      },
      input: e => {
        this.onSearch({
          name: this.name,
          value: e.currentTarget.value,
        });
      },
      select: index => {
        const item = this.items[index];

        let value = [];

        if (item) {
          value = [...this.value, item];

          if (this.displayMode === DISPLAY_MODES.MENU) {
            value = this.__setCheckboxValues(item, index);
          }
          this.__updateValueAndSearch(value);
        }
      },
      remove: e => {
        e.stopPropagation();

        const index = Number(e.currentTarget.index);
        const value = [...this.value];
        value.splice(index, 1);

        this.__updateValueAndSearch(value);
      },
      clickIcon: e => {
        this.__iconClick = !this.__iconClick;
        this.__toggleMenu(e);
      },
    };
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('click', this.__handlers.closeMenu);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('click', this.__handlers.closeMenu);
  }

  __toggleMenu(e) {
    e.stopPropagation();

    if (!this.disabled) {
      if (this.__focused && this.focusFlag) {
        this.__focused = false;
        this.__open = false;
      } else {
        this.__focused = true;
        this.__inputEl.focus();
      }
    }

    if (this.__iconClick && this.__open) {
      this.__open = false;
    }

    this.focusFlag = true;
  }

  __updateValueAndSearch(value) {
    this.onChange({
      name: this.name,
      value,
    });

    this.onSearch({
      name: this.name,
      value: this.displayMode === DISPLAY_MODES.MENU ? this.search : '',
    });
  }

  __getNonSelectAllItems() {
    return this.items.filter(item => item.value !== 'select-all');
  }

  __setSelectAllValue() {
    const allItems = this.items;
    const selectAllIndex = allItems.findIndex(
      itemVal => itemVal.value === 'select-all',
    );

    if (selectAllIndex !== -1) {
      allItems[selectAllIndex].checked = this.__getNonSelectAllItems().every(
        itemVal => itemVal.checked,
      );

      this.items = [...allItems];
    }
  }

  __setCheckboxValues(item, index) {
    const allItems = this.items;
    const isSelectAllAction = item.value === 'select-all';

    if (isSelectAllAction) {
      const newCheckedState = !allItems[index].checked;
      allItems.forEach(itemValue => {
        itemValue.checked = newCheckedState;
      });
    } else {
      allItems[index].checked = !allItems[index].checked;
    }

    this.items = [...allItems];

    return this.__getNonSelectAllItems().filter(itemVal => itemVal.checked);
  }

  isPinned() {
    return (
      this.value.length ||
      this.__focused ||
      this.search ||
      this.pinLabel ||
      this.displayMode === DISPLAY_MODES.MENU
    );
  }

  getLabel() {
    const useAsterisk = this.label.trim() && this.helper === 'Required';

    return useAsterisk ? `${this.label}*` : this.label;
  }

  getHelperText() {
    if (this.error !== '') {
      switch (typeof this.error) {
        case 'boolean':
          return this.error ? this.helper : '';

        case 'string':
          return this.error;

        default:
          return this.helper;
      }
    }

    return this.helper;
  }

  getPlaceholder() {
    if (this.displayMode === DISPLAY_MODES.MENU && this.search === '') {
      const nonSelectAllItems = this.__getNonSelectAllItems();
      const selected = nonSelectAllItems.filter(item => item.checked).length;
      this.placeholder = `${selected} of ${this.totalItems} selected`;
    }

    const show =
      this.placeholder.length > 0 &&
      (this.label.trim().length === 0 || this.isPinned());

    return show ? this.placeholder : '';
  }

  __addSelectAllItem() {
    if (this.displayMode === DISPLAY_MODES.MENU && this.search === '') {
      const hasSelectAll = this.items.some(item => item.value === 'select-all');

      if (!hasSelectAll) {
        this.items = [
          {
            label: 'Select All',
            value: 'select-all',
            checked: false,
          },
          ...this.items,
        ];

        this.__setSelectAllValue();
      }
    }
  }

  __removeSelectAllItem() {
    if (this.search && this.displayMode === DISPLAY_MODES.MENU) {
      const hasSelectAllItem = this.items.find(
        item => item.value === 'select-all',
      );

      if (hasSelectAllItem) {
        this.items = this.items.filter(item => item.value !== 'select-all');
      }
    }
  }

  firstUpdated() {
    this.__inputEl = this.shadowRoot.getElementById(ELEMENTS.input.id);
  }

  update(changedProps) {
    if (changedProps.has('__focused')) {
      this.__open = this.__focused;
    }

    if (changedProps.has('items')) {
      this.__addSelectAllItem();
    }

    if (changedProps.has('search')) {
      this.__removeSelectAllItem();
    }

    super.update(changedProps);
  }

  __renderCheckboxItems(item) {
    return html`
      <div class="checkbox-item">
        <neb-checkbox .checked="${item.checked}"></neb-checkbox>
        <p>${item.label}</p>
      </div>
    `;
  }

  __renderInputWithPills() {
    return html`
      <div class="pills">
        ${this.value.map((item, index) => this.renderPill(item, index))}

        <input
          id="${ELEMENTS.input.id}"
          class="input"
          type="text"
          maxlength="${this.maxLength}"
          autocomplete="off"
          .value="${this.search}"
          .placeholder="${this.getPlaceholder()}"
          @focus="${this.__handlers.focus}"
          @input="${this.__handlers.input}"
        />
      </div>
    `;
  }

  __renderInputWithIcons() {
    return html`
      <div class="search">
        <neb-icon
          id="${ELEMENTS.leadingIcon.id}"
          class="icon icon-leading"
          icon="neb:search"
        ></neb-icon>

        <input
          id="${ELEMENTS.input.id}"
          class="input-search"
          type="text"
          maxlength="${this.maxLength}"
          autocomplete="off"
          .value="${this.search}"
          .placeholder="${this.getPlaceholder()}"
          @focus="${this.__handlers.focus}"
          @blur="${this.__handlers.closeMenu}"
          @input="${this.__handlers.input}"
        />
        <neb-icon
          id="${ELEMENTS.trailingIcon.id}"
          class="icon icon-trailing"
          icon="neb:arrow"
          @click="${this.__handlers.clickIcon}"
        ></neb-icon>
      </div>
    `;
  }

  renderPill(item, index) {
    const backgroundColor = item.data.color
      ? `--background-color: ${item.data.color}`
      : '';

    return this.onRenderPill
      ? this.onRenderPill(item, index)
      : html`
          <neb-pill
            class="pill"
            style="${backgroundColor}"
            .index="${index}"
            ?disabled="${this.disabled}"
            @click="${this.__handlers.remove}"
            >${item.label}</neb-pill
          >
        `;
  }

  renderLabel() {
    return this.label
      ? html`
          <neb-floating-label
            id="${ELEMENTS.label.id}"
            text="${this.getLabel()}"
            ?focused="${this.__focused}"
            ?invalid="${this.__invalid}"
            ?pinned="${this.isPinned()}"
            ?disabled="${this.disabled}"
          ></neb-floating-label>
        `
      : '';
  }

  render() {
    return html`
      <div class="container">
        <neb-textbox
          id="${ELEMENTS.textbox.id}"
          class="textbox"
          ?focused="${this.__focused}"
          ?invalid="${Boolean(this.error)}"
          ?disabled="${this.disabled}"
          @click="${this.__handlers.click}"
          @keydown="${this.__handlers.keydown}"
        >
          ${this.displayMode === DISPLAY_MODES.PILLS
            ? this.__renderInputWithPills()
            : this.__renderInputWithIcons()}
          <neb-menu
            id="${ELEMENTS.menu.id}"
            class="menu"
            .maxVisibleItems="${this.maxVisibleItems}"
            .thresholdCount="${this.thresholdCount}"
            .itemHeight="${this.itemHeight}"
            .items="${this.items}"
            .onChange="${this.__handlers.select}"
            .onRequest="${this.__handlers.request}"
            .onRenderItem="${this.displayMode === DISPLAY_MODES.MENU
              ? this.__renderCheckboxItems.bind(this)
              : this.onRenderItem}"
            .scrollOnKeydown="${this.scrollOnKeydown}"
            ?open="${this.__open}"
            selectSearchMulti
          ></neb-menu>
        </neb-textbox>

        ${this.renderLabel()}

        <neb-text-helper
          id="${ELEMENTS.helper.id}"
          .text="${this.getHelperText()}"
          ?invalid="${Boolean(this.error)}"
          ?disabled="${this.disabled}"
        ></neb-text-helper>
      </div>
    `;
  }
}

window.customElements.define('neb-select-search-multi', SelectSearchMulti);
