/* eslint-disable complexity */
export function formatBilled({
  billedPrimary,
  billedSecondary,
  billedWaiting,
  billedPaymentReceived,
  billedPaidInFull,
}) {
  const billed = [];

  if (billedWaiting) {
    billed.push('W');
  }

  if (billedPrimary) {
    billed.push('BP');
  }

  if (billedSecondary) {
    billed.push('BS');
  }

  if (billedPaymentReceived) {
    billed.push('PR');
  }

  if (billedPaidInFull) {
    billed.push('PD');
  }

  return billed.length > 3
    ? `${billed.slice(0, 3).join(', ')}...`
    : billed.join(', ');
}

export function getLatestBilledStatus({
  billedPrimary,
  billedSecondary,
  billedWaiting,
  billedPaymentReceived,
  billedPaidInFull,
}) {
  let billed = '';

  if (billedWaiting) {
    billed = 'W';
  }

  if (billedPrimary) {
    billed = 'BP';
  }

  if (billedSecondary) {
    billed = 'BS';
  }

  if (billedPaymentReceived) {
    billed = 'PR';
  }

  if (billedPaidInFull) {
    billed = 'PD';
  }

  return billed;
}

export function formatHold({ holdClaim, holdStatement, holdSuperBill }) {
  const hold = [];

  if (holdStatement) {
    hold.push('ST');
  }

  if (holdSuperBill) {
    hold.push('SB');
  }

  if (holdClaim) {
    hold.push('C');
  }

  return hold.join(', ');
}
