import { formatAdjustmentAmount } from '../../packages/neb-lit-components/src/components/patients/ledger/charges/neb-ledger-charges-util';
import { parseDate } from '../../packages/neb-utils/date-util';
import {
  centsToCurrency,
  currencyToCents,
  formattedGreaterThanToInt,
  objToName,
  intGreaterThanToFormatted,
  centsToCurrencyWithNegative,
  currencyToCentsWithNegative,
} from '../../packages/neb-utils/formatters';
import { SORT_DIR } from '../utils/sort/sort';

import { formatBilled } from './line-items';
import { formatBillType } from './payer-information';

const DEFAULT_LIMIT = 15;

const formatServiceDate = ({ min, max }) => {
  const formattedMax = parseDate(max).format('MM/DD/YYYY');
  const formattedMin = parseDate(min).format('MM/DD/YYYY');

  return formattedMax === formattedMin
    ? formattedMax
    : `${formattedMin} - ${formattedMax}`;
};

export const formatTotals = totals => ({
  billedAmount: centsToCurrency(totals.billedAmount),
  allowedAmount: centsToCurrency(totals.allowedAmount),
  adjustmentAmount: formatAdjustmentAmount(totals.adjustmentAmount),
  payerBalance: centsToCurrency(totals.payerBalance),
  patientBalance: centsToCurrency(totals.patientBalance),
  balance: centsToCurrency(totals.balance),
});

export const formatBalancesForParams = ({
  payerBalance,
  patientBalance,
  primaryBalance,
  secondaryBalance,
  adjustmentBalance,
  totalBalance,
}) => ({
  payerBalance: payerBalance ? currencyToCents(payerBalance) : null,
  patientBalance: patientBalance ? currencyToCents(patientBalance) : null,
  primaryBalance: primaryBalance ? currencyToCents(primaryBalance) : null,
  secondaryBalance: secondaryBalance ? currencyToCents(secondaryBalance) : null,
  adjustmentBalance: adjustmentBalance
    ? currencyToCentsWithNegative(adjustmentBalance)
    : null,
  totalBalance: totalBalance ? currencyToCentsWithNegative(totalBalance) : null,
});

export const formatBalancesForFilters = ({
  payerBalance,
  patientBalance,
  primaryBalance,
  secondaryBalance,
  adjustmentBalance,
  totalBalance,
}) => ({
  payerBalance: payerBalance ? centsToCurrency(payerBalance) : null,
  patientBalance: patientBalance ? centsToCurrency(patientBalance) : null,
  primaryBalance: primaryBalance ? centsToCurrency(primaryBalance) : null,
  secondaryBalance: secondaryBalance ? centsToCurrency(secondaryBalance) : null,
  adjustmentBalance: adjustmentBalance
    ? centsToCurrencyWithNegative(adjustmentBalance)
    : null,
  totalBalance: totalBalance ? centsToCurrencyWithNegative(totalBalance) : null,
});

export function formatArHubInvoices(invoices) {
  return invoices.map(invoice => {
    const billedStatus = {
      billedPaidInFull: invoice.billedPaidInFull,
      billedPaymentReceived: invoice.billedPaymentReceived,
      billedPrimary: invoice.billedPrimary,
      billedSecondary: invoice.billedSecondary,
      billedWaiting: invoice.billedWaiting,
    };

    const dateRanges = {
      max: invoice.dateOfServiceMax,
      min: invoice.dateOfServiceMin,
    };

    return {
      adjustmentAmount: invoice.adjustmentAmount,
      allowedAmount: invoice.allowedAmount,
      balance: invoice.balance,
      billType: formatBillType(invoice.billType),
      billedAmount: invoice.billedAmount,
      billedStatus: formatBilled(billedStatus),
      claimStatus: invoice.claimStatus,
      serviceDate: formatServiceDate(dateRanges),
      patient: objToName(
        { first: invoice.firstName, last: invoice.lastName },
        { reverse: true },
      ),
      invoiceNumber: invoice.invoiceNumber,
      invoiceId: invoice.invoiceId,
      patientId: invoice.patientId,
      lastBilled: invoice.lastBilled
        ? parseDate(invoice.lastBilled).format('MM/DD/YYYY')
        : null,
      patientBalance: invoice.patientBalance,
      payerBalance: invoice.payerBalance,
      primaryPayer: invoice.primaryPayer,
      secondaryPayer: invoice.secondaryPayer,
      followUpId: invoice.followUpId,
      followUpDate: invoice.followUpDate
        ? parseDate(invoice.followUpDate).format('MM/DD/YYYY')
        : null,
      followUpDescription: invoice.followUpDescription,
      followUpDateAndDescription: invoice.followUpDate
        ? `${parseDate(invoice.followUpDate).format('MM/DD/YYYY')} - ${invoice.followUpDescription}`
        : null,
      isFollowUpCompleted: !!invoice.isFollowUpCompleted,
    };
  });
}

export function formatArHubParams({ selectedTab, sortParams, filters }) {
  if (selectedTab) {
    return {
      limit: null,
      sortAsc: sortParams?.key
        ? sortParams.dir === SORT_DIR.ASC
        : selectedTab.sortAsc,
      orderBy: sortParams?.key || selectedTab.orderBy,
      providerIds: selectedTab.filters.providers,
      locationIds: selectedTab.filters.locations,
      dosFrom: selectedTab.filters.dosFrom,
      dosTo: selectedTab.filters.dosTo,
      daysAged: formattedGreaterThanToInt(selectedTab.filters.daysAged),
      claimStatuses: selectedTab.filters.claimStatuses,
      billTypes: selectedTab.filters.billTypes,
      payerIds: selectedTab.filters.payers,
      billedStatuses: selectedTab.filters.billedStatuses,
      procedureCodes: selectedTab.filters.procedureCodes,
      adjustmentIds: selectedTab.filters.adjustments,
      followUpExists: selectedTab.filters.followUpExists,
      followUpDaysUntilDue: selectedTab.filters.followUpDaysUntilDue
        ? parseInt(selectedTab.filters.followUpDaysUntilDue, 10)
        : null,
      followUpCompleted: selectedTab.filters.followUpCompleted,
      ...formatBalancesForParams(selectedTab.filters),
    };
  }

  return {
    limit: DEFAULT_LIMIT,
    sortAsc: sortParams?.dir === SORT_DIR.ASC,
    orderBy: sortParams?.key,
    providerIds: filters.providers,
    locationIds: filters.locations,
    dosFrom: filters.dosFrom,
    dosTo: filters.dosTo,
    daysAged: formattedGreaterThanToInt(filters.daysAged),
    claimStatuses: filters.claimStatuses,
    billTypes: filters.billTypes,
    payerIds: filters.payers,
    billedStatuses: filters.billedStatuses,
    procedureCodes: filters.procedureCodes,
    adjustmentIds: filters.adjustments,
    followUpExists: filters.followUpExists,
    followUpDaysUntilDue: filters.followUpDaysUntilDue
      ? parseInt(filters.followUpDaysUntilDue, 10)
      : null,
    followUpCompleted: filters.followUpCompleted,
    ...formatBalancesForParams(filters),
  };
}

export const formatViewFilters = (filters, viewId) => {
  const balances = formatBalancesForFilters({
    payerBalance: filters[viewId].payerBalance,
    patientBalance: filters[viewId].patientBalance,
    primaryBalance: filters[viewId].primaryBalance,
    secondaryBalance: filters[viewId].secondaryBalance,
    adjustmentBalance: filters[viewId].adjustmentBalance,
    totalBalance: filters[viewId].totalBalance,
  });

  return {
    providers: filters[viewId].providerIds,
    locations: filters[viewId].locationIds,
    dosFrom: filters[viewId].dosFrom,
    dosTo: filters[viewId].dosTo,
    daysAged: filters[viewId].daysAged
      ? intGreaterThanToFormatted(filters[viewId].daysAged)
      : null,
    claimStatuses: filters[viewId].claimStatuses,
    billTypes: filters[viewId].billTypes,
    payers: filters[viewId].payerIds,
    procedureCodes: filters[viewId].procedureCodes || [],
    adjustments: filters[viewId].adjustmentIds || [],
    billedStatuses: filters[viewId].billedStatuses || [],
    followUpExists: filters[viewId].followUpExists
      ? Number(filters[viewId].followUpExists, 10)
      : null,
    followUpDaysUntilDue: filters[viewId].followUpDaysUntilDue,
    followUpCompleted: filters[viewId].followUpCompleted
      ? Number(filters[viewId].followUpCompleted, 10)
      : null,
    ...balances,
  };
};

export const formatViewToSave = view => ({
  id: view.id,
  name: view.name,
  description: view.description,
  orderBy: view.orderBy || 'patient',
  sortAsc: view.orderBy ? view.sortAsc : true,
  providerIds: view.filters.providers,
  locationIds: view.filters.locations,
  dosFrom: view.filters.dosFrom,
  dosTo: view.filters.dosTo,
  daysAged: formattedGreaterThanToInt(view.filters.daysAged),
  claimStatuses: view.filters.claimStatuses,
  billTypes: view.filters.billTypes,
  billedStatuses: view.filters.billedStatuses,
  payerIds: view.filters.payers,
  procedureCodes: view.filters.procedureCodes,
  adjustmentIds: view.filters.adjustments,
  followUpExists: view.filters.followUpExists,
  followUpDaysUntilDue: view.filters.followUpDaysUntilDue
    ? parseInt(view.filters.followUpDaysUntilDue, 10)
    : null,
  followUpCompleted: view.filters.followUpCompleted,
  columns: view.columns,
  ...formatBalancesForParams(view.filters),
});
