import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../neb-styles/neb-styles';
import { centsToCurrency } from '../../../neb-utils/formatters';

export const ELEMENTS = {
  nameText: { id: 'text-name' },
  chargeText: { id: 'text-charge' },
  allowedText: { id: 'text-allowed' },
};

class NebCellFeeSchedule extends LitElement {
  static get properties() {
    return {
      model: Object,
    };
  }

  constructor() {
    super();

    this.__initState();
  }

  __initState() {
    this.model = {
      feeScheduleName: null,
      feeScheduleCharge: null,
      allowedAmount: null,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .text {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          word-break: break-all;
          overflow: hidden;
        }
      `,
    ];
  }

  render() {
    return this.model.feeScheduleName
      ? html`
          <div id="${ELEMENTS.nameText.id}" class="text">
            Name: ${this.model.feeScheduleName}
          </div>

          <div id="${ELEMENTS.chargeText.id}" class="text">
            Charge:
            ${centsToCurrency(this.model.feeScheduleCharge * this.model.units)}
          </div>

          <div id="${ELEMENTS.allowedText.id}" class="text">
            Allowed:
            ${centsToCurrency(this.model.allowedAmount * this.model.units)}
          </div>
        `
      : html``;
  }
}

customElements.define('neb-cell-fee-schedule', NebCellFeeSchedule);
