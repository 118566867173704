import '../../../../packages/neb-lit-components/src/components/neb-date-picker';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-textarea';
import { html, css } from 'lit';

import {
  openError,
  openSuccess,
  openWarning,
} from '../../../../packages/neb-dialog/neb-banner-state';
import { BUTTON_ROLE } from '../../../../packages/neb-lit-components/src/components/neb-button';
import NebPopup, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../../packages/neb-popup/src/neb-popup';
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import { CSS_SPACING } from '../../../../packages/neb-styles/neb-variables';
import {
  saveArHubFollowUps,
  updateArHubFollowUp,
} from '../../../api-clients/follow-up';
import { CSS_SMALL_SPACING } from '../../../styles';

export const AR_HUB_FOLLOW_UP_SUCCESS_MESSAGE = count =>
  `Follow up date set for ${count} invoice${count !== 1 ? 's' : ''}`;
export const AR_HUB_FOLLOW_UP_WARNING_MESSAGE = count =>
  `${count} of the selected invoices already have follow ups scheduled and will be skipped`;

export const AR_HUB_FOLLOW_UP_ERROR_MESSAGE =
  'An error occurred while adding follow up';

export const AR_HUB_FOLLOW_UP_EDIT_SUCCESS_MESSAGE =
  'Follow up updated successfully';

export const AR_HUB_FOLLOW_UP_EDIT_ERROR_MESSAGE =
  'An error occurred while editing follow up';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  buttonConfirm: {
    id: 'button-confirm',
  },
  buttonCancel: {
    id: 'button-cancel',
  },
  followUpDatePicker: {
    id: 'follow-up-date-picker',
  },
  textareaDescription: {
    id: 'text-field-description',
  },
  selectedItemsCount: {
    id: 'selected-items-count',
  },
};

class NebPopupArHubAddFollowUp extends NebPopup {
  static get properties() {
    return {
      followUpDate: Object,
      description: String,
      model: Object,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          width: 450px;
        }

        .content-container {
          display: flex;
          flex-direction: column;
        }

        .button-container {
          display: flex;
          gap: ${CSS_SMALL_SPACING};
          margin-top: ${CSS_SPACING};
        }

        .date-picker-container {
          display: flex;
          flex-direction: column;
        }

        .selected-items-count {
          margin-bottom: ${CSS_SMALL_SPACING};
          font-weight: bold;
        }

        neb-date-picker {
          width: 100%;
        }

        neb-textarea {
          height: 100px;
          margin-top: 4px;
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.title = 'Add Follow Up';
    this.followUpDate = null;
    this.description = '';
    this.model = {
      id: null,
      date: null,
      description: '',
      parentId: null,
      selectedItems: [],
      editMode: false,
    };
  }

  updated(changedProperties) {
    if (changedProperties.has('model') && this.model) {
      if (this.model.date && !this.followUpDate) {
        this.followUpDate = this.model.date;
      }

      if (this.model.description && !this.description) {
        this.description = this.model.description;
      }
    }
  }

  initHandlers() {
    super.initHandlers();

    this.__handlers = {
      ...this.handlers,
      changeFollowUpDate: ({ value }) => {
        this.followUpDate = value;
      },
      changeDescription: ({ value }) => {
        this.description = value;
      },
      save: async () => {
        try {
          if (this.model.editMode) {
            await updateArHubFollowUp(this.model.id, {
              followUpDate: this.followUpDate,
              description: this.description,
            });

            store.dispatch(openSuccess(AR_HUB_FOLLOW_UP_EDIT_SUCCESS_MESSAGE));
            this.onClose(true);
          } else {
            const {
              saved,
              skipped,
              counts: { saved: savedCount, skipped: skippedCount },
            } = await saveArHubFollowUps({
              followUpDate: this.followUpDate,
              description: this.description,
              parentIds: this.model.selectedItems.map(item => item.invoiceId),
              parentType: 'Invoice',
            });
            store.dispatch(
              openSuccess(AR_HUB_FOLLOW_UP_SUCCESS_MESSAGE(savedCount)),
            );

            if (skippedCount > 0) {
              store.dispatch(
                openWarning(AR_HUB_FOLLOW_UP_WARNING_MESSAGE(skippedCount)),
              );
            }

            this.onClose({ saved, skipped });
          }
        } catch (error) {
          console.error(error);
          store.dispatch(
            openError(
              this.model.editMode
                ? AR_HUB_FOLLOW_UP_EDIT_ERROR_MESSAGE
                : AR_HUB_FOLLOW_UP_ERROR_MESSAGE,
            ),
          );
        }

        return null;
      },
    };
  }

  __getTitle() {
    return this.model.editMode ? 'Edit Follow Up' : this.title;
  }

  __renderSelectedItemsCount() {
    return !this.model.editMode
      ? html`<span id="${ELEMENTS.selectedItemsCount.id}"
          >Selected items: ${this.model.selectedItems.length}</span
        >`
      : '';
  }

  __renderFollowUpForm() {
    return html`
      <div class="date-picker-container">
        ${this.__renderSelectedItemsCount()}
        <neb-date-picker
          .id="${ELEMENTS.followUpDatePicker.id}"
          .selectedDate="${this.followUpDate}"
          .onChange="${this.__handlers.changeFollowUpDate}"
          label="Follow Up Date"
          placeholder="Select a date"
          momentFlag
        ></neb-date-picker>
        <neb-textarea
          .id="${ELEMENTS.textareaDescription.id}"
          .value="${this.description}"
          .onChange="${this.__handlers.changeDescription}"
          placeholder="Description"
          label="Description"
          maxLength="255"
        ></neb-textarea>
      </div>
    `;
  }

  renderHeader() {
    return this.__displayHeader
      ? html`
          <div id="${ELEMENTS.header.id}" class="header">
            <h2 id="${ELEMENTS.title.id}" class="title">
              ${this.__getTitle()}
            </h2>

            <div class="spacer"></div>
            ${this.showCancelButton
              ? html`
                  <button
                    id="${ELEMENTS.cancelButton.id}"
                    class="button-cancel"
                    @click="${this.handlers.cancel}"
                  >
                    <neb-icon class="icon-cancel" icon="neb:close"></neb-icon>
                  </button>
                `
              : ''}
          </div>
        `
      : '';
  }

  renderContent() {
    return html`
      <div class="content-container">
        ${this.__renderFollowUpForm()}
        <div class="button-container">
          <neb-button
            id="${ELEMENTS.buttonConfirm.id}"
            class="button"
            label="SAVE"
            .role="${BUTTON_ROLE.CONFIRM}"
            .onClick="${this.__handlers.save}"
            .disabled="${!this.followUpDate}"
          ></neb-button>
          <neb-button
            id="${ELEMENTS.buttonCancel.id}"
            class="button"
            label="CANCEL"
            .role="${BUTTON_ROLE.OUTLINE}"
            .onClick="${this.__handlers.cancel}"
          ></neb-button>
        </div>
      </div>
    `;
  }
}

customElements.define(
  'neb-popup-ar-hub-add-follow-up',
  NebPopupArHubAddFollowUp,
);
