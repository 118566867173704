import { css } from 'lit';

import { centsToCurrency } from '../../../packages/neb-utils/formatters';

export const DEFAULT_HIDDEN_COLUMNS = [
  'claimStatus',
  'followUpDate',
  'followUpDescription',
  'followUpDateAndDescription',
];

export const CONFIG = [
  {
    flex: css`0 0 50px`,
    key: 'checked',
  },
  {
    key: 'patient',
    label: 'Patient',
    flex: css`1 0 0`,
    sortable: true,
  },
  {
    key: 'serviceDate',
    label: 'Service Date',
    flex: css`1 0 0`,
    sortable: true,
  },
  {
    key: 'billType',
    label: 'Bill Type',
    flex: css`1 0 0`,
  },
  {
    key: 'primaryPayer',
    label: 'Primary',
    flex: css`1 0 0`,
    sortable: true,
  },
  {
    key: 'secondaryPayer',
    label: 'Secondary',
    flex: css`1 0 0`,
    sortable: true,
  },
  {
    key: 'invoiceNumber',
    label: 'Invoice',
    flex: css`1 0 0`,
    sortable: true,
  },
  {
    key: 'billedStatus',
    label: 'Billed Status',
    flex: css`1 0 0`,
  },
  {
    key: 'lastBilled',
    label: 'Last Billed',
    flex: css`1 0 0`,
    sortable: true,
  },
  {
    key: 'claimStatus',
    label: 'Claim Status',
    flex: css`1 0 0`,
    sortable: true,
  },
  {
    key: 'billedAmount',
    label: 'Billed Amount',
    flex: css`1 0 0`,
    sortable: true,
    formatter: a => centsToCurrency(a),
  },
  {
    key: 'allowedAmount',
    label: 'Allowed Amount',
    flex: css`1 0 0`,
    sortable: true,
    formatter: a => centsToCurrency(a),
  },
  {
    key: 'adjustmentAmount',
    label: 'Adjustment Amount',
    flex: css`1 0 0`,
    sortable: true,
    formatter: a => centsToCurrency(a),
  },
  {
    key: 'payerBalance',
    label: 'Payer Balance',
    flex: css`1 0 0`,
    sortable: true,
    formatter: a => centsToCurrency(a),
  },
  {
    key: 'patientBalance',
    label: 'Patient Balance',
    flex: css`1 0 0`,
    sortable: true,
    formatter: a => centsToCurrency(a),
  },
  {
    key: 'balance',
    label: 'Balance',
    flex: css`1 0 0`,
    sortable: true,
    formatter: a => centsToCurrency(a),
  },
  {
    key: 'followUpDate',
    label: 'Follow-Up Date',
    flex: css`1 0 0`,
    sortable: true,
    truncate: true,
  },
  {
    key: 'followUpDescription',
    label: 'Follow-Up Description',
    flex: css`1 0 0`,
    sortable: true,
    truncate: true,
  },
  {
    key: 'followUpDateAndDescription',
    label: 'Follow-Up Date + Description',
    flex: css`1 0 0`,
    sortable: true,
    truncate: true,
  },
];

export const getFilteredColumns = () =>
  CONFIG.filter(column => !DEFAULT_HIDDEN_COLUMNS.includes(column.key));

export const getCheckedColumn = () =>
  CONFIG.find(column => column.key === 'checked');

export const filterOutCheckedColumn = columns =>
  columns.filter(column => column.key !== 'checked');

export const ensureCheckedColumnFirst = columns => {
  const checkedColumn = getCheckedColumn();
  const otherColumns = columns.filter(column => column.key !== 'checked');
  return [checkedColumn, ...otherColumns];
};
