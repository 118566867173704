import { css, html, LitElement } from 'lit';

import { BUTTON_ROLE } from '../../../../../packages/neb-lit-components/src/components/neb-button';
import { CSS_COLOR_GREY_2 } from '../../../../../packages/neb-styles/neb-variables';
import {
  FEATURE_FLAGS,
  hasFeatureOrBeta,
} from '../../../../../packages/neb-utils/feature-util';
import { createNarrative } from '../../../../api-clients/intake-narrative';
import { openError } from '../../../../store';

import '../../../../components/misc/neb-structured-document-viewer';
import './neb-chart-notes-structured-documents-view-header';
import './neb-chart-notes-structured-documents-narrative-view';

export const ELEMENTS = {
  container: { id: 'container' },
  viewerHeader: { id: 'viewer-header' },
  documentViewer: { id: 'document-viewer' },
  createNarrativeButton: { id: 'create-narrative-button' },
  narrativeView: { id: 'narrative-view' },
};

class NebChartNotesStructuredDocumentsController extends LitElement {
  static get properties() {
    return {
      __hideUnanswered: Boolean,
      __hasAiFeature: Boolean,
      __loadingNarrative: Boolean,
      __narrative: String,

      document: Object,
      isSOAPTabSelected: Boolean,
    };
  }

  static get styles() {
    return css`
      :host {
        display: block;
        width: 100%;
        height: 100%;
      }

      .container {
        display: flex;
        flex-direction: column;
        height: 100%;
        min-height: 0;
      }

      .structured-viewer {
        min-width: 315px;
        overflow-y: auto;
      }

      .button-container {
        display: flex;
        padding: 16px;
        border-bottom: 1px solid ${CSS_COLOR_GREY_2};
      }

      .content-container {
        overflow: auto;
      }
    `;
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.__abortController = null;
    this.__hasAiFeature = false;
    this.__hideUnanswered = false;
    this.__loadingNarrative = false;
    this.__narrative = '';

    this.document = {};
    this.isSOAPTabSelected = false;

    this.onCopyDocument = () => {};

    this.onCopyNarrative = () => {};

    this.onCloseDocument = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      updateHideUnanswered: e => {
        this.__hideUnanswered = e.value;
      },

      copyDocumentData: pairs => {
        this.onCopyDocument(pairs);
      },

      copyNarrative: narrative => {
        this.onCopyNarrative(narrative);
      },

      closeDocument: () => {
        this.onCloseDocument();
      },

      createNarrative: async () => {
        this.__loadingNarrative = true;

        this.__abortController = new AbortController();

        try {
          this.__narrative = await createNarrative(this.document, {
            signal: this.__abortController.signal,
          });
        } catch (error) {
          this.__narrative = '';

          if (error.name !== 'AbortError') {
            openError('An error occurred while generating the narrative');
          }
        }

        this.__loadingNarrative = false;
      },
    };
  }

  async connectedCallback() {
    this.__hasAiFeature = await hasFeatureOrBeta(
      FEATURE_FLAGS.EGG_AI_INTAKE_NARRATIVE,
    );

    super.connectedCallback();
  }

  update(changedProperties) {
    if (changedProperties.has('document')) {
      this.__narrative = '';

      if (this.__abortController) {
        this.__abortController.abort();
      }
    }

    super.update(changedProperties);
  }

  get __shouldShowNarrativeView() {
    return this.__loadingNarrative || !!this.__narrative;
  }

  __renderNarrativeView() {
    return html`
      <neb-chart-notes-structured-documents-narrative-view
        id="${ELEMENTS.narrativeView.id}"
        .loading="${this.__loadingNarrative}"
        .narrative="${this.__narrative}"
        .document="${this.document}"
        .isSOAPTabSelected="${this.isSOAPTabSelected}"
        .onCopyNarrative="${this.__handlers.copyNarrative}"
      ></neb-chart-notes-structured-documents-narrative-view>
    `;
  }

  __renderDocumentViewer() {
    return html`
      <neb-structured-document-viewer
        id="${ELEMENTS.documentViewer.id}"
        class="structured-viewer"
        .hideUnanswered="${this.__hideUnanswered}"
        .document="${this.document}"
        .isSOAPTabSelected="${this.isSOAPTabSelected}"
        .onCopy="${this.__handlers.copyDocumentData}"
      ></neb-structured-document-viewer>
    `;
  }

  render() {
    return html`
      <div id="${ELEMENTS.container.id}" class="container">
        <neb-chart-notes-structured-documents-view-header
          id="${ELEMENTS.viewerHeader.id}"
          .hideUnanswered="${this.__hideUnanswered}"
          .hideCheckbox="${this.__shouldShowNarrativeView}"
          .onChange="${this.__handlers.updateHideUnanswered}"
          .onClose="${this.__handlers.closeDocument}"
        ></neb-chart-notes-structured-documents-view-header>

        <div class="content-container">
          ${this.__hasAiFeature && this.document?.isQuestionnaire
            ? html`
                <div class="button-container">
                  <neb-button
                    id="${ELEMENTS.createNarrativeButton.id}"
                    role="${BUTTON_ROLE.CONFIRM}"
                    label="Create Narrative"
                    trailingIcon="ai"
                    .disabled="${this.isSOAPTabSelected}"
                    .onClick="${this.__handlers.createNarrative}"
                  ></neb-button>
                </div>
              `
            : ''}
          ${this.__shouldShowNarrativeView
            ? this.__renderNarrativeView()
            : this.__renderDocumentViewer()}
        </div>
      </div>
    `;
  }
}

customElements.define(
  'neb-chart-notes-structured-documents-controller',
  NebChartNotesStructuredDocumentsController,
);
