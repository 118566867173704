import '../../../../src/components/filters/neb-filters-patient-appointments-mobile';
import '../../../../src/components/filters/neb-filters-patient-history-appointments';
import '../../../neb-lit-components/src/components/controls/neb-button-action';
import '../../../neb-lit-components/src/components/neb-button-actions';

import { openPopup } from '@neb/popup';
import { navigate } from '@neb/router';
import { LitElement, html, css } from 'lit';

import * as rteInsuranceApi from '../../../../src/api-clients/insurance-status';
import {
  printFilteredAppointments,
  prepareFilteredAppointmentsData,
} from '../../../../src/api-clients/pdf/print-filtered-appointments';
import NebFormAppointment from '../../../../src/components/forms/appointments/neb-form-appointment';
import { ADD_ONS, hasAddOn } from '../../../../src/utils/add-ons';
import { SELECT_LOCATION_MESSAGE_FOR_APPTS } from '../../../../src/utils/user-message';
import { getAppointments } from '../../../neb-api-client/src/appointment-api-client';
import { fetchOne } from '../../../neb-api-client/src/patient-api-client';
import { fetchMany as getCases } from '../../../neb-api-client/src/patient-cases';
import { getSchedulingPermissions } from '../../../neb-api-client/src/permissions-api-client';
import {
  FUTURE_QUERY_META,
  PAST_QUERY_META,
} from '../../../neb-appointment/neb-appointment-query-meta';
import { queryAppointments } from '../../../neb-appointment/neb-appointments-query';
import { openWarning, openError } from '../../../neb-dialog/neb-banner-state';
import { APPOINTMENT_TABLE_TYPES } from '../../../neb-lit-components/src/components/overlays/neb-overlay-appointment-history';
import { KEPT_STATUSES } from '../../../neb-lit-components/src/components/scheduling/neb-appointment-options';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../neb-lit-components/src/utils/overlay-constants';
import printUpcomingAppointments from '../../../neb-lit-components/src/utils/pdf/print-upcoming-appointments';
import { POPUP_RENDER_KEYS } from '../../../neb-popup/src/renderer-keys';
import { store } from '../../../neb-redux/neb-redux-store';
import { LocationsService } from '../../../neb-redux/services/locations';
import { CSS_SPACING } from '../../../neb-styles/neb-variables';
import { parseDate } from '../../../neb-utils/date-util';
import {
  FEATURE_FLAGS,
  hasFeatureOrBeta,
} from '../../../neb-utils/feature-util';
import { DEFAULT_NAME_OPTS, objToName } from '../../../neb-utils/formatters';
import { printPdf } from '../../../neb-utils/neb-pdf-print-util';
import { URL_NO_ACCESS } from '../../../neb-utils/neb-request-security';

import {
  getFutureDateFilter,
  getPastDateFilter,
  isDateInFuture,
  isDateInPast,
} from './neb-patient-appointments';

export const TABLE_SCROLL_THRESHOLD = 900;

export const ELEMENTS = {
  addAppointmentButton: {
    id: 'add-appointment-button',
  },
  printUpcomingApptsButton: {
    id: 'print-upcoming-appts-button',
  },
  buttonActions: {
    id: 'button-actions',
  },
  pastAppointments: {
    id: 'past-appointments-table',
  },
  futureAppointments: {
    id: 'future-appointments-table',
  },
  filters: {
    id: 'filters',
  },
  filtersMobile: {
    id: 'filters-mobile',
  },
};

function isFutureFilterValid(from, to) {
  const currentDate = parseDate().startOf('day').toISOString();

  if (to && to.isBefore(currentDate)) {
    return false;
  }

  return (
    !from || !to || to.isSame(currentDate, 'day') || to.isAfter(currentDate)
  );
}

function isPastFilterValid(from, to) {
  const currentDate = parseDate().startOf('day').toISOString();

  if (from && (from.isSame(currentDate, 'day') || from.isAfter(currentDate))) {
    return false;
  }

  return !from || !to || from.isBefore(currentDate);
}

class NebPatientHistoryAppointments extends LitElement {
  static get properties() {
    return {
      active: {
        type: Boolean,
      },
      patientId: {
        type: String,
      },
      patientName: {
        type: Object,
      },
      title: {
        type: String,
      },
      layout: {
        type: String,
        reflect: true,
      },
      __futureAppointments: {
        type: Object,
      },
      __pastAppointments: {
        type: Object,
      },
      __futureQuery: {
        type: Object,
      },
      __pastQuery: {
        type: Object,
      },
      __resetPage: {
        type: Boolean,
      },
      filter: {
        type: String,
      },
      __locations: {
        type: Array,
      },
      __cases: {
        type: Array,
      },
      __belowScrollThreshold: {
        type: Boolean,
        state: true,
      },
      __hasFilteredAppointmentsPrintFF: {
        type: Boolean,
      },
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
    this.__initServices();

    this.__resizeObserver = new ResizeObserver(entries => {
      if (entries && entries.length > 0) {
        const { width } = entries[0].contentRect;
        this.__belowScrollThreshold = width < TABLE_SCROLL_THRESHOLD;
        this.requestUpdate();
      }
    });
  }

  __initState() {
    this.active = false;
    this.patientId = '';
    this.patientName = {
      first: '',
      last: '',
    };

    this.layout = '';
    this.__belowScrollThreshold = false;
    this.__hasFilteredAppointmentsPrintFF = false;

    this.__futureQuery = {
      ...FUTURE_QUERY_META(),
      columns: ['dateAndTime', 'rte', 'type', 'provider', 'status'],
    };

    this.__pastQuery = {
      ...PAST_QUERY_META(),
      columns: ['dateAndTime', 'rte', 'type', 'provider', 'status'],
    };

    this.__resetPage = true;

    this.__futureAppointments = {
      appointments: [],
      queryMetadata: {},
      count: 0,
    };

    this.__pastAppointments = {
      appointments: [],
      queryMetadata: {},
      count: 0,
    };

    this.__menuItems = Object.freeze({
      SELECT_LOCATION: {
        label: 'Select Location',
        onSelect: () => this.__handlers.printUpcomingAppointmentsForLocation(),
      },
    });

    this.__loadingAppointments = false;
    this.__resetPage = true;
    this.__locations = [];
    this.__cases = [];
  }

  __initHandlers() {
    this.__handlers = {
      openNewAppointment: () => {
        if (getSchedulingPermissions()) {
          this.__openAppointmentOverlay();
        } else {
          navigate(URL_NO_ACCESS);
        }
      },
      futureTableUpdated: queryParam => {
        const from = parseDate(
          this.__pastAppointments.queryMetadata.start ||
            this.__futureAppointments.queryMetadata.start,
        );
        const to = parseDate(
          this.__futureAppointments.queryMetadata.end ||
            this.__pastAppointments.queryMetadata.startBefore,
        );

        if (isDateInPast(from, to)) {
          this.__futureAppointments = {
            appointments: [],
            queryMetadata: queryParam,
            count: 0,
          };
        } else {
          this.__tableUpdated(queryParam, APPOINTMENT_TABLE_TYPES.FUTURE);
        }
      },
      pastTableUpdated: queryParam => {
        const from = parseDate(
          this.__pastAppointments.queryMetadata.start ||
            this.__futureAppointments.queryMetadata.start,
        );
        const to = parseDate(
          this.__futureAppointments.queryMetadata.end ||
            this.__pastAppointments.queryMetadata.startBefore,
        );

        if (isDateInFuture(from, to)) {
          this.__pastAppointments = {
            appointments: [],
            queryMetadata: queryParam,
            count: 0,
          };
        } else {
          this.__tableUpdated(queryParam, APPOINTMENT_TABLE_TYPES.PAST);
        }
      },
      printUpcomingAppointmentsForLocation: () =>
        this.__printUpcomingAppointmentsForLocation(),
      printUpcomingAppointments: () =>
        printUpcomingAppointments({
          patient: { id: this.patientId },
        }),
      // eslint-disable-next-line complexity
      printFilteredAppointments: async type => {
        try {
          const currentDate = parseDate().startOf('day');

          let futureResponse = { data: [], count: 0 };
          let pastResponse = { data: [], count: 0 };

          if (type === 'future' && this.__futureAppointments.count > 0) {
            futureResponse = await getAppointments({
              ...this.__futureAppointments.queryMetadata,
              limit: 500,
            });
          } else if (type === 'past' && this.__pastAppointments.count > 0) {
            pastResponse = await getAppointments({
              ...this.__pastAppointments.queryMetadata,
              limit: 500,
            });
          } else if (!type) {
            const fetchFuture = this.__futureAppointments.count > 0;
            const fetchPast = this.__pastAppointments.count > 0;

            if (fetchFuture && fetchPast) {
              [futureResponse, pastResponse] = await Promise.all([
                getAppointments({
                  ...this.__futureAppointments.queryMetadata,
                  limit: 500,
                }),
                getAppointments({
                  ...this.__pastAppointments.queryMetadata,
                  limit: 500,
                }),
              ]);
            } else if (fetchFuture) {
              futureResponse = await getAppointments({
                ...this.__futureAppointments.queryMetadata,
                limit: 500,
              });
            } else if (fetchPast) {
              pastResponse = await getAppointments({
                ...this.__pastAppointments.queryMetadata,
                limit: 500,
              });
            }
          }

          if (futureResponse.count === 0 && pastResponse.count === 0) {
            store.dispatch(
              openWarning('No appointments found with the selected filters.'),
            );

            return;
          }

          if (!this.patientName || !this.patientName.first) {
            const patient = await fetchOne(this.patientId);
            this.patientName = patient.name;
          }

          const pdfData = {
            patientName: objToName(this.patientName, DEFAULT_NAME_OPTS),
            futureAppointments: futureResponse.data || [],
            pastAppointments: pastResponse.data || [],
            currentDate: currentDate.toISOString(),
          };

          const processedData = await prepareFilteredAppointmentsData(pdfData);
          await printPdf(printFilteredAppointments(processedData));
        } catch (e) {
          store.dispatch(
            openError('Error generating PDF for filtered appointments.'),
          );

          console.error('Error generating PDF for filtered appointments:', e);
        }
      },
      printFutureOnly: async () => {
        await this.__handlers.printFilteredAppointments('future');
      },
      printPastOnly: async () => {
        await this.__handlers.printFilteredAppointments('past');
      },
      // eslint-disable-next-line complexity
      filter: model => {
        const { providerIds, status, date, includeRescheduled } = model;
        const { to, from } = date;

        const futureDateFilter = getFutureDateFilter(from, to);

        const pastDateFilter = getPastDateFilter(from, to);

        const filters = {
          status,
          providerIds,
          includeRescheduled,
          ...(this.__hasFilteredAppointmentsPrintFF && {
            locationIds: model.locationIds,
            caseIds: model.caseIds,
          }),
        };

        const futureFilter = { ...filters, ...futureDateFilter };

        const pastFilter = { ...filters, ...pastDateFilter };

        const futureQuery = this.__requiresBuild(
          this.__futureAppointments.queryMetadata,
        )
          ? this.__buildDefaultQueryParam({
              ...FUTURE_QUERY_META(),
              ...(futureFilter && { filters: futureFilter }),
              offset: 0,
            })
          : {
              ...this.__futureAppointments.queryMetadata,
              ...futureFilter,
              offset: 0,
            };

        const pastQuery = this.__requiresBuild(
          this.__pastAppointments.queryMetadata,
        )
          ? this.__buildDefaultQueryParam({
              ...PAST_QUERY_META(),
              ...(pastFilter && { filters: pastFilter }),
              offset: 0,
            })
          : {
              ...this.__pastAppointments.queryMetadata,
              ...pastFilter,
              offset: 0,
            };

        if (from && to && from.isAfter(to)) {
          this.__futureAppointments = {
            appointments: [],
            queryMetadata: futureQuery,
            count: 0,
          };

          this.__pastAppointments = {
            appointments: [],
            queryMetadata: pastQuery,
            count: 0,
          };

          return;
        }

        if (this.filter === APPOINTMENT_TABLE_TYPES.ALL) {
          if (isDateInFuture(from, to)) {
            this.__tableUpdated(futureQuery, APPOINTMENT_TABLE_TYPES.FUTURE);
            this.__pastAppointments = {
              appointments: [],
              queryMetadata: pastQuery,
              count: 0,
            };
          } else if (isDateInPast(from, to)) {
            this.__tableUpdated(pastQuery, APPOINTMENT_TABLE_TYPES.PAST);
            this.__futureAppointments = {
              appointments: [],
              queryMetadata: futureQuery,
              count: 0,
            };
          } else {
            this.__tableUpdated(futureQuery, APPOINTMENT_TABLE_TYPES.FUTURE);
            this.__tableUpdated(pastQuery, APPOINTMENT_TABLE_TYPES.PAST);
          }
        } else if (this.filter === APPOINTMENT_TABLE_TYPES.FUTURE) {
          if (isFutureFilterValid(from, to)) {
            this.__tableUpdated(futureQuery, APPOINTMENT_TABLE_TYPES.FUTURE);
          } else {
            this.__futureAppointments = {
              appointments: [],
              queryMetadata: futureQuery,
              count: 0,
            };
          }
        } else if (isPastFilterValid(from, to)) {
          this.__tableUpdated(pastQuery, APPOINTMENT_TABLE_TYPES.PAST);
        } else {
          this.__pastAppointments = {
            appointments: [],
            queryMetadata: pastQuery,
            count: 0,
          };
        }
        this.__resetPage = !this.__resetPage;
      },
    };
  }

  __initServices() {
    this.__locationsService = new LocationsService(({ locations }) => {
      const inactiveLocations = locations.filter(loc => !loc.active);
      this.__locations = [
        ...locations.filter(loc => loc.active),
        ...inactiveLocations,
      ];
    });
  }

  __formatLocations(locations) {
    return locations.map(location => ({ ...location, label: location.name }));
  }

  async __fetchCases() {
    try {
      this.__cases = (await getCases(this.patientId, true)) || [];
    } catch (e) {
      console.error('Error fetching cases:', e);
      this.__cases = [];
    }
  }

  __buildDefaultQueryParam(query) {
    const {
      sort: { field, dir },
      pageSize,
      filters,
    } = query;

    const defaultParams = {
      patientId: this.patientId,
      sortField: field,
      sortDir: dir,
      secondarySortField: field,
      secondarySortDir: dir,
      limit: pageSize,
      expand: 'appointmentType,splits',
      status: KEPT_STATUSES,
      ...filters,
    };

    return defaultParams;
  }

  __requiresBuild(obj) {
    return !Object.keys(obj).length;
  }

  // eslint-disable-next-line complexity
  async __futureAndPastTablesUpdated(type) {
    const futureQuery = this.__requiresBuild(
      this.__futureAppointments.queryMetadata,
    )
      ? this.__buildDefaultQueryParam(FUTURE_QUERY_META())
      : this.__futureAppointments.queryMetadata;

    const pastQuery = this.__requiresBuild(
      this.__pastAppointments.queryMetadata,
    )
      ? this.__buildDefaultQueryParam(PAST_QUERY_META())
      : this.__pastAppointments.queryMetadata;

    const from = parseDate(pastQuery.start || futureQuery.start);
    const to = parseDate(futureQuery.end || pastQuery.startBefore);

    if (from && to && from.isAfter(to)) {
      this.__futureAppointments = {
        appointments: [],
        queryMetadata: futureQuery,
        count: 0,
      };

      this.__pastAppointments = {
        appointments: [],
        queryMetadata: pastQuery,
        count: 0,
      };

      return;
    }

    if (type === APPOINTMENT_TABLE_TYPES.ALL) {
      if (isDateInFuture(from, to)) {
        this.__tableUpdated(futureQuery, APPOINTMENT_TABLE_TYPES.FUTURE);
        this.__pastAppointments = {
          appointments: [],
          queryMetadata: pastQuery,
          count: 0,
        };
      } else if (isDateInPast(from, to)) {
        this.__tableUpdated(pastQuery, APPOINTMENT_TABLE_TYPES.PAST);
        this.__futureAppointments = {
          appointments: [],
          queryMetadata: futureQuery,
          count: 0,
        };
      } else {
        this.__tableUpdated(futureQuery, APPOINTMENT_TABLE_TYPES.FUTURE);
        this.__tableUpdated(pastQuery, APPOINTMENT_TABLE_TYPES.PAST);
      }
    } else if (type === APPOINTMENT_TABLE_TYPES.FUTURE) {
      await this.__tableUpdated(futureQuery, APPOINTMENT_TABLE_TYPES.FUTURE);
      this.__pastAppointments = {
        appointments: [],
        queryMetadata: {},
        count: 0,
      };
    } else {
      await this.__tableUpdated(pastQuery, APPOINTMENT_TABLE_TYPES.PAST);
      this.__futureAppointments = {
        appointments: [],
        queryMetadata: {},
        count: 0,
      };
    }
  }

  async __updateRTEStatuses(apt) {
    const insurancesStatuses = await rteInsuranceApi.fetchMany(
      apt.appointments,
    );

    if (insurancesStatuses.length) {
      return {
        ...apt,
        appointments: apt.appointments.map(appointment => ({
          ...appointment,
          realTimeEligibilityStatus: insurancesStatuses.find(insurance =>
            Object.values(appointment).includes(insurance.appointmentId),
          ).status,
        })),
      };
    }

    return apt;
  }

  async __tableUpdated(queryParam, type) {
    if (queryParam) {
      this.__loadingAppointments = true;

      const queryWithStandardSort = {
        ...queryParam,
        standardSort: true,
      };

      const { appointments, queryMetadata, count } = await queryAppointments(
        queryWithStandardSort,
      );

      if (type === APPOINTMENT_TABLE_TYPES.FUTURE) {
        const futureAppointments = {
          appointments,
          queryMetadata,
          count,
        };

        if (this.__hasCTVerify) {
          this.__futureAppointments =
            await this.__updateRTEStatuses(futureAppointments);
        } else {
          this.__futureAppointments = futureAppointments;
        }
      } else {
        const pastAppointments = {
          appointments,
          queryMetadata,
          count,
        };

        if (this.__hasCTVerify) {
          this.__pastAppointments =
            await this.__updateRTEStatuses(pastAppointments);
        } else {
          this.__pastAppointments = pastAppointments;
        }
      }

      this.__loadingAppointments = false;
    } else {
      this.__futureAndPastTablesUpdated(this.filter);
    }
  }

  async connectedCallback() {
    this.__hasCTVerify = await hasAddOn(ADD_ONS.CT_VERIFY);
    this.__hasFilteredAppointmentsPrintFF = await hasFeatureOrBeta(
      FEATURE_FLAGS.OWL_FILTERED_APPOINTMENTS_PRINT,
    );

    this.__locationsService.connect();
    this.__fetchCases();
    this.__futureQuery.columns = [
      'dateAndTime',
      'rte',
      'type',
      'provider',
      'location',
      'case',
      'status',
    ];

    this.__pastQuery.columns = [
      'dateAndTime',
      'rte',
      'type',
      'provider',
      'location',
      'case',
      'status',
    ];

    this.__resizeObserver.observe(this);
    super.connectedCallback();
  }

  disconnectedCallback() {
    this.__resizeObserver.unobserve(this);
    this.__locationsService.disconnect();
    super.disconnectedCallback();
  }

  __getMenuItems() {
    return [this.__menuItems.SELECT_LOCATION];
  }

  async __openAppointmentOverlay() {
    const accepted = await openOverlay(OVERLAY_KEYS.APPOINTMENT_FORM, {
      ...NebFormAppointment.createModel(),
      patientId: this.patientId,
    });

    if (accepted) {
      this.__futureAndPastTablesUpdated(this.filter);
    }
  }

  async __printUpcomingAppointmentsForLocation() {
    const res = await openPopup(POPUP_RENDER_KEYS.SELECT_LOCATION, {
      message: SELECT_LOCATION_MESSAGE_FOR_APPTS,
    });

    if (!res.canceled) {
      await printUpcomingAppointments({
        patient: { id: this.patientId },
        location: res.location,
      });
    }
  }

  static get styles() {
    return [
      css`
        :host {
          display: flex;
          flex-direction: column;
        }

        * {
          margin-bottom: ${CSS_SPACING};
        }

        .appointments {
          flex-shrink: 0;
        }

        :host([below-scroll-threshold]) .appointments {
          overflow-x: scroll;
          overflow-y: hidden;
        }
        :host([below-scroll-threshold]) .appointments::part(table) {
          width: max-content;
        }

        .hide-deleted-checkbox {
          width: fit-content;
        }

        .add-appointment-button {
          margin-right: 10px;
          margin-bottom: 0px;
        }

        .print-upcoming-appts-button {
          margin-left: 10px;
        }

        .button-actions {
          width: 10px;
        }

        :host([layout='small']) .print-upcoming-appts-button {
          margin-left: 0px;
        }
      `,
    ];
  }

  updated(changedProps) {
    if (
      changedProps.has('__belowScrollThreshold') ||
      changedProps.has('layout')
    ) {
      if (
        this.__hasFilteredAppointmentsPrintFF &&
        this.__belowScrollThreshold &&
        this.layout !== 'small'
      ) {
        this.setAttribute('below-scroll-threshold', '');
      } else {
        this.removeAttribute('below-scroll-threshold');
      }
    }
  }

  __renderDropdownMenu() {
    return html`
      <neb-button-actions
        id="${ELEMENTS.buttonActions.id}"
        class="button-actions"
        align="right"
        maxVisibleItems="10"
        vertical
        .value="${this.__getMenuItems()}"
        iconHeight="20px"
        iconWidth="20px"
      ></neb-button-actions>
    `;
  }

  __renderFutureAppointmentActions() {
    return this.filter !== APPOINTMENT_TABLE_TYPES.PAST
      ? html`
          <neb-button-action
            id="${ELEMENTS.printUpcomingApptsButton.id}"
            class="${ELEMENTS.printUpcomingApptsButton.id}"
            label="Print Upcoming Appointments"
            leadingIcon="print"
            .onClick="${this.__handlers.printUpcomingAppointments}"
          ></neb-button-action>
          ${this.__renderDropdownMenu()}
        `
      : html``;
  }

  __renderAppointmentActionButtons() {
    return html`
      <div class="appointment-action-buttons">
        <neb-button-action
          id="${ELEMENTS.addAppointmentButton.id}"
          class="${ELEMENTS.addAppointmentButton.id}"
          label="Add New Appointment"
          .onClick="${this.__handlers.openNewAppointment}"
        ></neb-button-action>
        ${this.__renderFutureAppointmentActions()}
      </div>
    `;
  }

  __renderAppointmentFilters() {
    return this.layout !== 'small'
      ? html`
          <neb-filters-patient-history-appointments
            id="${ELEMENTS.filters.id}"
            class="filters"
            .layout="${this.layout}"
            .onApply="${this.__handlers.filter}"
            .onPrint="${this.__handlers.printFilteredAppointments}"
            .expanded="${true}"
            .type="${this.filter}"
            .locations="${this.__hasFilteredAppointmentsPrintFF
              ? this.__locations
              : []}"
            .cases="${this.__hasFilteredAppointmentsPrintFF
              ? this.__cases
              : []}"
          ></neb-filters-patient-history-appointments>
        `
      : html`
          <neb-filters-patient-appointments-mobile
            id="${ELEMENTS.filtersMobile.id}"
            class="filters"
            .onApply="${this.__handlers.filter}"
          ></neb-filters-patient-appointments-mobile>
        `;
  }

  render() {
    return html`
      ${this.__renderAppointmentActionButtons()}
      ${this.__renderPastAndFutureAppointmentsTable()}
    `;
  }

  __renderFutureAppointments() {
    return this.filter !== APPOINTMENT_TABLE_TYPES.PAST
      ? html`
          <neb-appointments
            id="${ELEMENTS.futureAppointments.id}"
            class="appointments"
            title="Future Appointments"
            emptyMessage="No Future Appointments"
            .active="${this.active}"
            .patientId="${this.patientId}"
            .query="${this.__futureQuery}"
            .model="${this.__futureAppointments}"
            .resetPage="${this.__resetPage}"
            .onTableUpdated="${this.__handlers.futureTableUpdated}"
            .cases="${this.__hasFilteredAppointmentsPrintFF
              ? this.__cases
              : []}"
            .locations="${this.__hasFilteredAppointmentsPrintFF
              ? this.__locations
              : []}"
            .showPrintButton="${this.__hasFilteredAppointmentsPrintFF}"
            .onPrint="${this.__handlers.printFutureOnly}"
          >
          </neb-appointments>
        `
      : html``;
  }

  __renderPastAppointments() {
    return this.filter !== APPOINTMENT_TABLE_TYPES.FUTURE
      ? html`
          <neb-appointments
            id="${ELEMENTS.pastAppointments.id}"
            class="appointments"
            title="Past Appointments"
            emptyMessage="No Past Appointments"
            .active="${this.active}"
            .patientId="${this.patientId}"
            .query="${this.__pastQuery}"
            .model="${this.__pastAppointments}"
            .resetPage="${this.__resetPage}"
            .onTableUpdated="${this.__handlers.pastTableUpdated}"
            .cases="${this.__hasFilteredAppointmentsPrintFF
              ? this.__cases
              : []}"
            .locations="${this.__hasFilteredAppointmentsPrintFF
              ? this.__locations
              : []}"
            .showPrintButton="${this.__hasFilteredAppointmentsPrintFF}"
            .onPrint="${this.__handlers.printPastOnly}"
          >
          </neb-appointments>
        `
      : html``;
  }

  __renderPastAndFutureAppointmentsTable() {
    return html`
      ${this.__renderAppointmentFilters()}
      ${this.__renderFutureAppointments()}${this.__renderPastAppointments()}
    `;
  }
}

customElements.define(
  'neb-patient-history-appointments',
  NebPatientHistoryAppointments,
);
