import { Method } from '../../packages/neb-api-client/src/utils/api-client-utils';
import ApiClientV2 from '../../packages/neb-api-client/src/utils/api-client-v2';

export const billingApiClient = new ApiClientV2({ microservice: 'billing' });

export const getArHubInvoices = async (body, optOutLoadingIndicator = true) => {
  const { data } = await billingApiClient.makeRequest({
    optOutLoadingIndicator,
    method: Method.POST,
    body,
    path: '/api/v1/tenants/:tenantId/invoices/ar-hub-invoices',
    version: 1,
  });

  return data;
};

export const getArHubInvoicesV2 = async (
  body,
  optOutLoadingIndicator = true,
) => {
  const { data } = await billingApiClient.makeRequest({
    optOutLoadingIndicator,
    method: Method.POST,
    body,
    path: '/api/v2/tenants/:tenantId/invoices/ar-hub-invoices',
    version: 2,
  });

  return data;
};

export const saveArHubCustomView = async (
  body,
  optOutLoadingIndicator = true,
) => {
  const result = await billingApiClient.makeRequest({
    optOutLoadingIndicator,
    method: Method.PUT,
    body,
    path: '/api/v1/tenants/:tenantId/ar-hub-view',
    version: 1,
  });

  return result;
};

export const getArHubCustomViews = (optOutLoadingIndicator = true) =>
  billingApiClient.makeRequest({
    optOutLoadingIndicator,
    method: Method.GET,
    path: '/api/v1/tenants/:tenantId/ar-hub-view',
    version: 1,
  });

export const getArHubCustomViewsV2 = (optOutLoadingIndicator = true) =>
  billingApiClient.makeRequest({
    optOutLoadingIndicator,
    method: Method.GET,
    path: '/api/v2/tenants/:tenantId/ar-hub-view',
    version: 2,
  });

export const getArHubFilters = async (
  queryParams,
  optOutLoadingIndicator = true,
) => {
  const data = await billingApiClient.makeRequest({
    optOutLoadingIndicator,
    method: Method.GET,
    queryParams,
    path: '/api/v1/tenants/:tenantId/ar-hub-filters',
    version: 1,
  });

  return data;
};

export const saveArHubDefaultView = async ({
  viewIds,
  optOutLoadingIndicator = true,
}) => {
  const { data } = await billingApiClient.makeRequest({
    optOutLoadingIndicator,
    method: Method.POST,
    body: { viewIds },
    path: '/api/v1/tenants/:tenantId/ar-hub-default-view',
    version: 1,
  });

  return data[0];
};
