import Debouncer from '../../packages/neb-utils/debouncer';
import { REFETCH_INTERVAL } from '../../packages/neb-utils/env';

import { popupOpened } from './popup-util';
import { AutoFetcher } from './refetch-controller';

const USER_INACTIVE_TIMEOUT = 3600000;

export class PollController {
  constructor(host, cb, opts = { timeout: REFETCH_INTERVAL, force: false }) {
    this.host = host;
    this.cb = cb;
    this.timeout = opts.timeout || REFETCH_INTERVAL;
    this.userActive = true;
    this.force = opts.force || false;
    this.isVisible = !document.hidden;
    this.autoFetcher = null;

    this.timeoutDebouncer = new Debouncer(() => {
      this.userActive = false;
    }, USER_INACTIVE_TIMEOUT);

    this.handlers = {
      userAction: () => {
        this.userActive = true;
        this.timeoutDebouncer.debounce();
      },
    };

    host.addController(this);
    this._initializeAutoFetcher();
  }

  _initializeAutoFetcher() {
    try {
      this.autoFetcher = new AutoFetcher(this.host, this.cb);
    } catch (error) {
      console.error('Failed to initialize AutoFetcher:', error);
    }
  }

  hostConnected() {
    document.addEventListener('mousemove', this.handlers.userAction);

    this._timer = setInterval(async () => {
      if (this.userActive && !document.hidden) {
        try {
          if (!popupOpened() || this.force) {
            await this.cb();
          }
        } catch (e) {
          if (e.statusCode === 401) {
            this.userActive = false;
          }
        }
      }
    }, this.timeout);
  }

  hostDisconnected() {
    document.removeEventListener('mousemove', this.handlers.userAction);
    clearInterval(this._timer);
    this._timer = undefined;
  }
}
