import { openPopup } from '@neb/popup';
import { css, html } from 'lit';
import moment from 'moment-timezone';

import NebTable, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../../packages/neb-lit-components/src/components/tables/neb-table';
import { POPUP_RENDER_KEYS } from '../../../../packages/neb-popup/src/renderer-keys';
import { formatTotals } from '../../../formatters/ar-hub';
import {
  CSS_SPACING,
  CSS_COLOR_GREY_3,
  CSS_FONT_WEIGHT_BOLD,
  CSS_SPACING_ROW_LARGE,
  CSS_BANNER_INFO_COLOR,
} from '../../../styles';
import { navigateToRow } from '../../../utils/navigation-util';

import '../../misc/neb-icon';
import '../../controls/inputs/neb-checkbox';
import '../../../../packages/neb-lit-components/src/components/neb-button-actions';
import '../../../../packages/neb-lit-components/src/components/controls/neb-button-icon';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  totalsHeader: { id: 'totals-header' },
  totalsFooter: { id: 'totals-footer' },
  bulkActionMenu: { id: 'bulk-action-menu' },
  checkboxes: {
    selector: 'neb-checkbox',
  },
  invoiceLinks: { selector: '[id^=invoice-]' },
  followUpLinks: { selector: '[id^=follow-up-link-]' },
  completeFollowUpButtons: { selector: '[id^=complete-follow-up-button-]' },
};

class NebTableArHub extends NebTable {
  static get properties() {
    return {
      totals: Object,
      __selectedRows: { type: Array },
      isInvoiceOverlayClosed: Boolean,
      lastClickedInvoice: Object,
      selectedTab: Object,
    };
  }

  initState() {
    super.initState();
    this.totals = {};
    this.__selectedRows = [];
    this.isInvoiceOverlayClosed = false;
    this.lastClickedInvoice = null;
    this.selectedTab = null;

    this.onClickInvoice = () => {};

    this.onFollowUpAdded = () => {};

    this.onClickFollowUp = () => {};

    this.onCompleteFollowUp = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      clickCheckbox: e => {
        const rowIndex = parseInt(e.name, 10);
        const isChecked = e.value;

        this.__selectedRows = isChecked
          ? [...this.__selectedRows, rowIndex]
          : this.__selectedRows.filter(index => index !== rowIndex);
      },
      handleBulkSelection: select => {
        if (select) {
          this.__selectedRows = this.model.map((_, index) => index);
        } else {
          this.__selectedRows = [];
        }
      },
      handleAddFollowUp: async () => {
        if (this.__selectedRows.length > 0) {
          const selectedItems = this.__selectedRows.map(
            index => this.model[index],
          );

          const result = await openPopup(
            POPUP_RENDER_KEYS.AR_HUB_ADD_FOLLOW_UP,
            {
              date: null,
              description: '',
              selectedItems,
              editMode: false,
            },
          );

          if (result) {
            this.onFollowUpAdded(result);
          }
        }
      },
      getBulkActions: () => [
        {
          id: 'selectAll',
          label: 'Select All',
          onSelect: () => this.handlers.handleBulkSelection(true),
        },
        {
          id: 'deselectAll',
          label: 'Deselect All',
          onSelect: () => this.handlers.handleBulkSelection(false),
        },
        {
          id: 'addFollowUp',
          label: 'Add Follow Up',
          onSelect: () => this.handlers.handleAddFollowUp(),
        },
      ],
      clickInvoice: e => {
        e.stopPropagation();
        const rowIndex = Number(e.currentTarget.getAttribute('index'));
        const row = this.model[rowIndex];

        this.lastClickedInvoice = {
          index: rowIndex,
          invoiceNumber: row.invoiceNumber,
        };

        this.onClickInvoice(row, rowIndex);
      },
      clickFollowUp: async rowIndex => {
        const row = this.model[rowIndex];

        const result = await openPopup(POPUP_RENDER_KEYS.AR_HUB_ADD_FOLLOW_UP, {
          id: row.followUpId,
          date: row.followUpDate
            ? moment(row.followUpDate, 'MM/DD/YYYY')
            : null,
          description: row.followUpDescription,
          parentId: row.invoiceId,
          selectedItems: [row],
          editMode: true,
        });

        if (result) {
          this.onClickFollowUp();
        }
      },
      completeFollowUp: rowIndex => {
        const row = this.model[rowIndex];

        this.onCompleteFollowUp({
          followUpId: row.followUpId,
          isCompleted: row.isFollowUpCompleted,
          rowIndex,
        });
      },
    };
  }

  update(changedProperties) {
    super.update(changedProperties);

    if (changedProperties.has('model')) {
      this.totals = this.__getTotals(this.model);
    }

    if (
      changedProperties.has('isInvoiceOverlayClosed') &&
      this.isInvoiceOverlayClosed
    ) {
      this.__goToLastInvoiceVisited();
    }

    if (changedProperties.has('selectedTab')) {
      this.__selectedRows = [];
    }
  }

  static get styles() {
    return [
      super.styles,
      css`
        .header-container {
          background-color: white;
          position: sticky;
          z-index: 1;
          top: 0px;
        }

        .row-totals {
          display: flex;
          padding: 0 ${CSS_SPACING};
          background-color: ${CSS_COLOR_GREY_3};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .row-totals .cell-header {
          min-width: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .cell-header {
          padding: ${CSS_SPACING_ROW_LARGE} 0;
        }

        .label {
          width: min-content;
          margin-right: 5px;
        }

        .container-arrow {
          display: flex;
          margin-left: 4px;
          align-self: center;
          align-items: center;
          justify-content: flex-end;
          flex-flow: column nowrap;
        }

        .cell:not(:last-child) {
          margin-right: 0px;
        }

        .cell[truncate] {
          display: inherit;
        }

        @keyframes fade {
          from {
            background: rgb(192, 230, 255);
          }
          to {
            background: transparent;
          }
        }

        .item-highlight {
          animation: fade 3s;
        }

        .cell-checkbox {
          padding: 0 5px;
        }

        .ellipsis {
          font-weight: 300;
        }

        .icon {
          width: 28px;
          height: 28px;
          fill: ${CSS_BANNER_INFO_COLOR};
        }

        .icon-complete-follow-up {
          fill: #8cd211;
        }

        .follow-up-container {
          display: flex;
          align-items: center;
          gap: 4px;
          width: 100%;
        }

        .text-truncated {
          text-overflow: ellipsis;
          overflow: hidden;
        }
      `,
    ];
  }

  __goToLastInvoiceVisited() {
    if (!this.lastClickedInvoice) return;

    let rowIndex = this.lastClickedInvoice.index;

    if (
      this.model[rowIndex]?.invoiceNumber !==
      this.lastClickedInvoice.invoiceNumber
    ) {
      rowIndex = this.model.findIndex(
        row => row.invoiceNumber === this.lastClickedInvoice.invoiceNumber,
      );

      if (rowIndex === -1) return;
    }

    const row = this.shadowRoot.getElementById(`row-${rowIndex}`);

    if (row) {
      navigateToRow(row);
    }
  }

  __getTotals(charges) {
    const totals = charges.reduce(
      (acc, charge) => {
        acc.billedAmount += charge.billedAmount;
        acc.allowedAmount += charge.allowedAmount;
        acc.adjustmentAmount += charge.adjustmentAmount;
        acc.payerBalance += charge.payerBalance;
        acc.patientBalance += charge.patientBalance;
        acc.balance += charge.balance;
        return acc;
      },
      {
        billedAmount: 0,
        allowedAmount: 0,
        adjustmentAmount: 0,
        payerBalance: 0,
        patientBalance: 0,
        balance: 0,
      },
    );

    return {
      checked: 'Totals',
      ...formatTotals(totals),
    };
  }

  __renderTotalsRow(id) {
    if (!this.model.length) {
      return '';
    }
    return html`
      <div id="${id}" class="row row-totals">
        ${this.config.map(columnConfig => {
          const totalValue = this.totals[columnConfig.key];
          return html`
            <div class="cell cell-header" style="flex: ${columnConfig.flex}">
              ${totalValue}
            </div>
          `;
        })}
      </div>
    `;
  }

  __renderHeader() {
    return html`
      <div class="header-container">
        ${super.__renderHeader()}
        ${this.__renderTotalsRow(ELEMENTS.totalsHeader.id)}
      </div>
    `;
  }

  __renderFollowUpButton(rowIndex) {
    const { isFollowUpCompleted, followUpId } = this.model[rowIndex];

    return followUpId
      ? html`
          <neb-button-icon
            id="complete-follow-up-button-${rowIndex}"
            icon=${isFollowUpCompleted
              ? 'neb:completeFollowUp'
              : 'neb:followUp'}
            class="${isFollowUpCompleted ? 'icon-complete-follow-up' : ''} icon"
            .onClick="${() => this.handlers.completeFollowUp(rowIndex)}"
          ></neb-button-icon>
        `
      : '';
  }

  renderFooter() {
    return this.__renderTotalsRow(ELEMENTS.totalsFooter.id);
  }

  // eslint-disable-next-line complexity
  renderDataCell(value, columnConfig, rowIndex) {
    switch (columnConfig.key) {
      case 'checked':
        return this.__renderCheckboxCell({ rowIndex });

      case 'invoiceNumber':
        return html`
          <neb-text
            id="invoice-link-${rowIndex}"
            key="${columnConfig.key}"
            bold
            link
            index="${rowIndex}"
            @click="${this.handlers.clickInvoice}"
            >${value}</neb-text
          >
        `;
      case 'followUpDate':
      case 'followUpDescription':
      case 'followUpDateAndDescription':
        return html`
          <div class="follow-up-container">
            <neb-text
              id="follow-up-link-${rowIndex}"
              key="${columnConfig.key}"
              class="text-truncated"
              bold
              link
              index="${rowIndex}"
              @click="${() => this.handlers.clickFollowUp(rowIndex)}"
              >${value}</neb-text
            >
            ${this.__renderFollowUpButton(rowIndex)}
          </div>
        `;
      default:
        return value;
    }
  }

  renderHeaderCell(columnConfig) {
    if (columnConfig.key === 'checked') {
      return html`
        <neb-button-actions
          id="${ELEMENTS.bulkActionMenu.id}"
          class="ellipsis"
          align="left"
          maxVisibleItems="5"
          ?disabled="${!this.model.length}"
          .value="${this.bulkActions}"
          .onClick="${this.handlers.getBulkActions}"
          .forceDownMenu="${true}"
        ></neb-button-actions>
      `;
    }

    return super.renderHeaderCell(columnConfig);
  }

  __renderCheckboxCell({ rowIndex }) {
    return html`
      <neb-checkbox
        class="cell-checkbox"
        .name="${rowIndex}"
        ?checked="${this.__selectedRows.includes(rowIndex)}"
        .onChange="${this.handlers.clickCheckbox}"
      ></neb-checkbox>
    `;
  }
}

customElements.define('neb-table-ar-hub', NebTableArHub);
