import '../../../../../src/components/controls/inputs/neb-checkbox';

import { css, html } from 'lit';

import {
  CSS_SPACING,
  CSS_COLOR_GREY_3,
  CSS_FONT_WEIGHT_BOLD,
  CSS_COLOR_HIGHLIGHT,
} from '../../../../neb-styles/neb-variables';
import {
  FEATURE_FLAGS,
  hasFeatureOrBetaSync,
} from '../../../../neb-utils/feature-util';
import { currency } from '../../../../neb-utils/masks';
import { DISCOUNT_TYPES } from '../forms/neb-form-discount';

import NebTable, { ELEMENTS as ELEMENTS_BASE } from './neb-table';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  totalsHeader: {
    id: 'totals-header',
  },
  totalsFooter: {
    id: 'totals-footer',
  },
  checkboxes: {
    selector: '[id^=checkbox-]',
  },
  chargeLinks: {
    selector: '[id^=charge-link-]',
  },
  discountAllocated: {
    selector: '[id^=discount-allocated-]',
  },
  paymentLinks: {
    selector: '[id^=payment-link-]',
  },
  taxAmount: {
    selector: '[id^=taxAmount-]',
  },
  patientPaid: {
    selector: '[id^=patientPaid-]',
  },
};

class NebTableDiscountAllocation extends NebTable {
  static get properties() {
    return {
      totals: {
        type: Object,
      },
      type: {
        type: String,
      },
      editMode: {
        type: Boolean,
      },
      paymentId: {
        type: String,
      },
    };
  }

  initState() {
    super.initState();
    this.totals = {};

    this.type = '';
    this.editMode = false;
    this.paymentId = '';

    this.onClickCheckbox = () => {};

    this.onClickCharge = () => {};

    this.onClickPayment = () => {};

    this.onAllocationChange = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      clickCheckbox: e => this.onClickCheckbox(e),
      clickCharge: ({ currentTarget }) =>
        this.onClickCharge(currentTarget.lineItemId, currentTarget.invoiceId),
      clickPayment: ({ currentTarget }) =>
        this.onClickPayment(currentTarget.paymentId),
      allocationChange: e => this.onAllocationChange(e),
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        #header {
          background-color: white;
          position: sticky;
          top: 0;
          z-index: 1;
        }

        .container {
          height: auto;
        }

        .cell-data {
          padding: 10px 0px;
        }

        .totals {
          display: flex;
          padding: 18px ${CSS_SPACING};
          background-color: ${CSS_COLOR_GREY_3};
        }

        .text-bold {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .label {
          margin-right: 5px;
        }

        .text-field {
          width: 95%;
          padding-top: 10px;
        }

        .payment-ids {
          display: inline-flex;
          flex-wrap: wrap;
        }

        .link {
          cursor: pointer;
          color: ${CSS_COLOR_HIGHLIGHT};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          text-decoration: underline;
        }

        .title-cell {
          cursor: pointer;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      `,
    ];
  }

  __renderSelectAll() {
    if (!hasFeatureOrBetaSync(FEATURE_FLAGS.LS_BULK_DISCOUNT_SELECTION)) {
      return this.__renderSpacerCell(this.showSelectAll, 'cell-icon');
    }

    const selectableRows = this.model.filter(
      ({ discountDisabled }) => !discountDisabled,
    );

    return selectableRows.length
      ? html`
          <neb-checkbox
            class="cell-header cell-checkbox"
            id="${ELEMENTS.selectAllRows.id}"
            ?checked="${selectableRows.every(({ selected }) => selected)}"
            @click="${e => {
              this.handlers.selectAll();
              e.stopPropagation();
            }}"
          ></neb-checkbox>
        `
      : this.__renderSpacerCell(this.showSelectAll, 'cell-icon');
  }

  __renderCheckboxCell({ value, columnConfig, rowIndex }) {
    return html`
      <neb-checkbox
        class="cell-checkbox"
        name="${this.name}.${rowIndex}.${columnConfig.key}"
        id="checkbox-${rowIndex}"
        ?checked="${value.checked}"
        ?disabled="${value.disabled}"
        .onChange="${this.handlers.clickCheckbox}"
      ></neb-checkbox>
    `;
  }

  __renderProcedureCell({ value, rowIndex }) {
    return html`
      <div
        id="charge-link-${rowIndex}"
        class="link"
        .lineItemId="${this.model[rowIndex].id}"
        .invoiceId="${this.model[rowIndex].invoiceId}"
        @click="${this.handlers.clickCharge}"
      >
        ${value}
      </div>
    `;
  }

  __renderTitleAttrCell({ value, title }, key, rowIndex) {
    return html`
      <div id="${key}-${rowIndex}" class="title-cell" title="${title}">
        ${value}
      </div>
    `;
  }

  __renderTextfieldCell({ value, columnConfig, rowIndex }) {
    return html`
      <neb-textfield
        class="text-field"
        name="${this.name}.${rowIndex}.${columnConfig.key}"
        id="discount-allocated-${rowIndex}"
        helper=" "
        maxLength="13"
        .value="${value}"
        .error="${this.errors[rowIndex][columnConfig.key]}"
        .mask="${currency}"
        .inputMode="${'numeric'}"
        .onChange="${this.handlers.allocationChange}"
        .disabled="${!this.model[rowIndex].selected ||
        (this.type === DISCOUNT_TYPES.PERCENTAGE && !this.editMode)}"
      ></neb-textfield>
    `;
  }

  __renderPaymentId(payment, paymentIndex, lastRow, rowIndex) {
    return html`
      <div
        id="payment-link-${rowIndex}-${paymentIndex}"
        class="link"
        .paymentId="${payment.paymentId}"
        @click="${this.handlers.clickPayment}"
      >
        ${payment.paymentNumber}
      </div>

      ${lastRow ? '' : ','}&nbsp;
    `;
  }

  __renderPaymentIdsCell({ value, rowIndex }) {
    const paymentIdsWithoutThisDiscount = value.filter(
      v => v.paymentId !== this.paymentId,
    );

    return html`
      <div class="payment-ids">
        ${paymentIdsWithoutThisDiscount.map((v, index) =>
          this.__renderPaymentId(
            v,
            index,
            index === paymentIdsWithoutThisDiscount.length - 1,
            rowIndex,
          ),
        )}
      </div>
    `;
  }

  __renderTotals(id) {
    if (!this.config || !this.model.length) {
      return '';
    }

    const cells = this.config.map(
      cell => html`
        <span class="cell text-bold" style="flex: ${cell.flex}">
          ${this.totals[cell.key]}
        </span>
      `,
    );

    return html` <div id="${id}" class="totals">${cells}</div> `;
  }

  renderSubHeader() {
    return this.__renderTotals(ELEMENTS.totalsHeader.id);
  }

  renderDataCell(value, columnConfig, rowIndex) {
    switch (columnConfig.key) {
      case 'selected':
        return this.__renderCheckboxCell({ value, columnConfig, rowIndex });
      case 'procedure':
        return this.__renderProcedureCell({ value, rowIndex });
      case 'paymentIds':
        return this.__renderPaymentIdsCell({ value, rowIndex });
      case 'discountAllocated':
        return this.__renderTextfieldCell({ value, columnConfig, rowIndex });
      case 'taxAmount':
        return this.__renderTitleAttrCell(value, columnConfig.key, rowIndex);
      case 'patientPaid':
        return this.__renderTitleAttrCell(value, columnConfig.key, rowIndex);
      default:
        return super.renderDataCell(value, columnConfig, rowIndex);
    }
  }

  render() {
    return html`
      ${super.render()} ${this.__renderTotals(ELEMENTS.totalsFooter.id)}
    `;
  }
}

customElements.define(
  'neb-table-discount-allocation',
  NebTableDiscountAllocation,
);
