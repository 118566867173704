import '../../tables/code-bundles/neb-table-code-bundle-charges';
import '../../../../packages/neb-lit-components/src/components/neb-header';
import '../../../../packages/neb-lit-components/src/components/controls/neb-button-action';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-textarea';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import '../../../../packages/neb-lit-components/src/components/controls/neb-switch';

import { isRequired } from '@neb/form-validators';
import { openPopup } from '@neb/popup';
import { css, html } from 'lit';

import NebForm, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../../packages/neb-lit-components/src/components/forms/neb-form';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../../packages/neb-lit-components/src/utils/overlay-constants';
import { POPUP_RENDER_KEYS } from '../../../../packages/neb-popup/src/renderer-keys';
import { capitalize } from '../../../../packages/neb-utils/formatters';
import { getValueByPath } from '../../../../packages/neb-utils/utils';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  codeBundleName: { id: 'code-bundle-name' },
  codeBundleDescription: { id: 'code-bundle-description' },
  addDiagnosisButton: { id: 'button-add-diagnosis' },
  addChargeButton: { id: 'button-add-charge' },
  diagnosisTable: { id: 'table-diagnosis' },
  chargeTable: { id: 'table-charges' },
  activeSwitch: { id: 'switch-active' },
};

const APPEND_KEYS = [
  'chargeId',
  'procedure',
  'description',
  'modifiers.0',
  'modifiers.1',
  'modifiers.2',
  'modifiers.3',
  'active',
  'amount',
  'units',
];

const DIAGNOSIS_CONFIG = [
  {
    key: 'diagnosisCode',
    label: 'ICD10',
    flex: css`0 0 150px`,
  },
  {
    key: 'shortDescription',
    label: 'Description',
    flex: css`1 0 0`,
  },
];

const confirmRemove = type =>
  openPopup(POPUP_RENDER_KEYS.CONFIRM, {
    title: `Remove ${capitalize(type)}`,
    message: `This will remove the ${type} from the code bundle. Are you sure that you want to proceed?`,
    confirmText: 'Yes',
    cancelText: 'No',
  });

const formatDiagnoses = diagnoses =>
  diagnoses.map(d => ({
    label: `${d.diagnosisCode} - ${d.shortDescription}`,
    item: { diagnosisCode: d.diagnosisCode },
  }));

export default class NebFormAddCodeBundle extends NebForm {
  static get properties() {
    return {
      hasDHChargeUnitsFF: Boolean,
    };
  }

  static createModel() {
    return {
      name: '',
      diagnoses: [],
      charges: [],
      description: '',
      active: true,
    };
  }

  initState() {
    super.initState();

    this.hasDHChargeUnitsFF = false;
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,

      addDiagnosis: async () => {
        const selectedDiagnoses = await openOverlay(
          OVERLAY_KEYS.ADD_DIAGNOSIS,
          {
            selectedDiagnosis: JSON.parse(JSON.stringify(this.state.diagnoses)),
          },
        );

        this.formService.apply('diagnoses', selectedDiagnoses);
      },

      removeDiagnosis: async (_, item, index) => {
        const accepted = await confirmRemove('diagnosis');

        if (accepted) {
          this.formService.removeItem('diagnoses', index);

          this.state.charges.forEach((charge, chargeIndex) => {
            const pointerIndex = charge.diagnosisPointers.findIndex(
              pointer => pointer.item.diagnosisCode === item.diagnosisCode,
            );

            if (pointerIndex !== -1) {
              this.formService.removeItem(
                `charges.${chargeIndex}.diagnosisPointers`,
                pointerIndex,
              );
            }
          });
        }
      },

      reorderDiagnosis: (fromIndex, toIndex) => {
        this.formService.moveItem('diagnoses', fromIndex, toIndex);
      },

      addCharge: async () => {
        const stateChargesLength = this.state.charges.length;

        const selection = await openOverlay(OVERLAY_KEYS.SELECT_CHARGES, {
          charges: JSON.parse(JSON.stringify(this.state.charges)),
          description: 'Search for and select charges to add.',
          title: 'Add Charge',
        });

        const addedCharges = selection.filter(
          c => !this.state.charges.find(sc => sc.chargeId === c.chargeId),
        );

        addedCharges.forEach((charge, index) => {
          this.formService.addItem('charges');

          APPEND_KEYS.forEach(key =>
            this.formService.apply(
              `charges.${index + stateChargesLength}.${key}`,
              getValueByPath(charge, key.split('.')),
            ),
          );
        });
      },

      removeCharge: async (_, item, index) => {
        const accepted = await confirmRemove('charge');

        if (accepted) {
          this.formService.removeItem('charges', index);
        }
      },

      reorderCharge: (fromIndex, toIndex) => {
        this.formService.moveItem('charges', fromIndex, toIndex);
      },
    };
  }

  createSelectors() {
    return {
      children: {
        name: [isRequired()],
        diagnoses: {
          unsafe: true,
          clipPristine: true,
        },
        charges: {
          createItem: () => ({
            chargeId: '',
            procedure: '',
            description: '',
            modifiers: ['', '', '', ''],
            diagnosisPointers: [],
            active: false,
            amount: 0,
            units: 1,
          }),

          children: {
            $: {
              children: {
                diagnosisPointers: {
                  unsafe: true,
                  clipPristine: true,
                  validators: [],
                  format: (pointers, _, model) =>
                    formatDiagnoses(
                      model.diagnoses
                        ? model.diagnoses.filter(dx =>
                            pointers.find(
                              dp => dp.diagnosisCode === dx.diagnosisCode,
                            ),
                          )
                        : pointers,
                    ),

                  unformat: v =>
                    v.map(dx => ({ diagnosisCode: dx.item.diagnosisCode })),
                },
              },
            },
          },
        },
      },
    };
  }

  __renderDiagnosisTable() {
    if (this.state.diagnoses.length > 0) {
      return html`
        <neb-table
          id="${ELEMENTS.diagnosisTable.id}"
          .config="${DIAGNOSIS_CONFIG}"
          .model="${this.state.diagnoses}"
          .showRemoveButton="${true}"
          .onRemove="${this.handlers.removeDiagnosis}"
          .reorder="${true}"
          .onReorder="${this.handlers.reorderDiagnosis}"
        ></neb-table>
      `;
    }
    return '';
  }

  __renderAddDiagnosis() {
    return html`
      <neb-header label="Diagnoses"></neb-header>

      <div class="grid grid-auto-left">
        <neb-button-action
          id="${ELEMENTS.addDiagnosisButton.id}"
          class="pad"
          label="Add Diagnosis"
          .onClick="${this.handlers.addDiagnosis}"
        ></neb-button-action>
      </div>
    `;
  }

  __getChargesConfig() {
    return [
      {
        key: 'procedure',
        label: 'Procedure',
        flex: css`0 0 150px`,
      },
      {
        key: 'description',
        label: 'Description',
        flex: css`3 0 0`,
      },
      {
        key: 'modifiers',
        label: 'Modifiers',
        flex: css`1 0 0`,
        formatter: values =>
          values ? values.filter(item => item).join(', ') : '',
      },
      ...(this.hasDHChargeUnitsFF
        ? [
            {
              key: 'units',
              label: 'Units',
              flex: css`0 0 80px`,
            },
          ]
        : []),
      {
        key: 'diagnosisPointers',
        label: 'Diagnosis Pointer',
        flex: css`0 0 150px`,
      },
    ];
  }

  __renderChargesTable() {
    const diagnoses = formatDiagnoses(this.state.diagnoses);

    if (this.state.charges.length > 0) {
      return html`
        <neb-table-code-bundle-charges
          id="${ELEMENTS.chargeTable.id}"
          .model="${this.state.charges}"
          .config="${this.__getChargesConfig()}"
          .diagnoses="${diagnoses}"
          .onChange="${this.handlers.change}"
          .showRemoveButton="${true}"
          .onRemove="${this.handlers.removeCharge}"
          .reorder="${true}"
          .onReorder="${this.handlers.reorderCharge}"
        ></neb-table-code-bundle-charges>
      `;
    }
    return '';
  }

  __renderAddCharge() {
    return html`
      <neb-header label="Charges"></neb-header>

      <div class="grid grid-auto-left">
        <neb-button-action
          id="${ELEMENTS.addChargeButton.id}"
          class="pad"
          label="Add Charge"
          .onClick="${this.handlers.addCharge}"
        ></neb-button-action>
      </div>
    `;
  }

  __renderBundleName() {
    return html`
      <neb-textfield
        id="${ELEMENTS.codeBundleName.id}"
        class="grid pad spacer-top"
        helper="Required"
        label="Bundle Name"
        maxLength="50"
        name="name"
        value="${this.state.name}"
        .error="${this.errors.name}"
        .items="${this.__items}"
        .onChange="${this.handlers.change}"
      ></neb-field>
    `;
  }

  __renderBundleDescription() {
    return html`
      <neb-textarea
        id="${ELEMENTS.codeBundleDescription.id}"
        class="grid"
        label="Description"
        maxLength="255"
        name="description"
        value="${this.state.description}"
        .onChange="${this.handlers.change}"
        showCount
      ></neb-textarea>
    `;
  }

  __renderActiveToggle() {
    return html`
      <neb-switch
        id="${ELEMENTS.activeSwitch.id}"
        class="grid"
        label="Active"
        name="active"
        .on="${this.state.active}"
        .onChange="${this.handlers.change}"
      ></neb-switch>
    `;
  }

  renderContent() {
    return html`
      ${this.__renderBundleName()}${this.__renderBundleDescription()}
      ${this.__renderActiveToggle()} ${this.__renderAddDiagnosis()}
      ${this.__renderDiagnosisTable()}${this.__renderAddCharge()}
      ${this.__renderChargesTable()}
    `;
  }
}

customElements.define('neb-form-add-code-bundle', NebFormAddCodeBundle);
