/* eslint-disable complexity */
import moment from 'moment-timezone';

import { Method } from '../../../packages/neb-api-client/src/utils/api-client-utils';
import ApiClientV2 from '../../../packages/neb-api-client/src/utils/api-client-v2';
import { computeTime } from '../../../packages/neb-input/nebFormatUtils';
import { store } from '../../../packages/neb-redux/neb-redux-store';
import { parseDate } from '../../../packages/neb-utils/date-util';

export const apiClient = new ApiClientV2({
  microservice: 'pdf',
});

const DT_PDF_FORMAT = 'ddd MM/DD/YY h:mm A';

const getProviderName = (providerId, providers) => {
  if (!providerId || !providers || !providers.length) return '';

  const provider = providers.find(({ id }) => providerId === id);
  if (!provider || !provider.name) return '';

  const { first, last, preferred } = provider.name;

  const firstName = preferred || first || '';
  const lastName = last || '';

  if (!firstName && !lastName) return '';

  const firstInitial = firstName.charAt(0);

  if (!lastName) return firstName;
  if (!firstInitial) return lastName;

  return `${lastName}, ${firstInitial}.`;
};

const getFormattedStatus = (status, isRescheduled) => {
  if (isRescheduled) {
    return 'Rescheduled';
  }
  return status || '';
};

const getAppointmentsInfo = (appointments, locations, providers) =>
  appointments.map(appt => {
    const {
      end,
      start,
      locationId,
      appointmentType,
      providerId,
      status,
      isRescheduled,
    } = appt;

    const duration = computeTime(moment(end).diff(moment(start)), true);

    let locationName = '';

    if (locationId && locations && locations.length) {
      const location = locations.find(({ id }) => id === locationId);

      if (location) {
        locationName = location.name;
      }
    }

    const formattedTime = parseDate(moment(start)).format(DT_PDF_FORMAT);
    const providerName = providerId
      ? getProviderName(providerId, providers)
      : '';
    const appointmentTypeName =
      appointmentType && appointmentType.name ? appointmentType.name : '';

    return {
      appointmentType: appointmentTypeName,
      providerName,
      duration,
      locationId,
      locationName,
      formattedTime,
      status: getFormattedStatus(status, isRescheduled),
      startTime: start,
    };
  });

const getPracticeInformation = ({ id, name, hideLogo }) => ({
  id,
  name,
  hideLogo,
});

const getAppointmentLocations = (apptsInfo, locations) => {
  const appointmentLocationIds = [
    ...new Set(apptsInfo.map(({ locationId }) => locationId)),
  ];

  if (!locations || !locations.length) return [];

  return locations
    .filter(({ id }) => appointmentLocationIds.includes(id))
    .sort(({ name: a }, { name: b }) => a.localeCompare(b))
    .map(
      ({
        id,
        name,
        phoneNumber,
        emailAddress,
        address1,
        address2,
        city,
        state,
        zipCode,
      }) => ({
        id,
        name,
        phoneNumber,
        emailAddress,
        address1,
        address2,
        city,
        state,
        zipCode,
      }),
    );
};

export const prepareFilteredAppointmentsData = ({
  patientName,
  futureAppointments: incomingFutureAppointments,
  pastAppointments: incomingPastAppointments,
  currentDate,
}) => {
  const practiceInfo = {
    ...store.getState().practiceInformation.item,
    hideLogo: false,
  };

  const providers = store.getState().providers.item;

  const currentDateIso =
    currentDate || parseDate().startOf('day').toISOString();

  let futureAppointments = [];
  let pastAppointments = [];

  if (incomingFutureAppointments && incomingFutureAppointments.length > 0) {
    futureAppointments = getAppointmentsInfo(
      incomingFutureAppointments,
      practiceInfo.locations,
      providers,
    );
  }

  if (incomingPastAppointments && incomingPastAppointments.length > 0) {
    pastAppointments = getAppointmentsInfo(
      incomingPastAppointments,
      practiceInfo.locations,
      providers,
    );
  }

  const allAppointmentsInfo = [...futureAppointments, ...pastAppointments];

  return {
    futureAppointments,
    pastAppointments,
    practiceInformation: getPracticeInformation(practiceInfo),
    patientName,
    locations: getAppointmentLocations(
      allAppointmentsInfo,
      practiceInfo.locations,
    ),
    currentDate: currentDateIso,
  };
};

export async function printFilteredAppointments(params) {
  const res = await apiClient.makeRequest({
    path: '/api/v1/tenants/:tenantId/appointments/print-filtered',
    method: Method.POST,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  });

  return res.buffer;
}
