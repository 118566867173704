import { css, html } from 'lit';

import '../../../../../packages/neb-lit-components/src/components/neb-action-bar';
import '../../../../../packages/neb-lit-components/src/components/neb-popup-header';
import '../../../../../packages/neb-lit-components/src/components/controls/neb-button-action';
import '../../../../../packages/neb-lit-components/src/components/neb-text';
import Overlay from '../../../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import { moveItem } from '../../../../../packages/neb-utils/utils';
import { CSS_SPACING, CSS_COLOR_ERROR } from '../../../../styles';
import '../../../tables/ledger/ar-hub/neb-table-manage-columns';

export const ELEMENTS = {
  header: { id: 'header' },
  table: { id: 'table' },
  actionBar: { id: 'action-bar' },
  errorMessage: { id: 'error-message' },
  noColumnsMessage: { id: 'no-columns-message' },
};

export const AR_HUB_COLUMNS_TABLE_CONFIG = [
  {
    key: 'name',
    label: '',
    flex: css`1 0 0`,
    formatter: (value, row) => html`
      <div>
        <neb-text bold>${row.label}</neb-text>
      </div>
    `,
  },
  {
    key: 'visibility',
    label: '',
    width: '50px',
    align: 'right',
  },
];

class NebOverlayManageColumns extends Overlay {
  static get properties() {
    return {
      __disableApplyButton: { type: Boolean },
      __availableColumns: { type: Array },
      __showErrorMessage: { type: Boolean },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          width: 500px;
        }

        .header {
          padding: ${CSS_SPACING};
        }

        .button {
          padding: 0 0 ${CSS_SPACING} ${CSS_SPACING};
        }

        neb-table-manage-columns::part(cell-data) {
          padding: ${CSS_SPACING};
        }

        neb-table-manage-columns::part(row-header) {
          display: none;
        }

        neb-table-manage-columns {
          overflow-y: auto;
        }

        .error-message {
          color: ${CSS_COLOR_ERROR};
          padding: 0 ${CSS_SPACING};
          font-size: 12px;
          margin-top: -5px;
        }

        .no-columns-message {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: ${CSS_SPACING};
          height: 100px;
        }
      `,
    ];
  }

  constructor() {
    super();
    this.__initState();
    this.__initHandlers();
  }

  update(changedProperties) {
    super.update(changedProperties);

    if (changedProperties.has('model')) {
      this.__availableColumns = this.__sortColumnsByVisibility();
    }
  }

  __initState() {
    super.initState();

    this.__disableApplyButton = false;
    this.__showErrorMessage = false;
    this.__availableColumns = [];
  }

  __initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      reorder: (fromIndex, toIndex) => {
        this.__availableColumns = moveItem(
          this.__availableColumns,
          fromIndex,
          toIndex,
        );
      },
      toggleVisibility: (e, index) => {
        const newAvailableColumns = [...this.__availableColumns];
        newAvailableColumns[index] = {
          ...newAvailableColumns[index],
          selected: !newAvailableColumns[index].selected,
        };

        this.__availableColumns = newAvailableColumns;

        this.__showErrorMessage = !this.getSelectedColumnsItems().length;
        this.__disableApplyButton = this.__showErrorMessage;
      },
      apply: () => {
        if (!this.getSelectedColumnsItems().length) {
          this.__showErrorMessage = true;
          this.__disableApplyButton = true;
          return;
        }

        this.dismiss({
          selectedColumns: this.getSelectedColumnsItems(),
          allColumns: this.__availableColumns,
        });
      },
    };
  }

  getSelectedColumnsItems() {
    return this.__availableColumns.filter(item => item.selected);
  }

  __renderHeader() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        title="Manage Columns"
        subTitle="Choose which columns to display in the AR Hub table."
        .onCancel="${this.handlers.dismiss}"
        showCancelButton
      ></neb-popup-header>
    `;
  }

  __renderErrorMessage() {
    return this.__showErrorMessage
      ? html`
          <div id="${ELEMENTS.errorMessage.id}" class="error-message">
            Select at least one column to appear on the view
          </div>
        `
      : html``;
  }

  __renderNoColumnsMessage() {
    return html`
      <div id="${ELEMENTS.noColumnsMessage.id}" class="no-columns-message">
        No Columns Selected to View
      </div>
    `;
  }

  renderContent() {
    return html`
      ${this.__renderHeader()} ${this.__renderErrorMessage()}
      ${this.getSelectedColumnsItems().length === 0 && !this.__showErrorMessage
        ? this.__renderNoColumnsMessage()
        : html`
            <neb-table-manage-columns
              id="${ELEMENTS.table.id}"
              .config="${AR_HUB_COLUMNS_TABLE_CONFIG}"
              .model="${this.__availableColumns}"
              .reorder="${true}"
              .onReorder="${this.handlers.reorder}"
              .onToggleVisibility="${this.handlers.toggleVisibility}"
            ></neb-table-manage-columns>
          `}
      <neb-action-bar
        id="${ELEMENTS.actionBar.id}"
        confirmLabel="Apply"
        .onConfirm="${this.handlers.apply}"
        .onCancel="${this.handlers.dismiss}"
        .disableConfirm="${this.__disableApplyButton}"
      ></neb-action-bar>
    `;
  }

  __sortColumnsByVisibility() {
    const visibleColumns = this.model.availableColumns.filter(
      column => column.selected,
    );
    const nonVisibleColumns = this.model.availableColumns.filter(
      column => !column.selected,
    );

    return [...visibleColumns, ...nonVisibleColumns];
  }
}

customElements.define('neb-overlay-manage-columns', NebOverlayManageColumns);
