import '../../../../packages/neb-lit-components/src/components/neb-popup-header';

import { html, css } from 'lit';

import {
  openSuccess,
  openError,
} from '../../../../packages/neb-dialog/neb-banner-state';
import Overlay from '../../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import {
  CSS_SPACING,
  OVERLAY_WIDTH_LARGE,
} from '../../../../packages/neb-styles/neb-variables';
import {
  FEATURE_FLAGS,
  hasFeatureOrBeta,
} from '../../../../packages/neb-utils/feature-util';
import * as api from '../../../api-clients/code-bundles';
import NebFormAddCodeBundle from '../../forms/settings/neb-form-add-code-bundle';

export const ELEMENTS = {
  header: {
    id: 'header',
  },
  form: {
    id: 'form',
  },
};

class NebOverlayAddCodeBundle extends Overlay {
  static get properties() {
    return {
      __savingError: Object,
      __formModel: Object,
      __hasDHChargeUnitsFF: Boolean,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          width: ${OVERLAY_WIDTH_LARGE};
        }

        .description {
          padding: 5px 0 0 ${CSS_SPACING};
        }

        .header {
          padding: ${CSS_SPACING};
        }
      `,
    ];
  }

  initState() {
    super.initState();
    this.__savingError = null;
    this.__formModel = NebFormAddCodeBundle.createModel();
    this.__hasDHChargeUnitsFF = false;
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      save: async model => {
        const {
          context: { providerId },
        } = this.model;

        try {
          await (this.__formModel.id
            ? api.updateCodeBundle(model)
            : api.createCodeBundle(providerId, model));

          this.isDirty = false;
          this.dismiss(model);
          store.dispatch(openSuccess('Code Bundle saved successfully'));
          this.__savingError = null;
        } catch (err) {
          store.dispatch(openError('Unable to save Code Bundle'));
          this.__savingError = err;
          console.error(err);
        }
      },
    };
  }

  async connectedCallback() {
    this.__hasDHChargeUnitsFF = await hasFeatureOrBeta(
      FEATURE_FLAGS.DH_CHARGE_UNITS,
    );

    try {
      this.__formModel = this.model.item
        ? await api.getCodeBundle(this.model.item.id)
        : NebFormAddCodeBundle.createModel();
    } catch (err) {
      store.dispatch(
        openError('An error occurred while fetching the Code Bundle'),
      );

      console.error(err);
    }

    super.connectedCallback();
  }

  __getTitle() {
    return `${this.__formModel.id ? 'Update' : 'Add'} Code Bundle`;
  }

  renderContent() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        .title="${this.__getTitle()}"
        .onCancel="${this.handlers.dismiss}"
        .onBack="${this.handlers.dismiss}"
        showCancelButton
      ></neb-popup-header>

      <neb-form-add-code-bundle
        id="${ELEMENTS.form.id}"
        .model="${this.__formModel}"
        .hasDHChargeUnitsFF="${this.__hasDHChargeUnitsFF}"
        .savingError="${this.__savingError}"
        .onSave="${this.handlers.save}"
        .onCancel="${this.handlers.dismiss}"
        .onChangeDirty="${this.handlers.dirty}"
      ></neb-form-add-code-bundle>
    `;
  }
}

window.customElements.define(
  'neb-overlay-add-code-bundle',
  NebOverlayAddCodeBundle,
);
