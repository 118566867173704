import './neb-search';
import '../../src/components/misc/neb-notifications-counter';

import { html, css } from 'lit';

import { getLastTrackedLocation } from '../../src/utils/recent-records';
import {
  ELEMENTS as BASE_ELEMENTS,
  NebNavSecondaryBase,
} from '../neb-lit-components/src/components/neb-nav-secondary-base';
import {
  OVERLAY_KEYS,
  openOverlay,
} from '../neb-lit-components/src/utils/overlay-constants';
import { searchPatients } from '../neb-patient/src/store/patientsAction';
import { store, connect } from '../neb-redux/neb-redux-store';
import { navigate } from '../neb-route/neb-route-state';
import {
  CSS_COLOR_GREY_2,
  CSS_SECONDARY_NAV_SEARCH_WIDTH,
} from '../neb-styles/neb-variables';
import { hasFeatureOrBeta, FEATURE_FLAGS } from '../neb-utils/feature-util';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  search: { id: 'search' },
  actionContainer: { id: 'action-container' },
  notificationsCounter: { id: 'notifications-counter' },
};

class NebNavSecondaryLit extends connect(store)(NebNavSecondaryBase) {
  static get properties() {
    return {
      __route: String,
      __search: String,
      __overlayKey: String,
      __overlayStack: Array,
      __hasNotificationsFeatureFlag: Boolean,

      searchBoxFocus: Boolean,
    };
  }

  initState() {
    super.initState();

    this.__route = '';
    this.__search = '';
    this.__overlayKey = '';
    this.__overlayStack = [];
    this.searchBoxFocus = false;

    this.__hasNotificationsFeatureFlag = false;
  }

  async connectedCallback() {
    super.connectedCallback();

    this.__hasNotificationsFeatureFlag = await hasFeatureOrBeta(
      FEATURE_FLAGS.LS_NOTIFICATIONS,
    );
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      search: v => store.dispatch(searchPatients(v)),
      openPatientOverlay: async () => {
        const [, subApp, id] = this.__route.split('/');
        const atPatientRoot = subApp === 'patients' && !id;
        const inListOverlay = this.__overlayKey === 'patient-list';

        if (!atPatientRoot && !inListOverlay) {
          const args = { patientId: id || '' };

          const patient = await openOverlay(OVERLAY_KEYS.PATIENT_LIST, args);

          if (patient) {
            this.__navigate(patient);
          }
        }
      },
    };
  }

  __navigate({ id, lastLocation }) {
    const route = getLastTrackedLocation({
      id,
      lastLocation,
      isMobile: this.layout === 'small',
    });

    return this.navigate(`#/patients/${route}`);
  }

  _stateChanged({ route, popup, patients }) {
    if (route) this.__route = route.hash;
    if (patients) this.__search = patients.search;
    if (popup) this.__overlayStack = popup.overlays;

    if (this.__overlayStack) {
      const lastOverlay = this.__overlayStack[this.__overlayStack.length - 1];

      this.__overlayKey = lastOverlay ? lastOverlay.key : '';
    }
  }

  navigate(path) {
    store.dispatch(navigate(path));
  }

  update(changedProps) {
    if (changedProps.has('__overlayKey')) {
      store.dispatch(searchPatients(''));
    }

    super.update(changedProps);
  }

  updated(changedProps) {
    if (changedProps.has('searchBoxFocus') && this.searchBoxFocus) {
      const searchBoxField = this.shadowRoot.getElementById(ELEMENTS.search.id);
      setTimeout(() => searchBoxField.focus(), 0);
    }

    super.updated(changedProps);
  }

  static get styles() {
    return [
      super.styles,
      css`
        .search {
          width: ${CSS_SECONDARY_NAV_SEARCH_WIDTH};
          border-right: solid 1px ${CSS_COLOR_GREY_2};
        }

        .actions-container {
          display: flex;
          border-right: solid 1px ${CSS_COLOR_GREY_2};
          flex: 1 0 0;
          margin-right: 17px;
        }
      `,
    ];
  }

  __renderNotificationsCounter() {
    if (!this.__hasNotificationsFeatureFlag) {
      return '';
    }

    return html`
      <neb-notifications-counter
        id="${ELEMENTS.notificationsCounter.id}"
      ></neb-notifications-counter>
    `;
  }

  renderActionContent() {
    return html`
      <div id="${ELEMENTS.actionContainer.id}" class="actions-container">
        <slot name="actions"></slot>
      </div>
      ${this.__renderNotificationsCounter()}
    `;
  }

  renderLeftContent() {
    return html`
      <neb-search
        id="${ELEMENTS.search.id}"
        class="search"
        label="Search Patients..."
        .value="${this.__search}"
        .onChange="${this.handlers.search}"
        @click="${this.handlers.openPatientOverlay}"
      ></neb-search>
    `;
  }
}

customElements.define('neb-nav-secondary-lit', NebNavSecondaryLit);
