import { getLocations } from '../../../../src/api-clients/locations';
import {
  getAdjustmentsWithCP,
  getPatientOwedTotal,
} from '../../../../src/utils/ledger-form/neb-ledger-form-util';
import { currencyToCents } from '../../../neb-utils/formatters';
import { LINE_ITEM_TYPE } from '../../../neb-utils/neb-ledger-util';
import { mapToPatientInsuranceModel } from '../../../neb-utils/patientInsurance';
import {
  formatToBilling,
  formatToModifiers,
} from '../formatters/line-item-details';
import { getProviderUsers } from '../practice-users-api-client';
import { Method, RESPONSE_TYPE } from '../utils/api-client-utils';
import ApiClientV2 from '../utils/api-client-v2';

export const apiClient = new ApiClientV2({ microservice: 'billing' });

const BILL_TYPE = {
  SELF: 'selfPay',
  PACKAGE: 'carePackage',
  INSURANCE: 'insurance',
};

export const getBulkActionList = ({ lineItemIds }) =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: '/api/v3/tenants/:tenantId/line-items-bulk-action-list',
    queryParams: { lineItemIds },
    headers: {
      'Content-Type': 'application/json',
    },
  });

function mapToModel(raw, providers, locations) {
  const foundProvider = providers.find(p => p.id === raw.providerId);
  const foundLocation = locations.find(
    location => raw.locationId === location.id,
  );

  const location = foundLocation ? foundLocation.name : '';
  const provider = foundProvider
    ? `${foundProvider.lastName}, ${foundProvider.firstName}`
    : '';

  return {
    adjustment: raw.adjustment,
    allowedAmount: raw.allowedAmount,
    appointmentTypeId: raw.appointmentTypeId || null,
    associatedERAsAndEOBs: raw.associatedERAsAndEOBs || [],
    balance: raw.balance,
    billedAmount: raw.billedAmount,
    billedPrimary: raw.billedPrimary || false,
    billedSecondary: raw.billedSecondary || false,
    billedPatient: raw.billedPatient || false,
    billedPaidInFull: raw.billedPaidInFull || false,
    billedPaymentReceived: raw.billedPaymentReceived || false,
    billedWaiting: raw.billedWaiting || false,
    billType: raw.billType,
    chargeHasNotes: raw.chargeHasNotes || false,
    chargeNumber: raw.chargeNumber,
    chargeId: raw.chargeId || null,
    claimId: raw.claimId || null,
    claimNumber: raw.claimNumber || null,
    claimStatus: raw.claimStatus || null,
    claimIsElectronic: raw.claimIsElectronic || null,
    claimStatusEffectiveDate: raw.claimStatusEffectiveDate || null,
    code: raw.code,
    description: raw.description || '',
    dateOfService: raw.dateOfService,
    encounterId: raw.encounterId || null,
    encounterNumber: raw.encounterNumber,
    guarantorId: raw.guarantorId || '',
    holdClaim: raw.holdClaim || false,
    holdStatement: raw.holdStatement || false,
    holdSuperBill: raw.holdSuperBill || false,
    id: raw.id,
    invoiceHasNotes: raw.invoiceHasNotes || false,
    invoiceId: raw.invoiceId,
    invoiceNumber: raw.invoiceNumber,
    locationId: raw.locationId || '',
    location,
    modifiers: [raw.modifier_1, raw.modifier_2, raw.modifier_3, raw.modifier_4],
    patientCaseId: raw.patientCaseId || '',
    patientId: raw.patientId,
    patientOwed: raw.patientOwed,
    patientPackageId: raw.patientPackageId || '',
    patientPaid: raw.patientPaid,
    primaryDiagnosis: raw.primaryDiagnosis || '',
    primaryOwed: raw.primaryOwed,
    primaryPaid: raw.primaryPaid,
    primaryPayerId: raw.primaryPayerId || '',
    primaryPlanId: raw.primaryPlanId || '',
    providerId: raw.providerId || '',
    provider,
    secondaryOwed: raw.secondaryOwed,
    secondaryPaid: raw.secondaryPaid,
    secondaryPlanId: raw.secondaryPlanId || '',
    signed: raw.signed || null,
    suppressFromClaim: raw.suppressFromClaim,
    taxAmount: raw.taxAmount,
    units: raw.units,
    recoups: raw.recoups || [],
    ...(raw.type && { type: raw.type }),
  };
}

const getRawLineItems = (
  lineItemIds,
  version,
  optOutLoadingIndicator = false,
) => {
  const path =
    version === 3
      ? '/api/v3/tenants/:tenantId/line-items/list'
      : '/api/v1/tenants/:tenantId/line-items/list';

  const body = JSON.stringify({ lineItemIds });

  return apiClient.makeRequest({
    path,
    method: Method.POST,
    headers: {
      'Content-Type': 'application/json',
    },
    body,
    optOutLoadingIndicator,
    cacheKey: `${path}-${body}`,
    forceCache: true,
  });
};

export const getPatientsWithOpenCharges = async ({
  params,
  hasAllocationPagePerformanceFF = false,
}) => {
  const { lineItemIds, ...queryParams } = params;
  const body = JSON.stringify({
    ...(lineItemIds?.length ? { lineItemIds } : {}),
  });
  const path = hasAllocationPagePerformanceFF
    ? '/api/v3/tenants/:tenantId/line-items/patients'
    : '/api/v2/tenants/:tenantId/line-items/patients';

  const { data: patientIds } = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path,
    method: Method.POST,
    queryParams,
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });

  return patientIds;
};

export const getLineItems = async (lineItemIds, version = 3, opts = {}) => {
  const [providers, locations, res] = await Promise.all([
    opts?.providers ? opts.providers : getProviderUsers(true),
    opts?.locations ? opts.locations : getLocations({ hideAddressOnly: true }),
    getRawLineItems(lineItemIds, version, true),
  ]);

  return res.map(r => mapToModel(r, providers, locations));
};

export const getLineItemDetails = async (
  queryParams,
  lineItemIds = {},
  version = 1,
) => {
  const path =
    version === 1
      ? '/api/v1/tenants/:tenantId/line-items/details'
      : '/api/v3/tenants/:tenantId/line-items/details';
  const body = JSON.stringify(lineItemIds);

  const { data: lineItems } = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path,
    queryParams,
    method: Method.POST,
    headers: {
      'Content-Type': 'application/json',
    },
    body,
    cacheKey: `${path}-${JSON.stringify(queryParams)}-${body}`,
    forceCache: true,
  });

  return lineItems;
};

export const getPatientRelatedInfo = async patientIds => {
  const res = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: '/api/v1/tenants/:tenantId/line-items/patient-related-info',
    method: Method.POST,
    headers: {
      'Content-Type': 'application/json',
    },
    cacheKey: `/api/v1/tenants/:tenantId/line-items/patient-related-info-${JSON.stringify(
      patientIds,
    )}`,
    body: JSON.stringify({ patientIds }),
  });

  return {
    insurances: res.insurances.map(data => ({
      label: data.planName,
      data: mapToPatientInsuranceModel(data),
    })),
    packages: res.packages.map(data => ({
      label: data.name,
      data,
    })),
  };
};

export const createLineItems = body =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: '/api/v1/tenants/:tenantId/line-items',
    method: Method.POST,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

export const createLineItemsV2 = body =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: '/api/v2/tenants/:tenantId/line-items',
    method: Method.POST,
    body,
  });

export const savePayerInfo = async ({
  patientId,
  invoiceId,
  lineItemIds,
  model,
  multiCarePackageEnabled = false,
}) => {
  const hasInsurance = model.billType === BILL_TYPE.INSURANCE;
  const insuranceId = hasInsurance ? model.insuranceId : '';

  const body = JSON.stringify({
    invoiceId,
    lineItemIds,
    billType: model.billType,
    caseId: model.caseId || null,
    patientAuthorizationId: model.patientAuthorizationId || null,
    guarantorId: model.guarantorId || null,
    primaryPayerId: model.payerId || null,
    primaryPlanId: insuranceId || null,
    secondaryPlanId: model.secondaryInsuranceId || null,
    patientPackageId: model.packageId || null,
    secondaryPayerId: model.secondaryPayerPlanId || null,
    multiCarePackageEnabled,
  });

  const res = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: '/api/v3/tenants/:tenantId/patients/:patientId/line-item-headers',
    replacements: { patientId },
    body,
    method: Method.PUT,
    headers: { 'Content-Type': 'application/json' },
    responseType: RESPONSE_TYPE.RAW,
  });

  return res;
};

export const saveLineItems = charges =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: '/api/v2/tenants/:tenantId/line-items',
    method: Method.PUT,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      lineItems: formatToBilling(charges),
      hasAutoUnallocate: true,
    }),
  });

export const saveLineItemsModifiers = charges =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: '/api/v2/tenants/:tenantId/line-items',
    method: Method.PUT,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      lineItems: formatToModifiers(charges),
    }),
  });

const deleteItems = queryParams =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: '/api/v2/tenants/:tenantId/line-items',
    queryParams,
    method: Method.DELETE,
  });

export const unpostFromLedger = postedToLedgerIds =>
  deleteItems({
    ids: postedToLedgerIds,
    notifyCharting: true,
    unpostFromCharting: true,
  });

export const voidLineItems = ({ lineItemIds, notifyCharting = true }) =>
  deleteItems({ ids: lineItemIds, notifyCharting, unpostFromCharting: false });

export const buildPatientPackageAssociations = async ({
  lineItems,
  patientOwedChanged = false,
  changedLineItemIndex = 0,
}) => {
  const formattedLineItems = lineItems.reduce((acc, item, currentIndex) => {
    const billedAmount =
      typeof item.billedAmount === 'number'
        ? item.billedAmount
        : currencyToCents(String(item.billedAmount));

    const taxAmount =
      typeof item.taxAmount === 'number'
        ? item.taxAmount
        : currencyToCents(String(item.taxAmount));

    const amount = patientOwedChanged
      ? getPatientOwedTotal(item) +
        (currentIndex !== changedLineItemIndex
          ? getAdjustmentsWithCP(item.adjustments)
          : 0)
      : billedAmount + taxAmount;

    return item.type === LINE_ITEM_TYPE.ENCOUNTER_CHARGE && amount > 0
      ? [
          ...acc,
          {
            id: item.id,
            units: item.units,
            amount,
          },
        ]
      : acc;
  }, []);

  if (formattedLineItems.length > 0) {
    const { lineItems: patientPackageAssociations = [] } =
      await apiClient.makeRequest({
        optOutLoadingIndicator: false,
        path: '/api/v1/tenants/:tenantId/line-items/build-patient-package-associations',
        method: Method.POST,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          lineItems: formattedLineItems,
        }),
      });

    return patientPackageAssociations.reduce((acc, lineItem) => {
      acc[lineItem.id] = lineItem;
      return acc;
    }, {});
  }

  return {};
};
