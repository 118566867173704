import '../../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-textarea';
import { html, css } from 'lit';

import {
  openWarning,
  openError,
  openSuccess,
} from '../../../../packages/neb-dialog/neb-banner-state';
import { BUTTON_ROLE } from '../../../../packages/neb-lit-components/src/components/neb-button';
import NebPopup from '../../../../packages/neb-popup/src/neb-popup';
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import { CSS_SPACING } from '../../../../packages/neb-styles/neb-variables';
import { saveArHubCustomView } from '../../../api-clients/ar-hub';
import { formatViewToSave } from '../../../formatters/ar-hub';
import { CSS_SMALL_SPACING } from '../../../styles';

export const AR_HUB_SAVE_SUCCESS_MESSAGE = 'View has been saved successfully';
export const AR_HUB_CONFLICT_ERROR_MESSAGE = 'View name already exists';
export const AR_HUB_SAVE_ERROR_MESSAGE = 'An error occurred while saving view';

export const ELEMENTS = {
  buttonConfirm: {
    id: 'button-confirm',
  },
  buttonCancel: {
    id: 'button-cancel',
  },
  textfieldName: {
    id: 'text-field-name',
  },
  textareaDescription: {
    id: 'text-field-description',
  },
};

class NebPopupSaveNewView extends NebPopup {
  static get properties() {
    return {
      viewName: String,
      viewDescription: String,
      model: Object,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          width: 450px;
        }

        .content-container {
          display: flex;
          flex-direction: column;
        }

        .button-container {
          display: flex;
          gap: ${CSS_SMALL_SPACING};
          margin-top: ${CSS_SPACING};
        }

        .textfield-container {
          display: flex;
          flex-direction: column;
        }

        neb-textarea {
          height: 100px;
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.title = 'Save View As';
    this.viewName = '';
    this.viewDescription = '';
    this.model = {
      viewOrderBy: 'patient',
      viewSortAsc: false,
      filters: {
        providers: [],
        locations: [],
        claimStatuses: [],
        billTypes: [],
        billedStatuses: [],
        payers: [],
        procedureCodes: [],
        adjustments: [],
        dosFrom: null,
        dosTo: null,
        daysAged: null,
        payerBalance: null,
        patientBalance: null,
        primaryBalance: null,
        secondaryBalance: null,
        adjustmentBalance: null,
        followUpExists: null,
        followUpDaysUntilDue: null,
        followUpCompleted: null,
      },
      columns: [],
    };
  }

  initHandlers() {
    super.initHandlers();

    this.__handlers = {
      ...this.handlers,
      changeName: ({ value }) => {
        this.viewName = value;
      },
      changeDescription: ({ value }) => {
        this.viewDescription = value;
      },
      save: async () => {
        const providerId = store.getState().session.item.id;

        try {
          const { viewOrderBy, viewSortAsc, filters, columns } = this.model;

          const view = formatViewToSave({
            id: null,
            name: this.viewName,
            description: this.viewDescription,
            orderBy: viewOrderBy || 'patient',
            sortAsc: viewOrderBy ? viewSortAsc : true,
            providerId,
            columns,
            filters,
          });

          const savedView = await saveArHubCustomView(view);

          store.dispatch(openSuccess(AR_HUB_SAVE_SUCCESS_MESSAGE));
          this.onClose(savedView);
        } catch (error) {
          if (error.response) {
            console.error(error);
            const { status } = error.response;

            if (status === 409) {
              store.dispatch(openWarning(AR_HUB_CONFLICT_ERROR_MESSAGE));
              return null;
            }

            store.dispatch(openError(AR_HUB_SAVE_ERROR_MESSAGE));
          }
        }

        return null;
      },
    };
  }

  __renderTextFields() {
    return html`
      <div class="textfield-container">
        <neb-textfield
          .id="${ELEMENTS.textfieldName.id}"
          .value="${this.viewName}"
          .onChange="${this.__handlers.changeName}"
          placeholder="Name"
          maxLength="36"
        ></neb-textfield>
        <neb-textarea
          .id="${ELEMENTS.textareaDescription.id}"
          .value="${this.viewDescription}"
          .onChange="${this.__handlers.changeDescription}"
          placeholder="Name"
          label="Description"
          maxLength="255"
        ></neb-textarea>
      </div>
    `;
  }

  renderContent() {
    return html`
      <div class="content-container">
        ${this.__renderTextFields()}
        <div class="button-container">
          <neb-button
            id="${ELEMENTS.buttonConfirm.id}"
            class="button"
            label="SAVE"
            .role="${BUTTON_ROLE.CONFIRM}"
            .onClick="${this.__handlers.save}"
            .disabled="${this.viewName === ''}"
          ></neb-button>
          <neb-button
            id="${ELEMENTS.buttonCancel.id}"
            class="button"
            label="CANCEL"
            .role="${BUTTON_ROLE.OUTLINE}"
            .onClick="${this.__handlers.cancel}"
          ></neb-button>
        </div>
      </div>
    `;
  }
}

customElements.define('neb-popup-ar-hub-save-new-view', NebPopupSaveNewView);
