import '../../../neb-lit-components/src/components/neb-button';
import '../../../neb-lit-components/src/components/neb-file-select';
import '../../../neb-lit-components/src/components/neb-loading-overlay';
import '../../../neb-lit-components/src/components/neb-file-preview';
import '../../../../src/components/misc/neb-icon';
import '../../../neb-styles/neb-icons';

import { openPopup } from '@neb/popup';
import { LitElement, html, css } from 'lit';
import mime from 'mime-types';

import { fetchMany, save } from '../../../../src/api-clients/tags';
import { DISPLAY_MODES } from '../../../../src/components/controls/inputs/neb-select-search-multi';
import * as client from '../../../neb-api-client/src/document-api-client';
import { openSuccess, openError } from '../../../neb-dialog/neb-banner-state';
import { DARK_COLORS } from '../../../neb-lit-components/src/components/inputs/neb-picker-color';
import { POPOVER_POSITION } from '../../../neb-lit-components/src/components/neb-date-picker';
import { openDirtyPopup } from '../../../neb-popup';
import { POPUP_RENDER_KEYS } from '../../../neb-popup/src/renderer-keys';
import { store } from '../../../neb-redux/neb-redux-store';
import { baseStyles } from '../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_COLOR_GREY_1,
  CSS_COLOR_BLACK,
  CSS_COLOR_ERROR,
  CSS_COLOR_BLUE_BORDER,
  CSS_FONT_SIZE_HEADER,
  CSS_FONT_SIZE_BODY,
  CSS_FONT_WEIGHT_BOLD,
  CSS_FIELD_MARGIN,
} from '../../../neb-styles/neb-variables';
import { parseDate } from '../../../neb-utils/date-util';
import { removeNonASCII } from '../utils/neb-document-routing-util';

import * as popupOptions from './neb-document-popup-options';

const TEXT_BIG_FILE =
  'The file size must be less than 10MB and the file type must be a JPG, PNG or PDF.';
export const TEXT_UPLOAD_DOCUMENT_SUCCESS = 'Document Successfully Saved';
export const TEXT_UPLOAD_DOCUMENT_ERROR = 'Unable to Save Document';
export const IMG_PDF_BAD_FILE_ERROR =
  'Bad or corrupt file. Try fixing file and upload again.';
const TEXT_INVALID_SELECTION = 'Invalid Selection';

const MAX_FILE_SIZE = 10 * 1024 * 1024;

export const ELEMENTS = {
  imagePreview: { id: 'image-preview' },
  documentName: { id: 'document-name' },
  documentDate: { id: 'document-date' },
  documentNote: { id: 'document-note' },
  tags: { id: 'tags' },
  imagePdf: { id: 'image-pdf' },
  spinnerLoading: { id: 'spinner-loading' },
  datePicker: { id: 'picker-date' },
  fileSelect: { id: 'file-select' },
  document: { id: 'document' },
  header: {
    id: 'header-container',
    uploadText: 'Upload Document',
    editText: 'Edit Document',
  },
  uploadDate: { id: 'upload-date' },
  thumbnailPreview: { id: 'thumbnail-preview' },
  fileSelectPreview: { id: 'file-select-preview' },
  cancelButton: { id: 'button-cancel' },
  closeButton: { id: 'button-close' },
  saveButton: { id: 'button-save' },
  deleteButton: { id: 'button-delete' },
  inputName: { id: 'input-name' },
  inputNotes: { id: 'input-notes' },
  nebFilePreview: { id: 'neb-file-preview' },
  tagsSelectSearchMulti: { id: 'select-search-multi-type' },
};
const VALID_MIME_TYPES = ['image/png', 'image/jpeg', 'application/pdf'];
const VALID_FILE_EXTENSIONS = [
  'jpg',
  ...VALID_MIME_TYPES.map(t => mime.extension(t)),
];

class NebDocumentContent extends LitElement {
  static get properties() {
    return {
      model: Object,
      initialModel: Object,
      patientId: String,
      small: {
        type: Boolean,
        reflect: true,
      },
      __saving: Boolean,
      __fileBlob: Object,
      __errors: Array,
      __tagItems: Array,
      __tagItemsSearch: String,
      touchDevice: Boolean,
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.isDirty = false;
    this.touchDevice = false;
    this.model = null;
    this.__saving = false;
    this.__fileBlob = null;
    this.__isStateSet = false;
    this.patientId = null;
    this.initialModel = {
      id: null,
      name: '',
      note: '',
      date: null,
      file: null,
      uploadDate: null,
      thumbnail: null,
      tags: [],
      encounterId: null,
    };

    this.__errors = {
      name: '',
      date: '',
    };

    this.__tagItems = [];
    this.__tagItemsSearch = '';

    this.onClose = () => {};

    this.onUpdateModel = () => {};

    this.onUpdateIsDirty = value => {
      this.isDirty = value;
    };
  }

  __initHandlers() {
    this.__handlers = {
      invalidFile: () => this.__invalidFile(),
      proceedDirty: () => {
        this.__close({
          result: popupOptions.ActionResult.CANCELED,
        });
      },
      selectFile: fileBlob => this.__handleFileSelect(fileBlob),
      setDate: date => {
        this.model = { ...this.model, date };
        this.validate();
      },
      clearDate: () => {
        this.model = { ...this.model, date: null };
        this.validate();
      },
      save: () => {
        if (this.validate()) {
          this.upload();
        }
      },
      cancel: () => {
        if (this.validateIsDirty()) {
          this.onUpdateModel(this.model);

          this.__renderPopupDirty();
        } else {
          this.__close({
            result: popupOptions.ActionResult.CANCELED,
          });
        }
      },
      delete: () => {
        this.__renderPopupDelete();
      },
      setInput: e => {
        const { name, value } = e.currentTarget;
        this.model = { ...this.model, [name]: value };
        this.validate();
      },
      updateIsDirty: () => {
        this.onUpdateIsDirty(this.validateIsDirty());
      },
      uploadDateSelectable: date => date <= parseDate().startOf('day'),
      changeTags: async ({ value: tags }) => {
        const temporaryTagIndex = tags.findIndex(tag => !tag.data.id);

        if (temporaryTagIndex !== -1) {
          const tag = await save({
            name: tags[temporaryTagIndex].label,
            color: tags[temporaryTagIndex].data.color,
            description: '',
          });

          tags[temporaryTagIndex].data.id = tag.data[0].id;
        }

        this.model = { ...this.model, tags };
      },
      searchTags: async e => {
        this.__tagItemsSearch = e.value.trim();

        const tags = await this.__fetchTags();

        if (this.__tagItemsSearch) {
          const color =
            DARK_COLORS[Math.floor(Math.random() * DARK_COLORS.length)];

          const temporaryTag = {
            label: this.__tagItemsSearch,
            data: {
              id: '',
              color,
            },
          };

          const nameAlreadyExists = Boolean(
            tags.find(
              ({ label }) =>
                label.toLowerCase() === temporaryTag.label.toLowerCase(),
            ),
          );

          const filteredTags = tags.filter(({ label }) =>
            label.toLowerCase().includes(this.__tagItemsSearch.toLowerCase()),
          );

          this.__tagItems = nameAlreadyExists
            ? filteredTags
            : [temporaryTag, ...filteredTags];
        } else {
          this.__tagItems = tags;
        }
      },
    };
  }

  async firstUpdated() {
    this.__tagItems = await this.__fetchTags();
  }

  async __fetchTags() {
    return (await fetchMany(true))
      .map(({ id, name, color }) => ({
        label: name,
        data: { id, color },
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  connectedCallback() {
    super.connectedCallback();
    const callback = this.__handlers.proceedDirty;
    window.addEventListener('neb-dirty-manual-proceed', callback);
  }

  disconnectedCallback() {
    const callback = this.__handlers.proceedDirty;
    window.removeEventListener('neb-dirty-manual-proceed', callback);
    super.disconnectedCallback();
  }

  update(changedProps) {
    if (this.model && this.model.file) {
      this.__fileBlob = this.model.file;
    }

    if (changedProps.has('initialModel') && !this.model) {
      this.model = { ...this.initialModel };
    }

    this.initialModel.note = this.initialModel.note || '';

    if (this.model) {
      this.model.note = this.model.note || '';
    }

    super.update(changedProps);
  }

  updated(changedProps) {
    if (changedProps.has('model')) {
      this.__handlers.updateIsDirty();
    }
  }

  validate() {
    this.__errors = {
      name: !this.model.name ? 'Required' : '',
      date: !this.model.date ? 'Required' : '',
    };

    return (
      (!this.isNewDocument() || this.__fileBlob) &&
      !Object.entries(this.__errors).find(([_, value]) => value.length)
    );
  }

  isNewDocument() {
    return this.initialModel.id == null;
  }

  __getFilteredTagItems() {
    return this.__tagItems.filter(
      item =>
        !this.model.tags.find(modelItem => modelItem.data.id === item.data.id),
    );
  }

  __getFileNameWithoutExtension(fileName) {
    return fileName.slice(0, fileName.lastIndexOf('.'));
  }

  __processFileBlobForPreview(fileBlob) {
    this.__fileBlob = fileBlob;
    const updateModel = {
      ...this.model,
      uploadDate: parseDate().startOf('day'),
      file: fileBlob,
    };

    if (!this.model.name) {
      this.invalidFileName = true;
      updateModel.name = this.__getFileNameWithoutExtension(fileBlob.name);
    }

    this.model = { ...updateModel, name: removeNonASCII(updateModel.name) };
  }

  __handleFileSelect(fileBlob) {
    this.__processFileBlobForPreview(fileBlob);
  }

  __close(
    result = {
      result: popupOptions.ActionResult.CANCELED,
    },
  ) {
    this.onClose(result);
  }

  __invalidFile() {
    store.dispatch(openError(IMG_PDF_BAD_FILE_ERROR));
    this.__fileBlob = null;
    this.model.file = null;

    if (this.invalidFileName) {
      this.model.name = '';
      this.invalidFileName = false;
    }
  }

  validateIsDirty() {
    return JSON.stringify(this.initialModel) !== JSON.stringify(this.model);
  }

  async __renderPopupInvalidFile() {
    await openPopup(POPUP_RENDER_KEYS.MESSAGE, {
      title: TEXT_INVALID_SELECTION,
      message: TEXT_BIG_FILE,
    });
  }

  async __renderPopupDelete() {
    const result = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
      title: 'Delete Document',
      message: 'Are you sure you want to permanently delete this document?',
      confirmText: 'DELETE',
      cancelText: 'CANCEL',
    });

    if (result) {
      this.delete();
    }
  }

  __renderTags() {
    return html`
      <div class="container-field">
        <div class="input-text-label" id="${ELEMENTS.tags.id}">
          Document Tag(s)
        </div>

        <neb-select-search-multi
          id="${ELEMENTS.tagsSelectSearchMulti.id}"
          class="input-tags"
          name="tags"
          helper=" "
          maxLength="20"
          maxVisibleItems="3"
          .items="${this.__getFilteredTagItems()}"
          .value="${this.model.tags}"
          .search="${this.__tagItemsSearch}"
          .onChange="${this.__handlers.changeTags}"
          .onSearch="${this.__handlers.searchTags}"
          .scrollOnKeydown="${false}"
          .displayMode="${DISPLAY_MODES.PILLS}"
        ></neb-select-search-multi>
      </div>
    `;
  }

  async __renderPopupDirty() {
    if (await openDirtyPopup()) {
      this.__close({
        result: 'cancel',
      });
    }
  }

  async upload() {
    this.__saving = true;

    try {
      if (this.isNewDocument()) {
        await client.postDocument(this.model, this.patientId, true);
      } else {
        await client.putDocument(this.model, true);
        this.isDirty = false;
      }

      this.__close({
        result: popupOptions.ActionResult.SAVED,
      });

      store.dispatch(openSuccess(TEXT_UPLOAD_DOCUMENT_SUCCESS));
    } catch (err) {
      this.__close({
        result: popupOptions.ActionResult.ERROR,
      });

      store.dispatch(openError(TEXT_UPLOAD_DOCUMENT_ERROR));
    }

    this.__saving = false;
  }

  delete() {
    this.__deleting = true;

    if (!this.isNewDocument()) {
      this.isDirty = false;

      this.__close({
        result: popupOptions.ActionResult.DELETED,
      });
    }

    this.__deleting = false;
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
          position: relative;
          width: 100%;
          height: 100%;
          border: 0 solid black;
          box-sizing: border-box;
          margin: 0;
          padding: 0;

          overflow-y: auto;
        }

        .popup-buttons {
          display: flex;
          justify-content: space-between;
        }

        .header {
          display: flex;
          margin: 0;
          padding: 0;
          justify-content: space-between;
        }

        :host([small]) .container {
          padding: ${CSS_SPACING} 0;
          width: 100%;
        }

        .content {
          padding: 0;
          margin: 0;
        }

        .spacer {
          margin-bottom: ${CSS_SPACING};
        }

        :host([small]) .button {
          min-width: fit-content;
        }

        .button-cancel {
          margin-left: 10px;
        }

        .button-delete {
          margin-left: 10px;
        }

        .container-field {
          margin-bottom: 6px;
        }

        .text-bold {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .text-header {
          font-size: ${CSS_FONT_SIZE_HEADER};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .text-error {
          height: ${CSS_FIELD_MARGIN};
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: normal;
          margin: 0px 6px;
          min-width: 0px;
          font-size: 10.5px;
          letter-spacing: 0.35px;
          color: ${CSS_COLOR_ERROR};
        }

        .icon-close {
          height: 24px;
          width: 24px;
          fill: ${CSS_COLOR_GREY_1};
          padding: 0;
          cursor: pointer;
        }

        .preview {
          width: 100%;
          height: 150px;
        }

        .thumbnail {
          height: 100%;
        }

        .input-text {
          outline: none;
          border: 1px solid ${CSS_COLOR_GREY_1};
          border-radius: 4px;
          font-size: ${CSS_FONT_SIZE_BODY};
          padding: 8px 11px;
        }

        .input-text:hover {
          border: 1px solid ${CSS_COLOR_BLACK};
        }

        .input-text:focus {
          caret-color: ${CSS_COLOR_BLUE_BORDER};
          border: 2px solid ${CSS_COLOR_BLUE_BORDER};
        }

        .input-text[invalid] {
          border: 1px solid ${CSS_COLOR_ERROR};
          caret-color: ${CSS_COLOR_ERROR};
        }

        .input-text[invalid]:focus {
          border: 2px solid ${CSS_COLOR_ERROR};
        }

        .input-text-label {
          margin: 2px 4px;
          font-size: ${CSS_FONT_SIZE_BODY};
        }

        .input-text-label-note {
          margin: 3px 4px;
        }

        .input-name {
          width: 100%;
          height: 40px;
        }

        .input-notes {
          resize: none;
          height: 70px;
          width: 100%;
        }

        .input-date {
          --neb-pop-over-left: 10px;
        }

        .container-note {
          margin-bottom: ${CSS_SPACING};
        }

        .input-tags {
          width: 100%;
          height: 70px;
          --max-pill-width: unset;
          caret-color: ${CSS_COLOR_BLUE_BORDER};
        }
      `,
    ];
  }

  __renderPreview(fileBlob) {
    return html`
      <neb-file-preview
        id="${ELEMENTS.nebFilePreview.id}"
        class="thumbnail"
        .fileBlob="${fileBlob}"
        .onInvalidFile="${this.__handlers.invalidFile}"
      ></neb-file-preview>
    `;
  }

  __renderFileUpload() {
    return html`
      <neb-file-select
        id="${ELEMENTS.fileSelect.id}"
        class="input-file spacer"
        .showPreview="${this.__fileBlob !== null}"
        .allowedExtensions="${VALID_FILE_EXTENSIONS}"
        .validMimeTypes="${VALID_MIME_TYPES}"
        .maxFileSize="${MAX_FILE_SIZE}"
        .invalidFileMessage="${TEXT_BIG_FILE}"
        .onSelectFile="${this.__handlers.selectFile}"
        .touchDevice="${this.touchDevice}"
      >
        <div class="preview" id="${ELEMENTS.fileSelectPreview.id}">
          ${this.__fileBlob ? this.__renderPreview(this.__fileBlob) : ''}
        </div>
      </neb-file-select>
    `;
  }

  __renderThumbnailPreview() {
    return html`
      <div class="preview spacer" id="${ELEMENTS.thumbnailPreview.id}">
        ${this.model && this.model.thumbnail
          ? html`
              <img
                id="${ELEMENTS.imagePreview.id}"
                class="thumbnail"
                src="${this.model.thumbnail}"
              />
            `
          : ''}
      </div>
    `;
  }

  __renderDeleteButton() {
    if (this.isNewDocument()) {
      return '';
    }
    return html`
      <neb-button
        id="${ELEMENTS.deleteButton.id}"
        class="button button-delete"
        role="delete"
        label="Delete"
        ?disabled="${this.__deleting ||
        (this.isNewDocument() && !this.__fileBlob)}"
        .onClick="${this.__handlers.delete}"
      ></neb-button>
    `;
  }

  __renderTitle() {
    return this.small
      ? html``
      : html`
          <div class="header spacer">
            <div class="text-header" id="${ELEMENTS.header.id}">
              ${this.isNewDocument()
                ? ELEMENTS.header.uploadText
                : ELEMENTS.header.editText}
            </div>
            <neb-icon
              id="${ELEMENTS.closeButton.id}"
              class="icon-close"
              icon="neb:close"
              @click="${this.__handlers.cancel}"
            ></neb-icon>
          </div>
        `;
  }

  render() {
    return html`
      <div id="${ELEMENTS.document.id}" class="container">
        ${this.__renderTitle()}
        <div class="content">
          ${this.isNewDocument()
            ? this.__renderFileUpload()
            : this.__renderThumbnailPreview()}
          <div class="text-bold spacer" id="${ELEMENTS.uploadDate.id}">
            Upload Date:
            ${this.model
              ? parseDate(this.model.uploadDate).format('MMMM DD, YYYY')
              : ''}
          </div>

          <form onSubmit="return false;">
            <div class="container-field">
              <div class="input-text-label" id="${ELEMENTS.documentName.id}">
                Document Name
              </div>
              <input
                id="${ELEMENTS.inputName.id}"
                type="text"
                name="name"
                autocomplete="off"
                class="input-text input-name"
                .value="${this.model ? this.model.name : ''}"
                ?invalid="${!!this.__errors.name}"
                @input="${this.__handlers.setInput}"
              />
              <div class="text-error">${this.__errors.name}</div>
            </div>

            <div class="container-field">
              <div class="input-text-label" id="${ELEMENTS.documentDate.id}">
                Document Date
              </div>
              <neb-date-picker
                id="${ELEMENTS.datePicker.id}"
                class="input input-date"
                name="date"
                ?invalid="${!!this.__errors.date}"
                .selectedDate="${this.model.date}"
                .manualPopoverPosition="${POPOVER_POSITION.CENTER}"
                .isDateSelectable="${this.__handlers.uploadDateSelectable}"
                .onClick="${this.__handlers.setDate}"
                .onClear="${this.__handlers.clearDate}"
                momentFlag
              ></neb-date-picker>
            </div>

            ${this.__renderTags()}

            <div class="container-note container-field">
              <div
                class="input-text-label input-text-label-note"
                id="${ELEMENTS.documentNote.id}"
              >
                Note
              </div>
              <textarea
                id="${ELEMENTS.inputNotes.id}"
                type="text"
                name="note"
                class="input input-text input-notes"
                .value="${this.model ? this.model.note : ''}"
                @input="${this.__handlers.setInput}"
              ></textarea>
            </div>

            <div class="popup-buttons">
              <div>
                <neb-button
                  id="${ELEMENTS.saveButton.id}"
                  class="button"
                  role="confirm"
                  label="Save"
                  ?disabled="${this.__saving ||
                  (this.isNewDocument() && !this.__fileBlob) ||
                  (!this.isNewDocument() && !this.validateIsDirty())}"
                  .onClick="${this.__handlers.save}"
                ></neb-button>

                <neb-button
                  id="${ELEMENTS.cancelButton.id}"
                  class="button button-cancel"
                  role="cancel"
                  label="Cancel"
                  .onClick="${this.__handlers.cancel}"
                ></neb-button>
              </div>
              ${this.__renderDeleteButton()}
            </div>
          </form>

          <neb-loading-overlay
            id="${ELEMENTS.spinnerLoading.id}"
            title="Saving Document"
            showDelay="0"
            .show="${this.__saving}"
          ></neb-loading-overlay>
        </div>
      </div>
    `;
  }
}

customElements.define('neb-document-content', NebDocumentContent);
