import '../../../../packages/neb-lit-components/src/components/tables/neb-table-update-fee-schedule-billing-code';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-select';
import '../../../../packages/neb-lit-components/src/components/neb-pagination';

import { html, css } from 'lit';

import { BUTTON_ROLE } from '../../../../packages/neb-lit-components/src/components/neb-button';
import { CollectionService } from '../../../../packages/neb-utils/services/collection';
import { requiredSelect } from '../../../../packages/neb-utils/simple-form-util';
import { CSS_SPACING } from '../../../styles';
import { NebSimpleForm } from '../neb-simple-form';

export const TABLE_CONFIG = [
  {
    key: 'name',
    label: 'Fee Schedule Name',
    flex: css`1 0 0`,
  },
  {
    key: 'payerAdjustmentCodeId',
    label: 'Payer Adjustment Code',
    flex: css`1 0 0`,
  },
  {
    key: 'patientAdjustmentCodeId',
    label: 'Patient Adjustment Code',
    flex: css`1 0 0`,
  },
];

export const ELEMENTS = {
  tableFeeSchedule: {
    id: 'table-fee-schedule',
  },
  pagination: {
    id: 'pagination',
  },
  buttonConfirm: {
    id: 'button-confirm',
  },
  buttonCancel: {
    id: 'button-cancel',
  },
};

export const PAGE_SIZE = 5;

export class NebFormUpdateFeeScheduleBillingCode extends NebSimpleForm {
  static get properties() {
    return {
      patientAdjustmentCodes: Array,
      payerAdjustmentCodes: Array,

      __feeSchedules: Array,
      __feeSchedulesForCurrentPage: Array,
      __currentPageIndex: Number,
      __pageCount: Number,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .pagination {
          justify-content: end;
          padding: ${CSS_SPACING};
        }

        .button-container {
          display: flex;
          flex-wrap: wrap;
          justify-content: start;
        }

        .button:not(:last-child) {
          margin-right: 10px;
        }
      `,
    ];
  }

  static createModel() {
    return {
      feeSchedules: [],
    };
  }

  createSelectors() {
    return {
      feeSchedules: {
        stateKey: '__feeSchedules',
        children: {
          '*': {
            children: {
              patientAdjustmentCodeId: requiredSelect(
                this.patientAdjustmentCodes,
              ),
              payerAdjustmentCodeId: requiredSelect(this.payerAdjustmentCodes),
            },
          },
        },
      },
    };
  }

  constructor() {
    super();

    this.initServices();
  }

  initServices() {
    this.__collectionService = new CollectionService(
      {
        onChange: ({ pageIndex, pageCount, pageItems }) => {
          this.__currentPageIndex = pageIndex;
          this.__feeSchedulesForCurrentPage = pageItems;
          this.__pageCount = pageCount;
        },
      },
      {
        pageSize: PAGE_SIZE,
      },
    );
  }

  initState() {
    super.initState();

    this.patientAdjustmentCodes = [];
    this.payerAdjustmentCodes = [];
    this.alwaysRenderActionBar = true;

    this.__feeSchedules = [];
    this.__currentPageIndex = 0;
    this.__pageCount = 0;
    this.__feeSchedulesForCurrentPage = [];
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      changePage: pageIndex => this.__collectionService.setPageIndex(pageIndex),
      change: ({ name, value }) => {
        const [, index, key] = name.split('.');

        const globalIndex = Number(index) + this.__currentPageIndex * PAGE_SIZE;

        this.__feeSchedules[globalIndex][key] = value;

        this.__feeSchedulesForCurrentPage[index][key] = value;

        this.__collectionService.updateItem(
          this.__feeSchedulesForCurrentPage[index],
        );

        this.__feeSchedules = [...this.__feeSchedules];
      },
    };
  }

  update(changedProps) {
    if (changedProps.has('model') && this.model.feeSchedules) {
      this.__collectionService.setItems(this.__feeSchedules);
    }

    super.update(changedProps);
  }

  __navigateToErrorPage() {
    const firstErrorFoundIndex = Object.values(
      this.errors.feeSchedules,
    ).findIndex(fs => fs.patientAdjustmentCodeId || fs.payerAdjustmentCodeId);

    const pageIndexWithError = Math.floor(firstErrorFoundIndex / PAGE_SIZE);

    this.__collectionService.setPageIndex(pageIndexWithError);
  }

  save() {
    this.setErrors();

    if (!this.__hasErrors) {
      const saveModel = this.convertStateToModel();

      this.saving = true;
      this.onSave(saveModel);
    } else {
      this.__navigateToErrorPage();
    }
  }

  render() {
    return html`
      <neb-table-update-fee-schedule-billing-code
        id="${ELEMENTS.tableFeeSchedule.id}"
        name="feeSchedules"
        preview
        .config="${TABLE_CONFIG}"
        .model="${this.__feeSchedulesForCurrentPage}"
        .errors="${this.errors}"
        .currentPageIndex="${this.__currentPageIndex}"
        .patientAdjustmentCodes="${this.patientAdjustmentCodes}"
        .payerAdjustmentCodes="${this.payerAdjustmentCodes}"
        .onChange="${this.handlers.change}"
      ></neb-table-update-fee-schedule-billing-code>

      <neb-pagination
        id="${ELEMENTS.pagination.id}"
        class="pagination"
        .currentPage="${this.__currentPageIndex}"
        .onPageChanged="${this.handlers.changePage}"
        .pageCount="${this.__pageCount}"
      ></neb-pagination>

      <div class="button-container">
        <neb-button
          id="${ELEMENTS.buttonConfirm.id}"
          class="button"
          label="Save"
          .role="${BUTTON_ROLE.CONFIRM}"
          .onClick="${this.handlers.save}"
        ></neb-button>

        <neb-button
          id="${ELEMENTS.buttonCancel.id}"
          class="button"
          label="Cancel"
          .role="${BUTTON_ROLE.OUTLINE}"
          .onClick="${this.handlers.cancel}"
        ></neb-button>
      </div>
    `;
  }
}

customElements.define(
  'neb-form-update-fee-schedule-billing-code',
  NebFormUpdateFeeScheduleBillingCode,
);
