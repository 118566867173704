import { openPopup } from '@neb/popup';
import { html, css } from 'lit';

import * as popupOptions from '../../../packages/neb-document/src/components/neb-document-popup-options';
import { BUTTON_ROLE } from '../../../packages/neb-lit-components/src/components/neb-button';
import '../../../packages/neb-lit-components/src/components/neb-loading-overlay';
import NebPopup, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../packages/neb-popup/src/neb-popup';
import { POPUP_RENDER_KEYS } from '../../../packages/neb-popup/src/renderer-keys';
import { store } from '../../../packages/neb-redux/neb-redux-store';
import { baseStyles } from '../../../packages/neb-styles/neb-styles';
import { parseDate } from '../../../packages/neb-utils/date-util';
import {
  FEATURE_FLAGS,
  hasFeatureOrBetaSync,
} from '../../../packages/neb-utils/feature-util';
import { openError, openSuccess } from '../../store';
import { CSS_BORDER_GREY_2, CSS_SPACING } from '../../styles';

import { checkPlatformAndService } from './neb-scan-init';
import { uploadDocument } from './processor/document-processor';
import {
  isScanConfigValid,
  saveScanConfigToLocalStorage,
} from './processor/scan-config-processor';
import {
  startSession,
  scan,
  getDocumentViewModel,
  deleteDocumentPage,
} from './processor/scan-processor';
import {
  getLoadingIndicatorOn,
  getLoadingIndicatorOff,
  LOADING_INDICATOR_MESSAGE,
} from './utils/loading-indicator-util';
import './neb-scan-config';
import './components/neb-document-fields';
import './components/neb-document-image-viewer';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  scanConfig: { id: 'scan-config' },
  scanButton: { id: 'scan-button' },
  saveButton: { id: 'save-button' },
  cancelButton: { id: 'cancel-button' },
  loadingSpinner: { id: 'loading-spinner' },
  documentFields: { id: 'document-fields' },
  loadingIndicator: { id: 'loading-indicator' },
  documentImageViewer: { id: 'neb-document-image-viewer' },
};

const TEXT_SCAN_SAVE_DOCUMENT_SUCCESS =
  'Document Successfully Scanned and Saved';
const TEXT_SCAN_SAVE_DOCUMENT_ERROR = 'Unable to Scan and Save Document';

const CONFIRM_DELETE_PAGE = {
  title: 'Confirm Delete',
  confirmText: 'Yes',
  cancelText: 'No',
  message: 'Are you sure you want to delete current page?',
};

const MESSAGE_DELETE_PAGE_ERROR = {
  title: 'Delete Error',
  message: 'Unable to delete current page.',
};

class NebPopupScan extends NebPopup {
  static get properties() {
    return {
      __platform: String,
      __isScanServiceAvailable: Boolean,
      __documentModel: Object,
      __scanConfigModel: Object,
      __loadingIndicator: Object,
      __scanEnabled: Boolean,
      __scanResult: Object,
      __shouldCacheScanApi: Boolean,
    };
  }

  static createDocumentModel() {
    return {
      name: '',
      note: '',
      date: parseDate().startOf('day'),
      uploadDate: parseDate().startOf('day'),
      tags: [],
    };
  }

  static createScanConfigModel() {
    return {
      scannerId: null,
      documentSourceId: null,
      resolutionId: null,
      colorId: null,
      pageSizeId: null,
      duplexId: null,
      documentType: null,
      preview: true,
      pageSizeName: null,
    };
  }

  initState() {
    super.initState();
    this.showHeader = false;

    this.title = 'Scan Document';
    this.__isScanServiceAvailable = false;
    this.__documentModel = this.constructor.createDocumentModel();
    this.__scanConfigModel = this.constructor.createScanConfigModel();
    this.__scanResult = { count: 0 };
    this.__loadingIndicator = { loading: false, message: '' };
    this.__scanEnabled = false;
    this.__scanning = false;
    this.__shouldCacheScanApi = false;
    this.model = { patientId: null, encounterId: null };
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      __onCancel: () => {
        if (this.__scanning) {
          this.__scanning = false;
          this.__loadingIndicator = getLoadingIndicatorOff();
        } else {
          this.onClose({
            result: popupOptions.ActionResult.CANCELED,
          });
        }
      },
      __onScan: () => this.__scan(),
      __onSave: () => this.__save(),
      __documentModelUpdated: model => {
        this.__documentModel = {
          ...model,
          encounterId: this.model.encounterId,
        };

        this.__validateScanEnabled();
      },
      __scanConfigUpdated: model => {
        this.__scanConfigModel = { ...model };
        this.__validateScanEnabled();
      },
      __loadingUpdated: loadingIndicator => {
        this.__loadingIndicator = { ...loadingIndicator };
      },
      __deletePage: pageNumber => this.__deletePage(pageNumber),
    };
  }

  __validateScanEnabled() {
    this.__scanEnabled =
      isScanConfigValid(this.__scanConfigModel) &&
      this.__documentModel &&
      !!this.__documentModel.name &&
      !!this.__documentModel.date;
  }

  async __scan() {
    this.__loadingIndicator = getLoadingIndicatorOn(
      LOADING_INDICATOR_MESSAGE.SCANNING_DOCUMENT,
    );

    this.__scanning = true;
    const documentViewModel = await scan(this.__scanConfigModel);

    if (this.__scanning) {
      this.__scanning = false;
      this.__loadingIndicator = getLoadingIndicatorOff();

      this.__scanResult = { count: documentViewModel.count };

      if (!this.__scanConfigModel.preview) {
        await this.__save();
      }
    }
  }

  async __save() {
    this.__loadingIndicator = getLoadingIndicatorOn(
      LOADING_INDICATOR_MESSAGE.SAVING_DOCUMENT,
    );

    let uploaded = false;

    try {
      const documentViewModel = await getDocumentViewModel(true);
      documentViewModel.documentType = this.__scanConfigModel.documentType;

      uploaded = await uploadDocument({
        documentViewModel,
        documentModel: this.__documentModel,
        encounterId: this.model.encounterId,
        patientId: this.model.patientId,
        pageSizeName: this.__scanConfigModel.pageSizeName,
      });

      saveScanConfigToLocalStorage(this.__scanConfigModel);
    } catch (err) {
      console.error(err);
      store.dispatch(openError(TEXT_SCAN_SAVE_DOCUMENT_ERROR));
    }

    this.__loadingIndicator = getLoadingIndicatorOff();

    if (uploaded.success) {
      this.onClose({
        result: popupOptions.ActionResult.SAVED,
      });

      store.dispatch(openSuccess(TEXT_SCAN_SAVE_DOCUMENT_SUCCESS));
      return;
    }

    if (!uploaded.success && uploaded.error) {
      await openPopup(POPUP_RENDER_KEYS.MESSAGE, {
        title: uploaded.errorTitle
          ? uploaded.errorTitle
          : 'Scanner Service Error',
        message: uploaded.error,
      });
    }
  }

  async __initScannerAndStartSession() {
    const result = await checkPlatformAndService({
      shouldCacheScanApi: this.__shouldCacheScanApi,
    });

    if (result.success) {
      this.__isScanServiceAvailable = await startSession();
    } else {
      this.handlers.__onCancel();
    }
  }

  async __deletePage(pageNumber) {
    const confirmDeletePage = await openPopup(
      POPUP_RENDER_KEYS.CONFIRM,
      CONFIRM_DELETE_PAGE,
    );

    if (!confirmDeletePage) {
      return;
    }

    const result = await deleteDocumentPage(pageNumber);

    if (result.success) {
      this.__scanResult = { count: result.count };
      return;
    }
    await openPopup(POPUP_RENDER_KEYS.MESSAGE, MESSAGE_DELETE_PAGE_ERROR);
  }

  connectedCallback() {
    super.connectedCallback();

    this.__shouldCacheScanApi = hasFeatureOrBetaSync(
      FEATURE_FLAGS.LS_SCAN_API_CACHE,
    );
  }

  async firstUpdated() {
    await this.__initScannerAndStartSession();
    super.firstUpdated();
  }

  static get styles() {
    return [
      super.styles,
      baseStyles,
      css`
        :host {
          display: flex;
          flex-direction: column;
          margin: 0;
          padding: 0;
          width: 100%;
          height: 100%;
        }

        :host .content {
          margin-top: 0;
        }

        .container-scan {
          width: 100%;
          height: 100%;
          position: relative;
        }

        .left-panel {
          position: absolute;
          width: 30%;
          height: 100%;
          display: flex;
          flex-direction: column;
          overflow-y: auto;
        }

        .right-panel {
          background: transparent;
          position: absolute;
          width: 70%;
          height: 100%;
          left: 30%;
        }

        .panel-border {
          border: ${CSS_BORDER_GREY_2};
          border-radius: 5px;
          margin-bottom: ${CSS_SPACING};
          margin-right: ${CSS_SPACING};
        }

        .fill-remaining-space {
          flex-grow: 1;
        }

        .button-row {
          display: flex;
          justify-content: flex-end;
          height: 40px;
        }

        .button {
          margin-right: 10px;
        }
      `,
    ];
  }

  __renderSaveButton() {
    return this.__isScanServiceAvailable &&
      this.__scanResult.count > 0 &&
      this.__scanConfigModel.preview
      ? html`
          <neb-button
            id="${ELEMENTS.saveButton.id}"
            role="${BUTTON_ROLE.CONFIRM}"
            class="button"
            label="Save"
            .onClick="${this.handlers.__onSave}"
          ></neb-button>
        `
      : html``;
  }

  __renderButtons() {
    return html`
      <neb-button
        id="${ELEMENTS.scanButton.id}"
        role="${BUTTON_ROLE.CONFIRM}"
        class="button"
        label="Scan"
        ?disabled="${!this.__scanEnabled}"
        .onClick="${this.handlers.__onScan}"
      ></neb-button>
      ${this.__renderSaveButton()}
      <neb-button
        id="${ELEMENTS.cancelButton.id}"
        role="${BUTTON_ROLE.OUTLINE}"
        class="button"
        label="Cancel"
        .onClick="${this.handlers.__onCancel}"
      ></neb-button>
    `;
  }

  renderContent() {
    return html`
      <div class="container-scan">
        <div class="left-panel">
          <neb-scan-config
            id="${ELEMENTS.scanConfig.id}"
            class="panel-border"
            .isScanServiceAvailable="${this.__isScanServiceAvailable}"
            .onLoadingUpdated="${this.handlers.__loadingUpdated}"
            .onScanConfigUpdated="${this.handlers.__scanConfigUpdated}"
            .showReloadScannersButton="${this.__shouldCacheScanApi}"
          ></neb-scan-config>
          <neb-document-fields
            id="${ELEMENTS.documentFields.id}"
            class="panel-border fill-remaining-space"
            .onModelUpdated="${this.handlers.__documentModelUpdated}"
          >
          </neb-document-fields>
          <div class="button-row">${this.__renderButtons()}</div>
        </div>

        <div class="right-panel">
          <neb-document-image-viewer
            id="${ELEMENTS.documentImageViewer.id}"
            .scanResult="${this.__scanResult}"
            .onClose="${this.handlers.__onCancel}"
            .onLoadingUpdated="${this.handlers.__loadingUpdated}"
            .onDeletePage="${this.handlers.__deletePage}"
          ></neb-document-image-viewer>
        </div>

        <neb-loading-overlay
          id="${ELEMENTS.loadingIndicator.id}"
          title=""
          showDelay="0"
          .show="${this.__loadingIndicator.loading}"
          .title="${this.__loadingIndicator.message}"
          .showCancel="${true}"
          .onCancel="${this.handlers.__onCancel}"
        ></neb-loading-overlay>
      </div>
    `;
  }
}

customElements.define('neb-popup-scan', NebPopupScan);
