import * as patientApiClient from '../../packages/neb-api-client/src/patient-api-client';
import {
  getPatientRelationships,
  getPatientRelationshipsActiveGroup,
} from '../../packages/neb-api-client/src/patient-relationship-api-client';
import {
  DEFAULT_NAME_OPTS,
  objToName,
} from '../../packages/neb-utils/formatters';

const uniq = arr => [...new Set(arr)];

const sortCompare = (a, b) => {
  let comparison = 0;

  if (a.patientName > b.patientName) {
    comparison = 1;
  } else if (a.patientName < b.patientName) {
    comparison = -1;
  }

  return comparison;
};

export const fetchPatientRelationships = async ({
  patientId,
  relationTypeLabel = 'Self',
}) => {
  let relationshipsData = await getPatientRelationships(patientId);

  const isNotPrimary = !!relationshipsData.find(d => !d.isPrimary && d.active);

  let primaryRelationshipsData = [];

  if (isNotPrimary) {
    const relationshipGroup =
      await getPatientRelationshipsActiveGroup(patientId);

    primaryRelationshipsData = await getPatientRelationships(
      relationshipGroup.primary,
      { active: true },
    );

    primaryRelationshipsData = primaryRelationshipsData.filter(
      r => r.patientId !== patientId && r.relatedPatientId !== patientId,
    );
  }

  let sortedRelationships = [];
  let relationships = [];

  if (relationshipsData.length > 0) {
    relationshipsData = [
      {
        active: !!relationshipsData.find(d => d.active),
        id: '',
        isPrimary: isNotPrimary,
        note: '',
        patientId,
        relatedPatientId: patientId,
        type: relationTypeLabel,
      },
      ...relationshipsData,
      ...primaryRelationshipsData.map(r => ({
        ...r,
        type: 'Via Primary',
      })),
    ];

    const patientIds = uniq(relationshipsData.map(x => x.relatedPatientId));

    const patients = await patientApiClient.fetchSome(patientIds);

    relationships = relationshipsData.map(relationship => {
      const patient = patients.find(
        ({ id }) => id === relationship.relatedPatientId,
      );
      const primaryLabel =
        relationship.isPrimary || !relationship.active ? 'No' : 'Yes';

      const patientName = objToName(patient.name, DEFAULT_NAME_OPTS);

      return {
        ...relationship,
        patientName,
        isPrimaryLabel: primaryLabel,
        relatedPatient: patient,
      };
    });

    const [firstRow, ...otherRows] = relationships;
    sortedRelationships = [firstRow, ...otherRows.sort(sortCompare)];
  }

  return { relationships, sortedRelationships };
};
