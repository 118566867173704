/* eslint-disable complexity */
import { getValueByPath } from '@neb/form-service/src/utils';
import { openPopup } from '@neb/popup';
import { navigate } from '@neb/router';
import equal from 'fast-deep-equal';
import { css, html } from 'lit';

import '../../../../components/controls/inputs/neb-checkbox';
import { getInvoicesFromEncounters } from '../../../../../packages/neb-api-client/src/encounters-api-client';
import { APPOINTMENT_BILLING_TYPE } from '../../../../../packages/neb-api-client/src/mappers/appointment-billing-info-mapper';
import { getPatientBalance } from '../../../../../packages/neb-api-client/src/patient-totals-api-client';
import { getElectronicPayment } from '../../../../../packages/neb-api-client/src/payments/electronic-payments-api-client';
import { getScheduleStatus } from '../../../../../packages/neb-api-client/src/payments/scheduled-payments-api-client';
import {
  getPayments,
  printReceipts,
} from '../../../../../packages/neb-api-client/src/payments-api-client';
import { openWarning } from '../../../../../packages/neb-dialog/neb-banner-state';
import NebForm, {
  ELEMENTS as ELEMENTS_BASE,
} from '../../../../../packages/neb-lit-components/src/components/forms/neb-form';
import { BUTTON_ROLE } from '../../../../../packages/neb-lit-components/src/components/neb-button';
import { INSURANCE_LEVELS } from '../../../../../packages/neb-lit-components/src/utils/insurance-util';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../../../packages/neb-lit-components/src/utils/overlay-constants';
import { openDirtyPopup } from '../../../../../packages/neb-popup';
import { POPUP_RENDER_KEYS } from '../../../../../packages/neb-popup/src/renderer-keys';
import { store } from '../../../../../packages/neb-redux/neb-redux-store';
import { roomConflictOverridePopup } from '../../../../../packages/neb-utils/calendar-resources-util';
import { parseDate } from '../../../../../packages/neb-utils/date-util';
import { CARE_PACKAGE_STATUS } from '../../../../../packages/neb-utils/enums';
import { centsToCurrency } from '../../../../../packages/neb-utils/formatters';
import {
  formatRefundedSplitPayment,
  isElectronicPayment,
} from '../../../../../packages/neb-utils/neb-payment-util';
import { printPdf } from '../../../../../packages/neb-utils/neb-pdf-print-util';
import {
  REFRESH_CHANGE_TYPE,
  sendRefreshNotification,
} from '../../../../../packages/neb-utils/neb-refresh';
import { NO_REMAINING_AUTHORIZATIONS_MESSAGE } from '../../../../../packages/neb-utils/patientAuthorization';
import {
  getCarePackageStatus,
  getPatientPackagesWithCounts,
} from '../../../../../packages/neb-utils/patientPackage';
import {
  getAppointmentListData,
  RECEIPT_APPOINTMENT_LIMIT,
} from '../../../../../packages/neb-utils/pdf/print-upcoming-appointments';
import { updateInsuranceFromRTE } from '../../../../../packages/neb-utils/real-time-eligibility';
import * as selectors from '../../../../../packages/neb-utils/selectors';
import { map } from '../../../../../packages/neb-utils/utils';
import { required } from '../../../../../packages/neb-utils/validators';
import { getLocations } from '../../../../api-clients/locations';
import { fetchMany } from '../../../../api-clients/real-time-eligibility-records';
import { openError } from '../../../../store';
import {
  CSS_SPACING,
  CSS_COLOR_WHITE,
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_WEIGHT_BOLD,
  CSS_FONT_SIZE_HEADER,
  CSS_FONT_SIZE_BODY,
  CSS_COLOR_GREY_7,
  CSS_COLOR_ERROR,
  CSS_BANNER_ERROR_COLOR,
  CSS_COLOR_YELLOW,
  CSS_BANNER_SUCCESS_COLOR,
  CSS_WARNING_COLOR,
} from '../../../../styles';
import '../../../../../packages/neb-lit-components/src/components/scheduling/neb-appointment-details-lit';
import '../../../../../packages/neb-lit-components/src/components/inputs/neb-select';
import '../../../../../packages/neb-lit-components/src/components/inputs/neb-textarea';
import '../../../../../packages/neb-lit-components/src/components/controls/neb-button-action';
import '../../../../../packages/neb-lit-components/src/components/neb-radio-button';
import '../../../../../packages/neb-lit-components/src/components/controls/neb-tab-group';
import { ADD_ONS, hasAddOn } from '../../../../utils/add-ons';
import { REMOVE_ALLOCATION_CONFIRMATION_POPUP } from '../../../../utils/check-in-out';
import { getRTEStatusIcon } from '../../../../utils/real-time-eligibility';
import {
  ROOM_CHECKED_IN_ERROR,
  WARNING_MULTIPLE_INVOICES_ASSOCIATED_AT_APPOINTMENT_LEVEL,
  WARNING_SINGLE_INVOICE_ASSOCIATED_AT_APPOINTMENT_LEVEL,
} from '../../../../utils/user-message';
import {
  BILLING_OPTIONS,
  INSURANCE_KEYS,
  SELF_GUARANTOR_ITEM,
} from '../../utils/neb-form-check-in-util';
import * as checkInUtil from '../../utils/neb-form-check-in-util';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  containerForm: { id: 'container-form' },
  patientNameLabel: { id: 'patient-name-label' },
  confirmButton: { id: 'confirm-button', label: 'Complete Check In' },
  cancelButton: { id: 'cancel-button', label: 'Cancel' },
  createEncounterCheckbox: {
    id: 'create-encounter-checkbox',
    label: 'Create Encounter',
  },

  selfPayRadio: { id: 'selfPay-radio' },
  carePackageRadio: { id: 'carePackage-radio' },
  insuranceRadio: { id: 'insurance-radio' },
  caseRadio: { id: 'case-radio' },

  insuranceTabGroup: { id: 'insurance-tab-group' },
  eligibilityStatusInfo: { id: 'eligibility-status-info' },

  selectProvider: { id: 'select-provider' },
  selectAppointmentType: { id: 'select-appointment-type' },
  selectRoom: { id: 'select-room' },
  selectCase: { id: 'select-case' },
  selectAuthorization: { id: 'select-authorization' },
  selectGuarantor: { id: 'select-guarantor' },
  selectFeeSchedule: { id: 'select-fee-schedules' },
  selectPackage: { id: 'select-package' },
  selectPrimaryInsurance: { id: 'select-primary-insurance' },
  selectSecondaryInsurance: { id: 'select-secondary-insurance' },
  selectTertiaryInsurance: { id: 'select-tertiary-insurance' },

  tooltipAuthorization: { id: 'tooltip-patientAuthorizationId' },
  tooltipAppointmentType: { id: 'tooltip-appointmentTypeId' },
  tooltipGuarantor: { id: 'tooltip-guarantorId' },
  tooltipFeeSchedule: { id: 'tooltip-feeScheduleId' },
  tooltipPackage: { id: 'tooltip-patientPackageId' },
  tooltipPrimaryInsurance: { id: 'tooltip-primaryInsuranceId' },
  tooltipSecondaryInsurance: { id: 'tooltip-secondaryInsuranceId' },
  tooltipTertiaryInsurance: { id: 'tooltip-tertiaryInsuranceId' },
  iconAuthorizationWarning: { id: 'icon-authorization-warning' },

  addNewPackageButton: { id: 'add-new-package-button' },
  makePaymentButton: { id: 'make-payment-button' },
  printReceiptButton: { id: 'print-receipt-button' },
  patientBalanceButton: { id: 'patient-balance-button' },
  checkEligibilityButton: { id: 'check-eligibility-button' },

  multiCarePackageDetails: { id: 'multi-care-package-details' },
  viewMultiPackageDetailsButton: { id: 'view-multi-package-details-button' },

  copayInfo: { id: 'copay-info' },
  textAreaNote: { id: 'text-area-note' },
};

const NO_LINKED_AUTH_TOOLTIP =
  'Link an Authorization to the Case selected in order to apply it to this appointment.';

const EDITABLE_FROM_LEDGER_TOOLTIP =
  'This field is only editable from the ledger if this appointment is associated with an encounter that has posted charges.';

const REOPEN_ENCOUNTER_TO_EDIT_TOOLTIP =
  'This field is not editable if this appointment is associated with a signed encounter. To edit this field, the associated encounter must be reopened.';

const INACTIVE_PRIMARY_PLAN_ERROR =
  'Active Primary Plan on Case required to Check-In';
const INACTIVE_SECONDARY_PLAN_ERROR =
  'Active Secondary Plan on Case required to Check-In';
const INACTIVE_PRIMARY_SECONDARY_PLAN_ERROR =
  'Active Primary and Secondary Plan on Case required to Check-In';
const INACTIVE_PACKAGE_ERROR = 'Active Package required to Check-In';

const validatePackage = (_, __, data) => {
  const { caseBillTypeOverride, billType, patientPackageId } =
    data.billingDetails;

  if (
    !caseBillTypeOverride ||
    billType !== APPOINTMENT_BILLING_TYPE.CarePackage
  ) {
    return true;
  }

  return !!patientPackageId.data.active;
};

const validateInsurance = (_, __, data) => {
  const {
    caseBillTypeOverride,
    billType,
    primaryInsuranceId,
    secondaryInsuranceId,
  } = data.billingDetails;

  if (
    !caseBillTypeOverride ||
    billType !== APPOINTMENT_BILLING_TYPE.Insurance
  ) {
    return true;
  }

  const isPrimaryActive = !!primaryInsuranceId.data.active;
  const isSecondaryActive =
    !secondaryInsuranceId.data.id || !!secondaryInsuranceId.data.active;

  return isPrimaryActive && isSecondaryActive;
};

export class NebFormCheckIn extends NebForm {
  static get properties() {
    return {
      savingError: Boolean,
      metaData: Object,
      defaultInsurances: Object,

      __hasAddOnCtVerify: Boolean,
      __createEncounter: Boolean,
      __checkingRTE: Boolean,
      __selectedInsuranceLevel: String,
      __patientPayments: Array,
      __newAuths: Array,
    };
  }

  static createModel() {
    return {
      appointmentDetails: {
        appointmentTypeId: '',
        patientName: '',
        date: '',
        time: '',
        locationName: '',
        providerId: '',
        roomId: '',
        note: '',
        encounterId: '',
        patientId: '',
      },
      billingDetails: {
        patientBalance: '$0.00',
        billType: 'selfPay',
        guarantorId: '',
        feeScheduleId: '',
        patientPackageId: '',
        patientCaseId: '',
        patientAuthorizationId: '',
        caseBillTypeOverride: false,
        primaryInsuranceId: '',
        secondaryInsuranceId: '',
        tertiaryInsuranceId: '',
      },
    };
  }

  static createMetaData() {
    return {
      appointmentTypes: [],
      appointmentBillingInfo: null,
      users: [],
      rooms: [],
      guarantors: [],
      feeSchedules: [],
      packages: [],
      cases: [],
      authorizations: [],
      insurances: [],
      appointment: {},
      encounter: null,
      settings: {},
    };
  }

  createSelectors() {
    const { authorizations, settings } = this.metaData;
    const authsData = Object.values(authorizations).flat();
    const auths = authsData.length ? authsData : [selectors.ITEM_EMPTY];

    return {
      children: {
        appointmentDetails: {
          children: {
            appointmentTypeId: selectors.select(
              this.metaData.appointmentTypes,
              selectors.ITEM_EMPTY,
              { validators: [required()] },
            ),
            providerId: selectors.select(
              this.metaData.users,
              selectors.ITEM_EMPTY,
              { validators: [required()] },
            ),
            roomId: selectors.select(this.metaData.rooms, selectors.ITEM_EMPTY),
          },
        },
        billingDetails: {
          children: {
            patientCaseId: selectors.select(
              this.metaData.cases,
              selectors.ITEM_EMPTY,
              {
                validators: [
                  {
                    error: 'Required',
                    validate: (value, _, data) => {
                      if (data.billingDetails.caseBillTypeOverride) {
                        return !!value.data.id;
                      }

                      return true;
                    },
                  },
                  {
                    error: INACTIVE_PACKAGE_ERROR,
                    validate: (_, __, data) => validatePackage(_, __, data),
                  },
                  {
                    error: INACTIVE_PRIMARY_SECONDARY_PLAN_ERROR,
                    validate: (_, __, data) => validateInsurance(_, __, data),
                  },
                ],
              },
            ),
            patientAuthorizationId: selectors.select(
              auths,
              selectors.ITEM_EMPTY,
            ),
            guarantorId: selectors.select(
              this.metaData.guarantors,
              SELF_GUARANTOR_ITEM,
            ),
            feeScheduleId: {
              ...selectors.select(),
              format: (v, _, data) => {
                if (
                  data.billingDetails.billType ===
                  APPOINTMENT_BILLING_TYPE.Insurance
                ) {
                  return this.__findNewFeeSchedule();
                }

                return (
                  this.metaData.feeSchedules.find(item => item.data.id === v) ||
                  selectors.ITEM_EMPTY
                );
              },
            },
            patientPackageId: selectors.select(
              this.metaData.packages,
              selectors.ITEM_EMPTY,
              {
                validators: [
                  {
                    error: 'Required',
                    validate: (value, _, data) => {
                      if (
                        data.billingDetails.billType ===
                        APPOINTMENT_BILLING_TYPE.CarePackage
                      ) {
                        if (settings.multiCarePackage) {
                          return !!this.__filterActive(this.metaData.packages)
                            .length;
                        }

                        return !!value.data.id;
                      }

                      return true;
                    },
                  },
                ],
              },
            ),
            primaryInsuranceId: selectors.select(
              this.metaData.insurances,
              selectors.ITEM_EMPTY,
              {
                validators: [
                  {
                    error: 'Required',
                    validate: (value, _, data) => {
                      if (
                        data.billingDetails.billType ===
                        APPOINTMENT_BILLING_TYPE.Insurance
                      ) {
                        return !!value.data.id;
                      }

                      return true;
                    },
                  },
                ],
              },
            ),
            secondaryInsuranceId: selectors.select(
              this.metaData.insurances,
              selectors.ITEM_EMPTY,
            ),
            tertiaryInsuranceId: selectors.select(
              this.metaData.insurances,
              selectors.ITEM_EMPTY,
            ),
          },
        },
      },
    };
  }

  initState() {
    super.initState();

    this.metaData = NebFormCheckIn.createMetaData();

    this.__savingError = false;
    this.__hasAddOnCtVerify = false;
    this.__createEncounter = false;
    this.__checkingRTE = false;
    this.__selectedInsuranceLevel = INSURANCE_LEVELS.PRIMARY;
    this.__patientPayments = [];
    this.__newAuths = [];
    this.__defaultInsurances = null;
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      change: e => {
        const keyPath = e.name.split('.');
        const oldValue = getValueByPath(this.state, keyPath);

        if (oldValue !== e.value || e.name.includes('billType')) {
          this.formService.apply(e.name, e.value);

          if (keyPath[0] === 'appointmentDetails') {
            this.__handleAppointmentDetailsChange(keyPath);
          } else if (keyPath[0] === 'billingDetails') {
            this.__handleBillingDetailsChange(keyPath);
          }
        }
      },
      changeCreateEncounter: () => {
        this.__createEncounter = !this.__createEncounter;
      },
      captureNewAuths: newAuths => {
        this.__newAuths = newAuths;
      },
      addNewCase: async () => {
        const res = await openOverlay(OVERLAY_KEYS.CASE, {
          context: {
            patientId: this.model.appointmentDetails.patientId,
            guarantors: this.metaData.guarantors.filter(
              g => g.relation !== 'Self',
            ),
            isFirstCase:
              !this.metaData.cases.length ||
              (this.metaData.cases.length === 1 &&
                this.metaData.cases[0] === selectors.ITEM_EMPTY),
            onAuthorizationChange: this.handlers.captureNewAuths,
          },
        });

        if (res) {
          const newCase = res.patientCase ? res.patientCase : res;
          const newCaseItem = checkInUtil.formatCaseItem(newCase);

          this.metaData.cases = [
            newCaseItem,
            ...(newCaseItem.data.isDefault
              ? this.metaData.cases.map(c => ({
                  ...c,
                  data: { ...c.data, isDefault: false },
                }))
              : this.metaData.cases),
          ];

          const formattedNewAuths = checkInUtil.formatAuthItems(
            this.__newAuths,
          );

          if (formattedNewAuths.length) {
            this.metaData.authorizations[newCaseItem.data.id] =
              formattedNewAuths;
          }

          this.formService.apply('billingDetails.patientCaseId', newCaseItem);
          this.__patientCaseUpdated();
        }
      },
      addNewGuarantor: async () => {
        const guarantor = await openOverlay(OVERLAY_KEYS.GUARANTOR, {
          context: {
            patientId: this.model.appointmentDetails.patientId,
          },
        });

        if (guarantor?.organization || guarantor?.person) {
          const guarantorItem = checkInUtil.formatGuarantorItem(guarantor);

          this.metaData.guarantors = [
            guarantorItem,
            ...(guarantorItem.data.default
              ? this.metaData.guarantors.map(g => ({
                  ...g,
                  data: { ...g.data, default: false },
                }))
              : this.metaData.guarantors),
          ];

          this.formService.apply('billingDetails.guarantorId', guarantorItem);
        }
      },
      associateFeeSchedule: async () => {
        const feeSchedule = await openOverlay(
          OVERLAY_KEYS.ASSOCIATE_FEE_SCHEDULE,
          {
            patientId: this.model.appointmentDetails.patientId,
            feeSchedules: this.metaData.feeSchedules.map(({ data }) => ({
              id: data.id,
            })),
          },
        );

        if (feeSchedule) {
          const feeScheduleItem =
            checkInUtil.formatFeeScheduleItem(feeSchedule);

          this.metaData.feeSchedules = [
            feeScheduleItem,
            ...(feeScheduleItem.data.default
              ? this.metaData.feeSchedules.map(f => ({
                  ...f,
                  data: { ...f.data, default: false },
                }))
              : this.metaData.feeSchedules),
          ];

          this.formService.apply(
            'billingDetails.feeScheduleId',
            feeScheduleItem,
          );
        }
      },
      addNewPackage: async () => {
        const newPackage = await openOverlay(OVERLAY_KEYS.PATIENT_PACKAGE_ADD, {
          context: {
            patientId: this.model.appointmentDetails.patientId,
            filteredCount: this.metaData.packages.length,
          },
        });

        const startDate = new Date(this.metaData.appointment.start);

        if (newPackage) {
          const validPackage =
            getCarePackageStatus(newPackage, startDate) ===
            CARE_PACKAGE_STATUS.VALID;

          if (validPackage) {
            const packageItem = checkInUtil.formatPackageItem(newPackage);

            this.metaData.packages = [
              packageItem,
              ...(packageItem.data.default
                ? this.metaData.packages.map(p => ({
                    ...p,
                    data: { ...p.data, default: false },
                  }))
                : this.metaData.packages),
            ];

            this.formService.apply(
              'billingDetails.patientPackageId',
              packageItem,
            );
          } else {
            this.formService.apply(
              'billingDetails.patientPackageId',
              selectors.ITEM_EMPTY,
            );
          }
        }
      },
      selectInsuranceLevel: level => {
        this.__selectedInsuranceLevel = level;
      },
      addPrimaryInsurance: async () => {
        await this.__addNewInsurance('primaryInsuranceId');

        this.__findAndApplyNewFeeSchedule();
        this.requestUpdate();
      },
      addSecondaryInsurance: async () => {
        await this.__addNewInsurance('secondaryInsuranceId');
      },
      addTertiaryInsurance: async () => {
        await this.__addNewInsurance('tertiaryInsuranceId');
      },
      goToPatientBalance: async () => {
        if (this.__dirty) {
          const proceed = await openDirtyPopup();

          if (!proceed) return;

          await this.formService.reset();
        }

        navigate(
          `/patients/${this.model.appointmentDetails.patientId}/ledger/balance`,
        );
      },
      viewMultiPackageDetails: async () => {
        const packagesChanged = await openOverlay(
          OVERLAY_KEYS.PATIENT_PACKAGES_SUMMARY,
          {
            patientId: this.model.appointmentDetails.patientId,
            showAllActivePackages: true,
          },
        );

        if (packagesChanged) {
          const packages = await getPatientPackagesWithCounts(
            this.model.appointmentDetails.patientId,
          );

          this.metaData = {
            ...this.metaData,
            packages: packages.map(checkInUtil.formatPackageItem),
          };
        }
      },
      makePayment: async () => {
        const { patientId, patientName } = this.model.appointmentDetails;
        const { appointment, encounter } = this.metaData;

        const result = await openOverlay(OVERLAY_KEYS.ADD_PATIENT_PAYMENT, {
          patientId,
          patient: { name: patientName },
          appointmentId: appointment.id,
          locationId: appointment.locationId,
          encounterId: encounter?.id,
          doNotDismissAll: true,
        });

        if (this.__isPaymentValid(result)) {
          this.metaData.paymentCount += 1;
          const { balance } = await getPatientBalance(patientId);

          const newBalance = centsToCurrency(balance);

          this.formService.apply('billingDetails.patientBalance', newBalance);
        }
      },
      printReceipt: async () => {
        if (this.__patientPayments.length !== this.metaData.paymentCount) {
          const payments = await getPayments(
            this.model.appointmentDetails.patientId,
            {
              appointmentId: this.metaData.appointment.id,
            },
          );

          this.__patientPayments = payments.data.filter(
            p => !p.voidedAt && !p.refundedAt && !p.parentPaymentId,
          );

          this.metaData.paymentCount = this.__patientPayments.length;
        }

        if (this.__patientPayments.length > 0) {
          const rowsWithReceipts = this.__patientPayments.map(async row => {
            if (isElectronicPayment(row)) {
              const ePayment = await getElectronicPayment(
                row.electronicPaymentId,
              );

              if (ePayment && ePayment.receipt) {
                row.receipt = {
                  text: JSON.parse(ePayment.receipt).text.customer_receipt,
                };
              }
            }

            return row;
          });

          const resolvedRows = await Promise.all(rowsWithReceipts);

          const finalizedPayments = resolvedRows
            .map(item =>
              item.originalPaymentId
                ? formatRefundedSplitPayment(item, item.payments)
                : item,
            )
            .sort((a, b) => a.paymentNumber - b.paymentNumber);

          let selectedLocation = null;
          let appointmentListData = null;

          const { locationId } = this.metaData.appointment;
          selectedLocation = (await getLocations()).find(
            l => l.id === locationId,
          );

          appointmentListData = await getAppointmentListData(
            this.model.appointmentDetails.patientId,
            selectedLocation,
            RECEIPT_APPOINTMENT_LIMIT,
          );

          printPdf(
            printReceipts({
              patientPayments: finalizedPayments,
              selectedLocation,
              appointmentListData,
            }),
          );
        }
      },
      authorizationBannerClick: async () => {
        const result = await openOverlay(OVERLAY_KEYS.AUTHORIZATION, {
          id: this.state.billingDetails.patientAuthorizationId.data.id,
          patientId: this.model.appointmentDetails.patientId,
          patientCaseId: this.state.billingDetails.patientCaseId.data.id,
        });

        if (result) {
          const patientCaseId = this.state.billingDetails.patientCaseId.data.id;
          const authorizations =
            this.metaData.authorizations[patientCaseId] || [];

          let updatedAuthorizations;

          if (checkInUtil.checkValidAuthStatus(result)) {
            const newAuth = checkInUtil.formatAuthItem(result);
            this.formService.apply(
              'billingDetails.patientAuthorizationId',
              newAuth,
            );

            updatedAuthorizations = authorizations.map(auth =>
              auth.data.id === newAuth.data.id ? newAuth : auth,
            );
          } else {
            updatedAuthorizations = authorizations.filter(
              auth =>
                auth.data.id !==
                this.state.billingDetails.patientAuthorizationId.data.id,
            );

            this.formService.apply(
              'billingDetails.patientAuthorizationId',
              selectors.ITEM_EMPTY,
            );
          }

          this.metaData.authorizations[patientCaseId] = updatedAuthorizations;
        }
      },
      authorizationWarningClick: () => {
        if (this.layout === 'small') {
          store.dispatch(openWarning(NO_REMAINING_AUTHORIZATIONS_MESSAGE));
        } else {
          store.dispatch(
            openWarning(
              NO_REMAINING_AUTHORIZATIONS_MESSAGE,
              this.handlers.authorizationBannerClick,
            ),
          );
        }
      },
      checkEligibility: async () => {
        this.__checkingRTE = true;
        const appointmentDate = parseDate(this.metaData.appointment.start);
        const patientInsuranceId = this.__getInsuranceIdFromSelectedTab();

        const dateOfService = {
          beginningDateOfService: appointmentDate,
          endDateOfService: appointmentDate.add(1, 'days'),
        };

        try {
          const providerId = this.state.appointmentDetails.providerId.data.id;
          const response = await updateInsuranceFromRTE(
            patientInsuranceId,
            this.model.appointmentDetails.patientId,
            {
              dateOfService,
              providerId,
            },
          );

          if (response) {
            sendRefreshNotification([REFRESH_CHANGE_TYPE.SCHEDULING]);

            const updatedInsurance = response.data[0];

            await this.__updateRealTimeEligibility(
              updatedInsurance,
              this.model.appointmentDetails.patientId,
            );

            const updatedInsuranceItem = checkInUtil.formatInsuranceItem(
              updatedInsurance,
              providerId,
              this.metaData.appointment.start,
            );

            this.formService.apply(
              `billingDetails.${this.__getInsuranceLevelFromSelectedTab()}`,
              updatedInsuranceItem,
            );

            const originalIndex = this.metaData.insurances.findIndex(
              ins => ins.id === updatedInsuranceItem.id,
            );

            if (originalIndex !== -1) {
              this.metaData.insurances[originalIndex] = updatedInsuranceItem;
              this.metaData.insurances = [...this.metaData.insurances];
            } else {
              this.metaData.insurances = [
                updatedInsuranceItem,
                ...this.metaData.insurances.map(ins =>
                  checkInUtil.updateDefaultLevel(ins, updatedInsuranceItem),
                ),
              ];
            }
          }
        } catch (e) {
          console.error(e);
        }

        this.__checkingRTE = false;
      },
      cancel: () => this.onCancel(false),
    };
  }

  __isPaymentValid(paymentOverlayRes) {
    return (
      paymentOverlayRes &&
      paymentOverlayRes.paid &&
      paymentOverlayRes.payment &&
      !paymentOverlayRes.voidPayment
    );
  }

  async __getAssociatedInvoicesWarningMessage() {
    const { patientCaseId } = this.state.billingDetails;
    const oldPatientCaseId =
      this.metaData.appointmentBillingInfo?.patientCaseId;

    if (
      !this.metaData.encounter?.id ||
      !this.metaData.appointmentBillingInfo ||
      (!patientCaseId.data.id && !oldPatientCaseId) ||
      patientCaseId.data.id === oldPatientCaseId
    ) {
      return null;
    }

    const associatedInvoices = await getInvoicesFromEncounters([
      this.metaData.encounter.id,
    ]);

    if (!associatedInvoices || !associatedInvoices.length) return null;

    return associatedInvoices.length === 1
      ? WARNING_SINGLE_INVOICE_ASSOCIATED_AT_APPOINTMENT_LEVEL(
          associatedInvoices[0].invoiceNumber,
        )
      : WARNING_MULTIPLE_INVOICES_ASSOCIATED_AT_APPOINTMENT_LEVEL(
          associatedInvoices.map(({ invoiceNumber }) => invoiceNumber),
        );
  }

  __getFeeScheduleIdForSave(billType, feeScheduleId) {
    return billType === APPOINTMENT_BILLING_TYPE.Insurance
      ? this.state.billingDetails.primaryInsuranceId.data.feeScheduleId
      : feeScheduleId;
  }

  __formatModelToCheckInBody(model) {
    const { appointmentDetails, billingDetails } = model;
    const { appointmentTypeId, providerId, roomId, note } = appointmentDetails;
    const {
      billType,
      caseBillTypeOverride,
      patientPackageId,
      guarantorId,
      primaryInsuranceId,
      secondaryInsuranceId,
      tertiaryInsuranceId,
      feeScheduleId,
      patientCaseId,
      patientAuthorizationId,
    } = billingDetails;

    const { appointment, appointmentBillingInfo, invoiceIds, settings } =
      this.metaData;
    const { id, locationId, resourceId } = appointment;

    const updateScheduleRoom =
      this.state.appointmentDetails.roomId.data.id &&
      this.state.appointmentDetails.roomId.data.scheduleAvailable &&
      !!settings.updateScheduleRoom;

    const billingInfo = {
      id: appointmentBillingInfo?.id || null,
      appointmentId: id,
      billType,
      caseBillTypeOverride,
      guarantorId:
        (billType === APPOINTMENT_BILLING_TYPE.SelfPay ||
          billType === APPOINTMENT_BILLING_TYPE.Insurance) &&
        guarantorId
          ? guarantorId
          : null,
      patientPackageId:
        billType === APPOINTMENT_BILLING_TYPE.CarePackage &&
        patientPackageId &&
        !settings.multiCarePackage
          ? patientPackageId
          : null,
      primaryInsuranceId:
        billType === APPOINTMENT_BILLING_TYPE.Insurance && primaryInsuranceId
          ? primaryInsuranceId
          : null,
      secondaryInsuranceId:
        billType === APPOINTMENT_BILLING_TYPE.Insurance &&
        primaryInsuranceId &&
        secondaryInsuranceId
          ? secondaryInsuranceId
          : null,
      tertiaryInsuranceId:
        billType === APPOINTMENT_BILLING_TYPE.Insurance &&
        primaryInsuranceId &&
        secondaryInsuranceId &&
        tertiaryInsuranceId
          ? tertiaryInsuranceId
          : null,
      feeScheduleId: feeScheduleId || null,
      patientCaseId: patientCaseId || null,
      patientAuthorizationId: patientAuthorizationId || null,
      multiCarePackage: settings.multiCarePackage,
    };

    return {
      id,
      appointmentTypeId,
      providerId: providerId || null,
      roomId: roomId || null,
      resourceId: updateScheduleRoom ? roomId : resourceId,
      caseId: patientCaseId || null,
      patientAuthorizationId: patientAuthorizationId || null,
      note,
      locationId,
      invoiceIds,
      billingInfo,
      didBillingInfoChange: this.__didBillingInfoChangeOnSave(billingInfo),
      hasAllocations: !!this.metaData.hasAllocations,
    };
  }

  __formatInsurancesForPopup(insurances) {
    if (insurances.length === 1) return insurances[0];
    const firsts = insurances.slice(0, insurances.length - 1);
    const last = insurances[insurances.length - 1];

    if (insurances.length === 2) return `${firsts} and ${last}`;

    return `${firsts.join(', ')}, and ${last}`;
  }

  async __validateInsuranceCoverage() {
    if (
      this.state.billingDetails.billType !== APPOINTMENT_BILLING_TYPE.Insurance
    ) {
      return true;
    }

    const outOfCoverageInsurances = [
      this.state.billingDetails.primaryInsuranceId,
      this.state.billingDetails.secondaryInsuranceId,
      this.state.billingDetails.tertiaryInsuranceId,
    ].reduce((memo, ins) => {
      if (ins.data.id && ins.data.outOfCoverage) {
        memo.push(ins.label);
      }

      return memo;
    }, []);

    if (outOfCoverageInsurances.length) {
      const res = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
        title: 'Insurance Coverage Dates',
        message: html`
          The start or term dates on insurance plan(s)
          ${this.__formatInsurancesForPopup(outOfCoverageInsurances)} indicates
          this appointment is outside of the covered date range. <br /><br />Are
          you sure you want to use this insurance?
        `,
        confirmText: 'Yes',
        cancelText: 'No',
      });

      return res;
    }

    return true;
  }

  async __validateAssociatedInvoicesAndAllocations() {
    const associatedInvoicesWarning =
      await this.__getAssociatedInvoicesWarningMessage();
    const warning =
      associatedInvoicesWarning && associatedInvoicesWarning.length
        ? associatedInvoicesWarning[0]
        : null;

    if (this.metaData.hasAllocations) {
      const allocationPopupRes = await openPopup(
        POPUP_RENDER_KEYS.CONFIRM,
        REMOVE_ALLOCATION_CONFIRMATION_POPUP(warning),
      );

      if (!allocationPopupRes) {
        return false;
      }
    } else if (warning) {
      const associateInvoicePopupRes = await openPopup(
        POPUP_RENDER_KEYS.DIALOG,
        {
          title: 'Warning',
          message: warning,
          buttons: [
            { name: 'save', label: 'YES' },
            { name: 'discard', label: 'NO' },
          ],
        },
      );

      if (associateInvoicePopupRes !== 'save') {
        return false;
      }
    }

    return true;
  }

  async __validateSubscriptionStatus() {
    const { patientPackageId, billType } = this.state.billingDetails;

    const { packages, encounter } = this.metaData;

    if (
      patientPackageId &&
      patientPackageId.data &&
      patientPackageId.data.id &&
      billType === APPOINTMENT_BILLING_TYPE.CarePackage
    ) {
      if (!(this.__filterActive(packages).length < 1 || encounter?.signed)) {
        try {
          const status = await getScheduleStatus(
            this.model.appointmentDetails.patientId,
            {
              patientPackageId: patientPackageId.data.id,
            },
          );

          if (status.displayStatus === 'Failed' || status.error) {
            const res = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
              title: 'Subscription Payment Failed',
              message: html`
                The last payment attempt for this subscription failed. Payment
                for this subscription will not be received until the failed
                payment is re-processed, which may require a new payment method.
                <br /><br />Do you want to continue to apply this subscription
                to this appointment?
              `,
              confirmText: 'Yes',
              cancelText: 'No',
            });

            if (!res) {
              return false;
            }
          }
        } catch (e) {
          console.error(e);
          return false;
        }
      }
    }

    return true;
  }

  async __validateRoomCapacity() {
    const { roomId } = this.state.appointmentDetails;

    if (roomId && roomId.data.id) {
      let override;

      try {
        override = await roomConflictOverridePopup(roomId.data.id);
      } catch (e) {
        store.dispatch(openError(ROOM_CHECKED_IN_ERROR));
        override = false;
      }

      if (!override) {
        return false;
      }
    }

    return true;
  }

  __didBillingInfoChangeOnSave(newBillingInfo) {
    if (!this.metaData.appointmentBillingInfo) {
      return (
        this.state.billingDetails.billType !==
        this.model.billingDetails.billType
      );
    }

    const { updatedAt, createdAt, ...filteredAppointmentBillingInfo } =
      this.metaData.appointmentBillingInfo;

    const normalizedAppointmentBillingInfo = {
      ...filteredAppointmentBillingInfo,
      caseBillTypeOverride:
        filteredAppointmentBillingInfo.caseBillTypeOverride === 1,
    };

    const normalizedNewBillingInfo = {
      ...newBillingInfo,
      caseBillTypeOverride: newBillingInfo.caseBillTypeOverride === true,
    };

    return !equal(normalizedAppointmentBillingInfo, normalizedNewBillingInfo);
  }

  async save() {
    if (this.formService.validate()) {
      const insuranceCheck = await this.__validateInsuranceCoverage();
      if (!insuranceCheck) return this.onError();

      if (!this.metaData.settings.multiCarePackage) {
        const subscriptionStatusCheck =
          await this.__validateSubscriptionStatus();
        if (!subscriptionStatusCheck) return this.onError();
      }

      const roomCapacityCheck = await this.__validateRoomCapacity();
      if (!roomCapacityCheck) return this.onError();

      const rawModel = this.formService.build();
      const model = map(rawModel, (keyPath, value) =>
        typeof value === 'string' ? value.trim() : value,
      );

      const formattedModel = this.__formatModelToCheckInBody(model);

      if (formattedModel.didBillingInfoChange) {
        const associatedInvoicesCheck =
          await this.__validateAssociatedInvoicesAndAllocations();
        if (!associatedInvoicesCheck) return this.onError();
      }

      this.__saving = true;

      const selectedProviderName =
        this.state.appointmentDetails.providerId.data.name;

      return this.onSave(
        formattedModel,
        this.__createEncounter,
        selectedProviderName,
      );
    }

    return this.onError();
  }

  async __updateRealTimeEligibility(item, patientId) {
    const rteRecords = await fetchMany(patientId, item.id, true);

    if (rteRecords?.data.length) {
      let rteRecord;

      if (rteRecords.data.length === 1) {
        rteRecord = rteRecords.data[0];
      } else {
        rteRecord = rteRecords.data.reduce((latest, record) =>
          parseDate(record.submittedAt).isAfter(parseDate(latest.submittedAt))
            ? record
            : latest,
        );
      }

      item.realTimeEligibilityStatus = rteRecord.status;
      item.realTimeEligibilityRecord = {
        description: rteRecord.description,
        submittedAt: rteRecord.submittedAt,
      };
    }
  }

  async __addNewInsurance(level) {
    const { insurances } = this.metaData;
    const patientInsurances = insurances.map(({ data }) => data);
    const { patientId, providerId } = this.model.appointmentDetails;

    const context = {
      cancel: 'cancel',
      showBackButton: true,
      hasAddOnCTVerify: this.__hasAddOnCtVerify,
    };

    const overlayKey = this.__hasAddOnCtVerify
      ? OVERLAY_KEYS.ADD_INSURANCE
      : OVERLAY_KEYS.PATIENT_INSURANCE_ADD;

    const overlayOptions = {
      patientId,
      patientInsurances,
      context,
      ...(this.__hasAddOnCtVerify && { providerId }),
      forCheckIn: true,
    };

    const item = await openOverlay(overlayKey, overlayOptions);

    if (item && this.__hasAddOnCtVerify) {
      await this.__updateRealTimeEligibility(item, patientId);
    }

    if (item) {
      const insuranceItem = checkInUtil.formatInsuranceItem(
        item,
        providerId,
        this.metaData.appointment.start,
      );
      this.formService.apply(`billingDetails.${level}`, insuranceItem);

      if (item.defaultLevel === 'Other') {
        this.metaData.insurances = [insuranceItem, ...this.metaData.insurances];
      } else {
        const updatedInsurances = checkInUtil.updateInsuranceDefaultLevels(
          this.metaData.insurances,
          item.defaultLevel,
        );

        this.metaData.insurances = [insuranceItem, ...updatedInsurances];

        this.defaultInsurances = checkInUtil.getDefaultInsurances(
          this.metaData.insurances,
        );
      }
    }
  }

  __encounterExists() {
    return !!this.state.appointmentDetails.encounterId;
  }

  __providerUpdated() {
    const provider = this.state.appointmentDetails.providerId.data;

    this.__createEncounter =
      !this.__encounterExists() && provider.createEncounterAtCheckIn;
  }

  __handleAppointmentDetailsChange(keyPath) {
    switch (keyPath[1]) {
      case 'providerId':
        this.__providerUpdated();
        this.__findAndApplyNewFeeSchedule();
        break;

      default:
        break;
    }
  }

  __getInsuranceLevelFromSelectedTab() {
    switch (this.__selectedInsuranceLevel) {
      case INSURANCE_LEVELS.PRIMARY:
        return 'primaryInsuranceId';
      case INSURANCE_LEVELS.SECONDARY:
        return 'secondaryInsuranceId';
      case INSURANCE_LEVELS.TERTIARY:
        return 'tertiaryInsuranceId';
      default:
        return null;
    }
  }

  __getInsuranceIdFromSelectedTab() {
    switch (this.__selectedInsuranceLevel) {
      case INSURANCE_LEVELS.PRIMARY:
        return this.state.billingDetails.primaryInsuranceId.data.id;
      case INSURANCE_LEVELS.SECONDARY:
        return this.state.billingDetails.secondaryInsuranceId.data.id;
      case INSURANCE_LEVELS.TERTIARY:
        return this.state.billingDetails.tertiaryInsuranceId.data.id;
      default:
        return null;
    }
  }

  __setDefaultSelfBillType() {
    const { guarantors, feeSchedules } = this.metaData;

    const defaultGuarantor = guarantors.find(g => g.data.default);
    const defaultFeeSchedule = feeSchedules.find(f => f.data.default);

    this.formService.apply(
      'billingDetails.guarantorId',
      defaultGuarantor || SELF_GUARANTOR_ITEM,
    );

    this.formService.apply(
      'billingDetails.feeScheduleId',
      defaultFeeSchedule || selectors.ITEM_EMPTY,
    );
  }

  __setDefaultCarePackageBillType() {
    const { packages, feeSchedules, settings } = this.metaData;

    const defaultPackage = packages.find(p => p.data.default);
    const defaultFeeSchedule = feeSchedules.find(f => f.data.default);

    if (settings.multiCarePackage) {
      this.formService.apply(
        'billingDetails.patientPackageId',
        selectors.ITEM_EMPTY,
      );
    } else {
      this.formService.apply(
        'billingDetails.patientPackageId',
        defaultPackage || selectors.ITEM_EMPTY,
      );

      if (!defaultPackage) {
        this.formService.validateKey(
          ['billingDetails', 'patientPackageId'],
          true,
        );
      }
    }

    this.formService.apply(
      'billingDetails.feeScheduleId',
      defaultFeeSchedule || selectors.ITEM_EMPTY,
    );
  }

  __setDefaultInsuranceBillType() {
    if (!this.defaultInsurances) {
      this.defaultInsurances = checkInUtil.getDefaultInsurances(
        this.metaData.insurances,
      );
    }

    const { primary, secondary, tertiary } = this.defaultInsurances;

    this.formService.apply(
      'billingDetails.primaryInsuranceId',
      primary || selectors.ITEM_EMPTY,
    );

    this.formService.apply(
      'billingDetails.secondaryInsuranceId',
      secondary || selectors.ITEM_EMPTY,
    );

    this.formService.apply(
      'billingDetails.tertiaryInsuranceId',
      tertiary || selectors.ITEM_EMPTY,
    );

    if (!primary) {
      this.formService.validateKey(
        ['billingDetails', 'primaryInsuranceId'],
        true,
      );
    }
  }

  __billTypeUpdated() {
    this.formService.apply('billingDetails.caseBillTypeOverride', false);
    this.formService.validateKey(['billingDetails', 'patientCaseId'], true);

    switch (this.state.billingDetails.billType) {
      case APPOINTMENT_BILLING_TYPE.SelfPay:
        this.__setDefaultSelfBillType();

        break;

      case APPOINTMENT_BILLING_TYPE.CarePackage:
        this.__setDefaultCarePackageBillType();

        break;

      case APPOINTMENT_BILLING_TYPE.Insurance: {
        this.__selectedInsuranceLevel = INSURANCE_LEVELS.PRIMARY;

        this.__setDefaultInsuranceBillType();
        this.__findAndApplyNewFeeSchedule();
        break;
      }

      default:
        break;
    }
  }

  __billTypeUpdatedViaCase() {
    let caseGuarantor;

    switch (this.state.billingDetails.billType) {
      case APPOINTMENT_BILLING_TYPE.SelfPay: {
        caseGuarantor = this.metaData.guarantors.find(
          g =>
            g.data.id ===
            this.state.billingDetails.patientCaseId.data.guarantorId,
        );

        this.formService.apply(
          'billingDetails.guarantorId',
          caseGuarantor || SELF_GUARANTOR_ITEM,
        );

        break;
      }

      case APPOINTMENT_BILLING_TYPE.CarePackage: {
        const casePackage = this.metaData.packages.find(
          p =>
            p.data.id ===
            this.state.billingDetails.patientCaseId.data.patientPackageId,
        );
        this.formService.apply('billingDetails.patientPackageId', casePackage);

        break;
      }

      case APPOINTMENT_BILLING_TYPE.Insurance: {
        this.__selectedInsuranceLevel = INSURANCE_LEVELS.PRIMARY;

        caseGuarantor = this.metaData.guarantors.find(
          g =>
            g.data.id ===
            this.state.billingDetails.patientCaseId.data.guarantorId,
        );

        const caseInsurance = this.metaData.insurances.find(
          i =>
            i.data.id ===
            this.state.billingDetails.patientCaseId.data.primaryInsuranceId,
        );
        const secondaryInsurance = this.metaData.insurances.find(
          i =>
            i.data.id ===
            this.state.billingDetails.patientCaseId.data.secondaryInsuranceId,
        );

        this.formService.apply(
          'billingDetails.guarantorId',
          caseGuarantor || SELF_GUARANTOR_ITEM,
        );

        this.formService.apply(
          'billingDetails.primaryInsuranceId',
          caseInsurance,
        );

        this.formService.apply(
          'billingDetails.secondaryInsuranceId',
          secondaryInsurance || selectors.ITEM_EMPTY,
        );

        break;
      }

      default:
        break;
    }
  }

  __caseBillTypeOverrideUpdated() {
    const activeCases = this.__filterActive(this.metaData.cases);

    if (!this.model.billingDetails.patientCaseId && activeCases.length) {
      const defaultCase =
        activeCases.find(c => c.data.isDefault) || activeCases[0];

      this.formService.apply('billingDetails.patientCaseId', defaultCase);
      this.formService.apply(
        'billingDetails.billType',
        defaultCase.data.billType,
      );

      this.__patientCaseUpdated();
    } else {
      this.formService.validateKey(['billingDetails', 'patientCaseId'], true);
    }
  }

  __patientCaseUpdated() {
    const { patientAuthorizationId, patientCaseId } = this.state.billingDetails;

    if (
      !patientAuthorizationId.data.id ||
      patientCaseId.data.id !== patientAuthorizationId.data.patientCaseId
    ) {
      const authPath = 'billingDetails.patientAuthorizationId';
      const caseId = this.state.billingDetails.patientCaseId.data.id;
      const auths = this.metaData.authorizations[caseId];

      if (auths) {
        this.formService.apply(authPath, auths[0]);
      } else {
        this.formService.apply(authPath, selectors.ITEM_EMPTY);
      }
    }

    if (this.state.billingDetails.caseBillTypeOverride) {
      this.formService.apply(
        'billingDetails.billType',
        this.state.billingDetails.patientCaseId.data.billType,
      );

      this.__billTypeUpdatedViaCase();
    }

    this.formService.validateKey(['billingDetails', 'patientCaseId'], true);
  }

  __patientAuthUpdated() {
    if (!this.state.billingDetails.patientCaseId.data.id) {
      const matchingCase = this.metaData.cases.find(
        c =>
          c.data.id ===
          this.state.billingDetails.patientAuthorizationId.data.patientCaseId,
      );

      if (matchingCase) {
        this.formService.apply('billingDetails.patientCaseId', matchingCase);
        this.__patientCaseUpdated();
      }
    }
  }

  __insuranceUpdated(level) {
    const insuranceItem = this.state.billingDetails[level];

    if (!insuranceItem.data.id) {
      if (level === 'primaryInsuranceId') {
        this.formService.apply(
          'billingDetails.secondaryInsuranceId',
          selectors.ITEM_EMPTY,
        );

        this.formService.apply(
          'billingDetails.tertiaryInsuranceId',
          selectors.ITEM_EMPTY,
        );
      }

      if (level === 'secondaryInsuranceId') {
        this.formService.apply(
          'billingDetails.tertiaryInsuranceId',
          selectors.ITEM_EMPTY,
        );
      }
    }
  }

  __handleBillingDetailsChange(keyPath) {
    switch (keyPath[1]) {
      case 'billType':
        this.__billTypeUpdated();
        break;

      case 'caseBillTypeOverride':
        this.__caseBillTypeOverrideUpdated();
        break;

      case 'patientCaseId':
        this.__patientCaseUpdated();
        break;

      case 'patientAuthorizationId':
        this.__patientAuthUpdated();
        break;

      case 'primaryInsuranceId':
        this.__insuranceUpdated(keyPath[1]);
        this.__findAndApplyNewFeeSchedule();
        break;

      case 'secondaryInsuranceId':
      case 'tertiaryInsuranceId':
        this.__insuranceUpdated(keyPath[1]);
        break;

      default:
        break;
    }
  }

  __renderEncounterAssociationTooltip(fieldName) {
    const tooltipText =
      fieldName === 'appointmentTypeId'
        ? REOPEN_ENCOUNTER_TO_EDIT_TOOLTIP
        : EDITABLE_FROM_LEDGER_TOOLTIP;
    const encounterSigned = this.metaData.encounter?.signed;

    if (encounterSigned) {
      return html`
        <neb-tooltip id="tooltip-${fieldName}" class="tooltip">
          <div slot="tooltip">${tooltipText}</div>
        </neb-tooltip>
      `;
    }

    return '';
  }

  __wrapFieldWithTooltip(field, fieldName) {
    return html`
      <div class="select-container">
        ${field} ${this.__renderEncounterAssociationTooltip(fieldName)}
      </div>
    `;
  }

  __getCaseFieldError() {
    const { patientCaseId } = this.errors.billingDetails;

    if (!patientCaseId) {
      return '';
    }

    if (patientCaseId === INACTIVE_PACKAGE_ERROR) {
      return patientCaseId;
    }

    if (patientCaseId === INACTIVE_PRIMARY_SECONDARY_PLAN_ERROR) {
      const { primaryInsuranceId, secondaryInsuranceId } =
        this.state.billingDetails;
      const primaryActive = primaryInsuranceId.data.active;
      const secondaryActive = secondaryInsuranceId.data.id
        ? secondaryInsuranceId.data.active
        : true;

      if (!primaryActive && !secondaryActive) {
        return patientCaseId;
      }

      if (!primaryActive) {
        return INACTIVE_PRIMARY_PLAN_ERROR;
      }

      if (!secondaryActive) {
        return INACTIVE_SECONDARY_PLAN_ERROR;
      }
    }

    return patientCaseId;
  }

  __filterActive(items) {
    return items.filter(item => item.data.active);
  }

  __findNewFeeSchedule() {
    const provider = this.state.appointmentDetails.providerId;

    const feeSchedulePayerPlan =
      this.state.billingDetails?.primaryInsuranceId?.data
        ?.providerFeeScheduleMap?.[provider.data.id] ||
      this.state.billingDetails?.primaryInsuranceId?.data
        ?.providerFeeScheduleMap?.allProviders;

    if (feeSchedulePayerPlan) {
      const { feeScheduleId, feeSchedule } = feeSchedulePayerPlan;

      const feeScheduleToApply = {
        label: feeSchedule.name,
        data: {
          id: feeScheduleId,
        },
      };

      return feeScheduleToApply;
    }

    return selectors.ITEM_EMPTY;
  }

  __findAndApplyNewFeeSchedule() {
    const feeSchedule = this.__findNewFeeSchedule();

    this.formService.apply('billingDetails.feeScheduleId', feeSchedule);
  }

  static get styles() {
    return [
      super.styles,
      css`
        .layout {
          display: grid;
          padding-bottom: ${CSS_SPACING};
          background-color: ${CSS_COLOR_WHITE};
          grid-gap: ${CSS_SPACING} 0;
          grid-template-columns: 1fr;
          grid-auto-rows: unset;
          flex: 1 0 0;
        }

        .content {
          display: flex;
          overflow-y: auto;
          overflow-x: hidden;
          min-height: 0;
          flex-flow: column nowrap;
          flex: 1 0 0;
          scrollbar-gutter: stable;
        }

        .container-form {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: ${CSS_SPACING};
          padding: 0 5px ${CSS_SPACING} ${CSS_SPACING};
          background-color: ${CSS_COLOR_WHITE};
        }

        .patient-name-label {
          min-height: 22px;
          margin-bottom: ${CSS_SPACING};
          font-size: ${CSS_FONT_SIZE_HEADER};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .button {
          margin-right: 10px;
        }

        .payment-buttons-container {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: ${CSS_SPACING};
        }

        .payment-button {
          padding: 20px 0;
          min-width: 165px;
        }

        .insurance-detail-top-row {
          display: grid;
          grid-template-columns: 1fr 2fr;
        }

        .payer-name-text {
          word-break: break-all;
        }

        .check-eligibility-button {
          width: 75%;
          justify-self: center;
          align-content: center;
        }

        .action-bar-container {
          display: flex;
          flex-wrap: wrap;
          align-content: stretch;
          padding: ${CSS_SPACING};
          border-top: 1px solid ${CSS_COLOR_HIGHLIGHT};
          height: 110px;
          gap: 15px;
        }

        .container-item {
          display: flex;
          align-items: center;
          flex-basis: 100%;
        }

        .section-header {
          color: ${CSS_COLOR_HIGHLIGHT};
          font-size: ${CSS_FONT_SIZE_BODY};
        }

        .appointment-details {
          font-size: ${CSS_FONT_SIZE_HEADER};
        }

        .select {
          width: 100%;
        }

        .note {
          height: 212px;
        }

        .billing-details-section {
          display: grid;
          grid-template-rows: repeat(4, auto);
        }

        .bold,
        .section-header,
        .appointment-details,
        .billing-details-section {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .bill-type-radios {
          display: flex;
          flex-flow: wrap;
          width: 100%;
          margin-left: -10px;
          margin-bottom: 5px;
        }

        .padding-bottom,
        .select {
          padding-bottom: ${CSS_SPACING};
        }

        .padding-bottom-slim {
          padding-bottom: 10px;
        }

        .padding-top-slim {
          padding-top: 10px;
        }

        .appointment-details-section {
          padding-bottom: 30px;
        }

        .case-details {
          padding-top: ${CSS_SPACING};
          border-top: 1px solid ${CSS_COLOR_GREY_7};
        }

        .border-bottom {
          border-bottom: 1px solid ${CSS_COLOR_GREY_7};
        }

        .select-container {
          display: grid;
          grid-template-columns: 1fr auto;
        }

        .tooltip {
          padding: 23px 5px;
        }

        .view-details {
          color: ${CSS_COLOR_HIGHLIGHT};
          text-decoration: underline;
          cursor: pointer;
        }

        .multi-package-list {
          padding-left: ${CSS_SPACING};
        }

        .error-message {
          color: ${CSS_COLOR_ERROR};
        }

        .trailing-icon {
          margin-left: 5px;
        }

        .leading-icon {
          margin-right: 5px;
        }

        .icon-status {
          display: block;
          width: 20px;
          height: 20px;
          fill: ${CSS_BANNER_ERROR_COLOR};
        }

        .icon-validated,
        .icon-partially-validated {
          width: 20px;
          height: 20px;
          fill: ${CSS_BANNER_SUCCESS_COLOR};
        }

        .icon-warning {
          width: 20px;
          height: 20px;
          fill: ${CSS_COLOR_YELLOW};
        }

        .flex {
          display: flex;
        }

        .icon-authorization-warning {
          margin: 23px 5px;
          display: block;
          cursor: pointer;
          width: 24px;
          height: 24px;

          fill: ${CSS_WARNING_COLOR};
        }

        :host(:not([layout='large'])) .bill-type-radios {
          display: grid;
          grid-template-columns: auto auto;
        }

        :host([layout='small']) .layout {
          padding: unset;
        }

        :host([layout='small']) .container-form {
          display: flex;
          flex-direction: column;
          padding: 0 32px 20px 32px;
        }
      `,
    ];
  }

  async connectedCallback() {
    super.connectedCallback();

    this.__hasAddOnCtVerify = await hasAddOn(ADD_ONS.CT_VERIFY);
  }

  updated(changedProps) {
    if (
      changedProps.has('model') &&
      this.state.appointmentDetails?.providerId?.data?.id
    ) {
      const { createEncounterAtCheckIn } =
        this.state.appointmentDetails.providerId.data;

      this.__createEncounter =
        !this.__encounterExists() && createEncounterAtCheckIn;
    }

    if (changedProps.has('savingError') && this.savingError) {
      this.__saving = false;
    }
  }

  __renderColumnLeft() {
    const {
      appointmentDetails: {
        appointmentTypeId,
        patientName,
        date,
        time,
        locationName,
        providerId,
        calendarResourceName,
        roomId,
        note,
      },
    } = this.state;
    const { appointmentTypes, users, rooms, encounter } = this.metaData;
    const prefix = 'appointmentDetails';

    return html`
      <div class="column-left">
        <div class="appointment-details-section appointment-details">
          <p class="section-header padding-bottom">Appointment Details</p>
          <p class="padding-bottom">${patientName}</p>
          <p>${date}</p>
          <p>${time}</p>
          <p>${locationName}</p>
          <p>${calendarResourceName}</p>
        </div>
        <div class="column-left-fields">
          <neb-select
            id="${ELEMENTS.selectProvider.id}"
            class="select"
            label="Provider"
            helper="Required"
            name="${prefix}.providerId"
            .items="${users}"
            .value="${providerId}"
            .error="${this.errors.appointmentDetails.providerId}"
            .onChange="${this.handlers.change}"
            ?disabled="${users.length < 1 || encounter?.id}"
          ></neb-select>
          <div class="select-container">
            ${this.__wrapFieldWithTooltip(
              html`
                <neb-select
                  id="${ELEMENTS.selectAppointmentType.id}"
                  class="select"
                  label="Appointment Type"
                  name="${prefix}.appointmentTypeId"
                  helper="Required"
                  .value="${appointmentTypeId}"
                  .items="${appointmentTypes}"
                  .onChange="${this.handlers.change}"
                  ?disabled="${encounter?.signed}"
                ></neb-select>
              `,
              'appointmentTypeId',
            )}
          </div>
          <neb-select
            id="${ELEMENTS.selectRoom.id}"
            class="select"
            label="Check-In Room"
            name="${prefix}.roomId"
            .items="${[selectors.ITEM_EMPTY, ...rooms]}"
            .value="${roomId}"
            .onChange="${this.handlers.change}"
          ></neb-select>
          <neb-textarea
            id="${ELEMENTS.textAreaNote.id}"
            class="note"
            label="Note"
            name="${prefix}.note"
            .value="${note}"
            .onChange="${this.handlers.change}"
            maxLength="500"
            showCount
          ></neb-textarea>
        </div>
      </div>
    `;
  }

  __renderRadioButtons(type, override) {
    const radioButtons = Object.entries(BILLING_OPTIONS).map(
      ([key, label]) => html`
        <neb-radio-button
          id="${key}-radio"
          name="billingDetails.billType"
          label="${label}"
          .value="${key}"
          .checked="${type === key && !override}"
          .onChange="${this.handlers.change}"
          ?disabled="${this.metaData.encounter?.signed}"
        ></neb-radio-button>
      `,
    );

    radioButtons.push(html`
      <neb-radio-button
        id="${ELEMENTS.caseRadio.id}"
        name="billingDetails.caseBillTypeOverride"
        label="Case"
        .checked="${override}"
        .value="${true}"
        .onChange="${this.handlers.change}"
        ?disabled="${this.metaData.encounter?.signed}"
      ></neb-radio-button>
    `);

    return radioButtons;
  }

  __renderSelfPayFields(override) {
    if (!this.state) return '';

    const { guarantorId, feeScheduleId } = this.state.billingDetails;
    const { guarantors, feeSchedules, encounter } = this.metaData;

    return html`
      ${override
        ? html`
            <div class="case-details">
              <div class="padding-bottom-slim">
                <p class="bold">Case Bill Type</p>
                <p>${BILLING_OPTIONS.selfPay}</p>
              </div>
              <div class="padding-bottom border-bottom">
                <p class="bold">Guarantor</p>
                <p>${guarantorId.label}</p>
              </div>
            </div>
          `
        : this.__wrapFieldWithTooltip(
            html`
              <neb-select
                id="${ELEMENTS.selectGuarantor.id}"
                class="select"
                label="Guarantor"
                addNewItemLabel="Patient Guarantor"
                name="billingDetails.guarantorId"
                .value="${guarantorId}"
                .items="${[
                  SELF_GUARANTOR_ITEM,
                  ...this.__filterActive(guarantors),
                ]}"
                .onChange="${this.handlers.change}"
                .onAddNewItem="${this.handlers.addNewGuarantor}"
                ?disabled="${encounter?.signed}"
              ></neb-select>
            `,
            'guarantorId',
          )}
      ${this.__wrapFieldWithTooltip(
        html`
          <neb-select
            id="${ELEMENTS.selectFeeSchedule.id}"
            class="${`select${override ? ' padding-top-slim' : ''}`}"
            label="Fee Schedule"
            name="billingDetails.feeScheduleId"
            addNewItemLabel="Fee Schedule"
            customAddText="Associate"
            .value="${feeScheduleId}"
            .items="${[selectors.ITEM_EMPTY, ...feeSchedules]}"
            .onChange="${this.handlers.change}"
            .onAddNewItem="${this.handlers.associateFeeSchedule}"
            ?disabled="${encounter?.signed}"
          ></neb-select>
        `,
        'feeScheduleId',
      )}
    `;
  }

  __renderCarePackageSummary(packageSummary, override, multiCarePackage) {
    return (!multiCarePackage && packageSummary.length) || override
      ? html`
          <div class="${`padding-bottom${override ? ' border-bottom' : ''}`}">
            <p class="bold">Summary</p>
            ${packageSummary.map(text => html` <p>${text}</p> `)}
          </div>
        `
      : '';
  }

  __renderCarePackageBasedOnMulti(billType, patientPackageId, packages, multi) {
    const activePackages = this.__filterActive(packages);

    return multi
      ? html`
          <div
            id="${ELEMENTS.multiCarePackageDetails.id}"
            class="multi-package-container"
          >
            <p>
              <span class="bold">Available Packages: </span
              >${activePackages.length
                ? html`
                    <span
                      id="${ELEMENTS.viewMultiPackageDetailsButton.id}"
                      class="view-details"
                      @click="${this.handlers.viewMultiPackageDetails}"
                      >View Details</span
                    >
                  `
                : ''}
            </p>
            <p class="multi-package-list padding-bottom-slim">
              ${activePackages.length
                ? activePackages
                    .filter(p => p.data.active)
                    .map(p => html` <li>${p.label}</li> `)
                : html`
                    <span class="error-message">${INACTIVE_PACKAGE_ERROR}</span>
                  `}
            </p>
            <neb-button-action
              id="${ELEMENTS.addNewPackageButton.id}"
              class="button padding-bottom"
              label="Add New Package"
              leadingIcon="plus"
              .onClick="${this.handlers.addNewPackage}"
              ?disabled="${this.metaData.encounter?.signed}"
            ></neb-button-action>
          </div>
        `
      : this.__wrapFieldWithTooltip(
          html`
            <neb-select
              id="${ELEMENTS.selectPackage.id}"
              class="select"
              label="Care Package/Subscription"
              helper="${billType === APPOINTMENT_BILLING_TYPE.CarePackage
                ? 'Required'
                : ''}"
              addNewItemLabel="Package"
              name="billingDetails.patientPackageId"
              .value="${patientPackageId}"
              .error="${this.errors.billingDetails.patientPackageId}"
              .items="${activePackages}"
              .onChange="${this.handlers.change}"
              .onAddNewItem="${this.handlers.addNewPackage}"
              ?disabled="${this.metaData.encounter?.signed}"
            ></neb-select>
          `,
          'patientPackageId',
        );
  }

  __renderCarePackageFields(override) {
    if (!this.state) return '';

    const { patientPackageId, feeScheduleId, billType } =
      this.state.billingDetails;
    const { packages, feeSchedules, settings, encounter } = this.metaData;
    const { multiCarePackage } = settings;

    const packageSummary = patientPackageId.data.usageDetails?.length
      ? patientPackageId.data.usageDetails
      : [];

    return html`
      ${override
        ? html`
            <div class="case-details">
              <div class="padding-bottom-slim">
                <p class="bold">Case Bill Type</p>
                <p>${BILLING_OPTIONS.carePackage}</p>
              </div>
              <div class="padding-bottom-slim">
                <p class="bold">Care Package/Subscription</p>
                <p>${patientPackageId.label}</p>
              </div>
            </div>
          `
        : this.__renderCarePackageBasedOnMulti(
            billType,
            patientPackageId,
            packages,
            multiCarePackage,
          )}
      ${this.__renderCarePackageSummary(
        packageSummary,
        override,
        multiCarePackage,
      )}
      ${this.__wrapFieldWithTooltip(
        html`
          <neb-select
            id="${ELEMENTS.selectFeeSchedule.id}"
            class="${`select${override ? ' padding-top-slim' : ''}`}"
            label="Fee Schedule"
            name="billingDetails.feeScheduleId"
            addNewItemLabel="Fee Schedule"
            customAddText="Associate"
            .value="${feeScheduleId}"
            .items="${[selectors.ITEM_EMPTY, ...feeSchedules]}"
            .onChange="${this.handlers.change}"
            .onAddNewItem="${this.handlers.associateFeeSchedule}"
            ?disabled="${encounter?.signed}"
          ></neb-select>
        `,
        'feeScheduleId',
      )}
    `;
  }

  __getInsuranceItems(key) {
    const insurancesToCheck = INSURANCE_KEYS.filter(k => k !== key);

    const selectedInsurances = insurancesToCheck.map(
      k => this.state.billingDetails[k].data.id,
    );

    return [
      selectors.ITEM_EMPTY,
      ...this.metaData.insurances.filter(
        i => !selectedInsurances.includes(i.data.id) && i.data.active,
      ),
    ];
  }

  __renderCostSharingAndEligibility(
    key,
    { rteEnabled, rteStatus, rteDescription, rteLastChecked },
    { copay, coinsurance, deductible, outOfPocket },
  ) {
    const { secondaryInsuranceId } = this.state.billingDetails;

    return html`
      ${this.__hasAddOnCtVerify && rteEnabled && rteStatus
        ? html`
            <div
              id="${ELEMENTS.eligibilityStatusInfo.id}"
              class="padding-bottom-slim"
            >
              <p class="bold">Eligibility Status</p>
              <p class="flex">
                <neb-icon
                  class="icon-status icon-${rteStatus} leading-icon"
                  .icon="${getRTEStatusIcon(rteStatus)}"
                ></neb-icon
                >${rteDescription}
              </p>
              <p>${rteLastChecked}</p>
            </div>
          `
        : ''}
      ${copay
        ? html`
            <div class="padding-bottom-slim">
              <p class="bold">Co-pay</p>
              <p id="${ELEMENTS.copayInfo.id}">
                ${copay}${secondaryInsuranceId &&
                secondaryInsuranceId.data.id &&
                key === 'primaryInsuranceId'
                  ? ' - Bill to secondary'
                  : ''}
              </p>
            </div>
          `
        : ''}
      ${coinsurance
        ? html`
            <div class="padding-bottom-slim">
              <p class="bold">Coinsurance %</p>
              <p>${coinsurance}</p>
            </div>
          `
        : ''}
      ${deductible
        ? html`
            <div class="padding-bottom-slim">
              <p class="bold">Deductible</p>
              <p>${deductible}</p>
            </div>
          `
        : ''}
      ${outOfPocket
        ? html`
            <div class="padding-bottom-slim">
              <p class="bold">Max Out of Pocket</p>
              <p>${outOfPocket}</p>
            </div>
          `
        : ''}
    `;
  }

  __renderInsuranceInformation(key) {
    const insuranceItem = this.state.billingDetails[key];

    if (!insuranceItem || !insuranceItem.data.id) return '';

    const {
      payerName,
      name,
      coverageDates,
      policyHolder,
      copay,
      coinsurance,
      deductible,
      outOfPocket,
      rteEnabled,
      rteStatus,
      rteDescription,
      rteLastChecked,
    } = insuranceItem.data;

    const costSharingData = {
      copay,
      coinsurance,
      deductible,
      outOfPocket,
    };

    const rteData = {
      rteEnabled,
      rteStatus,
      rteDescription,
      rteLastChecked,
    };

    return html`
      <div class="insurance-detail-top-row padding-bottom-slim">
        <div class="payer-name-text">
          <p class="bold">Payer</p>
          <p>${payerName}</p>
        </div>
        ${this.__hasAddOnCtVerify && rteEnabled
          ? html`
              <neb-button
                id="${ELEMENTS.checkEligibilityButton.id}"
                class="check-eligibility-button"
                label="CHECK ELIGIBILITY"
                .role="${BUTTON_ROLE.CONFIRM}"
                ?disabled="${this.__checkingRTE}"
                .onClick="${this.handlers.checkEligibility}"
              ></neb-button>
            `
          : ''}
      </div>
      <div class="padding-bottom-slim">
        <p class="bold">Plan Name</p>
        <p>${name}</p>
      </div>
      <div class="padding-bottom-slim">
        <p class="bold">Coverage Dates</p>
        <p>${coverageDates}</p>
      </div>
      <div class="padding-bottom-slim">
        <p class="bold">Policyholder</p>
        <p>${policyHolder}</p>
      </div>
      ${this.state.billingDetails.feeScheduleId.data.id &&
      key === 'primaryInsuranceId'
        ? html`
            <div class="padding-bottom-slim">
              <p class="bold">Fee Schedule</p>
              <p>${this.state.billingDetails.feeScheduleId.label}</p>
            </div>
          `
        : ''}
      ${this.__renderCostSharingAndEligibility(key, rteData, costSharingData)}
    `;
  }

  __renderPrimaryInsurance(override) {
    return html`
      ${override
        ? ''
        : this.__wrapFieldWithTooltip(
            html`
              <neb-select
                id="${ELEMENTS.selectPrimaryInsurance.id}"
                class="select"
                label="Plan Name"
                name="billingDetails.primaryInsuranceId"
                helper="${this.state.billingDetails.billType ===
                APPOINTMENT_BILLING_TYPE.Insurance
                  ? 'Required'
                  : ''}"
                addNewItemLabel="Insurance"
                .value="${this.state.billingDetails.primaryInsuranceId}"
                .error="${this.errors.billingDetails.primaryInsuranceId}"
                .items="${this.__getInsuranceItems('primaryInsuranceId')}"
                .onChange="${this.handlers.change}"
                .onAddNewItem="${this.handlers.addPrimaryInsurance}"
                ?disabled="${this.metaData.encounter?.signed}"
              ></neb-select>
            `,
            'primaryInsuranceId',
          )}
      ${this.__renderInsuranceInformation('primaryInsuranceId')}
    `;
  }

  __renderSecondaryInsurance(override) {
    return html`
      ${override
        ? ''
        : this.__wrapFieldWithTooltip(
            html`
              <neb-select
                id="${ELEMENTS.selectSecondaryInsurance.id}"
                class="select"
                label="Plan Name"
                name="billingDetails.secondaryInsuranceId"
                addNewItemLabel="Insurance"
                .value="${this.state.billingDetails.secondaryInsuranceId}"
                .items="${this.__getInsuranceItems('secondaryInsuranceId')}"
                .onChange="${this.handlers.change}"
                .onAddNewItem="${this.handlers.addSecondaryInsurance}"
                ?disabled="${this.metaData.encounter?.signed}"
              ></neb-select>
            `,
            'secondaryInsuranceId',
          )}
      ${this.__renderInsuranceInformation('secondaryInsuranceId')}
    `;
  }

  __renderTertiaryInsurance() {
    return html`
      ${this.__wrapFieldWithTooltip(
        html`
          <neb-select
            id="${ELEMENTS.selectTertiaryInsurance.id}"
            class="select"
            label="Plan Name"
            name="billingDetails.tertiaryInsuranceId"
            addNewItemLabel="Insurance"
            .value="${this.state.billingDetails.tertiaryInsuranceId}"
            .items="${this.__getInsuranceItems('tertiaryInsuranceId')}"
            .onChange="${this.handlers.change}"
            .onAddNewItem="${this.handlers.addTertiaryInsurance}"
            ?disabled="${this.metaData.encounter?.signed}"
          ></neb-select>
        `,
        'tertiaryInsuranceId',
      )}
      ${this.__renderInsuranceInformation('tertiaryInsuranceId')}
    `;
  }

  __genInsuranceTabs(override) {
    return [
      {
        id: INSURANCE_LEVELS.PRIMARY,
        label: INSURANCE_LEVELS.PRIMARY,
        renderer: () => this.__renderPrimaryInsurance(override),
      },
      {
        id: INSURANCE_LEVELS.SECONDARY,
        label: INSURANCE_LEVELS.SECONDARY,
        renderer: () => this.__renderSecondaryInsurance(override),
        disabled: override
          ? !this.state.billingDetails.patientCaseId.data.secondaryInsuranceId
          : !this.state.billingDetails.primaryInsuranceId.data.id,
      },
      ...(override
        ? []
        : [
            {
              id: INSURANCE_LEVELS.TERTIARY,
              label: INSURANCE_LEVELS.TERTIARY,
              renderer: () => this.__renderTertiaryInsurance(),
              disabled: !this.state.billingDetails.secondaryInsuranceId.data.id,
            },
          ]),
    ];
  }

  __renderInsuranceTab() {
    const tab = this.__insuranceTabs.find(
      ({ id }) => id === this.__selectedInsuranceLevel,
    );

    return tab ? tab.renderer() : '';
  }

  __renderInsuranceFields(override) {
    if (!this.state) return '';
    this.__insuranceTabs = this.__genInsuranceTabs(override);
    const { guarantorId } = this.state.billingDetails;

    return html`
      ${override
        ? html`
            <div class="case-details">
              <div class="padding-bottom-slim">
                <p class="bold">Case Bill Type</p>
                <p>${BILLING_OPTIONS.insurance}</p>
              </div>
              <div class="padding-bottom">
                <p class="bold">Guarantor</p>
                <p>${guarantorId.label}</p>
              </div>
            </div>
          `
        : this.__wrapFieldWithTooltip(
            html`
              <neb-select
                id="${ELEMENTS.selectGuarantor.id}"
                class="select"
                label="Guarantor"
                addNewItemLabel="Patient Guarantor"
                name="billingDetails.guarantorId"
                .value="${guarantorId}"
                .items="${[SELF_GUARANTOR_ITEM, ...this.metaData.guarantors]}"
                .onChange="${this.handlers.change}"
                .onAddNewItem="${this.handlers.addNewGuarantor}"
                ?disabled="${this.metaData.encounter?.signed}"
              ></neb-select>
            `,
            'guarantorId',
          )}
      <neb-tab-group
        id="${ELEMENTS.insuranceTabGroup.id}"
        class="padding-bottom"
        .selectedId="${this.__selectedInsuranceLevel}"
        .items="${this.__insuranceTabs}"
        .onSelect="${this.handlers.selectInsuranceLevel}"
      ></neb-tab-group>
      ${this.__renderInsuranceTab()}
    `;
  }

  __renderBillingFields(type, override) {
    switch (type) {
      case APPOINTMENT_BILLING_TYPE.SelfPay:
        return this.__renderSelfPayFields(override);

      case APPOINTMENT_BILLING_TYPE.CarePackage:
        return this.__renderCarePackageFields(override);

      case APPOINTMENT_BILLING_TYPE.Insurance:
        return this.__renderInsuranceFields(override);

      default:
        return '';
    }
  }

  __getAuthorizations(caseItem, auths) {
    if (!caseItem || !caseItem.data.id) return Object.values(auths).flat();

    return auths[caseItem.data.id] || [];
  }

  __renderNoAuthRemainingWarning() {
    const { patientAuthorizationId } = this.state.billingDetails;

    if (
      patientAuthorizationId.data.id &&
      !patientAuthorizationId.data.hasRemaining
    ) {
      return html`
        <neb-icon
          id="${ELEMENTS.iconAuthorizationWarning.id}"
          class="icon-authorization-warning"
          icon="neb:warning"
          @click="${this.handlers.authorizationWarningClick}"
        ></neb-icon>
      `;
    }

    return '';
  }

  __renderNoLinkedAuthTooltip() {
    const { patientCaseId } = this.state.billingDetails;
    const auths = this.metaData.authorizations[patientCaseId.data.id];

    if (patientCaseId.data.id && (!auths || !auths.length)) {
      return html`
        <neb-tooltip id="${ELEMENTS.tooltipAuthorization.id}" class="tooltip">
          <div slot="tooltip">${NO_LINKED_AUTH_TOOLTIP}</div>
        </neb-tooltip>
      `;
    }

    return '';
  }

  __renderMakePaymentSection() {
    return html`
      <div class="payment-buttons-container">
        <neb-button
          id="${ELEMENTS.makePaymentButton.id}"
          class="payment-button"
          label="MAKE PAYMENT"
          .role="${BUTTON_ROLE.CONFIRM}"
          .onClick="${this.handlers.makePayment}"
        ></neb-button>
        ${this.metaData.paymentCount || this.__patientPayments.length
          ? html`
              <neb-button
                id="${ELEMENTS.printReceiptButton.id}"
                class="payment-button"
                label="PRINT RECIEPT"
                .role="${BUTTON_ROLE.CANCEL}"
                .onClick="${this.handlers.printReceipt}"
              ></neb-button>
            `
          : ''}
      </div>
    `;
  }

  __renderColumnRight() {
    const {
      billingDetails: {
        patientBalance,
        billType,
        caseBillTypeOverride,
        patientCaseId,
        patientAuthorizationId,
      },
    } = this.state;
    const { cases, authorizations, encounter } = this.metaData;
    const authItems = this.__getAuthorizations(patientCaseId, authorizations);

    return html`
      <div class="column-right">
        <div class="billing-details-section">
          <neb-button-action
            id="${ELEMENTS.patientBalanceButton.id}"
            class="patient-balance-link"
            label="${`Patient Balance: ${patientBalance}`}"
            trailingIcon="open"
            showUnderline
            .onClick="${this.handlers.goToPatientBalance}"
          ></neb-button-action>
          ${this.__renderMakePaymentSection()}
          <p class="section-header">Billing Details</p>
          <div class="bill-type-radios">
            ${this.__renderRadioButtons(billType, caseBillTypeOverride)}
          </div>
        </div>
        <div class="column-right-fields">
          <neb-select
            id="${ELEMENTS.selectCase.id}"
            class="select"
            label="Case"
            helper="${caseBillTypeOverride ? 'Required' : ''}"
            addNewItemLabel="Case"
            name="billingDetails.patientCaseId"
            .value="${patientCaseId}"
            .error="${this.__getCaseFieldError()}"
            .items="${caseBillTypeOverride
              ? this.__filterActive(cases)
              : [selectors.ITEM_EMPTY, ...this.__filterActive(cases)]}"
            .onChange="${this.handlers.change}"
            .onAddNewItem="${this.handlers.addNewCase}"
          ></neb-select>
          <div class="select-container">
            <neb-select
              id="${ELEMENTS.selectAuthorization.id}"
              class="select"
              label="Authorization"
              name="billingDetails.patientAuthorizationId"
              .value="${patientAuthorizationId}"
              .items="${authItems}"
              .onChange="${this.handlers.change}"
              ?disabled="${encounter?.signed || authItems.length < 1}"
            ></neb-select>
            ${this.__renderNoAuthRemainingWarning()}
            ${this.__renderNoLinkedAuthTooltip()}
          </div>
          ${this.__renderBillingFields(billType, caseBillTypeOverride)}
        </div>
      </div>
    `;
  }

  __renderCreateEncounterCheckbox() {
    return html`
      <neb-checkbox
        id="${ELEMENTS.createEncounterCheckbox.id}"
        name="createEncounter"
        label="${ELEMENTS.createEncounterCheckbox.label}"
        .checked="${this.__createEncounter}"
        .onChange="${this.handlers.changeCreateEncounter}"
        ?disabled="${this.__encounterExists()}"
      ></neb-checkbox>
    `;
  }

  __renderButtons() {
    return html`
      <neb-button
        id="${ELEMENTS.confirmButton.id}"
        class="button"
        .label="${ELEMENTS.confirmButton.label}"
        .role="${BUTTON_ROLE.CONFIRM}"
        .onClick="${this.handlers.save}"
        unelevated
      ></neb-button>
      <neb-button
        id="${ELEMENTS.cancelButton.id}"
        class="button"
        .label="${ELEMENTS.cancelButton.label}"
        .role="${BUTTON_ROLE.CANCEL}"
        .onClick="${this.handlers.cancel}"
      ></neb-button>
    `;
  }

  renderActionBar() {
    return html`
      <div class="action-bar-container">
        <div class="container-item">${this.__renderButtons()}</div>
        <div class="container-item">
          ${this.__renderCreateEncounterCheckbox()}
        </div>
      </div>
    `;
  }

  renderContent() {
    return html`
      <div id="${ELEMENTS.containerForm.id}" class="container-form">
        ${this.__renderColumnLeft()} ${this.__renderColumnRight()}
      </div>
    `;
  }
}

customElements.define('neb-form-check-in', NebFormCheckIn);
