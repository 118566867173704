/* eslint-disable complexity */
import { getValueByPath } from '@neb/form-service/src/utils';
import { inRange, isRequired } from '@neb/form-validators';
import { css, html } from 'lit';

import '../../../../../packages/neb-lit-components/src/components/tables/neb-table-encounter-charges';
import '../../../../../packages/neb-www-practice-charting/src/components/diagnosis/neb-encounter-diagnosis-table';
import '../../../../../packages/neb-lit-components/src/components/neb-dropdown-menu';
import { modifiersSelector } from '../../../../../packages/neb-lit-components/src/components/field-groups/neb-modifiers';
import { BUTTON_ROLE } from '../../../../../packages/neb-lit-components/src/components/neb-button';
import '../../../../../packages/neb-treatment-plan/components/neb-treatment-plan-table';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../../../packages/neb-lit-components/src/utils/overlay-constants';
import { dateToIcd10Year } from '../../../../../packages/neb-utils/icd10-util';
import * as selectors from '../../../../../packages/neb-utils/selectors';
import {
  getChargesForCheckOut,
  getChargeDetailsForCheckOut,
} from '../../../../api-clients/check-out';
import {
  CSS_COLOR_GREY_2,
  CSS_COLOR_GREY_4,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_WHITE,
  CSS_FONT_WEIGHT_BOLD,
  CSS_SPACING,
} from '../../../../styles';
import {
  NebFormCheckIn,
  ELEMENTS as CHECK_IN_ELEMENTS,
} from '../check-in/neb-form-check-in';

import './neb-table-check-out-encounter-charges';

export const NO_CHARGES_APPOINTMENT =
  'There are no charges for this encounter.';

export const NO_ENCOUNTER_APPOINTMENT =
  'There is no encounter associated with this appointment.';

export const NO_DIAGNOSIS_APPOINTMENT =
  'There are no diagnoses for this encounter.';

export const ELEMENTS = {
  ...CHECK_IN_ELEMENTS,
  visitSummaryButton: { id: 'visit-summary-button' },
  requestNextAppointmentButton: { id: 'request-next-appointment-button' },
  printUpcomingAppointmentsButton: { id: 'print-upcoming-appointments-button' },
  discountButton: { id: 'discount-button' },
  addDiagnosisButton: { id: 'add-diagnosis-button' },
  addChargeButton: { id: 'add-charge-button' },
  autoPointButton: { id: 'auto-point-button' },

  printAppointmentsEllipsis: { id: 'print-appointments-ellipsis' },
  printDropdown: { id: 'print-dropdown' },

  encounterBalanceTable: { id: 'encounter-balance-table' },
  treatmentPlanTable: { id: 'treatment-plan-table' },
  diagnosisTable: { id: 'diagnosis-table' },
  encounterChargesTable: { id: 'encounter-charges-table' },

  nextAppointmentText: { id: 'next-appointment-text' },

  saveButton: { id: 'save-button', label: 'Save' },
  saveAndCheckOutButton: {
    id: 'save-and-check-out-button',
    label: 'Save and Check Out',
  },
  cancelButton: { id: 'cancel-button', label: 'Cancel' },
};

export class NebFormCheckOut extends NebFormCheckIn {
  static get properties() {
    return {
      savingError: Boolean,
      metaData: Object,
      defaultInsurances: Object,

      __hasAddOnCtVerify: Boolean,
      __createEncounter: Boolean,
      __checkingRTE: Boolean,
      __selectedInsuranceLevel: String,
      __selectedChargeIds: Array,
      __patientPayments: Array,
      __newAuths: Array,
      __settingsCharges: Array,
    };
  }

  static createModel() {
    return {
      ...NebFormCheckIn.createModel(),
      encounterBalance: {
        totalCharges: '$0.00',
        adjustments: '$0.00',
        patientPayment: '$0.00',
        discounts: '$0.00',
        patientEncounterBalance: '$0.00',
      },
      chargeDetails: [],
      dxDetails: [],
    };
  }

  static createMetaData() {
    return {
      ...NebFormCheckIn.createMetaData(),
      appointmentBillingInfo: {
        caseBillTypeOverride: false,
      },
      treatmentPlan: [],
      patientPackageCharges: {},
      feeScheduleCharges: {},
    };
  }

  createSelectors() {
    const baseSelectors = super.createSelectors();

    return {
      children: {
        ...baseSelectors.children,
        dxDetails: {
          ...selectors.createDefaultModifiers({
            clipPristine: true,
            unsafe: true,
          }),
        },
        chargeDetails: {
          createItem: () => ({
            billedAmount: 0,
            chargeId: '',
            description: '',
            diagnosisPointers: [],
            feeScheduleCharges: {},
            modifiers: [null, null, null, null],
            order: 0,
            posted: false,
            postedToLedgerId: '',
            procedure: '',
            unitCharge: 0,
            units: 0,
            encounterChargeId: '',
          }),
          children: {
            $: {
              ...selectors.createDefaultModifiers({
                clipPristine: true,
                ignorePristine: true,
                unsafe: true,
              }),
              children: {
                diagnosisPointers: {
                  ...selectors.createDefaultModifiers({
                    clipPristine: true,
                    ignorePristine: true,
                    unsafe: true,
                  }),
                  format: dxs => {
                    const selectedItems = dxs.map(dx =>
                      this.model.dxDetails.find(
                        item => item.code === dx.diagnosisCode,
                      ),
                    );
                    const itemsAreLoaded = selectedItems.every(
                      item => item !== undefined,
                    );

                    return itemsAreLoaded ? selectedItems : [];
                  },
                  unformat: dxs => dxs.map(dx => dx.id),
                  validators: [],
                },
                modifiers: {
                  children: {
                    $: {
                      ...selectors.createDefaultModifiers({
                        clipPristine: true,
                        ignorePristine: true,
                        unsafe: true,
                      }),
                      ...modifiersSelector,
                    },
                  },
                },
                units: {
                  validators: [isRequired('1 - 999'), inRange(1, 999)],
                },
                feeScheduleCharges: {
                  ...selectors.createDefaultModifiers({
                    clipPristine: true,
                    ignorePristine: true,
                    unsafe: true,
                  }),
                },
              },
            },
          },
        },
      },
    };
  }

  initState() {
    super.initState();

    this.__selectedChargeIds = [];
    this.__settingsCharges = null;

    this.metaData = NebFormCheckOut.createMetaData();
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      change: e => {
        const keyPath = e.name.split('.');
        const oldValue = getValueByPath(this.state, keyPath);

        if (keyPath[0] === 'chargeDetails') {
          this.__handleChargeDetailsChange(keyPath, e.value);
          return;
        }

        if (oldValue !== e.value || e.name.includes('billType')) {
          this.formService.apply(e.name, e.value);

          if (keyPath[0] === 'appointmentDetails') {
            this.__handleAppointmentDetailsChange(keyPath);
          } else if (keyPath[0] === 'billingDetails') {
            this.__handleBillingDetailsChange(keyPath);
          }
        }
      },
      addDiagnosis: async () => {
        const selectedDiagnoses = await openOverlay(
          OVERLAY_KEYS.ADD_DIAGNOSIS,
          {
            showICD10Dropdown: true,
            selectedDiagnosis: this.state.dxDetails.map(
              ({ code, shortDescription }) => ({
                diagnosisCode: code,
                shortDescription,
              }),
            ),
            appointmentYear: dateToIcd10Year(this.metaData.appointment.start),
          },
        );

        const preSelectedDiagnoses = this.state.dxDetails.map(dx => dx.code);

        const diagnoses = selectedDiagnoses
          .map(({ diagnosisCode, shortDescription, showICD10Warning }) => ({
            code: diagnosisCode,
            shortDescription,
            label: `${diagnosisCode} - ${shortDescription}`,
            showICD10Warning,
          }))
          .filter(dx => !preSelectedDiagnoses.includes(dx.code));

        this.formService.apply('dxDetails', [
          ...this.state.dxDetails,
          ...diagnoses,
        ]);
      },
      autoPointDiagnoses: () => {
        if (!this.state.dxDetails.length) {
          return;
        }

        const dxs = this.__get4DiagnosisPointers();

        this.state.chargeDetails.forEach((item, index) => {
          if (!item.diagnosisPointers.length) {
            this.state.chargeDetails[index].diagnosisPointers = [...dxs];
            this.formService.validateKey([
              'chargeDetails',
              index,
              'diagnosisPointers',
            ]);
          }
        });
      },
      reorderDiagnoses: dxs => {
        this.formService.apply('dxDetails', dxs);
      },
      removeDiagnosis: dx => {
        this.state.chargeDetails.forEach((charge, index) => {
          const dxIndex = charge.diagnosisPointers.findIndex(
            dxp => dxp.code === dx.code,
          );

          if (dxIndex !== -1) {
            this.formService.removeItem(
              `chargeDetails.${index}.diagnosisPointers`,
              dxIndex,
            );
          }
        });

        const index = this.state.dxDetails.findIndex(
          dxd => dxd.code === dx.code,
        );
        this.formService.removeItem('dxDetails', index);
      },
      removeAllDiagnoses: () => {
        this.state.chargeDetails.forEach((_, index) =>
          this.formService.apply(
            `chargeDetails.${index}.diagnosisPointers`,
            [],
          ),
        );

        this.formService.apply('dxDetails', []);
      },
      removeCharge: (_, item, index) => {
        this.formService.removeItem('chargeDetails', index);
        this.__selectedChargeIds = this.__selectedChargeIds.filter(
          chargeId => chargeId !== item.chargeId,
        );
      },
      addCharge: async () => {
        const feeScheduleId = this.state.billingDetails.feeScheduleId.data.id;

        if (this.__settingsCharges === null) {
          const charges = await getChargesForCheckOut({
            ...(feeScheduleId && { feeScheduleId }),
          });

          this.__settingsCharges = charges || {};
        }

        if (Object.keys(this.__settingsCharges).length) {
          const missingFeeSchedule = Object.values(this.__settingsCharges).some(
            charge => charge.feeScheduleCharges[feeScheduleId] === undefined,
          );

          if (missingFeeSchedule) {
            const feeScheduleCharges = await getChargeDetailsForCheckOut({
              feeScheduleId,
            });

            Object.keys(this.__settingsCharges).forEach(chargeId => {
              const settingsCharge = this.__settingsCharges[chargeId];

              settingsCharge.feeScheduleCharges[feeScheduleId] =
                feeScheduleCharges[chargeId] || null;
            });
          }
        }

        const selectedCharges = this.__selectedChargeIds.map(
          chargeId => this.__settingsCharges[chargeId],
        );

        const overlayCharges = await openOverlay(
          OVERLAY_KEYS.LEDGER_ADD_CHARGE,
          {
            config: this.__getAddChargeTableConfig(),
            items: Object.values(this.__settingsCharges).sort((a, b) =>
              a.procedure.localeCompare(b.procedure),
            ),
            selectedCharges,
            isDirty: this.__dirty,
          },
        );

        this.state.chargeDetails.forEach(charge => {
          if (charge.feeScheduleCharges[feeScheduleId] === undefined) {
            charge.feeScheduleCharges[feeScheduleId] =
              this.__settingsCharges[charge.chargeId].feeScheduleCharges[
                feeScheduleId
              ];
          }
        });

        const newCharges = overlayCharges?.filter(
          charge => !this.__selectedChargeIds.includes(charge.id),
        );

        if (newCharges?.length) {
          const originalChargeLength = this.__selectedChargeIds.length;

          for (let i = 0; i < newCharges.length; i++) {
            this.__mapAndApplyNewChargeToState(
              structuredClone(newCharges[i]),
              feeScheduleId,
              i,
              originalChargeLength,
            );
          }
        }
      },
      reorderCharges: (fromIndex, toIndex) => {
        this.formService.moveItem('chargeDetails', fromIndex, toIndex);
      },
    };
  }

  __mapAndApplyNewChargeToState(
    charge,
    feeScheduleId,
    index,
    originalChargeLength,
  ) {
    const feeScheduleChargeMeta =
      feeScheduleId && charge.feeScheduleCharges[feeScheduleId];
    const { autoDiagnosisPointing } = this.metaData.settings;

    const newStateCharge = {
      billedAmount: feeScheduleChargeMeta?.feeScheduleCharge || charge.amount,
      chargeId: charge.id,
      description: charge.description,
      diagnosisPointers: autoDiagnosisPointing
        ? [...this.__get4DiagnosisPointers()]
        : [],
      feeScheduleCharges: structuredClone(charge.feeScheduleCharges),
      modifiers: [...(feeScheduleChargeMeta?.modifiers || charge.modifiers)],
      order: originalChargeLength + index,
      posted: !!this.metaData.settings.autoPostCharges,
      postedToLedgerId: '',
      procedure: charge.procedure,
      unitCharge: charge.amount,
      units: charge.units,
      encounterChargeId: '',
    };

    this.formService.addItem('chargeDetails', originalChargeLength + index);
    this.formService.apply(
      `chargeDetails.${originalChargeLength + index}`,
      newStateCharge,
    );

    this.__selectedChargeIds.push(charge.id);
  }

  __handleChargeDetailsChange(keyPath, value) {
    const oldValue = getValueByPath(this.state, keyPath);

    if (oldValue !== value) {
      const charge = this.state.chargeDetails[keyPath[1]];

      if (keyPath[2] === 'units') {
        charge.units = value;
      }

      if (keyPath[2] === 'modifiers') {
        charge.modifiers[keyPath[3]] = value;
      }

      if (keyPath[2] === 'diagnosisPointers') {
        charge.diagnosisPointers = [...value];
      }

      this.formService.validateKey(keyPath);
    }
  }

  async __feeScheduleUpdated() {
    let newFeeScheduleCharges = null;

    const currentFeeScheduleId =
      this.state.billingDetails.feeScheduleId.data.id;

    if (currentFeeScheduleId) {
      const chargeIdsToUpdate = this.state.chargeDetails
        .filter(
          charge =>
            charge.feeScheduleCharges[currentFeeScheduleId] === undefined,
        )
        .map(charge => charge.chargeId);

      if (chargeIdsToUpdate.length) {
        newFeeScheduleCharges = await getChargeDetailsForCheckOut({
          chargeIds: chargeIdsToUpdate,
          feeScheduleId: currentFeeScheduleId,
        });
      }

      this.state.chargeDetails.forEach((charge, index) => {
        if (
          chargeIdsToUpdate.includes(charge.chargeId) &&
          Object.keys(newFeeScheduleCharges).length
        ) {
          this.state.chargeDetails[index].feeScheduleCharges[
            currentFeeScheduleId
          ] = newFeeScheduleCharges[charge.chargeId];
        }

        const feeScheduleCharge =
          this.state.chargeDetails[index].feeScheduleCharges[
            currentFeeScheduleId
          ];

        if (feeScheduleCharge) {
          this.state.chargeDetails[index].modifiers = [
            ...feeScheduleCharge.modifiers,
          ];
        }
      });

      this.requestUpdate();
    }
  }

  __didBillingInfoChangeInState() {
    const normalizedBillingInfo = {
      ...this.metaData.appointmentBillingInfo,
      caseBillTypeOverride:
        this.metaData.appointmentBillingInfo.caseBillTypeOverride === 1,
    };

    const fieldsToCompare = [
      'billType',
      'billTypeOverride',
      'caseBillTypeOverride',
      'feeScheduleId',
      'guarantorId',
      'patientAuthorizationId',
      'patientCaseId',
      'primaryInsuranceId',
      'secondaryInsuranceId',
      'tertiaryInsuranceId',
    ];

    return fieldsToCompare.some(field => {
      const stateValue =
        this.state.billingDetails[field]?.data?.id ||
        this.state.billingDetails[field];
      const metaValue =
        normalizedBillingInfo[field]?.id || normalizedBillingInfo[field];
      return stateValue !== metaValue;
    });
  }

  __findAndApplyNewFeeSchedule() {
    const feeSchedule = this.__findNewFeeSchedule();

    this.formService.apply('billingDetails.feeScheduleId', feeSchedule);
    this.__feeScheduleUpdated();
  }

  __handleBillingDetailsChange(keyPath) {
    switch (keyPath[1]) {
      case 'billType':
        this.__billTypeUpdated();
        break;

      case 'caseBillTypeOverride':
        this.__caseBillTypeOverrideUpdated();
        break;

      case 'patientCaseId':
        this.__patientCaseUpdated();
        break;

      case 'patientAuthorizationId':
        this.__patientAuthUpdated();
        break;

      case 'primaryInsuranceId':
        this.__insuranceUpdated(keyPath[1]);
        this.__findAndApplyNewFeeSchedule();
        break;

      case 'secondaryInsuranceId':
      case 'tertiaryInsuranceId':
        this.__insuranceUpdated(keyPath[1]);
        break;

      case 'feeScheduleId':
        this.__feeScheduleUpdated();
        break;

      default:
        break;
    }
  }

  __getAddChargeTableConfig() {
    const feeScheduleId = this.state.billingDetails.feeScheduleId.data.id;

    return {
      tableConfig: [
        {
          key: 'procedure',
          label: 'Procedure',
          flex: css`1 0 80px`,
        },
        {
          key: 'description',
          label: 'Description',
          flex: css`1 0 100px`,
          style: css`
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
          `,
        },
        {
          key: 'modifiers',
          label: 'Modifiers',
          flex: css`1 0 80px`,
          formatter: (value, item) => {
            const feeScheduleModifiers =
              item.feeScheduleCharges[feeScheduleId]?.modifiers;

            if (feeScheduleModifiers) {
              return feeScheduleModifiers.filter(m => m).join(',');
            }

            return value ? value.filter(m => m).join(',') : '';
          },
        },
        {
          key: 'units',
          label: 'Units',
          flex: css`0 0 80px`,
        },
        {
          key: 'feeScheduleName',
          label: 'Fee Schedule',
          flex: css`1 0 120px`,
          style: css`
            flex-direction: column;
          `,
          formatter: (_, item) => {
            const feeScheduleValues = item.feeScheduleCharges[feeScheduleId];

            const model = feeScheduleValues
              ? {
                  ...feeScheduleValues,
                  units: item.units,
                }
              : {};

            return html`
              <neb-cell-fee-schedule .model="${model}"></neb-cell-fee-schedule>
            `;
          },
        },
      ],
      mobileTableConfig: [
        {
          mobile: true,
          key: 'procedure',
          label: 'Procedure',
          flex: css`0 0 120px`,
        },
        {
          mobile: true,
          key: 'description',
          label: 'Description',
          flex: css`1 0 0`,
        },
        {
          mobile: true,
          key: 'modifiers',
          label: 'Mods',
          flex: css`0 0 40px`,
          style: 'white-space: pre-line',
          formatter: value => (value ? value.filter(m => m).join('\n') : ''),
        },
      ],
      itemName: 'charge',
      itemPluralName: 'charges',
      title: 'Add Charge - Encounter',
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          display: flex;
          overflow: auto;
          min-height: 0;
          flex-flow: column nowrap;
          flex: 1 0 0;
          scrollbar-gutter: stable;
        }

        .container-form {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 40px;
          padding: 0 5px 0 ${CSS_SPACING};
          background-color: ${CSS_COLOR_WHITE};
        }

        .container-bottom {
          display: grid;
          grid-template-rows: auto 1fr;
          grid-gap: ${CSS_SPACING};
          background-color: ${CSS_COLOR_WHITE};
        }

        .encounter-balance-table {
          width: 100%;
        }

        .normal {
          font-weight: normal;
        }

        .two-line-section {
          display: grid;
          padding: ${CSS_SPACING} 0;
          gap: ${CSS_SPACING};
        }

        .padding-bottom-small {
          padding-bottom: 5px;
        }

        .encounter-balance-section {
          background-color: ${CSS_COLOR_GREY_4};
          border: 1px solid ${CSS_COLOR_GREY_2};
          border-radius: 10px;
          padding: ${CSS_SPACING};
          margin-bottom: ${CSS_SPACING};
        }

        table tr:last-child td {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          padding-top: 10px;
        }

        tr td:nth-child(2) {
          text-align: right;
        }

        .print-appointments-ellipsis {
          margin-left: -25px;
        }

        .table-add {
          padding: 10px 0 ${CSS_SPACING} ${CSS_SPACING};
        }

        .charges-table {
          padding-top: 0px;
        }

        .padding-left {
          padding-left: ${CSS_SPACING};
        }

        .payment-buttons-container {
          display: grid;
          grid-template-columns: 1fr 1fr;
        }

        .payment-buttons-inner {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          grid-gap: ${CSS_SPACING};
          align-items: center;
        }

        .action-bar-container {
          display: flex;
          flex-wrap: wrap;
          align-content: stretch;
          padding: ${CSS_SPACING};
          border-top: 1px solid ${CSS_COLOR_HIGHLIGHT};
          height: unset;
          gap: 15px;
        }
      `,
    ];
  }

  updated(changedProps) {
    if (changedProps.has('model')) {
      if (this.state.appointmentDetails?.providerId?.data?.id) {
        const { createEncounterAtCheckIn } =
          this.state.appointmentDetails.providerId.data;

        this.__createEncounter =
          !this.__encounterExists() && createEncounterAtCheckIn;
      }

      if (this.state.chargeDetails.length) {
        this.__selectedChargeIds = this.state.chargeDetails.map(
          item => item.chargeId,
        );
      }
    }

    if (changedProps.has('savingError') && this.savingError) {
      this.__saving = false;
    }
  }

  __get4DiagnosisPointers() {
    return this.state.dxDetails.slice(
      0,
      Math.min(this.state.dxDetails.length, 4),
    );
  }

  __renderEncounterBalanceTable() {
    const {
      encounterBalance: {
        totalCharges,
        adjustments,
        patientPayment,
        discounts,
        patientEncounterBalance,
        payerOwed,
        patientOwed,
        coveredCharges,
      },
    } = this.state;
    return html`
      <div class="encounter-balance-section padding-bottom">
        <p class="appointment-details bold padding-bottom-small">
          Patient Encounter Balance
        </p>
        <table
          id="${ELEMENTS.encounterBalanceTable.id}"
          class="encounter-balance-table"
        >
          <tr>
            <td>Total Charges</td>
            <td>${totalCharges}</td>
          </tr>
          <tr>
            <td>Adjustments</td>
            <td>${adjustments}</td>
          </tr>
          ${payerOwed
            ? html` <tr>
                <td>Payer Owed</td>
                <td>${payerOwed}</td>
              </tr>`
            : ''}
          ${patientOwed
            ? html` <tr>
                <td>Patient Owed</td>
                <td>${patientOwed}</td>
              </tr>`
            : ''}
          ${coveredCharges
            ? html` <tr>
                <td>Covered by Care Package</td>
                <td>${coveredCharges}</td>
              </tr>`
            : ''}
          <tr>
            <td>Patient Payments</td>
            <td>${patientPayment}</td>
          </tr>
          <tr>
            <td>Discounts</td>
            <td>${discounts}</td>
          </tr>
          <tr>
            <td>Patient Encounter Balance</td>
            <td>${patientEncounterBalance}</td>
          </tr>
        </table>
        <div></div>
      </div>
    `;
  }

  __renderColumnLeft() {
    const {
      appointmentDetails: {
        appointmentTypeId,
        patientName,
        date,
        time,
        locationName,
        providerId,
        calendarResourceName,
        nextAppointment,
      },
    } = this.state;
    return html`
      <div class="column-left">
        <div class="appointment-details">
          <p class="section-header padding-bottom">Appointment Details</p>
          <p class="padding-bottom">${patientName}</p>
          <p>${date}</p>
          <p>${time}</p>
          <div class="two-line-section">
            <div>
              <p>${locationName}</p>
              <p class="normal">${calendarResourceName}</p>
            </div>
            <div>
              <p>${appointmentTypeId.label}</p>
              <p class="normal">${providerId.label}</p>
            </div>
          </div>
        </div>
        ${this.__renderEncounterBalanceTable()}
        <neb-button-action
          id="${ELEMENTS.visitSummaryButton.id}"
          class="button padding-bottom"
          label="Open Visit Summary"
          leadingIcon="noteAdd"
        ></neb-button-action>
        <p class="section-header padding-bottom-slim">
          Request Next Appointment
        </p>
        <p id="${ELEMENTS.nextAppointmentText.id}" class="padding-bottom-slim">
          ${nextAppointment}
        </p>
        <div>
          <neb-button-action
            id="${ELEMENTS.requestNextAppointmentButton.id}"
            class="button"
            label="Add New Appointment"
            leadingIcon="plus"
          ></neb-button-action>
          <neb-button-action
            id="${ELEMENTS.printUpcomingAppointmentsButton.id}"
            class="button"
            label="Print Upcoming Appointments"
            leadingIcon="print"
          ></neb-button-action>
          <neb-button-actions
            id="${ELEMENTS.printAppointmentsEllipsis.id}"
            class="print-appointments-ellipsis"
            vertical
            maxVisibleItems="10"
            iconHeight="20px"
            iconWidth="20px"
          ></neb-button-actions>
        </div>
        ${this.metaData.treatmentPlan?.length > 0
          ? html`
              <p class="section-header padding-bottom-slim padding-top-slim">
                Treatment Plan (${this.metaData.treatmentPlan.length})
              </p>
              <neb-treatment-plan-table
                id="${ELEMENTS.treatmentPlanTable.id}"
                class="treatment-plan"
                .items="${this.metaData.treatmentPlan || []}"
                summary
                sticky-total
              ></neb-treatment-plan-table>
            `
          : ''}
      </div>
    `;
  }

  __renderMakePaymentSection() {
    return html`
      <div class="payment-buttons-container">
        <div class="payment-buttons-inner">
          <neb-button
            id="${ELEMENTS.makePaymentButton.id}"
            class="payment-button"
            label="MAKE PAYMENT"
            .role="${BUTTON_ROLE.CONFIRM}"
            .onClick="${this.handlers.makePayment}"
          ></neb-button>
          <neb-button-action
            id="${ELEMENTS.discountButton.id}"
            class="bold"
            label="Discount"
            leadingIcon="discount"
          ></neb-button-action>
          <neb-dropdown-menu
            id="${ELEMENTS.printDropdown.id}"
            class="normal"
            label="Print"
            leadingIcon="print"
            align="${this.layout !== 'large' ? 'left' : 'right'}"
          ></neb-dropdown-menu>
        </div>
        <div></div>
      </div>
    `;
  }

  __renderEmptyDxMessage() {
    return !this.model.appointmentDetails.encounterId
      ? NO_ENCOUNTER_APPOINTMENT
      : '';
  }

  __renderEmptyChargeMessage() {
    if (!this.model.appointmentDetails.encounterId) {
      return NO_ENCOUNTER_APPOINTMENT;
    }

    if (
      this.model.appointmentDetails.encounterId &&
      this.state.chargeDetails.length === 0
    ) {
      return NO_CHARGES_APPOINTMENT;
    }
    return '';
  }

  renderActionBar() {
    return html`
      <div class="action-bar-container">
        <div class="container-item">
          <neb-button
            id="${ELEMENTS.saveButton.id}"
            class="button"
            .label="${ELEMENTS.saveButton.label}"
            .role="${BUTTON_ROLE.CONFIRM}"
            unelevated
          ></neb-button>
          <neb-button
            id="${ELEMENTS.saveAndCheckOutButton.id}"
            class="button"
            .label="${ELEMENTS.saveAndCheckOutButton.label}"
            .role="${BUTTON_ROLE.CONFIRM}"
            .onClick="${this.handlers.save}"
            unelevated
          ></neb-button>
          <neb-button
            id="${ELEMENTS.cancelButton.id}"
            class="button"
            .label="${ELEMENTS.cancelButton.label}"
            .role="${BUTTON_ROLE.CANCEL}"
            .onClick="${this.handlers.cancel}"
          ></neb-button>
        </div>
      </div>
    `;
  }

  renderContent() {
    return html`
      <div id="${ELEMENTS.containerForm.id}" class="container-form">
        ${this.__renderColumnLeft()} ${this.__renderColumnRight()}
      </div>
      <div class="container-bottom">
        <div>
          <div class="padding-bottom border-bottom">
            <p class="section-header padding-left">Diagnoses</p>
          </div>
          <neb-button-action
            id="${ELEMENTS.addDiagnosisButton.id}"
            class="table-add button"
            label="Add Diagnosis"
            leadingIcon="plus"
            .onClick="${this.handlers.addDiagnosis}"
          ></neb-button-action>
          <neb-encounter-diagnosis-table
            id="${ELEMENTS.diagnosisTable.id}"
            name="dxDetails"
            .savedCodes="${this.state.dxDetails}"
            .onReorderDiagnoses="${this.handlers.reorderDiagnoses}"
            .onRemoveDiagnosis="${this.handlers.removeDiagnosis}"
            .onRemoveAllDiagnoses="${this.handlers.removeAllDiagnoses}"
            .emptyMessage="${this.__renderEmptyDxMessage()}"
          ></neb-encounter-diagnosis-table>
        </div>
        <div>
          <div class="padding-bottom border-bottom">
            <p class="section-header padding-left">Encounter Charges</p>
          </div>
          <div>
            <neb-button-action
              id="${ELEMENTS.addChargeButton.id}"
              class="table-add button"
              label="Add New Charge"
              leadingIcon="plus"
              .onClick="${this.handlers.addCharge}"
            ></neb-button-action>
            <neb-button-action
              id="${ELEMENTS.autoPointButton.id}"
              class="table-add button"
              label="Auto Point Diagnoses"
              leadingIcon="autoPoint"
              .onClick="${this.handlers.autoPointDiagnoses}"
            ></neb-button-action>
            <neb-table-check-out-encounter-charges
              id="${ELEMENTS.encounterChargesTable.id}"
              class="charges-table"
              name="chargeDetails"
              .layout="${this.layout}"
              .model="${this.state.chargeDetails}"
              .errors="${this.errors.chargeDetails}"
              .diagnoses="${this.state.dxDetails}"
              .reorder="${this.layout !== 'small'}"
              .onChange="${this.handlers.change}"
              .onRemove="${this.handlers.removeCharge}"
              .onReorder="${this.handlers.reorderCharges}"
              .didBillingInfoChange="${this.__didBillingInfoChangeInState()}"
              .initialUnits="${this.model.chargeDetails.map(
                charge => charge.units,
              )}"
              .feeScheduleId="${this.state.billingDetails.feeScheduleId.data
                .id}"
              writable="${true}"
              showRemoveButton="${true}"
            ></neb-table-check-out-encounter-charges>
          </div>
        </div>
      </div>
    `;
  }
}

customElements.define('neb-form-check-out', NebFormCheckOut);
