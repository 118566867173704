import {
  objToName,
  HIDE_PREFERRED_OPTS,
} from '../../../packages/neb-utils/formatters';

const NO_ASSOCIATED_PROVIDER_ID = 'null';

function filterNoAssociatedProviderId(ids) {
  return ids.filter(item => item !== NO_ASSOCIATED_PROVIDER_ID);
}

function remapPerson(person) {
  return {
    first: person.firstName,
    last: person.lastName,
    middle: person.middleName,
    suffix: person.suffix,
  };
}

function guarantorNameOrg(guarantor) {
  return guarantor.personId
    ? objToName(remapPerson(guarantor.person), HIDE_PREFERRED_OPTS)
    : guarantor.organization.name;
}

const getBusinessName = ({ location, pdfBody, locationSelected }) => {
  const shouldDisplayBusinessName =
    locationSelected && location && location.businessName;

  return shouldDisplayBusinessName
    ? location.businessName
    : pdfBody.practiceInformation.name;
};

function formatAddress(address) {
  return {
    address1: address.address1,
    address2: address.address2,
    city: address.city,
    state: address.state,
    zipcode: address.zipCode || address.zipcode,
  };
}

const buildBillingInfo = ({
  pdfBody,
  billingInfoLocation,
  payToAddressLocation,
  billingLocationSelected,
  payToLocationSelected,
}) => {
  const payToAddress = formatAddress(payToAddressLocation);
  const billingAddress = formatAddress(billingInfoLocation);

  payToAddress.businessName = getBusinessName({
    location: payToAddressLocation,
    pdfBody,
    locationSelected: payToLocationSelected,
  });

  billingAddress.businessName = getBusinessName({
    location: billingInfoLocation,
    pdfBody,
    locationSelected: billingLocationSelected,
  });

  return { payToAddress, billingAddress };
};

const getAddressLocation = ({ pdfBody, addressId, addressKey }) => {
  if (addressId) {
    const addressLocation = pdfBody.practiceInformation.locations.find(
      l => l.id === addressId,
    );

    if (addressLocation) return addressLocation;
  }

  const addressLocationId = pdfBody.billingInformation[addressKey].locationId;

  if (addressLocationId) {
    return pdfBody.practiceInformation.locations.find(
      l => l.id === addressLocationId,
    );
  }

  return (
    pdfBody.billingInformation[addressKey] ||
    pdfBody.practiceInformation.locations[0]
  );
};

const setBillingAndPayToAddresses = (
  pdfBody,
  billingAddressId,
  payToAddressId,
) => {
  const billingInfoLocation = getAddressLocation({
    pdfBody,
    addressId: billingAddressId,
    addressKey: 'billingAddress',
  });

  const payToAddressLocation = getAddressLocation({
    pdfBody,
    addressId: payToAddressId,
    addressKey: 'payToAddress',
  });

  return { billingInfoLocation, payToAddressLocation };
};

function buildStatementBodyWithLocations({
  body,
  billingAddressId,
  payToAddressId,
}) {
  const pdfBody = { ...body };
  const billingLocationSelected = !!billingAddressId;
  const payToLocationSelected = !!payToAddressId;

  const { billingInfoLocation, payToAddressLocation } =
    setBillingAndPayToAddresses(pdfBody, billingAddressId, payToAddressId);

  pdfBody.practiceInformation.phoneNumber =
    billingInfoLocation.phoneNumber ||
    pdfBody.practiceInformation.locations[0].phoneNumber;

  pdfBody.practiceInformation.email =
    billingInfoLocation.emailAddress ||
    pdfBody.practiceInformation.locations[0].emailAddress;

  const { payToAddress, billingAddress } = buildBillingInfo({
    pdfBody,
    billingInfoLocation,
    payToAddressLocation,
    billingLocationSelected,
    payToLocationSelected,
  });

  return {
    ...pdfBody,
    billingInformation: {
      ...pdfBody.billingInformation,
      payToAddress,
      billingAddress,
      hideLogo: billingInfoLocation.hideLogo,
    },
  };
}

export {
  NO_ASSOCIATED_PROVIDER_ID,
  guarantorNameOrg,
  filterNoAssociatedProviderId,
  formatAddress,
  buildStatementBodyWithLocations,
};
