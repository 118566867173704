export class AutoFetcher {
  constructor(host, fetchCallback) {
    this.host = host;
    this.fetchCallback = fetchCallback;
    this.lastFetchTime = 0;
    this.MIN_FETCH_INTERVAL = 2000;

    this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
    this.handleFocus = this.handleFocus.bind(this);

    host.addController(this);
  }

  hostConnected() {
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
    window.addEventListener('focus', this.handleFocus);
  }

  hostDisconnected() {
    document.removeEventListener(
      'visibilitychange',
      this.handleVisibilityChange,
    );

    window.removeEventListener('focus', this.handleFocus);

    if (this._timer) {
      clearInterval(this._timer);
      this._timer = undefined;
    }
  }

  async tryFetch() {
    const now = Date.now();

    if (now - this.lastFetchTime >= this.MIN_FETCH_INTERVAL) {
      this.lastFetchTime = now;

      try {
        await this.fetchCallback();
      } catch (e) {
        console.error('AutoFetcher error:', e);

        if (e.statusCode === 401) {
          this.userActive = false;
        }
      }
    }
  }

  handleVisibilityChange() {
    if (!document.hidden) {
      this.tryFetch();
    }
  }

  handleFocus() {
    this.tryFetch();
  }
}
