import DeprecatedApiClient, {
  Method,
  RESPONSE_TYPE,
} from '../../packages/neb-api-client/src/utils/api-client-utils';

const formatToClient = model => ({
  ...model,
  charges: model.charges.map(c => ({
    chargeId: c.chargeId,
    diagnosisPointers: c.diagnosisPointers,
    procedure: c.charge.procedure,
    description: c.charge.description,
    modifiers: c.charge.modifiersCalculated.split(','),
    active: true,
    amount: c.charge.amount,
    units: c.charge.units,
  })),
});

export const billingApiClient = new DeprecatedApiClient({
  microservice: 'billing',
});

export const createCodeBundle = (
  providerId,
  { name, diagnoses, charges, description, active },
) =>
  billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.POST,
    path: `providers/${providerId}/code-bundles`,
    headers: { 'Content-Type': 'application/json' },
    version: 1,
    body: JSON.stringify({ name, diagnoses, charges, description, active }),
    responseType: RESPONSE_TYPE.RAW,
  });

export const getCodeBundles = (providerId, queryParams) =>
  billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: `providers/${providerId}/code-bundles`,
    queryParams,
    version: 1,
  });

export const addCodeBundleToEncounter = ({ encounterId, codeBundleId }) =>
  billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.POST,
    path: `encounters/${encounterId}/code-bundles/${codeBundleId}`,
    version: 1,
  });

export const getCodeBundle = async id => {
  const res = await billingApiClient.makeRequest({
    method: Method.GET,
    path: `code-bundles/${id}`,
    version: 1,
  });

  return formatToClient(res.data[0]);
};

export const updateCodeBundle = ({ id, ...codeBundle }) =>
  billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.PUT,
    path: `code-bundles/${id}`,
    headers: { 'Content-Type': 'application/json' },
    version: 1,
    body: JSON.stringify(codeBundle),
    responseType: RESPONSE_TYPE.RAW,
  });

export const copyCodeBundles = ({ fromProviderId, toProviderId }) =>
  billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.POST,
    path: 'code-bundles-copy',
    headers: { 'Content-Type': 'application/json' },
    version: 1,
    body: JSON.stringify({ fromProviderId, toProviderId }),
    responseType: RESPONSE_TYPE.RAW,
  });
