import {
  Method,
  RESPONSE_TYPE,
} from '../../packages/neb-api-client/src/utils/api-client-utils';
import ApiClientV2 from '../../packages/neb-api-client/src/utils/api-client-v2';
import { PREALLOCATE_SCREEN_PAGE_SIZE } from '../utils/preallocate-payment-util';

export const apiClient = new ApiClientV2({ microservice: 'billing' });

export const getPreallocateLineItems = (
  { patientId, pageNumber = 1 },
  optOutLoadingIndicator = false,
) =>
  apiClient.makeRequest({
    path: '/api/v1/tenants/:tenantId/patients/:patientId/preallocate-payment/line-items?limit=:limit&offset=:offset',
    method: Method.GET,
    headers: {
      'Content-Type': 'application/json',
    },
    optOutLoadingIndicator,
    replacements: {
      patientId,
      limit: PREALLOCATE_SCREEN_PAGE_SIZE,
      offset: (pageNumber - 1) * PREALLOCATE_SCREEN_PAGE_SIZE,
    },
  });

export const allocateCreditsToPreallocationLineItems = (
  { patientId, lineItems, paymentId, creditsAmount },
  optOutLoadingIndicator = false,
) =>
  apiClient.makeRequest({
    path: '/api/v1/tenants/:tenantId/patients/:patientId/preallocate-payment/line-items',
    method: Method.PUT,
    headers: {
      'Content-Type': 'application/json',
    },
    optOutLoadingIndicator,
    responseType: RESPONSE_TYPE.RAW,
    replacements: {
      patientId,
    },
    body: {
      paymentId,
      unallocatedCreditsAmount: creditsAmount,
      lineItems,
    },
  });
