import '../../misc/neb-icon';
import { html, css, LitElement } from 'lit';

import { CSS_SPACING_ROW } from '../../../../packages/neb-styles/neb-variables';
import { trigger } from '../../../library/userpilot';
import '../../../../packages/neb-lit-components/src/components/controls/neb-button-action';

export const ELEMENTS = {
  button: {
    id: 'button',
  },
};

class NebButtonGiveFeedback extends LitElement {
  static get properties() {
    return {
      surveyCode: String,
    };
  }

  static get styles() {
    return css`
      .button {
        cursor: pointer;
        height: 36px;
        padding: 0 ${CSS_SPACING_ROW};
        display: flex;
        justify-content: center;
        border-radius: 40px;
        background-color: #ddedf7;
        color: #3b3866;
      }
    `;
  }

  __sendFeedback() {
    trigger(this.surveyCode);
  }

  render() {
    return html`
      <neb-button-action
        id="${ELEMENTS.button.id}"
        leadingIcon="campaign"
        label="Give Feedback"
        class="button"
        .onClick="${() => this.__sendFeedback()}"
      ></neb-button-action>
    `;
  }
}

customElements.define('neb-button-give-feedback', NebButtonGiveFeedback);
