import './neb-appointment-edit-form-view';

import equal from 'fast-deep-equal';
import { LitElement, html, css } from 'lit';

import { fetchManyRooms } from '../../../../neb-api-client/src/rooms-api-client';
import { fetchOne } from '../../../../neb-api-client/src/settings-api-client';
import { store, connect } from '../../../../neb-redux/neb-redux-store';
import { AppointmentDetailsMomentService } from '../../../../neb-redux/services/appointment-details-moment';
import { AppointmentStoreService } from '../../../../neb-redux/services/appointment-store';
import { LAYOUT_TYPE } from '../../../../neb-redux/services/layout';
import { LocationsService } from '../../../../neb-redux/services/locations';
import { baseStyles } from '../../../../neb-styles/neb-styles';
import { parseDate } from '../../../../neb-utils/date-util';
import { openAppointmentPage } from '../../utils/appointment-overlays-util';
import { OVERLAY_KEYS, openOverlay } from '../../utils/overlay-constants';

const { LARGE } = LAYOUT_TYPE;
export const ELEMENTS = {
  view: {
    id: 'view',
  },
};

export const EMPTY_ROOM = {
  data: { id: null, name: '', allTypes: false, appointmentTypes: [] },
  label: '',
};

export function getDuration({ hours, minutes }) {
  return Math.floor(hours * (1000 * 60 * 60) + minutes * (1000 * 60));
}

class NebAppointmentEditFormController extends connect(store)(LitElement) {
  static get properties() {
    return {
      appointmentId: String,
      layout: {
        type: String,
        reflect: true,
      },

      __allRooms: Array,
      __rooms: Array,
      __appointmentTypes: Array,
      __allAppointmentTypes: Array,
      __schedulingSettings: Object,
      __appointment: Object,
      __model: Object,
      __initialModel: Object,
      __patientId: String,
      __saving: Boolean,
      __locations: Array,
      __searchText: String,
      __sortParams: Object,
      __splits: Array,
      __splitErrors: Object,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: flex;
          height: 100%;
          width: 100%;
        }

        :host([layout='small']) {
          height: unset;
        }

        .view {
          flex: 1 0 0;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
    this.__initServices();
  }

  __initState() {
    this.layout = LARGE;
    this.appointmentId = '';

    this.__appointment = null;
    this.__allRooms = [];
    this.__rooms = [];
    this.__appointmentTypes = [];
    this.__allAppointmentTypes = [];
    this.__schedulingSettings = {
      resourceRequired: false,
    };

    this.__initialModel = null;
    this.__model = null;
    this.__patientId = null;
    this.__saving = false;
    this.__searchText = '';
    this.__locations = [];
    this.__initialSplits = [];
    this.__splits = [];
    this.__splitErrors = {};

    this.__sortParams = {
      key: 'onsetSymptomsDate',
      dir: 'asc',
    };

    this.__updateError = null;

    this.onCancel = () => {};

    this.onSave = () => {};

    this.onChange = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      changeModel: model => {
        this.__model = model;

        this.onChange(model, this.isDirty());
        this.__filterAppointmentTypes();
      },
      cancel: () => this.onCancel(),
      save: () => this.__saveAppointment(),
      appointmentSelect: appointmentId => openAppointmentPage(appointmentId),
      openAlerts: patientId =>
        openOverlay(OVERLAY_KEYS.PATIENT_ALERT, {
          patientId,
        }),
      openAppointmentHistory: patientId =>
        openOverlay(OVERLAY_KEYS.APPOINTMENT_HISTORY, {
          patientId,
        }),
      addSplit: () => {
        let duration = {
          hours: '0',
          minutes: '0',
        };

        if (!this.__splits.length) {
          duration = {
            hours: Math.floor(this.__model.duration / 3600000),
            minutes: Math.floor((this.__model.duration % 3600000) / 60000),
          };
        }

        this.__splits.push({
          resourceId: { data: { id: null } },
          duration,
          start: this.__model.start.toISOString(),
        });

        this.__updateSplits(parseDate(this.__model.start));
      },
      removeSplit: index => {
        this.__splits.splice(index, 1);

        this.__updateSplits(parseDate(this.__model.start));
      },
      removeSplits: () => {
        this.__splits = [];
      },
      updateSplit: ({ name, value }) => {
        const [, index, key, durationType] = name.split('.');

        if (key === 'resourceId') {
          this.__splits[index][key] = value;
        }

        if (key === 'duration') {
          this.__splits[index][key] = {
            ...this.__splits[index][key],
            [durationType]: value,
          };
        }

        this.__updateSplits(parseDate(this.__model.start));
      },
    };
  }

  connectedCallback() {
    super.connectedCallback();

    this.__appointmentService.connect();
    this.__locationsService.connect();
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.__appointmentService.disconnect();
    this.__locationsService.disconnect();
  }

  async __loadData() {
    const {
      data: { resourceRequired },
    } = await fetchOne(true);

    this.__schedulingSettings = { resourceRequired };

    const res = await fetchManyRooms({ includeAppointmentTypes: true });
    const rooms = res.map(data => ({
      label: data.name,
      data,
    }));

    this.__allRooms = rooms ? [EMPTY_ROOM, ...rooms] : [EMPTY_ROOM];

    this.__filterRooms();
    this.__handleUpdateAppointment();
  }

  __initServices() {
    this.__appointmentService = new AppointmentDetailsMomentService(
      async ({ appointment, appointmentTypes }) => {
        if (appointment && appointment.id) {
          const start = appointment.start.valueOf();
          const end = appointment.end.valueOf();
          const duration = end - start;

          this.__allAppointmentTypes = appointmentTypes
            .filter(apptType => apptType.active === 1)
            .map(data => ({ label: data.name, data }));

          this.__appointment = {
            ...appointment,
            duration,
          };

          await this.__loadData();

          this.__splits =
            this.__mapSplitsForForm(appointment.appointmentSplits) || [];

          this.__initialSplits = this.__splits.map(split => ({
            ...split,
            duration: {
              ...split.duration,
            },
          }));

          if (this.__splits.length) {
            this.__updateSplits(parseDate(appointment.start));
          }
        }
      },
    );

    this.__locationsService = new LocationsService(({ userLocations }) => {
      this.__locations = userLocations
        .filter(loc => loc.active)
        .map(data => ({
          label: data.name,
          data,
        }));
    });

    this.__appointmentStoreService = new AppointmentStoreService();
  }

  _stateChanged({ appointments }) {
    this.__updateError = appointments.updateError;
  }

  isDirty() {
    return (
      !equal(this.__initialModel, this.__model) ||
      !equal(this.__initialSplits, this.__splits)
    );
  }

  __resetModel(key) {
    this[key] = {
      id: this.__appointment.id,
      note: this.__appointment.note || '',
      caseId: this.__appointment.caseId,
      patientAuthorizationId: this.__appointment.patientAuthorizationId,
      details: this.__appointment.details,
      locationId: this.__appointment.locationId,
      resourceId: this.__appointment.resourceId,
      appointmentTypeId: this.__appointment.appointmentTypeId,
      start: this.__appointment.start,
      end: this.__appointment.end,
      duration: this.__appointment.duration,
    };
  }

  __updateSplits(newStart) {
    let durationSum = 0;

    this.__splits.forEach(split => {
      const splitStart = newStart.clone().add(durationSum, 'ms');

      split.start = splitStart.toISOString();
      durationSum += getDuration(split.duration);
    });

    this.__model = {
      ...this.__model,
      duration: durationSum,
    };

    this.__splits = [...this.__splits];

    this.__splitErrors = {
      splits: this.__splits.map(split => {
        const { resourceId, duration } = split;

        return {
          resourceId: !resourceId.data.id ? 'Required' : null,
          duration:
            (duration.hours === '0' || !duration.hours) &&
            (duration.minutes === '0' || !duration.minutes)
              ? 'Required'
              : null,
        };
      }),
    };

    this.onChange(undefined, this.isDirty());
  }

  __mapSplitsForForm(splits) {
    return splits
      .map(split => {
        const { resourceId, start } = split;
        const duration = parseDate(split.end).diff(split.start, 'ms');

        return {
          resourceId: this.__rooms.find(room => room.data.id === resourceId),
          duration: {
            hours: Math.floor(duration / (1000 * 60 * 60)),
            minutes: Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60)),
          },
          start,
        };
      })
      .sort((a, b) => parseDate(a.start).diff(parseDate(b.start)));
  }

  __mapSplitsForApi(splits) {
    return splits.map(split => ({
      start: split.start,
      duration: getDuration(split.duration),
      resourceId: split.resourceId.data.id,
    }));
  }

  __filterAppointmentTypes() {
    if (this.__allAppointmentTypes && this.__allAppointmentTypes.length > 0) {
      const selectedRoom = this.__rooms.find(
        room => room.data.id === this.__model.resourceId,
      ) || { data: { allTypes: false, appointmentTypes: [] } };

      const { resourceRequired } = this.__schedulingSettings;
      const { providerId } = this.__appointment;
      const { locationId, resourceId } = this.__model;
      const { allTypes, appointmentTypes } = selectedRoom.data;

      this.__appointmentTypes = this.__allAppointmentTypes.filter(apptType => {
        const hasMatchingProvider =
          !providerId || apptType.data.providers.includes(providerId);
        const hasMatchingLocation =
          apptType.data.locations.includes(locationId);
        const hasMatchingAppointmentType =
          allTypes || appointmentTypes.includes(apptType.data.id);

        if (resourceRequired) {
          if (providerId) {
            return (
              hasMatchingProvider &&
              hasMatchingLocation &&
              hasMatchingAppointmentType
            );
          }
          return hasMatchingLocation && hasMatchingAppointmentType;
        }

        if (resourceId) {
          if (providerId) {
            return (
              hasMatchingProvider &&
              hasMatchingLocation &&
              hasMatchingAppointmentType
            );
          }
          return hasMatchingLocation && hasMatchingAppointmentType;
        }

        if (providerId) {
          return hasMatchingProvider && hasMatchingLocation;
        }

        return hasMatchingLocation;
      });
    }
  }

  __filterRooms() {
    this.__rooms = this.__allRooms.filter(
      ({ data }) =>
        (data.active &&
          data.scheduleAvailable &&
          data.locationId === this.__model.locationId) ||
        (data.id === EMPTY_ROOM.data.id &&
          !this.__schedulingSettings.resourceRequired),
    );
  }

  __toAppointment() {
    return {
      id: this.__model.id,
      note: this.__model.note,
      caseId: this.__model.caseId,
      patientAuthorizationId: this.__model.patientAuthorizationId,
      locationId: this.__model.locationId,
      resourceId: this.__model.resourceId,
      appointmentTypeId: this.__model.appointmentTypeId,
      duration: this.__model.duration,
    };
  }

  __handleUpdateAppointment() {
    if (this.__appointment) {
      this.__resetModel('__initialModel');
      this.__resetModel('__model');
      this.onChange(this.__model, this.isDirty());
    }
  }

  async __saveAppointment() {
    const appointment = this.__toAppointment();

    try {
      this.__saving = true;

      const result = await this.__appointmentStoreService.edit({
        ...appointment,
        splits: this.__mapSplitsForApi(this.__splits),
      });

      if (result && result.success) {
        this.onChange(this.__model, false);
        this.onSave(result);
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.__saving = false;
    }
  }

  updated(changedProps) {
    if (changedProps.has('appointmentId')) {
      this.__appointmentService.update(this.appointmentId);
    }

    if (changedProps.has('__appointment')) {
      if (this.__appointment) {
        this.__handleUpdateAppointment();
        this.__filterAppointmentTypes();

        this.__patientId = this.__appointment.patient.id;
      }

      if (this.__allRooms.length) {
        this.__filterRooms();
      }
    }
  }

  update(changedProps) {
    if (changedProps.has('__model') && this.__allRooms.length) {
      this.__filterRooms();
      this.__filterAppointmentTypes();
    }

    super.update(changedProps);
  }

  render() {
    return html`
      <neb-appointment-edit-form-view
        id="${ELEMENTS.view.id}"
        class="view"
        .layout="${this.layout}"
        .appointment="${this.__model}"
        .locations="${this.__locations}"
        .rooms="${this.__rooms}"
        .splits="${this.__splits}"
        .splitErrors="${this.__splitErrors}"
        .appointmentTypes="${this.__appointmentTypes}"
        .schedulingSettings="${this.__schedulingSettings}"
        .patientId="${this.__patientId}"
        .onCancel="${this.__handlers.cancel}"
        .onClose="${this.__handlers.cancel}"
        .onSave="${this.__handlers.save}"
        .onChange="${this.__handlers.changeModel}"
        .onAppointmentSelect="${this.__handlers.appointmentSelect}"
        .onOpenAlerts="${this.__handlers.openAlerts}"
        .onOpenAppointmentHistory="${this.__handlers.openAppointmentHistory}"
        .onAddSplit="${this.__handlers.addSplit}"
        .onRemoveSplit="${this.__handlers.removeSplit}"
        .onRemoveSplits="${this.__handlers.removeSplits}"
        .onUpdateSplit="${this.__handlers.updateSplit}"
        .saving="${this.__saving}"
        ?dirty="${this.isDirty()}"
      ></neb-appointment-edit-form-view>
    `;
  }
}

window.customElements.define(
  'neb-appointment-edit-form-controller',
  NebAppointmentEditFormController,
);
