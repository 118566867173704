import { Method } from './utils/api-client-utils';
import ApiClientV2 from './utils/api-client-v2';

export const apiClient = new ApiClientV2({ microservice: 'billing' });

const GET_INSURANCE_VERSION = 1;

export const createPatientInsurance = (patientId, patientInsurance) => {
  const res = apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.POST,
    path: `/api/v3/tenants/:tenantId/patients/:patientId/insurance?forCheckIn=${patientInsurance.forCheckIn}`,
    replacements: { patientId },
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(patientInsurance),
    updateNotificationDetails: { insurance: { patientId } },
  });

  return res;
};

export const getPatientInsurances = (
  patientId,
  queryParams = {},
  optOutLoadingIndicator = false,
) => {
  const patientInsurances = apiClient.makeRequest({
    method: Method.GET,
    path: '/api/v1/tenants/:tenantId/patients/:patientId/insurance',
    queryParams,
    replacements: { patientId },
    headers: {
      'Content-Type': 'application/json',
    },
    optOutLoadingIndicator,
    cacheKey: `patients/${patientId}/insurance-${JSON.stringify(queryParams)}`,
  });

  return patientInsurances;
};

export const getPatientInsurancesCount = (
  patientId,
  queryParams = {},
  optOutLoadingIndicator = false,
) =>
  apiClient.makeRequest({
    method: Method.GET,
    path: '/api/v1/tenants/:tenantId/patients/:patientId/insurance/count',
    queryParams,
    replacements: { patientId },
    headers: {
      'Content-Type': 'application/json',
    },
    optOutLoadingIndicator,
  });

export const getPatientInsurance = (
  patientId,
  id,
  version = GET_INSURANCE_VERSION,
  optOutLoadingIndicator = false,
) => {
  const path =
    version === 2
      ? '/api/v2/tenants/:tenantId/patients/:patientId/insurance/:id'
      : '/api/v1/tenants/:tenantId/patients/:patientId/insurance/:id';

  return apiClient.makeRequest({
    method: Method.GET,
    path,
    replacements: { patientId, id },
    headers: {
      'Content-Type': 'application/json',
    },
    cacheKey: `${patientId}-${id}-${version}-patient-insurance`,
    optOutLoadingIndicator,
  });
};

export const getMaxVisits = patientId =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: '/api/v2/tenants/:tenantId/patients/:patientId/max-visits',
    replacements: { patientId },
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const putPatientInsurance = async patientInsurance => {
  const res = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.PUT,
    path: '/api/v3/tenants/:tenantId/patients/:patientId/insurance/:id',
    replacements: {
      patientId: patientInsurance.patientId,
      id: patientInsurance.id,
    },
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(patientInsurance),
    updateNotificationDetails: {
      insurance: { patientId: patientInsurance.patientId },
    },
  });

  return {
    insurance: res.data[0],
    newBillType: res.newBillType,
    caseOverrideUpdated: res.caseOverrideUpdated,
  };
};

export const getAdditionalInsuranceInfo = async (patientId, insuranceId) => {
  const res = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: '/api/v1/tenants/:tenantId/patients/:patientId/insurance/additional-info',
    replacements: { patientId },
    headers: {
      'Content-Type': 'application/json',
    },
    queryParams: {
      insuranceId,
    },
  });

  return res.data[0];
};

export const getInsurancesFromPatients = async patientIds => {
  if (!patientIds?.length) return [];

  const patientInsurances = await apiClient.makeRequest({
    method: Method.POST,
    path: '/api/v1/tenants/:tenantId/patient-insurances',
    optOutLoadingIndicator: false,
    cacheKey: `patient-insurances-${JSON.stringify({ patientIds })}`,
    body: JSON.stringify({ patientIds }),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return patientInsurances.data;
};
