import '../../misc/neb-icon';

import { css, html, LitElement } from 'lit';

import {
  CSS_COLOR_GREY_2,
  CSS_BANNER_INFO_BACKGROUND_COLOR,
  CSS_BANNER_INFO_COLOR,
  CSS_COLOR_HIGHLIGHT,
} from '../../../styles';

export const ELEMENTS = {
  button: { id: 'button' },
  icon: { id: 'icon' },
  name: { id: 'name' },
};

class NebButtonPatient extends LitElement {
  static get properties() {
    return {
      patient: {
        type: Object,
      },
      onClick: Function,
      active: {
        type: Boolean,
        reflect: true,
      },
    };
  }

  initState() {
    this.active = false;
    this.patient = null;

    this.onClick = () => {};
  }

  initHandlers() {
    this.handlers = {
      click: () => this.onClick(this.patient?.id),
    };
  }

  constructor() {
    super();

    this.initState();
    this.initHandlers();
  }

  static get styles() {
    return css`
      #button {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 4px 8px;
        border: 1px solid ${CSS_COLOR_GREY_2};
        border-radius: 20px;
        background: white;
        cursor: pointer;
        font-size: 12px;
        transition: all 0.2s ease;
        min-height: 30px;
      }

      :host([active]) #button {
        background: ${CSS_BANNER_INFO_BACKGROUND_COLOR};
        color: ${CSS_BANNER_INFO_COLOR};
        border-color: ${CSS_COLOR_HIGHLIGHT};
      }

      #icon {
        width: 16px;
        height: 16px;
        fill: currentColor;
      }
    `;
  }

  __formatName(patient) {
    if (!patient?.name) return '';
    return `${patient.name.first} ${patient.name.last.charAt(0)}.`;
  }

  render() {
    return html`
      <button id="${ELEMENTS.button.id}" @click="${this.handlers.click}">
        <neb-icon id="${ELEMENTS.icon.id}" icon="neb:person"></neb-icon>
        <span id="${ELEMENTS.name.id}">${this.__formatName(this.patient)}</span>
      </button>
    `;
  }
}

customElements.define('neb-button-patient', NebButtonPatient);
