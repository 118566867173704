import { html, css } from 'lit';

import '../../../../../packages/neb-lit-components/src/components/neb-text';
import '../../../misc/neb-icon';
import Table, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../../../packages/neb-lit-components/src/components/tables/neb-table';
import { CSS_COLOR_HIGHLIGHT, CSS_COLOR_GREY_3 } from '../../../../styles';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  visibilityIcon: { selector: '[id^=visibility-icon-]' },
  nameCell: { selector: '[id^=cell-value-name-]' },
};

class NebTableManageColumns extends Table {
  static get styles() {
    return [
      super.styles,
      css`
        .visibility-icon {
          cursor: pointer;
          width: 24px;
          height: 24px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        [id^='row-']:has(neb-icon[icon='neb:visibilityOff']) {
          background-color: ${CSS_COLOR_GREY_3};
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.model = [];

    this.onToggleVisibility = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      toggleVisibility: e => {
        const rowIndex = e.currentTarget.getAttribute('index');
        this.onToggleVisibility(e, rowIndex);
      },
    };
  }

  renderDataCell(value, columnConfig, rowIndex) {
    if (columnConfig.key === 'visibility') {
      const row = this.model[rowIndex];
      return html`
        <neb-icon
          id="visibility-icon-${rowIndex}"
          class="visibility-icon"
          icon="${row.selected ? 'neb:visible' : 'neb:visibilityOff'}"
          index="${rowIndex}"
          @click="${this.handlers.toggleVisibility}"
        ></neb-icon>
      `;
    }
    return html`
      <neb-text
        id="cell-value-${columnConfig.key}-${rowIndex}"
        ?truncate="${columnConfig.truncate}"
      >
        ${value}
      </neb-text>
    `;
  }
}

customElements.define('neb-table-manage-columns', NebTableManageColumns);
