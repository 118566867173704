export const calculateAlreadyAllocated = allocations =>
  allocations.reduce(
    (memo, { amount: existingAmount }) => memo + existingAmount,
    0,
  );

export const calculateAutoAllocateDebits = (
  lineItemDebits,
  payment,
  onlyAllocatePatientDebits = false,
) => {
  const { amount: paymentAmount } = payment;

  let remainingPaymentAmount = paymentAmount;

  return lineItemDebits.reduce(
    (
      memo,
      {
        debit: { id, allocations, amount, payerId },
        patientInsuranceId,
        codePaymentId,
      },
    ) => {
      const debitBalance = amount - calculateAlreadyAllocated(allocations);

      let allocated = 0;

      if (
        debitBalance > 0 &&
        (!onlyAllocatePatientDebits || !patientInsuranceId)
      ) {
        if (remainingPaymentAmount <= debitBalance) {
          allocated = remainingPaymentAmount;
          remainingPaymentAmount = 0;
        } else {
          allocated = debitBalance;
          remainingPaymentAmount -= debitBalance;
        }
      } else {
        allocated = 0;
      }

      memo.push({
        id,
        allocated,
        amount,
        payerId,
        codePaymentId: codePaymentId || null,
        patientInsuranceId: patientInsuranceId || null,
      });

      return memo;
    },
    [],
  );
};
