import ApiClientV2 from '../../utils/api-client-v2';

export const apiClient = new ApiClientV2({ microservice: 'billing' });

export const getMatchCharges = ({ eraId, lineItemReportId }) =>
  apiClient.makeRequest({
    path: '/api/v1/tenants/:tenantId/era/:eraId/line-item/:lineItemReportId/matchable-charges',
    replacements: { eraId, lineItemReportId },
    cacheKey: `era/${eraId}/line-item/${lineItemReportId}/matchable-charges`,
    optOutLoadingIndicator: false,
  });
