import '../../../../packages/neb-lit-components/src/components/neb-popup-header';
import '../../../../packages/neb-material-design/src/components/neb-loading-spinner';
import '../../forms/payment/neb-form-preallocate-payment';

import { html, css } from 'lit';

import { fetchSome } from '../../../../packages/neb-api-client/src/patient-api-client';
import { getPatientInsurances } from '../../../../packages/neb-api-client/src/patient-insurance-api-client';
import { getPatientRelationships } from '../../../../packages/neb-api-client/src/patient-relationship-api-client';
import { getProviderUsers } from '../../../../packages/neb-api-client/src/practice-users-api-client';
import { openError } from '../../../../packages/neb-dialog/neb-banner-state';
import Overlay from '../../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import { LocationsService } from '../../../../packages/neb-redux/services/locations';
import { getPreallocateLineItems } from '../../../api-clients/preallocate-payment';
import { CSS_SPACING, CSS_COLOR_GREY_2 } from '../../../styles';
import {
  createPreallocateInfoMap,
  ERROR_BANNER_MESSAGE,
  ERROR_LOADING_MORE,
  groupLineItemsModelsByEncounter,
  mapLineItemsToModel,
  selectLineItemsAhead,
  loadMoreUpdatePreallocateInfoMap,
} from '../../../utils/preallocate-payment-util';

export const ELEMENTS = {
  header: {
    id: 'header',
  },
  form: {
    id: 'form',
  },
  loadingSpinner: {
    id: 'loading-spinner',
  },
};

class NebOverlayPreallocatePayment extends Overlay {
  static get properties() {
    return {
      patient: Object,
      loading: Boolean,
      __preallocateInfoMap: Object,
      __relationships: Array,
      __insurances: Array,
      __providers: Array,
      __locations: Array,
      __locationsService: Object,
      __remainingPatientBalance: Number,
      __remainingPatientCredits: Number,
    };
  }

  initState() {
    super.initState();
    this.loading = true;
    this.__preallocateInfoMap = {};
    this.__relationships = [];
    this.__insurances = [];
    this.__providers = [];
    this.__remainingPatientBalance = 0;
    this.__remainingPatientCredits = 0;
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      loadMore: (groups, patientId) => this.__loadMore(groups, patientId),
    };
  }

  connectedCallback() {
    super.connectedCallback();

    return this.__fetch();
  }

  async __fetch() {
    if (this.model.patient) {
      try {
        const [
          {
            meta: {
              patientCharges = 0,
              remainingPatientCredits = 0,
              encounters,
            },
            data: lineItems,
            count,
          },
          insurances,
          relationships,
          providers,
        ] = await Promise.all([
          getPreallocateLineItems({
            patientId: this.model.patient.id,
            pageNumber: 1,
          }),
          getPatientInsurances(this.model.patient.id, {}, true),
          getPatientRelationships(this.model.patient.id, {}, undefined, true),
          getProviderUsers(),
        ]);

        const relationshipPatients = await fetchSome(
          relationships.map(({ relatedPatientId }) => relatedPatientId),
        );

        this.__relationships = relationships
          .sort((a, b) => a.createdAt?.localeCompare(b.createdAt))
          .map(relationship => ({
            ...relationship,
            patient: relationshipPatients.find(
              patient => patient.id === relationship.relatedPatientId,
            ),
          }));

        this.__insurances = insurances;
        this.__providers = providers;

        this.__locationsService = new LocationsService(
          ({ activeLocations }) => {
            this.__locations = activeLocations.map(data => ({
              data,
              label: data.name,
            }));
          },
        );

        this.__locationsService.connect();

        this.__preallocateInfoMap = createPreallocateInfoMap({
          patientId: this.model.patient.id,
          relationships,
          count,
          itemGroups: selectLineItemsAhead(
            groupLineItemsModelsByEncounter(
              mapLineItemsToModel({
                lineItems,
                encounters,
                insurances,
                providers,
                relationships,
                locations: this.__locations,
              }),
            ),
          ),
        });

        this.__remainingPatientBalance =
          patientCharges - remainingPatientCredits;

        this.__remainingPatientCredits = remainingPatientCredits;
      } catch (e) {
        store.dispatch(openError(ERROR_BANNER_MESSAGE));
        this.__preallocateInfoMap = null;
        console.error(e);
      } finally {
        this.loading = false;
      }
    }
  }

  async __loadMore(preallocateInfoMap, patientId) {
    try {
      const {
        data: lineItems,
        count,
        meta: { encounters },
      } = await getPreallocateLineItems({
        patientId,
        pageNumber: preallocateInfoMap[patientId].pageNumber + 1,
      });

      this.__preallocateInfoMap = loadMoreUpdatePreallocateInfoMap({
        preallocateInfoMap,
        patientId,
        count,
        itemGroups: groupLineItemsModelsByEncounter([
          ...preallocateInfoMap[patientId].itemGroups.flatMap(
            ({ items }) => items,
          ),
          ...mapLineItemsToModel({
            lineItems,
            encounters,
            insurances: this.__insurances,
            providers: this.__providers,
            relationships: this.__relationships,
            locations: this.__locations,
          }),
        ]),
      });
    } catch (e) {
      store.dispatch(openError(ERROR_LOADING_MORE, e));
    }
  }

  static get styles() {
    return [
      super.styles,
      css`
        .header {
          padding: ${CSS_SPACING};
          border-bottom: 1px solid ${CSS_COLOR_GREY_2};
        }

        .content {
          width: 100%;
        }

        #loading-spinner {
          margin: auto;
          height: 100px;
          width: 100px;
        }

        .form {
          height: 93%;
          overflow-y: scroll;
        }
      `,
    ];
  }

  dismissWithBlocker() {
    const result = undefined;
    const dismissAll = true;
    this.dismiss(result, dismissAll);
  }

  __renderSpinnyWheel() {
    return html`
      <neb-loading-spinner
        id="${ELEMENTS.loadingSpinner.id}"
      ></neb-loading-spinner>
    `;
  }

  __renderForm() {
    return html`
      <neb-form-preallocate-payment
        id="${ELEMENTS.form.id}"
        class="form"
        .patient="${this.model.patient}"
        .preallocateInfoMap="${this.__preallocateInfoMap}"
        .remainingPatientBalance="${this.__remainingPatientBalance}"
        .remainingPatientCredits="${this.__remainingPatientCredits}"
        .relationships="${this.__relationships}"
        .onDismiss="${() => this.dismissWithBlocker()}"
        .onLoadMore="${this.handlers.loadMore}"
      >
      </neb-form-preallocate-payment>
    `;
  }

  renderContent() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        title="Outstanding Charges"
        showBackButton
        .onBack="${this.handlers.dismiss}"
        .onCancel="${this.handlers.dismissAll}"
      >
      </neb-popup-header>
      ${this.loading ? this.__renderSpinnyWheel() : this.__renderForm()}
      </neb-form-preallocate-payment>
    `;
  }
}

customElements.define(
  'neb-overlay-preallocate-payment',
  NebOverlayPreallocatePayment,
);
