import {
  isElectronicPayment,
  isPartiallyOrFullyAllocated,
} from '../../../packages/neb-utils/neb-payment-util';

export const REPORT_TYPE = { EOB: 'EOB', ERA: 'ERA' };

const paymentIsNotUnallocated = ({ status }) => status !== 'Unallocated';

const paymentIsRefunded = ({ status }) => status === 'Refunded';

const paymentIsVoided = ({ voidedAt }) => voidedAt !== null;

const paymentHasNoBalance = ({ amount, paymentAllocated }) =>
  amount - paymentAllocated === 0;

const paymentIsZeroDollar = ({ amount }) => amount === 0;

const getAllocated = ({ voidedAt, refundedAt, paymentAllocated }) =>
  !(voidedAt || refundedAt) ? paymentAllocated : 0;

const paymentHasSomeAllocation = paymentDetail => {
  const allocated = getAllocated(paymentDetail);

  return isPartiallyOrFullyAllocated({ allocated });
};

const paymentHasSplitPayment = ({ parentPaymentId }) => !!parentPaymentId;

const paymentHasEClaimInfo = ({ eRA }) => !!(eRA && eRA.hasEClaimInfo);

const paymentHasERA = ({ eRA }) => !!eRA;

const paymentIsPayerPayment = ({ payerPlanId }) => !!payerPlanId;

const paymentMethodIsNotERA = ({ paymentMethod }) => paymentMethod !== 'ERA';

const paymentHasNegativeValue = ({ amount }) => amount < 0;

const paymentIsZeroDollarOrHasNoBalance = payment =>
  paymentIsZeroDollar(payment) || paymentHasNoBalance(payment);

const paymentHasProviderAdjustment = ({ providerAdjustments }) =>
  providerAdjustments.length > 0;

const eraHasWarnOrUnmatchedAmount = ({ eRA }) =>
  eRA && (eRA.warn || eRA.unmatchedAmount > 0);

const getPaymentState = payment => ({
  isNegativePayment: paymentHasNegativeValue(payment),
  isZeroDollarOrNoBalance: paymentIsZeroDollarOrHasNoBalance(payment),
  isRefunded: paymentIsRefunded(payment),
  isElectronic: isElectronicPayment(payment),
  isNotUnallocated: paymentIsNotUnallocated(payment),
  isPayerPayment: paymentIsPayerPayment(payment),
  methodIsNotEra: paymentMethodIsNotERA(payment),
  hasERA: paymentHasERA(payment),
  hasEClaimInfo: paymentHasEClaimInfo(payment),
  hasSplitPayment: paymentHasSplitPayment(payment),
  hasSomeAllocation: paymentHasSomeAllocation(payment),
  hasWarnOrUnmatchedAmount: eraHasWarnOrUnmatchedAmount(payment),
  isVoided: paymentIsVoided(payment),
  hasProviderAdjustment: paymentHasProviderAdjustment(payment),
});

const disableVoidPayment = ({
  isElectronic,
  hasSplitPayment,
  isVoided,
  isRefunded,
}) => isVoided || isElectronic || hasSplitPayment || isRefunded;

const disableRefundPayment = ({
  hasERA,
  isNotUnallocated,
  hasSomeAllocation,
  isZeroDollarOrNoBalance,
}) =>
  hasERA || isNotUnallocated || hasSomeAllocation || isZeroDollarOrNoBalance;

const disableReviewAllocations = ({ isPayerPayment, hasSomeAllocation }) =>
  !isPayerPayment || !hasSomeAllocation;

const disablePreviewAllocations = ({
  hasEClaimInfo,
  methodIsNotEra,
  isPayerPayment,
  isNotUnallocated,
}) => methodIsNotEra || !isPayerPayment || !hasEClaimInfo || isNotUnallocated;

const disableAllocatePayment = ({
  isNotUnallocated,
  isNegativePayment,
  isZeroDollarOrNoBalance,
  isVoided,
}) =>
  isZeroDollarOrNoBalance
    ? isVoided || isNegativePayment
    : isNotUnallocated || isNegativePayment;

const disableSplitPayment = ({
  hasSplitPayment,
  isNotUnallocated,
  isVoided,
  hasProviderAdjustment,
}) =>
  (!hasSplitPayment && isNotUnallocated) || isVoided || hasProviderAdjustment;

// eslint-disable-next-line complexity
const getPaymentActions = ({
  s3key,
  paymentState,
  readonlyOrDirty,
  reportType,
  hasEraOverviewNavigationFF,
}) => {
  const buttons = [
    ...(paymentState.hasERA && s3key
      ? [
          {
            name: 'printReport',
            label: 'ERA Report',
            icon: 'receipt',
            disabled: false,
          },
        ]
      : []),
    ...(reportType === REPORT_TYPE.EOB && s3key
      ? [
          {
            name: 'printReport',
            label: 'EOB Report',
            icon: 'receipt',
            disabled: false,
          },
        ]
      : []),
    {
      name: 'voidPayment',
      label: 'Void Payment',
      icon: 'clear',
      disabled: disableVoidPayment(paymentState),
    },
    {
      name: 'refundPayment',
      label: 'Refund Payment',
      icon: 'refund',
      disabled: disableRefundPayment(paymentState),
    },
    {
      name: 'reviewExistingAllocation',
      label: 'Review Allocations',
      icon: 'wallet',
      disabled: disableReviewAllocations(paymentState),
    },
    {
      name: 'previewAllocation',
      label: 'Preview Allocation',
      icon: 'preview',
      disabled: disablePreviewAllocations(paymentState),
    },
    {
      name: 'allocatePayment',
      label: 'Allocate Payment',
      icon: 'allocate',
      disabled: disableAllocatePayment(paymentState),
    },
    {
      name: 'splitPayment',
      label: 'Split Payment',
      icon: 'split',
      disabled: disableSplitPayment(paymentState),
    },
    ...(paymentState.hasWarnOrUnmatchedAmount && reportType === REPORT_TYPE.ERA
      ? [
          {
            name: 'updateERAPayment',
            label: 'Update Payment',
            icon: 'edit',
            warn: true,
          },
        ]
      : []),
    ...(hasEraOverviewNavigationFF
      ? [
          {
            name: 'autoAllocatePayment',
            label: 'Auto Allocate Pt. Payments',
            icon: 'flashAuto',
          },
        ]
      : []),
  ];

  return buttons.map(button => ({
    ...button,
    disabled: button.disabled || readonlyOrDirty,
  }));
};

export default ({
  s3key,
  reportType,
  paymentDetail,
  readonlyOrDirty,
  onPerformAction,
  hasEraOverviewNavigationFF = false,
}) => {
  const paymentState = getPaymentState(paymentDetail);

  const buttons = [
    ...getPaymentActions({
      s3key,
      paymentState,
      readonlyOrDirty,
      reportType,
      hasEraOverviewNavigationFF,
    }),
  ];

  return buttons.map(button => ({
    ...button,
    onClick: () => onPerformAction(button.name),
  }));
};
