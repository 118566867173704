import '../../../../packages/neb-lit-components/src/components/neb-popup-header';
import '../../../../packages/neb-lit-components/src/components/neb-text';
import '../../../../packages/neb-lit-components/src/components/neb-header';
import '../../tables/code-bundles/neb-table-code-bundle-charges';
import { css, html } from 'lit';

import '../../forms/settings/neb-form-add-code-bundle';
import { openError } from '../../../../packages/neb-dialog/neb-banner-state';
import Overlay from '../../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import {
  CSS_SPACING,
  OVERLAY_WIDTH_EXTRA_LARGE,
} from '../../../../packages/neb-styles/neb-variables';
import {
  FEATURE_FLAGS,
  hasFeatureOrBeta,
} from '../../../../packages/neb-utils/feature-util';
import { getCodeBundle } from '../../../api-clients/code-bundles';

export const ELEMENTS = {
  diagnosisTable: { id: 'table-diagnosis' },
  chargeTable: { id: 'table-charges' },
  header: { id: 'header' },
  description: { id: 'description' },
  actionBar: { id: 'action-bar' },
};

const DIAGNOSIS_CONFIG = [
  {
    key: 'diagnosisCode',
    label: 'ICD10',
    flex: css`0 0 150px`,
  },
  {
    key: 'shortDescription',
    label: 'Description',
    flex: css`1 0 0`,
  },
];

const LOADING_TEXT = 'Loading...';

const NO_DIAGNOSES_TEXT =
  'There are currently no diagnoses included in this code bundle.';

const NO_CHARGES_TEXT =
  'There are currently no charges included in this code bundle.';

const ERROR_DIAGNOSES_TEXT =
  'An error occurred while fetching the Code Bundle diagnoses.';

const ERROR_CHARGES_TEXT =
  'An error occurred while fetching the Code Bundle charges.';

const formatDiagnosisPointerOptions = diagnoses =>
  diagnoses
    ? diagnoses.map(d => ({
        label: `${d.diagnosisCode} - ${d.shortDescription}`,
        item: { diagnosisCode: d.diagnosisCode },
      }))
    : [];

class NebViewCodeBundleDetail extends Overlay {
  static get properties() {
    return {
      __codeBundle: Object,
      __emptyMessageDiagnoses: String,
      __emptyMessageCharges: String,
      __hasDHChargeUnitsFF: Boolean,
    };
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,

      dismiss: () => this.dismiss(),
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          width: ${OVERLAY_WIDTH_EXTRA_LARGE};
        }

        .header {
          display: flex;
          padding: ${CSS_SPACING};
        }

        .table-header {
          padding: 20px 0px 18px;
        }

        .description {
          padding-left: ${CSS_SPACING};
        }

        .row-header {
          padding-top: 20px;
        }

        .scrollable-area {
          overflow: auto;
        }
      `,
    ];
  }

  initState() {
    super.initState();
    this.model = {
      codeBundleId: '',
    };

    this.__codeBundle = {
      name: '',
      diagnoses: [],
      charges: [],
      description: '',
      active: true,
    };

    this.__emptyMessageDiagnoses = LOADING_TEXT;
    this.__emptyMessageCharges = LOADING_TEXT;
    this.__hasDHChargeUnitsFF = false;
  }

  async connectedCallback() {
    this.__hasDHChargeUnitsFF = await hasFeatureOrBeta(
      FEATURE_FLAGS.DH_CHARGE_UNITS,
    );

    super.connectedCallback();

    try {
      if (this.model.codeBundleId) {
        const codeBundle = await getCodeBundle(this.model.codeBundleId);
        const charges = codeBundle.charges.map(charge => ({
          ...charge,
          diagnosisPointers: formatDiagnosisPointerOptions(
            charge.diagnosisPointers.map(dx => {
              const diagnosis = codeBundle.diagnoses.find(
                ({ diagnosisCode }) => dx.diagnosisCode === diagnosisCode,
              );

              return {
                ...dx,
                shortDescription: diagnosis ? diagnosis.shortDescription : '',
              };
            }),
          ),
        }));

        this.__codeBundle = {
          ...codeBundle,
          charges,
        };

        this.__emptyMessageDiagnoses = NO_DIAGNOSES_TEXT;
        this.__emptyMessageCharges = NO_CHARGES_TEXT;
      }
    } catch (err) {
      store.dispatch(
        openError('An error occurred while fetching the Code Bundle'),
      );

      this.__emptyMessageDiagnoses = ERROR_DIAGNOSES_TEXT;

      this.__emptyMessageCharges = ERROR_CHARGES_TEXT;

      console.error(err);
    }
  }

  __renderDiagnosisTable() {
    return html`
      <neb-table
        id="${ELEMENTS.diagnosisTable.id}"
        .config="${DIAGNOSIS_CONFIG}"
        .model="${this.__codeBundle.diagnoses}"
        .hideHeader="${!this.__codeBundle.diagnoses.length}"
        .emptyMessage="${this.__emptyMessageDiagnoses}"
      ></neb-table>
    `;
  }

  __getChargesConfig() {
    return [
      {
        key: 'procedure',
        label: 'Procedure',
        flex: css`0 0 150px`,
      },
      {
        key: 'description',
        label: 'Description',
        flex: css`3 0 0`,
      },
      {
        key: 'modifiers',
        label: 'Modifiers',
        flex: css`1 0 0`,
        formatter: values =>
          values ? values.filter(item => item).join(', ') : '',
      },
      ...(this.__hasDHChargeUnitsFF
        ? [
            {
              key: 'units',
              label: 'Units',
              flex: css`0 0 80px`,
            },
          ]
        : []),
      {
        key: 'diagnosisPointers',
        label: 'Diagnosis Pointer',
        flex: css`0 0 150px`,
      },
    ];
  }

  __renderChargesTable() {
    const diagnosisPointerOptions = formatDiagnosisPointerOptions(
      this.__codeBundle.diagnoses,
    );

    return html`
      <neb-table-code-bundle-charges
        id="${ELEMENTS.chargeTable.id}"
        .model="${this.__codeBundle.charges}"
        .config="${this.__getChargesConfig()}"
        .diagnoses="${diagnosisPointerOptions}"
        .inactivePointers="${true}"
        .hideHeader="${!this.__codeBundle.charges.length}"
        .emptyMessage="${this.__emptyMessageCharges}"
      ></neb-table-code-bundle-charges>
    `;
  }

  renderContent() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        title="${this.__codeBundle.name}"
        .onCancel="${this.handlers.dismiss}"
      ></neb-popup-header>

      <div class="scrollable-area">
        <neb-text id="${ELEMENTS.description.id}" class="description">
          ${this.__codeBundle.description}
        </neb-text>

        <neb-header class="table-header" label="Diagnoses"></neb-header>

        ${this.__renderDiagnosisTable()}

        <neb-header class="table-header" label="Charges"></neb-header>

        ${this.__renderChargesTable()}
      </div>

      <neb-action-bar
        id="${ELEMENTS.actionBar.id}"
        confirmLabel=""
        cancelLabel="Done"
        .onCancel="${this.handlers.dismiss}"
      ></neb-action-bar>
    `;
  }
}

customElements.define(
  'neb-overlay-view-code-bundle-detail',
  NebViewCodeBundleDetail,
);
