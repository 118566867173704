import {
  NO_ASSOCIATED_PROVIDER_ID,
  filterNoAssociatedProviderId,
  buildStatementBodyWithLocations,
} from '../../../src/utils/api-client/ledger-statement-util';
import { store } from '../../neb-redux/neb-redux-store';

import { Method, RESPONSE_TYPE } from './utils/api-client-utils';
import ApiClientV2 from './utils/api-client-v2';
import { buildStatementBody } from './utils/ledger-utils';

export const apiClientBilling = new ApiClientV2({ microservice: 'billing' });
export const apiClientPdf = new ApiClientV2({ microservice: 'pdf' });

export async function add(patientId, model, preview, addressIds) {
  const nullProvider = model.providerIds.includes(NO_ASSOCIATED_PROVIDER_ID);
  const providerIds = filterNoAssociatedProviderId(model.providerIds);

  const body = {
    preview,
    patientId,
    addressIds,
    statementBody: {
      agingTable: model.agingTable,
      allCharges: model.allCharges,
      groupStatements: model.groupStatements,
      previousBalance: model.previousBalance,
      nullProvider,
      providers: providerIds,
      relatedPatientIds: model.relatedPatientIds,
      dateOfServiceTo: model.dateOfServiceTo || undefined,
      dateOfServiceFrom: model.dateOfServiceFrom || undefined,
      dueByDate: model.dueByDate,
      includePaymentDetails: model.includePaymentDetails,
      showCarePackageChargesAsPatientPaid:
        model.showCarePackageChargesAsPatientPaid,
      includeCreditCardCutOff: model.includeCreditCardCutOff,
      minimumBalance: model.minimumBalance || 0,
      balanceEmail: model.balanceEmail,
    },
    comment: model.comment,
    ...(model.billingAddressId && { locationId: model.billingAddressId }),
  };

  const res = await apiClientBilling.makeRequest({
    method: Method.POST,
    path: '/api/v4/tenants/:tenantId/statements',
    optOutLoadingIndicator: false,
    body: JSON.stringify(body),
  });

  return res.data;
}

async function printStatementPdf({ updatedPdfBody, optOutLoadingIndicator }) {
  const res = await apiClientPdf.makeRequest({
    method: Method.POST,
    path: '/api/v4/tenants/:tenantId/statement',
    optOutLoadingIndicator,
    body: JSON.stringify(updatedPdfBody),
  });

  return res;
}

async function updateStatement({ statementS3Key }) {
  await apiClientBilling.makeRequest({
    method: Method.PUT,
    path: '/api/v2/tenants/:tenantId/statements',
    optOutLoadingIndicator: false,
    body: JSON.stringify({ statements: statementS3Key }),
  });
}

async function deleteStatements(statementIds) {
  await apiClientBilling.makeRequest({
    method: Method.DELETE,
    path: '/api/v2/tenants/:tenantId/statements',
    optOutLoadingIndicator: false,
    body: JSON.stringify({
      statementIds,
    }),
  });
}

export async function print({
  patientId,
  statements,
  addressIds,
  comment,
  preview,
  optOutLoadingIndicator = false,
}) {
  const { billingAddressId, payToAddressId } = addressIds;
  const pdfBody = await buildStatementBody(
    patientId,
    statements,
    preview,
    comment,
    store.getState(),
  );

  const updatedPdfBody = await buildStatementBodyWithLocations({
    body: pdfBody,
    billingAddressId,
    payToAddressId,
  });

  try {
    const res = await printStatementPdf({
      updatedPdfBody,
      optOutLoadingIndicator,
    });

    if (!preview) {
      await updateStatement(res);
    }

    return res.buffer;
  } catch (err) {
    if (!preview) {
      await deleteStatements(statements.map(item => item.id));
    }

    return Promise.reject(Error('Error generating statement'));
  }
}

export async function fetchMany(query) {
  let queryParams;

  if (query.sortParams) {
    const { key, dir } = query.sortParams;
    queryParams = { ...query, sortField: key, sortDir: dir };
    delete queryParams.sortParams;
  }

  const res = await apiClientBilling.makeRequest({
    path: '/api/v2/tenants/:tenantId/statements',
    queryParams,
    optOutLoadingIndicator: false,
  });

  return res;
}

export async function fetchOne(statementId) {
  const res = await apiClientBilling.makeRequest({
    path: '/api/v2/tenants/:tenantId/statements/:statementId',
    replacements: { statementId },
    optOutLoadingIndicator: false,
    responseType: RESPONSE_TYPE.RAW,
  });

  return res.arrayBuffer();
}

async function createBatchRequest({
  model,
  preview,
  optOutLoadingIndicator = false,
  addressIds,
  billingInformation,
  version = 4,
}) {
  const nullProvider = model.providerIds.includes(NO_ASSOCIATED_PROVIDER_ID);
  const providerIds = filterNoAssociatedProviderId(model.providerIds);
  const practiceInformation = store.getState().practiceInformation.item;

  const body = {
    preview,
    addressIds,
    statementBody: {
      agingTable: model.agingTable,
      allCharges: model.allCharges,
      groupStatements: model.groupStatements,
      previousBalance: model.previousBalance,
      nullProvider,
      providers: providerIds,
      dateOfServiceTo: model.dateOfServiceTo,
      dateOfServiceFrom: model.dateOfServiceFrom,
      dueByDate: model.dueByDate,
      includePaymentDetails: model.includePaymentDetails,
      showCarePackageChargesAsPatientPaid:
        model.showCarePackageChargesAsPatientPaid,
      includeCreditCardCutOff: model.includeCreditCardCutOff,
      minimumBalance: model.minimumBalance || 0,
      balanceEmail: model.balanceEmail,
    },
    practiceInformation,
    billingInformation,
    comment: model.comment,
    ...(model.billingAddressId && { locationId: model.billingAddressId }),
  };

  const { billingAddressId, payToAddressId } = addressIds;

  const updatedPdfBody = await buildStatementBodyWithLocations({
    body,
    billingAddressId,
    payToAddressId,
  });

  const path =
    version === 5
      ? '/api/v5/tenants/:tenantId/statements/batches'
      : '/api/v4/tenants/:tenantId/statements/batches';

  return apiClientBilling.makeRequest({
    method: Method.POST,
    path,
    optOutLoadingIndicator,
    body: JSON.stringify(updatedPdfBody),
  });
}

export async function addBatch({
  model,
  preview,
  optOutLoadingIndicator = false,
  addressIds,
  billingInformation,
}) {
  const res = await createBatchRequest({
    model,
    preview,
    optOutLoadingIndicator,
    addressIds,
    billingInformation,
  });

  return res.data;
}

export async function addBatchAsync({
  model,
  preview,
  optOutLoadingIndicator = false,
  addressIds,
  billingInformation,
}) {
  await createBatchRequest({
    model,
    preview,
    optOutLoadingIndicator,
    addressIds,
    billingInformation,
    version: 5,
  });
}

export async function fetchBatch(batchId) {
  const res = await apiClientBilling.makeRequest({
    path: '/api/tenants/:tenantId/statements/batches/:batchId',
    replacements: { batchId },
    optOutLoadingIndicator: false,
    responseType: RESPONSE_TYPE.RAW,
  });

  return res.arrayBuffer();
}

export async function fetchBatches() {
  const res = await apiClientBilling.makeRequest({
    path: '/api/tenants/:tenantId/statements/batches',
  });

  return res;
}

export async function deleteBatch(batchId) {
  const res = await apiClientBilling.makeRequest({
    method: Method.DELETE,
    path: '/api/tenants/:tenantId/statements/batches/:batchId',
    replacements: { batchId },
    optOutLoadingIndicator: false,
  });

  return res;
}

export async function getBatchStatements(batchId, query) {
  let queryParams;

  if (query.sortParams) {
    const { key, dir } = query.sortParams;
    queryParams = { ...query, sortField: key, sortDir: dir };
    delete queryParams.sortParams;
  }
  const res = await apiClientBilling.makeRequest({
    path: '/api/v1/tenants/:tenantId/statements/batch/:batchId',
    queryParams,
    replacements: { batchId },
  });

  return res;
}

export async function removeOrAddStatementFromBatch(
  batchId,
  statementIds,
  excludedFromBatch,
) {
  const res = await apiClientBilling.makeRequest({
    method: Method.PUT,
    path: '/api/v1/tenants/:tenantId/statements/batch/:batchId',
    replacements: { batchId },
    optOutLoadingIndicator: false,
    body: { excludedFromBatch, statementIds },
    responseType: RESPONSE_TYPE.RAW,
  });

  return res;
}
