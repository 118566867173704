import { Popup } from '@neb/popup';
import { EVENT_ROUTE_CHANGE } from '@neb/router';
import { html, css } from 'lit';

import { openDirtyPopup } from '../../../../neb-popup';
import { store } from '../../../../neb-redux/neb-redux-store';
import { Dirty } from '../../../../neb-redux/services/dirty';
import { LayoutService } from '../../../../neb-redux/services/layout';
import { getDocumentTitle } from '../../../../neb-route/neb-route-state';
import { baseStyles } from '../../../../neb-styles/neb-styles';
import { CSS_COLOR_GREY_2 } from '../../../../neb-styles/neb-variables';
import { clearOverlays } from '../../utils/overlay-constants';

export const TRANSITION_DURATION = 2000;

export const ELEMENTS = {
  container: { id: 'container' },
  content: { id: 'content' },
};

export const TRANSITION_SIDE = {
  LEFT: 'left',
  RIGHT: 'right',
};

const CSS_LEFT = css`left`;
const CSS_RIGHT = css`right`;

export default class Overlay extends Popup {
  static get properties() {
    return {
      __result: Object,
      __open: {
        reflect: true,
        type: Boolean,
        attribute: 'open',
      },

      isDirty: Boolean,
      layout: {
        type: String,
        reflect: true,
      },
      side: {
        reflect: true,
        type: String,
      },
      index: {
        type: Number,
        reflect: true,
      },
    };
  }

  constructor() {
    super();

    this.initState();
    this.initHandlers();
  }

  initState() {
    this.__result = undefined;
    this.__dismissAll = false;
    this.__open = false;

    this.isDirty = false;
    this.side = TRANSITION_SIDE.RIGHT;

    this.__dirtyService = new Dirty(
      (hash, nextHash) => hash !== nextHash,
      () => this.isDirty,
    );

    this.__layoutService = new LayoutService(layout => {
      this.layout = layout;
    });
  }

  initHandlers() {
    this.handlers = {
      clickBlocker: e => {
        const ref = this.shadowRoot.getElementById(ELEMENTS.content.id);
        const {
          globalLoading: { count },
        } = store.getState();

        if (ref && !ref.contains(e.target) && !count) {
          e.stopPropagation();
          this.dismissWithBlocker();
        }
      },
      dirty: isDirty => {
        this.isDirty = isDirty;
      },
      dirtyProceed: () => {
        this.__dismissAll = true;
        this.__open = false;
        this.isDirty = false;
        this.result = undefined;
      },
      dismiss: result => {
        document.title = getDocumentTitle(window.location.hash);

        return this.dismiss(result);
      },
      dismissAll: result => this.dismiss(result, true),
      routeChanged: () => {
        if (!this.isDirty) this.dismiss(undefined, true);
      },
      transitionEnd: () => {
        if (!this.__open) {
          this.onClose(this.__result);
          document.title = getDocumentTitle(window.location.hash);
        }

        if (this.__dismissAll) {
          clearOverlays();
        }
      },
    };
  }

  connectedCallback() {
    super.connectedCallback();

    this.__dirtyService.connect();
    this.__layoutService.connect();

    if (this.index > 0) {
      this.__open = true;
    }

    window.addEventListener(EVENT_ROUTE_CHANGE, this.handlers.routeChanged);
    window.addEventListener('neb-dirty-proceed', this.handlers.dirtyProceed);
    this.shadowRoot.addEventListener('mousedown', this.handlers.clickBlocker);
    this.shadowRoot.addEventListener('touchstart', this.handlers.clickBlocker);
  }

  disconnectedCallback() {
    this.shadowRoot.removeEventListener(
      'touchstart',
      this.handlers.clickBlocker,
    );

    this.shadowRoot.removeEventListener(
      'mousedown',
      this.handlers.clickBlocker,
    );

    window.removeEventListener('neb-dirty-proceed', this.handlers.dirtyProceed);
    window.removeEventListener(EVENT_ROUTE_CHANGE, this.handlers.routeChanged);

    this.__layoutService.disconnect();
    this.__dirtyService.disconnect();

    super.disconnectedCallback();
  }

  dismissWithBlocker() {
    this.dismiss();
  }

  async dismiss(result, all = false) {
    this.__dismissAll = all;

    if (this.isDirty) if (await openDirtyPopup()) this.isDirty = false;

    if (!this.isDirty) {
      this.__result = result;

      if (this.index === 0 || this.__dismissAll) {
        this.__open = false;
      } else {
        this.onClose(this.__result);
      }
    }
  }

  firstUpdated() {
    super.firstUpdated();
    this.__open = true;
  }

  static get styles() {
    return [
      super.styles,
      baseStyles,
      css`
        :host {
          width: 100%;
          height: 100%;
        }

        .spacer {
          flex: 1 0 0;
        }

        .container {
          position: relative;
          width: 100%;
          height: 100%;
        }

        .content {
          display: flex;
          position: absolute;
          top: 0;
          height: 100%;
          max-width: 100%;
          background-color: #fff;
          flex-flow: column nowrap;
        }

        :host([layout='small']) .content {
          width: 100%;
        }

        :host([side='${CSS_LEFT}']) .content {
          left: 0;
          transform: translateX(-100%);
          transition: transform 200ms ease-out;
          border-right: 1px solid ${CSS_COLOR_GREY_2};
        }

        :host([side='${CSS_RIGHT}']) .content {
          right: 0;
          transform: translateX(100%);
          transition: transform 200ms ease-out;
          border-left: 1px solid ${CSS_COLOR_GREY_2};
        }

        :host([open][active]) .content,
        :host([open][index]) .content {
          transform: translateX(0);
        }
      `,
    ];
  }

  renderContent() {
    throw new Error('renderContent() not implemented');
  }

  render() {
    return html`
      <div id="${ELEMENTS.container.id}" class="container">
        <div
          id="${ELEMENTS.content.id}"
          class="content"
          @transitionend="${this.handlers.transitionEnd}"
        >
          ${this.renderContent()}
        </div>
      </div>
    `;
  }
}
