import { getEncounterCharges } from '../neb-api-client/src/charting/encounter-charge';
import {
  APPOINTMENT_DISPLAY_STATUSES,
  APPOINTMENT_ACTIONS,
  APPOINTMENT_ACTIONS_ENCOUNTER_CHECK,
  APPOINTMENT_STATUS,
  KEPT_STATUSES,
} from '../neb-lit-components/src/components/scheduling/neb-appointment-options';
import { ELEMENTS } from '../neb-lit-components/src/components/scheduling/neb-appointment-page-view';
import { store } from '../neb-redux/neb-redux-store';

import { parseDate } from './date-util';

export const DEFAULT_FILTERS = {
  status: KEPT_STATUSES.map(status => ({
    label: status,
    data: { id: status },
  })),
  expanded: true,
};

export const ALL_STATUSES = [
  {
    label: 'Active',
    data: { id: 'Active' },
  },
  {
    label: 'Checked-In',
    data: { id: 'Checked-In' },
  },
  {
    label: 'Checked-Out',
    data: { id: 'Checked-Out' },
  },
  {
    label: 'Canceled',
    data: { id: 'Canceled' },
  },
  {
    label: 'Deleted',
    data: { id: 'Deleted' },
  },
  {
    label: 'No-Show',
    data: { id: 'No-Show' },
  },
  {
    label: 'Rescheduled',
    data: { id: 'Rescheduled' },
  },
];

export const getEncounterInfo = async (
  encounter,
  optOutLoadingIndicator = false,
) => {
  const encounterInfo = {
    exists: encounter != null && !encounter.archived,
    signed: !!encounter && encounter.signed,
    charges:
      encounter && encounter.id
        ? await getEncounterCharges(encounter.id, optOutLoadingIndicator)
        : [],
  };

  return encounterInfo;
};

export const getAppointmentActionButtons = (
  appointmentStatus,
  appointmentDate,
  encounterInfo = null,
) => {
  const items = [];

  let actionLabel = '';

  const displayCheckIn =
    appointmentStatus === APPOINTMENT_STATUS.ACTIVE &&
    parseDate(appointmentDate).isBefore(parseDate().endOf('day'));

  actionLabel = displayCheckIn ? APPOINTMENT_ACTIONS.CHECK_IN.action : '';

  if (actionLabel) {
    items.push({
      id: ELEMENTS.buttonCheckIn.id,
      label: APPOINTMENT_ACTIONS.CHECK_IN.label,
      class: 'button button-check-in',
      isOutlined: false,
      actionName: actionLabel,
      encounterCheck: { allowed: true },
    });
  }

  actionLabel =
    appointmentStatus === APPOINTMENT_STATUS.CHECKED_IN
      ? APPOINTMENT_ACTIONS.CHECK_OUT.action
      : '';

  if (actionLabel) {
    items.push({
      id: ELEMENTS.buttonCheckOut.id,
      label: APPOINTMENT_ACTIONS.CHECK_OUT.label,
      class: 'button button-check-out',
      isOutlined: false,
      actionName: actionLabel,
      encounterCheck: { allowed: true },
    });
  }

  actionLabel = displayCheckIn ? APPOINTMENT_ACTIONS.NO_SHOW.action : '';

  if (actionLabel) {
    items.push({
      id: ELEMENTS.buttonNoShow.id,
      label: APPOINTMENT_ACTIONS.NO_SHOW.label,
      class: 'button button-no-show',
      isOutlined: true,
      actionName: actionLabel,
      encounterCheck:
        encounterInfo && encounterInfo.exists
          ? APPOINTMENT_ACTIONS_ENCOUNTER_CHECK.NO_SHOW
          : { allowed: true },
    });
  }

  return items;
};

const createMenuItem = appointmentAction => ({
  id: ELEMENTS.actionMenu.id,
  label: appointmentAction.label,
  class: ELEMENTS.actionMenu.id,
  isOutlined: false,
  actionName: appointmentAction.action,
  title: appointmentAction.title,
  message: appointmentAction.message,
  encounterCheck: { allowed: true },
});

const getMenuItem = (appointmentAction, encounterInfo) => {
  const menuItem = createMenuItem(appointmentAction);

  if (encounterInfo && encounterInfo.exists) {
    if (appointmentAction.action === APPOINTMENT_ACTIONS.CANCEL.action) {
      return {
        ...menuItem,
        encounterCheck: APPOINTMENT_ACTIONS_ENCOUNTER_CHECK.CANCEL,
      };
    }

    if (appointmentAction.action === APPOINTMENT_ACTIONS.DELETE.action) {
      return {
        ...menuItem,
        encounterCheck: APPOINTMENT_ACTIONS_ENCOUNTER_CHECK.DELETE,
      };
    }
  }

  return menuItem;
};

export const getAppointmentActionMenuItems = (
  appointmentStatus,
  recurrenceEventId = null,
  encounterInfo = null,
  touchedPastAppointment = false,
  confirmed = false,
  arrived = false,
) => {
  const items = [];

  if (appointmentStatus === APPOINTMENT_STATUS.ACTIVE) {
    items.push(createMenuItem(APPOINTMENT_ACTIONS.EDIT));
    items.push(createMenuItem(APPOINTMENT_ACTIONS.RESCHEDULE));

    if (confirmed) {
      items.push(createMenuItem(APPOINTMENT_ACTIONS.UNCONFIRM));
    } else {
      items.push(createMenuItem(APPOINTMENT_ACTIONS.CONFIRM));
    }

    if (arrived) {
      items.push(createMenuItem(APPOINTMENT_ACTIONS.RETURN_TO_SCHEDULED));
    } else {
      items.push(createMenuItem(APPOINTMENT_ACTIONS.ARRIVED));
    }

    items.push(getMenuItem(APPOINTMENT_ACTIONS.CANCEL, encounterInfo));
    items.push(getMenuItem(APPOINTMENT_ACTIONS.DELETE, encounterInfo));

    if (
      recurrenceEventId &&
      !touchedPastAppointment &&
      appointmentStatus !== APPOINTMENT_STATUS.CHECKED_IN
    ) {
      items.push(createMenuItem(APPOINTMENT_ACTIONS.DELETE_SERIES));
    }
  } else if (appointmentStatus === APPOINTMENT_STATUS.NO_SHOW) {
    items.push(createMenuItem(APPOINTMENT_ACTIONS.RESCHEDULE));
  } else if (appointmentStatus === APPOINTMENT_STATUS.CHECKED_IN) {
    items.push(createMenuItem(APPOINTMENT_ACTIONS.EDIT));
    items.push(createMenuItem(APPOINTMENT_ACTIONS.RESCHEDULE));
    items.push(createMenuItem(APPOINTMENT_ACTIONS.RETURN_TO_SCHEDULED));

    items.push(createMenuItem(APPOINTMENT_ACTIONS.CHANGE_ROOM));
  } else if (appointmentStatus === APPOINTMENT_STATUS.CHECKED_OUT) {
    items.push(createMenuItem(APPOINTMENT_ACTIONS.EDIT));
    items.push(createMenuItem(APPOINTMENT_ACTIONS.RETURN_TO_SCHEDULED));
  }

  return items;
};

export const getAppointmentDisplayStatus = appointmentStatus => {
  const index = APPOINTMENT_DISPLAY_STATUSES.findIndex(
    x => x.status === appointmentStatus,
  );
  return index < 0 ? '' : APPOINTMENT_DISPLAY_STATUSES[index].name;
};

export function getHistoryApptFiltersKey(type) {
  const {
    session: {
      item: { tenantId, id: userId },
    },
  } = store.getState();

  return `history-appointment-filters:${tenantId}:${userId}:${type}`;
}

export function getHistoryApptStatusesFromLocalStorage(type) {
  const historyAppointmentFilter = JSON.parse(
    localStorage.getItem(getHistoryApptFiltersKey(type)),
  );

  return historyAppointmentFilter || DEFAULT_FILTERS;
}

export function getApptFiltersKey() {
  const {
    session: {
      item: { tenantId, id: userId },
    },
  } = store.getState();

  return `appointment-filters:${tenantId}:${userId}`;
}

export function getApptStatusesFromLocalStorage() {
  const appointmentFilter = JSON.parse(
    localStorage.getItem(getApptFiltersKey()),
  );
  return appointmentFilter || DEFAULT_FILTERS;
}
