import { format, parse } from 'date-fns';
import moment from 'moment-timezone';

import { toTimeDisplayValue } from './util/nebDatetimeUtil';

export const FULL_DATE_FORMAT = 'dddd, MMMM D, YYYY';
export const DATE_MONTH_FORMAT = 'dddd, MMMM D';
export const SHORT_DATE_AND_TIME_FORMAT = 'M/D/YYYY h:mm A';
export const SHORT_DATE_AND_SHORT_TIME_FORMAT = 'M/D/YY h:mm A';
export const SHORT_WEEKDAY_DATE_AND_TIME_FORMAT = 'ddd MM/DD/YY h:mm A';
export const MONTH_DAY_YEAR_TIME = 'MM/DD/YYYY h:mm A';
export const ISO_DATE_FORMAT = 'YYYY-MM-DD';
export const ISO_DATE_TIME_FORMAT = 'YYYY-MM-DD hh:mm:ss';
export const MONTH_DAY_YEAR = 'MM/DD/YYYY';

/**
 * Format value in the form of a phone number (555) 555-5555.
 *
 * @param {*} value - The number to format.
 */
export function toPhoneDisplayValue(value) {
  if (value == null) {
    return '';
  }

  const pattern = /^(\(\d{3}\)) \d{3}-\d{4}$/;
  const valid = pattern.test(value);

  if (!valid) {
    const valStr = value.toString();
    const match = valStr.match(/\d/g);

    if (match && match.length === 10) {
      const result = `(${match[0]}${match[1]}${match[2]}) ${match[3]}${
        match[4]
      }${match[5]}-${match[6]}${match[7]}${match[8]}${match[9]}`;

      return result;
    }
  }

  return value;
}

/**
 * Calculate age based on the given ISO date string.
 *
 * @param {*} dateString - The ISO date string to format in ISO 8601 syntax (YYYY-MM-DD)
 */
export function toDisplayAge(bday) {
  let age = moment().diff(bday, 'years');

  if (!bday) {
    age = 0;
  }

  age = age < 0 ? 0 : age;
  return `${age} yr${age !== 1 ? 's' : ''} old`;
}

/**
 * Format the ISO date string into a given date format.
 *
 * @param {*} dateString - The ISO date string to format in ISO 8601 syntax (YYYY-MM-DD)
 * @param {*} formatString - The format string (e.g MM/DD/YYYY).
 */
export function toFormatDate(dateString, formatString) {
  if (!dateString) {
    return '';
  }

  const formattedDate = format(parse(dateString), formatString);
  return formattedDate;
}

/**
 * Format the ISO date string into a full date format of dddd, MMMM D, YYYY.
 * Like: Monday, April 16, 2018
 *
 * @param {*} dateString - The ISO date string to format in ISO 8601 syntax (YYYY-MM-DD)
 */
export function toFormatFullDate(dateString) {
  return toFormatDate(dateString, FULL_DATE_FORMAT);
}

/**
 * Return true, if the given ISO date string is in the future.
 *
 * @param {*} dateString - The ISO date string to format in ISO 8601 syntax (YYYY-MM-DD).
 */
export function isFutureDate(dateString) {
  return (
    parse(dateString, 'YYYY-MM-DD', new Date()).getTime() > new Date().getTime()
  );
}

/**
 * Return true, if the given ISO date string is before 1900.
 *
 * @param {*} dateString - The ISO date string to format in ISO 8601 syntax (YYYY-MM-DD).
 */
export function isTooOld(dateString) {
  return (
    parse(dateString, 'YYYY-MM-DD', new Date()).getTime() <
    new Date('1900/01/01').getTime()
  );
}

/**
 * Converts array of raw numeric values for time into an array of displayable values.
 * @param {Array} arrayNumericStartTimes input like [13.5, 13.75, 14]
 * @returns {Array} output like [{ 'value': 13.5, 'display': '1:30 PM' }, { 'value': 13.75, 'display': '1:45 PM' }, { 'value': 14, 'display': '2:00 PM' }];
 */
export function timesToDisplayFormat(arrayNumericStartTimes) {
  if (!arrayNumericStartTimes) {
    return null;
  }

  const result = arrayNumericStartTimes.map(x => ({
    value: x,
    display: toTimeDisplayValue(x),
  }));
  return result;
}

function formatAbbreviatedDuration(hours, minutes) {
  const hourName = hours === 1 ? 'hr' : 'hrs';

  if (hours > 0 && minutes > 0) {
    return `${hours} ${hourName} ${minutes} min`;
  }

  if (hours > 0) {
    return `${hours} ${hourName}`;
  }
  return `${minutes} min`;
}

/**
 * Format duration with full units (Hour/Hours, Minutes)
 * @param {number} hours Number of hours
 * @param {number} minutes Number of minutes
 * @returns {string} Formatted duration string with full units
 */
function formatFullDuration(hours, minutes) {
  const hourName = hours === 1 ? 'Hour' : 'Hours';

  if (hours > 0 && minutes > 0) {
    return `${hours} ${hourName} ${minutes} Minutes`;
  }

  if (hours > 0) {
    return `${hours} ${hourName}`;
  }
  return `${minutes} Minutes`;
}

/**
 * Send in a duration and it will return a formatted string (1 Hour 10 Minutes).
 * @param {durationValue} durationValue integer as milliseconds
 * @param {boolean} useAbbreviatedUnits optional flag to use abbreviated units (hr/min)
 */
export function computeTime(durationValue, useAbbreviatedUnits = false) {
  const millisecConstant = 1000;
  const multiplier = 60;
  const minutes = parseInt(
    (durationValue / (millisecConstant * multiplier)) % multiplier,
    10,
  );
  const hours = parseInt(
    (durationValue / (millisecConstant * multiplier * multiplier)) % multiplier,
    10,
  );

  return useAbbreviatedUnits
    ? formatAbbreviatedDuration(hours, minutes)
    : formatFullDuration(hours, minutes);
}

/**
 * Converts the date object into a string value according to iCal Format
 * @param {*} date - The Date object
 */
export function toIcalStringDate(date) {
  return format(date, 'YYYYMMDDTHHmm00');
}
