import '../../../../neb-styles/neb-icons';
import '../../../../../src/components/misc/neb-icon';
import './neb-encounter-diagnosis-table-row';
import '../../../../neb-lit-components/src/components/neb-effect';

import { LitElement, html } from 'lit';

import { getChartingPermissions } from '../../../../neb-api-client/src/permissions-api-client';
import { NebDnDVerticalOrder } from '../../../../neb-lit-components/src/components/neb-dnd-vertical-order';
import { renderNebTableStyles } from '../../../../neb-styles/neb-table-styles';
import {
  CSS_SPACING,
  CSS_COLOR_GREY_1,
  CSS_FONT_SIZE_BODY,
  CSS_COLOR_WHITE,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_DISABLED,
} from '../../../../neb-styles/neb-variables';
import {
  getTableRowInfo,
  formatIcdSelectorIdCode,
} from '../../../../neb-utils/neb-charting-util';

export const ELEMENTS = {
  noDiagnosisText: {
    id: 'no-diagnosis-text',
  },
  tableRows: {
    selector: 'neb-encounter-diagnosis-table-row',
  },
  icd10WarningSpacer: {
    id: 'icd10-warning-spacer',
  },
  removeAllIcon: {
    id: 'remove-all-icon',
  },
};
class NebEncounterDiagnosisTable extends NebDnDVerticalOrder(LitElement) {
  static get properties() {
    return {
      emptyMessage: String,
      savedCodes: Array,
      savedProblems: Array,
      showAddToProblems: Boolean,
      __hasChartingPermissions: { type: Boolean },
    };
  }

  constructor() {
    super();

    this.__initHandlers();

    this.savedCodes = [];
    this.savedProblems = [];
    this.showAddToProblems = false;
    this.emptyMessage = '';

    this.onAddToProblems = () => {};

    this.onRemoveDiagnosis = () => {};

    this.onRemoveAllDiagnoses = () => {};

    this.onReorderDiagnoses = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      addToProblems: icd10Info => this.onAddToProblems(icd10Info),
      removeDiagnosis: icd10Info => this.onRemoveDiagnosis(icd10Info),
      removeAllDiagnoses: () => this.onRemoveAllDiagnoses(),
    };
  }

  firstUpdated() {
    super.firstUpdated();
    this.__hasChartingPermissions = getChartingPermissions();
  }

  __isProblemAdded(icd10Info) {
    return !!this.savedProblems.find(
      p => p.code === icd10Info.code && p.status !== 'resolved',
    );
  }

  __renderStyles() {
    return html`
      ${renderNebTableStyles()}

      <style>
        .neb-table-cell {
          padding-top: 0;
        }

        .drag-handle-header {
          flex: 0 0 20px;
        }

        .icd10-warning {
          flex: 0 0 24px;
        }

        .icd-code-header {
          max-width: 140px;
        }

        .remove-diagnosis-header {
          text-align: right;
        }

        .remove-icon {
          cursor: pointer;
          width: 20px;
          height: 20px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .remove-icon[disabled] {
          fill: ${CSS_COLOR_DISABLED};
          pointer-events: none;
        }

        .problem-header {
          min-width: 200px;
          text-align: left;
        }

        .no-diagnosis-text {
          margin: ${CSS_SPACING};
          font-size: ${CSS_FONT_SIZE_BODY};
          font-style: italic;
          color: ${CSS_COLOR_GREY_1};
        }

        :host([dragging]) [reorder-row] {
          background-color: ${CSS_COLOR_WHITE};
        }

        :host {
          display: flex;
          flex-direction: column;
          user-select: none;
          -webkit-user-select: none;
          -moz-user-select: none;
        }
      </style>
    `;
  }

  __renderDragHandleHeader() {
    return this.__hasChartingPermissions
      ? html` <div class="neb-table-cell drag-handle-header"></div> `
      : '';
  }

  __renderIcd10WarningColumn() {
    return this.__hasChartingPermissions
      ? html`
          <div
            id="${ELEMENTS.icd10WarningSpacer.id}"
            class="icd10-warning"
          ></div>
        `
      : '';
  }

  __renderProblemListHeader() {
    return this.showAddToProblems
      ? html`
          <div class="neb-table-cell neb-table-medium-width problem-header">
            Problem List
          </div>
        `
      : '';
  }

  __renderRemoveAllIcon() {
    return this.savedCodes.length && this.__hasChartingPermissions
      ? html`
          <neb-icon
            id="${ELEMENTS.removeAllIcon.id}"
            class="remove-icon"
            icon="neb:minus"
            @click="${this.__handlers.removeAllDiagnoses}"
          ></neb-icon>
        `
      : '';
  }

  render() {
    return html`
      ${this.__renderStyles()}

      <div class="neb-table-row neb-table-header">
        ${this.__renderDragHandleHeader()} ${this.__renderIcd10WarningColumn()}
        <div class="neb-table-cell neb-table-small-width icd-code-header">
          ICD10
        </div>
        <div class="neb-table-cell neb-table-large-width">Description</div>
        ${this.__renderProblemListHeader()}

        <div class="neb-table-cell remove-diagnosis-header">
          ${this.__renderRemoveAllIcon()}
        </div>
      </div>
      <div>
        ${this.savedCodes.length > 0
          ? this.__renderResults()
          : html`
              <div
                id="${ELEMENTS.noDiagnosisText.id}"
                class="no-diagnosis-text"
              >
                ${this.emptyMessage ||
                'There are no diagnoses for this encounter.'}
              </div>
            `}
      </div>
    `;
  }

  updated() {
    this._items = this.savedCodes;

    this._update();
  }

  _afterDragEnd() {
    this.onReorderDiagnoses(this.savedCodes);
  }

  __renderResults() {
    return this.savedCodes.map(code => this.__renderSearchResultRow(code));
  }

  __renderSearchResultRow(icd10Info) {
    const isAdded = this.__isProblemAdded(icd10Info);

    const { isNew } = getTableRowInfo(icd10Info);
    return html`
      <neb-effect ?color="${isNew}">
        <neb-encounter-diagnosis-table-row
          id="icd10-${formatIcdSelectorIdCode(icd10Info.code)}"
          reorder-row
          .icd10Info="${icd10Info}"
          .isInProblemList="${isAdded}"
          .onAddToProblems="${this.__handlers.addToProblems}"
          .onRemoveDiagnosis="${this.__handlers.removeDiagnosis}"
          .showAddToProblems="${this.showAddToProblems}"
          ?hasChartingPermissions="${this.__hasChartingPermissions}"
        ></neb-encounter-diagnosis-table-row>
      </neb-effect>
    `;
  }
}
customElements.define(
  'neb-encounter-diagnosis-table',
  NebEncounterDiagnosisTable,
);
