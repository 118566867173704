import { store } from '../../packages/neb-redux/neb-redux-store';
import { CSS_COLOR_BLUE, getHexColorFromCSS } from '../styles';

export const HOT_BUTTON_CATEGORY_TYPE = {
  CHARGES: 'charges',
  DIAGNOSES: 'diagnoses',
};

export const HOT_BUTTON_SINGULAR_TYPE = {
  charges: 'charge',
  diagnoses: 'diagnosis',
};

export const HOT_BUTTON_ERROR_MESSAGE = type =>
  type === HOT_BUTTON_CATEGORY_TYPE.CHARGES
    ? 'Add charge to save Hot Buttons'
    : 'Add diagnosis to save Hot Buttons';

const TYPE_KEYS = {
  [HOT_BUTTON_CATEGORY_TYPE.CHARGES]: ['chargeId', 'chargeId'],
  [HOT_BUTTON_CATEGORY_TYPE.DIAGNOSES]: ['code', 'diagnosisCode'],
};

function isItemAdded(type, item, existingItems) {
  const [existingKey, hotButtonKey] = TYPE_KEYS[type];

  return !!existingItems.find(
    existingItem => existingItem[existingKey] === item[hotButtonKey],
  );
}

const formatHotButton = button => {
  let formattedButton = {
    name: button.name,
    color: button.color,
    position: button.content.position,
  };

  formattedButton = button.content.chargeId
    ? (formattedButton = {
        ...formattedButton,
        chargeId: button.content.chargeId,
      })
    : {
        ...formattedButton,
        diagnosisCode: button.content.diagnosisCode,
        shortDescription: button.content.shortDescription,
      };

  return formattedButton;
};

const formatHotButtons = buttons =>
  buttons.map(button => formatHotButton(button));

export const mapCategoryModelToRaw = model => {
  const category = {
    name: model.name,
    categoryType: model.type,
    indexOrder: model.indexOrder || 0,
    active: model.active,
  };

  const buttons = model.buttons || [];
  const filteredButtons = buttons.filter(item => item);
  const formattedButtons = formatHotButtons(filteredButtons);

  category[model.type] = formattedButtons;

  return category;
};

export const mapCategoryItemsToButtonModel = (
  items,
  type,
  existingItems = [],
  activeCharges = [],
) => {
  const buttons = [...Array(25).fill(null)];
  items.forEach(item => {
    const settingsCharge = activeCharges.find(x => x.id === item.chargeId);
    buttons[item.position] = {
      name: item.name,
      disabled: isItemAdded(type, item, existingItems),
      color: item.color || getHexColorFromCSS(CSS_COLOR_BLUE),
      content:
        type === HOT_BUTTON_CATEGORY_TYPE.CHARGES
          ? {
              position: item.position,
              chargeId: item.chargeId,
              ...(settingsCharge || item.charge),
            }
          : item,
    };
  });

  return buttons;
};

export const getDefaultProvider = (providerItems, encounterProviderId) => {
  const {
    session: {
      item: { id: loggedInUserId },
    },
  } = store.getState();

  const loggedInProvider = providerItems.find(
    item => item.id === loggedInUserId,
  );

  if (loggedInProvider) return loggedInProvider;

  const encounterProvider = providerItems.find(
    item => item.id === encounterProviderId,
  );

  return encounterProvider;
};

const formatCheckboxCategory = category => ({
  id: category.id,
  name: category.name,
  active: category.active,
  checked: false,
});

export const formatCheckboxCategories = categories =>
  categories.reduce((acc, category) => {
    acc.push(formatCheckboxCategory(category));
    return acc;
  }, []);
