import './neb-encounter-charges-list-item';
import '../../../../neb-styles/neb-styles';
import '../../../../neb-lit-components/src/components/neb-effect';
import '../../../../neb-lit-components/src/components/neb-toggle-button';

import { LitElement, html, css } from 'lit';

import { NebDnDVerticalOrder } from '../../../../neb-lit-components/src/components/neb-dnd-vertical-order';
import {
  CSS_COLOR_WHITE,
  CSS_COLOR_GREY_1,
  CSS_BORDER_GREY_2,
  CSS_SPACING,
} from '../../../../neb-styles/neb-variables';
import { getTableRowInfo } from '../../../../neb-utils/neb-charting-util';

export const ELEMENTS = {
  addAllButton: { id: 'add-all' },
  headerDescription: { id: 'description-header' },
  headerDiagnosisPointer: { id: 'diagnosis-pointer-header' },
  headerFeeSchedule: { id: 'fee-schedule-header' },
  headerModifiers: { id: 'modifiers-header' },
  headerProcedure: { id: 'procedure-header' },
  headerTable: { id: 'table-header' },
  headerUnits: { id: 'units-header' },
  message: {
    id: 'message',
    text: {
      noCharges: 'There are no charges for this encounter.',
      noResults: 'No results found.',
    },
  },
  listItems: {
    selector: 'neb-encounter-charges-list-item',
    idPrefix: 'row-',
  },
};

const BUTTON_TEXT = 'Add All Charges';
const BUTTON_TOGGLED_TEXT = 'All Charges Added';

class NebEncounterChargesListOld extends NebDnDVerticalOrder(LitElement) {
  static get properties() {
    return {
      items: Array,
      activeCharges: Array,
      allDiagnosis: Array,
      message: html,
      showDiagnosis: {
        type: Boolean,
        attribute: 'show-diagnosis',
      },
      showReorder: {
        type: Boolean,
        attribute: 'show-reorder',
      },
      showAddButton: {
        type: Boolean,
        attribute: 'show-add-button',
        reflect: true,
      },
      showAddAllButton: {
        type: Boolean,
        attribute: 'show-add-all-button',
        reflect: true,
      },
      readOnly: {
        type: Boolean,
        attribute: 'read-only',
        reflect: true,
      },
      priorCharge: {
        type: Boolean,
        attribute: 'prior-charge',
        reflect: true,
      },
      hasDHChargeUnitsFF: {
        type: Boolean,
      },
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  updated() {
    this._items = this.items;
    this._verticalOffset = -36;

    this._update();
  }

  static get styles() {
    return css`
      .table {
        display: flex;
        flex-direction: column;
      }
      .table-row {
        display: flex;
        flex-direction: row;
        border-bottom: ${CSS_BORDER_GREY_2};
        padding: 4px 0;
        box-sizing: border-box;
      }
      :host([dragging]) .table-row {
        background-color: ${CSS_COLOR_WHITE};
      }
      :host([read-only]) .table-row {
        padding: 8px 0;
      }
      .table-header {
        font-weight: bold;
        line-height: 53px;
        overflow: hidden;
        border-bottom: ${CSS_BORDER_GREY_2};
      }
      :host([read-only]) .table-header {
        align-items: center;
        line-height: 44px;
      }
      .add-charge,
      .procedure {
        padding-left: ${CSS_SPACING};
      }
      .reorder-charge {
        max-width: 50px;
      }

      .column-1,
      .procedure {
        flex: 1;
      }
      .column-2 {
        flex: 2;
      }
      .column-3 {
        flex: 3;
      }
      .add-charge {
        min-width: 210px;
      }
      .procedure {
        min-width: 115px;
      }
      :host([show-add-button]) .procedure {
        padding-left: 0;
      }
      :host([prior-charge]) .add-charge {
        height: 30px;
      }
      :host([prior-charge]) .procedure {
        min-width: 90px;
      }
      :host([prior-charge]) .description {
        flex: 2.5;
      }
      :host([prior-charge]) .modifiers {
        min-width: 110px;
        margin-left: 50px;
      }
      :host([prior-charge]) .units {
        padding-right: 15px;
      }
      .text-caption {
        margin: ${CSS_SPACING};
        color: ${CSS_COLOR_GREY_1};
        font-style: italic;
      }
      .diagnosis {
        flex-basis: 60px;
      }
      .modifiers {
        min-width: 190px;
      }
      .settings-link {
        color: inherit;
        text-decoration: underline;
        cursor: pointer;
      }
      .description {
        padding-right: 10px;
      }

      .fee-schedule {
        padding-right: ${CSS_SPACING};
      }
    `;
  }

  render() {
    return html`
      <div class="table">
        <div class="table-row table-header" id="${ELEMENTS.headerTable.id}">
          ${this.__renderAddChargeHeader()}
          ${this.showReorder
            ? html` <span class="column-1 reorder-charge"></span> `
            : ''}
          <span id="${ELEMENTS.headerProcedure.id}" class="column-1 procedure"
            >Procedure
          </span>
          <span
            id="${ELEMENTS.headerDescription.id}"
            class="column-3 description"
            >Description
          </span>
          <span id="${ELEMENTS.headerModifiers.id}" class="column-2 modifiers"
            >Modifiers
          </span>
          ${this.__renderUnitsHeader()}${this.__renderFeeScheduleHeader()}${this.__renderDiagnosisPointerHeader()}
          ${this.showDiagnosis ? html` <span class="column-2"></span> ` : ''}
        </div>
        ${this.items && this.items.length > 0
          ? this.__renderList()
          : html`
              <p
                id="${ELEMENTS.message.id}"
                class="text-caption neb-font-regular-secondary"
              >
                ${this.message}
              </p>
            `}
      </div>
    `;
  }

  __renderAddChargeHeader() {
    return this.showAddButton
      ? html`
          <span class="column-1 add-charge"
            >${this.__renderAddAllChargesButton()}</span
          >
        `
      : '';
  }

  __renderAddAllChargesButton() {
    return this.showAddAllButton
      ? html`
          <neb-toggle-button
            id="${ELEMENTS.addAllButton.id}"
            .buttonText="${this.__isAddAllCharges()
              ? BUTTON_TOGGLED_TEXT
              : BUTTON_TEXT}"
            .onClick="${this.__handlers.addAllCharges}"
            .isToggled="${this.__isAddAllCharges()}"
          ></neb-toggle-button>
        `
      : '';
  }

  __renderUnitsHeader() {
    return !(this.readOnly && !this.priorCharge) || this.hasDHChargeUnitsFF
      ? html`
          <span id="${ELEMENTS.headerUnits.id}" class="column-1 units"
            >Units</span
          >
        `
      : '';
  }

  __renderFeeScheduleHeader() {
    return !this.priorCharge
      ? html`
          <span
            id="${ELEMENTS.headerFeeSchedule.id}"
            class="column-1 fee-schedule"
            >Fee Schedule
          </span>
        `
      : '';
  }

  __renderDiagnosisPointerHeader() {
    return this.showDiagnosis
      ? html`
          <span
            id="${ELEMENTS.headerDiagnosisPointer.id}"
            class="column-1 diagnosis"
            >Diagnosis Pointer
          </span>
        `
      : '';
  }

  __renderList() {
    return this.items.map((item, index) => {
      const { isNew, obj: model } = getTableRowInfo(item);
      return html`
        <neb-effect ?color="${isNew}">
          <neb-encounter-charges-list-item
            id="${ELEMENTS.listItems.idPrefix}${index}"
            class="table-row"
            reorder-row
            .model="${model}"
            .allDiagnosis="${this.allDiagnosis}"
            .activeCharges="${this.activeCharges}"
            .hasDHChargeUnitsFF="${this.hasDHChargeUnitsFF}"
            ?showDiagnosis="${this.showDiagnosis}"
            ?showAddButton="${this.showAddButton}"
            ?showReorder="${this.showReorder}"
            ?readOnly="${this.readOnly}"
            ?priorCharge="${this.priorCharge}"
            @mousedown="${this.__encounterChargesListItemDrag}"
          ></neb-encounter-charges-list-item>
        </neb-effect>
      `;
    });
  }

  __isAddAllCharges() {
    const allInactive = this.items.every(
      ({ chargeId }) => !this.__isChargeActive(chargeId),
    );
    if (allInactive) return false;
    return this.items.length > 0
      ? this.items.every(({ isEncounter, chargeId }) =>
          this.__isChargeActive(chargeId) ? isEncounter : true,
        )
      : false;
  }

  __initState() {
    this.items = [];
    this.activeCharges = [];
    this.allDiagnosis = [];
    this.showDiagnosis = false;
    this.showAddButton = false;
    this.showAddAllButton = false;
    this.showReorder = false;
    this.readOnly = false;
    this.priorCharge = false;

    this.hasDHChargeUnitsFF = false;

    this.onAllPriorEncounterChargesSelected = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      addAllCharges: () => {
        const allNewCharges = this.items.filter(
          ({ isEncounter, chargeId }) =>
            !isEncounter && this.__isChargeActive(chargeId),
        );
        this.onAllPriorEncounterChargesSelected(allNewCharges);
        this.requestUpdate();
      },
    };
  }

  _afterDragEnd() {
    this.dispatchEvent(
      new CustomEvent('neb-encounter-charge-reordered', {
        detail: {
          items: this.items,
        },
        bubbles: true,
        composed: true,
      }),
    );
  }

  __encounterChargesListItemDrag(e) {
    if (this._isDragging) {
      e.preventDefault();
    }
  }

  __isChargeActive(chargeId) {
    return !!this.activeCharges.find(c => c.id === chargeId);
  }

  async validateList() {
    await this.updateComplete;
    this.shadowRoot
      .querySelectorAll('neb-encounter-charges-list-item')
      .forEach(item => item.validate());
  }
}

customElements.define(
  'neb-encounter-charges-list-old',
  NebEncounterChargesListOld,
);
