import '../neb-popup-header';
import '../../../../neb-patient/src/components/neb-patient-history-appointments';
import '../patients/neb-patient-summary-controller';

import { html, css } from 'lit';

import {
  CSS_SPACING,
  CSS_BORDER_GREY_2,
  OVERLAY_WIDTH_EXTRA_LARGE,
} from '../../../../../src/styles';
import {
  FEATURE_FLAGS,
  hasFeatureOrBeta,
} from '../../../../neb-utils/feature-util';

import Overlay from './neb-overlay';

export const ELEMENTS = {
  patientSummary: {
    id: 'patient-info',
  },
  popupHeader: {
    id: 'popup-header',
  },
  patientAppointment: {
    id: 'patient-appointment',
  },
};

export const APPOINTMENT_TABLE_TYPES = {
  PAST: 'PAST',
  FUTURE: 'FUTURE',
  ALL: 'ALL',
};

class NebOverlayAppointmentHistory extends Overlay {
  static get properties() {
    return {
      __hasFilteredAppointmentsPrintFF: {
        type: Boolean,
        reflect: true,
        attribute: 'print-ff',
      },
    };
  }

  initState() {
    super.initState();
    this.__hasFilteredAppointmentsPrintFF = false;
    this.model = {
      patientId: '',
      filter: '',
      patientName: {
        first: '',
        last: '',
      },
    };
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      dismissFromSummary: () => {
        if (this.index !== 0) {
          this.handlers.dismiss();
        }
      },
    };
  }

  async connectedCallback() {
    super.connectedCallback();

    this.__hasFilteredAppointmentsPrintFF = await hasFeatureOrBeta(
      FEATURE_FLAGS.OWL_FILTERED_APPOINTMENTS_PRINT,
    );
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          display: flex;
          flex-direction: row;
        }

        :host([layout='small']) .content {
          flex-direction: column;
        }

        .demographic {
          padding: ${CSS_SPACING};
          border-right: ${CSS_BORDER_GREY_2};
        }

        :host([layout='small']) .demographic {
          max-width: 100%;
          padding: 0;
          border-right: none;
        }

        .appointment-history {
          padding: ${CSS_SPACING};
          overflow-y: auto;
          width: 710px;
        }

        :host([print-ff]) .appointment-history {
          width: ${OVERLAY_WIDTH_EXTRA_LARGE};
          overflow-x: hidden;
        }

        .popup-header {
          margin-bottom: ${CSS_SPACING};
        }

        :host(:not([layout='large'])) .appointment-history {
          width: auto;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <neb-patient-summary-controller
        id="${ELEMENTS.patientSummary.id}"
        class="demographic"
        slot="info-bar"
        .patientId="${this.model.patientId}"
        .onCloseAppointmentsHistory="${this.handlers.dismissFromSummary}"
      >
      </neb-patient-summary-controller>
      <div class="appointment-history">
        <neb-popup-header
          id="${ELEMENTS.popupHeader.id}"
          class="popup-header"
          title="Appointment History"
          .onCancel="${this.handlers.dismiss}"
        ></neb-popup-header>

        <neb-patient-history-appointments
          id="${ELEMENTS.patientAppointment.id}"
          .layout="${this.layout}"
          .active="${this.__open}"
          .patientId="${this.model.patientId}"
          .patientName="${this.model.patientName}"
          .filter="${this.model.filter}"
        ></neb-patient-history-appointments>
      </div>
    `;
  }
}

window.customElements.define(
  'neb-overlay-appointment-history',
  NebOverlayAppointmentHistory,
);
