import { testConnection } from '../../../neb-api-client/src/files-api-client';
import { openSuccess, openError } from '../../../neb-dialog/neb-banner-state';
import { store } from '../../../neb-redux/neb-redux-store';

const SFTP_CSS = Object.freeze({
  NOT_TESTED: '',
  ERROR_ROUTING: 'issue',
  ERROR_AUTHENTICATION: 'issue',
  ERROR_SERVER: 'issue',
  SUCCESS: 'success',
  WARNING: 'warning',
});

export function getSftpStatusIcon(status) {
  switch (status) {
    case SFTP_CSS.ERROR_ROUTING:
      return 'neb:error';

    case SFTP_CSS.ERROR_AUTHENTICATION:
      return 'neb:error';

    case SFTP_CSS.ERROR_SERVER:
      return 'neb:error';

    case SFTP_CSS.WARNING:
      return 'neb:rteWarning';

    case SFTP_CSS.SUCCESS:
      return 'neb:checkCircle';

    case SFTP_CSS.NOT_TESTED:
      return '';

    default:
      return 'neb:rteWarning';
  }
}

export const CONNECTION_STATUS = {
  SUCCESS: 'Connection Success',
  AUTH_FAILED: 'Connection Failed: Check Authentication',
  ROUTING_FAILED: 'Connection Failed: Check Routing',
  SERVER_FAILED: 'Connection Failed: Check Server',
  DEFAULT: 'Connection Failed',
};

export async function testClearinghouseConnection({
  id,
  host,
  username,
  port,
  paths,
  onSuccess = () => {},
  onError = () => {},
}) {
  try {
    const res = await testConnection({
      id,
      host,
      username,
      port,
      paths,
    });

    switch (res.connectionStatus) {
      case CONNECTION_STATUS.SUCCESS:
        store.dispatch(openSuccess(CONNECTION_STATUS.SUCCESS));
        onSuccess(SFTP_CSS.SUCCESS);
        return { status: SFTP_CSS.SUCCESS, icon: 'success' };

      case CONNECTION_STATUS.AUTH_FAILED:
        store.dispatch(openError(CONNECTION_STATUS.AUTH_FAILED));
        onError(SFTP_CSS.ERROR_AUTHENTICATION);
        return { status: SFTP_CSS.ERROR_AUTHENTICATION, icon: 'error' };

      case CONNECTION_STATUS.ROUTING_FAILED:
        store.dispatch(openError(CONNECTION_STATUS.ROUTING_FAILED));
        onError(SFTP_CSS.ERROR_ROUTING);
        return { status: SFTP_CSS.ERROR_ROUTING, icon: 'error' };

      default:
        store.dispatch(openError(CONNECTION_STATUS.SERVER_FAILED));
        onError(SFTP_CSS.ERROR_SERVER);
        return { status: SFTP_CSS.ERROR_SERVER, icon: 'error' };
    }
  } catch (err) {
    store.dispatch(openError(CONNECTION_STATUS.DEFAULT));
    onError(SFTP_CSS.WARNING);
    return { status: SFTP_CSS.WARNING, icon: 'error' };
  }
}
