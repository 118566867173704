const checkItemIsUpdating = items =>
  items.some(item => item.isUpdating) &&
  items.every(item => item.isUpdating || item.posted);

const buildUpdatingLineItems = ({
  claim: { lineItems },
  lineItemReportId,
  isClaimUpdating,
  failed,
}) =>
  lineItems.map(lineItem =>
    isClaimUpdating || lineItem.lineItemReportId === lineItemReportId
      ? { ...lineItem, isUpdating: !failed }
      : lineItem,
  );

const checkClaimIsUpdating = ({
  isEraUpdating,
  lineItemReportId,
  claim,
  claimReportId,
}) =>
  isEraUpdating || (!lineItemReportId && claim.claimReportId === claimReportId);

const buildUpdatingClaims = ({
  reportData: { claims },
  claimReportId,
  lineItemReportId,
  isEraUpdating,
  failed,
}) =>
  claims.map(claim => {
    const isUpdating = checkClaimIsUpdating({
      isEraUpdating,
      lineItemReportId,
      claim,
      claimReportId,
    });

    if (
      !isUpdating &&
      (!lineItemReportId || claim.claimReportId !== claimReportId)
    ) {
      return claim;
    }

    const lineItems = buildUpdatingLineItems({
      claim,
      lineItemReportId,
      isClaimUpdating: isUpdating,
      failed,
    });

    return {
      ...claim,
      isUpdating: !failed && checkItemIsUpdating(lineItems),
      lineItems,
    };
  });

export default ({
  reportData,
  claimReportId,
  lineItemReportId,
  failed = false,
}) => {
  const isUpdating = !(claimReportId || lineItemReportId);
  const claims = buildUpdatingClaims({
    reportData,
    claimReportId,
    lineItemReportId,
    isEraUpdating: isUpdating,
    failed,
  });

  return {
    ...reportData,
    isUpdating:
      !failed && checkItemIsUpdating(claims.flatMap(claim => claim.lineItems)),
    claims,
  };
};
