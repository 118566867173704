import moment from 'moment-timezone';

import {
  saveToLocalStorage,
  getFromLocalStorage,
} from '../../../utils/local-storage-util';

const SCAN_API_CACHE_KEY = 'scanApiCache';
const FORMATTED_TODAY = moment().format('L');

export const clearScanApiCache = () => {
  saveToLocalStorage(SCAN_API_CACHE_KEY, JSON.stringify({}));
};

export const getFromCache = (key, cacheApiCalls) => {
  if (!cacheApiCalls) {
    return null;
  }

  const scanApiCacheValue = getFromLocalStorage(SCAN_API_CACHE_KEY) || '{}';
  const scanApiCache = JSON.parse(scanApiCacheValue);

  if (
    scanApiCache.lastUpdated &&
    scanApiCache.lastUpdated !== FORMATTED_TODAY
  ) {
    clearScanApiCache();
    return null;
  }
  const value = scanApiCache[key] || null;
  return value;
};

export const saveToCache = (key, value, cacheApiCalls) => {
  if (!cacheApiCalls) {
    return false;
  }

  const scanApiCacheValue = getFromLocalStorage(SCAN_API_CACHE_KEY) || '{}';
  const scanApiCache = JSON.parse(scanApiCacheValue);
  scanApiCache[key] = value;
  scanApiCache.lastUpdated = FORMATTED_TODAY;
  saveToLocalStorage(SCAN_API_CACHE_KEY, JSON.stringify(scanApiCache));

  return true;
};
