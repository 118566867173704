import '../../neb-popup-header';
import '../../neb-selection-card';

import { html, css } from 'lit';

import {
  OVERLAY_WIDTH_LARGE,
  CSS_SPACING,
  CSS_BORDER_GREY_2,
  CSS_FONT_WEIGHT_BOLD,
  CSS_COLOR_WHITE,
} from '../../../../../neb-styles/neb-variables';
import {
  hasFeatureOrBetaSync,
  FEATURE_FLAGS,
} from '../../../../../neb-utils/feature-util';
import { openOverlay, OVERLAY_KEYS } from '../../../utils/overlay-constants';
import Overlay from '../neb-overlay';

export const ELEMENTS = {
  header: {
    id: 'header',
    title: 'Add Payment',
  },
  description: {
    id: 'description',
    text: 'Choose payment type and proceed to payment details',
  },
  cards: {
    selector: 'neb-selection-card',
  },
  patientPaymentCard: {
    id: 'patient-payment',
    title: 'Patient Payment',
  },
  payerPaymentCard: {
    id: 'payer-payment',
    title: 'Add EOB',
  },
  preallocatePaymentCard: {
    id: 'preallocate-payment',
    title: 'Select & Pay Patient Charges [NEW]',
  },
};

class NebOverlayAddPayment extends Overlay {
  static get properties() {
    return {
      __configCard: {
        type: Array,
      },
    };
  }

  initState() {
    super.initState();

    this.__buildConfigCard();
  }

  initHandlers() {
    super.initHandlers();

    this.handlers.addPatientPayment = () =>
      openOverlay(OVERLAY_KEYS.ADD_PATIENT_PAYMENT, {
        patientId: this.model.patientId,
        patient: this.model.patient,
      });

    this.handlers.addPayerPayment = async () => {
      const payerPayment = await openOverlay(OVERLAY_KEYS.EOB_FORM, {
        patientId: this.model.patientId,
      });

      if (payerPayment) {
        await openOverlay(OVERLAY_KEYS.ERA_EOB_MANAGEMENT, {
          readonly: false,
          payerPayment,
        });

        this.onClose();
      }

      return payerPayment;
    };

    this.handlers.preallocatePayment = () => {
      openOverlay(OVERLAY_KEYS.PREALLOCATE_PAYMENT, {
        patient: this.model.patient,
      });
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          width: ${OVERLAY_WIDTH_LARGE};
        }

        :host([layout='small']) .header {
          padding: 10px ${CSS_SPACING};
          border-bottom: ${CSS_BORDER_GREY_2};
        }

        .header-container {
          padding: ${CSS_SPACING};
        }

        .card {
          margin-bottom: ${CSS_SPACING};
        }

        .content-padding {
          padding: ${CSS_SPACING} ${CSS_SPACING} 0;
        }

        .bold {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .main {
          background-color: ${CSS_COLOR_WHITE};
        }
      `,
    ];
  }

  firstUpdated() {
    super.firstUpdated();

    if (this.model) {
      this.__buildConfigCard();
    }
  }

  __buildConfigCard() {
    this.__configCard = [
      ...(hasFeatureOrBetaSync(FEATURE_FLAGS.LS_PREALLOCATION_SCREEN)
        ? [
            {
              id: ELEMENTS.preallocatePaymentCard.id,
              title: ELEMENTS.preallocatePaymentCard.title,
              icon: 'preallocatePayment',
              handler: 'preallocatePayment',
              content: () => this.__renderPreallocatePaymentContent(),
            },
          ]
        : []),

      {
        id: ELEMENTS.patientPaymentCard.id,
        title: ELEMENTS.patientPaymentCard.title,
        icon: 'patients',
        handler: 'addPatientPayment',
        content: () => this.__renderPatientPaymentContent(),
      },

      ...(this.layout === 'large'
        ? [
            {
              id: ELEMENTS.payerPaymentCard.id,
              title: ELEMENTS.payerPaymentCard.title,
              icon: 'medicalInsurance',
              handler: 'addPayerPayment',
              content: () => this.__renderPayerPaymentContent(),
            },
          ]
        : []),
    ];
  }

  __renderHeader() {
    return html`
      <div class="header-container">
        <neb-popup-header
          id="${ELEMENTS.header.id}"
          .title="${ELEMENTS.header.title}"
          .onCancel="${this.handlers.dismiss}"
          showCancelButton
        ></neb-popup-header>
        <div id="${ELEMENTS.description.id}">${ELEMENTS.description.text}</div>
      </div>
    `;
  }

  __renderCards() {
    return html`
      <div class="content-padding main">
        ${this.__configCard.map(c => this.__renderCard(c))}
      </div>
    `;
  }

  __renderCard(card) {
    return html`
      <neb-selection-card
        id="${card.id}"
        class="card"
        .title="${card.title}"
        .icon="${card.icon}"
        .onClick="${this.handlers[card.handler]}"
      >
        ${card.content()}
      </neb-selection-card>
    `;
  }

  __renderPatientPaymentContent() {
    return html`
      <p>
        Capture payments received from or on behalf of the patient, including
        balance payments, copays and packages.
        <span class="bold">
          These payments can then be allocated to any outstanding patient
          balances.
        </div>
      </p>
    `;
  }

  __renderPayerPaymentContent() {
    return html`
      <p>
        Capture payments received from payers, including fee for service or
        capitation payments.
      </p>
    `;
  }

  __renderPreallocatePaymentContent() {
    return html`
      <p>
        Choose the applicable charges to determine payment amount, then collect
        patient payment.
      </p>
    `;
  }

  renderContent() {
    return html` ${this.__renderHeader()}${this.__renderCards()} `;
  }
}

customElements.define('neb-overlay-add-payment', NebOverlayAddPayment);
