import { html, css } from 'lit';

import NebPopup, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../packages/neb-popup/src/neb-popup';
import '../pages/user-agreement/neb-page-user-agreement';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  pageUserAgreement: {
    id: 'page-user-agreement',
  },
};

class NebPopupUserAgreement extends NebPopup {
  static get properties() {
    return {
      ...super.properties,
      model: { type: Object },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          width: 600px;
          max-height: 80vh;
          display: flex;
          flex-direction: column;
        }

        .content-container {
          display: flex;
          flex-direction: column;
          flex: 1;
          min-height: 0;
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.title = 'ChiroTouch Cloud Subscription and Services Agreement';
    this.model = {
      agreementText: '',
      privacyPolicyUrl: '',
    };
  }

  initHandlers() {
    super.initHandlers();

    this.__handlers = {
      ...this.handlers,
      onAccept: () => {
        this.onClose(true);
      },
    };
  }

  renderContent() {
    return html`
      <div class="content-container">
        <neb-page-user-agreement
          id="${ELEMENTS.pageUserAgreement.id}"
          .agreementText="${this.model?.agreementText}"
          .privacyPolicyUrl="${this.model?.privacyPolicyUrl}"
          .onAccept="${this.__handlers.onAccept}"
        ></neb-page-user-agreement>
      </div>
    `;
  }
}

customElements.define('neb-popup-user-agreement', NebPopupUserAgreement);
