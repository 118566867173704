import { Method } from '../../packages/neb-api-client/src/utils/api-client-utils';
import ApiClientv2 from '../../packages/neb-api-client/src/utils/api-client-v2';

const MINUTE = 60 * 1000;
export const apiClient = new ApiClientv2({
  microservice: 'billing',
  cacheConfig: {
    max: 200,
    maxAge: 10 * MINUTE,
  },
});

export const addNDCFields = (data, defaultValue = '') => ({
  nationalDrugCodeEnabled: data.nationalDrugCodeEnabled || false,
  nationalDrugCodeQualifier: data.nationalDrugCodeQualifier || defaultValue,
  nationalDrugCode: data.nationalDrugCode,
  nationalDrugCodeDosage: data.nationalDrugCodeDosage,
  nationalDrugCodeUnitOfMeasurement:
    data.nationalDrugCodeUnitOfMeasurement || defaultValue,
  nationalDrugCodeNumberCategory:
    data.nationalDrugCodeNumberCategory || defaultValue,
  nationalDrugCodeSequenceOrPrescription:
    data.nationalDrugCodeSequenceOrPrescription,
});

export function mapToModel(raw) {
  return {
    id: raw.id,
    taxId: raw.taxId || '',
    taxName: raw.taxName || null,
    taxRate: raw.taxRate || null,
    active: raw.active,
    EPSDTCode: raw.EPSDTCode,
    eClaimIncludeChargeDescription: raw.eClaimIncludeChargeDescription,
    ...addNDCFields(raw),
    availableForPurchase: raw.availableForPurchase,
    billWithTreatmentInitiationDate: raw.billWithTreatmentInitiationDate,
    billWithXrayDate: raw.billWithXrayDate,
    timedProcedure: raw.timedProcedure,
    suppressFromClaim: raw.suppressFromClaim,
    durableMedicalEquipment: raw.durableMedicalEquipment,
    amount: Number(raw.amount),
    procedure: raw.procedure,
    description: raw.description,
    modifiers: raw.modifiers
      ? raw.modifiers.map(m => m || '')
      : ['', '', '', ''],
    feeScheduleCharges: raw.feeScheduleCharges
      ? raw.feeScheduleCharges.map(fsc => ({
          ...fsc,
          active: true,
        }))
      : [],
    units: raw.units,
  };
}

export function mapToRaw(model) {
  return {
    id: model.id || undefined,
    taxId: model.taxId || null,
    active: model.active,
    EPSDTCode: model.EPSDTCode,
    eClaimIncludeChargeDescription: model.eClaimIncludeChargeDescription,
    ...addNDCFields(model, null),
    availableForPurchase: model.availableForPurchase,
    billWithTreatmentInitiationDate: model.billWithTreatmentInitiationDate,
    billWithXrayDate: model.billWithXrayDate,
    timedProcedure: model.timedProcedure,
    suppressFromClaim: model.suppressFromClaim,
    durableMedicalEquipment: model.durableMedicalEquipment,
    amount: model.amount,
    procedure: model.procedure,
    description: model.description,
    modifiers: model.modifiers
      ? model.modifiers.map(m => m || '')
      : ['', '', '', ''],
    feeScheduleCharges: model.feeScheduleCharges.map(fsc => ({
      ...fsc,
      active: undefined,
    })),
    units: model.units,
  };
}

export async function getMany({
  hideInactive = false,
  optOutLoadingIndicator = false,
} = {}) {
  const res = await apiClient.makeRequest({
    method: Method.GET,
    path: '/api/v2/tenants/:tenantId/charges',
    optOutLoadingIndicator,
    cacheKey: 'all-charges',
    queryParams: { hideInactive },
  });

  return res.map(mapToModel);
}

export async function getOne(id, optOutLoadingIndicator = false) {
  const res = await apiClient.makeRequest({
    method: Method.GET,
    path: '/api/v1/tenants/:tenantId/charges/:id',
    replacements: { id },
    optOutLoadingIndicator,
  });

  return mapToModel(res);
}

export async function save(charge) {
  const editing = !!charge.id;

  const method = editing ? Method.PUT : Method.POST;
  const path = editing
    ? '/api/v3/tenants/:tenantId/charges/:id'
    : '/api/v1/tenants/:tenantId/charges';
  const replacements = {
    ...(editing && { id: charge.id }),
  };

  const res = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method,
    path,
    replacements,
    body: JSON.stringify(mapToRaw(charge)),
    headers: {
      'Content-Type': 'application/json',
    },
    clearCacheKeys: ['all-charges'],
  });

  return mapToModel(res);
}
