import { css, html, LitElement } from 'lit';

import {
  CSS_COLOR_GREY_4,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_WHITE,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../packages/neb-styles/neb-variables';
import { CSS_SMALL_SPACING } from '../../styles';

export const ELEMENTS = {
  viewTabs: { id: 'view-tabs' },
  viewTab: { selector: '[id^="view-tab-"]' },
  untitledViewButton: { id: 'untitled-view-button' },
};

class NebTabsArHub extends LitElement {
  static get properties() {
    return {
      selectedViews: Array,
      selectedTab: Object,
      showNewView: Boolean,
      onSelectTab: Function,
    };
  }

  static get styles() {
    return css`
      :host {
        display: block;
      }

      .tabs-group {
        display: flex;
        gap: ${CSS_SMALL_SPACING};
        align-items: center;
        flex-wrap: wrap;
      }

      .tabs {
        display: flex;
        flex-wrap: wrap;
        gap: ${CSS_SMALL_SPACING};
        align-items: center;
      }

      .default-tab {
        display: inline-flex;
        align-items: center;
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border-radius: 14px;
        padding: 5px 10px;
        cursor: pointer;
      }

      .tab {
        background-color: ${CSS_COLOR_GREY_4};
        color: ${CSS_COLOR_HIGHLIGHT};
      }

      .view-name {
        font-weight: ${CSS_FONT_WEIGHT_BOLD};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .selected-tab {
        background-color: ${CSS_COLOR_HIGHLIGHT};
        color: ${CSS_COLOR_WHITE};
      }

      .selected-view-name {
        font-weight: ${CSS_FONT_WEIGHT_BOLD};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    `;
  }

  constructor() {
    super();
    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.selectedViews = [];
    this.selectedTab = {};
    this.showNewView = false;

    this.onSelectTab = () => {};

    this.onBuildNewView = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      selectTab: event => {
        const view = event.currentTarget.value;
        this.onSelectTab(view);
      },
      buildNewView: () => {
        this.onBuildNewView();
      },
    };
  }

  __renderTabs() {
    if (this.selectedViews?.length) {
      const tabs = this.selectedViews.map((view, index) => {
        const isSelected = view.id === this.selectedTab?.id;
        return html`<div
          id="view-tab-${index}"
          class="${isSelected ? 'selected-tab' : 'tab'} default-tab"
          .value="${view}"
          @click=${this.__handlers.selectTab}
        >
          <span class="${isSelected ? 'selected-view-name' : 'view-name'}"
            >${view.name}</span
          >
        </div>`;
      });

      return html`<div class="tabs" id="${ELEMENTS.viewTabs.id}">${tabs}</div>`;
    }

    return '';
  }

  __renderNewViewLink() {
    return this.showNewView
      ? html`
          <div
            id="${ELEMENTS.untitledViewButton.id}"
            class="${this.selectedTab.id ? 'tab' : 'selected-tab'} default-tab"
            @click=${this.__handlers.buildNewView}
          >
            <span class="selected-view-name">Untitled View</span>
          </div>
        `
      : '';
  }

  render() {
    return html`
      <div class="tabs-group">
        ${this.__renderTabs()} ${this.__renderNewViewLink()}
      </div>
    `;
  }
}

customElements.define('neb-tabs-ar-hub', NebTabsArHub);
