import { html, css, LitElement, nothing } from 'lit';
import '../controls/inputs/neb-checkbox';
import '../../../packages/neb-lit-components/src/components/inputs/neb-textfield';

import {
  centsToCurrency,
  currencyToCents,
} from '../../../packages/neb-utils/formatters';
import { currency } from '../../../packages/neb-utils/masks';
import {
  CSS_SPACING,
  CSS_BANNER_INFO_COLOR,
  CSS_COLOR_GREY_1,
  CSS_FIELD_MARGIN,
} from '../../styles';

export const ELEMENTS = {
  lineItemCard: {
    id: 'line-preallocate-item-card',
  },
  header: {
    id: 'header',
  },
  charge: {
    selector: '[id^=charge-]',
  },
  checkboxLineItems: {
    selector: '[id^=checkbox-line-preallocate-item-]',
  },
  amountTextFields: {
    selector: '[id^=amount-text-field-]',
  },
};

export const ACTIONS = Object.freeze({
  checkboxUpdate: 'CHECKBOX_UPDATE',
  amountUpdate: 'AMOUNT_UPDATE',
});

class NebPreallocateLineItemCard extends LitElement {
  static get properties() {
    return {
      preallocateItemGroup: Object,
      __values: Array,
    };
  }

  static get styles() {
    return css`
      #line-preallocate-item-card {
        padding: 0 ${CSS_SPACING};
        box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.14);
        border-radius: 2px;
        display: flex;
        flex-direction: column;
        position: relative;
      }

      #line-preallocate-item-card[clickable]:hover:not(
          :has(.charge-fields:hover)
        ) {
        background-color: #fcfcfc;
      }

      #line-preallocate-item-card[clickable] {
        z-index: 1;
        cursor: pointer;
        transition: background-color 0.2s ease-in-out;
      }

      #line-preallocate-item-card div {
        display: flex;
      }

      #header {
        margin: ${CSS_FIELD_MARGIN} 0;
      }

      .charge-details {
        display: flex;
        justify-content: center;
        flex-direction: column;
      }

      .charge-fields {
        display: flex;
        gap: ${CSS_SPACING};
        align-items: center;
        width: 150px;
        cursor: default;
      }

      .title {
        flex-direction: column;
      }

      .title > span:first-child {
        font-size: 1.3em;
        color: ${CSS_BANNER_INFO_COLOR};
        font-weight: bold;
      }

      .title > span:last-child {
        color: ${CSS_COLOR_GREY_1};
      }

      .flex-separator {
        justify-content: space-between;
        align-items: center;
        margin-top: -${CSS_SPACING};
      }

      .flex-separator > div:first-child {
        flex: 9;
      }

      .flex-separator > div:last-child {
        flex: 3;
        justify-content: flex-end;
      }

      .small {
        font-size: 12px;
        color: ${CSS_COLOR_GREY_1};
      }

      span,
      b,
      .charge-fields {
        z-index: 2;
        width: fit-content;
      }
    `;
  }

  constructor() {
    super();

    this.__values = [];
  }

  updated(changedProps) {
    super.updated(changedProps);

    if (changedProps.has('preallocateItemGroup') && this.preallocateItemGroup) {
      this.__values = this.preallocateItemGroup.items.map(item =>
        centsToCurrency(item.amount),
      );
    }
  }

  __onChange(e, idx) {
    const items = this.preallocateItemGroup.items.map(item => ({
      amount: item.amount,
      selected: item.selected,
      outstandingPatientBalance: item.outstandingPatientBalance,
    }));

    switch (e.name) {
      case ACTIONS.checkboxUpdate:
        items[idx].selected = e.value;
        items[idx].amount = items[idx].outstandingPatientBalance;

        this.onChange(items);

        break;
      case ACTIONS.amountUpdate:
        if (e.event === 'blur') {
          let centsValue = currencyToCents(e.value);

          if (centsValue > items[idx].outstandingPatientBalance) {
            centsValue = items[idx].outstandingPatientBalance;
          }

          if (centsValue <= 0) {
            items[idx].selected = false;
            centsValue = items[idx].outstandingPatientBalance;
          }

          items[idx].amount = centsValue;
          e.value = centsToCurrency(centsValue);

          this.onChange(items);
        }

        this.__values = this.__values.map((value, idx2) =>
          idx2 === idx ? e.value : value,
        );

        break;
      default:
        break;
    }
  }

  __isCardClickable() {
    return !this.preallocateItemGroup.items.every(item => item.selected);
  }

  __onCardClick() {
    if (!this.__isCardClickable()) {
      return;
    }

    this.onChange(
      this.preallocateItemGroup.items.map(item => ({
        amount: item.outstandingPatientBalance,
        selected: true,
      })),
    );
  }

  __renderHeader() {
    return html`
      <div id="${ELEMENTS.header.id}" class="flex-separator">
        <div class="title">
          <span>${this.preallocateItemGroup.title}</span>
          <span class="small"
            >${this.preallocateItemGroup.appointmentType}
            ${this.preallocateItemGroup.items[0].locationName
              ? ` - ${this.preallocateItemGroup.items[0].locationName}`
              : ''}</span
          >
        </div>
        <div class="estimated-owed">
          <b>
            ${this.preallocateItemGroup.estimatedOwedLabel}:
            ${centsToCurrency(this.preallocateItemGroup.estimatedOwed)}</b
          >
        </div>
      </div>
    `;
  }

  __renderCharge(preallocateItem, idx) {
    return html`
      <div id="charge-${idx}" class="flex-separator">
        <div class="charge-details">
          <span> ${preallocateItem.chargeDescription} </span>
          <span class="small">${preallocateItem.chargeDetails}</span>
        </div>
        <div class="charge-fields" @click="${e => e.stopPropagation()}">
          <neb-checkbox
            id="checkbox-line-preallocate-item-${idx}"
            name="${ACTIONS.checkboxUpdate}"
            .onChange="${e => this.__onChange(e, idx)}"
            ?checked="${preallocateItem.selected}"
          ></neb-checkbox>
          <neb-textfield
            label=" "
            helper=" "
            id="amount-text-field-${idx}"
            name="${ACTIONS.amountUpdate}"
            .value="${this.__values[idx]}"
            .mask="${currency}"
            .inputMode="${'numeric'}"
            ?disabled="${!preallocateItem.selected}"
            .onChange="${e => this.__onChange(e, idx)}"
          ></neb-textfield>
        </div>
      </div>
    `;
  }

  render() {
    if (!this.preallocateItemGroup) {
      return nothing;
    }

    if (
      this.preallocateItemGroup.encounterChargesCount >
      this.preallocateItemGroup.items.length
    ) {
      return nothing;
    }

    return html`
      <div
        id="${ELEMENTS.lineItemCard.id}"
        ?clickable="${this.__isCardClickable()}"
        @click="${this.__onCardClick}"
      >
        ${this.__renderHeader()}
        ${this.preallocateItemGroup.items.map((preallocateItem, idx) =>
          this.__renderCharge(preallocateItem, idx),
        )}
      </div>
    `;
  }
}

customElements.define(
  'neb-preallocate-line-item-card',
  NebPreallocateLineItemCard,
);
