import './neb-appointment-page-view';

import { openPopup } from '@neb/popup';
import { css, html, LitElement } from 'lit';

import {
  confirmAppointment,
  markPatientAsArrived,
  unconfirmAppointment,
  updateAppointmentRoom,
} from '../../../../../src/api-clients/appointments';
import { EDIT_MODE } from '../../../../../src/components/overlays/appointments/neb-overlay-edit-reschedule-appointment';
import { openOverlayCheckInOut } from '../../../../../src/features/check-in-out/utils/open-overlay-check-in-out';
import { getAppointmentFormModel } from '../../../../../src/utils/scheduling/appointments';
import { mapToPatientCaseName } from '../../../../neb-api-client/src/mappers/patient-case-mapper';
import { fetchOne } from '../../../../neb-api-client/src/patient-cases';
import { getSchedulingPermissions } from '../../../../neb-api-client/src/permissions-api-client';
import * as reasonsApi from '../../../../neb-api-client/src/reasons-api-client';
import { fetchManyRooms } from '../../../../neb-api-client/src/rooms-api-client';
import {
  cancelOrDeleteAppointment,
  forceFetchAppointments,
} from '../../../../neb-calendar/neb-appointments-state';
import {
  openError,
  openSuccess,
} from '../../../../neb-dialog/neb-banner-state';
import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import { getEncounterForAppt } from '../../../../neb-redux/actions/encounterActions';
import { connect, store } from '../../../../neb-redux/neb-redux-store';
import { AppointmentDetailsMomentService } from '../../../../neb-redux/services/appointment-details-moment';
import { AppointmentStoreService } from '../../../../neb-redux/services/appointment-store';
import { navigate } from '../../../../neb-route/neb-route-state';
import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  getAppointmentActionButtons,
  getAppointmentActionMenuItems,
  getEncounterInfo,
} from '../../../../neb-utils/appointment-action-util';
import { roomConflictOverridePopup } from '../../../../neb-utils/calendar-resources-util';
import { parseDate } from '../../../../neb-utils/date-util';
import { URL_NO_ACCESS } from '../../../../neb-utils/neb-request-security';
import { openChangeRoomPopup } from '../../utils/appointment-overlays-util';
import { openOverlay, OVERLAY_KEYS } from '../../utils/overlay-constants';
import { MODE } from '../overlays/scheduling/neb-overlay-check-in-out';

import { APPOINTMENT_ACTIONS } from './neb-appointment-options';

export const ELEMENTS = {
  view: {
    id: 'view',
  },
};

class NebAppointmentPageController extends connect(store)(LitElement) {
  static get properties() {
    return {
      layout: {
        type: String,
        reflect: true,
      },
      appointmentId: {
        type: String,
      },
      __appointment: {
        type: Object,
      },
      __patientId: {
        type: String,
      },
      __appointmentStatus: {
        type: String,
      },
      patientCaseName: {
        type: String,
      },
      __selectedAuthorization: {
        type: Object,
      },
      __hasEncounter: {
        type: Boolean,
      },
      __appointmentActionButtons: {
        type: Array,
      },
      __appointmentActionMenuItems: {
        type: Array,
      },
      __rooms: {
        type: Array,
      },
      __cancelRescheduleReason: {
        type: String,
      },
      __allReasons: {
        type: Array,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();

    this.__initServices();
  }

  __initState() {
    this.__patientSummaryModel = {};
    this.appointmentId = '';
    this.layout = '';
    this.patientCaseName = '';
    this.__activeReasons = null;
    this.__appointmentStatus = '';
    this.__appointment = {};
    this.__cancelDeleteError = null;
    this.__cancelRescheduleReason = '';
    this.__allReasons = [];
    this.__hasEncounter = false;
    this.__appointmentActionButtons = [];
    this.__appointmentActionMenuItems = [];
    this.__selectedAuthorization = {};
    this.__rooms = [];

    this.onDismiss = () => {};
  }

  __updateAppointmentIdFromOverlayResult(model) {
    if (this.appointmentId !== model.id) {
      this.appointmentId = model.id;
    } else {
      this.__appointmentService.update(model.id);
    }
  }

  __initHandlers() {
    this.__handlers = {
      actionMenuItem: menuItem => {
        if (getSchedulingPermissions()) {
          switch (menuItem.actionName) {
            case APPOINTMENT_ACTIONS.EDIT.action:
              return this.__handlers.edit(menuItem);

            case APPOINTMENT_ACTIONS.RESCHEDULE.action:
              return this.__handlers.reschedule(menuItem);

            case APPOINTMENT_ACTIONS.CANCEL.action:
              return this.__handlers.cancel(menuItem);

            case APPOINTMENT_ACTIONS.DELETE.action:
              return this.__handlers.delete(menuItem);

            case APPOINTMENT_ACTIONS.DELETE_SERIES.action:
              return this.__handlers.deleteSeries();

            case APPOINTMENT_ACTIONS.RETURN_TO_SCHEDULED.action:
              return this.__handlers.returnToScheduled(
                APPOINTMENT_ACTIONS.RETURN_TO_SCHEDULED,
              );

            case APPOINTMENT_ACTIONS.CONFIRM.action:
              return this.__handlers.confirmAppointment(
                APPOINTMENT_ACTIONS.CONFIRM,
              );

            case APPOINTMENT_ACTIONS.UNCONFIRM.action:
              return this.__handlers.unconfirmAppointment(
                APPOINTMENT_ACTIONS.UNCONFIRM,
              );

            case APPOINTMENT_ACTIONS.ARRIVED.action:
              return this.__handlers.markPatientAsArrived(
                APPOINTMENT_ACTIONS.ARRIVED,
              );

            case APPOINTMENT_ACTIONS.CHANGE_ROOM.action:
              return this.__handlers.changeRoom(menuItem);

            default:
              return () => {};
          }
        } else {
          store.dispatch(navigate(URL_NO_ACCESS));
          return undefined;
        }
      },
      actionButton: button => {
        if (getSchedulingPermissions()) {
          switch (button.actionName) {
            case APPOINTMENT_ACTIONS.NO_SHOW.action:
              return this.__handlers.noShow(button);
            case APPOINTMENT_ACTIONS.CHECK_IN.action:
              return this.__handlers.checkIn(button);
            case APPOINTMENT_ACTIONS.CHECK_OUT.action:
              return this.__handlers.checkOut(button);

            default:
              return () => {};
          }
        } else {
          store.dispatch(navigate(URL_NO_ACCESS));
          return undefined;
        }
      },
      cancel: async menuItem => {
        if (await this.__actionIsAllowed(menuItem)) {
          await this.__cancelAppointment();
        }
      },
      close: () => this.onDismiss(),
      confirmAppointment: async menuItem => {
        const res = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
          title: menuItem.title,
          message: menuItem.message,
          confirmText: 'Yes',
          cancelText: 'No',
        });

        if (res) {
          try {
            await confirmAppointment(this.appointmentId);
            this.__appointmentService.update(this.appointmentId);
            store.dispatch(
              forceFetchAppointments(store.getState().appointments.targetDate),
            );

            store.dispatch(openSuccess(menuItem.successMessage));
          } catch (_) {
            store.dispatch(openError(menuItem.errorMessage));
          }

          this.onDismiss();
        }
      },
      unconfirmAppointment: async menuItem => {
        const res = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
          title: menuItem.title,
          message: menuItem.message,
          confirmText: 'Yes',
          cancelText: 'No',
        });

        if (res) {
          try {
            await unconfirmAppointment(this.appointmentId);
            this.__appointmentService.update(this.appointmentId);
            store.dispatch(
              forceFetchAppointments(store.getState().appointments.targetDate),
            );

            store.dispatch(openSuccess(menuItem.successMessage));
          } catch (_) {
            store.dispatch(openError(menuItem.errorMessage));
          }

          this.onDismiss();
        }
      },
      markPatientAsArrived: async menuItem => {
        const res = await openPopup(POPUP_RENDER_KEYS.ARRIVED, {
          title: menuItem.title,
          message: menuItem.message,
          confirmText: 'Yes',
          cancelText: 'No',
        });

        if (res) {
          try {
            await markPatientAsArrived(this.appointmentId);
            this.__appointmentService.update(this.appointmentId);
            store.dispatch(
              forceFetchAppointments(store.getState().appointments.targetDate),
            );

            store.dispatch(openSuccess(menuItem.successMessage));
          } catch (_) {
            store.dispatch(openError(menuItem.errorMessage));
          }

          this.onDismiss();
        }
      },
      delete: async menuItem => {
        if (await this.__actionIsAllowed(menuItem)) {
          await this.__executeAppointmentAction(APPOINTMENT_ACTIONS.DELETE);
        }
      },
      deleteSeries: () =>
        this.__executeAppointmentAction(APPOINTMENT_ACTIONS.DELETE_SERIES),
      edit: async () => {
        const model = getAppointmentFormModel(this.__appointment);

        const result = await openOverlay(
          OVERLAY_KEYS.APPOINTMENT_EDIT_RESCHEDULE_FORM,
          {
            ...model,
            mode: EDIT_MODE.EDIT,
            hasEncounter: this.__hasEncounter,
          },
        );

        if (result && result.success) {
          this.__updateAppointmentIdFromOverlayResult(result.model);
        } else {
          this.__updateAppointmentIdFromOverlayResult(this.__appointment);
        }
      },
      reschedule: async () => {
        const model = getAppointmentFormModel(this.__appointment);

        const result = await openOverlay(
          OVERLAY_KEYS.APPOINTMENT_EDIT_RESCHEDULE_FORM,
          {
            ...model,
            mode: EDIT_MODE.RESCHEDULE,
            hasEncounter: this.__hasEncounter,
          },
        );

        if (result && result.success) {
          this.__updateAppointmentIdFromOverlayResult(result.model);

          await store.dispatch(getEncounterForAppt(result.model.id));
        } else {
          this.__updateAppointmentIdFromOverlayResult(this.__appointment);
        }
      },
      changeRoom: async () => {
        const appointmentAction = APPOINTMENT_ACTIONS.CHANGE_ROOM;

        const res = await openChangeRoomPopup(this.appointmentId, {
          rooms: this.__rooms,
          roomId: this.__appointment.roomId,
        });

        if (res && res.room.id !== this.__appointment.roomId) {
          const roomId = res.room.id;

          try {
            await this.__validateAndUpdateRoomId(appointmentAction, roomId);
          } catch (_) {
            store.dispatch(openError(appointmentAction.errorMessage));
          }

          this.onDismiss();
        }
      },
      returnToScheduled: async action => {
        const confirm = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
          title: action.title,
          message: action.message,
          confirmText: 'Yes',
          cancelText: 'No',
        });

        if (confirm) {
          const result = await this.__appointmentStoreService.returnToScheduled(
            this.appointmentId,
          );

          if (result && result.success) {
            this.onDismiss();
          }
        }
      },
      navigateToPatientEncounter: () => this.__navigateToPatientEncounter(),
      noShow: async button => {
        if (await this.__actionIsAllowed(button)) {
          await this.__executeAppointmentAction(APPOINTMENT_ACTIONS.NO_SHOW);
        }
      },
      checkIn: async _button => {
        const result = await this.__openCheckInOutOverlay(MODE.checkIn);

        if (result && result.success && !result.cancelled) {
          this.onDismiss();
        }
      },
      checkOut: async _button => {
        const result = await this.__openCheckInOutOverlay(MODE.checkOut);

        if (result && result.success) {
          if (result.cancelled) {
            this.__updateAppointmentIdFromOverlayResult(result.model);
          } else {
            this.onDismiss();
          }
        }
      },
      authorizationDetailChanged: () =>
        this.__getPatientCase(this.__appointment.caseId),
      patientSummaryModelLoaded: model => {
        this.__patientSummaryModel = model;
      },
    };
  }

  __initServices() {
    this.__appointmentService = new AppointmentDetailsMomentService(
      ({ appointment }) => {
        this.__appointment = appointment;
      },
    );

    this.__appointmentStoreService = new AppointmentStoreService();
  }

  _stateChanged({ appointments }) {
    this.__cancelDeleteError = appointments.cancelDeleteError;
  }

  connectedCallback() {
    super.connectedCallback();
    this.__appointmentService.connect();

    this.__fetchReasons();
  }

  disconnectedCallback() {
    this.__appointmentService.disconnect();

    super.disconnectedCallback();
  }

  async __fetchReasons() {
    try {
      this.__allReasons = await reasonsApi.fetchMany();
      this.__activeReasons = this.__allReasons.filter(item => item.active);
    } catch (error) {
      store.dispatch(openError('An error occurred while fetching data'));
    }
  }

  __openCheckInOutOverlay(mode) {
    return openOverlayCheckInOut(OVERLAY_KEYS.CHECK_IN_OUT, {
      appointment: this.__appointment,
      patientId: this.__patientId,
      patientSummaryModel: this.__patientSummaryModel,
      mode,
    });
  }

  __navigateToPatientEncounter() {
    const { id, patientId } = this.__appointment.encounter;
    store.dispatch(
      navigate(`#/patients/${patientId}/clinical/encounters/${id}`),
    );
  }

  __setCancelRescheduleReason() {
    if (this.__appointment && this.__allReasons.length) {
      const currentReason = this.__allReasons.find(
        reason => reason.id === this.__appointment.cancelRescheduleReasonId,
      );

      if (currentReason) this.__cancelRescheduleReason = currentReason.name;
    }
  }

  async __actionIsAllowed(actionItem) {
    if (actionItem && !actionItem.encounterCheck.allowed) {
      await openPopup(POPUP_RENDER_KEYS.MESSAGE, {
        title: actionItem.encounterCheck.title,
        message: actionItem.encounterCheck.message,
        confirmText: 'OK',
      });

      return false;
    }

    return true;
  }

  async __cancelAppointment() {
    const appointmentAction = APPOINTMENT_ACTIONS.CANCEL;
    const result = await openPopup(POPUP_RENDER_KEYS.APPOINTMENT_CANCEL, {
      reasons: this.__activeReasons,
    });

    if (result && result.accepted) {
      await store.dispatch(
        cancelOrDeleteAppointment({
          appointmentId: this.appointmentId,
          appointmentAction: appointmentAction.action,
          note: result.note,
          reason: result.reason,
          patientId: this.__patientId,
        }),
      );

      if (this.__cancelDeleteError) {
        store.dispatch(openError(appointmentAction.errorMessage));
      } else {
        store.dispatch(openSuccess(appointmentAction.successMessage));
      }

      this.onDismiss();
    }
  }

  async __executeAppointmentAction(appointmentAction) {
    const result = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
      title: appointmentAction.title,
      message: appointmentAction.message,
      confirmText: 'Yes',
      cancelText: 'No',
    });
    if (!result) return;
    await store.dispatch(
      cancelOrDeleteAppointment({
        appointmentId: this.appointmentId,
        appointmentAction: appointmentAction.action,
        patientId: this.__patientId,
      }),
    );

    if (this.__cancelDeleteError) {
      store.dispatch(openError(appointmentAction.errorMessage));
    } else {
      store.dispatch(openSuccess(appointmentAction.successMessage));
    }

    this.onDismiss();
  }

  __updateEncounterStatus() {
    this.__hasEncounter = !!this.__appointment.encounter;
  }

  async __getPatientCase(caseId) {
    const patientCase = await fetchOne(this.__patientId, caseId, true);

    this.patientCaseName = mapToPatientCaseName(
      patientCase.name,
      patientCase.onsetSymptomsDate,
    );

    const patientAuthorization = patientCase.patientAuthorizations.find(
      a => a.id === this.__appointment.patientAuthorizationId,
    );

    this.__selectedAuthorization = patientAuthorization || {};
  }

  async __getRooms() {
    const rooms = await fetchManyRooms();

    this.__rooms = rooms.filter(
      room => room.locationId === this.__appointment.locationId && room.active,
    );
  }

  async __validateAndUpdateRoomId(appointmentAction, roomId) {
    if (await roomConflictOverridePopup(roomId)) {
      await updateAppointmentRoom({
        id: this.appointmentId,
        body: { roomId },
      });

      this.__appointmentService.update(this.appointmentId);

      store.dispatch(
        forceFetchAppointments(store.getState().appointments.targetDate),
      );

      store.dispatch(openSuccess(appointmentAction.successMessage));
    }
  }

  async __setAppointmentActions(appointment) {
    const encounterInfo = await getEncounterInfo(appointment.encounter, true);

    this.__appointmentActionButtons = getAppointmentActionButtons(
      appointment.status,
      appointment.start,
      encounterInfo,
    );

    this.__appointmentActionMenuItems = getAppointmentActionMenuItems(
      appointment.status,
      appointment.recurrenceEventId,
      encounterInfo,
      appointment.recurrenceEvent && appointment.start.isBefore(parseDate()),
      !!appointment.confirmedAt,
      !!appointment.arrivedAt,
    );
  }

  __getScheduledRoom() {
    const scheduledRoom = this.__rooms.find(
      room => room.id === this.__appointment.resourceId,
    );

    return scheduledRoom || {};
  }

  __getCheckedInRoom() {
    const checkedInRoom = this.__rooms.find(
      room => room.id === this.__appointment.roomId,
    );

    return checkedInRoom || {};
  }

  update(changedProps) {
    if (changedProps.has('appointmentId')) {
      this.__appointmentService.update(this.appointmentId);
    }

    if (
      changedProps.has('__appointment') &&
      this.__appointment &&
      this.__appointment.id
    ) {
      const { patientId, patient } = this.__appointment;
      this.__patientId = patientId || patient.id;
      this.__setAppointmentActions(this.__appointment);

      if (this.__appointment.caseId && this.__patientId) {
        this.__getPatientCase(this.__appointment.caseId);
      } else {
        this.patientCaseName = '';
      }

      this.__getRooms();

      this.__updateEncounterStatus();

      this.__setCancelRescheduleReason();
    }

    if (changedProps.has('__allReasons')) {
      this.__setCancelRescheduleReason();
    }

    super.update(changedProps);
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: flex;
          height: 100%;
          width: 100%;
        }

        .view {
          flex: 1 0 0;
        }
      `,
    ];
  }

  render() {
    return html`
      <neb-appointment-page-view
        id="${ELEMENTS.view.id}"
        class="view"
        .model="${this.__appointment}"
        .appointmentActionButtons="${this.__appointmentActionButtons}"
        .appointmentActionMenuItems="${this.__appointmentActionMenuItems}"
        .layout="${this.layout}"
        .hasEncounter="${this.__hasEncounter}"
        .patientId="${this.__patientId}"
        .patientCaseName="${this.patientCaseName}"
        .scheduledRoom="${this.__getScheduledRoom()}"
        .checkedInRoom="${this.__getCheckedInRoom()}"
        .cancelRescheduleReason="${this.__cancelRescheduleReason}"
        .selectedAuthorization="${this.__selectedAuthorization}"
        .onAuthorizationDetailChanged="${this.__handlers
          .authorizationDetailChanged}"
        .onNavigateToPatientEncounter="${this.__handlers
          .navigateToPatientEncounter}"
        .onActionMenuItemSelect="${this.__handlers.actionMenuItem}"
        .onActionButtonClick="${this.__handlers.actionButton}"
        .onPatientSummaryModelLoaded="${this.__handlers
          .patientSummaryModelLoaded}"
        .onClose="${this.__handlers.close}"
      ></neb-appointment-page-view>
    `;
  }
}

window.customElements.define(
  'neb-appointment-page-controller',
  NebAppointmentPageController,
);
