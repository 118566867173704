import { Method } from '../../packages/neb-api-client/src/utils/api-client-utils';
import ApiClientV2 from '../../packages/neb-api-client/src/utils/api-client-v2';

const apiClient = new ApiClientV2({ microservice: 'core' });

export const createNarrative = async ({ id }, { signal } = {}) => {
  const res = await apiClient.makeRequest({
    path: '/api/v1/tenants/:tenantId/intake-narrative',
    method: Method.POST,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ documentId: id }),
    signal,
  });

  const [{ narrative }] = res.data;

  return narrative;
};
