import { LitElement, html, css } from 'lit';

import { datadogRumAddAction } from '../../../../src/library/datadog';
import { baseStyles } from '../../../neb-styles/neb-styles';
import {
  CSS_COLOR_GREY_1,
  CSS_COLOR_WHITE,
  CSS_FONT_FAMILY,
  CSS_FONT_SIZE_BODY,
  CSS_FONT_WEIGHT_BOLD,
  CSS_SPACING,
} from '../../../neb-styles/neb-variables';

import '../../../../src/components/misc/neb-icon';

export const BUTTON_ROLE = {
  CONFIRM: 'confirm',
  CANCEL: 'cancel',
  DELETE: 'delete',
  OUTLINE: 'outline',
};

export const ELEMENTS = {
  button: { id: 'button' },
  label: { id: 'label' },
  rippleContainer: { id: 'container-ripples' },
  trailingIcon: { id: 'trailing-icon' },
};

export const CSS_COLOR_BLUE_1 = css`#0b9fcb`;

class NebButton extends LitElement {
  static get properties() {
    return {
      __active: {
        type: Boolean,
        reflect: true,
        attribute: 'active',
      },

      disabled: {
        reflect: true,
        type: Boolean,
      },
      label: String,
      layout: {
        reflect: true,
        type: String,
      },
      name: String,
      role: {
        reflect: true,
        type: String,
      },
      trailingIcon: String,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: inline-block;
          min-width: 106.5px;
        }

        .layer {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }

        .button {
          display: block;
          outline: none;
          overflow: hidden;
          position: relative;
          background: none;
          border: none;
          width: 100%;
          height: 36px;
          padding: 0;
          border-radius: 18px;
          background-color: ${CSS_COLOR_WHITE};
        }

        :host([disabled]) .button {
          opacity: 0.5;
        }

        :host([role='confirm']) .button {
          background-color: ${CSS_COLOR_BLUE_1};
        }

        :host(:not([disabled])) .button:hover {
          cursor: pointer;
          opacity: 0.8;
        }

        .layer-fade {
          background-color: ${CSS_COLOR_WHITE};
          opacity: 0;
          transition: opacity 500ms;
        }

        :host([active]) .layer-fade {
          opacity: 0.5;
        }

        :host([disabled]) .layer-fade {
          opacity: 0.5;
        }

        :host([role='cancel']) .layer-fade,
        :host([role='delete']) .layer-fade {
          background-color: ${CSS_COLOR_GREY_1};
        }

        .layer-outline {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: transparent;
          border-radius: 18px;
        }

        .icon {
          width: 24px;
          height: 24px;
          padding-left: 5px;
        }

        .label-container {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 25px;
        }

        :host([role='confirm']) .icon {
          fill: ${CSS_COLOR_WHITE};
        }

        :host([role='outline']) .icon {
          fill: ${CSS_COLOR_BLUE_1};
        }

        :host([role='cancel']) .icon,
        :host([role='delete']) .icon {
          fill: ${CSS_COLOR_GREY_1};
        }

        :host([role='cancel']) .layer-outline,
        :host([role='delete']) .layer-outline {
          border: 1px solid ${CSS_COLOR_GREY_1};
        }

        :host([role='outline']) .layer-outline {
          border: 1px solid ${CSS_COLOR_BLUE_1};
        }

        .label {
          position: relative;
          margin: 0;
          color: ${CSS_COLOR_WHITE};
          font-size: ${CSS_FONT_SIZE_BODY};
          font-family: ${CSS_FONT_FAMILY};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          text-align: center;
          text-transform: uppercase;
        }

        :host([layout='small']) .label {
          padding: 0 ${CSS_SPACING};
        }

        :host([role='cancel']) .label,
        :host([role='delete']) .label {
          color: ${CSS_COLOR_GREY_1};
        }

        :host([role='outline']) .label {
          color: ${CSS_COLOR_BLUE_1};
        }

        .ripple {
          position: absolute;
          background-color: ${CSS_COLOR_WHITE};
          border-radius: 50%;
          opacity: 0.5;
          animation: ripple 500ms;
        }

        :host([role='cancel']) .ripple,
        :host([role='delete']) .ripple,
        :host([role='outline']) .ripple {
          background-color: ${CSS_COLOR_GREY_1};
        }

        @keyframes ripple {
          0% {
            transform: translate(-50%, -50%) scale(0);
            opacity: 0.5;
          }
          100% {
            transform: translate(-50%, -50%) scale(1);
            opacity: 0;
          }
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.disabled = false;
    this.label = 'Sample';
    this.layout = '';
    this.name = '';
    this.role = BUTTON_ROLE.CONFIRM;

    this.onClick = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      click: e => {
        datadogRumAddAction(this.label);
        return this.onClick({ name: this.name, event: e });
      },
      mouseup: _e => {
        if (!this.disabled) {
          this.__active = false;
        }
      },
      mousedown: e => {
        if (this.disabled) {
          return;
        }

        const componentStyles = window.getComputedStyle(this);
        const leftOffset = parseInt(
          componentStyles.getPropertyValue('padding-left'),
          10,
        );
        const topOffset = parseInt(
          componentStyles.getPropertyValue('padding-top'),
          10,
        );

        const largerWidth = this.clientWidth > this.clientHeight;
        const dimension = largerWidth ? this.clientWidth : this.clientHeight;
        const radius = dimension * 2;
        const rect = this.getBoundingClientRect();
        const el = document.createElement('div');

        el.className = 'ripple';
        el.style.left = `${e.clientX - rect.x - leftOffset}px`;
        el.style.top = `${e.clientY - rect.y - topOffset}px`;
        el.style.width = `${radius}px`;
        el.style.height = `${radius}px`;
        el.addEventListener('animationend', this.__handlers.removeRipple);

        this.__rippleContainerEl.appendChild(el);
        this.__active = true;
      },
      removeRipple: e => this.__rippleContainerEl.removeChild(e.currentTarget),
    };
  }

  connectedCallback() {
    super.connectedCallback();

    document.addEventListener('mouseup', this.__handlers.mouseup);
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    document.removeEventListener('mouseup', this.__handlers.mouseup);
  }

  firstUpdated() {
    this.__rippleContainerEl = this.shadowRoot.getElementById(
      ELEMENTS.rippleContainer.id,
    );
  }

  __renderTrailingIcon() {
    return this.trailingIcon
      ? html`<neb-icon
          id="${ELEMENTS.trailingIcon.id}"
          class="icon"
          .icon="${`neb:${this.trailingIcon}`}"
        ></neb-icon>`
      : '';
  }

  render() {
    return html`
      <button
        id="${ELEMENTS.button.id}"
        class="button"
        part="button"
        data-dd-action-name="${this.label || 'neb-button'}"
        @click="${this.__handlers.click}"
        @mousedown="${this.__handlers.mousedown}"
        ?disabled="${this.disabled}"
      >
        <div class="layer layer-fade"></div>

        <div id="${ELEMENTS.rippleContainer.id}" class="layer"></div>

        <div class="layer layer-outline"></div>

        <div class="label-container">
          <span id="${ELEMENTS.label.id}" class="label">${this.label}</span>

          ${this.__renderTrailingIcon()}
        </div>
      </button>
    `;
  }
}

customElements.define('neb-button', NebButton);
