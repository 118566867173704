import '../neb-header';
import '../neb-button';
import '../neb-radio-button';
import '../controls/neb-tab-group';
import '../tables/neb-table-allocation-payments';
import '../controls/neb-switch';
import '../inputs/neb-select-search';

import { openPopup } from '@neb/popup';
import equal from 'fast-deep-equal';
import { html, css } from 'lit';

import { editProviderAdjustment } from '../../../../../src/components/forms/era-eob/neb-form-era-eob-management/neb-form-era-eob-management-utils';
import { UpdateNotificationService } from '../../../../../src/services/update-notifications';
import {
  getSelectedBalanceDueV2,
  validateAllowedAgainstTotalOwedV2,
  owedGrandTotalValidatorV2,
  getPatientDebits,
  getPrimaryPayerDebits,
  calculateDebitsOwed,
  calculateDebitsPaid,
  isPatientRespDebit,
  mapDebitsForEditPopup,
  mapDebitAmountsToCents,
  buildDebitAllocationAmounts,
  mapAdjustmentsForEditPopup,
  hasOnlyOnePaidPkgDebit,
  validatePatientAllocatedAmountAgainstAvailableAmount,
  validatePrimaryAllocatedAmountAgainstAvailableAmount,
  validatePrimaryAllocatedAmountAgainstOwedAmount,
  validatePatientAllocatedAmountAgainstOwedAmount,
  validatePatientAllocatedAmountAgainstAvailableOwedAmount,
  validatePrimaryAllocatedAmountAgainstAvailableOwedAmount,
  validateNonPrimaryAllocatedAmountAgainstAvailableAmount,
  validateNonPrimaryAllocatedAmountAgainstOwedAmount,
  validateNonPrimaryOwedAmountAgainstAllocatedAmount,
  validateNonPrimaryAllocatedAmountAgainstAvailableOwedAmount,
  validatePatientOwedAmountAgainstAllocatedAmount,
  validatePrimaryOwedAmountAgainstAllocatedAmount,
  getSecondaryPayerDebits,
  isPayerRespDebit,
  mapPatientInsurancesForEditPopup,
  calculateBalancedAllowedAmount,
  calculateBalancedAdjustmentAmount,
  calculateAdjustmentsSum,
  getPatientRespCodePaymentId,
} from '../../../../../src/utils/allocate-charges/neb-allocate-charge-util';
import { ALLOCATE_PAYMENT_TAB } from '../../../../../src/utils/allocate-payment/allocate-payment-util';
import { navigateToRow } from '../../../../../src/utils/navigation-util';
import { searchPatients } from '../../../../../src/utils/patients';
import {
  getProviderAdjustmentsTotal,
  openPayment,
} from '../../../../../src/utils/payment-util';
import {
  getBillingCodesWriteOffs,
  profiles,
  TYPE,
} from '../../../../neb-api-client/src/billing-codes';
import { getLedgerInvoiceItems } from '../../../../neb-api-client/src/invoice-api-client';
import { buildPatientPackageAssociations } from '../../../../neb-api-client/src/ledger/line-items';
import * as patientApiClient from '../../../../neb-api-client/src/patient-api-client';
import { getPaymentDetail } from '../../../../neb-api-client/src/payments-api-client';
import { MONTH_DAY_YEAR } from '../../../../neb-input/nebFormatUtils';
import { openDirtyPopup } from '../../../../neb-popup';
import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import {
  CSS_SPACING,
  CSS_FONT_WEIGHT_BOLD,
  CSS_COLOR_GREY_3,
  CSS_COLOR_BLUE_BORDER,
  CSS_COLOR_WHITE,
} from '../../../../neb-styles/neb-variables';
import {
  CODE_WRITE_OFFS,
  CODE_PAYMENTS,
} from '../../../../neb-utils/constants';
import { parseDate } from '../../../../neb-utils/date-util';
import { FEATURE_FLAGS, getFeatures } from '../../../../neb-utils/feature-util';
import {
  centsToCurrency,
  currencyToCents,
  currencyToCentsWithNegative,
  centsToCurrencyWithNegative,
} from '../../../../neb-utils/formatters';
import {
  LINE_ITEM_DETAIL,
  checkForWarnings,
  selectedChargesHasErrors,
  BILL_TYPE,
  validateAllowedAgainstBilled,
  openLedgerLineItemOverlay,
  fetchInsurancesFromPatients,
  fetchPaymentTypeItems,
} from '../../../../neb-utils/neb-ledger-util';
import { isPayerPayment } from '../../../../neb-utils/neb-payment-util';
import { DEFAULT_LEVEL } from '../../../../neb-utils/patientInsurance';
import * as selectors from '../../../../neb-utils/selectors';
import { map, deepCopy } from '../../../../neb-utils/utils';
import { openEncounterSummary } from '../../utils/encounter-overlays-util';
import { openOverlay, OVERLAY_KEYS } from '../../utils/overlay-constants';
import { POPOVER_POSITION } from '../neb-date-picker';
import { AllocationChargesTable2 } from '../tables/neb-table-allocation-charges-2';

import NebForm, { ELEMENTS as BASE_ELEMENTS } from './neb-form';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  paymentsTable: { id: 'payment-allocation-table' },
  selectedBalance: { id: 'selected-balance' },
  selectedAvailable: { id: 'selected-available' },
  dateOfTransactionFrom: { id: 'date-of-transaction-from' },
  dateOfTransactionTo: { id: 'date-of-transaction-to' },
  header: {
    id: 'outstanding-charges-header',
    title: 'Outstanding Charges',
    description:
      'For each selected charge, enter the payment type and amount you want to allocate and click "Allocate".',
  },
  headerAllocatedCharges: {
    id: 'allocated-charges-header',
    title: 'Allocated Charges',
    description:
      'Select charges to edit Allocated amounts, edit Payer and Patient Owed amounts and Adjustments as needed.',
  },
  selectPatient: { id: 'select-payer' },
  patientSelectSearch: { id: 'patient-select-search' },
  selectLocations: { id: 'select-locations' },
  filterContainer: { id: 'filter-container' },
  applyFilterButton: { id: 'apply-filter-button' },
  allocationChargesTable: { id: 'allocation-charges-table' },
  allocationAllocatedChargesTable: { id: 'allocation-allocated-charges-table' },
  description: {
    id: 'description',
    text: 'Select a payment associated with this patient or payer and the charges you want the payment applied to.',
  },
  tabs: {
    id: 'tabs',
  },
  removeAllocation: {
    id: 'remove-allocation',
    label: 'Remove Allocation',
  },
  buttonAdditionalCharges: {
    id: 'additional-charges',
  },
  buttonScrollToTop: {
    id: 'button-scroll-to-top',
  },
};

const NO_PAYMENTS_TO_ALLOCATE_MESSAGE =
  'There are no payments by this payer with available funds to allocate.';

const NO_OUTSTANDING_CHARGES =
  'There are no open charges for the selected payment.';

const NO_ALLOCATED_CHARGES =
  'There are no charges for the selected parameters.';

const CONFIRM_REMOVE_ALLOCATION = {
  confirmText: 'Yes',
  cancelText: 'No',
  title: 'Remove Allocation',
  message: html`
    Are you sure you want to remove the selected payments allocated amount from
    the selected charge(s)?
  `,
};
const FOCUS = 'focus';
const BLUR = 'blur';

export class NebFormAllocationCharges extends NebForm {
  static get properties() {
    return {
      __preallocated: Array,
      __selectIndexes: Array,
      __unchecked: Object,
      __selectedPayment: Object,
      __menuItemsMap: Object,
      __paymentModel: Array,
      __showScrollToTopButton: Boolean,
      __patientSearch: String,
      __patientSearchResult: Array,
      __useItemFilterPatient: Boolean,
      __hasAllocationsPatientSearchFeatureFlag: Boolean,
      __hasApplyResetAllocateFitFeatureFlag: Boolean,
      __previousSelectIndexes: Array,

      selectedTab: String,
      patientsHash: Object,
      selectedPayer: String,
      patientId: String,
      patients: Array,
      payments: Array,
      itemFilters: Object,
      enablePatientDropdown: Boolean,
      locations: Array,
      defaultLocationId: String,
      payers: Array,
      hasRCMSecondaryField: Boolean,
      showAdditionalCharges: Boolean,
      associatedLineItemIds: Array,
      hasShowNextInsurance: Boolean,
      hasFitInvoiceOverlayPerformanceFF: Boolean,
      hasOmegaInitialAllocationPerformanceFF: Boolean,
      fromEraEobPage: Boolean,
    };
  }

  static createModel() {
    return [
      {
        ...LINE_ITEM_DETAIL,
        patientOwed: 0,
        patientAllocated: 0,
        patientPaid: 0,
        primaryOwed: 0,
        primaryAllocated: 0,
        primaryPaid: 0,
        nonPrimaryPayerDebits: [],
        debits: [
          {
            allocations: [],
            amount: 0,
            id: '',
            payerId: '',
            allocated: 0,
            patientInsuranceId: '',
            codePaymentId: '',
          },
        ],
        adjustments: [],
        providerAdjustments: [],
      },
    ];
  }

  static createMetaData() {
    return {
      patientIds: [],
      patients: [],
      codePaymentItems: [],
      codeWriteOffItems: [],
      patientsInsurances: [],
      insuranceCharges: [],
      patientOutstandingLineItems: [],
      payers: [],
    };
  }

  initState() {
    super.initState();

    this.selectedTab = ALLOCATE_PAYMENT_TAB.OUTSTANDING;
    this.__showScrollToTopButton = false;
    this.__navItems = [
      {
        id: ALLOCATE_PAYMENT_TAB.OUTSTANDING,
        label: 'Outstanding Charges',
        renderer: () => this.__renderOutstandingCharges(),
      },
      {
        id: ALLOCATE_PAYMENT_TAB.ALLOCATED,
        label: 'Allocated Charges',
        renderer: () => this.__renderAllocatedCharges(),
      },
    ];

    this.__useItemFilterPatient = true;
    this.__patientSearch = '';
    this.__patientSearchResult = [];
    this.__hasAllocationsPatientSearchFeatureFlag = false;
    this.__hasApplyResetAllocateFitFeatureFlag = false;
    this.__selectedPayment = null;
    this.__selectIndexes = [];
    this.__previousSelectIndexes = [];
    this.__unchecked = { outstandingIds: [], allocatedIds: [] };
    this.__preallocated = [];
    this.__menuItemsMap = {
      insurances: [],
      adjustments: [],
      paymentTypes: [],
    };

    this.__paymentModel = [];

    this.metaData = NebFormAllocationCharges.createMetaData();

    this.patientsHash = {};
    this.confirmLabel = 'Allocate';
    this.selectedPayer = '';
    this.patientId = '';
    this.patients = [];
    this.payments = [];
    this.itemFilters = {
      dateOfTransactionFrom: null,
      dateOfTransactionTo: null,
      patient: '',
      locations: [],
      additionalCharges: false,
    };

    this.locations = [];
    this.defaultLocationId = '';

    this.enablePatientDropdown = false;
    this.payers = [];
    this.showAdditionalCharges = false;
    this.hasRCMSecondaryField = false;
    this.associatedLineItemIds = [];
    this.hasShowNextInsurance = false;
    this.hasFitInvoiceOverlayPerformanceFF = false;
    this.hasOmegaInitialAllocationPerformanceFF = false;
    this.fromEraEobPage = false;

    this.onSelectPayment = () => {};

    this.onItemFiltersChange = () => {};

    this.onPayerUpdate = async () => {};

    this.onLoadPaymentDetails = () => {};

    this.onUpdateLineItem = () => {};

    this.__notificationService = new UpdateNotificationService({
      callback: () => this.rerenderPatientInfo(),
    });

    this.onRemoveAllocation = async () => {};

    this.onSelectTab = () => {};

    this.onRecoup = () => {};

    this.onRemoveRecoupHistory = () => {};
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      paymentSelected: async index => {
        this.__selectedPayment = { ...this.payments[index] };
        this.__menuItemsMap = await this.__getMenuItemsMap();

        this.onSelectPayment(this.__selectedPayment);
      },
      checkItem: (index, checked) => {
        this.__selectIndexes.splice(index, 1, !checked);
        this.__selectIndexes = [...this.__selectIndexes];

        if (checked) this.__revertRow(index);
      },
      addItem: (path, index = -1) => {
        this.formService.addItem(path, index);
      },
      removeItem: (path, index) => {
        this.formService.removeItem(path, index);
      },
      changeAllowed: ({ name, value, event }) => {
        const { row, rowIndex } = this.__getRowAndRowIndexFromName(name);

        if (!this.__handleFocusAndCheck(event, rowIndex)) return;

        this.formService.apply(name, value);

        const newAdjustmentAmount = calculateBalancedAdjustmentAmount(
          row.billedAmount,
          row.taxAmount,
          currencyToCentsWithNegative(value),
        );

        this.__updateAdjustmentAmount(row, rowIndex, newAdjustmentAmount);

        this.formService.validateRow(rowIndex);
      },
      openPatientOwedPopup: async name => {
        const { row, rowIndex } = this.__getRowAndRowIndexFromName(name);

        const { debits } = getPatientDebits(row);

        const result = await openPopup(
          POPUP_RENDER_KEYS.INDIVIDUAL_CODE_AND_AMOUNT,
          {
            title: 'Patient Responsibility',
            initialCodes: mapDebitsForEditPopup(debits),
            displayPaidAmount: true,
            paymentCodes: this.__menuItemsMap.paymentTypes,
            billType: row.billType,
            isCarePackageWithInsurance: row.isCarePackageWithInsurance,
          },
        );

        this.shadowRoot
          .getElementById(this.__getTableId(this.selectedTab))
          .focusPatientOwed(rowIndex);

        if (result) {
          this.__deleteIndividualItems(
            rowIndex,
            'debits',
            result.itemsToDelete,
          );

          this.__addIndividualItems(rowIndex, 'patientOwed', result.itemsToAdd);
          this.__editIndividualItems(rowIndex, 'debits', result.itemsToEdit);

          if (row.isCarePackageWithInsurance && result.popupChanged) {
            await this.__recalculatePatientPackageCharge(rowIndex);
          } else {
            this.__syncPatientCharge(rowIndex);
          }

          this.formService.validateRow(rowIndex);
        }
      },
      changePatientOwed: async ({ name, value, event }) => {
        const { row, rowIndex } = this.__getRowAndRowIndexFromName(name);

        const isNotBlurEvent = event !== BLUR;

        if (!isPayerPayment(this.__selectedPayment) && isNotBlurEvent) {
          return;
        }

        if (this.__shouldCheckItem(event)) {
          this.handlers.checkItem(rowIndex, false);
        }

        if (value === this.state[rowIndex].patientOwed) return;

        let { debits, debitIndexes } = getPatientDebits(row);

        if (debits.length === 0 && debitIndexes.length === 0) {
          this.formService.addItem(`${rowIndex}.debits`);

          ({ debits, debitIndexes } = getPatientDebits(row));
        }

        if (
          debits.length === 1 &&
          debitIndexes.length === 1 &&
          this.state[rowIndex].debits[debitIndexes].codePaymentId === ''
        ) {
          this.formService.apply(
            `${rowIndex}.debits.${debitIndexes}.codePaymentId`,
            CODE_PAYMENTS.GENERAL_PAYMENT.id,
          );
        }

        const [debitIndex] = debitIndexes;

        this.formService.apply(
          `${rowIndex}.debits.${debitIndex}.amount`,
          value,
        );

        this.formService.apply(`${rowIndex}.patientOwed`, value);

        if (row.isCarePackageWithInsurance) {
          await this.__recalculatePatientPackageCharge(rowIndex);
        } else {
          this.__syncAllocated({ rowIndex, field: 'patientAllocated' });
        }

        this.formService.validateRow(rowIndex);
      },
      changePatientAllocated: ({ name, value, event }) => {
        const { rowIndex } = this.__getRowAndRowIndexFromName(name);

        if (!this.__handleFocusAndCheck(event, rowIndex)) return;

        if (value === this.state[rowIndex].patientAllocated) return;

        this.formService.apply(`${rowIndex}.patientAllocated`, value);

        this.__syncAllocated({ rowIndex, field: 'patientAllocated' });

        this.formService.validateRow(rowIndex);
      },
      changePrimaryOwed: ({ name, value, event }) => {
        const { row, rowIndex } = this.__getRowAndRowIndexFromName(name);

        if (!this.__handleFocusAndCheck(event, rowIndex)) return;

        const { debits, debitIndexes } = getPrimaryPayerDebits(row);

        if (debits.length !== 1 && debitIndexes.length !== 1) return;

        const [debitIndex] = debitIndexes;

        this.formService.apply(
          `${rowIndex}.debits.${debitIndex}.amount`,
          value,
        );

        this.formService.apply(`${rowIndex}.primaryOwed`, value);

        this.__syncAllocated({ rowIndex, field: 'primaryAllocated' });

        this.formService.validateRow(rowIndex);
      },
      changeSecondaryOwed: ({ name, value, event }) => {
        const { row, rowIndex } = this.__getRowAndRowIndexFromName(name);

        if (!this.__handleFocusAndCheck(event, rowIndex)) return;

        const { nonPrimaryPayerDebitIdx } =
          this.__getNonPrimaryPayerDebitIndexFromName(name);

        const nonPrimaryPatientInsuranceId =
          row.nonPrimaryPayerDebits[nonPrimaryPayerDebitIdx].patientInsuranceId;

        const { debits, debitIndexes } = getSecondaryPayerDebits(
          row,
          nonPrimaryPatientInsuranceId,
        );
        if (debits.length > 1) return;

        if (
          debits.length > 1 ||
          (currencyToCents(value) === 0 && debits.length === 0)
        ) {
          return;
        }

        if (currencyToCents(value) > 0 && debits.length === 0) {
          const { payerId } =
            row.nonPrimaryPayerDebits[nonPrimaryPayerDebitIdx];

          this.__addIndividualItems(rowIndex, 'secondaryOwed', [
            {
              amount: currencyToCents(value),
              codeId: CODE_PAYMENTS['PR-45'].id,
              individualRowId: '',
              patientInsuranceId: nonPrimaryPatientInsuranceId,
              payerId,
            },
          ]);

          this.__syncOwedAndPaid({
            rowIndex,
            type: 'secondary',
            nonPrimaryPatientInsuranceId,
          });

          this.__syncAllocated({
            rowIndex,
            field: 'secondaryAllocated',
            nonPrimaryPatientInsuranceId,
            nonPrimaryPayerDebitIdx,
          });

          this.formService.validateRow(rowIndex);
          return;
        }

        const [debitIndex] = debitIndexes;

        this.formService.apply(
          `${rowIndex}.debits.${debitIndex}.amount`,
          value,
        );

        this.formService.apply(
          `${rowIndex}.nonPrimaryPayerDebits.${nonPrimaryPayerDebitIdx}.secondaryOwed`,
          currencyToCents(value),
        );

        this.__syncAllocated({
          rowIndex,
          field: 'secondaryAllocated',
          nonPrimaryPatientInsuranceId,
          nonPrimaryPayerDebitIdx,
        });

        this.formService.validateRow(rowIndex);
      },
      changePrimaryAllocated: ({ name, value, event }) => {
        const { rowIndex } = this.__getRowAndRowIndexFromName(name);

        if (!this.__handleFocusAndCheck(event, rowIndex)) return;

        this.formService.apply(`${rowIndex}.primaryAllocated`, value);

        this.__syncAllocated({ rowIndex, field: 'primaryAllocated' });

        this.formService.validateRow(rowIndex);
      },
      changeSecondaryAllocated: ({ name, value, event }) => {
        const { row, rowIndex } = this.__getRowAndRowIndexFromName(name);

        if (!this.__handleFocusAndCheck(event, rowIndex)) return;

        const { nonPrimaryPayerDebitIdx } =
          this.__getNonPrimaryPayerDebitIndexFromName(name);

        if (
          value ===
          centsToCurrency(
            row.nonPrimaryPayerDebits[nonPrimaryPayerDebitIdx]
              .secondaryAllocated,
          )
        ) {
          return;
        }

        const nonPrimaryPatientInsuranceId =
          row.nonPrimaryPayerDebits[nonPrimaryPayerDebitIdx].patientInsuranceId;

        this.formService.apply(name, currencyToCents(value));

        this.__syncAllocated({
          rowIndex,
          field: 'secondaryAllocated',
          nonPrimaryPatientInsuranceId,
          nonPrimaryPayerDebitIdx,
        });

        this.formService.validateRow(rowIndex);
      },
      viewEncounterSummary: (encounterId, appointmentTypeId, patientId) =>
        openEncounterSummary({
          patient: this.patients.find(p => p.data.id === patientId).data,
          encounterId,
          appointmentTypeId,
        }),
      viewCharge: async ({ id, invoiceId, patientId, rowIndex }) => {
        const accepted = this.__dirty ? await openDirtyPopup() : true;

        if (!accepted) return;

        const payment = this.payments[rowIndex];

        const lineItemIds = invoiceId
          ? (await getLedgerInvoiceItems(invoiceId)).data.map(li => li.id)
          : [id];

        const overlayKey = this.hasFitInvoiceOverlayPerformanceFF
          ? OVERLAY_KEYS.LEDGER_VIEW_SELECTED_CHARGES_V2
          : OVERLAY_KEYS.LEDGER_VIEW_SELECTED_CHARGES;

        await openOverlay(overlayKey, {
          patient: this.patientsHash[patientId],
          lineItemIds,
          selectedIds: [],
        });

        this.onLoadPaymentDetails(payment);
      },
      editCharge: async ({ patientId, id }) => {
        const { lineItemId } = await openLedgerLineItemOverlay({
          patientId,
          id,
        });

        if (!lineItemId) return;

        await this.onUpdateLineItem(lineItemId);
      },
      viewPayment: async ({
        paymentId,
        value,
        patientId,
        payerPlanId,
        paymentMethod,
      }) => {
        const accepted = this.__dirty ? await openDirtyPopup() : true;

        if (!accepted) return;

        if (payerPlanId) {
          const paymentDetails = await getPaymentDetail(paymentId);

          await openPayment({
            payment: {
              payment: {
                id: paymentId,
                patientId,
              },
              ...paymentDetails,
            },
            readonly: false,
          });
        } else if (
          paymentMethod === 'Discount' ||
          (value && value.indexOf('D-') === 0)
        ) {
          await openOverlay(OVERLAY_KEYS.DISCOUNT, {
            id: paymentId,
            patientId,
          });
        } else {
          await openOverlay(OVERLAY_KEYS.PAYMENT_DETAIL_2, {
            readonly: false,
            payment: {
              patientId,
              id: paymentId,
            },
          });
        }

        this.onLoadPaymentDetails({
          patientId,
          id: paymentId,
        });
      },
      openAdjustmentsPopup: async name => {
        const { row, rowIndex } = this.__getRowAndRowIndexFromName(name);

        const result = await openPopup(
          POPUP_RENDER_KEYS.INDIVIDUAL_CODE_AND_AMOUNT,
          {
            title: 'Adjustments',
            initialCodes: mapAdjustmentsForEditPopup(row.adjustments),
            displayPaidAmount: false,
            billType: row.billType,
            codeWriteOffs: this.__menuItemsMap.adjustments,
            isCarePackageWithInsurance: row.isCarePackageWithInsurance,
          },
        );

        this.shadowRoot
          .getElementById(this.__getTableId(this.selectedTab))
          .focusAdjustment(rowIndex);

        if (result) {
          this.__deleteIndividualItems(
            rowIndex,
            'adjustments',
            result.itemsToDelete,
          );

          this.__addIndividualItems(rowIndex, 'adjustments', result.itemsToAdd);

          this.__editIndividualItems(
            rowIndex,
            'adjustments',
            result.itemsToEdit,
          );

          this.__updateAllowedAmountFromAdjustments(rowIndex);
          this.formService.validateRow(rowIndex);
        }
      },
      openSecondaryOwedPopup: async name => {
        const { row, rowIndex } = this.__getRowAndRowIndexFromName(name);

        const { nonPrimaryPayerDebitIdx } =
          this.__getNonPrimaryPayerDebitIndexFromName(name);

        const { patientInsuranceId: nonPrimaryPatientInsuranceId, payerId } =
          row.nonPrimaryPayerDebits[nonPrimaryPayerDebitIdx];

        const { debits } = getSecondaryPayerDebits(
          row,
          nonPrimaryPatientInsuranceId,
        );

        const initialCodes = this.__getInitialCodes(debits, row);

        const currentInsurance = this.__menuItemsMap.insurances.find(
          ins => ins.data.id === nonPrimaryPatientInsuranceId,
        );

        const patientPlans = this.__getPatientPlansList(
          currentInsurance,
          row.patientId,
        );

        const enablePlansDropdown = this.__shouldEnablePlansDropdown();

        const result = await openPopup(
          POPUP_RENDER_KEYS.INDIVIDUAL_CODE_AND_AMOUNT,
          {
            title: 'Secondary Responsibility',
            initialCodes,
            displayPaidAmount: true,
            paymentCodes: this.__menuItemsMap.paymentTypes,
            plans: mapPatientInsurancesForEditPopup(patientPlans),
            selectedPlan:
              this.hasRCMSecondaryField || this.hasShowNextInsurance
                ? currentInsurance
                : null,
            hasRCMSecondaryField: this.hasRCMSecondaryField,
            hasRCMChangeSecondary: this.hasRCMChangeSecondary,
            enablePlansDropdown,
          },
        );

        this.shadowRoot
          .getElementById(this.__getTableId(this.selectedTab))
          .focusSecondaryOwed(rowIndex, nonPrimaryPayerDebitIdx);

        if (result) {
          if (!this.hasRCMChangeSecondary) {
            this.__deleteIndividualItems(
              rowIndex,
              'debits',
              result.itemsToDelete,
            );
          }
          const patientInsuranceId =
            this.hasRCMChangeSecondary && result.patientInsurance?.id
              ? result.patientInsurance.id
              : nonPrimaryPatientInsuranceId;

          const itemsToAdd = result.itemsToAdd.map(item => ({
            ...item,
            patientInsuranceId,
            payerId:
              this.hasRCMChangeSecondary && result.patientInsurance?.payer
                ? result.patientInsurance.payer.id
                : payerId,
          }));

          this.__addIndividualItems(
            rowIndex,
            'secondaryOwed',
            itemsToAdd,
            patientInsuranceId,
          );

          if (this.hasRCMChangeSecondary && result.patientInsurance?.id) {
            const currentInsuranceId = currentInsurance.data.id;
            const { patientInsurance } = result;

            this.__updateSecondaryPayer(
              row,
              rowIndex,
              currentInsuranceId,
              patientInsurance,
            );

            this.__syncAllocated({
              rowIndex,
              field: 'secondaryAllocated',
              currentInsuranceId,
              nonPrimaryPayerDebitIdx,
            });
          }

          if (this.hasRCMChangeSecondary) {
            this.__deleteIndividualItems(
              rowIndex,
              'debits',
              result.itemsToDelete,
            );
          }

          this.__editIndividualItems(rowIndex, 'debits', result.itemsToEdit);

          this.__syncOwedAndPaid({
            rowIndex,
            type: 'secondary',
            nonPrimaryPatientInsuranceId: patientInsuranceId,
          });

          if (this.__debitsChanged(result, debits)) {
            this.__syncAllocated({
              rowIndex,
              field: 'secondaryAllocated',
              nonPrimaryPatientInsuranceId: patientInsuranceId,
              nonPrimaryPayerDebitIdx,
            });
          }
          this.formService.validateRow(rowIndex);
        }
      },
      addSecondaryPayer: async name => {
        const { row, rowIndex } = this.__getRowAndRowIndexFromName(name);

        const usedSecondaryInsuranceIds = row.nonPrimaryPayerDebits.map(
          debit => debit.patientInsuranceId,
        );

        const insuranceOptions = this.__menuItemsMap.insurances.filter(
          insurance =>
            !usedSecondaryInsuranceIds.includes(insurance.data.id) &&
            insurance.data.id !== row.primaryInsuranceId &&
            insurance.patientId === this.state[rowIndex].patientId,
        );

        const result = await openPopup(
          POPUP_RENDER_KEYS.INDIVIDUAL_CODE_AND_AMOUNT,
          {
            title: 'Secondary Responsibility',
            plans: mapPatientInsurancesForEditPopup(insuranceOptions),
            enablePlansDropdown: true,
            initialCodes: [],
            displayPaidAmount: true,
            paymentCodes: this.__menuItemsMap.paymentTypes,
          },
        );

        if (result) {
          const {
            patientInsurance: {
              id: nonPrimaryPatientInsuranceId,
              payer: { id: payerId, alias },
            },
          } = result;

          const indexToAdd = this.__addNonPrimaryPayerDebits(rowIndex, {
            patientInsuranceId: nonPrimaryPatientInsuranceId,
            payerId,
            alias,
          });

          this.__addIndividualItems(
            rowIndex,
            'secondaryOwed',
            result.itemsToAdd.map(item => ({
              ...item,
              patientInsuranceId: nonPrimaryPatientInsuranceId,
              payerId,
            })),
          );

          this.__syncOwedAndPaid({
            rowIndex,
            type: 'secondary',
            nonPrimaryPatientInsuranceId,
          });

          this.__syncAllocated({
            rowIndex,
            field: 'secondaryAllocated',
            nonPrimaryPatientInsuranceId,
            nonPrimaryPayerDebitIdx: indexToAdd,
          });

          this.formService.validateRow(rowIndex);
        }
      },
      changeAdjustments: ({ name, value, event }) => {
        const { row, rowIndex } = this.__getRowAndRowIndexFromName(name);

        if (!this.__handleFocusAndCheck(event, rowIndex)) return;

        this.__updateAdjustmentAmount(
          row,
          rowIndex,
          currencyToCentsWithNegative(value),
        );

        this.__updateAllowedAmountFromAdjustments(rowIndex);
        this.formService.validateRow(rowIndex);
      },
      itemsFilterChange: async ({ name, value }) => {
        if (this.__patientFilterUpdated(name)) {
          this.__patientSearch = value;
        }

        if (!equal(this.itemFilters[name], value)) {
          let itemFilters = {
            ...this.itemFilters,
            [name]: value,
          };

          if (this.__patientFilterUpdated(name)) {
            if (!value) {
              if (this.selectedTab === ALLOCATE_PAYMENT_TAB.ALLOCATED) {
                itemFilters = {
                  ...this.itemFilters,
                  patient: this.patients[0],
                };
              } else {
                this.__useItemFilterPatient = false;
              }
            } else {
              this.__useItemFilterPatient = true;
            }
          }

          if (this.__hasApplyResetAllocateFitFeatureFlag) {
            this.itemFilters = itemFilters;
          } else {
            const accepted = this.formService.isDirty
              ? await openDirtyPopup()
              : true;

            if (accepted) {
              const fieldsToRefetchPatients = [
                'dateOfTransactionFrom',
                'dateOfTransactionTo',
              ];
              const refetchPatients = fieldsToRefetchPatients.includes(name);
              this.onItemFiltersChange({
                itemFilters,
                selectFirstPatient: false,
                refetchPatients,
              });
            }
          }

          if (name === 'dateOfTransactionFrom') {
            this.__updateDateOfTransactionToSelectable();
          }

          if (name === 'dateOfTransactionTo') {
            this.__updateDateOfTransactionFromSelectable();
          }
        }
      },
      additionalChargesChange: ({ name, value }) => {
        if (this.__hasApplyResetAllocateFitFeatureFlag) {
          const itemFilters = {
            ...this.itemFilters,
            [name]: value,
          };
          this.itemFilters = itemFilters;
          this.onItemFiltersChange({
            itemFilters: this.itemFilters,
            selectFirstPatient: false,
            onlyPatients: true,
            refetchPatients: true,
          });
        }
        return this.handlers.itemsFilterChange({ name, value });
      },
      applyFilter: async () => {
        const accepted = this.formService.isDirty
          ? await openDirtyPopup()
          : true;

        if (accepted) {
          this.onItemFiltersChange({
            itemFilters: this.itemFilters,
            selectFirstPatient: false,
            refetchPatients: true,
          });
        }
      },
      refreshPayer: () => {
        this.onPayerUpdate();
      },
      selectPayer: async ({ rowIndex, payerPlanId }) => {
        const payment = this.payments[rowIndex];
        const accepted = this.__dirty ? await openDirtyPopup() : true;
        if (!accepted) return;

        const res = await openOverlay(OVERLAY_KEYS.PAYER_PLAN, {
          id: payerPlanId,
        });

        if (res || this.__dirty) await this.onLoadPaymentDetails(payment);
      },
      searchDate: date => {
        const allocationChargesTableIndex = this.model.findIndex(
          ({ dateOfService, transactionDate }) =>
            parseDate(dateOfService || transactionDate).format(
              MONTH_DAY_YEAR,
            ) === date.format(MONTH_DAY_YEAR),
        );

        const table = this.shadowRoot.getElementById(
          ELEMENTS.allocationChargesTable.id,
        );
        const row = table.shadowRoot.getElementById(
          `row-${allocationChargesTableIndex}`,
        );

        navigateToRow(row);
      },
      save: async () => {
        this.formService.validate();

        const hasErrors = selectedChargesHasErrors(
          this.errors,
          this.__selectIndexes,
        );

        const onlyWarnings = await checkForWarnings(this.errors);

        if (!hasErrors || onlyWarnings) {
          this.__deleteZeroValueNonPrimaryPayerDebits();
          this.__deleteZeroValueAdjustments();
          this.__resetZeroValuePatientDebit();

          const rawModel = this.formService.build();
          const model = map(rawModel, (_, value) =>
            typeof value === 'string' ? value.trim() : value,
          );

          this.__saving = true;
          await this.onSave(model, this.__selectIndexes);
        }

        this.__saving = false;
      },
      selectAll: () => {
        this.__selectIndexes = this.__selectIndexes.map(() => true);
      },
      deselectAll: () => {
        this.__selectIndexes = this.__selectIndexes.map(() => false);
        this.formService.reset();
        this.formService.validate();
      },
      removeAllocation: async () => {
        const confirmed = await openPopup(
          POPUP_RENDER_KEYS.CONFIRM,
          CONFIRM_REMOVE_ALLOCATION,
        );

        let lineItems;
        let selectedLineItems;

        if (confirmed) {
          if (this.hasRCMChangeSecondary) {
            lineItems = this.formService.build();

            this.__selectIndexes.forEach((isSelected, index) => {
              if (isSelected) {
                const modelDebits = this.model[index].debits;
                lineItems[index].debits = modelDebits;
              }
            });

            selectedLineItems = lineItems.filter(
              (_, index) => this.__selectIndexes[index],
            );
          } else {
            lineItems = this.formService.build();
            selectedLineItems = lineItems.filter(
              (_, index) => this.__selectIndexes[index],
            );
          }

          await this.onRemoveAllocation(selectedLineItems);
        }
      },
      selectTab: async selectedTab => {
        if (this.__hasAllocationsPatientSearchFeatureFlag) {
          const { patient } = this.itemFilters;

          if (patient && this.patients.includes(patient)) {
            this.__useItemFilterPatient = true;
          } else {
            this.__useItemFilterPatient =
              selectedTab === ALLOCATE_PAYMENT_TAB.OUTSTANDING;
          }
        }

        if (this.formService.isDirty) {
          const discardChanges = await openDirtyPopup();

          if (!discardChanges) {
            return;
          }
        }

        this.onSelectTab(selectedTab);
      },
      scrollToTop: () =>
        this.shadowRoot
          .getElementById(ELEMENTS.description.id)
          .scrollIntoView({ behavior: 'smooth' }),
      searchPatients: ({ value }) => {
        let patientList = this.patients;

        if (this.selectedTab === ALLOCATE_PAYMENT_TAB.OUTSTANDING) {
          patientList = this.patients.filter(p => p.data.id);
        }

        this.__patientSearchResult = searchPatients(patientList, value);
      },
      editProviderAdjustment: async index => {
        const result = await editProviderAdjustment({
          payments: this.__paymentModel,
          index,
        });

        if (result) await this.onPayerUpdate();
      },
      recoup: allocation => this.onRecoup(allocation),
      removeRecoupHistory: charge => this.onRemoveRecoupHistory(charge),
    };

    this.__updateDateOfTransactionFromSelectable();
    this.__updateDateOfTransactionToSelectable();
  }

  __shouldEnablePlansDropdown() {
    return this.hasRCMSecondaryField && !this.hasShowNextInsurance;
  }

  __shouldEscapeIfFocusEvent(event) {
    const isFocusEvent = event === FOCUS;
    return !isPayerPayment(this.__selectedPayment) && isFocusEvent;
  }

  __shouldCheckItem(event) {
    const isFocusOrBlurEvent = event === FOCUS || event === BLUR;
    return (
      isPayerPayment(this.__selectedPayment) &&
      this.selectedTab === ALLOCATE_PAYMENT_TAB.OUTSTANDING &&
      !isFocusOrBlurEvent
    );
  }

  __handleFocusAndCheck(event, rowIndex) {
    if (this.__shouldEscapeIfFocusEvent(event)) {
      return false;
    }

    if (this.__shouldCheckItem(event)) {
      this.handlers.checkItem(rowIndex, false);
    }
    return true;
  }

  async __recalculatePatientPackageCharge(rowIndex) {
    const allCharges = deepCopy(this.state);
    const currentCharge = allCharges[rowIndex];

    const lineItems = allCharges
      .filter(
        charge => charge.patientPackageId === currentCharge.patientPackageId,
      )
      .map(charge => ({
        ...charge,
        lineItemDebits: charge.debits
          .filter(debit => !debit.payerId)
          .map(debit => ({ debit })),
      }));

    const patientPackageAssociations = await buildPatientPackageAssociations({
      lineItems,
      patientOwedChanged: true,
      changedLineItemIndex: lineItems.findIndex(
        lineItem => lineItem.id === currentCharge.id,
      ),
    });

    if (Object.keys(patientPackageAssociations).length === 0) {
      this.__updatePatientPackageCharge({
        rowIndex,
        newLineItemDebits: [],
        newAdjustments: [],
      });

      return;
    }

    allCharges.forEach((charge, index) => {
      const associations = patientPackageAssociations[charge.id];

      if (!associations) {
        return;
      }

      const { lineItemDebits, adjustments } = associations;

      if (!this.__chargeIsCoveredByPackage(lineItemDebits, adjustments)) {
        this.__syncPatientCharge(rowIndex);
        return;
      }

      this.__updatePatientPackageCharge({
        rowIndex: index,
        newLineItemDebits: lineItemDebits,
        newAdjustments: adjustments,
      });
    });
  }

  __patientFilterUpdated(name) {
    return this.__hasAllocationsPatientSearchFeatureFlag && name === 'patient';
  }

  __updateSecondaryPayer(row, rowIndex, currentInsuranceId, patientInsurance) {
    row.nonPrimaryPayerDebits.forEach((debit, index) => {
      if (debit.patientInsuranceId === currentInsuranceId) {
        this.formService.apply(
          `${rowIndex}.nonPrimaryPayerDebits.${index}.patientInsuranceId`,
          patientInsurance.id,
        );

        this.formService.apply(
          `${rowIndex}.nonPrimaryPayerDebits.${index}.payerId`,
          patientInsurance.payer.id,
        );

        this.formService.apply(
          `${rowIndex}.nonPrimaryPayerDebits.${index}.secondaryPayer.alias`,
          patientInsurance.payer.alias,
        );

        this.formService.apply(
          `${rowIndex}.nonPrimaryPayerDebits.${index}.secondaryAllocated`,
          0,
        );
      }
    });

    row.debits.forEach((debit, index) => {
      if (debit.patientInsuranceId === currentInsuranceId) {
        this.formService.apply(
          `${rowIndex}.debits.${index}.patientInsuranceId`,
          patientInsurance.id,
        );

        this.formService.apply(
          `${rowIndex}.debits.${index}.payerId`,
          patientInsurance.payer.id,
        );

        this.formService.apply(
          `${rowIndex}.debits.${index}.allocated`,
          centsToCurrency(0),
        );
      }
    });
  }

  __getPatientPlansList(currentInsurance, id) {
    if (this.hasRCMChangeSecondary) {
      const insurances = this.__menuItemsMap.insurances.filter(insurance => {
        const { defaultLevel } = insurance.data;
        const { patientId } = insurance;

        return defaultLevel !== DEFAULT_LEVEL.Primary && patientId === id;
      });

      const allInsurances = new Set([currentInsurance, ...insurances]);

      return Array.from(allInsurances);
    }

    return [currentInsurance];
  }

  __getInitialCodes(secondaryDebits, row) {
    const { debits, nonPrimaryPayerDebits } = row;

    if (this.hasShowNextInsurance) {
      return mapDebitsForEditPopup(secondaryDebits);
    }

    return this.hasRCMSecondaryField &&
      nonPrimaryPayerDebits.length &&
      nonPrimaryPayerDebits[0].secondaryOwed === 0
      ? getPatientRespCodePaymentId(debits)
      : mapDebitsForEditPopup(secondaryDebits);
  }

  __chargeIsCoveredByPackage(lineItemDebits, adjustments) {
    const hasPackageDebits = lineItemDebits.some(
      ({ codePaymentId }) => codePaymentId === CODE_PAYMENTS.PACKAGE.id,
    );

    const hasPackageAdjustments = adjustments.some(
      ({ codeId }) => codeId === CODE_WRITE_OFFS.PACKAGE.id,
    );

    return hasPackageDebits || hasPackageAdjustments;
  }

  __updatePatientPackageCharge({
    rowIndex,
    newLineItemDebits,
    newAdjustments,
  }) {
    const { debits, adjustments } = this.state[rowIndex];

    for (let i = debits.length - 1; i > -1; i -= 1) {
      if (!debits[i].payerId) {
        this.formService.removeItem(`${rowIndex}.debits`, i);
      }
    }

    for (let i = adjustments.length - 1; i > -1; i -= 1) {
      if (adjustments[i].codeId === CODE_WRITE_OFFS.PACKAGE.id) {
        this.formService.removeItem(`${rowIndex}.adjustments`, i);
      }
    }

    newLineItemDebits.forEach(({ codePaymentId, debit }) => {
      const newDebitIndex = this.state[rowIndex].debits.length;

      this.formService.addItem(`${rowIndex}.debits`);

      this.formService.apply(
        `${rowIndex}.debits.${newDebitIndex}.codePaymentId`,
        codePaymentId,
      );

      this.formService.apply(
        `${rowIndex}.debits.${newDebitIndex}.amount`,
        centsToCurrency(debit.amount),
      );

      (debit.allocations || []).forEach(allocation => {
        this.formService.addItem(
          `${rowIndex}.debits.${newDebitIndex}.allocations`,
        );

        this.formService.apply(
          `${rowIndex}.debits.${newDebitIndex}.allocations.0.amount`,
          allocation.amount,
        );

        this.formService.apply(
          `${rowIndex}.debits.${newDebitIndex}.allocations.0.credit.amount`,
          allocation.credit.amount,
        );

        this.formService.apply(
          `${rowIndex}.debits.${newDebitIndex}.allocations.0.credit.patientPackageId`,
          allocation.credit.patientPackageId,
        );
      });
    });

    newAdjustments.forEach(({ codeId, amount }) => {
      const newAdjustmentIndex = this.state[rowIndex].adjustments.length;

      this.formService.addItem(`${rowIndex}.adjustments`);

      this.formService.apply(
        `${rowIndex}.adjustments.${newAdjustmentIndex}.codeId`,
        codeId,
      );

      this.formService.apply(
        `${rowIndex}.adjustments.${newAdjustmentIndex}.amount`,
        centsToCurrencyWithNegative(amount),
      );
    });

    this.__syncPatientCharge(rowIndex);
    this.__updateAllowedAmountFromAdjustments(rowIndex);
  }

  __syncPatientCharge(rowIndex) {
    this.__syncOwedAndPaid({ rowIndex, type: 'patient' });
    this.__clearAllocatedIfDebitsRemoved({ rowIndex, type: 'patient' });
    this.__syncAllocated({ rowIndex, field: 'patientAllocated' });
  }

  __updateDateOfTransactionFromSelectable() {
    this.handlers.dateOfTransactionFromSelectable = date =>
      (this.itemFilters.dateOfTransactionTo === null ||
        date.isSameOrBefore(this.itemFilters.dateOfTransactionTo)) &&
      date.isSameOrBefore(parseDate().endOf('day'));
  }

  __updateDateOfTransactionToSelectable() {
    this.handlers.dateOfTransactionToSelectable = date =>
      (this.itemFilters.dateOfTransactionFrom === null ||
        date.isSameOrAfter(this.itemFilters.dateOfTransactionFrom)) &&
      date.isSameOrBefore(parseDate().endOf('day'));
  }

  createSelectors() {
    return {
      children: {
        $: {
          children: {
            allowedAmount: selectors.currency({
              validateManually: true,
              validateRaw: true,
              validators: [
                validateAllowedAgainstBilled(
                  'The total Owed amounts must equal the Allowed amount',
                ),
              ],
            }),
            patientAdjustment: selectors.currencyWithNegative({
              validateManually: true,
              validateRaw: true,
              validators: [
                owedGrandTotalValidatorV2(
                  "The total Owed amounts plus Adjustments must equal the charge's Billed amount plus Tax.",
                ),
              ],
            }),
            patientOwed: selectors.currency({
              validateManually: true,
              validateRaw: true,
              validators: [
                validateAllowedAgainstTotalOwedV2(
                  'The total Owed amounts must be equal to or less than the Allowed amount.',
                ),
                owedGrandTotalValidatorV2(
                  "The total Owed amounts plus Adjustments must equal the charge's Billed amount plus Tax.",
                ),
                {
                  error:
                    'The allocated amount may not exceed the patient’s owed amount.',
                  validate: (_, idx, state) =>
                    validatePatientOwedAmountAgainstAllocatedAmount(
                      idx[0],
                      this.model,
                      state,
                    ),
                },
              ],
            }),
            primaryOwed: selectors.currency({
              validateManually: true,
              validateRaw: true,
              validators: [
                owedGrandTotalValidatorV2(
                  "The total Owed amounts plus Adjustments must equal the charge's Billed amount plus Tax.",
                ),
                {
                  error:
                    'The allocated amount may not exceed the payer’s owed amount.',
                  validate: (_, idx, state) =>
                    validatePrimaryOwedAmountAgainstAllocatedAmount(
                      idx[0],
                      this.model,
                      state,
                    ),
                },
              ],
            }),
            patientAllocated: selectors.currency({
              validateManually: true,
              validateRaw: true,
              validators: [
                {
                  error:
                    'The allocated amount may not exceed the owed or available amount.',
                  validate: (_, idx, state) =>
                    validatePatientAllocatedAmountAgainstAvailableOwedAmount(
                      idx[0],
                      this.__selectedPayment.available,
                      this.model,
                      state,
                    ),
                },
                {
                  error:
                    'The allocated amount may not exceed the patient’s available amount.',
                  validate: (_, idx, state) =>
                    validatePatientAllocatedAmountAgainstAvailableAmount(
                      idx[0],
                      this.__selectedPayment.available,
                      this.model,
                      state,
                    ),
                },
                {
                  error:
                    'The allocated amount may not exceed the patient’s owed amount.',
                  validate: (_, idx, state) =>
                    validatePatientAllocatedAmountAgainstOwedAmount(
                      idx[0],
                      this.model,
                      state,
                    ),
                },
              ],
            }),
            primaryAllocated: selectors.currency({
              validateManually: true,
              validateRaw: true,
              validators: [
                {
                  error:
                    'The allocated amount may not exceed the owed or available amount.',
                  validate: (_, idx, state) =>
                    validatePrimaryAllocatedAmountAgainstAvailableOwedAmount(
                      idx[0],
                      this.__selectedPayment.available,
                      this.model,
                      state,
                    ),
                },
                {
                  error:
                    'The allocated amount may not exceed the payer’s available amount.',
                  validate: (_, idx, state) =>
                    validatePrimaryAllocatedAmountAgainstAvailableAmount(
                      idx[0],
                      this.__selectedPayment.available,
                      this.model,
                      state,
                    ),
                },
                {
                  error:
                    'The allocated amount may not exceed the payer’s owed amount.',
                  validate: (_, idx, state) =>
                    validatePrimaryAllocatedAmountAgainstOwedAmount(
                      idx[0],
                      this.model,
                      state,
                    ),
                },
              ],
            }),
            patientPaid: selectors.currency({
              unsafe: true,
              ignorePristine: true,
            }),
            primaryPaid: selectors.currency({
              unsafe: true,
              ignorePristine: true,
            }),
            nonPrimaryPayerDebits: {
              createItem: () => ({
                patientInsuranceId: '',
                payerId: '',
                secondaryAllocated: 0,
                secondaryOwed: 0,
                secondaryPaid: 0,
                secondaryPayer: { alias: '' },
              }),
              children: {
                $: {
                  children: {
                    secondaryAllocated: {
                      validators: [
                        {
                          error:
                            'The allocated amount may not exceed the owed or available amount.',
                          validate: (_, idx, state) =>
                            validateNonPrimaryAllocatedAmountAgainstAvailableOwedAmount(
                              idx[0],
                              idx[2],
                              this.__selectedPayment,
                              this.model,
                              state,
                            ),
                        },
                        {
                          error:
                            'The allocated amount may not exceed the payer’s available amount.',
                          validate: (_, idx, state) =>
                            validateNonPrimaryAllocatedAmountAgainstAvailableAmount(
                              idx[0],
                              idx[2],
                              this.__selectedPayment,
                              this.model,
                              state,
                            ),
                        },
                        {
                          error:
                            'The allocated amount may not exceed the payer’s owed amount.',
                          validate: (_, idx, state) =>
                            validateNonPrimaryAllocatedAmountAgainstOwedAmount(
                              idx[0],
                              idx[2],
                              this.model,
                              state,
                            ),
                        },
                      ],
                    },
                    secondaryOwed: {
                      validators: [
                        owedGrandTotalValidatorV2(
                          "The total Owed amounts plus Adjustments must equal the charge's Billed amount plus Tax.",
                        ),
                        {
                          error:
                            'The allocated amount may not exceed the payer’s owed amount.',
                          validate: (_, idx, state) =>
                            validateNonPrimaryOwedAmountAgainstAllocatedAmount(
                              idx[0],
                              idx[2],
                              this.model,
                              state,
                            ),
                        },
                      ],
                    },
                  },
                },
              },
            },
            adjustments: AllocationChargesTable2.createAdjustmentsSelectors(),
            debits: AllocationChargesTable2.createDebitSelectors(),
            patient: { unsafe: true, clipPristine: true },
            locations: selectors.multiSelect(this.locations, []),
          },
        },
      },
    };
  }

  __getRow(rowIndex) {
    const row = this.state[rowIndex];
    return {
      ...row,
      isCarePackageWithInsurance:
        Boolean(row.patientPackageId) && row.billType === BILL_TYPE.INSURANCE,
    };
  }

  __getRowAndRowIndexFromName(name) {
    const rowIndex = name.split('.')[0];
    const row = this.__getRow(rowIndex);

    return { row, rowIndex };
  }

  __getNonPrimaryPayerDebitIndexFromName(name) {
    const nonPrimaryPayerDebitIdx = name.split('.')[2];

    return { nonPrimaryPayerDebitIdx };
  }

  __deleteZeroValueNonPrimaryPayerDebits() {
    this.state.forEach(({ debits, nonPrimaryPayerDebits }, rowIndex) => {
      for (
        let nonPrimaryPayerDebitIdx = nonPrimaryPayerDebits.length - 1;
        nonPrimaryPayerDebitIdx >= 0;
        nonPrimaryPayerDebitIdx -= 1
      ) {
        const {
          secondaryAllocated,
          secondaryOwed,
          secondaryPaid,
          payerId: nonPrimaryPayerId,
          patientInsuranceId: nonPrimaryPatientInsuranceId,
        } = nonPrimaryPayerDebits[nonPrimaryPayerDebitIdx];

        if (
          secondaryAllocated === 0 &&
          secondaryOwed === 0 &&
          secondaryPaid === 0
        ) {
          for (let debitIdx = debits.length - 1; debitIdx >= 0; debitIdx -= 1) {
            const {
              allocated,
              amount,
              payerId: debitPayerId,
              patientInsuranceId: debitPatientInsuranceId,
            } = debits[debitIdx];

            if (
              currencyToCents(allocated) === 0 &&
              currencyToCents(amount) === 0 &&
              debitPayerId === nonPrimaryPayerId &&
              debitPatientInsuranceId === nonPrimaryPatientInsuranceId
            ) {
              this.formService.removeItem(`${rowIndex}.debits`, debitIdx);
            }
          }

          this.formService.removeItem(
            `${rowIndex}.nonPrimaryPayerDebits`,
            nonPrimaryPayerDebitIdx,
          );
        }
      }
    });
  }

  __deleteZeroValueAdjustments() {
    this.state.forEach(({ adjustments }, rowIndex) => {
      if (
        adjustments.length === 1 &&
        adjustments[0].id === '' &&
        currencyToCents(adjustments[0].amount) === 0
      ) {
        this.__deleteIndividualItems(rowIndex, 'adjustments', [
          {
            individualRowId: adjustments[0].id,
            codeId: adjustments[0].codePaymentId,
            amount: currencyToCents(adjustments[0].amount),
          },
        ]);
      }
    });
  }

  __resetZeroValuePatientDebit() {
    this.state.forEach((row, rowIndex) => {
      const { debits, debitIndexes } = getPatientDebits(row);

      if (
        debits.length === 1 &&
        currencyToCents(debits[0].amount) === 0 &&
        currencyToCents(debits[0].allocated) === 0
      ) {
        this.formService.apply(
          `${rowIndex}.debits.${debitIndexes}.codePaymentId`,
          '',
        );
      }
    });
  }

  __deleteIndividualItems(rowIndex, type, itemsToDelete) {
    itemsToDelete.forEach(itemToDelete => {
      const row = this.__getRow(rowIndex);
      const indexToDelete = row[type].findIndex(
        individualType => individualType.id === itemToDelete.individualRowId,
      );

      this.formService.removeItem(`${rowIndex}.${type}`, indexToDelete);
    });
  }

  __debitWithNoIdLength(rowIndex, type, nonPrimaryPatientInsuranceId) {
    let length;

    const row = this.__getRow(rowIndex);

    switch (type) {
      case 'patientOwed':
        length = row.debits.filter(
          debit => isPatientRespDebit(debit) && !debit.id,
        ).length;

        break;

      case 'secondaryOwed':
        length = row.debits.filter(
          debit =>
            isPayerRespDebit(debit) &&
            !debit.id &&
            debit.patientInsuranceId === nonPrimaryPatientInsuranceId,
        ).length;

        break;

      default:
        break;
    }

    return length;
  }

  __debitWithNoIdIndex(rowIndex, type, nonPrimaryPatientInsuranceId) {
    let index;

    const row = this.__getRow(rowIndex);

    switch (type) {
      case 'patientOwed':
        index = row.debits.findIndex(
          debit => isPatientRespDebit(debit) && !debit.id,
        );

        break;

      case 'secondaryOwed':
        index = row.debits.findIndex(
          debit =>
            isPayerRespDebit(debit) &&
            !debit.id &&
            debit.patientInsuranceId === nonPrimaryPatientInsuranceId,
        );

        break;

      default:
        break;
    }

    return index;
  }

  __adjustmentWithNoIdIndex(rowIndex) {
    const row = this.__getRow(rowIndex);

    return row.adjustments.findIndex(adj => !adj.id);
  }

  __existingDebitIndex(rowIndex, itemToEdit) {
    const row = this.__getRow(rowIndex);

    return row.debits.findIndex(
      debit => debit.id === itemToEdit.individualRowId,
    );
  }

  __existingAdjustmentIndex(rowIndex, itemToEdit) {
    const row = this.__getRow(rowIndex);

    return row.adjustments.findIndex(
      adj => adj.id === itemToEdit.individualRowId,
    );
  }

  __addNonPrimaryPayerDebits(rowIndex, { patientInsuranceId, payerId, alias }) {
    const row = this.__getRow(rowIndex);
    const indexToAdd = row.nonPrimaryPayerDebits.length;

    this.formService.addItem(`${rowIndex}.nonPrimaryPayerDebits`, indexToAdd);

    this.formService.apply(
      `${rowIndex}.nonPrimaryPayerDebits.${indexToAdd}.patientInsuranceId`,
      patientInsuranceId,
    );

    this.formService.apply(
      `${rowIndex}.nonPrimaryPayerDebits.${indexToAdd}.payerId`,
      payerId,
    );

    this.formService.apply(
      `${rowIndex}.nonPrimaryPayerDebits.${indexToAdd}.secondaryAllocated`,
      0,
    );

    this.formService.apply(
      `${rowIndex}.nonPrimaryPayerDebits.${indexToAdd}.secondaryOwed`,
      0,
    );

    this.formService.apply(
      `${rowIndex}.nonPrimaryPayerDebits.${indexToAdd}.secondaryPaid`,
      0,
    );

    this.formService.apply(
      `${rowIndex}.nonPrimaryPayerDebits.${indexToAdd}.secondaryPayer.alias`,
      alias,
    );

    return indexToAdd;
  }

  __deleteItemsBeforeAdding(rowIndex, type, nonPrimaryPatientInsuranceId) {
    const isDebit = type !== 'adjustments';
    const attribute = isDebit ? 'debits' : 'adjustments';
    const row = this.__getRow(rowIndex);

    let countAddedItemsToDelete = 0;

    if (isDebit) {
      countAddedItemsToDelete = this.__debitWithNoIdLength(
        rowIndex,
        type,
        nonPrimaryPatientInsuranceId,
      );
    } else {
      countAddedItemsToDelete = row.adjustments.filter(adj => !adj.id).length;
    }

    while (countAddedItemsToDelete) {
      const indexToDelete = isDebit
        ? this.__debitWithNoIdIndex(
            rowIndex,
            type,
            nonPrimaryPatientInsuranceId,
          )
        : this.__adjustmentWithNoIdIndex(rowIndex);

      if (indexToDelete !== -1) {
        this.formService.removeItem(`${rowIndex}.${attribute}`, indexToDelete);
      }

      countAddedItemsToDelete -= 1;
    }
  }

  __addIndividualItems(
    rowIndex,
    type,
    itemsToAdd,
    nonPrimaryPatientInsuranceId,
  ) {
    const isDebit = type !== 'adjustments';
    const attribute = isDebit ? 'debits' : 'adjustments';

    this.__deleteItemsBeforeAdding(
      rowIndex,
      type,
      nonPrimaryPatientInsuranceId,
    );

    const codeId = isDebit ? 'codePaymentId' : 'codeId';

    itemsToAdd.forEach(itemToAdd => {
      const row = this.__getRow(rowIndex);
      const indexToAdd = row[attribute].length;
      this.formService.addItem(`${rowIndex}.${attribute}`);
      this.formService.apply(
        `${rowIndex}.${attribute}.${indexToAdd}.${codeId}`,
        itemToAdd.codeId,
      );

      this.formService.apply(
        `${rowIndex}.${attribute}.${indexToAdd}.amount`,
        centsToCurrency(itemToAdd.amount),
      );

      if (itemToAdd.patientInsuranceId && itemToAdd.payerId) {
        this.formService.apply(
          `${rowIndex}.${attribute}.${indexToAdd}.payerId`,
          itemToAdd.payerId,
        );

        this.formService.apply(
          `${rowIndex}.${attribute}.${indexToAdd}.patientInsuranceId`,
          itemToAdd.patientInsuranceId,
        );
      }
    });
  }

  __editIndividualItems(rowIndex, type, itemsToEdit) {
    const isDebit = type !== 'adjustments';

    itemsToEdit.forEach(itemToEdit => {
      const indexToEdit = isDebit
        ? this.__existingDebitIndex(rowIndex, itemToEdit)
        : this.__existingAdjustmentIndex(rowIndex, itemToEdit);

      const codeId = isDebit ? 'codePaymentId' : 'codeId';

      this.formService.apply(
        `${rowIndex}.${type}.${indexToEdit}.${codeId}`,
        itemToEdit.codeId,
      );

      this.formService.apply(
        `${rowIndex}.${type}.${indexToEdit}.amount`,
        centsToCurrency(itemToEdit.amount),
      );
    });
  }

  __applyAllocatedToDebits({
    rowIndex,
    debits,
    debitIndexes,
    allocationAmount,
  }) {
    const debitAllocationAmounts = buildDebitAllocationAmounts({
      debits: mapDebitAmountsToCents(debits),
      debitIndexes,
      allocationAmount,
    });

    debitAllocationAmounts.forEach(({ debitIndex, allocated }) => {
      this.formService.apply(
        `${rowIndex}.debits.${debitIndex}.allocated`,
        centsToCurrency(allocated),
      );
    });
  }

  __syncOwedAndPaid({ rowIndex, type, nonPrimaryPatientInsuranceId }) {
    const row = this.__getRow(rowIndex);

    let debits;

    switch (type) {
      case 'patient':
        ({ debits } = getPatientDebits(row));
        break;
      case 'primary':
        ({ debits } = getPrimaryPayerDebits(row));
        break;
      case 'secondary':
        ({ debits } = getSecondaryPayerDebits(
          row,
          nonPrimaryPatientInsuranceId,
        ));

        break;
      default:
        return;
    }

    const owed = calculateDebitsOwed(debits, true);
    const paid = calculateDebitsPaid(debits, true);

    if (type === 'secondary') {
      row.nonPrimaryPayerDebits.forEach((value, index) => {
        if (value.patientInsuranceId === nonPrimaryPatientInsuranceId) {
          this.formService.apply(
            `${rowIndex}.nonPrimaryPayerDebits.${index}.secondaryOwed`,
            currencyToCents(owed),
          );

          this.formService.apply(
            `${rowIndex}.nonPrimaryPayerDebits.${index}.secondaryPaid`,
            currencyToCents(paid),
          );
        }
      });
    } else {
      this.formService.apply(`${rowIndex}.${type}Owed`, owed);
      this.formService.apply(`${rowIndex}.${type}Paid`, paid);
    }
  }

  __clearAllocatedIfDebitsRemoved({ rowIndex, type }) {
    const row = this.__getRow(rowIndex);

    let debits;

    if (type === 'patient') {
      ({ debits } = getPatientDebits(row));
    } else {
      return;
    }

    if (debits.length === 0 || hasOnlyOnePaidPkgDebit(debits)) {
      this.formService.apply(`${rowIndex}.${type}Allocated`, '$0.00');
    }
  }

  __syncAllocated({
    rowIndex,
    field,
    nonPrimaryPatientInsuranceId,
    nonPrimaryPayerDebitIdx,
  }) {
    const row = this.__getRow(rowIndex);

    let debits;
    let debitIndexes;

    switch (field) {
      case 'patientAllocated':
        ({ debits, debitIndexes } = getPatientDebits(row));
        break;
      case 'primaryAllocated':
        ({ debits, debitIndexes } = getPrimaryPayerDebits(row));
        break;
      case 'secondaryAllocated':
        ({ debits, debitIndexes } = getSecondaryPayerDebits(
          row,
          nonPrimaryPatientInsuranceId,
        ));

        break;
      default:
        return;
    }

    const allocationAmount =
      field === 'secondaryAllocated'
        ? row.nonPrimaryPayerDebits[nonPrimaryPayerDebitIdx][field]
        : currencyToCents(row[field]);

    this.__applyAllocatedToDebits({
      rowIndex,
      debits,
      debitIndexes,
      allocationAmount,
    });
  }

  __debitsChanged({ itemsToAdd, itemsToEdit, itemsToDelete }, debits) {
    if (itemsToAdd.length || itemsToDelete.length) return true;

    return !itemsToEdit.every(
      (v, idx) =>
        v.individualRowId === debits[idx].id &&
        v.codeId === debits[idx].codePaymentId &&
        v.amount === currencyToCents(debits[idx].amount),
    );
  }

  async rerenderPatientInfo() {
    const patientIds = [...new Set(this.state.map(ch => ch.patientId))];

    const patients = await patientApiClient.fetchSome(
      patientIds,
      {},
      false,
      true,
    );

    patients.forEach((patient, index) =>
      this.formService.apply(`${index}.patient`, patient),
    );
  }

  async __getMenuItemsMap() {
    if (this.hasOmegaInitialAllocationPerformanceFF) {
      const formattedPaymentTypes = this.metaData.codePaymentItems
        .filter(item => item.forInsurance || item.forPatient)
        .map(item => profiles[TYPE.PAYMENT].toModel(item))
        .map(data => ({
          label: data.code,
          data,
        }));

      return {
        insurances: this.metaData.patientsInsurances,
        adjustments: this.metaData.codeWriteOffItems,
        paymentTypes: formattedPaymentTypes,
      };
    }

    const patientInsurances = await this.__getPatientInsurances();
    const [insurances, adjustments, paymentTypes] = await Promise.all([
      patientInsurances,
      getBillingCodesWriteOffs(),
      fetchPaymentTypeItems(),
    ]);

    return {
      insurances,
      adjustments,
      paymentTypes,
    };
  }

  async __getPatientInsurances() {
    const patientId = this.itemFilters.patient?.data?.id;
    const patientIds = this.patients
      .filter(p => p.data.id && (!patientId || p.data.id === patientId))
      .map(p => p.data.id);

    const insurances = await fetchInsurancesFromPatients(patientIds);
    return insurances.map(insurance => ({
      ...insurance,
      patientId: insurance.data.patientId,
    }));
  }

  __getTableId(tab) {
    if (tab === ALLOCATE_PAYMENT_TAB.ALLOCATED) {
      return ELEMENTS.allocationAllocatedChargesTable.id;
    }
    return ELEMENTS.allocationChargesTable.id;
  }

  __addOrRemoveRow(selectedIndex, li, propName) {
    const origLength =
      this.formService.__initialState[selectedIndex][propName].length;
    const modLength = li[propName].length;

    const diffLength = modLength - origLength;
    const action = diffLength && (diffLength > 0 ? 'removeItem' : 'addItem');

    Array(Math.abs(diffLength))
      .fill()
      .forEach(() => this.formService[action](`${selectedIndex}.${propName}`));
  }

  __defaultAdjustmentCodeId(rowIndex) {
    return this.state[rowIndex].billType === BILL_TYPE.INSURANCE
      ? CODE_WRITE_OFFS.NEGOTIATED_RATE.id
      : CODE_WRITE_OFFS.WRITE_OFF.id;
  }

  __updateAdjustmentAmount(row, rowIndex, amount) {
    const adjustments = row.adjustments.filter(adjustment => {
      if (adjustment.codeId === CODE_WRITE_OFFS.PACKAGE.id) {
        amount -= currencyToCentsWithNegative(adjustment.amount);
        return false;
      }
      return true;
    });

    switch (adjustments.length) {
      case 0:
        if (amount) {
          this.__addIndividualItems(rowIndex, 'adjustments', [
            { codeId: this.__defaultAdjustmentCodeId(rowIndex), amount },
          ]);
        }

        break;

      case 1:
        if (amount) {
          this.__editIndividualItems(rowIndex, 'adjustments', [
            {
              individualRowId: adjustments[0].id,
              codeId: adjustments[0].codeId,
              amount,
            },
          ]);
        } else {
          const previousCode = adjustments[0].codeId;

          this.__deleteIndividualItems(rowIndex, 'adjustments', [
            {
              individualRowId: adjustments[0].id,
              codeId: adjustments[0].codeId,
              amount,
            },
          ]);

          this.__addIndividualItems(rowIndex, 'adjustments', [
            { codeId: previousCode, amount: 0 },
          ]);
        }

        break;

      default:
        break;
    }
  }

  __updateAllowedAmountFromAdjustments(rowIndex) {
    const row = this.state[rowIndex];
    const adjustmentsSum = calculateAdjustmentsSum(row.adjustments);

    const newAllowedAmount = calculateBalancedAllowedAmount(
      row.billedAmount,
      row.taxAmount,
      adjustmentsSum,
    );

    this.formService.apply(
      `${rowIndex}.allowedAmount`,
      centsToCurrency(newAllowedAmount),
    );
  }

  __revertDebit(selectedIndex, initialSelectState) {
    initialSelectState.debits.forEach((_, index) => {
      const path = `${selectedIndex}.debits.${index}.`;
      const initialDebit = initialSelectState.debits[index];

      const fields = [
        'codePaymentId',
        'patientInsuranceId',
        'payerId',
        'id',
        'allocated',
        'amount',
      ];

      fields.forEach(field =>
        this.formService.apply(`${path}${field}`, initialDebit[field]),
      );
    });
  }

  __revertAdjustment(selectedIndex, initialSelectState) {
    initialSelectState.adjustments.forEach((_, index) => {
      const path = `${selectedIndex}.adjustments.${index}.`;
      const selectAdjustment = initialSelectState.adjustments[index];
      const fields = ['amount', 'codeId'];

      fields.forEach(field =>
        this.formService.apply(`${path}${field}`, selectAdjustment[field]),
      );
    });
  }

  __revertNonPrimaryPayerDebits(selectedIndex, initialSelectState) {
    let countAddedItemsToDelete =
      this.state[selectedIndex].nonPrimaryPayerDebits.length;

    while (countAddedItemsToDelete !== -1) {
      this.formService.removeItem(
        `${selectedIndex}.nonPrimaryPayerDebits`,
        countAddedItemsToDelete,
      );

      countAddedItemsToDelete -= 1;
    }

    initialSelectState.nonPrimaryPayerDebits.forEach((_, index) => {
      this.formService.addItem(`${selectedIndex}.nonPrimaryPayerDebits`, index);

      const path = `${selectedIndex}.nonPrimaryPayerDebits.${index}.`;
      const initialNonPrimaryPayerDebit =
        initialSelectState.nonPrimaryPayerDebits[index];

      const fields = [
        'patientInsuranceId',
        'payerId',
        'secondaryAllocated',
        'secondaryOwed',
        'secondaryPaid',
      ];

      fields.forEach(field => {
        this.formService.apply(
          `${path}${field}`,
          initialNonPrimaryPayerDebit[field],
        );

        this.formService.apply(
          `${path}secondaryPayer.alias`,
          initialNonPrimaryPayerDebit.secondaryPayer.alias,
        );
      });
    });
  }

  __revertLineItem(selectedIndex, initialSelectState) {
    const path = `${selectedIndex}.`;

    const fields = [
      'allowedAmount',
      'patientOwed',
      'primaryOwed',
      'patientAllocated',
      'primaryAllocated',
      'patientPaid',
      'primaryPaid',
    ];

    fields.forEach(field =>
      this.formService.apply(`${path}${field}`, initialSelectState[field]),
    );
  }

  __revertRow(selectedIndex) {
    if (this.formService.isDirty) {
      const li = this.state[selectedIndex];

      this.__addOrRemoveRow(selectedIndex, li, 'debits');
      this.__addOrRemoveRow(selectedIndex, li, 'adjustments');

      const initialSelectState = this.formService.__initialState[selectedIndex];

      this.__revertDebit(selectedIndex, initialSelectState);
      this.__revertAdjustment(selectedIndex, initialSelectState);
      this.__revertLineItem(selectedIndex, initialSelectState);
      this.__revertNonPrimaryPayerDebits(selectedIndex, initialSelectState);

      this.formService.validateRow(selectedIndex);
    }
  }

  __isPayerPayment() {
    return (
      this.payments && this.payments.length && isPayerPayment(this.payments[0])
    );
  }

  __toggleRemoveAllocation() {
    return !this.__selectIndexes.some(index => index);
  }

  getChecked(payment) {
    return (
      this.payments.length === 1 ||
      (this.__selectedPayment && this.__selectedPayment.id === payment.id)
    );
  }

  getPaymentModel() {
    if (!this.payments?.length) return [];

    const paymentModel = this.payments.map(payment => {
      const {
        providerAdjustments = [],
        paymentAmount = payment.paymentAmount ?? payment.amount,
        paymentAllocated = payment.paymentAllocated ??
          paymentAmount - payment.available,
      } = payment;

      const available =
        paymentAmount -
        paymentAllocated +
        getProviderAdjustmentsTotal(providerAdjustments);

      return {
        ...payment,
        checked: this.getChecked(payment),
        allocated: paymentAllocated,
        available,
      };
    });

    this.__selectedPayment =
      paymentModel.find(p => p.id === this.__selectedPayment?.id) ||
      this.payments[0];

    return paymentModel;
  }

  __formatLocations(locations) {
    return locations.map(location => ({
      data: location,
      label: location.name,
    }));
  }

  __getCurrentUncheckedIds() {
    return this.selectedTab === ALLOCATE_PAYMENT_TAB.OUTSTANDING
      ? this.__unchecked.outstandingIds
      : this.__unchecked.allocatedIds;
  }

  __scrollListener(content) {
    this.__showScrollToTopButton = content.scrollTop > 300;
  }

  __getPatientSelectSearchValue() {
    const { patient } = this.itemFilters;

    if (patient && patient.data.id === '') {
      return this.__patientSearch;
    }

    return this.__useItemFilterPatient ? patient : this.__patientSearch;
  }

  __getPatientSelectSearchLabel() {
    return this.selectedTab === ALLOCATE_PAYMENT_TAB.OUTSTANDING
      ? 'Select a Patient To Filter'
      : 'Patient';
  }

  firstUpdated() {
    this.content = this.shadowRoot.querySelector('.content');
    this.content?.addEventListener('scroll', () =>
      this.__scrollListener(this.content),
    );
  }

  async connectedCallback() {
    this.__notificationService.connect();
    const features = await getFeatures();

    this.__hasAllocationsPatientSearchFeatureFlag = features.includes(
      FEATURE_FLAGS.ALLOCATIONS_PATIENT_SEARCH,
    );

    this.__hasApplyResetAllocateFitFeatureFlag = features.includes(
      FEATURE_FLAGS.APPLY_RESET_ALLOCATE_FIT,
    );

    if (isPayerPayment(this.__selectedPayment)) {
      this.confirmLabel = 'Save';
    }

    super.connectedCallback();
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.content?.removeEventListener('scroll', () =>
      this.__scrollListener(this.content),
    );

    this.__notificationService.disconnect();
  }

  __changeButtonLabel() {
    if (isPayerPayment(this.__selectedPayment)) {
      this.confirmLabel = 'Save';
    }
  }

  __shouldCheckItems() {
    return (
      !this.fromEraEobPage ||
      this.selectedTab === ALLOCATE_PAYMENT_TAB.ALLOCATED
    );
  }

  __shouldDirtyOnSelect() {
    return (
      isPayerPayment(this.__selectedPayment) &&
      this.selectedTab === ALLOCATE_PAYMENT_TAB.OUTSTANDING
    );
  }

  update(changedProps) {
    if (changedProps.has('__selectedPayment') || changedProps.has('model')) {
      const uncheckedIds = this.__getCurrentUncheckedIds();

      this.__selectIndexes = this.__shouldCheckItems()
        ? this.model.map(
            x => !!this.payments?.length && !uncheckedIds.includes(x.id),
          )
        : this.model.map(({ id }) => this.associatedLineItemIds.includes(id));

      this.__previousSelectIndexes = [...this.__selectIndexes];
    }

    if (changedProps.has('__selectedPayment') && this.model.length) {
      this.reload();
    }

    if (changedProps.has('model')) {
      this.__preallocated = this.model.map(li =>
        li.debits
          ? li.debits.reduce((sum, debit) => sum + debit.allocated, 0)
          : 0,
      );
    }

    if (changedProps.has('payments')) {
      if (this.payments.length && this.__selectedPayment) {
        const selectedIndex = this.payments.findIndex(
          p => p.id === this.__selectedPayment.id,
        );

        this.__selectedPayment = this.payments[selectedIndex];
      } else {
        this.__selectedPayment = this.payments?.length
          ? this.payments[0]
          : null;
      }

      this.reload();
    }

    if (changedProps.has('__selectedPayment')) {
      this.__changeButtonLabel();
    }

    super.update(changedProps);
  }

  async updated(changedProps) {
    if (changedProps.has('patients') && this.patients.length > 0) {
      this.__menuItemsMap = await this.__getMenuItemsMap();
      this.build();
    }

    if (changedProps.has('__selectIndexes')) {
      const uncheckedIds = [];
      this.__selectIndexes.forEach((checked, index) => {
        if (!checked) {
          uncheckedIds.push(this.state[index].id);
        }
      });

      if (this.selectedTab === ALLOCATE_PAYMENT_TAB.OUTSTANDING) {
        this.__unchecked.outstandingIds = uncheckedIds;
      } else {
        this.__unchecked.allocatedIds = uncheckedIds;
      }

      if (this.__shouldDirtyOnSelect()) {
        const hasChanged =
          this.__selectIndexes.length !== this.__previousSelectIndexes.length ||
          !this.__selectIndexes.every(
            (value, i) => value === this.__previousSelectIndexes[i],
          );

        if (hasChanged) {
          this.__dirty = true;
        } else if (!this.formService.isDirty) {
          this.__dirty = false;
        }
      }
    }

    if (changedProps.has('patients') || changedProps.has('payments')) {
      this.__paymentModel = this.getPaymentModel();

      this.__notificationService.update({
        patient: { id: this.patientId },
      });
    }
  }

  static get styles() {
    return [
      super.styles,
      css`
        .container-filter-old {
          padding: ${CSS_SPACING};
          display: grid;
          grid-template-columns: repeat(4, 3fr);
          grid-gap: ${CSS_SPACING};
          width: 100%;
          padding-top: ${CSS_SPACING};
          z-index: 2;
        }

        .container-filter {
          padding: ${CSS_SPACING};
          display: grid;
          grid-template-columns: repeat(4, 1fr) auto auto;
          grid-column-gap: ${CSS_SPACING};
          width: 100%;
          padding-top: ${CSS_SPACING};
          z-index: 2;
        }

        .align-self-center {
          align-self: center;
        }

        .content {
          position: relative;
        }

        .field {
          width: 100%;
        }

        .padding-bottom {
          padding-bottom: 130px;
        }

        .container-totals {
          display: grid;
          grid-template-columns: auto auto 3fr;
          grid-gap: ${CSS_SPACING};
          width: 100%;
          padding: ${CSS_SPACING};
          background-color: ${CSS_COLOR_GREY_3};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          margin-bottom: ${CSS_SPACING};
        }

        .content-padding {
          padding: 0 ${CSS_SPACING} ${CSS_SPACING};
        }

        .button-remove-allocation {
          width: max-content;
          padding-left: ${CSS_SPACING};
          z-index: 1;
        }

        .button-top {
          display: flex;
          position: fixed;
          width: max-content;
          margin: 6px auto;
          padding: 8px 20px;
          left: 0;
          bottom: 0;
          right: 0;
          color: ${CSS_COLOR_WHITE};
          background-color: ${CSS_COLOR_BLUE_BORDER};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          outline: none;
          overflow: hidden;
          border: none;
          border-radius: 18px;
        }

        .button-top:hover {
          opacity: 0.8;
          transition: opacity 300ms;
        }

        .icon-up-arrow {
          display: flex;
          height: 15px;
          width: 15px;
          margin-left: 8px;
          transform: rotate(180deg);
          fill: ${CSS_COLOR_WHITE};
        }
      `,
    ];
  }

  __renderSelectedTab() {
    const item = this.__navItems.find(e => e.id === this.selectedTab);

    return item ? item.renderer() : this.__navItems[0].renderer();
  }

  __renderChargesTabs() {
    return html`
      <neb-tab-group
        id="${ELEMENTS.tabs.id}"
        class="tabs"
        .layout="${this.layout}"
        .items="${this.__navItems}"
        .selectedId="${this.selectedTab}"
        .onSelect="${this.handlers.selectTab}"
      ></neb-tab-group>

      ${this.__renderSelectedTab()}
    `;
  }

  __renderOutstandingCharges() {
    return html`
      <neb-header
        id="${ELEMENTS.header.id}"
        .label="${ELEMENTS.header.title}"
        .description="${ELEMENTS.header.description}"
      ></neb-header>

      ${this.__renderFilterFields()}

        <neb-table-allocation-charges-2
          id="${ELEMENTS.allocationChargesTable.id}"
          class="padding-bottom field"
          emptyMessage="${NO_OUTSTANDING_CHARGES}"
          .model="${this.state}"
          .patientId="${this.patientId}"
          .paymentDetail="${this.__selectedPayment || {}}"
          .allocatableId="${
            (this.__selectedPayment && this.__selectedPayment.payerPlanId) ||
            null
          }"
          .preallocated="${this.__preallocated}"
          .selectIndexes="${this.__selectIndexes}"
          .menuItems="${this.__menuItemsMap}"
          .payers="${this.payers}"
          .errors="${this.errors}"
          .hasErrors="${this.formService.hasErrors}"
          .onItemCheck="${this.handlers.checkItem}"
          .onChangeAllowed="${this.handlers.changeAllowed}"
          .onSelectEncounter="${this.handlers.viewEncounterSummary}"
          .onClickInvoiceLink="${this.handlers.viewCharge}"
          .onClickEditChargeLink="${this.handlers.editCharge}"
          .onClickPaymentLink="${this.handlers.viewPayment}"
          .onSelectPayer="${this.handlers.selectPayer}"
          .onOpenPatientOwedPopup="${this.handlers.openPatientOwedPopup}"
          .onChangePatientOwed="${this.handlers.changePatientOwed}"
          .onChangePatientAllocated="${this.handlers.changePatientAllocated}"
          .onChangePrimaryOwed="${this.handlers.changePrimaryOwed}"
          .onChangePrimaryAllocated="${this.handlers.changePrimaryAllocated}"
          .onOpenSecondaryOwedPopup="${this.handlers.openSecondaryOwedPopup}"
          .onAddSecondaryPayer="${this.handlers.addSecondaryPayer}"
          .onChangeSecondaryOwed="${this.handlers.changeSecondaryOwed}"
          .onChangeSecondaryAllocated="${
            this.handlers.changeSecondaryAllocated
          }"
          .onOpenAdjustmentsPopup="${this.handlers.openAdjustmentsPopup}"
          .onChangeAdjustments="${this.handlers.changeAdjustments}"
          .onSelectAll="${this.handlers.selectAll}"
          .onDeselectAll="${this.handlers.deselectAll}"
          ?disabled="${!(this.payments && this.payments.length)}"
          .onSearchDate="${this.handlers.searchDate}"
          .hasRCMSecondaryField="${this.hasRCMSecondaryField}"
          .onRecoup="${this.handlers.recoup}"
          .onRemoveRecoupHistory="${this.handlers.removeRecoupHistory}"
          .showAssociateLabel="${isPayerPayment(this.__selectedPayment)}"
          .isPayerPayment="${isPayerPayment(this.__selectedPayment)}"
          .selectedTab="${this.selectedTab}"
          .hasShowNextInsurance="${this.hasShowNextInsurance}"
        ></neb-table-allocation-charges-2>
      </div>
    `;
  }

  __renderAllocatedCharges() {
    return html`
      <neb-header
        id="${ELEMENTS.headerAllocatedCharges.id}"
        .label="${ELEMENTS.headerAllocatedCharges.title}"
        .description="${ELEMENTS.headerAllocatedCharges.description}"
      ></neb-header>

      ${this.__renderFilterFields()}

      <neb-button
        id="${ELEMENTS.removeAllocation.id}"
        class="button-remove-allocation"
        tabindex="-1"
        role="outline"
        label="${ELEMENTS.removeAllocation.label}"
        ?disabled="${this.__toggleRemoveAllocation()}"
        .onClick="${this.handlers.removeAllocation}"
      ></neb-button>

      <neb-table-allocation-charges-2
        id="${ELEMENTS.allocationAllocatedChargesTable.id}"
        class="padding-bottom field"
        emptyMessage="${NO_ALLOCATED_CHARGES}"
        .model="${this.state}"
        .patientId="${this.patientId}"
        .paymentDetail="${this.__selectedPayment || {}}"
        .allocatableId="${(this.__selectedPayment &&
          this.__selectedPayment.payerPlanId) ||
        null}"
        .preallocated="${this.__preallocated}"
        .selectIndexes="${this.__selectIndexes}"
        .menuItems="${this.__menuItemsMap}"
        .payers="${this.payers}"
        .errors="${this.errors}"
        .hasErrors="${this.formService.hasErrors}"
        .onItemCheck="${this.handlers.checkItem}"
        .onChangeAllowed="${this.handlers.changeAllowed}"
        .onChange="${this.handlers.change}"
        .onSelectEncounter="${this.handlers.viewEncounterSummary}"
        .onClickInvoiceLink="${this.handlers.viewCharge}"
        .onClickPaymentLink="${this.handlers.viewPayment}"
        .onSelectPayer="${this.handlers.selectPayer}"
        .onOpenPatientOwedPopup="${this.handlers.openPatientOwedPopup}"
        .onChangePatientOwed="${this.handlers.changePatientOwed}"
        .onChangePatientAllocated="${this.handlers.changePatientAllocated}"
        .onChangePrimaryOwed="${this.handlers.changePrimaryOwed}"
        .onChangePrimaryAllocated="${this.handlers.changePrimaryAllocated}"
        .onOpenSecondaryOwedPopup="${this.handlers.openSecondaryOwedPopup}"
        .onAddSecondaryPayer="${this.handlers.addSecondaryPayer}"
        .onChangeSecondaryOwed="${this.handlers.changeSecondaryOwed}"
        .onChangeSecondaryAllocated="${this.handlers.changeSecondaryAllocated}"
        .onOpenAdjustmentsPopup="${this.handlers.openAdjustmentsPopup}"
        .onChangeAdjustments="${this.handlers.changeAdjustments}"
        .onSelectAll="${this.handlers.selectAll}"
        .onDeselectAll="${this.handlers.deselectAll}"
        ?disabled="${!(this.payments && this.payments.length)}"
        .hasRCMSecondaryField="${this.hasRCMSecondaryField}"
        .onRecoup="${this.handlers.recoup}"
        .onRemoveRecoupHistory="${this.handlers.removeRecoupHistory}"
        .isPayerPayment="${isPayerPayment(this.__selectedPayment)}"
        .selectedTab="${this.selectedTab}"
        .hasShowNextInsurance="${this.hasShowNextInsurance}"
      ></neb-table-allocation-charges-2>
    `;
  }

  __renderContainerTotals() {
    const selectedItems = this.state.filter(
      (_, index) => this.__selectIndexes[index],
    );
    return html`
      <div class="container-totals">
        <span id="${ELEMENTS.selectedBalance.id}" class="field">
          Selected Balance Due: ${getSelectedBalanceDueV2(selectedItems)}
        </span>
        <span id="${ELEMENTS.selectedAvailable.id}" class="field">
          Selected Available to Allocate:
          ${this.__selectedPayment
            ? centsToCurrency(this.__selectedPayment.available)
            : '$0.00'}
        </span>
      </div>
    `;
  }

  __renderAdditionalChargesFilter() {
    if (
      !this.showAdditionalCharges ||
      this.selectedTab === ALLOCATE_PAYMENT_TAB.ALLOCATED
    ) {
      return '';
    }

    return html`
      <neb-switch
        id="${ELEMENTS.buttonAdditionalCharges.id}"
        name="additionalCharges"
        label="Additional Charges"
        class="align-self-center"
        .onChange="${this.handlers.additionalChargesChange}"
        ?on="${this.itemFilters.additionalCharges}"
      ></neb-switch>
    `;
  }

  __renderPatientFilter() {
    return this.__hasAllocationsPatientSearchFeatureFlag
      ? html`
          <neb-select-search
            id="${ELEMENTS.patientSelectSearch.id}"
            name="patient"
            class="field"
            tabindex="-1"
            label="${this.__getPatientSelectSearchLabel()}"
            .items="${this.__patientSearchResult}"
            .onChange="${this.handlers.itemsFilterChange}"
            .onSearch="${this.handlers.searchPatients}"
            .value="${this.__getPatientSelectSearchValue()}"
            ?disabled="${this.__selectedPayment &&
            this.__selectedPayment.patientId &&
            !this.enablePatientDropdown}"
            showSearch
          ></neb-select-search>
        `
      : html`
          <neb-select
            id="${ELEMENTS.selectPatient.id}"
            name="patient"
            class="field"
            tabindex="-1"
            label="Patient"
            .items="${this.patients}"
            .value="${this.itemFilters.patient}"
            .onChange="${this.handlers.itemsFilterChange}"
            ?disabled="${this.__selectedPayment &&
            this.__selectedPayment.patientId &&
            !this.enablePatientDropdown}"
          ></neb-select>
        `;
  }

  __renderFilterFields() {
    return html`
      <div
        id="${ELEMENTS.filterContainer.id}"
        class="${
          this.__hasApplyResetAllocateFitFeatureFlag
            ? 'container-filter'
            : 'container-filter-old' // remember to delete this class after removing the feature flag
        }"
      >
        <neb-date-picker
          id="${ELEMENTS.dateOfTransactionFrom.id}"
          name="dateOfTransactionFrom"
          class="field"
          tabindex="-1"
          label="Transaction From Date"
          helperText=" "
          manualPopoverPosition="${POPOVER_POSITION.CENTER}"
          .selectedDate="${this.itemFilters.dateOfTransactionFrom}"
          .isDateSelectable="${this.handlers.dateOfTransactionFromSelectable}"
          .onChange="${this.handlers.itemsFilterChange}"
          momentFlag
        ></neb-date-picker>

        <neb-date-picker
          id="${ELEMENTS.dateOfTransactionTo.id}"
          name="dateOfTransactionTo"
          class="field"
          tabindex="-1"
          label="Transaction To Date"
          helperText=" "
          manualPopoverPosition="${POPOVER_POSITION.CENTER}"
          .selectedDate="${this.itemFilters.dateOfTransactionTo}"
          .isDateSelectable="${this.handlers.dateOfTransactionToSelectable}"
          .onChange="${this.handlers.itemsFilterChange}"
          momentFlag
        ></neb-date-picker>

        ${this.__renderPatientFilter()}

        <neb-select
          id="${ELEMENTS.selectLocations.id}"
          name="locations"
          class="field"
          tabindex="-1"
          label="Locations"
          .items="${this.__formatLocations(this.locations)}"
          .value="${this.itemFilters.locations}"
          .onChange="${this.handlers.itemsFilterChange}"
          multiSelect
        ></neb-select>

        ${this.__renderAdditionalChargesFilter()} ${this.__renderApplyButton()}
      </div>
    `;
  }

  __renderApplyButton() {
    if (!this.__hasApplyResetAllocateFitFeatureFlag) {
      return '';
    }

    return html`
      <neb-button
        id="${ELEMENTS.applyFilterButton.id}"
        label="Apply"
        class="align-self-center"
        .onClick="${this.handlers.applyFilter}"
      ></neb-button>
    `;
  }

  __renderScrollToTopButton() {
    return this.__showScrollToTopButton
      ? html`
          <button
            id="${ELEMENTS.buttonScrollToTop.id}"
            class="button-top"
            @click="${this.handlers.scrollToTop}"
          >
            GO TO TOP
            <neb-icon class="icon-up-arrow" icon="neb:arrow"></neb-icon>
          </button>
        `
      : '';
  }

  renderContent() {
    return html`
      <div id="${ELEMENTS.description.id}" class="content-padding">
        ${ELEMENTS.description.text}
      </div>

      <neb-table-allocation-payments
        id="${ELEMENTS.paymentsTable.id}"
        emptyMessage="${NO_PAYMENTS_TO_ALLOCATE_MESSAGE}"
        tabindex="-1"
        .layout="${this.layout}"
        .model="${this.__paymentModel}"
        .onClickRadio="${this.handlers.paymentSelected}"
        .onClickDetail="${this.handlers.viewPayment}"
        .onPayerUpdate="${this.handlers.refreshPayer}"
        .onSort="${this.handlers.onSort}"
        .onEditProviderAdjustment="${this.handlers.editProviderAdjustment}"
      ></neb-table-allocation-payments>
      ${this.__renderContainerTotals()} ${this.__renderChargesTabs()}
      ${this.__renderScrollToTopButton()}
    `;
  }
}

customElements.define('neb-form-allocation-charges', NebFormAllocationCharges);
