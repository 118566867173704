import { html, css } from 'lit';

import { NebActionBar, ELEMENTS as BASE_ELEMENTS } from './neb-action-bar';
import { BUTTON_ROLE } from './neb-button';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  autoAllocateButton: {
    id: 'button-auto-allocate',
  },
};

class NebActionBarPayment extends NebActionBar {
  static get properties() {
    return {
      ...super.properties,
      showAutoAllocateButton: {
        type: Boolean,
        reflect: true,
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .button-secondary-container {
          display: flex;
          flex-wrap: wrap;
        }

        .button {
          margin-bottom: 20px;
        }

        .button-actions {
          width: 25px;
        }
      `,
    ];
  }

  __initState() {
    super.__initState();
    this.showAutoAllocateButton = false;

    this.onAutoAllocate = () => {};
  }

  __initHandlers() {
    super.__initHandlers();

    this.__handlers = {
      ...this.__handlers,
      autoAllocate: () => this.onAutoAllocate(),
    };
  }

  __renderAutoAllocateButton() {
    return this.showAutoAllocateButton
      ? html`
          <neb-button
            id="${ELEMENTS.autoAllocateButton.id}"
            class="button"
            label="AUTO-ALLOCATE"
            .role="${BUTTON_ROLE.CONFIRM}"
            .onClick="${this.__handlers.autoAllocate}"
          ></neb-button>
        `
      : '';
  }

  render() {
    return html`
      <div class="container">
        ${this.__renderConfirmButton()}

        <div class="button-secondary-container">
          ${this.__renderAutoAllocateButton()} ${this.__renderRemoveButton()}
        </div>
      </div>
    `;
  }
}

customElements.define('neb-action-bar-payment', NebActionBarPayment);
