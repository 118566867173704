export const SEX = ['Male', 'Female', 'Unknown'];
export const SEX_ALLOW_EMPTY = ['', ...SEX];
export const EMPLOYMENT_STATUS = [
  'Employed',
  'Student',
  'Not Employed',
  'Retired',
  'Unknown',
];
export const PATIENT_STATUS = ['Active', 'Inactive'];
export const RELATIONSHIP_STATUS = [
  'Single',
  'Married',
  'Widowed',
  'Divorced',
  'Other',
];
export const INSURANCE = [
  'Wellpoint Inc. Group.',
  'Kaiser Foundation Group',
  'Humana Group',
  'Unitedhealth Group',
  'Aetna Group',
];
export const CONTACT_METHODS = ['Mobile', 'Work', 'Home', 'Fax'];
