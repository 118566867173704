import { openPopup } from '@neb/popup';
import equal from 'fast-deep-equal';
import { css, html } from 'lit';

import '../../../packages/neb-lit-components/src/components/neb-header';
import '../../../packages/neb-lit-components/src/components/neb-date-picker';
import '../../../packages/neb-lit-components/src/components/neb-popup-header';
import '../../../packages/neb-lit-components/src/components/neb-table-readonly';
import '../../../packages/neb-lit-components/src/components/tables/neb-table-payment-transaction';
import { getLedgerInvoiceItems } from '../../../packages/neb-api-client/src/invoice-api-client';
import * as patientApiClient from '../../../packages/neb-api-client/src/patient-api-client';
import { getPaymentDetail } from '../../../packages/neb-api-client/src/payments-api-client';
import NebForm, {
  ELEMENTS as ELEMENTS_BASE,
} from '../../../packages/neb-lit-components/src/components/forms/neb-form';
import { POPOVER_POSITION } from '../../../packages/neb-lit-components/src/components/neb-popover';
import { AllocationChargesTable2 } from '../../../packages/neb-lit-components/src/components/tables/neb-table-allocation-charges-2';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../packages/neb-lit-components/src/utils/overlay-constants';
import { openDirtyPopup } from '../../../packages/neb-popup';
import { POPUP_RENDER_KEYS } from '../../../packages/neb-popup/src/renderer-keys';
import {
  CODE_PAYMENTS,
  CODE_WRITE_OFFS,
} from '../../../packages/neb-utils/constants';
import { parseDate } from '../../../packages/neb-utils/date-util';
import { PAYMENT_METHODS } from '../../../packages/neb-utils/enums';
import {
  centsToCurrency,
  centsToCurrencyWithNegative,
  currencyToCents,
  currencyToCentsWithNegative,
  objToName,
} from '../../../packages/neb-utils/formatters';
import {
  BILL_TYPE,
  checkForWarnings,
  fetchInsurancesFromPatients,
  validateAllowedAgainstBilled,
} from '../../../packages/neb-utils/neb-ledger-util';
import * as selectors from '../../../packages/neb-utils/selectors';
import { map } from '../../../packages/neb-utils/utils';
import {
  genRequireSelect,
  required,
} from '../../../packages/neb-utils/validators';
import { UpdateNotificationService } from '../../services/update-notifications';
import {
  CSS_COLOR_GREY_1,
  CSS_FONT_SIZE_HEADER,
  CSS_FONT_WEIGHT_BOLD,
  CSS_SPACING,
} from '../../styles';
import {
  buildDebitAllocationAmounts,
  calculateBalancedAdjustmentAmount,
  calculateBalancedAllowedAmount,
  calculateDebitsOwed,
  calculateDebitsPaid,
  getPatientDebits,
  getPrimaryPayerDebits,
  getSecondaryPayerDebits,
  hasOnlyOnePaidPkgDebit,
  isPatientRespDebit,
  isPayerRespDebit,
  mapAdjustmentsForEditPopup,
  mapDebitAmountsToCents,
  mapDebitsForEditPopup,
  mapPatientInsurancesForEditPopup,
  owedGrandTotalValidatorV2,
  rowHasPackageCreditsOrAdjustments,
  validateAllowedAgainstTotalOwedV2,
  validatePatientAllocatedAmountAgainstAvailableAmount,
  validatePatientAllocatedAmountAgainstAvailableOwedAmount,
  validatePatientAllocatedAmountAgainstOwedAmount,
  validatePatientOwedAmountAgainstAllocatedAmount,
} from '../../utils/allocate-charges/neb-allocate-charge-util';
import { calculateAdjustmentsSum } from '../../utils/ledger-form/neb-ledger-form-util';
import { openPayment } from '../../utils/payment-util';
import { NO_CHARGES_TO_PREVIEW_MESSAGE } from '../../utils/user-message';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  header: { id: 'header' },
  previewChargesTable: { id: 'preview-charges-table' },
  dateOfTransactionFrom: { id: 'date-of-transaction-from' },
  dateOfTransactionTo: { id: 'date-of-transaction-to' },
  selectPatient: { id: 'select-payer' },
  selectLocation: { id: 'select-location' },
  paymentDetail: { id: 'payment-detail' },
  transactionDetail: { id: 'transaction-detail' },
  tableHeader: {
    id: 'preview-charges-header',
    title: 'Apply Recommended Allocation',
    description:
      'Select the charges to review and apply the recommended payment allocation.',
  },
};

const FOCUS = 'focus';

export class NebFormPreviewAllocation extends NebForm {
  static get properties() {
    return {
      paymentDetail: Object,
      itemFilters: Object,
      practiceUsers: Array,
      locations: Array,
      patients: Array,
      payers: Array,
      paymentTypes: Array,
      adjustmentTypes: Array,
      patientsHash: Object,
      hasRelationships: Boolean,
      hasRCMSecondaryField: Boolean,

      __paymentModel: Array,
      __uncheckedIds: Array,
      __selectIndexes: Array,
      __menuItemsMap: Object,
    };
  }

  constructor() {
    super();
    this.__initState();
    this.__initHandlers();
  }

  static createModel() {
    return {
      paymentTransaction: [
        {
          amount: 0,
          cardSaleId: null,
          authEFT: null,
          maskedCardDescription: null,
          electronicPaymentId: null,
          electronicReferenceId: null,
          referenceId: null,
          dateOfServiceFrom: null,
          dateOfServiceTo: null,
          method: PAYMENT_METHODS.CASH,
          payer: null,
          payerPlanId: '',
          paymentType: null,
          transactionDate: null,
          patientName: null,
          patientOnline: false,
          postedById: null,
        },
      ],
      items: [],
      locations: [],
    };
  }

  createSelectors() {
    return {
      children: {
        paymentTransaction: {
          children: {
            $: {
              children: {
                amount: [
                  required(),
                  {
                    error: ' ',
                    validate: v => v > 0,
                  },
                ],
                authEFT: {
                  format: v => v || '',
                  unformat: v => (v === '' ? null : v),
                },
                method: [required()],
                paymentType: [required()],
                transactionDate: {
                  validators: [required()],
                },
                dateOfServiceFrom: {
                  validators: [
                    {
                      error: 'Required',
                      validate: v =>
                        this.model.paymentTransaction[0].dateOfServiceFrom
                          ? !!v
                          : true,
                    },
                  ],
                },
                dateOfServiceTo: {
                  validators: [
                    {
                      error: 'Required',
                      validate: v =>
                        this.model.paymentTransaction[0].dateOfServiceFrom
                          ? !!v
                          : true,
                    },
                  ],
                },
                payer: {
                  clipErrors: true,
                  clipPristine: true,
                  validators: [required(), genRequireSelect()],
                },
              },
            },
          },
        },
        items: {
          children: {
            $: {
              children: {
                allowedAmount: selectors.currency({
                  validateManually: true,
                  validateRaw: true,
                  validators: [
                    validateAllowedAgainstBilled(
                      'The total Owed amounts must equal the Allowed amount',
                    ),
                  ],
                }),
                patientAdjustment: selectors.currencyWithNegative({
                  validateManually: true,
                  validateRaw: true,
                  validators: [
                    owedGrandTotalValidatorV2(
                      "The total Owed amounts plus Adjustments must equal the charge's Billed amount plus Tax.",
                    ),
                  ],
                }),
                patientOwed: selectors.currency({
                  validateManually: true,
                  validateRaw: true,
                  validators: [
                    validateAllowedAgainstTotalOwedV2(
                      'The total Owed amounts must be equal to or less than the Allowed amount.',
                    ),
                    owedGrandTotalValidatorV2(
                      "The total Owed amounts plus Adjustments must equal the charge's Billed amount plus Tax.",
                    ),
                    {
                      error:
                        'The allocated amount may not exceed the patient’s owed amount.',
                      validate: (_, idx, state) =>
                        validatePatientOwedAmountAgainstAllocatedAmount(
                          idx[1],
                          this.model.items,
                          state.items,
                        ),
                    },
                  ],
                }),
                primaryOwed: selectors.currency({
                  validateManually: true,
                  validateRaw: true,
                  validators: [
                    owedGrandTotalValidatorV2(
                      "The total Owed amounts plus Adjustments must equal the charge's Billed amount plus Tax.",
                    ),
                  ],
                }),
                patientAllocated: selectors.currency({
                  validateManually: true,
                  validateRaw: true,
                  validators: [
                    {
                      error:
                        'The allocated amount may not exceed the owed or available amount.',
                      validate: (_, idx, state) =>
                        validatePatientAllocatedAmountAgainstAvailableOwedAmount(
                          idx[1],
                          this.paymentDetail.available,
                          this.model.items,
                          state.items,
                        ),
                    },
                    {
                      error:
                        'The allocated amount may not exceed the patient’s available amount.',
                      validate: (_, idx, state) =>
                        validatePatientAllocatedAmountAgainstAvailableAmount(
                          idx[1],
                          this.paymentDetail.available,
                          this.model.items,
                          state.items,
                        ),
                    },
                    {
                      error:
                        'The allocated amount may not exceed the patient’s owed amount.',
                      validate: (_, idx, state) =>
                        validatePatientAllocatedAmountAgainstOwedAmount(
                          idx[1],
                          this.model.items,
                          state.items,
                        ),
                    },
                  ],
                }),
                primaryAllocated: selectors.currency({
                  validateManually: true,
                  validateRaw: true,
                  validators: [],
                }),
                patientPaid: selectors.currency({
                  unsafe: true,
                  ignorePristine: true,
                }),
                primaryPaid: selectors.currency({
                  unsafe: true,
                  ignorePristine: true,
                }),
                nonPrimaryPayerDebits: {
                  createItem: () => ({
                    patientInsuranceId: '',
                    payerId: '',
                    secondaryAllocated: 0,
                    secondaryOwed: 0,
                    secondaryPaid: 0,
                    secondaryPayer: { alias: '' },
                  }),
                  children: {
                    $: {
                      children: {
                        secondaryOwed: {
                          validators: [
                            owedGrandTotalValidatorV2(
                              "The total Owed amounts plus Adjustments must equal the charge's Billed amount plus Tax.",
                            ),
                          ],
                        },
                      },
                    },
                  },
                },
                adjustments:
                  AllocationChargesTable2.createAdjustmentsSelectors(),
                debits: AllocationChargesTable2.createDebitSelectors(),
                patient: { unsafe: true, clipPristine: true },
                locations: selectors.multiSelect(this.locations, []),
              },
            },
          },
        },
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .subheader {
          margin: 0 ${CSS_SPACING} ${CSS_SPACING};
          font-size: ${CSS_FONT_SIZE_HEADER};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .table-spacer {
          padding-bottom: ${CSS_SPACING};
        }

        .container-filter {
          padding-right: ${CSS_SPACING};
          padding-left: ${CSS_SPACING};
          display: grid;
          grid-template-columns: repeat(4, 3fr);
          grid-gap: ${CSS_SPACING};
          width: 100%;
          padding-top: ${CSS_SPACING};
          z-index: 2;
        }

        .field {
          width: 100%;
        }

        .content {
          position: relative;
        }

        .container-totals {
          display: grid;
          grid-template-columns: auto auto 3fr;
          grid-gap: ${CSS_SPACING};
          width: 100%;
          padding: ${CSS_SPACING};
          background-color: ${CSS_COLOR_GREY_1};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          margin-bottom: ${CSS_SPACING};
        }

        .content-padding {
          padding: ${CSS_SPACING};
        }

        .button-remove-allocation {
          width: max-content;
          padding-left: ${CSS_SPACING};
          z-index: 1;
        }

        .buttons {
          display: flex;
          align-items: center;
        }

        .buttons-bar {
          padding-left: 20px;
        }

        .button-actions {
          width: 20px;
        }
      `,
    ];
  }

  __initState() {
    this.paymentDetail = {
      allocations: [],
      paymentId: '',
      amount: 0,
    };

    this.itemFilters = {
      dateOfTransactionFrom: null,
      dateOfTransactionTo: null,
      patient: '',
      locations: [],
    };

    this.practiceUsers = [];
    this.locations = [];
    this.patients = [];
    this.payers = [];
    this.paymentTypes = [];
    this.adjustmentTypes = [];
    this.patientsHash = {};
    this.hasRelationships = false;
    this.hasRCMSecondaryField = false;

    this.__paymentModel = [];
    this.__preallocated = [];
    this.__uncheckedIds = [];
    this.__selectIndexes = [];

    this.__menuItemsMap = {
      insurances: [],
      adjustments: [],
      paymentTypes: [],
    };

    this.__notificationService = new UpdateNotificationService({
      callback: () => {
        this.rerenderPatientInfo();
      },
    });

    this.onSave = () => {};

    this.onUpdate = () => {};

    this.onDismiss = () => {};

    this.onItemFiltersChange = () => {};
  }

  __initHandlers() {
    this.handlers = {
      ...this.handlers,
      dismissPayment: () => this.onDismiss(),
      checkItem: (index, checked) => {
        this.__selectIndexes.splice(index, 1, !checked);
        this.__selectIndexes = [...this.__selectIndexes];

        if (checked) this.__revertRow(index);
      },
      save: async () => {
        const valid = this.formService.validate();

        const onlyWarnings = await checkForWarnings(this.errors);

        if (valid || onlyWarnings) {
          this.__deleteZeroValueNonPrimaryPayerDebits();
          this.__deleteZeroValueAdjustments();
          this.__resetZeroValuePatientDebit();

          const rawModel = this.formService.build();
          const model = map(rawModel, (_keyPath, value) =>
            typeof value === 'string' ? value.trim() : value,
          );

          this.__saving = true;
          this.onSave(model, this.__selectIndexes);
        }
      },
      changeAllowed: ({ name, value, event }) => {
        if (event === FOCUS) return;

        const { row, rowIndex } = this.__getRowAndRowIndexFromName(name);
        this.formService.apply(`items.${rowIndex}.allowedAmount`, value);

        const { debits } = getPatientDebits(row);

        if (!rowHasPackageCreditsOrAdjustments(row, debits)) {
          const newAdjustmentAmount = calculateBalancedAdjustmentAmount(
            row.billedAmount,
            row.taxAmount,
            currencyToCentsWithNegative(value),
          );

          this.__updateAdjustmentAmount(row, rowIndex, newAdjustmentAmount);
        }

        this.formService.validate();
      },
      openPatientOwedPopup: async name => {
        const { row, rowIndex } = this.__getRowAndRowIndexFromName(name);

        const { debits } = getPatientDebits(row);

        const initialCodes = mapDebitsForEditPopup(debits);

        const result = await openPopup(
          POPUP_RENDER_KEYS.INDIVIDUAL_CODE_AND_AMOUNT,
          {
            title: 'Patient Responsibility',
            initialCodes,
            displayPaidAmount: true,
            paymentCodes: this.__menuItemsMap.paymentTypes,
            billType: row.billType,
          },
        );

        this.shadowRoot
          .getElementById(ELEMENTS.previewChargesTable.id)
          .focusPatientOwed(rowIndex);

        if (result) {
          this.__deleteIndividualItems(
            rowIndex,
            'debits',
            result.itemsToDelete,
          );

          this.__addIndividualItems(rowIndex, 'patientOwed', result.itemsToAdd);
          this.__editIndividualItems(rowIndex, 'debits', result.itemsToEdit);

          this.__syncOwedAndPaid({ rowIndex, type: 'patient' });
          this.__clearAllocatedIfDebitsRemoved({ rowIndex, type: 'patient' });
          this.__syncAllocated({ rowIndex, field: 'patientAllocated' });

          this.formService.validate();
        }
      },
      openSecondaryOwedPopup: async name => {
        const { row, rowIndex } = this.__getRowAndRowIndexFromName(name);

        const { nonPrimaryPayerDebitIdx } =
          this.__getNonPrimaryPayerDebitIndexFromName(name);

        const { patientInsuranceId: nonPrimaryPatientInsuranceId, payerId } =
          row.nonPrimaryPayerDebits[nonPrimaryPayerDebitIdx];

        const currentInsurance = this.__menuItemsMap.insurances.find(
          ins => ins.data.id === nonPrimaryPatientInsuranceId,
        );

        const { debits } = getSecondaryPayerDebits(
          row,
          nonPrimaryPatientInsuranceId,
        );

        const initialCodes = mapDebitsForEditPopup(debits);

        const result = await openPopup(
          POPUP_RENDER_KEYS.INDIVIDUAL_CODE_AND_AMOUNT,
          {
            title: 'Secondary Responsibility',
            initialCodes,
            displayPaidAmount: true,
            paymentCodes: this.__menuItemsMap.paymentTypes,
            plans: mapPatientInsurancesForEditPopup([currentInsurance]),
          },
        );

        this.shadowRoot
          .getElementById(ELEMENTS.previewChargesTable.id)
          .focusSecondaryOwed(rowIndex, nonPrimaryPayerDebitIdx);

        if (result) {
          this.__deleteIndividualItems(
            rowIndex,
            'debits',
            result.itemsToDelete,
          );

          const itemsToAdd = result.itemsToAdd.map(item => ({
            ...item,
            patientInsuranceId: nonPrimaryPatientInsuranceId,
            payerId,
          }));

          this.__addIndividualItems(
            rowIndex,
            'secondaryOwed',
            itemsToAdd,
            nonPrimaryPatientInsuranceId,
          );

          this.__editIndividualItems(rowIndex, 'debits', result.itemsToEdit);

          this.__syncOwedAndPaid({
            rowIndex,
            type: 'secondary',
            nonPrimaryPatientInsuranceId,
          });

          if (this.__debitsChanged(result, debits)) {
            this.__syncAllocated({
              rowIndex,
              field: 'secondaryAllocated',
              nonPrimaryPatientInsuranceId,
              nonPrimaryPayerDebitIdx,
            });
          }
          this.formService.validate();
        }
      },
      // eslint-disable-next-line complexity
      changePatientOwed: ({ name, value, event }) => {
        if (event === FOCUS) return;

        const { row, rowIndex } = this.__getRowAndRowIndexFromName(name);

        if (value === this.state.items[rowIndex].patientOwed) return;

        let { debits, debitIndexes } = getPatientDebits(row);

        if (debits.length === 0 && debitIndexes.length === 0) {
          this.formService.addItem(`items.${rowIndex}.debits`);

          ({ debits, debitIndexes } = getPatientDebits(row));
        }

        if (
          debits.length === 1 &&
          debitIndexes.length === 1 &&
          this.state.items[rowIndex].debits[debitIndexes].codePaymentId === ''
        ) {
          this.formService.apply(
            `items.${rowIndex}.debits.${debitIndexes}.codePaymentId`,
            CODE_PAYMENTS.GENERAL_PAYMENT.id,
          );
        }

        const [debitIndex] = debitIndexes;

        this.formService.apply(
          `items.${rowIndex}.debits.${debitIndex}.amount`,
          value,
        );

        this.formService.apply(`items.${rowIndex}.patientOwed`, value);

        this.__syncAllocated({ rowIndex, field: 'patientAllocated' });

        this.formService.validate();
      },
      changePatientAllocated: ({ name, value, event }) => {
        if (event === FOCUS) return;

        const { rowIndex } = this.__getRowAndRowIndexFromName(name);

        if (value === this.state.items[rowIndex].patientAllocated) return;

        this.formService.apply(`items.${rowIndex}.patientAllocated`, value);

        this.__syncAllocated({ rowIndex, field: 'patientAllocated' });

        this.formService.validate();
      },
      changePrimaryOwed: ({ name, value, event }) => {
        if (event === FOCUS) return;

        const { row, rowIndex } = this.__getRowAndRowIndexFromName(name);

        const { debits, debitIndexes } = getPrimaryPayerDebits(row);

        if (debits.length !== 1 && debitIndexes.length !== 1) return;

        const [debitIndex] = debitIndexes;

        this.formService.apply(
          `items.${rowIndex}.debits.${debitIndex}.amount`,
          value,
        );

        this.formService.apply(`items.${rowIndex}.primaryOwed`, value);

        this.__syncAllocated({ rowIndex, field: 'primaryAllocated' });

        this.formService.validate();
      },
      // eslint-disable-next-line complexity
      changeSecondaryOwed: ({ name, value, event }) => {
        if (event === FOCUS) return;

        const { row, rowIndex } = this.__getRowAndRowIndexFromName(name);

        const { nonPrimaryPayerDebitIdx } =
          this.__getNonPrimaryPayerDebitIndexFromName(name);

        const nonPrimaryPatientInsuranceId =
          row.nonPrimaryPayerDebits[nonPrimaryPayerDebitIdx].patientInsuranceId;

        const { debits, debitIndexes } = getSecondaryPayerDebits(
          row,
          nonPrimaryPatientInsuranceId,
        );

        if (debits.length > 1) return;

        if (
          debits.length > 1 ||
          (currencyToCents(value) === 0 && debits.length === 0)
        ) {
          return;
        }

        if (currencyToCents(value) > 0 && debits.length === 0) {
          const { payerId } =
            row.nonPrimaryPayerDebits[nonPrimaryPayerDebitIdx];

          this.__addIndividualItems(rowIndex, 'secondaryOwed', [
            {
              amount: currencyToCents(value),
              codeId: CODE_PAYMENTS['PR-45'].id,
              individualRowId: '',
              patientInsuranceId: nonPrimaryPatientInsuranceId,
              payerId,
            },
          ]);

          this.__syncOwedAndPaid({
            rowIndex,
            type: 'secondary',
            nonPrimaryPatientInsuranceId,
          });

          this.__syncAllocated({
            rowIndex,
            field: 'secondaryAllocated',
            nonPrimaryPatientInsuranceId,
            nonPrimaryPayerDebitIdx,
          });

          this.formService.validate();
          return;
        }

        const [debitIndex] = debitIndexes;

        this.formService.apply(
          `items.${rowIndex}.debits.${debitIndex}.amount`,
          value,
        );

        this.formService.apply(
          `items.${rowIndex}.nonPrimaryPayerDebits.${nonPrimaryPayerDebitIdx}.secondaryOwed`,
          currencyToCents(value),
        );

        this.__syncAllocated({
          rowIndex,
          field: 'secondaryAllocated',
          nonPrimaryPatientInsuranceId,
          nonPrimaryPayerDebitIdx,
        });

        this.formService.validate();
      },
      addSecondaryPayer: async name => {
        const { row, rowIndex } = this.__getRowAndRowIndexFromName(name);

        const usedSecondaryInsuranceIds = row.nonPrimaryPayerDebits.map(
          debit => debit.patientInsuranceId,
        );
        const insuranceOptions = this.__menuItemsMap.insurances.filter(
          insurance =>
            !usedSecondaryInsuranceIds.includes(insurance.data.id) &&
            insurance.data.id !== row.primaryInsuranceId,
        );

        const result = await openPopup(
          POPUP_RENDER_KEYS.INDIVIDUAL_CODE_AND_AMOUNT,
          {
            title: 'Secondary Responsibility',
            plans: mapPatientInsurancesForEditPopup(insuranceOptions),
            enablePlansDropdown: true,
            initialCodes: [],
            displayPaidAmount: true,
            paymentCodes: this.__menuItemsMap.paymentTypes,
          },
        );

        if (result) {
          const {
            patientInsurance: {
              id: nonPrimaryPatientInsuranceId,
              payer: { id: payerId, alias },
            },
          } = result;

          const indexToAdd = this.__addNonPrimaryPayerDebits(rowIndex, {
            patientInsuranceId: nonPrimaryPatientInsuranceId,
            payerId,
            alias,
          });

          this.__addIndividualItems(
            rowIndex,
            'secondaryOwed',
            result.itemsToAdd.map(item => ({
              ...item,
              patientInsuranceId: nonPrimaryPatientInsuranceId,
              payerId,
            })),
          );

          this.__syncOwedAndPaid({
            rowIndex,
            type: 'secondary',
            nonPrimaryPatientInsuranceId,
          });

          this.__syncAllocated({
            rowIndex,
            field: 'secondaryAllocated',
            nonPrimaryPatientInsuranceId,
            nonPrimaryPayerDebitIdx: indexToAdd,
          });

          this.formService.validate();
        }
      },
      changePrimaryAllocated: ({ name, value, event }) => {
        if (event === FOCUS) return;

        const { rowIndex } = this.__getRowAndRowIndexFromName(name);

        this.formService.apply(`items.${rowIndex}.primaryAllocated`, value);

        this.__syncAllocated({ rowIndex, field: 'primaryAllocated' });

        this.formService.validate();
      },
      changeSecondaryAllocated: ({ name, value, event }) => {
        if (event === FOCUS) return;

        const { row, rowIndex } = this.__getRowAndRowIndexFromName(name);

        const { nonPrimaryPayerDebitIdx } =
          this.__getNonPrimaryPayerDebitIndexFromName(name);

        if (
          value ===
          centsToCurrency(
            row.nonPrimaryPayerDebits[nonPrimaryPayerDebitIdx]
              .secondaryAllocated,
          )
        ) {
          return;
        }

        const nonPrimaryPatientInsuranceId =
          row.nonPrimaryPayerDebits[nonPrimaryPayerDebitIdx].patientInsuranceId;

        this.formService.apply(name, currencyToCents(value));

        this.__syncAllocated({
          rowIndex,
          field: 'secondaryAllocated',
          nonPrimaryPatientInsuranceId,
          nonPrimaryPayerDebitIdx,
        });

        this.formService.validate();
      },
      openAdjustmentsPopup: async name => {
        const { row, rowIndex } = this.__getRowAndRowIndexFromName(name);

        const result = await openPopup(
          POPUP_RENDER_KEYS.INDIVIDUAL_CODE_AND_AMOUNT,
          {
            title: 'Adjustments',
            initialCodes: mapAdjustmentsForEditPopup(row.adjustments),
            displayPaidAmount: false,
            billType: row.billType,
            codeWriteOffs: this.__menuItemsMap.adjustments,
          },
        );

        this.shadowRoot
          .getElementById(ELEMENTS.previewChargesTable.id)
          .focusAdjustment(rowIndex);

        if (result) {
          this.__deleteIndividualItems(
            rowIndex,
            'adjustments',
            result.itemsToDelete,
          );

          this.__addIndividualItems(rowIndex, 'adjustments', result.itemsToAdd);
          this.__editIndividualItems(
            rowIndex,
            'adjustments',
            result.itemsToEdit,
          );

          this.__updateAllowedAmountFromAdjustments(
            this.state.items[rowIndex],
            rowIndex,
          );

          this.formService.validate();
        }
      },
      changeAdjustments: ({ name, value, event }) => {
        if (event === FOCUS) return;

        const { row, rowIndex } = this.__getRowAndRowIndexFromName(name);

        this.__updateAdjustmentAmount(
          row,
          rowIndex,
          currencyToCentsWithNegative(value),
        );

        this.__updateAllowedAmountFromAdjustments(
          this.state.items[rowIndex],
          rowIndex,
        );

        this.formService.validate();
      },
      selectPayer: async ({ __, payerPlanId }) => {
        const accepted = this.__dirty ? await openDirtyPopup() : true;
        if (!accepted) return;

        const res = await openOverlay(OVERLAY_KEYS.PAYER_PLAN, {
          id: payerPlanId,
        });

        if (res || this.__dirty) this.onUpdate();
      },
      viewCharge: async ({ id, invoiceId, patientId }) => {
        const accepted = this.__dirty ? await openDirtyPopup() : true;

        if (!accepted) return;

        const lineItemIds = invoiceId
          ? (await getLedgerInvoiceItems(invoiceId)).data.map(li => li.id)
          : [id];

        await openOverlay(OVERLAY_KEYS.LEDGER_VIEW_SELECTED_CHARGES_V2, {
          patient: this.patientsHash[patientId],
          lineItemIds,
          selectedIds: [],
        });

        this.onUpdate();
      },
      viewPayment: async ({ paymentId, value, payerPlanId }) => {
        if (paymentId !== this.paymentDetail.id) {
          const accepted = this.__dirty ? await openDirtyPopup() : true;

          if (!accepted) return;

          if (payerPlanId) {
            const paymentDetails = await getPaymentDetail(paymentId);

            await openPayment({
              payment: {
                payment: {
                  id: paymentId,
                  patientId: this.paymentDetail.patientId,
                },
                ...paymentDetails,
              },
              readonly: false,
            });
          } else if (value.indexOf('D-') === 0) {
            await openOverlay(OVERLAY_KEYS.DISCOUNT, {
              id: paymentId,
              patientId: this.paymentDetail.patientId,
            });
          } else {
            await openOverlay(OVERLAY_KEYS.PAYMENT_DETAIL_2, {
              readonly: false,
              payment: {
                patientId: this.paymentDetail.patientId,
                id: paymentId,
              },
            });
          }
          this.onUpdate();
        }
      },
      selectAll: () => {
        this.__selectIndexes = this.__selectIndexes.map(() => true);
      },
      deselectAll: () => {
        this.__selectIndexes = this.__selectIndexes.map(() => false);
        this.formService.reset();
        this.formService.validate();
      },
      itemsFilterChange: async ({ name, value }) => {
        const originalFilters = this.itemFilters;

        if (!equal(this.itemFilters[name], value)) {
          this.itemFilters = {
            ...this.itemFilters,
            [name]: value,
          };

          if (!this.paymentDetail.id) return;

          const accepted = this.__dirty ? await openDirtyPopup() : true;

          if (accepted) this.onItemFiltersChange(this.itemFilters);
          else this.itemFilters = originalFilters;

          if (name === 'dateOfTransactionFrom') {
            this.__updateDateOfTransactionFromSelectable();
          }

          if (name === 'dateOfTransactionTo') {
            this.__updateDateOfTransactionToSelectable();
          }
        }
      },
    };

    this.__updateDateOfTransactionFromSelectable();
    this.__updateDateOfTransactionToSelectable();
  }

  __getPaymentConfig() {
    return [
      {
        key: 'paymentAmount',
        label: 'Payment Amount',
        truncate: true,
        flex: css`1 0 0`,
        formatter: amount => centsToCurrency(amount),
      },
      {
        truncate: true,
        key: 'allocated',
        label: 'Allocated',
        flex: css`1 0 0`,
        formatter: amount => centsToCurrency(amount),
      },
      {
        truncate: true,
        key: 'recommendedAllocated',
        label: 'Recommended Allocated',
        flex: css`1 0 0`,
        formatter: amount => centsToCurrency(amount),
      },
      {
        truncate: true,
        key: 'available',
        label: 'Available',
        flex: css`1 0 0`,
        formatter: amount => centsToCurrency(amount),
      },
    ];
  }

  __setPresetValues() {
    this.__selectIndexes = this.model.items.map(
      x => !this.__uncheckedIds.includes(x.id),
    );

    this.__preallocated = this.model.items.map(li =>
      li.debits ? li.debits.reduce((sum, d) => sum + d.allocated, 0) : 0,
    );
  }

  __updateDateOfTransactionFromSelectable() {
    this.handlers.dateOfTransactionFromSelectable = date =>
      (this.itemFilters.dateOfTransactionTo === null ||
        date.isSameOrBefore(this.itemFilters.dateOfTransactionTo)) &&
      date.isSameOrBefore(parseDate().endOf('day'));
  }

  __updateDateOfTransactionToSelectable() {
    this.handlers.dateOfTransactionToSelectable = date =>
      (this.itemFilters.dateOfTransactionFrom === null ||
        date.isSameOrAfter(this.itemFilters.dateOfTransactionFrom)) &&
      date.isSameOrBefore(parseDate().endOf('day'));
  }

  __getRow(rowIndex) {
    return this.state.items[rowIndex];
  }

  __getRowAndRowIndexFromName(name) {
    const rowIndex = name.split('.')[0];
    const row = this.__getRow(rowIndex);

    return { row, rowIndex };
  }

  __applyAllocatedToDebits({
    rowIndex,
    debits,
    debitIndexes,
    allocationAmount,
  }) {
    const debitAllocationAmounts = buildDebitAllocationAmounts({
      debits: mapDebitAmountsToCents(debits),
      debitIndexes,
      allocationAmount,
    });

    debitAllocationAmounts.forEach(({ debitIndex, allocated }) => {
      this.formService.apply(
        `items.${rowIndex}.debits.${debitIndex}.allocated`,
        centsToCurrency(allocated),
      );
    });
  }

  __syncAllocated({
    rowIndex,
    field,
    nonPrimaryPatientInsuranceId,
    nonPrimaryPayerDebitIdx,
  }) {
    const row = this.__getRow(rowIndex);

    let debits;
    let debitIndexes;

    switch (field) {
      case 'patientAllocated':
        ({ debits, debitIndexes } = getPatientDebits(row));
        break;
      case 'primaryAllocated':
        ({ debits, debitIndexes } = getPrimaryPayerDebits(row));
        break;
      case 'secondaryAllocated':
        ({ debits, debitIndexes } = getSecondaryPayerDebits(
          row,
          nonPrimaryPatientInsuranceId,
        ));

        break;
      default:
        return;
    }

    const allocationAmount =
      field === 'secondaryAllocated'
        ? row.nonPrimaryPayerDebits[nonPrimaryPayerDebitIdx][field]
        : currencyToCents(row[field]);

    this.__applyAllocatedToDebits({
      rowIndex,
      debits,
      debitIndexes,
      allocationAmount,
    });
  }

  async rerenderPatientInfo() {
    const patient = await patientApiClient.fetchOne(
      this.paymentDetail.patientId,
    );

    const patientName = objToName(patient.name, {
      reverse: true,
      middleInitial: true,
    });

    const isDirty = this.__dirty;

    this.state.paymentTransaction.forEach((payment, index) => {
      this.formService.apply(
        `paymentTransaction.${index}.patientName`,
        patientName,
      );

      if (!payment.payerId) {
        this.formService.apply(
          `paymentTransaction.${index}.payer`,
          patientName,
        );
      }
    });

    if (!isDirty) {
      const model = this.formService.build();
      this.formService.refresh(model);
    }
  }

  connectedCallback() {
    super.connectedCallback();

    this.__notificationService.connect();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.__notificationService.disconnect();
  }

  getAllocatedAmount() {
    if (this.paymentDetail.voidedAt || this.paymentDetail.refundedAt) {
      return 0;
    }

    return this.paymentDetail.amount - this.paymentDetail.available;
  }

  update(changedProps) {
    if (changedProps.has('paymentDetail') && this.paymentDetail) {
      this.__paymentModel = [
        {
          paymentAmount: this.paymentDetail.amount,
          refunded: 0,
          allocated: this.getAllocatedAmount(),
          split: 0,
          available: this.paymentDetail.available,
          recommendedAllocated: this.paymentDetail.recommendedAllocated || 0,
          paymentMethod: this.paymentDetail.paymentMethod,
          postedById: this.paymentDetail.postedById,
        },
      ];
    }

    if (changedProps.has('model') && this.model.items) {
      this.__setPresetValues();

      this.__notificationService.update({
        patient: { id: this.paymentDetail.patientId },
      });
    }

    super.update(changedProps);
  }

  async updated(changedProps) {
    if (changedProps.has('__selectIndexes')) {
      const uncheckedIds = [];
      this.__selectIndexes.forEach((checked, index) => {
        if (!checked) {
          uncheckedIds.push(this.state.items[index].id);
        }
      });

      this.__uncheckedIds = uncheckedIds;
    }

    if (changedProps.has('patients') && this.patients.length) {
      this.__menuItemsMap = await this.__getMenuItemsMap();
      this.build();
    }

    super.updated(changedProps);
  }

  async __getPatientInsurances() {
    const patientId = this.itemFilters.patient?.data?.id;
    const patientIds = this.patients
      .filter(p => p.data.id && (!patientId || p.data.id === patientId))
      .map(p => p.data.id);

    const insurances = await fetchInsurancesFromPatients(patientIds);
    return insurances.map(insurance => ({
      ...insurance,
      patientId: insurance.data.patientId,
    }));
  }

  async __getMenuItemsMap() {
    const patientInsurances = await this.__getPatientInsurances();

    return {
      insurances: patientInsurances,
      adjustments: this.adjustmentTypes,
      paymentTypes: this.paymentTypes,
    };
  }

  async load() {
    this.__menuItemsMap = await this.__getMenuItemsMap();
  }

  __defaultAdjustmentCodeId(row) {
    return row.billType === BILL_TYPE.INSURANCE
      ? CODE_WRITE_OFFS.NEGOTIATED_RATE.id
      : CODE_WRITE_OFFS.WRITE_OFF.id;
  }

  __deleteZeroValueNonPrimaryPayerDebits() {
    // eslint-disable-next-line complexity
    this.state.items.forEach(({ debits, nonPrimaryPayerDebits }, rowIndex) => {
      for (
        let nonPrimaryPayerDebitIdx = nonPrimaryPayerDebits.length - 1;
        nonPrimaryPayerDebitIdx >= 0;
        nonPrimaryPayerDebitIdx -= 1
      ) {
        const {
          secondaryAllocated,
          secondaryOwed,
          secondaryPaid,
          payerId: nonPrimaryPayerId,
          patientInsuranceId: nonPrimaryPatientInsuranceId,
        } = nonPrimaryPayerDebits[nonPrimaryPayerDebitIdx];

        if (
          secondaryAllocated === 0 &&
          secondaryOwed === 0 &&
          secondaryPaid === 0
        ) {
          for (let debitIdx = debits.length - 1; debitIdx >= 0; debitIdx -= 1) {
            const {
              allocated,
              amount,
              payerId: debitPayerId,
              patientInsuranceId: debitPatientInsuranceId,
            } = debits[debitIdx];

            if (
              currencyToCents(allocated) === 0 &&
              currencyToCents(amount) === 0 &&
              debitPayerId === nonPrimaryPayerId &&
              debitPatientInsuranceId === nonPrimaryPatientInsuranceId
            ) {
              this.formService.removeItem(`items.${rowIndex}.debits`, debitIdx);
            }
          }

          this.formService.removeItem(
            `items.${rowIndex}.nonPrimaryPayerDebits`,
            nonPrimaryPayerDebitIdx,
          );
        }
      }
    });
  }

  __deleteZeroValueAdjustments() {
    this.state.items.forEach(({ adjustments }, rowIndex) => {
      if (
        adjustments.length === 1 &&
        adjustments[0].id === '' &&
        currencyToCentsWithNegative(adjustments[0].amount) === 0
      ) {
        this.__deleteIndividualItems(rowIndex, 'adjustments', [
          {
            individualRowId: adjustments[0].id,
            codeId: adjustments[0].codePaymentId,
            amount: currencyToCentsWithNegative(adjustments[0].amount),
          },
        ]);
      }
    });
  }

  __resetZeroValuePatientDebit() {
    this.state.items.forEach((row, rowIndex) => {
      const { debits, debitIndexes } = getPatientDebits(row);

      if (
        debits.length === 1 &&
        currencyToCents(debits[0].amount) === 0 &&
        currencyToCents(debits[0].allocated) === 0
      ) {
        this.formService.apply(
          `items.${rowIndex}.debits.${debitIndexes}.codePaymentId`,
          '',
        );
      }
    });
  }

  __addNonPrimaryPayerDebits(rowIndex, { patientInsuranceId, payerId, alias }) {
    const row = this.__getRow(rowIndex);
    const indexToAdd = row.nonPrimaryPayerDebits.length;

    this.formService.addItem(
      `items.${rowIndex}.nonPrimaryPayerDebits`,
      indexToAdd,
    );

    this.formService.apply(
      `items.${rowIndex}.nonPrimaryPayerDebits.${indexToAdd}.patientInsuranceId`,
      patientInsuranceId,
    );

    this.formService.apply(
      `items.${rowIndex}.nonPrimaryPayerDebits.${indexToAdd}.payerId`,
      payerId,
    );

    this.formService.apply(
      `items.${rowIndex}.nonPrimaryPayerDebits.${indexToAdd}.secondaryAllocated`,
      0,
    );

    this.formService.apply(
      `items.${rowIndex}.nonPrimaryPayerDebits.${indexToAdd}.secondaryOwed`,
      0,
    );

    this.formService.apply(
      `items.${rowIndex}.nonPrimaryPayerDebits.${indexToAdd}.secondaryPaid`,
      0,
    );

    this.formService.apply(
      `items.${rowIndex}.nonPrimaryPayerDebits.${indexToAdd}.secondaryPayer.alias`,
      alias,
    );

    return indexToAdd;
  }

  __clearAllocatedIfDebitsRemoved({ rowIndex, type }) {
    const row = this.__getRow(rowIndex);

    let debits;

    if (type === 'patient') {
      ({ debits } = getPatientDebits(row));
    } else {
      return;
    }

    if (debits.length === 0 || hasOnlyOnePaidPkgDebit(debits)) {
      this.formService.apply(`items.${rowIndex}.${type}Allocated`, '$0.00');
    }
  }

  __debitsChanged({ itemsToAdd, itemsToEdit, itemsToDelete }, debits) {
    if (itemsToAdd.length || itemsToDelete.length) return true;

    return !itemsToEdit.every(
      (v, idx) =>
        v.individualRowId === debits[idx].id &&
        v.codeId === debits[idx].codePaymentId &&
        v.amount === currencyToCents(debits[idx].amount),
    );
  }

  __syncOwedAndPaid({ rowIndex, type, nonPrimaryPatientInsuranceId }) {
    const row = this.__getRow(rowIndex);

    let debits;

    switch (type) {
      case 'patient':
        ({ debits } = getPatientDebits(row));
        break;
      case 'primary':
        ({ debits } = getPrimaryPayerDebits(row));
        break;
      case 'secondary':
        ({ debits } = getSecondaryPayerDebits(
          row,
          nonPrimaryPatientInsuranceId,
        ));

        break;
      default:
    }

    const owed = calculateDebitsOwed(debits, true);
    const paid = calculateDebitsPaid(debits, true);

    if (type === 'secondary') {
      row.nonPrimaryPayerDebits.forEach((value, index) => {
        if (value.patientInsuranceId === nonPrimaryPatientInsuranceId) {
          this.formService.apply(
            `items.${rowIndex}.nonPrimaryPayerDebits.${index}.secondaryOwed`,
            currencyToCents(owed),
          );

          this.formService.apply(
            `items.${rowIndex}.nonPrimaryPayerDebits.${index}.secondaryPaid`,
            currencyToCents(paid),
          );
        }
      });
    } else {
      this.formService.apply(`items.${rowIndex}.${type}Owed`, owed);
      this.formService.apply(`items.${rowIndex}.${type}Paid`, paid);
    }
  }

  __existingDebitIndex(rowIndex, itemToEdit) {
    const row = this.__getRow(rowIndex);

    return row.debits.findIndex(
      debit => debit.id === itemToEdit.individualRowId,
    );
  }

  __debitWithNoIdLength(rowIndex, type, nonPrimaryPatientInsuranceId) {
    let length;

    const row = this.__getRow(rowIndex);

    switch (type) {
      case 'patientOwed':
        length = row.debits.filter(
          debit => isPatientRespDebit(debit) && !debit.id,
        ).length;

        break;

      case 'secondaryOwed':
        length = row.debits.filter(
          debit =>
            isPayerRespDebit(debit) &&
            !debit.id &&
            debit.patientInsuranceId === nonPrimaryPatientInsuranceId,
        ).length;

        break;

      default:
        break;
    }

    return length;
  }

  __debitWithNoIdIndex(rowIndex, type, nonPrimaryPatientInsuranceId) {
    let index;

    const row = this.__getRow(rowIndex);

    switch (type) {
      case 'patientOwed':
        index = row.debits.findIndex(
          debit => isPatientRespDebit(debit) && !debit.id,
        );

        break;

      case 'secondaryOwed':
        index = row.debits.findIndex(
          debit =>
            isPayerRespDebit(debit) &&
            !debit.id &&
            debit.patientInsuranceId === nonPrimaryPatientInsuranceId,
        );

        break;

      default:
        break;
    }

    return index;
  }

  __adjustmentWithNoIdIndex(rowIndex) {
    const row = this.__getRow(rowIndex);

    return row.adjustments.findIndex(adj => !adj.id);
  }

  __existingAdjustmentIndex(rowIndex, itemToEdit) {
    const row = this.__getRow(rowIndex);

    return row.adjustments.findIndex(
      adj => adj.id === itemToEdit.individualRowId,
    );
  }

  __getNonPrimaryPayerDebitIndexFromName(name) {
    const nonPrimaryPayerDebitIdx = name.split('.')[2];

    return { nonPrimaryPayerDebitIdx };
  }

  __deleteItemsBeforeAdding(rowIndex, type, nonPrimaryPatientInsuranceId) {
    const isDebit = type !== 'adjustments';
    const attribute = isDebit ? 'debits' : 'adjustments';
    const row = this.__getRow(rowIndex);

    let countAddedItemsToDelete = 0;

    if (isDebit) {
      countAddedItemsToDelete = this.__debitWithNoIdLength(
        rowIndex,
        type,
        nonPrimaryPatientInsuranceId,
      );
    } else {
      countAddedItemsToDelete = row.adjustments.filter(adj => !adj.id).length;
    }

    while (countAddedItemsToDelete) {
      const indexToDelete = isDebit
        ? this.__debitWithNoIdIndex(
            rowIndex,
            type,
            nonPrimaryPatientInsuranceId,
          )
        : this.__adjustmentWithNoIdIndex(rowIndex);

      if (indexToDelete !== -1) {
        this.formService.removeItem(
          `items.${rowIndex}.${attribute}`,
          indexToDelete,
        );
      }

      countAddedItemsToDelete -= 1;
    }
  }

  __addIndividualItems(
    rowIndex,
    type,
    itemsToAdd,
    nonPrimaryPatientInsuranceId,
  ) {
    const isDebit = type !== 'adjustments';
    const attribute = isDebit ? 'debits' : 'adjustments';

    this.__deleteItemsBeforeAdding(
      rowIndex,
      type,
      nonPrimaryPatientInsuranceId,
    );

    const codeId = isDebit ? 'codePaymentId' : 'codeId';

    itemsToAdd.forEach(itemToAdd => {
      const row = this.__getRow(rowIndex);
      const indexToAdd = row[attribute].length;
      this.formService.addItem(`items.${rowIndex}.${attribute}`);

      this.formService.apply(
        `items.${rowIndex}.${attribute}.${indexToAdd}.${codeId}`,
        itemToAdd.codeId,
      );

      this.formService.apply(
        `items.${rowIndex}.${attribute}.${indexToAdd}.amount`,
        centsToCurrency(itemToAdd.amount),
      );

      if (itemToAdd.patientInsuranceId && itemToAdd.payerId) {
        this.formService.apply(
          `items.${rowIndex}.${attribute}.${indexToAdd}.payerId`,
          itemToAdd.payerId,
        );

        this.formService.apply(
          `items.${rowIndex}.${attribute}.${indexToAdd}.patientInsuranceId`,
          itemToAdd.patientInsuranceId,
        );
      }
    });
  }

  __editIndividualItems(rowIndex, type, itemsToEdit) {
    const isDebit = type !== 'adjustments';

    itemsToEdit.forEach(itemToEdit => {
      const indexToEdit = isDebit
        ? this.__existingDebitIndex(rowIndex, itemToEdit)
        : this.__existingAdjustmentIndex(rowIndex, itemToEdit);

      const codeId = isDebit ? 'codePaymentId' : 'codeId';

      this.formService.apply(
        `items.${rowIndex}.${type}.${indexToEdit}.${codeId}`,
        itemToEdit.codeId,
      );

      this.formService.apply(
        `items.${rowIndex}.${type}.${indexToEdit}.amount`,
        centsToCurrencyWithNegative(itemToEdit.amount),
      );
    });
  }

  __updateAdjustmentAmount(row, rowIndex, amount) {
    switch (row.adjustments.length) {
      case 0:
        if (amount) {
          this.__addIndividualItems(rowIndex, 'adjustments', [
            { codeId: this.__defaultAdjustmentCodeId(row), amount },
          ]);
        }

        break;

      case 1:
        if (amount) {
          this.__editIndividualItems(rowIndex, 'adjustments', [
            {
              individualRowId: row.adjustments[0].id,
              codeId: row.adjustments[0].codeId,
              amount,
            },
          ]);
        } else {
          const previousCode = row.adjustments[0].codeId;

          this.__deleteIndividualItems(rowIndex, 'adjustments', [
            {
              individualRowId: row.adjustments[0].id,
              codeId: previousCode,
              amount,
            },
          ]);

          this.__addIndividualItems(rowIndex, 'adjustments', [
            { codeId: previousCode, amount: 0 },
          ]);
        }

        break;

      default:
        break;
    }
  }

  __deleteIndividualItems(rowIndex, type, itemsToDelete) {
    itemsToDelete.forEach(itemToDelete => {
      const row = this.__getRow(rowIndex);
      const indexToDelete = row[type].findIndex(
        individualType => individualType.id === itemToDelete.individualRowId,
      );

      this.formService.removeItem(`items.${rowIndex}.${type}`, indexToDelete);
    });
  }

  __addOrRemoveRow(selectedIndex, li, propName) {
    const origLength =
      this.formService.__initialState.items[selectedIndex][propName].length;

    const modLength = li[propName].length;

    const diffLength = modLength - origLength;
    const action = diffLength && (diffLength > 0 ? 'removeItem' : 'addItem');

    Array(Math.abs(diffLength))
      .fill()
      .forEach(() =>
        this.formService[action](`items.${selectedIndex}.${propName}`),
      );
  }

  __updateAllowedAmountFromAdjustments(row, rowIndex) {
    const { debits } = getPatientDebits(row);

    if (!rowHasPackageCreditsOrAdjustments(row, debits)) {
      const adjustmentsSum = calculateAdjustmentsSum(row.adjustments);
      const newAllowedAmount = calculateBalancedAllowedAmount(
        row.billedAmount,
        row.taxAmount,
        adjustmentsSum,
      );

      this.formService.apply(
        `items.${rowIndex}.allowedAmount`,
        centsToCurrencyWithNegative(newAllowedAmount),
      );
    }
  }

  __revertDebit(selectedIndex, initialSelectState) {
    initialSelectState.debits.forEach((_, index) => {
      const path = `items.${selectedIndex}.debits.${index}.`;
      const initialDebit = initialSelectState.debits[index];

      const fields = [
        'codePaymentId',
        'patientInsuranceId',
        'payerId',
        'id',
        'allocated',
        'amount',
      ];

      fields.forEach(field =>
        this.formService.apply(`${path}${field}`, initialDebit[field]),
      );
    });
  }

  __revertAdjustment(selectedIndex, initialSelectState) {
    initialSelectState.adjustments.forEach((_, index) => {
      const path = `items.${selectedIndex}.adjustments.${index}.`;
      const selectAdjustment = initialSelectState.adjustments[index];
      const fields = ['amount', 'codeId'];

      fields.forEach(field =>
        this.formService.apply(`${path}${field}`, selectAdjustment[field]),
      );
    });
  }

  __revertNonPrimaryPayerDebits(selectedIndex, initialSelectState) {
    let countAddedItemsToDelete =
      this.state.items[selectedIndex].nonPrimaryPayerDebits.length;

    while (countAddedItemsToDelete !== -1) {
      this.formService.removeItem(
        `items.${selectedIndex}.nonPrimaryPayerDebits`,
        countAddedItemsToDelete,
      );

      countAddedItemsToDelete -= 1;
    }

    initialSelectState.nonPrimaryPayerDebits.forEach((_, index) => {
      this.formService.addItem(
        `items.${selectedIndex}.nonPrimaryPayerDebits`,
        index,
      );

      const path = `items.${selectedIndex}.nonPrimaryPayerDebits.${index}.`;
      const initialNonPrimaryPayerDebit =
        initialSelectState.nonPrimaryPayerDebits[index];

      const fields = [
        'patientInsuranceId',
        'payerId',
        'secondaryAllocated',
        'secondaryOwed',
        'secondaryPaid',
      ];

      fields.forEach(field => {
        this.formService.apply(
          `${path}${field}`,
          initialNonPrimaryPayerDebit[field],
        );

        this.formService.apply(
          `${path}secondaryPayer.alias`,
          initialNonPrimaryPayerDebit.secondaryPayer.alias,
        );
      });
    });
  }

  __revertLineItem(selectedIndex, initialSelectState) {
    const path = `items.${selectedIndex}.`;

    const fields = [
      'allowedAmount',
      'patientOwed',
      'primaryOwed',
      'patientAllocated',
      'primaryAllocated',
      'patientPaid',
      'primaryPaid',
    ];

    fields.forEach(field =>
      this.formService.apply(`${path}${field}`, initialSelectState[field]),
    );
  }

  __revertRow(selectedIndex) {
    if (this.formService.isDirty) {
      const li = this.state.items[selectedIndex];

      this.__addOrRemoveRow(selectedIndex, li, 'debits');
      this.__addOrRemoveRow(selectedIndex, li, 'adjustments');

      const initialSelectState =
        this.formService.__initialState.items[selectedIndex];

      this.__revertDebit(selectedIndex, initialSelectState);
      this.__revertAdjustment(selectedIndex, initialSelectState);
      this.__revertLineItem(selectedIndex, initialSelectState);
      this.__revertNonPrimaryPayerDebits(selectedIndex, initialSelectState);

      this.formService.validate();
    }
  }

  __renderHeader() {
    const title = `Recommended Payer Payment Allocation - Payment ID ${this.paymentDetail.paymentNumber}`;

    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        .title="${title}"
        .onCancel="${this.handlers.dismissPayment}"
        showCancelButton
      >
      </neb-popup-header>
    `;
  }

  __renderPaymentDetails() {
    return html`
      <neb-table-readonly
        id="${ELEMENTS.paymentDetail.id}"
        .model="${this.__paymentModel}"
        .config="${this.__getPaymentConfig()}"
        .layout="${this.layout}"
      ></neb-table-readonly>

      <neb-table-payment-transaction
        id="${ELEMENTS.transactionDetail.id}"
        name="paymentTransaction"
        .layout="${this.layout}"
        .paymentDetail="${this.paymentDetail}"
        .paymentTypes="${this.paymentTypes}"
        .practiceUsers="${this.practiceUsers}"
        .model="${this.state.paymentTransaction}"
        .errors="${this.errors.paymentTransaction}"
        .onChange="${this.handlers.change}"
        ?readOnlyDetails="${true}"
      ></neb-table-payment-transaction>
    `;
  }

  __renderRecommendedChargesHeader() {
    const { id, title, description } = ELEMENTS.tableHeader;

    return html`
      <neb-header
        id="${id}"
        .label="${title}"
        .description="${description}"
      ></neb-header>
    `;
  }

  __renderTransactionDateFromFilter() {
    return html`
      <neb-date-picker
        id="${ELEMENTS.dateOfTransactionFrom.id}"
        name="dateOfTransactionFrom"
        class="field"
        tabindex="-1"
        label="Transaction From Date"
        helperText=" "
        manualPopoverPosition="${POPOVER_POSITION.CENTER}"
        .selectedDate="${this.itemFilters.dateOfTransactionFrom}"
        .isDateSelectable="${this.handlers.dateOfTransactionFromSelectable}"
        .onChange="${this.handlers.itemsFilterChange}"
        momentFlag
      ></neb-date-picker>
    `;
  }

  __renderTransactionDateToFilter() {
    return html`
      <neb-date-picker
        id="${ELEMENTS.dateOfTransactionTo.id}"
        name="dateOfTransactionTo"
        class="field"
        tabindex="-1"
        label="Transaction To Date"
        helperText=" "
        manualPopoverPosition="${POPOVER_POSITION.CENTER}"
        .selectedDate="${this.itemFilters.dateOfTransactionTo}"
        .isDateSelectable="${this.handlers.dateOfTransactionToSelectable}"
        .onChange="${this.handlers.itemsFilterChange}"
        momentFlag
      ></neb-date-picker>
    `;
  }

  __renderPatientFilter() {
    return html`
      <neb-select
        id="${ELEMENTS.selectPatient.id}"
        name="patient"
        class="field"
        tabindex="-1"
        label="Patient"
        .items="${this.patients}"
        .value="${this.itemFilters.patient}"
        .onChange="${this.handlers.itemsFilterChange}"
        ?disabled="${this.paymentDetail.patientId && !this.hasRelationships}"
      ></neb-select>
    `;
  }

  __renderFilters() {
    return html`
      <div class="container-filter">
        ${this.__renderTransactionDateFromFilter()}
        ${this.__renderTransactionDateToFilter()}
        ${this.__renderPatientFilter()} ${this.__renderLocationFilter()}
      </div>
    `;
  }

  __renderLocationFilter() {
    return html`
      <neb-select
        id="${ELEMENTS.selectLocation.id}"
        name="locations"
        class="field"
        tabindex="-1"
        label="Locations"
        .items="${this.locations}"
        .value="${this.itemFilters.locations}"
        .onChange="${this.handlers.itemsFilterChange}"
        multiSelect
      >
      </neb-select>
    `;
  }

  __renderTable() {
    return html`
      <neb-table-allocation-charges-2
        id="${ELEMENTS.previewChargesTable.id}"
        name="items"
        emptyMessage="${NO_CHARGES_TO_PREVIEW_MESSAGE}"
        .model="${this.state.items}"
        .patientId="${this.paymentDetail.patientId}"
        .paymentDetail="${this.paymentDetail}"
        .allocatableId="${null}"
        .preallocated="${this.__preallocated}"
        .selectIndexes="${this.__selectIndexes}"
        .menuItems="${this.__menuItemsMap}"
        .payers="${this.payers}"
        .errors="${this.errors.items}"
        .hasErrors="${this.formService.hasErrors}"
        .onItemCheck="${this.handlers.checkItem}"
        .onChangeAllowed="${this.handlers.changeAllowed}"
        .onClickInvoiceLink="${this.handlers.viewCharge}"
        .onClickPaymentLink="${this.handlers.viewPayment}"
        .onSelectPayer="${this.handlers.selectPayer}"
        .onOpenPatientOwedPopup="${this.handlers.openPatientOwedPopup}"
        .onChangePatientOwed="${this.handlers.changePatientOwed}"
        .onChangePatientAllocated="${this.handlers.changePatientAllocated}"
        .onChangePrimaryOwed="${this.handlers.changePrimaryOwed}"
        .onChangePrimaryAllocated="${this.handlers.changePrimaryAllocated}"
        .onOpenSecondaryOwedPopup="${this.handlers.openSecondaryOwedPopup}"
        .onAddSecondaryPayer="${this.handlers.addSecondaryPayer}"
        .onChangeSecondaryOwed="${this.handlers.changeSecondaryOwed}"
        .onOpenAdjustmentsPopup="${this.handlers.openAdjustmentsPopup}"
        .onChangeAdjustments="${this.handlers.changeAdjustments}"
        .onSelectAll="${this.handlers.selectAll}"
        .onDeselectAll="${this.handlers.deselectAll}"
        .hasRCMSecondaryField="${this.hasRCMSecondaryField}"
      ></neb-table-allocation-charges-2>
    `;
  }

  __renderRecommendedCharges() {
    return html`
      ${this.__renderRecommendedChargesHeader()} ${this.__renderFilters()}
      ${this.__renderTable()}
    `;
  }

  __isEditingPreviewAllocations() {
    return (
      this.model?.items?.length && this.__selectIndexes.some(index => index)
    );
  }

  __isActionBarEnabled() {
    return this.formService.isDirty || this.__isEditingPreviewAllocations();
  }

  renderActionBar() {
    if (this.__isActionBarEnabled()) {
      return html`
        <neb-action-bar
          id="${ELEMENTS.actionBar.id}"
          .onConfirm="${this.handlers.save}"
          .onCancel="${this.handlers.cancel}"
          confirmLabel="Allocate"
          cancelLabel="${this.cancelLabel}"
        ></neb-action-bar>
      `;
    }

    return '';
  }

  renderContent() {
    return html`
      ${this.__renderHeader()} ${this.__renderPaymentDetails()}
      ${this.__renderRecommendedCharges()}
    `;
  }
}
customElements.define('neb-form-preview-allocation', NebFormPreviewAllocation);
