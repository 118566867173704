import { parseDate } from '../../../../../neb-utils/date-util';

const DATE_3_MONTHS_BEFORE = parseDate().startOf('day').subtract(3, 'month');

export const sortPatients = patients =>
  // eslint-disable-next-line complexity
  patients.sort((a, b) => {
    if (a.name.last < b.name.last) return -1;
    if (a.name.last > b.name.last) return 1;
    if (a.name.first < b.name.first) return -1;
    if (a.name.first > b.name.first) return 1;
    if (a.name.middle < b.name.middle) return -1;
    if (a.name.middle > b.name.middle) return 1;
    if (a.name.preferred < b.name.preferred) return -1;
    if (a.name.preferred > b.name.preferred) return 1;
    return 0;
  });

export const sortRawPatients = patients =>
  // eslint-disable-next-line complexity
  patients.sort((a, b) => {
    if (a.lastName?.toLowerCase() < b.lastName?.toLowerCase()) return -1;
    if (a.lastName?.toLowerCase() > b.lastName?.toLowerCase()) return 1;
    if (a.firstName?.toLowerCase() < b.firstName?.toLowerCase()) return -1;
    if (a.firstName?.toLowerCase() > b.firstName?.toLowerCase()) return 1;
    if (a.middleName?.toLowerCase() < b.middleName?.toLowerCase()) return -1;
    if (a.middleName?.toLowerCase() > b.middleName?.toLowerCase()) return 1;

    if (a.preferredName?.toLowerCase() < b.preferredName?.toLowerCase()) {
      return -1;
    }

    if (a.preferredName?.toLowerCase() > b.preferredName?.toLowerCase()) {
      return 1;
    }
    return 0;
  });

function getDefaultFilterDatesForPayment(itemFilters, payment) {
  if (
    !!payment.payerPlanId &&
    !itemFilters.dateOfTransactionFrom &&
    !itemFilters.dateOfTransactionTo
  ) {
    return { ...itemFilters, dateOfTransactionFrom: DATE_3_MONTHS_BEFORE };
  }

  return itemFilters;
}

export function getFilterTransactionDates(payment, query = {}) {
  if (query) {
    return {
      ...(query.dateOfTransactionFrom
        ? {
            dateOfTransactionFrom: parseDate(
              query.dateOfTransactionFrom,
            ).startOf('day'),
          }
        : { dateOfTransactionFrom: null }),
      ...(query.dateOfTransactionTo
        ? {
            dateOfTransactionTo: parseDate(query.dateOfTransactionTo).endOf(
              'day',
            ),
          }
        : { dateOfTransactionTo: null }),
    };
  }

  let paymentDates = {
    ...(payment?.dateOfServiceFrom
      ? {
          dateOfTransactionFrom: parseDate(payment.dateOfServiceFrom).startOf(
            'day',
          ),
        }
      : {}),
    ...(payment?.dateOfServiceTo
      ? {
          dateOfTransactionTo: parseDate(payment.dateOfServiceTo).endOf('day'),
        }
      : {}),
  };

  paymentDates = getDefaultFilterDatesForPayment(paymentDates, payment);

  return paymentDates;
}

export function getPayerPlansFromPatientsInsurances(patientsInsurances) {
  return Object.values(
    patientsInsurances.reduce((memo, obj) => {
      memo[obj.payerPlanId] = obj.payerPlan;
      return memo;
    }, {}),
  );
}

export function getPatientsHash(patients) {
  return patients.reduce((memo, patient) => {
    memo[patient.id] = patient;
    return memo;
  }, {});
}
