import { html, css } from 'lit';

import NebPopup from '../../../packages/neb-popup/src/neb-popup';
import { CSS_FONT_FAMILY } from '../../../packages/neb-styles/neb-variables';
import { NebFormUpdateFeeScheduleBillingCode } from '../forms/settings/neb-form-update-fee-schedule-billing-code';

export const ELEMENTS = {
  formFeeSchedule: {
    id: 'form-fee-schedule',
  },
};

class NebPopupUpdateFeeScheduleBillingCode extends NebPopup {
  static get properties() {
    return {
      model: Object,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          font-family: ${CSS_FONT_FAMILY};
        }

        .form {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 850px;
          height: 100%;
          max-height: 650px;
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.dynamic = true;
    this.title = 'Update Fee Schedule Adjustment Codes';
    this.model = NebFormUpdateFeeScheduleBillingCode.createModel();
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      save: model => {
        this.onClose(model);
      },
    };
  }

  __getFormModel() {
    return {
      feeSchedules: this.model.feeSchedules,
    };
  }

  renderContent() {
    return html`
      <neb-form-update-fee-schedule-billing-code
        id="${ELEMENTS.formFeeSchedule.id}"
        class="form"
        .model="${this.__getFormModel()}"
        .patientAdjustmentCodes="${this.model.patientAdjustmentCodes}"
        .payerAdjustmentCodes="${this.model.payerAdjustmentCodes}"
        .onSave="${this.handlers.save}"
        .onCancel="${this.handlers.cancel}"
      ></neb-form-update-fee-schedule-billing-code>
    `;
  }
}

customElements.define(
  'neb-popup-update-fee-schedule-billing-code',
  NebPopupUpdateFeeScheduleBillingCode,
);
