import { html, css, LitElement } from 'lit';

import '../../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-textarea';

import { POPOVER_POSITION } from '../../../../packages/neb-lit-components/src/components/neb-date-picker';
import { baseStyles } from '../../../../packages/neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_SPACING_ROW,
} from '../../../../packages/neb-styles/neb-variables';
import { parseDate } from '../../../../packages/neb-utils/date-util';
import { DISPLAY_MODES } from '../../../components/controls/inputs/neb-select-search-multi';
import { layoutStyles } from '../../../styles';
import {
  fetchTags,
  searchTags,
  findAndCreateNewTags,
} from '../utils/document-tag-util';

export const ELEMENTS = {
  documentName: { id: 'document-name', name: 'name' },
  documentDate: { id: 'document-date', name: 'date' },
  documentNote: { id: 'document-note', name: 'note' },
  documentTags: { id: 'document-tags', name: 'tags' },
};

class NebDocumentFields extends LitElement {
  static get properties() {
    return {
      model: Object,
      __errors: Array,
      __tagItems: Array,
      __tagItemsSearch: String,
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  static createModel() {
    return {
      name: '',
      note: '',
      date: parseDate().startOf('day'),
      uploadDate: parseDate().startOf('day'),
      tags: [],
    };
  }

  __initState() {
    this.model = this.constructor.createModel();
    this.__tagItems = [];
    this.__tagItemsSearch = '';

    this.__errors = {
      name: '',
      date: '',
    };

    this.onModelUpdated = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      change: e => {
        this.model = { ...this.model, [e.name]: e.value };
        this.__validate();
      },
      setDate: date => {
        this.model = { ...this.model, date };
        this.__validate();
      },
      clearDate: () => {
        this.model = { ...this.model, date: null };
        this.__validate();
      },
      modelUpdated: () => {
        this.onModelUpdated(this.model);
      },
      changeTags: async ({ value: tags }) => {
        const updatedTags = await findAndCreateNewTags(tags);
        this.model = { ...this.model, tags: updatedTags };
      },
      searchTags: async e => {
        this.__tagItemsSearch = e.value.trim();
        this.__tagItems = await searchTags(this.__tagItemsSearch);
      },
      fetchTags: async () => {
        if (this.__tagItems.length) return;
        this.__tagItems = await fetchTags();
      },
    };
  }

  __validate() {
    this.__errors = {
      name: !this.model.name ? 'Required' : '',
      date: !this.model.date ? 'Required' : '',
    };

    return !Object.entries(this.__errors).find(([_, value]) => value.length);
  }

  __getFilteredTagItems() {
    return this.__tagItems.filter(
      item =>
        !this.model.tags.find(modelItem => modelItem.data.id === item.data.id),
    );
  }

  static get styles() {
    return [
      baseStyles,
      layoutStyles,
      css`
        .container {
          padding: ${CSS_SPACING} 0 ${CSS_SPACING};
          width: 100%;
          height: 100%;
        }

        .content {
          display: grid;
          grid-template-columns: 1fr;
          grid-template-rows: min-content min-content min-content 1fr;
          gap: ${CSS_SPACING_ROW};
          padding-left: ${CSS_SPACING};
          padding-right: ${CSS_SPACING};
          height: 100%;
        }

        .field {
          padding-top: ${CSS_SPACING_ROW};
        }
      `,
    ];
  }

  updated(changedProps) {
    if (changedProps.has('model')) {
      this.__handlers.modelUpdated();
    }
  }

  __renderFields() {
    return html`
      <neb-textfield
        id="${ELEMENTS.documentName.id}"
        name="${ELEMENTS.documentName.name}"
        helper="Required"
        .value="${this.model.name}"
        .error="${this.__errors.name}"
        .onChange="${this.__handlers.change}"
        maxLength="255"
        label="Document Name"
      ></neb-textfield>

      <neb-date-picker
        id="${ELEMENTS.documentDate.id}"
        class="input input-date"
        name="${ELEMENTS.documentDate.name}"
        label="Document Date"
        .value="${this.model.date}"
        ?invalid="${!!this.__errors.date}"
        .selectedDate="${this.model.date}"
        .manualPopoverPosition="${POPOVER_POSITION.CENTER}"
        .onClick="${this.__handlers.setDate}"
        .onClear="${this.__handlers.clearDate}"
        momentFlag
      ></neb-date-picker>

      <neb-select-search-multi
        id="${ELEMENTS.documentTags.id}"
        class="input-tags"
        name="${ELEMENTS.documentTags.name}"
        .items="${this.__getFilteredTagItems()}"
        .value="${this.model.tags}"
        .search="${this.__tagItemsSearch}"
        .onChange="${this.__handlers.changeTags}"
        .onSearch="${this.__handlers.searchTags}"
        label="Document Tag(s)"
        helper=" "
        maxLength="20"
        maxVisibleItems="3"
        .scrollOnKeydown="${false}"
        @focus="${this.__handlers.fetchTags}"
        .displayMode="${DISPLAY_MODES.PILLS}"
      ></neb-select-search-multi>

      <neb-textarea
        id="${ELEMENTS.documentNote.id}"
        name="${ELEMENTS.documentNote.name}"
        class="field"
        .value="${this.model.note}"
        .onChange="${this.__handlers.change}"
        label="Note"
      ></neb-textarea>
    `;
  }

  render() {
    return html`
      <div class="container">
        <div class="content">${this.__renderFields()}</div>
      </div>
    `;
  }
}

customElements.define('neb-document-fields', NebDocumentFields);
