import { LitElement, html, css } from 'lit';

import { BUTTON_ROLE } from '../../../../packages/neb-lit-components/src/components/neb-button';
import {
  CSS_SPACING,
  CSS_COLOR_HIGHLIGHT,
} from '../../../../packages/neb-styles/neb-variables';
import { CSS_SMALL_SPACING } from '../../../styles';

export const ELEMENTS = {
  agreementContent: {
    id: 'agreement-content',
  },
  privacyLink: {
    id: 'privacy-link',
  },
  buttonAccept: {
    id: 'button-accept',
  },
};

class NebPageUserAgreement extends LitElement {
  static get properties() {
    return {
      agreementText: { type: String },
      privacyPolicyUrl: { type: String },
      hasReachedBottom: { type: Boolean, state: true },
    };
  }

  static get styles() {
    return css`
      :host {
        display: flex;
        flex-direction: column;
        flex: 1;
      }

      .agreement-content {
        flex: 1;
        overflow-y: auto;
        padding: ${CSS_SPACING};
        border: 1px solid #ccc;
        border-radius: 4px;
        margin-bottom: ${CSS_SPACING};
        min-height: 300px;
        max-height: calc(60vh - 120px);
      }

      .agreement-content h2 {
        margin-top: 0;
        margin-bottom: 1rem;
      }

      .agreement-content h3 {
        margin-top: 1.5rem;
        margin-bottom: 0.5rem;
      }

      .agreement-content p {
        margin-bottom: 1rem;
        line-height: 1.5;
      }

      .button-container {
        display: flex;
        gap: ${CSS_SMALL_SPACING};
        margin-top: ${CSS_SPACING};
        justify-content: flex-end;
      }

      .privacy-link {
        margin-top: ${CSS_SMALL_SPACING};
        text-align: center;
      }

      .privacy-link a {
        color: ${CSS_COLOR_HIGHLIGHT};
        text-decoration: underline;
      }
    `;
  }

  constructor() {
    super();
    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.hasReachedBottom = false;
    this.agreementText = '';
    this.privacyPolicyUrl = '';

    this.onAccept = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      handleScroll: e => {
        const element = e.target;
        const reachedBottom =
          Math.abs(
            element.scrollHeight - element.scrollTop - element.clientHeight,
          ) < 1;

        if (reachedBottom && !this.hasReachedBottom) {
          this.hasReachedBottom = true;
        }
      },
      handleAccept: () => {
        this.onAccept();
      },
    };
  }

  firstUpdated() {
    const element = this.shadowRoot.getElementById(
      ELEMENTS.agreementContent.id,
    );
    const isScrollable = element.scrollHeight > element.clientHeight;

    if (!isScrollable) {
      this.hasReachedBottom = true;
    }
  }

  render() {
    return html`
      <div
        id="${ELEMENTS.agreementContent.id}"
        class="agreement-content"
        @scroll="${this.__handlers.handleScroll}"
      >
        ${this.agreementText}
      </div>

      <div class="privacy-link">
        <a
          id="${ELEMENTS.privacyLink.id}"
          href="${this.privacyPolicyUrl}"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
      </div>

      <div class="button-container">
        <neb-button
          id="${ELEMENTS.buttonAccept.id}"
          class="button"
          label="ACCEPT"
          .role="${BUTTON_ROLE.CONFIRM}"
          @click="${this.__handlers.handleAccept}"
          .disabled="${!this.hasReachedBottom}"
        ></neb-button>
      </div>
    `;
  }
}

customElements.define('neb-page-user-agreement', NebPageUserAgreement);
