import '../../../../neb-lit-components/src/components/neb-cell-fee-schedule';

import { css, html } from 'lit';

import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../../neb-lit-components/src/utils/overlay-constants';
import { mapAddedChargeToEncounterCharge } from '../encounter-charge';
import { getPracticeSettings, PRACTICE_SETTINGS } from '../practice-settings';

import formServiceSetupCharges from './form-service-setup-charges';

const mapAddedCharge = (charges, selectedCharges, autoPost) => {
  const addedCharges = charges.filter(
    c => !selectedCharges.find(sc => sc.id === c.id),
  );

  return mapAddedChargeToEncounterCharge({
    charges: addedCharges,
    autoPost,
  }).map((ch, idx) => ({
    ...ch,
    billedAmount: ch.feeScheduleCharge
      ? ch.feeScheduleCharge
      : addedCharges[idx].billedAmount,
    taxName: ch.taxName || null,
    taxRate: ch.taxRate || null,
  }));
};

export default async ({
  stateCharges,
  settingCharges,
  isDirty,
  filterNdc,
  hasDHChargeUnitsFF = false,
}) => {
  const selectedCharges = settingCharges.filter(tc =>
    stateCharges.some(sc => tc.id === sc.chargeId),
  );

  const TABLE_CONFIG = {
    tableConfig: [
      {
        key: 'procedure',
        label: 'Procedure',
        flex: css`1 0 80px`,
      },
      {
        key: 'description',
        label: 'Description',
        flex: css`1 0 100px`,
        style: css`
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
        `,
      },
      {
        key: 'modifiers',
        label: 'Modifiers',
        flex: css`1 0 80px`,
        formatter: value => (value ? value.filter(m => m).join(',') : ''),
      },
      ...(hasDHChargeUnitsFF
        ? [
            {
              key: 'units',
              label: 'Units',
              flex: css`1 0 80px`,
            },
          ]
        : []),
      {
        key: 'feeScheduleName',
        label: 'Fee Schedule',
        flex: css`1 0 120px`,
        style: css`
          flex-direction: column;
        `,
        formatter: (_, item) => html`
          <neb-cell-fee-schedule .model="${item}"></neb-cell-fee-schedule>
        `,
      },
    ],
    mobileTableConfig: [
      {
        mobile: true,
        key: 'procedure',
        label: 'Procedure',
        flex: css`0 0 120px`,
      },
      {
        mobile: true,
        key: 'description',
        label: 'Description',
        flex: css`1 0 0`,
      },
      {
        mobile: true,
        key: 'modifiers',
        label: 'Mods',
        flex: css`0 0 40px`,
        style: 'white-space: pre-line',
        formatter: value => (value ? value.filter(m => m).join('\n') : ''),
      },
    ],
    itemName: 'charge',
    itemPluralName: 'charges',
    title: 'Add Charge - Encounter',
  };

  const overlayCharges = await openOverlay(OVERLAY_KEYS.LEDGER_ADD_CHARGE, {
    config: TABLE_CONFIG,
    items: settingCharges,
    selectedCharges,
    isDirty,
  });

  if (!overlayCharges) return [];

  const practiceSettings = await getPracticeSettings();

  const addedCharges = mapAddedCharge(
    overlayCharges,
    selectedCharges,
    practiceSettings[PRACTICE_SETTINGS.AUTO_POST_CHARGES],
  );

  return formServiceSetupCharges({
    charges: addedCharges,
    stateTotalCharges: stateCharges.length,
    filterNdc,
  });
};
