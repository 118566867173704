import { html, css } from 'lit';

import { POPOVER_POSITION } from '../../../packages/neb-lit-components/src/components/neb-popover';
import { parseDate } from '../../../packages/neb-utils/date-util';
import Debouncer from '../../../packages/neb-utils/debouncer';
import * as masks from '../../../packages/neb-utils/masks';
import { CSS_SPACING, CSS_SMALL_SPACING } from '../../styles';
import { DISPLAY_MODES } from '../controls/inputs/neb-select-search-multi';

import '../../../packages/neb-lit-components/src/components/inputs/neb-select';
import '../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import '../controls/field-groups/neb-range-date';
import { NebFilters, ELEMENTS as BASE_ELEMENTS } from './neb-filters';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  providersSelect: { id: 'providers-select' },
  locationsSelect: { id: 'locations-select' },
  claimStatusesSelect: { id: 'claim-statuses-select' },
  dosRange: { id: 'dos-range' },
  daysAged: { id: 'days-aged' },
  payerBalance: { id: 'payer-balance' },
  patientBalance: { id: 'patient-balance' },
  primaryBalance: { id: 'primary-balance' },
  secondaryBalance: { id: 'secondary-balance' },
  adjustmentBalance: { id: 'adjustment-balance' },
  totalBalance: { id: 'total-balance' },
  billTypesSelect: { id: 'bill-types-select' },
  payersSelect: { id: 'payers-select' },
  billedStatusesSelect: { id: 'billed-statuses-select' },
  procedureCodesSelect: { id: 'procedure-codes-select' },
  adjustmentsSelect: { id: 'adjustments-select' },
  followUpExistsSelect: { id: 'follow-up-exists-select' },
  followUpDaysUntilDue: { id: 'follow-up-days-until-due' },
  followUpCompletedSelect: { id: 'follow-up-completed-select' },
};

const NO_FOLLOW_UP_OPTION = { label: '', value: null };

export const FOLLOW_UP_EXISTS_OPTIONS = [
  NO_FOLLOW_UP_OPTION,
  { label: 'Has Follow-up', value: true },
  { label: 'No Follow-up', value: false },
];

export const FOLLOW_UP_COMPLETED_OPTIONS = [
  NO_FOLLOW_UP_OPTION,
  { label: 'Completed', value: true },
  { label: 'Not Completed', value: false },
];

class NebFiltersArHub extends NebFilters {
  static get properties() {
    return {
      search: Object,
      items: Object,
      appliedFilters: Object,
      filteredItems: Object,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .container {
          border: 0;
        }

        .content {
          grid-template-columns: repeat(4, 1fr);
        }

        .select {
          padding-bottom: 16px;
        }

        .container-buttons {
          padding: 0 ${CSS_SPACING};
          display: inline-grid;
          grid-gap: ${CSS_SPACING};
          grid-template-columns: auto auto;
          margin-top: ${CSS_SMALL_SPACING};
        }

        neb-select-search-multi,
        neb-range-date {
          height: 55px;
          width: auto;
        }
      `,
    ];
  }

  static createModel() {
    return {};
  }

  initState() {
    super.initState();

    this.search = {
      providers: '',
      locations: '',
      billedStatuses: '',
      claimStatuses: '',
      payers: '',
      procedureCodes: '',
      adjustments: '',
    };

    this.items = {
      providers: [],
      locations: [],
      claimStatuses: [],
      payers: [],
      procedureCodes: [],
      adjustments: [],
      billTypes: [],
      billedStatuses: [],
    };

    this.filteredItems = {
      providers: [],
      locations: [],
      billedStatuses: [],
      claimStatuses: [],
      billTypes: [],
      payers: [],
      procedureCodes: [],
      adjustments: [],
    };

    this.appliedFilters = {
      providers: [],
      locations: [],
      claimStatuses: [],
      billTypes: [],
      payers: [],
      procedureCodes: [],
      adjustments: [],
      billedStatuses: [],
      dosFrom: null,
      dosTo: null,
      daysAged: null,
      payerBalance: null,
      patientBalance: null,
      primaryBalance: null,
      secondaryBalance: null,
      adjustmentBalance: null,
      totalBalance: null,
      followUpExists: null,
      followUpDaysUntilDue: null,
      followUpCompleted: null,
    };

    this.onReset = () => {};
    this.__applyDebouncer = new Debouncer(() => {
      this.onApply(this.__pendingAppliedFilters);
    }, 200);
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      search: ({ name, value }) => {
        this.search = {
          ...this.search,
          [name]: value,
        };

        const items = this.items[name];
        const filteredItems = items.filter(item =>
          item.label
            .toLowerCase()
            .replace(/[\s-]/g, '')
            .includes(value.toLowerCase().replace(/[\s-]/g, '')),
        );

        this.filteredItems = {
          ...this.filteredItems,
          [name]: filteredItems,
        };
      },
      selectMulti: ({ name, value }) => {
        const ids = value.map(item => item.value.id);

        this.appliedFilters = {
          ...this.appliedFilters,
          [name]: [...ids],
        };
      },
      selectDosRange: ({ name, value }) => {
        const dosName = name.split('.')[1];
        const field = dosName === 'from' ? 'dosFrom' : 'dosTo';

        this.appliedFilters = {
          ...this.appliedFilters,
          [field]: value ? value.toISOString() : null,
        };
      },
      change: ({ name, value }) => {
        this.appliedFilters = {
          ...this.appliedFilters,
          [name]: value,
        };
      },
      singleSelect: ({ name, value }) => {
        this.appliedFilters = {
          ...this.appliedFilters,
          [name]: value?.value,
        };
      },
      apply: () => {
        this.__pendingAppliedFilters = this.appliedFilters;
        this.filteredItems = { ...this.items };
        this.search = Object.keys(this.search).reduce(
          (acc, key) => ({
            ...acc,
            [key]: '',
          }),
          {},
        );

        this.__applyDebouncer.debounce();
      },
      reset: () => {
        this.onReset();
      },
      clickIcon: name => {
        this.appliedFilters = {
          ...this.appliedFilters,
          [name]: null,
        };
      },
    };
  }

  updated(changedProps) {
    super.updated(changedProps);

    if (changedProps.has('items')) {
      this.filteredItems = this.items;
    }
  }

  __getDosRangeModel() {
    if (!this.appliedFilters) {
      return { from: null, to: null };
    }

    return {
      from: this.appliedFilters.dosFrom
        ? parseDate(this.appliedFilters.dosFrom)
        : null,
      to: this.appliedFilters.dosTo
        ? parseDate(this.appliedFilters.dosTo)
        : null,
    };
  }

  __getSelectedValue(name) {
    const values = this.appliedFilters[name];

    if (values.length) {
      return this.items[name].filter(item => values.includes(item.value.id));
    }

    return [];
  }

  __getTrailingIcon(name) {
    return this.appliedFilters[name] ? 'neb:clear' : '';
  }

  __getFollowUpValue(filterName, options) {
    if (this.appliedFilters[filterName] === null) {
      return NO_FOLLOW_UP_OPTION;
    }

    return this.appliedFilters[filterName] ? options[1] : options[2];
  }

  __renderProviderLocationFields() {
    return html`
      <neb-select-search-multi
        id="${ELEMENTS.providersSelect.id}"
        name="providers"
        label="Providers"
        .search="${this.search.providers}"
        .displayMode="${DISPLAY_MODES.MENU}"
        .items="${this.filteredItems.providers}"
        .totalItems="${this.items.providers.length}"
        .onSearch="${this.handlers.search}"
        .onChange="${this.handlers.selectMulti}"
      ></neb-select-search-multi>

      <neb-select-search-multi
        id="${ELEMENTS.locationsSelect.id}"
        name="locations"
        label="Locations"
        .search="${this.search.locations}"
        .displayMode="${DISPLAY_MODES.MENU}"
        .items="${this.filteredItems.locations}"
        .totalItems="${this.items.locations.length}"
        .onSearch="${this.handlers.search}"
        .onChange="${this.handlers.selectMulti}"
      ></neb-select-search-multi>
    `;
  }

  __renderDateFields() {
    return html`
      <neb-range-date
        id="${ELEMENTS.dosRange.id}"
        name="dateRange"
        label="Date of Service"
        .model="${this.__getDosRangeModel()}"
        .onChange="${this.handlers.selectDosRange}"
        .manualPosition="${POPOVER_POSITION.CENTER}"
      ></neb-range-date>

      <neb-textfield
        id="${ELEMENTS.daysAged.id}"
        name="daysAged"
        label="Days Aged"
        placeholder="Greater than"
        trailingIcon="${this.__getTrailingIcon('daysAged')}"
        .mask="${masks.greaterThan}"
        .value="${this.appliedFilters?.daysAged || null}"
        .onChange="${this.handlers.change}"
        .onClickIcon="${this.handlers.clickIcon}"
      ></neb-textfield>
    `;
  }

  __renderBalanceFields() {
    return html`
      <neb-textfield
        id="${ELEMENTS.patientBalance.id}"
        name="patientBalance"
        label="Patient Balance >"
        placeholder="Greater than"
        trailingIcon="${this.__getTrailingIcon('patientBalance')}"
        .mask="${masks.currency}"
        .value="${this.appliedFilters.patientBalance}"
        .onChange="${this.handlers.change}"
        .onClickIcon="${this.handlers.clickIcon}"
        .pinLabel="${true}"
      ></neb-textfield>

      <neb-textfield
        id="${ELEMENTS.payerBalance.id}"
        name="payerBalance"
        label="Payer Balance >"
        placeholder="Greater than"
        trailingIcon="${this.__getTrailingIcon('payerBalance')}"
        .mask="${masks.currency}"
        .value="${this.appliedFilters.payerBalance}"
        .onChange="${this.handlers.change}"
        .onClickIcon="${this.handlers.clickIcon}"
        .pinLabel="${true}"
      ></neb-textfield>

      <neb-textfield
        id="${ELEMENTS.primaryBalance.id}"
        name="primaryBalance"
        label="Primary Balance >"
        placeholder="Greater than"
        trailingIcon="${this.__getTrailingIcon('primaryBalance')}"
        .mask="${masks.currency}"
        .value="${this.appliedFilters.primaryBalance}"
        .onChange="${this.handlers.change}"
        .onClickIcon="${this.handlers.clickIcon}"
        .pinLabel="${true}"
      ></neb-textfield>

      <neb-textfield
        id="${ELEMENTS.secondaryBalance.id}"
        name="secondaryBalance"
        label="Secondary Balance >"
        placeholder="Greater than"
        trailingIcon="${this.__getTrailingIcon('secondaryBalance')}"
        .mask="${masks.currency}"
        .value="${this.appliedFilters.secondaryBalance}"
        .onChange="${this.handlers.change}"
        .onClickIcon="${this.handlers.clickIcon}"
        .pinLabel="${true}"
      ></neb-textfield>

      <neb-textfield
        id="${ELEMENTS.adjustmentBalance.id}"
        name="adjustmentBalance"
        label="Adjustment Balance >"
        placeholder="Greater than"
        trailingIcon="${this.__getTrailingIcon('adjustmentBalance')}"
        .mask="${masks.currencyNegative}"
        .value="${this.appliedFilters.adjustmentBalance}"
        .onChange="${this.handlers.change}"
        .onClickIcon="${this.handlers.clickIcon}"
        .pinLabel="${true}"
      ></neb-textfield>

      <neb-textfield
        id="${ELEMENTS.totalBalance.id}"
        name="totalBalance"
        label="Total Balance >"
        placeholder="Greater than"
        trailingIcon="${this.__getTrailingIcon('totalBalance')}"
        .mask="${masks.currencyNegative}"
        .value="${this.appliedFilters.totalBalance}"
        .onChange="${this.handlers.change}"
        .onClickIcon="${this.handlers.clickIcon}"
        .pinLabel="${true}"
      ></neb-textfield>
    `;
  }

  __renderClaimFields() {
    return html`
      <neb-select-search-multi
        id="${ELEMENTS.claimStatusesSelect.id}"
        name="claimStatuses"
        label="Claim Status"
        .search="${this.search.claimStatuses}"
        .displayMode="${DISPLAY_MODES.MENU}"
        .items="${this.filteredItems.claimStatuses}"
        .totalItems="${this.items.claimStatuses.length}"
        .onSearch="${this.handlers.search}"
        .onChange="${this.handlers.selectMulti}"
      ></neb-select-search-multi>

      <neb-select
        id="${ELEMENTS.billTypesSelect.id}"
        name="billTypes"
        label="Bill Type"
        .items="${this.filteredItems.billTypes}"
        .value="${this.__getSelectedValue('billTypes')}"
        .onChange="${this.handlers.selectMulti}"
        multiSelect
      ></neb-select>

      <neb-select-search-multi
        id="${ELEMENTS.billedStatusesSelect.id}"
        name="billedStatuses"
        label="Billed Status"
        .search="${this.search.billedStatuses}"
        .displayMode="${DISPLAY_MODES.MENU}"
        .items="${this.filteredItems.billedStatuses}"
        .totalItems="${this.items.billedStatuses.length}"
        .onSearch="${this.handlers.search}"
        .onChange="${this.handlers.selectMulti}"
      ></neb-select-search-multi>
    `;
  }

  __renderPayerFields() {
    return html`
      <neb-select-search-multi
        id="${ELEMENTS.payersSelect.id}"
        name="payers"
        label="Payer"
        .search="${this.search.payers}"
        .displayMode="${DISPLAY_MODES.MENU}"
        .items="${this.filteredItems.payers}"
        .totalItems="${this.items.payers.length}"
        .onSearch="${this.handlers.search}"
        .onChange="${this.handlers.selectMulti}"
      ></neb-select-search-multi>
    `;
  }

  __renderCodeFields() {
    return html`
      <neb-select-search-multi
        id="${ELEMENTS.procedureCodesSelect.id}"
        name="procedureCodes"
        label="Procedure Codes"
        .search="${this.search.procedureCodes}"
        .displayMode="${DISPLAY_MODES.MENU}"
        .items="${this.filteredItems.procedureCodes}"
        .totalItems="${this.items.procedureCodes.length}"
        .onSearch="${this.handlers.search}"
        .onChange="${this.handlers.selectMulti}"
      ></neb-select-search-multi>

      <neb-select-search-multi
        id="${ELEMENTS.adjustmentsSelect.id}"
        name="adjustments"
        label="Adjustments"
        .search="${this.search.adjustments}"
        .displayMode="${DISPLAY_MODES.MENU}"
        .items="${this.filteredItems.adjustments}"
        .totalItems="${this.items.adjustments.length}"
        .onSearch="${this.handlers.search}"
        .onChange="${this.handlers.selectMulti}"
      ></neb-select-search-multi>
    `;
  }

  __renderFollowUpFields() {
    return html`
      <neb-select
        id="${ELEMENTS.followUpExistsSelect.id}"
        name="followUpExists"
        label="Follow Up Exists"
        .items="${FOLLOW_UP_EXISTS_OPTIONS}"
        .value="${this.__getFollowUpValue(
          'followUpExists',
          FOLLOW_UP_EXISTS_OPTIONS,
        )}"
        .onChange="${this.handlers.singleSelect}"
      ></neb-select>

      <neb-textfield
        id="${ELEMENTS.followUpDaysUntilDue.id}"
        name="followUpDaysUntilDue"
        label="Days Until Due"
        trailingIcon="${this.__getTrailingIcon('followUpDaysUntilDue')}"
        .mask="${masks.number}"
        .value="${this.appliedFilters?.followUpDaysUntilDue || null}"
        .onChange="${this.handlers.change}"
        .onClickIcon="${this.handlers.clickIcon}"
      ></neb-textfield>

      <neb-select
        id="${ELEMENTS.followUpCompletedSelect.id}"
        name="followUpCompleted"
        label="Follow Up Completed"
        .items="${FOLLOW_UP_COMPLETED_OPTIONS}"
        .value="${this.__getFollowUpValue(
          'followUpCompleted',
          FOLLOW_UP_COMPLETED_OPTIONS,
        )}"
        .onChange="${this.handlers.singleSelect}"
      ></neb-select>
    `;
  }

  renderContent() {
    return html`
      ${this.__renderProviderLocationFields()} ${this.__renderCodeFields()}
      ${this.__renderBalanceFields()} ${this.__renderPayerFields()}
      ${this.__renderClaimFields()} ${this.__renderFollowUpFields()}
      ${this.__renderDateFields()}
    `;
  }
}

window.customElements.define('neb-filters-ar-hub', NebFiltersArHub);
