import '../../../packages/neb-lit-components/src/components/neb-badge';
import './neb-icon';

import { LitElement, html, css } from 'lit';

import { isOverlayOpen } from '../../../packages/neb-lit-components/src/utils/misc';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../packages/neb-lit-components/src/utils/overlay-constants';
import { store } from '../../../packages/neb-redux/neb-redux-store';
import { getNotificationsCount } from '../../api-clients/notifications';
import { CSS_COLOR_GREY_5, CSS_SPACING } from '../../styles';
import { PollController } from '../../utils/poll-controller';

export const NEB_NOTIFICATIONS_COUNTER_REFRESH =
  'neb-notifications-counter-refresh';

export const ELEMENTS = {
  container: { id: 'container' },
  badge: { id: 'badge', tag: 'neb-badge' },
  icon: { id: 'icon', tag: 'neb-icon' },
};

export class NebNotificationsCounter extends LitElement {
  static get properties() {
    return {
      __count: { type: Number },
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
    this.__initServices();
  }

  __initState() {
    this.__count = 0;
  }

  __initHandlers() {
    this.__handlers = {
      click: async () => {
        const { overlays } = store.getState().popup;
        const overlayKey = OVERLAY_KEYS.NOTIFICATIONS;

        if (isOverlayOpen(overlays, overlayKey.name)) {
          return;
        }

        await openOverlay(overlayKey);
      },

      refresh: async () => {
        this.__count = await getNotificationsCount();
      },
    };
  }

  __initServices() {
    this.__pollController = new PollController(this, this.__handlers.refresh, {
      force: true,
    });
  }

  connectedCallback() {
    super.connectedCallback();

    window.addEventListener(
      NEB_NOTIFICATIONS_COUNTER_REFRESH,
      this.__handlers.refresh,
    );

    this.__handlers.refresh();
  }

  disconnectedCallback() {
    window.removeEventListener(
      NEB_NOTIFICATIONS_COUNTER_REFRESH,
      this.__handlers.refresh,
    );

    super.disconnectedCallback();
  }

  static get styles() {
    return [
      css`
        .container {
          position: relative;
          margin-right: ${CSS_SPACING};
          cursor: pointer;
        }

        .badge {
          position: absolute;
          left: 15px;
        }

        .icon {
          width: 24px;
          height: 24px;
          fill: ${CSS_COLOR_GREY_5};
          margin-top: 3px;
        }
      `,
    ];
  }

  __renderBadge() {
    if (!this.__count) {
      return '';
    }

    return html`
      <neb-badge
        id="${ELEMENTS.badge.id}"
        class="badge"
        .count="${this.__count}"
      ></neb-badge>
    `;
  }

  __renderIcon() {
    return html`
      <neb-icon
        id="${ELEMENTS.icon.id}"
        class="icon"
        .icon="${this.__count
          ? 'neb:notification_important'
          : 'neb:notification'}"
      ></neb-icon>
    `;
  }

  render() {
    return html`
      <div
        id="${ELEMENTS.container.id}"
        class="container"
        @click="${this.__handlers.click}"
      >
        ${this.__renderBadge()} ${this.__renderIcon()}
      </div>
    `;
  }
}

window.customElements.define(
  'neb-notifications-counter',
  NebNotificationsCounter,
);
