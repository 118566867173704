import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../../packages/neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_FONT_FAMILY,
  CSS_FONT_SIZE_BODY,
} from '../../../../styles';

import '../../../../../packages/neb-lit-components/src/components/neb-loading-overlay';
import '../../../../components/misc/neb-structured-document-header';

export const ELEMENTS = {
  header: { id: 'header' },
  narrative: { id: 'narrative' },
  loadingIndicator: { id: 'loading-indicator' },
};

class NebChartNotesStructuredDocumentsNarrativeView extends LitElement {
  static get properties() {
    return {
      document: Object,
      isSOAPTabSelected: Boolean,
      loading: Boolean,
      narrative: String,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .container {
          display: flex;
          flex-direction: column;
          max-height: 100%;
          padding: ${CSS_SPACING};
        }

        .narrative {
          font-family: ${CSS_FONT_FAMILY};
          font-size: ${CSS_FONT_SIZE_BODY};
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.document = {};
    this.isSOAPTabSelected = false;
    this.loading = false;
    this.narrative = '';

    this.onCopyNarrative = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      copyNarrative: () => {
        this.onCopyNarrative(this.narrative);
      },
    };
  }

  render() {
    return html`
      <div class="container">
        <neb-structured-document-header
          id="${ELEMENTS.header.id}"
          .document="${this.document}"
          .isSOAPTabSelected="${this.isSOAPTabSelected}"
          .onCopy="${this.__handlers.copyNarrative}"
        ></neb-structured-document-header>

        <span id="${ELEMENTS.narrative.id}" class="narrative"
          >${this.narrative}</span
        >

        <neb-loading-overlay
          id="${ELEMENTS.loadingIndicator.id}"
          .show="${this.loading}"
          title="Creating Narrative..."
        ></neb-loading-overlay>
      </div>
    `;
  }
}

customElements.define(
  'neb-chart-notes-structured-documents-narrative-view',
  NebChartNotesStructuredDocumentsNarrativeView,
);
