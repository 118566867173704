/* eslint-disable complexity */
import { css, html } from 'lit';

import NebTable, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../../../packages/neb-lit-components/src/components/tables/neb-table';
import '../../../../../packages/neb-lit-components/src/components/inputs/neb-select';
import { centsToCurrency } from '../../../../../packages/neb-utils/formatters';
import '../../../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import '../../../../../packages/neb-lit-components/src/components/field-groups/neb-modifiers';
import '../../../../../packages/neb-lit-components/src/components/neb-cell-fee-schedule';
import { number } from '../../../../../packages/neb-utils/masks';
import {
  getDiagnosisItemHeight,
  ITEM_MIN_WIDTH,
} from '../../../../utils/diagnoses';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  addButton: {
    selector: '[id^=add-button-]',
  },
  row: {
    selector: '[id^=row-]',
  },
  modifiers: {
    selector: '.modifiers',
  },
  units: {
    selector: '.units',
  },
  feeSchedule: {
    selector: '.fee-schedule',
  },
  diagnosisPointers: {
    selector: '.diagnosis-pointers',
  },
};

class NebTableCheckOutEncounterCharges extends NebTable {
  static get properties() {
    return {
      wrapModifiers: { type: Boolean, reflect: true },
      didBillingInfoChange: Boolean,
      diagnoses: Array,
      initialUnits: Array,
      feeScheduleId: String,
    };
  }

  initState() {
    super.initState();

    this.wrapModifiers = false;

    this.didBillingInfoChange = true;
    this.feeScheduleId = '';
    this.diagnoses = [];
    this.initialUnits = [];
    this.config = this.__calcConfig();
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      resize: () => {
        const windowSize = window.innerWidth;

        this.wrapModifiers = windowSize < 1200;

        this.config = this.__calcConfig();
      },
    };
  }

  __calcConfig() {
    return [
      {
        key: 'procedure',
        label: 'Procedure',
        flex: css`0 0 70px`,
        customStyle: 'word-break: break-word;',
        mobile: true,
      },
      {
        key: 'description',
        label: 'Description',
        flex: css`1 0 85px`,
        customStyle:
          'display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 3; overflow: hidden;',
        mobile: true,
      },
      {
        key: 'modifiers',
        label: 'Modifiers',
        flex: this.wrapModifiers ? css`0 0 120px` : css`0 0 225px`,
      },
      {
        key: 'units',
        label: 'Units',
        flex: css`1 0 40px`,
      },
      {
        key: 'unitCharge',
        label: 'Unit Chrg',
        flex: css`1 0 80px`,
        formatter: v => centsToCurrency(v),
      },
      {
        key: 'billedAmount',
        label: 'Billed',
        flex: css`1 0 60px`,
      },
      {
        key: 'feeScheduleCharges',
        label: 'Fee Schedule',
        flex: css`1 0 70px`,
      },
      {
        key: 'carePackage',
        label: 'Care Pkg',
        flex: css`1 0 30px`,
      },
      {
        key: 'diagnosisPointers',
        label: 'Diagnosis Pointer',
        flex: css`1 0 100px`,
      },
    ];
  }

  __getFeeScheduleModel(
    charge,
    didUnitsChange,
    hasBillingInfoChanged,
    feeScheduleId,
  ) {
    if (charge.encounterChargeId && !didUnitsChange && !hasBillingInfoChanged) {
      return (
        charge.feeScheduleCharges.chartingValues || { feeScheduleName: '' }
      );
    }

    const feeScheduleModel = charge.feeScheduleCharges[feeScheduleId];

    return feeScheduleModel || { feeScheduleName: '' };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host(:not([wrapModifiers])) .modifiers {
          --modifier-columns: repeat(4, 50.4px);
        }

        :host([wrapModifiers]) .modifiers {
          --modifier-columns: repeat(2, 50.4px);
        }

        .units {
          --text-indent: 8px;
          width: 50px;
        }

        .reorder-spacer {
          flex: 0 0 20px;
          width: 20px;
        }
      `,
    ];
  }

  connectedCallback() {
    window.addEventListener('resize', this.handlers.resize);
    super.connectedCallback();
  }

  disconnectedCallback() {
    window.removeEventListener('resize', this.handlers.resize);
    super.disconnectedCallback();
  }

  __renderHeader() {
    const reorder = this.reorder !== false;

    return !this.hideHeader
      ? html`
          <div
            id="${ELEMENTS.header.id}"
            class="row row-header"
            part="row-header"
            ?preview="${this.preview}"
          >
            ${this.__renderSpacerCell(reorder, 'reorder-spacer')}
            ${this.__renderSelectAll()} ${this.__renderConfigHeaderCells()}
            ${this.__renderRemoveAll()} ${this.__renderModeHeaderCell()}
          </div>
        `
      : '';
  }

  renderDataCell(value, columnConfig, rowIndex, name, error) {
    switch (columnConfig.key) {
      case 'modifiers':
        return html`
          <neb-modifiers
            class="modifiers"
            label=" "
            helper=" "
            name="${name}"
            .errors="${error}"
            .values="${value}"
            .onChange="${this.handlers.change}"
          ></neb-modifiers>
        `;

      case 'units':
        return html`
          <neb-textfield
            name="${name}"
            class="units"
            helper=" "
            maxLength="3"
            label=" "
            .inputMode="${'numeric'}"
            .error="${error}"
            .value="${value}"
            .mask="${number}"
            .onChange="${this.handlers.change}"
          ></neb-textfield>
        `;

      case 'billedAmount': {
        const charge = this.model[rowIndex];
        const didUnitsChange =
          this.initialUnits[rowIndex] !== this.model[rowIndex].units;

        const { feeScheduleCharge } = this.__getFeeScheduleModel(
          charge,
          didUnitsChange,
          this.didBillingInfoChange,
          this.feeScheduleId,
        );

        if (feeScheduleCharge) {
          return centsToCurrency(feeScheduleCharge * charge.units);
        }

        return centsToCurrency(charge.unitCharge * charge.units);
      }

      case 'feeScheduleCharges': {
        const charge = this.model[rowIndex];
        const didUnitsChange =
          this.initialUnits[rowIndex] !== this.model[rowIndex].units;

        const feeScheduleModel = this.__getFeeScheduleModel(
          charge,
          didUnitsChange,
          this.didBillingInfoChange,
          this.feeScheduleId,
        );

        const model = {
          ...feeScheduleModel,
          units: charge.units,
        };

        return html`
          <neb-cell-fee-schedule
            class="fee-schedule"
            .model="${model}"
          ></neb-cell-fee-schedule>
        `;
      }

      case 'diagnosisPointers':
        return this.diagnoses.length
          ? html`
              <neb-select
                class="diagnosis-pointers"
                maxSelection="4"
                label=" "
                helper=" "
                .name="${name}"
                .error="${error}"
                .value="${value}"
                .items="${this.diagnoses}"
                .onChange="${this.handlers.change}"
                forceAlignMenu="right"
                placeholder="Select"
                multiSelect
                showFullText
                wrapText
                itemHeight="${getDiagnosisItemHeight(this.diagnoses)}"
                itemMinWidth="${ITEM_MIN_WIDTH}"
              ></neb-select>
            `
          : html` <span class="text-no-diagnoses">No Encounter Dx</span> `;

      default:
        return value;
    }
  }
}

customElements.define(
  'neb-table-check-out-encounter-charges',
  NebTableCheckOutEncounterCharges,
);
