import { css, html, LitElement } from 'lit';

import {
  CSS_COLOR_GREY_1,
  CSS_COLOR_GREY_2,
  CSS_COLOR_GREY_9,
  CSS_COLOR_GREY_10,
} from '../../../../../packages/neb-styles/neb-variables';

import '../../../../components/controls/inputs/neb-checkbox';
import '../../../../components/misc/neb-icon';

export const ELEMENTS = {
  closeButton: { id: 'close-button' },
  container: { id: 'container' },
  checkbox: { id: 'checkbox' },
  title: { id: 'title' },
};

class NebChartNotesStructuredDocumentsViewHeader extends LitElement {
  static get properties() {
    return {
      hideCheckbox: Boolean,
      hideUnanswered: Boolean,
    };
  }

  static get styles() {
    return css`
      :host {
        display: block;
      }

      .container {
        border-top: 1px solid ${CSS_COLOR_GREY_9};
        border-left: 1px solid ${CSS_COLOR_GREY_9};
        border-right: 1px solid ${CSS_COLOR_GREY_9};
        border-bottom: 1px solid ${CSS_COLOR_GREY_2};
        border-radius: 2px 2px 0 0;
        background-color: ${CSS_COLOR_GREY_10};
        margin: 0;
        padding: 7px 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-shrink: 0; /* Needed for safari */
      }

      .right-side {
        display: flex;
        align-items: center;
      }

      .icon-close {
        cursor: pointer;
        display: flex;
        width: 24px;
        height: 24px;
        fill: ${CSS_COLOR_GREY_1};
      }

      .title-text {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 90%;
        display: inline-block;
      }

      .checkbox {
        width: 155px;
        margin-right: 12px;
        margin-left: 12px;
      }
    `;
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.hideCheckbox = false;
    this.hideUnanswered = false;

    this.onChange = () => {};

    this.onClose = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      updateHideUnanswered: e => this.onChange(e),
      closeDocument: () => this.onClose(),
    };
  }

  __renderCheckbox() {
    return !this.hideCheckbox
      ? html`
          <neb-checkbox
            id="${ELEMENTS.checkbox.id}"
            class="checkbox"
            label="Hide Unanswered"
            .checked="${this.hideUnanswered}"
            .onChange="${this.__handlers.updateHideUnanswered}"
          ></neb-checkbox>
        `
      : '';
  }

  render() {
    return html`
      <div id="${ELEMENTS.container.id}" class="container">
        <span id="${ELEMENTS.title.id}" class="title-text">
          Completed Questionnaire
        </span>

        <div class="right-side">
          ${this.__renderCheckbox()}

          <neb-icon
            id="${ELEMENTS.closeButton.id}"
            class="icon-close"
            icon="neb:close"
            @click="${this.__handlers.closeDocument}"
          ></neb-icon>
        </div>
      </div>
    `;
  }
}

customElements.define(
  'neb-chart-notes-structured-documents-view-header',
  NebChartNotesStructuredDocumentsViewHeader,
);
