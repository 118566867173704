import '../neb-text';
import '../../../../../src/components/misc/neb-icon';
import '../../../../../src/components/controls/inputs/neb-checkbox';
import '../neb-button-actions';

import { css, html } from 'lit';

import {
  formatBilled,
  formatHold,
} from '../../../../../src/formatters/line-items';
import { navigateToRow } from '../../../../../src/utils/navigation-util';
import { fetchOne } from '../../../../neb-api-client/src/ledger-statement-api-client';
import {
  CSS_SPACING,
  CSS_COLOR_GREY_2,
  CSS_FONT_WEIGHT_BOLD,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_GREY_8,
} from '../../../../neb-styles/neb-variables';
import { BILLING_NOTE_TYPES } from '../../../../neb-utils/constants';
import { parseDate } from '../../../../neb-utils/date-util';
import { centsToCurrency } from '../../../../neb-utils/formatters';
import { formatEncounterNumber } from '../../../../neb-utils/neb-encounters-util';
import { LINE_ITEM_TYPE } from '../../../../neb-utils/neb-ledger-util';
import { printPdf } from '../../../../neb-utils/neb-pdf-print-util';
import { findTableWidth } from '../../../../neb-utils/table';
import { openOverlay, OVERLAY_KEYS } from '../../utils/overlay-constants';
import {
  formatAdjustmentAmount,
  formatCode,
} from '../patients/ledger/charges/neb-ledger-charges-util';

import NebTable, { ELEMENTS as ELEMENTS_BASE } from './neb-table';

const getBorderedColumns = [
  'claimStatus',
  'procMod',
  'billedWithTaxAmount',
  'patientPayments',
  'patientName',
];

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  checkbox: { selector: 'neb-checkbox' },
  patientLinks: { selector: '[id^=patient-]' },
  invoiceLinks: { selector: '[id^=invoice-]' },
  claimLinks: { selector: '[id^=claim-]' },
  payerPayments: { selector: '[id^=payerPayments-]' },
  patientPayments: { selector: '[id^=patientPayments-]' },
  encounterLinks: { selector: '[id^=encounter-]' },
  chargeNumbers: { selector: '[id^=charge-number-]' },
  onStatement: { selector: '[id^=on-statement-]' },
  textBilled: { id: 'text-billed' },
  textHold: { id: 'text-hold' },
  bulkActionMenu: { id: 'bulk-action-menu' },
  headerCheckbox: { id: 'header-checkbox' },
  noteIcon: {
    selector: '[id^=icon-note-]',
  },
  associatedERAsAndEOBs: { selector: '[id^=era-eob-]' },
  recoupedIndicators: { selector: '[id^=recouped-indicator-]' },
};

const CLAIMS_ICON = {
  paper: 'neb:paperClaims',
  electronic: 'neb:electronicClaims',
  refresh: 'neb:refresh',
};

class NebTableSelectedLineItems extends NebTable {
  static get properties() {
    return {
      selectedIds: Array,
      disabled: {
        type: Boolean,
        reflect: true,
      },
      isEraEob: {
        type: Boolean,
        reflect: true,
      },
      renderPatientColumn: Boolean,
      paymentLinks: Boolean,
      __hasSignedEncounter: {
        type: Boolean,
        reflect: true,
        attribute: 'has-signed-encounter',
      },
      __initialLength: Number,
      toggleHeaderCheckbox: Boolean,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          width: 100%;
          min-height: 240px;
        }

        .cell-data[key='claimStatus'],
        .cell-data[key='procMod'],
        .cell-data[key='billedWithTaxAmount'],
        .cell-data[key='associatedERAsAndEOBs'],
        .cell-data[key='patientPayments'] {
          border-right: 1px solid ${CSS_COLOR_GREY_2};
        }

        .cell-data[key='payerPayments'],
        .cell-data[key='patientPayments'] {
          flex-wrap: wrap;
        }

        .cell-data[key='statement'] {
          justify-content: center;
        }

        .ellipsis {
          padding-top: 1px;
          min-width: 0;
          width: 28px;
          font-weight: normal;
        }

        .row[preview] {
          padding: 0 ${CSS_SPACING};
        }

        .patient-text {
          margin-right: 6px;
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          color: ${CSS_COLOR_HIGHLIGHT};
          text-decoration: underline;
          cursor: pointer;
          white-space: nowrap;
        }

        .button {
          display: flex;
          align-items: center;
          cursor: pointer;
        }

        .icon {
          width: 15px;
          height: 15px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .icon-note {
          width: 16px;
          height: 16px;
          margin-left: 2px;
          margin-right: -10px;
          margin-top: 3px;
          fill: ${CSS_COLOR_HIGHLIGHT};
          cursor: pointer;
        }

        :host([has-signed-encounter]) [id*='encounterNumber'].cell {
          flex: 1 0 110px !important;
          padding-right: 10px;
        }

        .cell:not(:last-child) {
          margin-right: 12px;
        }

        .ellipsis {
          margin-left: -4px;
          padding-top: 1px;
          min-width: 0;
          width: 28px;
          font-weight: normal;
        }

        @keyframes fade {
          from {
            background: rgb(192, 230, 255);
          }
          to {
            background: transparent;
          }
        }

        .item-highlight {
          animation: fade 3s;
        }

        .payment-link {
          display: flex;
          align-items: center;
        }
        .recouped-icon {
          width: 16px;
          height: 16px;
          stroke-width: 1;
          fill: ${CSS_COLOR_HIGHLIGHT};
          margin-left: 2px;
        }
        .recouped-icon[disabled] {
          fill: ${CSS_COLOR_GREY_8};
        }
      `,
    ];
  }

  getConfig() {
    return [
      {
        preserveBasis: true,
        key: 'checked',
        label: '',
        flex: css`0 0 28px`,
      },
      {
        key: 'chargeNumber',
        label: 'Charge',
        flex: css`1 0 80px`,
      },
      {
        key: 'billed',
        label: 'Billed',
        flex: css`0 0 20px`,
        mobile: true,
      },
      {
        key: 'hold',
        label: 'Hold',
        flex: css`0 0 20px`,
        mobile: true,
      },
      {
        key: 'statement',
        label: 'On Stmt',
        flex: css`0 0 20px`,
      },
      ...(this.renderPatientColumn
        ? [
            {
              key: 'patientMRN',
              label: 'MRN',
              flex: css`1 0 80px`,
            },
            {
              key: 'patientName',
              sortable: this.isEraEob,
              label: 'Patient',
              flex: css`1 0 80px`,
            },
          ]
        : []),

      {
        key: 'encounterNumber',
        label: 'Encounter',
        sortable: this.isEraEob,
        flex: css`1 0 100px`,
      },
      ...(this.isEraEob
        ? [
            {
              key: 'invoiceNumber',
              sortable: this.isEraEob,
              label: 'Invoice',
              flex: css`2 0 0px`,
            },
            {
              key: 'claimNumber',
              sortable: this.isEraEob,
              label: 'Claim',
              flex: css`1 0 80px`,
            },
            {
              key: 'claimStatus',
              sortable: this.isEraEob,
              label: 'Claim Status',
              flex: css`1 0 80px`,
            },
          ]
        : []),
      {
        key: 'provider',
        label: 'Provider',
        sortable: this.isEraEob,
        flex: css`1 0 90px`,
      },
      {
        key: 'location',
        label: 'Location',
        sortable: this.isEraEob,
        flex: css`1 0 80px`,
      },
      {
        key: 'primaryDiagnosis',
        label: 'Dx',
        flex: css`1 0 40px`,
      },
      {
        key: 'dateOfService',
        label: 'DOS',
        sortable: this.isEraEob,
        flex: css`1 0 45px`,
        formatter: v => parseDate(v).format('L'),
      },
      {
        key: 'procMod',
        label: 'Proc + Mod',
        flex: css`1 0 85px`,
        formatter: (_, row) => formatCode(row.code, row.modifiers),
      },
      {
        key: 'units',
        label: 'Units',
        flex: css`1 0 40px`,
      },
      {
        key: 'billedWithTaxAmount',
        label: 'Billed',
        flex: css`1 0 60px`,
        formatter: (_, row) =>
          centsToCurrency(row.billedAmount + row.taxAmount),
      },
      {
        key: 'payerOwed',
        label: 'Pyr. Owed',
        flex: css`1 0 60px`,
        formatter: (_, row) =>
          centsToCurrency(row.primaryOwed + row.secondaryOwed),
      },
      {
        key: 'payerPaid',
        label: 'Pyr. Paid',
        flex: css`1 0 65px`,
        formatter: (_, row) =>
          centsToCurrency(row.primaryPaid + row.secondaryPaid),
      },
      {
        key: 'payerPayments',
        label: 'Pymt ID(s)',
        flex: css`1 0 96px`,
      },
      ...(!this.isEraEob
        ? [
            {
              key: 'associatedERAsAndEOBs',
              label: 'ERA/EOB',
              flex: css`0 0 95px`,
              preserveBasis: true,
            },
          ]
        : []),
      {
        key: 'patientOwed',
        label: 'Pt. Owed',
        flex: css`1 0 60px`,
        formatter: v => centsToCurrency(v),
      },
      {
        key: 'patientPaid',
        label: 'Pt. Paid',
        flex: css`1 0 60px`,
        formatter: v => centsToCurrency(v),
      },
      {
        key: 'patientPayments',
        label: 'Pymt ID(s)',
        flex: css`1 0 96px`,
      },
      {
        key: 'adjustment',
        label: 'Adj.',
        flex: css`1 0 60px`,
        formatter: v => formatAdjustmentAmount(v),
      },
      {
        key: 'balance',
        label: 'Balance',
        flex: css`1 0 60px`,
        formatter: v => centsToCurrency(v),
      },
    ];
  }

  initState() {
    super.initState();

    this.disabled = false;
    this.isEraEob = false;
    this.selectedIds = [];
    this.renderPatientColumn = false;
    this.paymentLinks = true;

    this.__hasSignedEncounter = false;
    this.__initialLength = null;

    this.toggleHeaderCheckbox = true;

    this.onSelectEncounter = () => {};

    this.onSelectPatient = () => {};

    this.onSelectClaim = () => {};

    this.onSelectInvoice = () => {};

    this.onMinWidthUpdated = () => {};

    this.onChangeClaimStatus = () => {};

    this.onOpenClaimBatchesOverlay = () => {};

    this.onSaveAndSubmit = () => {};

    this.onBulkSelect = () => {};

    this.onSelectAll = () => {};

    this.onSelectChargeNumber = () => {};

    this.onClickPaymentId = () => {};

    this.onUpdateBillingNotes = () => {};

    this.onClickERAsEOBs = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,

      selectAllBoxes: () => {
        this.onSelectAll();
      },
      selectEncounter: ({ name: rowIndex }) => {
        const { encounterId, appointmentTypeId, patientId } =
          this.model[rowIndex];
        this.onSelectEncounter({ encounterId, appointmentTypeId, patientId });
      },
      selectClaim: ({ name: rowIndex }) => {
        const { claimId } = this.model[rowIndex];
        this.onSelectClaim({ claimId });
      },
      selectInvoice: async ({ name: rowIndex }) => {
        const { invoiceId, patientId } = this.model[rowIndex];
        await this.onSelectInvoice({ invoiceId, patientId });
        this.__goToLastInvoiceVisited(rowIndex);
      },
      selectPatient: event => {
        const rowIndex = event.currentTarget.getAttribute('index');
        this.onSelectPatient(this.model[rowIndex].patientId);
      },
      changeClaimStatus: () => {
        const claims = [];
        this.model.forEach(item => {
          if (item.checked) {
            const claim = {
              id: item.claimId,
              status: item.claimStatus,
              isElectronic: item.claimIsElectronic,
              claimNumber: item.claimNumber,
              claimStatuses: [
                {
                  effectiveDate: item.claimStatusEffectiveDate,
                  status: item.claimStatus,
                },
              ],
            };
            claims.push(claim);
          }
        });

        this.onChangeClaimStatus(claims);
      },
      getBulkActions: () => [
        {
          id: 'selectAll',
          label: 'Select All',
          onSelect: () => this.onBulkSelect(true),
        },
        {
          id: 'deselectAll',
          label: 'Deselect All',
          onSelect: () => this.onBulkSelect(false),
        },
        {
          id: 'changeStatus',
          label: 'Change Status',
          icon: CLAIMS_ICON.paper,
          onSelect: this.handlers.changeClaimStatus,
        },
        {
          id: 'claimBatchesOverlay',
          label: 'Claim Batches',
          icon: 'neb:batch',
          onSelect: this.onOpenClaimBatchesOverlay,
        },
        {
          id: 'submit',
          label: 'Generate and Submit',
          icon: 'neb:generateSubmit',
          onSelect: this.onSaveAndSubmit,
        },
      ],
      clickChargeNumber: async ({ name = '' }) => {
        const [id, rowIndex] = name.split('.');
        const { patientId } = this.model[rowIndex];

        await this.onSelectChargeNumber({ patientId, id });
      },
      clickOnStatement: async ({ name: rowIndex }) => {
        const { statementId } = this.model[rowIndex];

        if (statementId) {
          await printPdf(fetchOne(statementId));
        }
      },
      clickPaymentId: ({ name: paymentId }) => {
        const payment = this.model.reduce(
          (memo, { payerPayments, patientPayments, recoups }) =>
            payerPayments.get(paymentId) ||
            patientPayments.get(paymentId) ||
            this.__getRecoupedPayment(paymentId, recoups) ||
            memo,
          '',
        );
        this.onClickPaymentId(payment);
      },
      openNotesOverlay: async rowIndex => {
        const {
          dateOfService,
          chargeNumber,
          id,
          code,
          patientId,
          description,
        } = this.model[rowIndex];

        const result = await openOverlay(OVERLAY_KEYS.BILLING_NOTE, {
          parentType: BILLING_NOTE_TYPES.CHARGE,
          parentId: id,
          parentData: {
            dateOfService: parseDate(dateOfService).format('MM/DD/YYYY'),
            chargeNumber,
            code,
            description,
          },
          patientId,
        });
        if (result) this.onUpdateBillingNotes();
      },
      clickERAsEOBs: ({ name }) => {
        const rowIndex = name.split('.')[1];
        const { associatedERAsAndEOBs, id } = this.model[rowIndex];

        return this.onClickERAsEOBs({
          associatedERAsAndEOBs,
          lineItemId: id,
          refetchData: true,
        });
      },
    };
  }

  update(changedProps) {
    if (
      (changedProps.has('model') &&
        this.model.length !== this.__initialLength) ||
      changedProps.has('isEraEob') ||
      changedProps.has('renderPatientColumn')
    ) {
      const config = this.getConfig();
      const result = findTableWidth(config, this.model, {
        borderedColumns: getBorderedColumns,
      });

      this.config = result.config;
      this.style.setProperty('min-width', `${result.width}px`);
      this.onMinWidthUpdated(result.width);
      this.__initialLength = this.model.length;
    }

    this.__hasSignedEncounter = this.model
      ? this.model.some(({ signed }) => signed)
      : false;

    super.update(changedProps);
  }

  __getRecoupedPayment(paymentId, recoups) {
    const recoup = recoups.find(
      ({ fromPayment }) => fromPayment.id === paymentId,
    );
    return recoup?.fromPayment;
  }

  __goToLastInvoiceVisited(rowIndex) {
    const row = this.shadowRoot.getElementById(`row-${rowIndex}`);
    navigateToRow(row);
  }

  __renderCheckboxOrEllipsis() {
    return this.isEraEob
      ? html`
          <neb-button-actions
            id="${ELEMENTS.bulkActionMenu.id}"
            class="ellipsis"
            align="left"
            .forceDownMenu="${true}"
            .maxVisibleItems="${this.maxVisibleItems}"
            .onClick="${this.handlers.getBulkActions}"
            ?disabled="${!this.model.length}"
          ></neb-button-actions>
        `
      : html`
          <neb-checkbox
            id="${ELEMENTS.headerCheckbox.id}"
            class="checkbox"
            .onChange="${this.handlers.selectAllBoxes}"
            ?checked="${this.toggleHeaderCheckbox}"
            ?disabled="${this.disabled}"
          ></neb-checkbox>
        `;
  }

  renderHeaderCell(headerCell) {
    return headerCell.key === 'checked'
      ? this.__renderCheckboxOrEllipsis()
      : super.renderHeaderCell(headerCell);
  }

  __renderNotesIcon(rowIndex) {
    const { chargeHasNotes } = this.model[rowIndex];
    return html`
      <neb-icon
        id="icon-note-${rowIndex}"
        class="icon-note"
        icon="${chargeHasNotes ? 'neb:editNote' : 'neb:addNote'}"
        @click="${() => this.handlers.openNotesOverlay(rowIndex)}"
      ></neb-icon>
    `;
  }

  __renderLinkCell({ id, name, rowIndex }) {
    const { chargeNumber } = this.model[rowIndex];
    return html`
      <neb-text
        id="${id}"
        bold
        link
        .name="${name}"
        .onClick="${this.handlers.clickChargeNumber}"
        >${chargeNumber}
      </neb-text>
      ${this.__renderNotesIcon(rowIndex)}
    `;
  }

  __renderOnStatement({ rowIndex }) {
    if (!this.model[rowIndex].billedPatient) return '';

    return html`
      <neb-text
        id="on-statement-${rowIndex}"
        .name="${rowIndex}"
        .onClick="${this.handlers.clickOnStatement}"
        ?link="${this.model[rowIndex].statementId}"
        >✓
      </neb-text>
    `;
  }

  __renderChargeNumber({ rowIndex }) {
    const { chargeNumber, id: chargeId, type } = this.model[rowIndex];

    const id = `charge-number-${rowIndex}`;
    const name = `${chargeId}.${rowIndex}`;

    return type === LINE_ITEM_TYPE.ENCOUNTER_CHARGE
      ? this.__renderLinkCell({ id, name, rowIndex })
      : html`
          <span id="${id}" name="${name}">${chargeNumber}</span>
          ${this.__renderNotesIcon(rowIndex)}
        `;
  }

  __renderPaymentNumber({
    id: paymentId,
    paymentMethod,
    displayCode,
    key,
    index,
    recouped,
  }) {
    return this.paymentLinks
      ? html`
          <div class="payment-link">
            <neb-text
              id="${key}-${index}"
              link
              .name="${paymentId}"
              trailingIcon="${this.__getPaymentIcon(paymentMethod)}"
              .onClick="${this.handlers.clickPaymentId}"
              >${displayCode}
            </neb-text>
            ${recouped ? this.__renderRecoupedIndicator({ paymentId }) : ''}
          </div>
        `
      : html`
          <div class="payment-link">
            <neb-text id="${key}-${index}" .name="${paymentId}"
              >${displayCode}
            </neb-text>
            ${recouped
              ? this.__renderRecoupedIndicator({ paymentId, disabled: true })
              : ''}
          </div>
        `;
  }

  __displayRecoups(key, index) {
    return key === 'payerPayments' && this.model[index]?.recoups?.length;
  }

  __getPaymentIcon(paymentMethod) {
    if (paymentMethod === 'ERA') return 'neb:receipt';
    return '';
  }

  __getRecoups({ recoups }) {
    return recoups.map(({ fromPayment }) => ({
      id: fromPayment.id,
      displayCode: fromPayment.paymentNumber,
      recouped: true,
    }));
  }

  __renderRecoupedIndicator({ allocationId, disabled = false }) {
    return html`
      <neb-icon
        id="recouped-indicator-${allocationId}"
        class="recouped-icon"
        icon="neb:swapVerticalCircle"
        ?disabled="${disabled}"
        title="Reversed"
      ></neb-icon>
    `;
  }

  __renderPayments(key, value, index) {
    const allPayments = [
      ...Array.from(value.entries()).map(([_, payment]) => payment),
      ...(this.__displayRecoups(key, index)
        ? this.__getRecoups(this.model[index])
        : []),
    ];
    return allPayments
      .sort((a, b) => a.displayCode - b.displayCode)
      .map((payment, i) => {
        const leadingComma = i === 0 ? '' : ', ';
        return html`
          ${leadingComma}&nbsp${this.__renderPaymentNumber({
            ...payment,
            key,
            index,
          })}
        `;
      });
  }

  __renderERAsAndEOBsCell(data, rowIndex) {
    return html`
      <neb-text
        id="era-eob-${rowIndex}"
        bold
        link
        .name="era-eob.${rowIndex}"
        .onClick="${this.handlers.clickERAsEOBs}"
        >${data && data.length ? '✓' : ''}</neb-text
      >
    `;
  }

  // eslint-disable-next-line complexity
  renderDataCell(value, columnConfig, rowIndex, name, error) {
    switch (columnConfig.key) {
      case 'checked':
        return html`
          <neb-checkbox
            class="align-offset"
            .name="${name}"
            id="${ELEMENTS.checkbox.id}"
            .onChange="${this.handlers.change}"
            ?disabled="${this.disabled}"
            ?checked="${value}"
          ></neb-checkbox>
        `;

      case 'patientName':
        return this.__renderPatientNameCell(value, rowIndex);

      case 'billed': {
        const formattedString = formatBilled(this.model[rowIndex]);

        return html`
          <neb-text id="${ELEMENTS.textBilled.id}" class="text-billed-patient"
            >${formattedString}</neb-text
          >
        `;
      }

      case 'hold': {
        const formattedString = formatHold(this.model[rowIndex]);

        return html`
          <neb-text id="${ELEMENTS.textHold.id}" class="text-hold-claim"
            >${formattedString}</neb-text
          >
        `;
      }

      case 'encounterNumber':
        return html`
          <neb-text
            id="encounter-${rowIndex}"
            bold
            link
            .name="${rowIndex}"
            .onClick="${this.handlers.selectEncounter}"
            >${formatEncounterNumber(this.model[rowIndex])}</neb-text
          >
        `;

      case 'invoiceNumber':
        return this.model[rowIndex].invoiceNumber
          ? html`
              <neb-text
                id="invoice-link-${rowIndex}"
                bold
                link
                .name="${rowIndex}"
                .onClick="${this.handlers.selectInvoice}"
                >${this.model[rowIndex].invoiceNumber}</neb-text
              >
            `
          : super.renderDataCell(
              'Not Invoiced',
              columnConfig,
              rowIndex,
              name,
              error,
            );

      case 'claimNumber':
        return html`
          <neb-text
            id="claim-link-${rowIndex}"
            bold
            link
            .name="${rowIndex}"
            .onClick="${this.handlers.selectClaim}"
            >${this.model[rowIndex].claimNumber}</neb-text
          >
        `;

      case 'statement':
        return this.__renderOnStatement({ rowIndex });

      case 'chargeNumber':
        return this.__renderChargeNumber({ rowIndex });

      case 'patientPayments':
      case 'payerPayments':
        return this.__renderPayments(columnConfig.key, value, rowIndex);

      case 'associatedERAsAndEOBs':
        return this.__renderERAsAndEOBsCell(value, rowIndex);

      default:
        return super.renderDataCell(value, columnConfig, rowIndex, name, error);
    }
  }

  __renderPatientNameCell(value, rowIndex) {
    return html`
      <div
        id="patient-link-${rowIndex}"
        class="button"
        index="${rowIndex}"
        @click="${this.handlers.selectPatient}"
      >
        <span class="patient-text">${value}</span>
        <neb-icon class="icon" icon="neb:open"></neb-icon>
      </div>
    `;
  }
}
customElements.define(
  'neb-table-selected-line-items',
  NebTableSelectedLineItems,
);
