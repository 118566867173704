import '../../neb-popup-header';

import { openPopup } from '@neb/popup';
import equal from 'fast-deep-equal';
import { html, css } from 'lit';

import { CSS_SPACING, layoutStyles } from '../../../../../../src/styles';
import { getPatientAppointments } from '../../../../../neb-api-client/src/patient-api-client';
import * as personApiClient from '../../../../../neb-api-client/src/person-api-client';
import { openDirtyPopup } from '../../../../../neb-popup';
import { parseDate } from '../../../../../neb-utils/date-util';
import { DEFAULT_LEVEL } from '../../../../../neb-utils/patientInsurance';
import { personToPolicyHolder } from '../../../../../neb-utils/policyHolder';
import { createInsuranceFromRTE } from '../../../../../neb-utils/real-time-eligibility';
import { INSURANCE_LEVELS } from '../../../utils/insurance-util';
import { openOverlay, OVERLAY_KEYS } from '../../../utils/overlay-constants';
import { FormAddInsuranceRTE } from '../../forms/neb-form-add-insurance-rte';
import {
  findMatchingMemberAndGroup,
  findMatchingMemberAndPayerPlan,
  POPUP_CONFIRM_PAYER,
  POPUP_CONFIRM_PLAN_ID,
  POPUP_MSG_PAYER,
  POPUP_MSG_PLAN_ID,
} from '../../patients/insurance/shared/neb-patient-insurance-validation-util';
import Overlay from '../neb-overlay';

export const ELEMENTS = {
  header: { id: 'header' },
  form: { id: 'form' },
};

class OverlayAddInsuranceRTE extends Overlay {
  static get properties() {
    return {
      __formModel: Object,
      __savingError: Object,

      patientInsurances: Array,
      forCheckIn: Boolean,
    };
  }

  static get styles() {
    return [
      super.styles,
      layoutStyles,
      css`
        .content {
          display: flex;
          width: 875px;
          flex-flow: column nowrap;
        }

        .header {
          padding: ${CSS_SPACING};
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.patientInsurances = [];
    this.forCheckIn = false;

    this.__formModel = FormAddInsuranceRTE.createModel();
    this.__savingError = null;
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      // eslint-disable-next-line complexity
      save: async (model, payerPlan) => {
        const result = await this.__validate(model);

        if (result) {
          let res;

          if (model.defaultLevel === INSURANCE_LEVELS.PRIMARY) {
            const to = parseDate();
            const from = parseDate().subtract(30, 'days');

            const appointments =
              await this.__getPatientInsuranceBillTypeAppointments(
                from,
                to,
                model.planInfo.memberIdentifier,
              );

            if (appointments && appointments.length) {
              res = await openOverlay(
                OVERLAY_KEYS.PATIENT_INSURANCE_BILL_TYPE_UPDATE,
                {
                  patientId: model.patientId,
                  appointments,
                  payerPlan: payerPlan.label,
                  insurance: payerPlan.data.payerName,
                  memberIdentifier: model.planInfo.memberIdentifier,
                  dateRange: {
                    from,
                    to,
                  },
                  insuranceDates: {
                    from: null,
                    to: null,
                  },
                },
              );
            }
          }

          const insurance = await createInsuranceFromRTE({
            ...model,
            providerId: this.model.providerId || model.providerId || null,
            appointmentIds: res?.appointmentIds || [],
            forCheckIn: this.forCheckIn,
          });
          this.isDirty = false;

          if (
            this.model.context.cancel === 'cancel' &&
            this.model.context.hasAddOnCTVerify
          ) {
            this.onClose();
          }
          this.handlers.dismiss(insurance);
        }
      },
      cancel: async () => {
        if (this.isDirty) {
          if (await openDirtyPopup()) this.isDirty = false;
        }

        if (!this.isDirty) {
          this.onClose();
          this.handlers.dismiss();
        }
      },
      cancelAll: () => {
        this.handlers.dismissAll();
      },
    };
  }

  async connectedCallback() {
    super.connectedCallback();

    const patientId =
      this.model?.patientId ||
      this.model?.context?.patientDemographic?.patientId;

    const person = await this.__getPerson(patientId);
    person.dateOfBirth = person.dateOfBirth
      ? person.dateOfBirth.replace(/-/g, '/')
      : '';

    const policyHolder = personToPolicyHolder(person, {
      policyHolderId: person.patientId,
      address: person.address,
    });

    const defaultLevel = this.__getDefaultLevel();

    this.__formModel = {
      ...this.__formModel,
      patientId,
      policyHolder,
      defaultLevel,
    };
  }

  dismissWithBlocker() {
    this.dismiss(null);
  }

  async __getPatientInsuranceBillTypeAppointments(from, to, memberIdentifier) {
    const appointments = await getPatientAppointments(this.model.patientId, {
      from: from.startOf('day').utc(),
      to: to.endOf('day').utc(),
      memberIdentifier,
    });

    return appointments;
  }

  __getDefaultLevel() {
    const activeInsuranceDefaultLevels = this.patientInsurances.reduce(
      (acc, ins) => {
        if (!ins.active) return acc;

        acc[ins.defaultLevel] = true;

        return acc;
      },
      {},
    );

    if (!activeInsuranceDefaultLevels[DEFAULT_LEVEL.Primary]) {
      return DEFAULT_LEVEL.Primary;
    }

    if (!activeInsuranceDefaultLevels[DEFAULT_LEVEL.Secondary]) {
      return DEFAULT_LEVEL.Secondary;
    }

    if (!activeInsuranceDefaultLevels[DEFAULT_LEVEL.Tertiary]) {
      return DEFAULT_LEVEL.Tertiary;
    }

    return DEFAULT_LEVEL.Other;
  }

  async __validate(model) {
    let proceed = true;

    const payerPlanModel = {
      ...model,
      payerPlan: { id: model.payerPlanId },
    };

    if (
      findMatchingMemberAndPayerPlan(this.patientInsurances, payerPlanModel)
    ) {
      proceed = await openPopup(POPUP_CONFIRM_PAYER, POPUP_MSG_PAYER);
    } else if (
      findMatchingMemberAndGroup(this.patientInsurances, payerPlanModel)
    ) {
      proceed = await openPopup(POPUP_CONFIRM_PLAN_ID, POPUP_MSG_PLAN_ID);
    }

    if (!proceed) this.__savingError = { error: 'canceled' };

    return proceed;
  }

  __getDemographicInfo(demographic) {
    if (!demographic) return {};

    return {
      lastName: demographic.lastName,
      firstName: demographic.firstName,
      middleName: demographic.middleName,
      suffix: demographic.suffix,
      sex: demographic.sex,
      dateOfBirth: demographic.dateOfBirth
        ? demographic.dateOfBirth.replace(/-/g, '/')
        : '',
    };
  }

  __shouldUpdatePerson(person) {
    if (
      !person?.patientId ||
      !this.model?.context?.patientDemographic?.lastName
    ) {
      return false;
    }

    const demographicPerson = this.__getDemographicInfo(person);
    const demographicPatient = this.__getDemographicInfo(
      this.model.context.patientDemographic,
    );

    return !equal(demographicPerson, demographicPatient);
  }

  async __getPerson(patientId) {
    const person = await personApiClient.getPersonByPatientId(patientId, {
      relations: true,
    });

    if (this.__shouldUpdatePerson(person)) {
      const personDemographic = {
        ...person,
        ...this.__getDemographicInfo(this.model.context.patientDemographic),
      };

      const model = {
        ...personApiClient.mapToModel(personDemographic),
        id: person.id,
      };

      await personApiClient.update(model);

      return personDemographic;
    }

    return person;
  }

  update(changedProps) {
    if (changedProps.has('model')) {
      const { patientInsurances, forCheckIn } = this.model;
      this.patientInsurances = patientInsurances || [];
      this.forCheckIn = forCheckIn || false;
    }

    super.update(changedProps);
  }

  renderContent() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        title="Add Insurance Using RTE"
        .onBack="${this.handlers.dismiss}"
        .onCancel="${this.handlers[this.model.context.cancel]}"
        ?showBackButton="${this.model.context.showBackButton}"
        showCancelButton
      ></neb-popup-header>

      <neb-form-add-insurance-rte
        id="${ELEMENTS.form.id}"
        .model="${this.__formModel}"
        .providers="${this.model.context.providers}"
        .onChangeDirty="${this.handlers.dirty}"
        .onCancel="${this.handlers.dismiss}"
        .onSave="${this.handlers.save}"
        .savingError="${this.__savingError}"
      ></neb-form-add-insurance-rte>
    `;
  }
}

window.customElements.define(
  'neb-overlay-add-insurance-rte',
  OverlayAddInsuranceRTE,
);
