import '../neb-pagination';
import '../neb-popup-header';
import '../neb-action-bar';
import '../inputs/neb-select';
import '../inputs/neb-textfield';
import '../tables/neb-table-diagnoses-select';
import '../neb-loading-overlay';

import { html, css } from 'lit';

import { fetchMany } from '../../../../../src/api-clients/clinical-codes';
import { listIcd10Years } from '../../../../../src/utils/clinical-codes';
import {
  CSS_COLOR_ERROR,
  CSS_SPACING,
  OVERLAY_WIDTH_LARGE,
} from '../../../../neb-styles/neb-variables';
import { parseDate } from '../../../../neb-utils/date-util';
import { dateToIcd10Year } from '../../../../neb-utils/icd10-util';
import { FetchService } from '../../../../neb-utils/services/fetch';

import Overlay from './neb-overlay';

export const DEBOUNCE_DELAY = 300;

const NO_RESULTS_MESSAGE = 'No results found.';
const NO_SEARCH_MESSAGE = 'Begin your search.';

export const ELEMENTS = {
  table: { id: 'table' },
  description: { id: 'description' },
  header: { id: 'header' },
  noResultsMessage: { id: 'no-results' },
  searchField: { id: 'search' },
  icd10Dropdown: { id: 'icd10-dropdown' },
  actionBar: { id: 'action-bar' },
  paginationControl: { id: 'pagination-control' },
  selectedCount: { id: 'selected-count' },
  loadingOverlay: { id: 'loading-indicator' },
};

class NebOverlayAddDiagnoses extends Overlay {
  static get properties() {
    return {
      __collectionState: Object,
      __selectedDiagnosis: Array,
      __description: String,
      __appointmentYear: Number,
      __icd10Year: Number,
      __startingNumberOfSelected: Number,
      __loading: Boolean,
      showICD10Dropdown: Boolean,
    };
  }

  initState() {
    super.initState();

    this.model = {
      selectedDiagnosis: [],
      description: '',
    };

    this.__selectedDiagnosis = [];
    this.__startingNumberOfSelected = 0;
    this.__description = 'Search for and select diagnoses to add.';
    this.__collectionState = FetchService.createModel();
    this.__noDataMessage = NO_SEARCH_MESSAGE;
    this.__icd10Year = dateToIcd10Year(parseDate());
    this.__loading = false;
    this.__appointmentYear = null;
    this.__initServices();
  }

  __initServices() {
    const client = async query => {
      this.__loading = true;
      const icd10codes = await fetchMany({
        searchText: query.search,
        icd10year: this.__icd10Year,
        appointmentYear: this.__appointmentYear,
        offset: query.offset,
        limit: query.limit,
      });
      this.__loading = false;
      return icd10codes;
    };

    this.__fetchService = new FetchService(
      {
        onChange: state => {
          this.__collectionState = state;
        },
        onMapItem: item => ({
          diagnosisCode: item.code,
          shortDescription: item.shortDescription,
          ...(this.__appointmentYear
            ? { showICD10Warning: item.showICD10Warning }
            : {}),
        }),
      },
      client,
      {
        pageSize: 10,
        debounceDuration: DEBOUNCE_DELAY,
      },
    );
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      apply: () => this.dismiss(this.__selectedDiagnosis),
      changePage: pageIndex => {
        this.__fetchService.setPageIndex(pageIndex);
        this.__pageIndex = pageIndex;
      },
      clearSearch: () => {
        this.__fetchService.search('', true);
      },
      search: ({ value }) => this.__fetchService.search(value, true),
      addDiagnosis: rowIndex => {
        if (this.__appointmentYear) {
          const { diagnosisCode, shortDescription, showICD10Warning } =
            this.__collectionState.pageItems[rowIndex];

          this.__selectedDiagnosis = [
            ...this.__selectedDiagnosis,
            { diagnosisCode, shortDescription, showICD10Warning },
          ];
        } else {
          const { diagnosisCode, shortDescription } =
            this.__collectionState.pageItems[rowIndex];

          this.__selectedDiagnosis = [
            ...this.__selectedDiagnosis,
            { diagnosisCode, shortDescription },
          ];
        }
      },

      changeIcd10Year: ({ value }) => this.__icd10YearSelected(value),
    };
  }

  __maxSelectionReached() {
    return (
      this.model.totalSelectionLimit &&
      (this.__selectedDiagnosis.length >= this.model.totalSelectionLimit ||
        this.__selectedDiagnosis.length - this.__startingNumberOfSelected >=
          this.model.currentSelectionLimit)
    );
  }

  __icd10YearSelected(year) {
    if (this.__icd10Year !== year) {
      this.__icd10Year = year;
      this.__fetchService.search('', false);
    }
  }

  firstUpdated(changedProps) {
    super.firstUpdated();

    if (changedProps.has('model')) {
      this.__startingNumberOfSelected = this.model.selectedDiagnosis
        ? this.model.selectedDiagnosis.length
        : 0;
    }
  }

  update(changedProps) {
    if (changedProps.has('model')) {
      this.__selectedDiagnosis = [...this.model.selectedDiagnosis];
      if (this.model.description) this.__description = this.model.description;

      if (this.model.appointmentYear) {
        this.__appointmentYear = this.model.appointmentYear;
      }
    }

    super.update(changedProps);
  }

  dismissWithBlocker() {
    this.dismiss(this.__selectedDiagnosis);
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          width: ${OVERLAY_WIDTH_LARGE};
        }

        .main-header {
          display: flex;
          padding: ${CSS_SPACING} ${CSS_SPACING} 25px ${CSS_SPACING};
        }

        .description {
          padding-left: ${CSS_SPACING};
        }

        .search-input-positioning {
          display: flex;
          flex-flow: row nowrap;
        }

        .icd10-dropdown {
          width: 140px;
          padding-top: 16px;
          padding-right: ${CSS_SPACING};
          margin: 0px 0px ${CSS_SPACING} ${CSS_SPACING};
        }

        .search-field-with-icd {
          padding-top: 16px;
          width: 700px;
          margin: 0px ${CSS_SPACING} 0px 0px;
        }

        .search-field {
          margin: 15px ${CSS_SPACING} ${CSS_SPACING};
        }

        .row-container {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          margin: ${CSS_SPACING};
        }

        .pagination {
          display: flex;
          justify-content: flex-end;
          flex-shrink: 0;
        }

        .container-table {
          overflow-y: auto;
        }

        .selection-length {
          margin: ${CSS_SPACING} ${CSS_SPACING} 0;
        }

        .max-selection-length {
          margin: ${CSS_SPACING} ${CSS_SPACING} 0;
          color: ${CSS_COLOR_ERROR};
        }
      `,
    ];
  }

  __getEmptyMessage() {
    return this.__collectionState.searchText.length === 0
      ? NO_SEARCH_MESSAGE
      : NO_RESULTS_MESSAGE;
  }

  __renderPaginationControl() {
    return this.__collectionState.pageItems.length > 1
      ? html`
          <div class="row-container">
            ${this.__renderSelectedCount()}

            <neb-pagination
              id="${ELEMENTS.paginationControl.id}"
              class="pagination"
              .currentPage="${this.__collectionState.pageIndex}"
              .pageCount="${this.__collectionState.pageCount}"
              .onPageChanged="${this.handlers.changePage}"
            ></neb-pagination>
          </div>
        `
      : '';
  }

  __renderSelectedCount() {
    if (this.model.totalSelectionLimit) {
      const bulkSelectText = `${this.__selectedDiagnosis.length}/${
        this.model.totalSelectionLimit
      } items added`;

      const singleSelectText = `${
        this.__selectedDiagnosis.length - this.__startingNumberOfSelected
      }/${this.model.currentSelectionLimit} items added`;

      const value =
        this.model.totalSelectionLimit === this.model.currentSelectionLimit
          ? bulkSelectText
          : singleSelectText;

      return html`
        <neb-text
          id="${ELEMENTS.selectedCount.id}"
          class="${this.__maxSelectionReached()
            ? 'max-selection-length'
            : 'selection-length'}"
          >${value}</neb-text
        >
      `;
    }
    return '';
  }

  __renderSearchField() {
    return html`
      <neb-textfield
        id="${ELEMENTS.searchField.id}"
        class="${this.model.showICD10Dropdown
          ? 'search-field-with-icd'
          : 'search-field'}"
        label="Enter code or description to filter list below."
        leadingIcon="neb:search"
        .trailingIcon="${this.__collectionState.searchText ? 'neb:clear' : ''}"
        .trailingVisible="${!!this.__collectionState.searchText}"
        .value="${this.__collectionState.searchText}"
        .onChange="${this.handlers.search}"
        .onClickIcon="${this.handlers.clearSearch}"
      ></neb-textfield>
    `;
  }

  __renderInputFields() {
    return this.model.showICD10Dropdown
      ? html`
          <div class="search-input-positioning">
            <neb-select
              id="${ELEMENTS.icd10Dropdown.id}"
              label="ICD10 Year"
              class="icd10-dropdown"
              .items="${listIcd10Years()}"
              .value="${this.__icd10Year}"
              .onChange="${this.handlers.changeIcd10Year}"
            ></neb-select>
            ${this.__renderSearchField()}
          </div>
        `
      : html` ${this.__renderSearchField()} `;
  }

  renderContent() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="main-header"
        title="Add Diagnosis"
        showCancelButton
        .onCancel="${this.handlers.apply}"
      ></neb-popup-header>

      <div id="${ELEMENTS.description.id}" class="description">
        ${this.__description}
      </div>

      ${this.__renderInputFields()}

      <div class="container-table">
        <neb-table-diagnoses-select
          id="${ELEMENTS.table.id}"
          .layout="${this.layout}"
          .selectedItems="${this.__selectedDiagnosis}"
          .emptyMessage="${this.__getEmptyMessage()}"
          .model="${this.__collectionState.pageItems}"
          .onAdd="${this.handlers.addDiagnosis}"
          .maxSelectionReached="${this.__maxSelectionReached()}"
        ></neb-table-diagnoses-select>
      </div>

      ${this.__renderPaginationControl()}

      <neb-action-bar
        id="${ELEMENTS.actionBar.id}"
        confirmLabel=""
        cancelLabel="Done"
        .onCancel="${this.handlers.apply}"
      ></neb-action-bar>

      <neb-loading-overlay
        id="${ELEMENTS.loadingOverlay.id}"
        title="Loading"
        showDelay="${500}"
        .show="${this.__loading}"
      ></neb-loading-overlay>
    `;
  }
}

customElements.define('neb-overlay-add-diagnoses', NebOverlayAddDiagnoses);
