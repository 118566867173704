import '../../../../packages/neb-lit-components/src/components/inputs/neb-select';

import { html, css } from 'lit';

import NebTable, {
  ELEMENTS as ELEMENTS_BASE,
} from '../../../../packages/neb-lit-components/src/components/tables/neb-table';
import {
  getDiagnosisItemHeight,
  ITEM_MIN_WIDTH,
} from '../../../utils/diagnoses';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  textNoDiagnoses: {
    selector: 'span.no-diagnoses-text',
    text: 'No Code Bundle Dx',
  },
  diagnosisDropdown: { selector: '.diagnosis-dropdown', tag: 'neb-select' },
};

class NebTableCodeBundleCharges extends NebTable {
  static get properties() {
    return {
      diagnoses: Array,
      inactivePointers: Boolean,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .cell-data:last-of-type {
          padding: 0;
        }
        .no-diagnoses-text {
          line-height: 72px;
        }
      `,
    ];
  }

  initState() {
    super.initState();
    this.model = [];
    this.diagnoses = [];
    this.inactivePointers = false;
  }

  renderDataCell(value, columnConfig, rowIndex, name, error) {
    const prependedName = `charges.${name}`;

    switch (columnConfig.key) {
      case 'procedure':
        return html`
          <div class="procedure" name="${prependedName}">${value}</div>
        `;

      case 'description':
        return html`
          <div class="description" name="${prependedName}">${value}</div>
        `;

      case 'diagnosisPointers':
        return this.diagnoses.length > 0
          ? html`
              <neb-select
                class="diagnosis-dropdown"
                maxSelection="4"
                label=" "
                helper=" "
                .name="${prependedName}"
                .error="${error}"
                .value="${value}"
                .items="${this.diagnoses}"
                .onChange="${this.handlers.change}"
                placeholder="Select"
                multiSelect
                showFullText
                forceAlignMenu="right"
                wrapText
                itemHeight="${getDiagnosisItemHeight(this.diagnoses)}"
                itemMinWidth="${ITEM_MIN_WIDTH}"
                ?nonEditable="${this.inactivePointers}"
              ></neb-select>
            `
          : html`
              <span class="no-diagnoses-text"
                >${ELEMENTS.textNoDiagnoses.text}</span
              >
            `;

      default:
        return value;
    }
  }
}
customElements.define(
  'neb-table-code-bundle-charges',
  NebTableCodeBundleCharges,
);
