import '../../../packages/neb-lit-components/src/components/inputs/neb-select';
import '../../../packages/neb-lit-components/src/components/controls/neb-button-action';
import { html, css } from 'lit';

import { BUTTON_ROLE } from '../../../packages/neb-lit-components/src/components/neb-button';
import { KEPT_STATUSES } from '../../../packages/neb-lit-components/src/components/scheduling/neb-appointment-options';
import {
  CSS_BORDER_GREY_2,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../packages/neb-styles/neb-variables';
import {
  ALL_STATUSES,
  DEFAULT_FILTERS,
  getApptFiltersKey,
  getApptStatusesFromLocalStorage,
} from '../../../packages/neb-utils/appointment-action-util';
import createProviderCollection from '../../../packages/neb-utils/collections/providers';
import { parseDate } from '../../../packages/neb-utils/date-util';
import {
  FEATURE_FLAGS,
  getFeatures,
} from '../../../packages/neb-utils/feature-util';
import * as selectors from '../../../packages/neb-utils/selectors';
import { NebDateRange } from '../controls/field-groups/neb-range-date';

import { NebFilters, ELEMENTS as BASE_ELEMENTS } from './neb-filters';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  statusSelect: { id: 'status-select' },
  providerSelect: { id: 'provider-select' },
  caseSelect: { id: 'case-select' },
  dateRangeInput: { id: 'date-range-input' },
  locationsSelect: { id: 'locations-select' },
};

const FILTERS = Object.freeze({
  providerIds: 'providerIds',
  date: 'date',
  status: 'status',
  locationIds: 'locationIds',
  caseIds: 'caseIds',
});

class NebPatientAppointmentFilters extends NebFilters {
  static get properties() {
    return {
      locations: Array,
      cases: Array,
      patientId: String,

      __providerItems: Array,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .container {
          border: 0;
          border-radius: 0;
          border-top: ${CSS_BORDER_GREY_2};
          border-bottom: ${CSS_BORDER_GREY_2};
        }

        .content {
          grid-template-columns: repeat(3, 1fr);
        }

        .select {
          padding-bottom: 16px;
        }

        .button-container {
          display: flex;
          gap: 10px;
        }

        .container-buttons {
          display: flex !important;
          grid-gap: 10px !important;
          grid-template-columns: auto auto auto !important;
          align-items: center !important;
        }

        .print-button {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          padding-left: 10px;
        }
      `,
    ];
  }

  static createModel() {
    return {
      status: KEPT_STATUSES,
      providerIds: [],
      caseIds: [],
      date: NebDateRange.createModel(),
      locationIds: [],
    };
  }

  async connectedCallback() {
    const features = await getFeatures();
    this.__hasLocalStorageFilters = features.includes(
      FEATURE_FLAGS.DH_LOCAL_STORAGE_FILTERS,
    );

    super.connectedCallback();
  }

  initState() {
    super.initState();

    this.locations = [];
    this.cases = [];
    this.patientId = '';

    this.__providerItems = [];
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      changeDate: e => {
        this.formService.apply(e.name, e.value ? e.value.toISOString() : null);
      },
      apply: () => {
        const model = this.formService.build();
        const { from, to } = model.date;
        const { status } = model;

        const includeRescheduled = !!status.find(s => s === 'Rescheduled');

        this.onApply({
          ...model,
          date: {
            from: from ? parseDate(from._i) : null,
            to: to ? parseDate(to._i) : null,
          },
          includeRescheduled,
        });

        if (this.__hasLocalStorageFilters) {
          localStorage.setItem(
            getApptFiltersKey(),
            JSON.stringify({
              status: this.state.status,
              expanded: this.expanded,
            }),
          );
        }
      },
      toggle: () => {
        this.expanded = !this.expanded;

        if (this.__hasLocalStorageFilters) {
          localStorage.setItem(
            getApptFiltersKey(),
            JSON.stringify({
              status: this.state.status,
              expanded: this.expanded,
            }),
          );
        }
      },
    };
  }

  createSelectors() {
    return {
      children: {
        status: selectors.multiSelect(ALL_STATUSES),
        providerIds: selectors.multiSelect([]),
        caseIds: selectors.multiSelect([]),
        date: NebDateRange.createSelectors(),
        locationIds: selectors.multiSelect([]),
      },
    };
  }

  __handleGetApptStatus() {
    const { status, expanded } = this.__hasLocalStorageFilters
      ? getApptStatusesFromLocalStorage()
      : DEFAULT_FILTERS;

    this.expanded = expanded;
    this.formService.apply('status', status);
    this.handlers.apply();
  }

  async load() {
    this.__handleGetApptStatus();
    await createProviderCollection(
      ({ pageItems }) => {
        this.__providerItems = pageItems;
      },
      null,
      { hideInactive: false },
    );
  }

  update(changedProps) {
    if (
      (changedProps.has('active') || changedProps.has('patientId')) &&
      this.active &&
      this.patientId
    ) {
      this.formService.reset();
      this.__handleGetApptStatus();
    }
    super.update(changedProps);
  }

  __formatLocations(items) {
    return items.map(item => ({
      label: item.name,
      data: item,
    }));
  }

  __formatCases(items) {
    return items.map(item => ({
      label: item.active ? item.name : `${item.name} (Inactive)`,
      data: item,
    }));
  }

  __renderLocationsSelect() {
    return html`
      <neb-select
        id="${ELEMENTS.locationsSelect.id}"
        class="select"
        name="${FILTERS.locationIds}"
        allLabel="Locations"
        label="Locations"
        .items="${this.__formatLocations(this.locations)}"
        .value="${this.state.locationIds}"
        .onChange="${this.handlers.change}"
        multiSelect
      >
      </neb-select>
    `;
  }

  __renderCaseSelect() {
    return html`
      <neb-select
        id="${ELEMENTS.caseSelect.id}"
        label="Cases"
        name="${FILTERS.caseIds}"
        .items="${this.__formatCases(this.cases)}"
        .value="${this.state.caseIds}"
        .onChange="${this.handlers.change}"
        multiSelect
        wrapText
      ></neb-select>
    `;
  }

  renderContent() {
    const date = {
      from: this.state.date.from ? parseDate(this.state.date.from) : null,
      to: this.state.date.to ? parseDate(this.state.date.to) : null,
    };

    return html`
      <neb-select
        id="${ELEMENTS.statusSelect.id}"
        label="Appointment Status"
        allLabel="Statuses"
        name="${FILTERS.status}"
        .items="${ALL_STATUSES}"
        .value="${this.state.status}"
        .onChange="${this.handlers.change}"
        multiSelect
      ></neb-select>

      <neb-select
        id="${ELEMENTS.providerSelect.id}"
        label="Providers"
        allLabel="Providers"
        name="${FILTERS.providerIds}"
        .items="${this.__providerItems}"
        .value="${this.state.providerIds}"
        .onChange="${this.handlers.change}"
        multiSelect
      ></neb-select>

      ${this.__renderCaseSelect()} ${this.__renderLocationsSelect()}

      <neb-range-date
        id="${ELEMENTS.dateRangeInput.id}"
        label="Date"
        name="${FILTERS.date}"
        .model="${date}"
        .onChange="${this.handlers.changeDate}"
      ></neb-range-date>
    `;
  }

  __renderButtons() {
    return html`
      <div class="container-buttons" id="${ELEMENTS.actionButtons.id}">
        <neb-button
          id="${ELEMENTS.applyButton.id}"
          label="Apply"
          .role="${BUTTON_ROLE.CONFIRM}"
          .onClick="${this.handlers.apply}"
        ></neb-button>
        <neb-button
          id="${ELEMENTS.resetButton.id}"
          label="Reset"
          .role="${BUTTON_ROLE.OUTLINE}"
          .onClick="${this.handlers.reset}"
        ></neb-button>
      </div>
    `;
  }
}

window.customElements.define(
  'neb-filters-patient-appointments',
  NebPatientAppointmentFilters,
);
