import { getAllocatePaymentData } from '../../../packages/neb-api-client/src/allocation-api-client';
import { formatPaymentsV2 } from '../../../packages/neb-api-client/src/payments-api-client';
import {
  getFilterTransactionDates,
  getPatientsHash,
  getPayerPlansFromPatientsInsurances,
} from '../../../packages/neb-lit-components/src/components/overlays/payment/util';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../packages/neb-lit-components/src/utils/overlay-constants';
import {
  FEATURE_FLAGS,
  hasFeatureOrBeta,
} from '../../../packages/neb-utils/feature-util';
import { CHARGES_ORIGIN } from '../neb-charges-util';

export const ALLOCATE_PAYMENT_TAB = {
  ALLOCATED: 'allocatedChargesTab',
  OUTSTANDING: 'outstandingChargesTab',
};

async function openOverlayAllocatePaymentWithFF({
  patientId,
  payments,
  fromEraEobPage,
  origin,
  reportId,
  eobId,
  selectedLineItemIds,
  showAdditionalCharges,
  selectedTab,
}) {
  const payment = payments?.length ? payments[0] : null;

  const filterDates = getFilterTransactionDates(payment);
  const filters = {
    ...filterDates,
    payerPlanId: payment?.payerPlanId,
    patientId: patientId || payment?.patientId,
    ...{ eobId: origin === CHARGES_ORIGIN.EOB ? eobId : null },
    ...{ eraId: origin === CHARGES_ORIGIN.ERA ? reportId : null },
  };

  const payload = selectedLineItemIds?.length
    ? { lineItemIds: selectedLineItemIds }
    : {};

  const metaData = await getAllocatePaymentData({
    filters,
    payload,
  });

  return openOverlay(OVERLAY_KEYS.ALLOCATE_PAYMENT, {
    payments,
    patientId,
    fromEraEobPage,
    showAdditionalCharges,
    origin,
    reportId,
    eobId,
    selectedTab,
    selectedLineItemIds,
    hasOmegaInitialAllocationPerformanceFF: true,
    metaData: {
      ...metaData,
      payments: formatPaymentsV2(metaData.payments, metaData.patients),
      payerPlans: getPayerPlansFromPatientsInsurances(
        metaData.patientsInsurances,
      ),
      patientsHash: getPatientsHash(metaData.patients),
    },
  });
}

export async function openOverlayAllocatePayment({
  patientId,
  payments,
  fromEraEobPage,
  origin,
  reportId,
  eobId,
  selectedLineItemIds,
  showAdditionalCharges = true,
  selectedTab = ALLOCATE_PAYMENT_TAB.OUTSTANDING,
}) {
  const hasOmegaInitialAllocationPerformanceFF = await hasFeatureOrBeta(
    FEATURE_FLAGS.OMEGA_INITIAL_ALLOCATION_PERFORMANCE,
  );

  if (hasOmegaInitialAllocationPerformanceFF) {
    return openOverlayAllocatePaymentWithFF({
      patientId,
      payments,
      fromEraEobPage,
      origin,
      reportId,
      eobId,
      selectedLineItemIds,
      showAdditionalCharges,
      selectedTab,
    });
  }

  return openOverlay(OVERLAY_KEYS.ALLOCATE_PAYMENT, {
    patientId,
    payments,
    fromEraEobPage,
    origin,
    reportId,
    eobId,
    selectedLineItemIds,
    showAdditionalCharges,
    selectedTab,
    hasOmegaInitialAllocationPerformanceFF: false,
  });
}
