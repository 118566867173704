import '../../../packages/neb-lit-components/src/components/inputs/neb-select';

import { html, css } from 'lit';

import { BUTTON_ROLE } from '../../../packages/neb-lit-components/src/components/neb-button';
import { POPOVER_POSITION } from '../../../packages/neb-lit-components/src/components/neb-date-picker';
import { KEPT_STATUSES } from '../../../packages/neb-lit-components/src/components/scheduling/neb-appointment-options';
import { CSS_BORDER_GREY_2 } from '../../../packages/neb-styles/neb-variables';
import {
  ALL_STATUSES,
  DEFAULT_FILTERS,
  getApptFiltersKey,
  getApptStatusesFromLocalStorage,
} from '../../../packages/neb-utils/appointment-action-util';
import { parseDate } from '../../../packages/neb-utils/date-util';
import {
  FEATURE_FLAGS,
  hasFeatureOrBeta,
} from '../../../packages/neb-utils/feature-util';
import * as selectors from '../../../packages/neb-utils/selectors';
import { NebDateRange } from '../controls/field-groups/neb-range-date';

import { NebFilters, ELEMENTS as BASE_ELEMENTS } from './neb-filters';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  statusSelect: { id: 'status-select' },
  dateRangeInput: { id: 'date-range-input' },
};

const FILTERS = Object.freeze({
  date: 'date',
  status: 'status',
});

class NebPatientAppointmentFiltersMobile extends NebFilters {
  static get properties() {
    return {};
  }

  static get styles() {
    return [
      super.styles,
      css`
        .container {
          border: 0;
          border-radius: 0;
          border-top: ${CSS_BORDER_GREY_2};
          border-bottom: ${CSS_BORDER_GREY_2};
        }

        .content {
          grid-template-columns: repeat(2, 1fr);
        }

        .select {
          padding-bottom: 16px;
        }

        .grid-mobile {
          display: flex;
          flex-wrap: wrap;
          padding: 0px 20px 10px;
          gap: 20px 12px;
        }

        .status-select {
          flex: 1 1 280px;
        }

        .range-date {
          flex: 1 1 280px;
        }
      `,
    ];
  }

  static createModel() {
    return {
      status: KEPT_STATUSES,
      date: NebDateRange.createModel(),
    };
  }

  initState() {
    super.initState();
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      changeDate: e => {
        this.formService.apply(e.name, e.value ? e.value.toISOString() : null);
      },
      apply: () => {
        const model = this.formService.build();
        const { from, to } = model.date;
        const { status } = model;

        const includeRescheduled = !!status.find(s => s === 'Rescheduled');

        this.onApply({
          ...model,
          date: {
            from: from ? parseDate(from._i) : null,
            to: to ? parseDate(to._i) : null,
          },
          includeRescheduled,
        });

        if (this.__hasLocalStorageFilters) {
          localStorage.setItem(
            getApptFiltersKey(),
            JSON.stringify({
              status: this.state.status,
              expanded: this.expanded,
            }),
          );
        }
      },
      toggle: () => {
        this.expanded = !this.expanded;

        if (this.__hasLocalStorageFilters) {
          localStorage.setItem(
            getApptFiltersKey(),
            JSON.stringify({
              status: this.state.status,
              expanded: this.expanded,
            }),
          );
        }
      },
    };
  }

  async connectedCallback() {
    super.connectedCallback();
    this.__hasLocalStorageFilters = await hasFeatureOrBeta(
      FEATURE_FLAGS.DH_LOCAL_STORAGE_FILTERS,
    );
  }

  load() {
    const { status, expanded } = this.__hasLocalStorageFilters
      ? getApptStatusesFromLocalStorage()
      : DEFAULT_FILTERS;
    this.expanded = expanded;
    this.formService.apply('status', status);
    this.handlers.apply();
  }

  createSelectors() {
    return {
      children: {
        status: selectors.multiSelect(ALL_STATUSES),
        date: NebDateRange.createSelectors(),
      },
    };
  }

  render() {
    return html`
      <div class="container">
        <div class="grid-mobile">${this.renderContent()}</div>
        ${this.__renderFilters()}
      </div>
    `;
  }

  __renderFilters() {
    return html`
      <div class="container-buttons">
        <neb-button
          id="${ELEMENTS.applyButton.id}"
          label="Apply"
          .role="${BUTTON_ROLE.CONFIRM}"
          .onClick="${this.handlers.apply}"
        ></neb-button>
        <neb-button
          id="${ELEMENTS.resetButton.id}"
          label="Reset"
          .role="${BUTTON_ROLE.OUTLINE}"
          .onClick="${this.handlers.reset}"
        ></neb-button>
      </div>
    `;
  }

  renderContent() {
    const date = {
      from: this.state.date.from ? parseDate(this.state.date.from) : null,
      to: this.state.date.to ? parseDate(this.state.date.to) : null,
    };

    return html`
      <neb-select
        id="${ELEMENTS.statusSelect.id}"
        class="status-select"
        label="Appointment Status"
        allLabel="Statuses"
        name="${FILTERS.status}"
        .items="${ALL_STATUSES}"
        .value="${this.state.status}"
        .onChange="${this.handlers.change}"
        multiSelect
      ></neb-select>

      <neb-range-date
        id="${ELEMENTS.dateRangeInput.id}"
        class="range-date"
        label="Date"
        name="${FILTERS.date}"
        .manualPosition="${POPOVER_POSITION.CENTER}"
        .model="${date}"
        .onChange="${this.handlers.changeDate}"
      ></neb-range-date>
    `;
  }
}

window.customElements.define(
  'neb-filters-patient-appointments-mobile',
  NebPatientAppointmentFiltersMobile,
);
