import '../../../../../neb-material-design/src/components/neb-md-textfield';

import { html, css } from 'lit';

import { CSS_SPACING } from '../../../../../neb-styles/neb-variables';
import { centsToCurrency } from '../../../../../neb-utils/formatters';
import { number } from '../../../../../neb-utils/masks';
import { NebTableRow, ELEMENTS as BASE_ELEMENTS } from '../../neb-table-row';

const ERROR_TEXT_UNITS = '1 - 999';
export const UNITS_KEY = 'units';
export const CONFIG = [
  {
    key: 'procedure',
    label: 'Procedure',
    flex: css`0 0 96px`,
  },
  {
    key: 'description',
    label: 'Description',
    flex: css`1 0 0`,
  },
  {
    key: 'modifiers',
    label: 'Modifiers',
    flex: css`0 0 96px`,
  },
  {
    key: 'amount',
    label: 'Amount',
    flex: css`0 0 80px`,
  },
];

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  amountField: {
    id: 'field-amount',
  },
  descriptionField: {
    id: 'field-description',
  },
  modifiersField: {
    id: 'field-modifiers',
  },
  procedureField: {
    id: 'field-procedure',
  },
  typeField: {
    id: 'field-type',
  },
  unitsField: {
    id: 'field-units',
  },
};

class NebChargeTableRow extends NebTableRow {
  static get properties() {
    return {
      maxSelectionReached: Boolean,
      showRemoveButton: {
        reflect: true,
        type: Boolean,
      },
      displayUnits: Boolean,
    };
  }

  initState() {
    super.initState();
    this.__elements = {};
    this.config = CONFIG;
    this.maxSelectionReached = false;
    this.showRemoveButton = false;
    this.model = {
      procedure: '',
      description: '',
      modifiers: ['', '', '', ''],
      amount: '',
      units: '',
      availableForPurchase: false,
    };

    this.displayUnits = false;

    this.onChange = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers.changeUnits = ({ value }) => {
      this.change(UNITS_KEY, value);
    };

    this.handlers.validateUnits = () =>
      this.model.units && parseInt(this.model.units, 10) > 0;

    this.handlers.add = () =>
      this.__elements.unitsField
        ? this.handlers.validateAndAdd()
        : this.onAdd(this.model, this.index);

    this.handlers.validateAndAdd = () => {
      if (this.__elements.unitsField.validate()) {
        return this.onAdd(this.model, this.index);
      }

      return undefined;
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .units {
          padding-right: ${CSS_SPACING};
          margin-top: 10px;
        }
      `,
    ];
  }

  updated() {
    this.__elements = {
      unitsField: this.shadowRoot.getElementById(ELEMENTS.unitsField.id),
    };
  }

  validate() {
    return this.__elements.unitsField
      ? this.__elements.unitsField.validate()
      : true;
  }

  __renderAddButton() {
    return this.topic
      ? html`
          <div class="cell cell-button">
            <neb-toggle-button
              id="${ELEMENTS.addButton.id}"
              class="add-button"
              .layout="${this.layout}"
              .isToggled="${this.added}"
              .onClick="${this.handlers.add}"
              .buttonText="${this.added
                ? `${this.topic} Added`
                : `Add ${this.topic}`}"
              ?disabled="${this.maxSelectionReached}"
            ></neb-toggle-button>
          </div>
        `
      : '';
  }

  __renderRemoveButton() {
    return this.showRemoveButton
      ? html`
          <neb-icon
            id="${ELEMENTS.removeIcon.id}"
            class="icon-remove"
            icon="neb:minus"
            @click="${this.handlers.remove}"
          ></neb-icon>
        `
      : '';
  }

  renderColumn(key, value, model) {
    switch (key) {
      case 'procedure':
        return html` <div id="${ELEMENTS.procedureField.id}">${value}</div> `;

      case 'description':
        return html` <div id="${ELEMENTS.descriptionField.id}">${value}</div> `;

      case 'modifiers':
        return html`
          <div id="${ELEMENTS.modifiersField.id}">
            ${value.filter(v => v).join(', ')}
          </div>
        `;

      case 'amount': {
        const { units } = model;

        return html`
          <div id="${ELEMENTS.amountField.id}">
            ${centsToCurrency(value * units)}
          </div>
        `;
      }

      case 'availableForPurchase':
        return html`
          <div id="${ELEMENTS.typeField.id}">
            ${value ? 'Purchase' : 'Charge'}
          </div>
        `;

      case 'units':
        return this.displayUnits
          ? value
          : html`
              <neb-md-textfield
                id="${ELEMENTS.unitsField.id}"
                class="item units"
                slim
                maxLength="3"
                labelText=" "
                helperText="Required"
                .disabled="${!!this.added}"
                .errorText="${ERROR_TEXT_UNITS}"
                .externalValidator="${this.handlers.validateUnits}"
                .mask="${number}"
                .inputMode="${'numeric'}"
                .value="${value ? value.toString() : ''}"
                .onChange="${this.handlers.changeUnits}"
              ></neb-md-textfield>
            `;

      default:
        return '';
    }
  }

  render() {
    return html`
      <div class="container">
        ${this.__renderAddButton()} ${this.__renderColumns()}
        ${this.__renderRemoveButton()}
      </div>
    `;
  }
}

window.customElements.define('neb-charge-table-row', NebChargeTableRow);
