import { Method } from '../../packages/neb-api-client/src/utils/api-client-utils';
import ApiClientV2 from '../../packages/neb-api-client/src/utils/api-client-v2';

export const apiClient = new ApiClientV2({ microservice: 'billing' });

export async function getChargesForCheckOut({ feeScheduleId }) {
  const res = await apiClient.makeRequest({
    method: Method.GET,
    path: '/api/v1/tenants/:tenantId/check-out/charges',
    queryParams: { ...(feeScheduleId && { feeScheduleId }) },
  });

  return res;
}

export async function getChargeDetailsForCheckOut({
  chargeIds,
  feeScheduleId,
}) {
  const res = await apiClient.makeRequest({
    method: Method.GET,
    path: '/api/v1/tenants/:tenantId/check-out/charge-details',
    queryParams: { ...(chargeIds && { chargeIds }), feeScheduleId },
  });

  return res;
}
