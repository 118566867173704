import '../../tables/ledger/neb-table-ar-hub';
import { css, html, LitElement } from 'lit';

import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../../packages/neb-lit-components/src/utils/overlay-constants';
import Debouncer from '../../../../packages/neb-utils/debouncer';
import '../../../../packages/neb-lit-components/src/components/controls/neb-button-action';
import '../../../../packages/neb-lit-components/src/components/tables/neb-table';
import { CSS_SMALL_SPACING, CSS_SPACING } from '../../../styles';
import '../../overlays/ledger/ar-hub/neb-overlay-manage-columns';
import {
  CONFIG,
  getFilteredColumns,
  getCheckedColumn,
  filterOutCheckedColumn,
  ensureCheckedColumnFirst,
} from '../../../utils/ar-hub/column-definitions';

export const ELEMENTS = {
  table: { id: 'table' },
  showHideColumnsButton: { id: 'show-hide-columns-button' },
};

const EMPTY_MESSAGE = 'No results found, please adjust your filters';

class NebPageArHubNewCustomView extends LitElement {
  static get properties() {
    return {
      model: Array,
      sortParams: Array,
      isInvoiceOverlayClosed: Boolean,
      config: { type: Array },
      allColumns: { type: Array },
      columns: { type: Array },
    };
  }

  static get styles() {
    return css`
      .button-container {
        display: flex;
        justify-content: flex-end;
        margin-bottom: ${CSS_SMALL_SPACING};
        padding: 0 ${CSS_SPACING};
      }
    `;
  }

  constructor() {
    super();
    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.model = [];
    this.sortParams = [];
    this.isInvoiceOverlayClosed = false;
    this.config = getFilteredColumns();
    this.debouncer = new Debouncer();

    this.allColumns = filterOutCheckedColumn(CONFIG).map(column => ({
      key: column.key,
      label: column.label,
      selected: getFilteredColumns().some(c => c.key === column.key),
    }));

    this.columns = [...this.config];

    this.onChangeSort = () => {};

    this.onClickInvoice = () => {};

    this.onFollowUpAdded = () => {};

    this.onClickFollowUp = () => {};

    this.onSetColumns = () => {};

    this.onCompleteFollowUp = () => {};

    this.__sortDebouncer = new Debouncer(() => {
      const result = this.__pendingSortResult;
      this.sortParams = [result[0]];
      const { key, dir } = result[0];
      this.onChangeSort(key, dir);
    }, 200);
  }

  __initHandlers() {
    this.__handlers = {
      onSort: (_name, result) => {
        this.__pendingSortResult = result;
        this.__sortDebouncer.debounce();
      },
      onClickInvoice: row => {
        this.onClickInvoice(row);
      },
      onFollowUpAdded: result => {
        this.onFollowUpAdded(result);
      },
      onClickFollowUp: () => {
        this.onClickFollowUp();
      },
      completeFollowUp: ({ followUpId, isCompleted, rowIndex }) => {
        this.onCompleteFollowUp({ followUpId, isCompleted, rowIndex });
      },
      showHideColumns: async () => {
        const columns = filterOutCheckedColumn(this.config).map(column => ({
          key: column.key,
          label: column.label,
          selected: true,
        }));

        const unselectedColumns = filterOutCheckedColumn(CONFIG)
          .filter(column => !columns.some(c => c.key === column.key))
          .map(column => ({
            key: column.key,
            label: column.label,
            selected: false,
          }));

        const result = await openOverlay(OVERLAY_KEYS.MANAGE_COLUMNS, {
          availableColumns: [...columns, ...unselectedColumns],
        });

        if (result) {
          this.allColumns = result.allColumns;

          const { selectedColumns } = result;

          if (!selectedColumns.length) {
            this.config = [getCheckedColumn(), CONFIG[1]];
            return;
          }

          const configColumns = selectedColumns.map(selectedColumn =>
            CONFIG.find(column => column.key === selectedColumn.key),
          );

          this.config = ensureCheckedColumnFirst(configColumns);
          this.onSetColumns(this.config);
        }
      },
    };
  }

  __renderButtons() {
    return html`
      <div class="button-container">
        <neb-button-action
          id="${ELEMENTS.showHideColumnsButton.id}"
          label="Show/Hide Columns"
          leadingIcon="visibilityOff"
          .onClick="${this.__handlers.showHideColumns}"
        ></neb-button-action>
      </div>
    `;
  }

  updated(changedProperties) {
    if (changedProperties.has('columns') && this.columns) {
      const otherColumns = this.columns
        .filter(column => column.columnKey !== 'checked')
        .map(column => {
          const config = CONFIG.find(c => c.key === column.columnKey);
          return {
            ...config,
          };
        });

      this.config = ensureCheckedColumnFirst(otherColumns);
    }
  }

  __renderTable() {
    return html`
      <neb-table-ar-hub
        id="${ELEMENTS.table.id}"
        .config="${this.config}"
        .emptyMessage="${EMPTY_MESSAGE}"
        .model="${this.model}"
        .selectedTab="${this.selectedTab}"
        .onSort="${this.__handlers.onSort}"
        .onClickInvoice="${this.__handlers.onClickInvoice}"
        .onFollowUpAdded="${this.__handlers.onFollowUpAdded}"
        .onClickFollowUp="${this.__handlers.onClickFollowUp}"
        .onCompleteFollowUp="${this.__handlers.completeFollowUp}"
        .isInvoiceOverlayClosed="${this.isInvoiceOverlayClosed}"
        .sortParams="${this.sortParams}"
      ></neb-table-ar-hub>
    `;
  }

  render() {
    return html`
      <div class="content">
        ${this.__renderButtons()} ${this.__renderTable()}
      </div>
    `;
  }
}

customElements.define(
  'neb-page-ar-hub-new-custom-view',
  NebPageArHubNewCustomView,
);
