import { css, html } from 'lit';
import moment from 'moment-timezone';

import {
  allocatePayment,
  getChangedLineItemsForAllocationV2,
  getPreviewCharges,
} from '../../../packages/neb-api-client/src/allocation-api-client';
import { getBillingCodesWriteOffs } from '../../../packages/neb-api-client/src/billing-codes';
import * as patientApiClient from '../../../packages/neb-api-client/src/patient-api-client';
import { getPatientRelationships } from '../../../packages/neb-api-client/src/patient-relationship-api-client';
import { getPayerPlans } from '../../../packages/neb-api-client/src/payer-plan-api-client';
import { getPaymentDetail } from '../../../packages/neb-api-client/src/payments-api-client';
import {
  getPracticeUsers,
  getProviderUsers,
} from '../../../packages/neb-api-client/src/practice-users-api-client';
import Overlay from '../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import { sortPatients } from '../../../packages/neb-lit-components/src/components/overlays/payment/util';
import { openDirtyPopup } from '../../../packages/neb-popup';
import { Dirty } from '../../../packages/neb-redux/services/dirty';
import { LocationsService } from '../../../packages/neb-redux/services/locations';
import {
  FEATURE_FLAGS,
  hasFeatureOrBeta,
} from '../../../packages/neb-utils/feature-util';
import { objToName } from '../../../packages/neb-utils/formatters';
import { fetchPaymentTypeItems } from '../../../packages/neb-utils/neb-ledger-util';
import { uniq } from '../../../packages/neb-utils/utils';
import { openError, openSuccess } from '../../store';
import { formatAllocationLineItemsV2 } from '../../utils/allocate-charges/neb-allocate-charge-util';
import {
  PREVIEW_ALLOCATED_ERROR,
  PREVIEW_ALLOCATED_SUCCESS,
} from '../../utils/user-message';
import { NebFormPreviewAllocation } from '../forms/neb-form-preview-allocation';

export const ELEMENTS = {
  form: { id: 'form-preview-allocation' },
};

const OPTIONAL_PAYMENT_FIELDS = [
  'cardSaleId',
  'authEFT',
  'maskedCardDescription',
  'referenceId',
  'dateOfServiceFrom',
  'dateOfServiceTo',
  'electronicPaymentId',
  'electronicReferenceId',
  'patientOnline',
  'postedById',
];

class NebOverlayPreviewAllocation extends Overlay {
  static get properties() {
    return {
      __patients: Array,
      __paymentDetail: Object,
      __loading: Boolean,
      __formModel: Object,
      __practiceUsers: Array,
      __paymentTypes: Array,
      __adjustmentTypes: Array,
      __hasRelationships: Boolean,
      __locations: Array,
      __userLocations: Array,
      __defaultLocationId: String,
      __locationsService: Object,
      __hasRCMSecondaryField: Boolean,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          width: 100%;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
    this.__initServices();
  }

  __initState() {
    this.__hasRelationships = false;
    this.__providers = [];
    this.__payerPlans = [];
    this.__patients = [];
    this.__locations = [];
    this.__userLocations = [];
    this.__defaultLocationId = '';
    this.__locationsService = {};
    this.__paymentDetail = {
      allocations: [],
      paymentId: '',
      amount: 0,
    };

    this.__selectPayment = {};
    this.__patientsHash = {};

    this.__paymentTypes = [];
    this.__practiceUsers = [];
    this.__adjustmentTypes = [];
    this.__itemFilters = {
      dateOfTransactionFrom: null,
      dateOfTransactionTo: null,
      patient: '',
      locations: [],
    };

    this.__hasRCMSecondaryField = false;

    this.__formModel = NebFormPreviewAllocation.createModel();
  }

  __initHandlers() {
    this.handlers = {
      ...this.handlers,
      filterItems: query => this.__filterItems(query),
      changeDirty: isDirty => {
        this.__isDirty = isDirty;
      },
      cancel: () => this.__cancelPreviewAllocation(),
      save: (model, selectIndexes) =>
        this.__saveAllocatedCharges(model, selectIndexes),
      reload: async () => {
        await this.__load();

        if (
          !!this.__itemFilters.dateOfTransactionFrom ||
          !!this.__itemFilters.dateOfServiceFrom ||
          this.__itemFilters.locations.length ||
          this.__itemFilters.patient.data.id
        ) {
          await this.__filterItems(this.__itemFilters);
        }
      },
    };
  }

  __initServices() {
    this.__locationsService = new LocationsService(
      ({ userLocations, defaultLocationId }) => {
        this.__locations = userLocations;
        this.__defaultLocationId = defaultLocationId;
      },
    );

    this.__dirtyService = new Dirty(
      (hash, nextHash) => hash !== nextHash,
      () => this.__isDirty,
    );
  }

  async connectedCallback() {
    super.connectedCallback();

    const [paymentTypes, practiceUsers, adjustments, hasRCM] =
      await Promise.all([
        fetchPaymentTypeItems(),
        getPracticeUsers(),
        getBillingCodesWriteOffs({}, true),
        hasFeatureOrBeta(FEATURE_FLAGS.RCM_SECONDARY_FIELD),
      ]);

    this.__paymentTypes = paymentTypes;
    this.__practiceUsers = practiceUsers;
    this.__adjustmentTypes = adjustments;
    this.__hasRCMSecondaryField = hasRCM;

    this.__dirtyService.connect();
    this.__locationsService.connect();
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.__dirtyService.disconnect();
    this.__locationsService.disconnect();
  }

  __setDefaultLocation() {
    if (this.__defaultLocationId) {
      const defaultLocation = this.__locations.find(
        location => location.id === this.__defaultLocationId,
      );

      if (defaultLocation) {
        this.__itemFilters = {
          ...this.__itemFilters,
          locations: [{ data: defaultLocation, label: defaultLocation.name }],
        };
      }
    }
  }

  async __filterItems(query) {
    const lineItems = await this.__getPreviewCharges(query);

    this.__lineItems = lineItems;

    this.__formModel = {
      ...this.__formModel,
      items: lineItems,
    };

    this.__itemFilters = {
      dateOfTransactionFrom: query.dateOfTransactionFrom || null,
      dateOfTransactionTo: query.dateOfTransactionTo || null,
      patient: query.patient || '',
      locations: query.locations || [],
    };
  }

  async updated(changedProps) {
    if (changedProps.has('model') || changedProps.has('__defaultLocationId')) {
      if (changedProps.has('__defaultLocationId')) {
        this.__setDefaultLocation();
      }
      await this.__load();
    }

    super.updated(changedProps);
  }

  async __load() {
    this.__loading = true;

    const { payment } = this.model;

    const paymentId = payment.originalPaymentId
      ? payment.originalPaymentId
      : payment.id;

    this.__paymentDetail = await getPaymentDetail(paymentId, true);
    this.__formModel = await this.__getFormModel();
    this.__loading = false;
  }

  async __getPatientsHash(patientIds) {
    const uniqueIds = uniq(patientIds);

    const patientsList = await patientApiClient.fetchSome(
      uniqueIds,
      {},
      true,
      true,
    );

    return patientsList.reduce((memo, patient) => {
      memo[patient.id] = patient;
      return memo;
    }, {});
  }

  __extractDateFromFilter({ dateOfTransactionFrom }) {
    return dateOfTransactionFrom
      ? {
          dateOfServiceFrom: moment
            .tz(dateOfTransactionFrom, 'UTC')
            .startOf('day')
            .toISOString(),
        }
      : {};
  }

  __extractDateToFilter({ dateOfTransactionTo }) {
    return dateOfTransactionTo
      ? {
          dateOfServiceTo: moment
            .tz(dateOfTransactionTo, 'UTC')
            .endOf('day')
            .toISOString(),
        }
      : {};
  }

  __extractPatientFilter({ patient }) {
    return patient?.data?.id
      ? {
          patientId: patient.data.id,
        }
      : {};
  }

  __extractLocationFilter(query) {
    const locationIds =
      query.locations && query.locations.length
        ? query.locations.map(({ data }) => data.id)
        : this.__locations.map(location => location.id);

    const filteredLocationIds = locationIds.filter(id => id);

    return filteredLocationIds.length
      ? {
          locationIds: filteredLocationIds,
        }
      : {};
  }

  async __getPreviewCharges(query) {
    const queryParams = query
      ? {
          ...this.__extractDateFromFilter(query),
          ...this.__extractDateToFilter(query),
          ...this.__extractPatientFilter(query),
          ...this.__extractLocationFilter(query),
        }
      : null;

    const lineItems = await getPreviewCharges({
      paymentId: this.__paymentDetail.id,
      queryParams,
      hasPerformanceFeatureFlag: true,
    });

    const patientIds = lineItems.map(li => li.patientId);

    this.__patientsHash = await this.__getPatientsHash(patientIds);

    const formattedLineItems = formatAllocationLineItemsV2({
      lineItems,
      providers: this.__providers,
      payerPlans: this.__payerPlans,
      payment: this.__paymentDetail,
      patients: this.__patientsHash,
    });

    return formattedLineItems;
  }

  async __getFormModel() {
    const [providers, payerPlans] = await Promise.all([
      getProviderUsers(true),
      getPayerPlans({}, null, true),
    ]);

    this.__providers = providers;
    this.__payerPlans = payerPlans.payerPlan;

    const lineItems = await this.__getPreviewCharges({
      locations: this.__itemFilters.locations,
    });

    const patientIds = uniq(lineItems.map(li => li.patientId));
    const patients = await patientApiClient.fetchSome(patientIds);
    const sortedPatients = sortPatients(patients);
    const relatedPatientIds = this.__paymentDetail.patientId
      ? (await getPatientRelationships(this.__paymentDetail.patientId)).map(
          relationship => relationship.relatedPatientId,
        )
      : [];

    this.__hasRelationships = relatedPatientIds.length > 0;

    this.__patients = [
      this.__hasRelationships
        ? {
            label: '[All Related Patients]',
            data: {
              id: '',
              patientIds: [
                this.__paymentDetail.patientId,
                ...relatedPatientIds,
              ],
            },
          }
        : { label: '[All Patients]', data: { id: '' } },
      ...sortedPatients.map(p => ({
        label: objToName(p.name, {
          reverse: true,
          middleInitial: true,
        }),
        data: { id: p.id },
      })),
    ];

    const {
      amount,
      codePayment,
      transactionDate: date,
      paymentMethod: method,
      note,
      payerPlan,
      payerPlanId,
      patientName,
    } = this.__paymentDetail;

    const paymentType = codePayment
      ? `${codePayment.code} - ${codePayment.description}`
      : '';

    const payer = {
      item: {
        id: payerPlan.id,
        payerName: payerPlan.payerName,
      },
      label: `(${payerPlan.alias}) ${payerPlan.payerName}`,
    };

    const mapFormToPaymentDetail = Object.keys(
      NebFormPreviewAllocation.createModel().paymentTransaction[0],
    ).reduce(
      (prev, key) => ({ ...prev, [key]: this.__paymentDetail[key] || null }),
      {},
    );

    this.__itemFilters = {
      ...this.__itemFilters,
      patient: this.__getPatientForFilter(patientName),
    };

    return {
      paymentTransaction: [
        {
          ...mapFormToPaymentDetail,
          transactionDate: date.toISOString(),
          paymentType,
          payer,
          method,
          amount,
          payerPlanId,
          ...this.__getOptionalPaymentFields(),
        },
      ],
      note,
      items: lineItems,
    };
  }

  __getPatientForFilter(patientName) {
    if (
      this.__itemFilters.patient &&
      this.__itemFilters.patient.data.id &&
      this.__patients.some(
        p => p.data.id === this.__itemFilters.patient.data.id,
      )
    ) {
      return this.__patients.find(
        p => p.data.id === this.__itemFilters.patient.data.id,
      );
    }

    return this.__hasRelationships
      ? this.__patients[0]
      : this.__patients.find(p => p.label === patientName) ||
          this.__patients[0];
  }

  __formatLocations(locations) {
    return locations.map(location => ({
      label: location.name,
      data: location,
    }));
  }

  async __saveAllocatedCharges(model, selectIndexes) {
    const changedLineItems = getChangedLineItemsForAllocationV2(
      this.__formModel.items,
      model.items,
      true,
      selectIndexes,
    );

    if (changedLineItems.length === 0) return;

    try {
      await allocatePayment(this.__paymentDetail.id, changedLineItems, 4);

      openSuccess(PREVIEW_ALLOCATED_SUCCESS);
      this.onClose(true);
    } catch (_) {
      openError(PREVIEW_ALLOCATED_ERROR);
    }
  }

  async __cancelPreviewAllocation() {
    if (await openDirtyPopup()) {
      this.onClose(false);
    }
  }

  __getOptionalPaymentFields() {
    return OPTIONAL_PAYMENT_FIELDS.reduce((memo, key) => {
      if (Object.keys(this.__paymentDetail).includes(key)) {
        memo[key] = this.__paymentDetail[key];
      }
      return memo;
    }, {});
  }

  renderContent() {
    return html`
      <neb-form-preview-allocation
        id="${ELEMENTS.form.id}"
        ?readonly="${this.model.readonly}"
        .layout="${this.layout}"
        .patients="${this.__patients}"
        .patientsHash="${this.__patientsHash}"
        .locations="${this.__formatLocations(this.__locations)}"
        .patientId="${this.model.payment.patientId}"
        .paymentDetail="${this.__paymentDetail}"
        .model="${this.__formModel}"
        .itemFilters="${this.__itemFilters}"
        .onDismiss="${this.handlers.dismiss}"
        .onChangeDirty="${this.handlers.changeDirty}"
        .onItemFiltersChange="${this.handlers.filterItems}"
        .onSave="${this.handlers.save}"
        .onCancel="${this.handlers.cancel}"
        .onUpdate="${this.handlers.reload}"
        .hasRelationships="${this.__hasRelationships}"
        .paymentTypes="${this.__paymentTypes}"
        .practiceUsers="${this.__practiceUsers}"
        .adjustmentTypes="${this.__adjustmentTypes}"
        .payers="${this.__payerPlans}"
        ?loading="${this.__loading}"
        .hasRCMSecondaryField="${this.__hasRCMSecondaryField}"
      ></neb-form-preview-allocation>
    `;
  }
}

customElements.define(
  'neb-overlay-preview-allocation',
  NebOverlayPreviewAllocation,
);
