import { LitElement, html, css } from 'lit';

import { CSS_COLOR_GREY_2, layoutStyles } from '../../styles';

import '../../../packages/neb-lit-components/src/components/controls/neb-button-action';

export const ELEMENTS = {
  documentName: { id: 'document-name' },
  documentDate: { id: 'document-date' },
  uploadDate: { id: 'upload-date' },
  copyButton: { id: 'copy-button' },
};

class NebStructuredDocumentHeader extends LitElement {
  static get properties() {
    return {
      document: Object,
      copyButtonLabel: String,
      isSOAPTabSelected: Boolean,
    };
  }

  static get styles() {
    return [
      layoutStyles,
      css`
        .text {
          font-size: 16px;
          font-family: sans-serif, Arial, Verdana, 'Trebuchet MS',
            'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
        }

        .title {
          font-weight: bold;
          text-decoration: underline;
        }

        .divider {
          margin: 8px 0;
          height: 1px;
          background-color: ${CSS_COLOR_GREY_2};
        }

        .copy-button {
          max-width: fit-content;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.document = {};
    this.copyButtonLabel = 'Copy to Chart Notes';
    this.isSOAPTabSelected = false;

    this.onCopy = () => {};
  }

  __initHandlers() {
    this.handlers = {
      copy: () => this.onCopy(),
    };
  }

  render() {
    return html`
      <div id="${ELEMENTS.documentName.id}" class="title text">
        ${this.document.name}
      </div>

      <div class="grid grid-lean grid-2 text">
        <div id="${ELEMENTS.documentDate.id}">
          Document Date: ${this.document.documentDate}
        </div>

        <div id="${ELEMENTS.uploadDate.id}">
          Upload Date: ${this.document.uploadDate}
        </div>

        <neb-button-action
          id="${ELEMENTS.copyButton.id}"
          class="copy-button"
          .label="${this.copyButtonLabel}"
          .onClick="${this.handlers.copy}"
          .disabled="${this.isSOAPTabSelected}"
        ></neb-button-action>
      </div>
      <div class="divider"></div>
    `;
  }
}

customElements.define(
  'neb-structured-document-header',
  NebStructuredDocumentHeader,
);
