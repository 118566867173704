import '../../../../../neb-material-design/src/components/neb-md-textfield-with-icon';
import '../../neb-action-bar';
import '../../neb-table-header';
import '../../neb-popup-header';
import '../../neb-bulk-update';
import '../../neb-tooltip';
import '../../neb-pagination';
import '../../neb-text';
import '../../inputs/neb-textfield';
import '../../settings/charges/neb-charge-table-row';

import { html, css } from 'lit';

import { getMany as getManyCharges } from '../../../../../../src/api-clients/charges';
import { store } from '../../../../../neb-redux/neb-redux-store';
import { navigate } from '../../../../../neb-route/neb-route-state';
import {
  CSS_SPACING,
  CSS_FONT_SIZE_BODY,
  CSS_COLOR_GREY_1,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_ERROR,
} from '../../../../../neb-styles/neb-variables';
import { SELECT_CHARGES_OVERLAY_TYPE } from '../../../../../neb-utils/enums';
import {
  FEATURE_FLAGS,
  hasFeatureOrBeta,
} from '../../../../../neb-utils/feature-util';
import { openBulkUpdatePopup } from '../../../utils/charge-overlays-util';
import { getPurchaseCharges } from '../../../utils/purchase-charges';
import Overlay, { ELEMENTS as ELEMENTS_BASE } from '../neb-overlay';

export const PAGE_SIZE = 10;

export const NO_RESULTS_MESSAGE = 'No results.';

export const ADD_ALL_BUTTON_LABEL = {
  ADD_ALL_CHARGES: 'Add All Charges',
  ALL_CHARGES_ADDED: 'All Charges Added',
};

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  header: {
    id: 'header',
  },
  description: {
    id: 'description',
  },
  tableHeader: {
    id: 'table-header',
  },
  searchField: {
    id: 'search',
  },
  linkText: {
    id: 'text-link',
  },
  actionBar: {
    id: 'action-bar',
  },
  noItemsText: {
    id: 'text-no-items',
  },
  rows: {
    selector: 'neb-charge-table-row',
  },
  pagination: {
    id: 'pagination',
  },
  bulkUpdate: {
    id: 'bulk-update',
  },
  bulkUpdateTooltip: {
    id: 'bulk-update-tooltip',
  },
  selectedCount: {
    id: 'selected-count',
  },
};

const HINT_TEXT =
  'To update multiple charges with the same value, enter the unit value and click the arrow icon to populate against all filtered charges below.';

export const CONFIG = [
  {
    key: 'procedure',
    label: 'Procedure',
    flex: css`0 0 96px`,
  },
  {
    key: 'description',
    label: 'Description',
    flex: css`1 0 0`,
  },
  {
    key: 'modifiers',
    label: 'Modifiers',
    flex: css`0 0 96px`,
  },
  {
    key: 'amount',
    label: 'Total Amount',
    flex: css`0 0 80px`,
  },
];

export const CONFIG_UNIT = {
  mobile: true,
  key: 'units',
  label: 'Max Units',
  flex: css`0 0 120px`,
};

export const CONFIG_TYPE = {
  key: 'availableForPurchase',
  label: 'Type',
  flex: css`0 0 96px`,
};

const CONFIG_SPACER = {
  key: 'spacer',
  label: '',
  flex: css`0 0 180px`,
};

class NebOverlaySelectCharges extends Overlay {
  static get properties() {
    return {
      __charges: Array,
      __filteredCharges: Array,
      __pageCharges: Array,
      __pageIndex: Number,
      __pageCount: Number,
      __searchText: String,
      __allAdded: Boolean,
      __selectedCharges: Array,
      __maxUnits: String,
      __hideAddAll: Boolean,
      __includeBillingCodes: Boolean,
      __requireAvailableForPurchase: Boolean,
      __startingNumberOfSelected: Number,
      __hasDHChargeUnitsFF: Boolean,
    };
  }

  initState() {
    super.initState();
    this.model = {
      charges: [],
      type: SELECT_CHARGES_OVERLAY_TYPE.NO_UNITS,
      title: 'Add Charges',
      description: '',
    };

    this.__charges = [];
    this.__filteredCharges = [];
    this.__pageCharges = [];
    this.__pageIndex = 0;
    this.__pageCount = 0;
    this.__searchText = '';
    this.__selectedCharges = [];
    this.__allAdded = false;
    this.__maxUnits = '';
    this.__hideAddAll = false;
    this.__includeBillingCodes = false;
    this.__requireAvailableForPurchase = false;
    this.__startingNumberOfSelected = 0;
    this.__hasDHChargeUnitsFF = false;
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      apply: () => this.dismiss(this.__selectedCharges),
      clickSettings: () => store.dispatch(navigate('#/charges')),
      add: charge => {
        this.__selectedCharges = [...this.__selectedCharges, charge];
        this.__allAdded = this.__areAllChargesAdded();
      },
      addAll: () => {
        if (this.__chargesExist()) {
          const idTracker = [];
          const mergedArr = [];
          const allCharges = [
            ...this.__selectedCharges,
            ...this.__filteredCharges,
          ];
          allCharges.forEach(c => {
            if (
              !idTracker.includes(c.chargeId) &&
              ((this.model.type === SELECT_CHARGES_OVERLAY_TYPE.SHOW_UNITS &&
                c.units > 0) ||
                this.model.type !== SELECT_CHARGES_OVERLAY_TYPE.SHOW_UNITS)
            ) {
              idTracker.push(c.chargeId);
              mergedArr.push(c);
            }
          });

          this.__selectedCharges = mergedArr;
          this.__allAdded = this.__filteredCharges.every(fc =>
            this.__selectedCharges.some(sc => sc.chargeId === fc.chargeId),
          );
        }
      },
      search: ({ value }) => this.__filterCharges(value),
      clearSearch: () => {
        this.__searchText = '';

        this.__filterCharges('');
      },
      pageChanged: page => this.__refreshPageCharges(page),
      updateCharge: updatedCharge => {
        this.__updateChargeArray('__pageCharges', updatedCharge);

        this.__updateChargeArray('__filteredCharges', updatedCharge);
      },
      updateMaxUnits: value => {
        this.__maxUnits = value;
      },
      bulkUpdate: async () => {
        const result = await openBulkUpdatePopup(this.__maxUnits);

        if (result) {
          const updatedCharges = this.__filteredCharges.map(charge => {
            if (!this.isChargeAdded(charge)) charge.units = this.__maxUnits;
            return charge;
          });

          this.__filteredCharges = updatedCharges;
        }
      },
    };
  }

  async connectedCallback() {
    this.__hasDHChargeUnitsFF = await hasFeatureOrBeta(
      FEATURE_FLAGS.DH_CHARGE_UNITS,
    );

    super.connectedCallback();
  }

  dismissWithBlocker() {
    this.dismiss(this.__selectedCharges);
  }

  __updateChargeArray(key, updatedCharge) {
    const index = this[key].findIndex(
      c => c.chargeId === updatedCharge.chargeId,
    );
    const clone = [...this[key]];
    clone[index] = updatedCharge;
    this[key] = clone;
  }

  __refreshPageCharges(pageIndex) {
    this.__pageIndex = pageIndex;
    const startIndex = pageIndex * PAGE_SIZE;
    const count = Math.min(
      PAGE_SIZE,
      this.__filteredCharges.length - startIndex,
    );
    const endIndex = startIndex + count;
    this.__pageCount = Math.ceil(this.__filteredCharges.length / 10);
    this.__pageCharges = this.__filteredCharges.slice(startIndex, endIndex);
    this.__allAdded = this.__chargesExist()
      ? this.__areAllChargesAdded()
      : false;
  }

  __filterCharges(value) {
    this.__searchText = value;

    if (!value) {
      this.__filteredCharges = this.__charges;
    } else {
      const searchText = value.trim().split(' ');
      this.__filteredCharges = this.__charges.filter(c =>
        searchText.every(
          text =>
            c.procedure.toLowerCase().includes(text.toLowerCase()) ||
            c.description.toLowerCase().includes(text.toLowerCase()),
        ),
      );
    }

    this.__refreshPageCharges(0);
  }

  __chargesExist() {
    return this.__filteredCharges && this.__filteredCharges.length > 0;
  }

  __maxSelectionReached() {
    return (
      this.model.totalSelectionLimit &&
      (this.__selectedCharges.length >= this.model.totalSelectionLimit ||
        this.__selectedCharges.length - this.__startingNumberOfSelected >=
          this.model.currentSelectionLimit)
    );
  }

  isChargeAdded({ chargeId }) {
    return Boolean(
      this.__selectedCharges.find(item => item.chargeId === chargeId),
    );
  }

  __areAllChargesAdded() {
    return this.__filteredCharges.every(
      charge =>
        !!this.__selectedCharges.find(c => c.chargeId === charge.chargeId),
    );
  }

  __mapCharges(charges, type) {
    return charges.map(c => {
      if (!c.id && c.chargeId) return c;

      const { id, ...mappedCharge } = { ...c, chargeId: c.id };

      if (
        type === SELECT_CHARGES_OVERLAY_TYPE.SHOW_UNITS &&
        !mappedCharge.units
      ) {
        mappedCharge.units = '1';
      }

      return mappedCharge;
    });
  }

  __updateTableConfig() {
    if (this.model.labelProcedure) {
      CONFIG[0] = { ...CONFIG[0], label: this.model.labelProcedure };
    }
  }

  __fetch() {
    if (this.__includeBillingCodes) {
      getPurchaseCharges({
        requireAvailableForPurchase: this.__requireAvailableForPurchase,
      }).then(charges => {
        this.__charges = this.__mapCharges(charges, this.model.type);
      });
    } else {
      getManyCharges(true).then(charges => {
        this.__charges = this.__mapCharges(charges, this.model.type);
      });
    }
  }

  __getActiveChargesFromModel() {
    return [...this.model.charges].filter(c => c.active);
  }

  firstUpdated() {
    super.firstUpdated();

    if (this.model.charges) {
      this.__startingNumberOfSelected =
        this.__getActiveChargesFromModel().length;
    }
  }

  update(changedProps) {
    if (changedProps.has('model')) {
      this.model.charges = this.__getActiveChargesFromModel();
      this.__selectedCharges = this.__mapCharges(
        this.model.charges,
        this.model.type,
      );

      this.__updateTableConfig();
      this.__hideAddAll = this.model.hideAddAll;
      this.__includeBillingCodes = this.model.includeBillingCodes;
      this.__requireAvailableForPurchase =
        this.model.requireAvailableForPurchase;

      this.__fetch();
    }

    if (changedProps.has('__charges')) {
      this.__charges = [...this.__charges].filter(c => c.active);
      this.__filteredCharges = this.__charges;

      this.__refreshPageCharges(0);
    }

    super.update(changedProps);
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          display: flex;
          width: 880px;
          flex-flow: column nowrap;
        }

        .header {
          padding: ${CSS_SPACING};
        }

        .description {
          padding-left: ${CSS_SPACING};
        }

        .row {
          display: flex;
        }

        .search {
          margin: ${CSS_SPACING} ${CSS_SPACING} 14px;
        }

        .text-link {
          cursor: pointer;
          font-style: none;
          color: ${CSS_COLOR_HIGHLIGHT};
        }

        .text-no-items {
          margin: ${CSS_SPACING};
          font-size: ${CSS_FONT_SIZE_BODY};
          font-style: italic;
          color: ${CSS_COLOR_GREY_1};
        }

        .container-table {
          padding-top: ${CSS_SPACING};
          display: flex;
          overflow: auto;
          min-height: 0;
          flex-flow: column nowrap;
          flex: 1 0 0;
        }

        .row-container {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
        }

        .pagination {
          flex-direction: row-reverse;
          margin: ${CSS_SPACING};
        }

        .bulk-update-container {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding-right: ${CSS_SPACING};
        }

        .left-padding {
          padding-left: 12px;
        }

        .tooltip {
          padding-top: 10px;
        }

        .selection-length {
          margin: ${CSS_SPACING} ${CSS_SPACING} ${CSS_SPACING};
        }

        .max-selection-length {
          margin: ${CSS_SPACING} ${CSS_SPACING} ${CSS_SPACING};
          color: ${CSS_COLOR_ERROR};
        }
      `,
    ];
  }

  __renderBulkUpdate() {
    const bulkDisabled = !(this.__maxUnits > 0) || this.__allAdded;
    return html`
      <div class="bulk-update-container">
        <neb-bulk-update
          id="${ELEMENTS.bulkUpdate.id}"
          .onUpdateMaxUnits="${this.handlers.updateMaxUnits}"
          .onBulkUpdate="${this.handlers.bulkUpdate}"
          .disabled="${bulkDisabled}"
          .maxUnits="${this.__maxUnits}"
        >
        </neb-bulk-update>
        <neb-tooltip
          id="${ELEMENTS.bulkUpdateTooltip.id}"
          class="left-padding"
          defaultAnchor="left"
        >
          <div class="tooltip" slot="tooltip">${HINT_TEXT}</div>
        </neb-tooltip>
      </div>
    `;
  }

  __getConfig() {
    const baseConfig = [...CONFIG];

    if (this.model.type === SELECT_CHARGES_OVERLAY_TYPE.SHOW_UNITS) {
      return [...baseConfig, CONFIG_UNIT];
    }

    if (this.__hasDHChargeUnitsFF) {
      baseConfig.splice(-1, 0, {
        key: 'units',
        label: 'Units',
        flex: css`0 0 80px`,
      });
    }

    if (this.model.type === SELECT_CHARGES_OVERLAY_TYPE.SHOW_TYPE) {
      return [CONFIG_TYPE, ...baseConfig];
    }

    return baseConfig;
  }

  __renderRows() {
    return this.__pageCharges.map(charge => {
      const selectedCharge = this.__selectedCharges.find(
        c => c.chargeId === charge.chargeId,
      );

      const model = selectedCharge || charge;
      return html`
        <neb-charge-table-row
          class="charge-table-row"
          topic="Charge"
          .layout="${this.layout}"
          .model="${model}"
          .displayUnits="${this.__hasDHChargeUnitsFF}"
          .config="${this.__getConfig()}"
          .onAdd="${this.handlers.add}"
          .onChange="${this.handlers.updateCharge}"
          ?added="${this.isChargeAdded(charge)}"
          .maxSelectionReached="${this.__maxSelectionReached()}"
        ></neb-charge-table-row>
      `;
    });
  }

  __renderTableContent() {
    return this.__pageCharges.length
      ? this.__renderRows()
      : html`
          <p id="${ELEMENTS.noItemsText.id}" class="text-no-items">
            ${this.__searchText && this.__chargesExist
              ? NO_RESULTS_MESSAGE
              : html`
                  There are no charges configured for the practice.
                  <span
                    id="${ELEMENTS.linkText.id}"
                    class="text-link"
                    @click="${this.handlers.clickSettings}"
                    >Click here</span
                  >
                  to add practice charges.
                `}
          </p>
        `;
  }

  __renderPagination() {
    return html`
      <div class="row-container">
        ${this.__renderSelectedCount()}
        <neb-pagination
          id="${ELEMENTS.pagination.id}"
          class="pagination"
          .pageCount="${this.__pageCount}"
          .currentPage="${this.__pageIndex}"
          .onPageChanged="${this.handlers.pageChanged}"
        ></neb-pagination>
      </div>
    `;
  }

  __renderSelectedCount() {
    if (this.model.totalSelectionLimit) {
      const bulkSelectText = `${this.__selectedCharges.length}/${
        this.model.totalSelectionLimit
      } items added`;

      const singleSelectText = `${
        this.__selectedCharges.length - this.__startingNumberOfSelected
      }/${this.model.currentSelectionLimit} items added`;

      const value =
        this.model.totalSelectionLimit === this.model.currentSelectionLimit
          ? bulkSelectText
          : singleSelectText;

      return html`
        <neb-text
          id="${ELEMENTS.selectedCount.id}"
          class="${this.__maxSelectionReached()
            ? 'max-selection-length'
            : 'selection-length'}"
          >${value}</neb-text
        >
      `;
    }

    return '';
  }

  __renderTable() {
    const addAllLabel = !this.__allAdded
      ? ADD_ALL_BUTTON_LABEL.ADD_ALL_CHARGES
      : ADD_ALL_BUTTON_LABEL.ALL_CHARGES_ADDED;

    return html`
      ${this.model.type === SELECT_CHARGES_OVERLAY_TYPE.SHOW_UNITS
        ? this.__renderBulkUpdate()
        : ''}

      <div class="container-table">
        <neb-table-header
          id="${ELEMENTS.tableHeader.id}"
          .allAdded="${!this.__hideAddAll ? this.__allAdded : null}"
          .config="${!this.__hideAddAll
            ? this.__getConfig()
            : [CONFIG_SPACER, ...this.__getConfig()]}"
          .onAddAll="${this.handlers.addAll}"
          .addAllLabel="${addAllLabel}"
        ></neb-table-header>

        ${this.__renderTableContent()}
      </div>
    `;
  }

  __renderSearchField() {
    return html`
      <neb-textfield
        id="${ELEMENTS.searchField.id}"
        class="row search"
        leadingIcon="neb:search"
        label="Enter procedure or description to filter list below."
        .trailingIcon="${this.__searchText ? 'neb:clear' : ''}"
        .value="${this.__searchText}"
        .onChange="${this.handlers.search}"
        .onClickIcon="${this.handlers.clearSearch}"
      ></neb-textfield>
    `;
  }

  __renderDescription() {
    return html`
      <div id="${ELEMENTS.description.id}" class="description">
        ${this.model.description}
      </div>
    `;
  }

  __renderHeader() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        title="${this.model.title ? this.model.title : 'Add Charges'}"
        .onCancel="${this.handlers.apply}"
      ></neb-popup-header>

      ${this.model.description ? this.__renderDescription() : ''}
    `;
  }

  __renderActionBar() {
    return html`
      <neb-action-bar
        id="${ELEMENTS.actionBar.id}"
        confirmLabel=""
        cancelLabel="Done"
        .onCancel="${this.handlers.apply}"
      ></neb-action-bar>
    `;
  }

  renderContent() {
    return html`
      ${this.__renderHeader()} ${this.__renderSearchField()}
      ${this.__renderTable()}
      ${this.__pageCount > 0
        ? this.__renderPagination()
        : this.__renderSelectedCount()}
      ${this.__renderActionBar()}
    `;
  }
}

customElements.define('neb-overlay-select-charges', NebOverlaySelectCharges);
