import { openPopup } from '@neb/popup';
import { LitElement } from 'lit';

import { POPUP_RENDER_KEYS } from '../neb-popup/src/renderer-keys';
import { store, connect } from '../neb-redux/neb-redux-store';
import {
  renewTokenIfNeeded,
  tokenExpired,
  getLocalSession,
} from '../neb-utils/session';

import { start, reset, clear, timeOut } from './neb-timeout-state';

export const TIMEOUT_DURATION = 120 * 60 * 1000;
export const WARNING_DURATION = 5 * 60 * 1000;

class NebTimeout extends connect(store)(LitElement) {
  static get properties() {
    return {
      __duration: {
        type: Number,
      },
      __warnDuration: {
        type: Number,
      },
      __isAuthenticated: {
        type: Boolean,
      },
      __isWarnTimeout: {
        type: Boolean,
      },
      __isTimedOut: {
        type: Boolean,
      },
      __autoLogout: {
        type: Boolean,
      },
    };
  }

  constructor() {
    super();
    this.initState();
  }

  initState() {
    this.__autoLogout = true;
    this.__debouncer = null;
  }

  async __renderPopup() {
    const { logout, forcedLogout } = await openPopup(
      POPUP_RENDER_KEYS.TIMEOUT_WARNING,
      {
        countdownDuration: this.__warnDuration,
      },
    );

    return logout ? this.__endSession(forcedLogout) : this.resetTimer();
  }

  async __checkExpiredSession() {
    const localSession = getLocalSession();

    if (localSession && tokenExpired(localSession) && this.__autoLogout) {
      await this.__endSession(true);
    } else {
      await this.__isWarnTimeoutChanged();
    }
  }

  __debounce() {
    if (!this.__debouncer && !this.__isWarnTimeout) {
      this.__debouncer = true;

      setTimeout(async () => {
        await this.__checkExpiredSession();

        this.__debouncer = false;
      }, 5000);
    }
  }

  firstUpdated() {
    this.__isResetting = false;

    if (window.localStorage.getItem('neb-timeout-local')) {
      window.localStorage.removeItem('neb-timeout-local');

      this.__timeOut();

      openPopup(POPUP_RENDER_KEYS.TIMEOUT, {});
    }
  }

  async updated(changedProps) {
    if (
      changedProps.has('__isWarnTimeout') ||
      changedProps.has('__isTimedOut') ||
      changedProps.has('__isAuthenticated')
    ) {
      await this.__isWarnTimeoutChanged();
    }
  }

  connectedCallback() {
    super.connectedCallback();

    window.addEventListener('focus', () => this.__checkExpiredSession());
    window.addEventListener('mousemove', () => this.__debounce());
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('focus', () => this.__checkExpiredSession());
    window.removeEventListener('mousemove', () => this.__debounce());
  }

  _stateChanged({
    session: { isAuthenticated, item } = {},
    sessionTimeout: { isWarnTimeout, isTimedOut },
  }) {
    this.__isAuthenticated = isAuthenticated;
    this.__isWarnTimeout = isWarnTimeout;
    this.__isTimedOut = isTimedOut;
    this.__autoLogout = item?.inactiveLogout ?? true;
  }

  async __isWarnTimeoutChanged() {
    if (this.__isWarnTimeout && this.__autoLogout) {
      await this.updateComplete;
      await this.__renderPopup();
    } else if (!this.__isResetting && this.__isAuthenticated) {
      this.__isResetting = true;
      await this.resetTimer();
      await renewTokenIfNeeded();
      this.__isResetting = false;
    }
  }

  async __endSession(forcedLogout) {
    this.dispatchEvent(
      new CustomEvent('neb-timeout-end-session', {
        bubbles: true,
        composed: true,
      }),
    );

    this.clearTimer();

    if (!forcedLogout && !this.__isTimedOut) {
      await this.__timeOut();
    }

    if (forcedLogout) {
      const nebLocalTimeout = {
        isSessionTimeout: true,
      };
      window.localStorage.setItem(
        'neb-timeout-local',
        JSON.stringify(nebLocalTimeout),
      );
    }
  }
  /**
   * Start session timeout.
   * @param {Number} duration the timeout duration.
   * @param {Number} warnDuration the warning time before timed out.
   */

  startTimer(duration = TIMEOUT_DURATION, warnDuration = WARNING_DURATION) {
    this.duration = duration;
    this.warnDuration = warnDuration;

    store.dispatch(start(duration, warnDuration));
  }

  resetTimer() {
    return store.dispatch(reset());
  }

  clearTimer() {
    store.dispatch(clear());
  }

  __timeOut() {
    store.dispatch(timeOut());
  }

  set duration(duration) {
    this.__duration = duration;
  }

  set warnDuration(duration) {
    this.__warnDuration = duration;
  }
}
customElements.define('neb-timeout', NebTimeout);
