const NON_DIGIT = /\D/g;
const REGEX_ALL = /[\s\S]+/;
export const DIGIT = /\d */g;
export const DIGIT_NEGATIVE = /[-\d]+/;

function toRawPositionNumeric(pos, val) {
  const count = val.substring(0, pos).replace(DIGIT, '').length;

  return pos - count;
}

function toRawPositionCurrency(pos, val) {
  const count = val.substring(0, pos).replace(DIGIT, '').length;

  let modifier = 0;

  if (val.length === 2) {
    modifier = 1;
  }

  return pos - count + modifier;
}

function buildSingleDelMark(del, index, maxLength) {
  return {
    pattern: DIGIT,
    toRawPosition: toRawPositionNumeric,
    toRawValue: val => val.replace(NON_DIGIT, ''),
    toFormattedPosition: pos => (pos > index ? pos + 1 : pos),
    toFormattedValue: raw =>
      [raw.slice(0, index), raw.slice(index, maxLength)]
        .filter(str => str.length)
        .join(del),
  };
}

function buildDblDelMask(del, index1, index2, maxLength) {
  return {
    pattern: DIGIT,
    toRawPosition: toRawPositionNumeric,
    toRawValue: val => val.replace(NON_DIGIT, ''),
    toFormattedPosition: pos => {
      if (pos > index2) {
        return pos + 2;
      }

      if (pos > index1) {
        return pos + 1;
      }

      return pos;
    },
    toFormattedValue: raw =>
      [
        raw.slice(0, index1),
        raw.slice(index1, index2),
        raw.slice(index2, maxLength),
      ]
        .filter(str => str.length)
        .join(del),
  };
}

export const date = buildDblDelMask('/', 2, 4, 8);
export const ssn = buildDblDelMask('-', 3, 5, 9);
export const zipCode = buildSingleDelMark('-', 5, 9);
export const taxId = buildSingleDelMark('-', 2, 9);

export const defaultMask = {
  pattern: REGEX_ALL,
  toRawPosition: origPos => origPos,
  toRawValue: origVal => origVal,
  toFormattedPosition: rawPos => rawPos,
  toFormattedValue: rawVal => rawVal,
};

export const number = {
  pattern: DIGIT,
  toRawPosition: toRawPositionNumeric,
  toRawValue: val => val.replace(NON_DIGIT, ''),
  toFormattedPosition: pos => pos,
  toFormattedValue: raw => raw,
};

export const numberNoLeadingZero = {
  pattern: DIGIT,
  toRawPosition: toRawPositionNumeric,
  toRawValue: val => val.replace(NON_DIGIT, ''),
  toFormattedPosition: pos => pos,
  toFormattedValue: raw => {
    const leadingChar = raw[0];

    if (leadingChar === '0') {
      return '';
    }

    return raw;
  },
};

export const numberNoLeadingZeroAllowZero = {
  pattern: DIGIT,
  toRawPosition: toRawPositionNumeric,
  toRawValue: val => val.replace(NON_DIGIT, ''),
  toFormattedPosition: pos => pos,
  toFormattedValue: raw => {
    const leadingChar = raw[0];

    if (leadingChar === '0' && raw.length > 1) {
      return raw[1];
    }

    return raw;
  },
};

export const signedInteger = {
  pattern: /[-0-9]/,
  toRawPosition: origPos => origPos,
  toRawValue: origVal => {
    if (origVal.startsWith('0')) {
      return '0';
    }

    if (origVal.startsWith('-0')) {
      return `-${origVal.replace('-0', '').replace(NON_DIGIT, '')}`;
    }

    return (
      (origVal.startsWith('-') ? '-' : '') + origVal.replace(NON_DIGIT, '')
    );
  },
  toFormattedPosition: rawPos => rawPos,
  toFormattedValue: rawVal => rawVal,
};

export const phone = {
  pattern: DIGIT,
  toRawPosition: toRawPositionNumeric,
  toRawValue: val => val.replace(NON_DIGIT, ''),
  toFormattedPosition: (pos, rowVal) => {
    if (pos > 6) {
      return pos + 4;
    }

    if (pos > 3) {
      return pos + 3;
    }

    if (pos <= 3 && rowVal.length > 3) return pos + 1;

    return pos;
  },
  toFormattedValue: raw => {
    const segments = [raw.slice(0, 3), raw.slice(3, 6), raw.slice(6, 10)];

    if (raw.length < 4) {
      return segments[0];
    }

    if (raw.length < 7) {
      return `(${segments[0]}) ${segments[1]}`;
    }

    return `(${segments[0]}) ${segments[1]}-${segments[2]}`;
  },
};

export const validFileName = {
  pattern: /^[^|:/<>?*"[\]\\]/i,
  toRawPosition: pos => pos,
  toRawValue: val => val,
  toFormattedPosition: pos => pos,
  toFormattedValue: raw => {
    let val = raw.replace(/[|:/<>?*"[\]\\]/g, '');

    while (val.charAt(0) === '.') {
      val = val.substring(1);
    }
    return val;
  },
};

export const alphanumeric = {
  pattern: /^[a-z0-9]+$/i,
  toRawPosition: (pos, val) => {
    const count = val.substring(0, pos).replace(/^[a-z0-9]+$/i, '').length;

    return pos - count;
  },
  toRawValue: val => val.replace(/\W/g, ''),
  toFormattedPosition: pos => pos,
  toFormattedValue: raw => raw,
};

export const floatingPoint = {
  pattern: /^[.0-9]+$/i,
  toRawPosition: (pos, val) => {
    const count = val.substring(0, pos).replace(/^[.0-9]+$/i, '').length;

    return pos - count;
  },
  toRawValue: val => val.replace(/[^\d.]/g, ''),
  toFormattedPosition: pos => pos,
  toFormattedValue: raw => raw,
};

function getFormattedPosition(pos, rawVal, origVal) {
  if (rawVal === '') return 5;

  const afterDecimal = origVal.split('.')[1] || '';
  const decimalOffset = origVal.length - origVal.indexOf('.');
  const behindDecimal = [2, 4].includes(decimalOffset);
  const decimalPos = rawVal.length - 2;
  const totalCommas = Math.max(0, Math.ceil(decimalPos / 3) - 1);
  const posDiff = Math.max(0, decimalPos - pos);
  const commaLoss = Math.floor(posDiff / 3);
  const rawNum = Number(rawVal);
  const amount =
    afterDecimal.length > 2 &&
    rawNum < 1000 &&
    (pos === rawVal.length - 1 || pos === rawVal.length - 2);
  const commaCount = totalCommas - commaLoss;
  const padding = Number(rawVal.length < 3) + 1;
  const offset = commaCount + Number(behindDecimal) + padding - Number(amount);

  if (rawVal.length === 1) return pos + offset + 1;

  return pos + offset;
}

export const currency = {
  pattern: DIGIT,
  toRawPosition: toRawPositionCurrency,
  toRawValue: val => val.replace(NON_DIGIT, ''),
  toFormattedPosition: getFormattedPosition,
  toFormattedValue: raw =>
    `$${(Number(raw) / 100)
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`,
};

function toRawPositionNumericNegative(pos, val) {
  const count = val.substring(0, pos).replace(DIGIT, '').length;

  let modifier = 0;

  if (val.length === 2 || (val.length === 3 && val.includes('-'))) {
    modifier += 1; // fixes double click, -> any key - cursor position
  }

  if (val.length === 2 && val.includes('-')) {
    modifier += 1; // fixes double click, -> "-" cursor position
  }

  if (val === '$') {
    modifier += 1; // fixes double click, -> backspace cursor position
  }

  const addNegativePosition = val.includes('-') ? 1 : 0;
  return addNegativePosition + pos - count + modifier;
}

function toRowValueNegative(val) {
  const isNegative = val.includes('-');
  const currencySignalRemoved = !val.includes('$');

  let value = val.replace(NON_DIGIT, '');
  if (value === '') value = '0'; // fixes nan error
  if (isNegative && !currencySignalRemoved) value = `-${value}`;

  return value;
}

export const currencyNegative = {
  pattern: DIGIT_NEGATIVE,
  toRawValue: toRowValueNegative,
  toRawPosition: toRawPositionNumericNegative,
  toFormattedValue: raw =>
    `${raw.startsWith('-') && raw !== '-00' ? '-' : ''}$${(
      Number(Math.abs(raw)) / 100
    )
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`,
  toFormattedPosition: (pos, rawVal, origVal) => {
    const addNegativePosition = rawVal.includes('-') ? 1 : 0;
    const formattedPosition = getFormattedPosition(pos, rawVal, origVal);
    return formattedPosition + addNegativePosition;
  },
};

export const greaterThan = {
  pattern: DIGIT,
  toRawPosition: toRawPositionNumeric,
  toRawValue: val => {
    const digits = val.replace(NON_DIGIT, '');
    return digits.replace(/^0+/, '');
  },
  toFormattedPosition: (pos, val) => {
    if (!val) {
      return 3;
    }

    return pos + 2;
  },
  toFormattedValue: raw => `> ${raw || 0}`,
};

export function characterSetX12() {
  const X12_VALID = /^[a-z0-9.!@#$%&()-_+=]+$/i;
  const X12_INVALID = /[~*:;^|"'`{}]/g;

  return {
    pattern: X12_VALID,
    toRawPosition: (pos, val) => {
      const count = val.substring(0, pos).replace(X12_VALID, '').length;

      return pos - count;
    },
    toRawValue: val => val,
    toFormattedPosition: pos => pos,
    toFormattedValue: raw => raw.replace(X12_INVALID, ''),
  };
}
