import * as router from '@neb/router';

import * as userpilot from '../../src/library/userpilot';
import {
  DISCARD_BUTTON,
  openDirtyDialog,
  openDirtyPopup,
  SAVE_BUTTON,
  STAY_BUTTON,
  TITLES,
  TITLE_SEPARATOR,
  BRAND_SEPARATOR,
  titleFormatMap,
  PATHS,
} from '../neb-popup';
import { store } from '../neb-redux/neb-redux-store';

const ACTION_START_NAVIGATION = 'NEB_ROUTE_START_NAVIGATION';
const ACTION_WARN = 'NEB_ROUTE_WARN';
const ACTION_PREVENT_NAVIGATION = 'NEB_ROUTE_PREVENT_NAVIGATION';
const ACTION_MANUAL_UPDATE_HASH = 'ACTION_MANUAL_UPDATE_HASH';

export const ACTION_NAVIGATE = 'NEB_ROUTE_NAVIGATE';
/**
 * @typedef {Object} Route
 * @property {string} hash
 * @property {string} nextHash
 * @property {boolean} preventAppendHistory
 * @property {boolean} replaceHistoryIfPrevented
 * @property {boolean} navigating
 * @property {boolean} warn
 * @property {boolean} manualWarn
 */

/**
 * @param {Route} state
 * @param {Object} action
 * @returns {Route}
 */

export const routeReducer = (
  state = {
    hash: undefined,
    prevHash: undefined,
    nextHash: undefined,
    sync: true,
    preventAppendHistory: false,
    replaceHistoryIfPrevented: false,
    navigating: false,
    warn: false,
    manualWarn: false,
  },
  action,
) => {
  switch (action.type) {
    case ACTION_START_NAVIGATION:
      return {
        ...state,
        nextHash: action.hash,
        sync: action.sync,
        preventAppendHistory: action.preventAppendHistory,
        replaceHistoryIfPrevented: action.replaceHistoryIfPrevented,
        navigating: true,
        warn: false,
        manualWarn: false,
      };

    case ACTION_NAVIGATE:
      return {
        ...state,
        hash: action.hash,
        prevHash: action.prevHash,
        nextHash: null,
        sync: false,
        preventAppendHistory: false,
        replaceHistoryIfPrevented: false,
        navigating: false,
        warn: false,
        manualWarn: false,
      };

    case ACTION_WARN:
      return { ...state, warn: true, manualWarn: Boolean(action.manualWarn) };

    case ACTION_PREVENT_NAVIGATION:
      return {
        ...state,
        nextHash: null,
        preventAppendHistory: false,
        replaceHistoryIfPrevented: false,
        navigating: false,
        warn: false,
        manualWarn: false,
      };

    case ACTION_MANUAL_UPDATE_HASH:
      return { ...state, hash: action.hash };

    default:
      return state;
  }
};

/**
 * Helper function to format a string into title case with first letter capitalization
 * @param {string} str - The string to format
 * @returns {string} The formatted string
 */
const formatTitle = str => {
  if (!str) return '';
  if (titleFormatMap[str]) return titleFormatMap[str];
  return str
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

/**
 * Helper function to check if a string is a UUID
 * @param {string} str - String to check
 * @returns {boolean} True if string is a UUID
 */
const isUUID = str =>
  /^[0-9a-f]{8}-[0-9a-f]{4}-[4][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
    str,
  );

/**
 * Helper function to parse the route and return the appropriate title
 * @param {string} hash - The route hash
 * @param {string} [overlayName] - Optional overlay name to include as subtitle
 * @returns {string} The formatted title
 */
export const getDocumentTitle = (hash, overlayName) => {
  const isOnlineBooking = window.location.pathname.includes(PATHS.PORTAL);

  if (!hash) {
    return isOnlineBooking ? TITLES.ONLINE_BOOKING : TITLES.DEFAULT;
  }

  const overlay = overlayName || store.getState()?.popup?.overlays?.[0]?.key;

  const segments = hash
    .replace('#', '')
    .split('/')
    .filter(segment => !isUUID(segment));

  let title = formatTitle(segments[1]);

  if (!title && window.location.pathname === PATHS.SETTINGS) {
    title = TITLES.SETTINGS;
  }

  if (!overlay && segments.length > 2) {
    const lastSegment = formatTitle(segments[segments.length - 1]);

    if (lastSegment) {
      title = `${title}${TITLE_SEPARATOR}${lastSegment}`;
    }
  }

  if (overlay) {
    const formattedOverlay = formatTitle(overlay);

    if (formattedOverlay) {
      title = `${title}${TITLE_SEPARATOR}${formattedOverlay}`;
    }
  }

  if (isOnlineBooking) {
    return title || TITLES.ONLINE_BOOKING;
  }

  return title ? `${title}${BRAND_SEPARATOR}${TITLES.DEFAULT}` : TITLES.DEFAULT;
};

/**
 * Dispatch this action afte a user has been warned and the navigation should proceed.
 *
 * @param {boolean} preventAppendHistory
 * @param {string} hash
 * @returns {Object}
 */
export const continueNavigation = (dispatch, getState) => {
  const {
    sync,
    preventAppendHistory,
    replaceHistoryIfPrevented,
    warn,
    hash,
    nextHash,
    manualWarn,
  } = getState().route;

  if (manualWarn) {
    window.dispatchEvent(new CustomEvent('neb-dirty-manual-proceed'));
    /*
     * Because when manually warning the user, the page is not navigating anywhere, so by
     * triggering the prevent navigation, the state will be set appropriatly.
     */

    return dispatch({
      type: ACTION_PREVENT_NAVIGATION,
    });
  }

  if (warn) {
    window.dispatchEvent(new CustomEvent('neb-dirty-proceed'));
  }

  if (!preventAppendHistory) {
    window.history.pushState({}, '', nextHash);
  }

  if (replaceHistoryIfPrevented && warn) {
    window.history.replaceState({}, '', nextHash);
  } else if (sync && hash !== nextHash) {
    router.syncRoute(hash);
  }

  document.title = getDocumentTitle(nextHash);

  userpilot.reload();

  window.dispatchEvent(new CustomEvent('neb-route-hashchange'));

  return dispatch({
    type: ACTION_NAVIGATE,
    prevHash: hash,
    hash: nextHash,
  });
};

/**
 * Dispatch this action to start the navigation process. It will first update the `navigating`
 * state to give the oportunity of other components to warn the user and prevent the navigation.
 *
 * If nothing prevented then navigation it will continue with the navigation, setting the `hash`
 * state.
 *
 * @param {string} hash
 * @returns {Promise}
 */
export const navigate =
  (
    hash,
    options = {
      sync: true,
      preventAppendHistory: false,
      replaceHistoryIfPrevented: false,
    },
  ) =>
  (dispatch, getState) => {
    const { sync, preventAppendHistory, replaceHistoryIfPrevented } = options;
    dispatch({
      type: ACTION_START_NAVIGATION,
      hash,
      sync,
      preventAppendHistory,
      replaceHistoryIfPrevented,
    });

    const { navigating, warn, nextHash } = getState().route;

    if (navigating && !warn && nextHash !== null && nextHash !== undefined) {
      dispatch(continueNavigation);
    }
  };
/**
 * Dispatch this action after a user has been warned and the navigation should be prevented.
 *
 * @returns {Object}
 */

export const preventNavigation = (dispatch, getState) => {
  const { manualWarn, hash } = getState().route;

  if (manualWarn) {
    window.dispatchEvent(new CustomEvent('neb-dirty-manual-canceled'));
  }

  window.dispatchEvent(new CustomEvent('neb-route-canceled'));
  dispatch({
    type: ACTION_PREVENT_NAVIGATION,
  });

  if (window.location.href.indexOf(hash) === -1) {
    window.history.back();
  }
};

/**
 * Dispatch this action to update the state so the user can be warned before navigating.
 *
 * @returns {Object}
 */
export const warn =
  (
    manualWarn = false,
    { useNewDirtyDialog, isDirty, formIsValid, onSave, onDiscard, onStay } = {
      manualWarn: false,
      useNewDirtyDialog: false,
      isDirty: false,
      formIsValid: false,
      onSave: async () => {},
      onDiscard: async () => {},
      onStay: async () => {},
    },
  ) =>
  async dispatch => {
    dispatch({
      type: ACTION_WARN,
      manualWarn,
    });

    if (useNewDirtyDialog) {
      const option = !isDirty || (await openDirtyDialog({ formIsValid }));

      if (!option || option === STAY_BUTTON.name) {
        await onStay();
        dispatch(preventNavigation);
      }

      if (option === SAVE_BUTTON.name) {
        await onSave();
        dispatch(continueNavigation);
      }

      if (option === DISCARD_BUTTON.name) {
        await onDiscard();
        dispatch(continueNavigation);
      }
    }

    if (!useNewDirtyDialog) {
      if (await openDirtyPopup()) {
        dispatch(continueNavigation);
      } else {
        dispatch(preventNavigation);
      }
    }
  };

/**
 * Dispatch this action to manually warn with no navigation
 *
 * @returns {Object}
 */
export const manuallyWarn = () => warn(true);

export const manuallyUpdateHash = hash => ({
  type: ACTION_MANUAL_UPDATE_HASH,
  hash,
});
